import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axios from "../../config/axios";
import { identifier } from "@babel/types";
import { logout } from "./auth";

export const createCMSCategory = createAsyncThunk(
  "/admin/createCMSCategory",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/cmsCategory/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Category Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deleteCMSCategory = createAsyncThunk(
  "/admin/deleteCMSCategory",
  async (id, { getState, rejectWithValue }) => {
    console.log("🚀 ~ file: admin.js:35 ~ id:", id);
    try {
      const state = getState();
      console.log("🚀 ~ file: admin.js:36 ~ state:", state);
      const authToken = state.auth.token;
      console.log("🚀 ~ file: admin.js:38 ~ authToken:", authToken);
      const response = await axios.delete(`/cmsCategory/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Category delete Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const allDMCA = createAsyncThunk(
  "/admin/allDMCA",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.admin;
      const response = await axios.get(
        `/copyright/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getSingleDMCA = createAsyncThunk(
  "/admin/singleDMCA",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/copyright/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateSingleDMCA = createAsyncThunk(
  "/admin/updateSingleDMCA",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.patch(
        `/copyright/update/${data.dmcaId}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const CMSCategories = createAsyncThunk(
  "/admin/getCMSCategory",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get("/cmsCategory/get", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const addSiteInfo = createAsyncThunk(
  "/admin/personalInfo/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/personalInfo/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Site Info Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updateSiteInfo = createAsyncThunk(
  "/admin/personalInfo/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(
        `/personalInfo/update/${data.chargeId}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success("Site Info Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const createPage = createAsyncThunk(
  "/admin/createPage",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/pages/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Page Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const createProductCategory = createAsyncThunk(
  "/admin/product/category/create",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/product/category/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Category Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createProductSubCategory = createAsyncThunk(
  "/admin/product/subcategory/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/product/subcategory/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Category Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateProductSubCategory = createAsyncThunk(
  "/admin/product/subcategory/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(
        `/product/subcategory/update/${data.subCategoryId}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success("Category Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createProductSubSubCategory = createAsyncThunk(
  "/admin/product/subsubcategory/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        "/product/subcategory/sub/create",
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success("Category Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updateProductCategory = createAsyncThunk(
  "/admin/product/category/update",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(
        `/product/category/update/${data.id}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success("Category Updated Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const deleteProductCategory = createAsyncThunk(
  "/admin/product/category/delete",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/product/category/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Category deleted Successfully");
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message || "Oops! Something went wrong!");
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deleteProductSubCategory = createAsyncThunk(
  "/admin/product/subcategory/delete",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/product/subcategory/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Category deleted Successfully");
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message || "Oops! Something went wrong!");

      if (err.response && err.response.data && err.response.data.message) {
        return thunkAPI.rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getCategories = createAsyncThunk(
  "/admin/product/categories",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.admin;
      const response = await axios.get(
        `/admin/product/category/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getProductSubCategories = createAsyncThunk(
  "/admin/product/subcategories",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/admin/product/subcategory/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const adsCharges = createAsyncThunk(
  "/admin/charges/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get("/charges/get", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getPlatformCharges = createAsyncThunk(
  "/admin/platform-charges/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get("/platform-charges/get", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const addAdsCharges = createAsyncThunk(
  "/admin/charges/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/charges/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const addPlatformCharges = createAsyncThunk(
  "/admin/platform-charges/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/platform-charges/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateAdsCharges = createAsyncThunk(
  "/admin/charges/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(`/charges/update/${data._id}`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updateCMSPage = createAsyncThunk(
  "/admin/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(`/pages/update/${data.pageId}`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Page Saved Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateUser = createAsyncThunk(
  "/admin/user",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(`/users/update/${data.userId}`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("User Updated Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deleteSeller = createAsyncThunk(
  "/admin/seller/delete/id",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/users/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Seller delete Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deletePages = createAsyncThunk(
  "/admin/deletePages",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/pages/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Page Deleted Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const deleteSiteInfo = createAsyncThunk(
  "/admin/deleteSiteInfo",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/personalInfo/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Info Deleted Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const deleteAdsCharges = createAsyncThunk(
  "/admin/charges/delete",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/charges/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Info Deleted Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const singleSiteInfo = createAsyncThunk(
  "/admin/singleSiteInfo",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/personalInfo/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const singleAdsCharge = createAsyncThunk(
  "/admin/charges",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/charges/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getCmsPages = createAsyncThunk(
  "/admin/cmsPages",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.admin;
      const response = await axios.get(
        `/pages/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "/admin/users",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.admin;
      const response = await axios.get(
        `/users/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getUserWallets = createAsyncThunk(
  "/admin/user-wallets",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.admin;
      const response = await axios.get(
        `/users/user-wallets?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getSingleWallet = createAsyncThunk(
  "/admin/user-wallets/item",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/users/user-wallets/${data.id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateSingleWallet = createAsyncThunk(
  "/admin/user-wallets/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.patch(
        `/users/user-wallets/update/${data.id}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success("Updated Successfully");
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const personalInfo = createAsyncThunk(
  "/admin/personalInfo",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get("/personalInfo/get", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getAnnouncements = createAsyncThunk(
  "/admin/announcements",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.admin;
      const response = await axios.get(
        `/announcement/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const readAnnouncements = createAsyncThunk(
  "/admin/readAnnouncements",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.patch(`/announcement/read`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getSingleAnnouncement = createAsyncThunk(
  "/admin/single-announcements",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/announcement/get/by/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const deleteAnnouncement = createAsyncThunk(
  "/admin/delete/announcements",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/announcement/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const announcementSellers = createAsyncThunk(
  "/admin/sellers",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get("/announcement/getSellers", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const createAnnouncement = createAsyncThunk(
  "/admin/admin_announcement/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/announcement/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Announcement Sent Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const replyAnnouncement = createAsyncThunk(
  "/admin/admin_announcement/reply",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        "/announcement/reply-announcement",
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success("Announcement Sent Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const yinnCoin = createAsyncThunk(
  "/admin/yinn_coin",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get("/product/get/yinn_coin", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const createYinnCoin = createAsyncThunk(
  "/admin/yinn_coin/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/product/create/yinn_coin", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Coin created Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const paymentConfig = createAsyncThunk(
  "/admin/payments/config",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get("/payments/config", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updatePaymentConfig = createAsyncThunk(
  "/admin/payments/config/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put("/payments/config", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Configurations updated Successfully");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const singlePage = createAsyncThunk(
  "/admin/singlePage",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/pages/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const approvePayment = createAsyncThunk(
  "/admin/approvePayment",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `/admin/payment/approve/${data.id}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getAllSellerSupports = createAsyncThunk(
  "/admin/sellersupport",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(`/sellersupport/getall`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getsingleSellerSupport = createAsyncThunk(
  "/admin/singleTicket",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const res = await axios.get(
        `/sellersupport/getsinglesellersupport/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return res.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const replySellerSupport = createAsyncThunk(
  "/admin/replySellersupport",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const res = await axios.put(`/sellersupport/update/`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return res.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const DeleteSellerSupport = createAsyncThunk(
  "/admin/deleteSellerSupport",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(
        `sellersupport/seller-support-delete/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const TranslationContent = createAsyncThunk(
  "/admin/translation/TranslationContent",
  async (data, { getState }, thunkAPI) => {
    console.log("🚀 ~ file: admin.js:1380 ~ data:", data);
    try {
      const state = getState();
      const authToken = state.auth.token;
      toast.success("All Feilds Are Successfully Send");
      const response = await axios.post("/translation/web-content", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateAdminPassword = createAsyncThunk(
  "/admin/account/updatepassword",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/admin/account/updatepassword", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      toast.success("Password Updated Successfully");
      toast.success("You have to re-login, you will be logged out");

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateMembershipPrices = createAsyncThunk(
  "/admin/membership/plans",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/admin/membership/plans", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      toast.success("Membership plan price successfully updated");

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

const admin = createSlice({
  name: "admin",
  initialState: {
    cmsCategories: [],
    cmspages: [],
    users: [],
    usersWallets: [],
    count: 0,
    personalinfo: [],
    sellerSupports: [],
    singlesellersupport: {},
    productCategories: [],
    productSubCategories: [],
    adcharge: [],
    platformCharges: [],
    announcements: [],
    singleAnnouncement: {},
    sellers: [],
    dmca: [],
    singleDmca: {},
    createAnnouncement: [],
    yinnCoins: [],
    filters: [
      {
        field: "pageNo",
        value: 1,
      },
      {
        field: "perPage",
        value: 5,
      },
    ],
    paymentConfiguration: {},
    singleAdCharge: null,
    cmsSinglePage: null,
    singleInfo: null,
    message: "",
    loading: false,
    err: "",
    done: false,
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    addFilter(state, { payload: { field, value } }) {
      const filters = [...state.filters];
      const checkFilter = filters.findIndex((item) => item.field === field);
      if (checkFilter !== -1) {
        filters[checkFilter] = { field, value };
      } else {
        filters.push({ field, value });
      }
      state["filters"] = filters;
    },
    ClearState(state, action) {
      return {
        message: "",
        loading: false,
        err: "",
        done: false,
      };
    },
  },
  extraReducers: {
    [createCMSCategory.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createCMSCategory.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [createCMSCategory.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [allDMCA.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [allDMCA.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      dmca: action.payload.data,
      count: action.payload.count,
      err: "",
    }),
    [allDMCA.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSingleDMCA.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSingleDMCA.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      singleDmca: action.payload.data,
      err: "",
    }),
    [getSingleDMCA.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateSingleDMCA.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateSingleDMCA.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updateSingleDMCA.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [CMSCategories.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [CMSCategories.fulfilled]: (state, action) => ({
      ...state,
      cmsCategories: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [CMSCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [addSiteInfo.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [addSiteInfo.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [addSiteInfo.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createPage.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createPage.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [createPage.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateCMSPage.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateCMSPage.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updateCMSPage.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateUser.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateUser.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updateUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getCmsPages.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getCmsPages.fulfilled]: (state, action) => ({
      ...state,
      cmspages: action.payload.data,
      count: action.payload.count,
      loading: false,
      done: true,
      err: "",
    }),
    [getCmsPages.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAllUsers.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAllUsers.fulfilled]: (state, action) => ({
      ...state,
      users: action.payload.data.users,
      count: action.payload.data.count,
      loading: false,
      done: true,
      err: "",
    }),
    [getAllUsers.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getUserWallets.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getUserWallets.fulfilled]: (state, action) => ({
      ...state,
      usersWallets: action.payload.data.wallets,
      count: action.payload.data.count,
      loading: false,
      done: true,
      err: "",
    }),
    [getUserWallets.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSingleWallet.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSingleWallet.fulfilled]: (state, action) => ({
      ...state,
      singleWallet: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [getSingleWallet.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateSingleWallet.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateSingleWallet.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updateSingleWallet.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [personalInfo.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [personalInfo.fulfilled]: (state, action) => ({
      ...state,
      personalinfo: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [personalInfo.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [adsCharges.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [adsCharges.fulfilled]: (state, action) => ({
      ...state,
      adcharge: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [adsCharges.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getPlatformCharges.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getPlatformCharges.fulfilled]: (state, action) => ({
      ...state,
      platformCharges: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [getPlatformCharges.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [addAdsCharges.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [addAdsCharges.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [addAdsCharges.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [addPlatformCharges.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [addPlatformCharges.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [addPlatformCharges.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateAdsCharges.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateAdsCharges.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updateAdsCharges.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createAnnouncement.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createAnnouncement.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [createAnnouncement.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [replyAnnouncement.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [replyAnnouncement.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [replyAnnouncement.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteAnnouncement.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteAnnouncement.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [deleteAnnouncement.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteSeller.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteSeller.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [deleteSeller.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deletePages.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deletePages.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [deletePages.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteSiteInfo.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteSiteInfo.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [deleteSiteInfo.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [singlePage.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [singlePage.fulfilled]: (state, action) => ({
      ...state,
      cmsSinglePage: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [singlePage.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [approvePayment.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [approvePayment.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [approvePayment.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [singleSiteInfo.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [singleSiteInfo.fulfilled]: (state, action) => ({
      ...state,
      singleInfo: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [singleSiteInfo.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateSiteInfo.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateSiteInfo.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updateSiteInfo.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getCategories.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getCategories.fulfilled]: (state, action) => ({
      ...state,
      productCategories: action.payload.data,
      count: action.payload.count,
      loading: false,
      done: true,
      err: "",
    }),
    [getCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [announcementSellers.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [announcementSellers.fulfilled]: (state, action) => ({
      ...state,
      sellers: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [announcementSellers.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getProductSubCategories.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getProductSubCategories.fulfilled]: (state, action) => ({
      ...state,
      productSubCategories: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [getProductSubCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createProductCategory.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createProductCategory.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [createProductCategory.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err,
    }),
    [createProductSubCategory.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createProductSubCategory.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [createProductSubCategory.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createProductSubSubCategory.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createProductSubSubCategory.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [createProductSubSubCategory.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateProductCategory.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateProductCategory.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updateProductCategory.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err,
    }),
    [deleteProductCategory.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteProductCategory.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [deleteProductCategory.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteProductSubCategory.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteProductSubCategory.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [deleteProductSubCategory.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteAdsCharges.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteAdsCharges.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [deleteAdsCharges.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [singleAdsCharge.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [singleAdsCharge.fulfilled]: (state, action) => ({
      ...state,
      singleAdCharge: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [singleAdsCharge.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAnnouncements.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAnnouncements.fulfilled]: (state, action) => ({
      ...state,
      announcements: action.payload.data,
      count: action.payload.count,
      loading: false,
      done: true,
      err: "",
    }),
    [getAnnouncements.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [readAnnouncements.pending]: (state, action) => ({
      ...state,
    }),
    [readAnnouncements.fulfilled]: (state, action) => ({
      ...state,
      done: true,
      err: "",
    }),
    [readAnnouncements.rejected]: (state, action) => ({
      ...state,
      err: action.error.message,
    }),
    [getSingleAnnouncement.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSingleAnnouncement.fulfilled]: (state, action) => ({
      ...state,
      singleAnnouncement: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [getSingleAnnouncement.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [yinnCoin.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [yinnCoin.fulfilled]: (state, action) => ({
      ...state,
      yinnCoins: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [yinnCoin.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [paymentConfig.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [paymentConfig.fulfilled]: (state, action) => ({
      ...state,
      paymentConfiguration: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [paymentConfig.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updatePaymentConfig.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updatePaymentConfig.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updatePaymentConfig.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createYinnCoin.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createYinnCoin.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [createYinnCoin.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAllSellerSupports.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAllSellerSupports.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      sellerSupports: action.payload.data,
      err: "",
    }),
    [getAllSellerSupports.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),

    [getsingleSellerSupport.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getsingleSellerSupport.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      singlesellersupport: action.payload.data,
      err: "",
    }),
    [getsingleSellerSupport.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [replySellerSupport.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [replySellerSupport.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [replySellerSupport.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteCMSCategory.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteCMSCategory.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [deleteCMSCategory.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [DeleteSellerSupport.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [DeleteSellerSupport.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [DeleteSellerSupport.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [TranslationContent.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [TranslationContent.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [TranslationContent.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateAdminPassword.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateAdminPassword.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updateAdminPassword.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateMembershipPrices.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateMembershipPrices.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updateMembershipPrices.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
  },
});

const { reducer, actions } = admin;

export const { SetState, addFilter, ClearState } = actions;

export default reducer;
