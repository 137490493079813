export const PER_PAGE_OPTIONS = [8, 16, 80, 160];
export const PER_PAGE = PER_PAGE_OPTIONS[0];
export const PAGINATION = [
  { field: "pageNo", value: 0 },
  { field: "perPage", value: PER_PAGE },
];
export const PAGINATION_MODEL = {
  pageNo: 0,
  perPage: PER_PAGE,
};
