import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import {
  getProductSubCategories,
  getSingleProduct,
} from "../../../redux/reducers/seller";
import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";
import moment from "moment";
import "./viewproduct.css";


const ViewProduct = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const addProductTranslation = translationState?.[0]?.translations?.addProduct?.[userLanguage];
  const otherProductTranslation = translationState?.[0]?.translations?.otherProduct?.[userLanguage];
  const digitalProductTranslation = translationState?.[0]?.translations?.digitalProduct?.[userLanguage];

  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];

  useEffect(() => {
    dispatch(getSingleProduct(productId));
  }, []);

  const { singleProduct, loading } = useSelector((state) => state.seller);
  const ChatGPTTranslated = singleProduct?.translatedArray?.filter((item)=> item.language == userLanguage)
  const convertCommentFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw({ entityMap: {}, blocks: text.blocks }));
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <b>
                <i>{addProductTranslation?.detail ? addProductTranslation?.detail : "Product Details"}</i>
              </b>
            </CardHeader>
            <CardBody className="card-body">
              <div className="row">
                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{addProductTranslation?.productName ? addProductTranslation?.productName : "Product Name"}</b>
                  </Label>
                  <br />
                  {ChatGPTTranslated?.[0] ? ChatGPTTranslated[0]?.name : singleProduct?.name}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{addProductTranslation?.brand ? addProductTranslation?.brand : "Brand"}</b>
                  </Label>
                  <br />
                  {ChatGPTTranslated?.[0] ? ChatGPTTranslated[0]?.brand : singleProduct.brand}
                </section>
              </div>
              <br />

              <div className="row">
                <section className="col-md-12">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{addProductTranslation?.productSpecification ? addProductTranslation?.productSpecification : "Product Specification"}</b>
                  </Label>
                  <div id="comment-div">
                    {ChatGPTTranslated?.[0] ? 
                      ChatGPTTranslated[0]?.discription
                      :
                      singleProduct && singleProduct.specification && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCommentFromJSONToHTML(
                              singleProduct.specification
                            ),
                          }}
                        />
                      )
                      }
                  </div>
                </section>
              </div>

              <div className="row">
                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b> {addProductTranslation?.basePrice ? addProductTranslation?.basePrice : "Regular Price"}</b>
                  </Label>
                  <br />
                  {singleProduct.regularPrice}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b> {otherProductTranslation?.price ? otherProductTranslation?.price : "Cost"}</b>
                  </Label>
                  <br />
                  {singleProduct.cost}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{addProductTranslation?.dimension ? addProductTranslation?.dimension : "Dimension Units"}</b>
                  </Label>
                  <br />
                  {singleProduct.dimensionUnit}
                </section>
              </div>
              <div className="row">
                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{addProductTranslation?.width ? addProductTranslation?.width : "Width"}</b>
                  </Label>
                  <br />
                  {singleProduct.width}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{addProductTranslation?.depth ? addProductTranslation?.depth : "Depth"}</b>
                  </Label>
                  <br />
                  {singleProduct.depth}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{addProductTranslation?.heigth ? addProductTranslation?.heigth : "Height"}</b>
                  </Label>
                  <br />
                  {singleProduct.height}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b> {addProductTranslation?.girth ? addProductTranslation?.girth : "Girth"}</b>
                  </Label>
                  <br />
                  {singleProduct.girth}
                </section>
              </div>
              <br />
              <hr />
              {singleProduct?.attributes?.length > 0 ? (
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="12" sm="12">
                        <b>{otherProductTranslation?.productAttribute ? otherProductTranslation?.productAttribute : "Product Attribute"}</b>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="card-body">
                    <Row>
                      {singleProduct?.attributes?.length > 0
                        ? singleProduct?.attributes?.map((item, index) => (
                            <section className="col-md-3">
                              <Label htmlFor="appendedPrependedInput">
                                <b> {ChatGPTTranslated?.[0]?.attribute ? ChatGPTTranslated[0]?.attribute : item.name}</b>
                              </Label>
                              <br />
                            </section>
                          ))
                        : ""}
                    </Row>
                  </CardBody>
                </Card>
              ) : (
                ""
              )}
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="12" sm="12">
                      <b>{otherProductTranslation?.varients ? otherProductTranslation?.varients : "Variants"}</b>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="card-body">
                  {singleProduct?.attributes?.length > 0 ? (
                    <Table hover bordered striped responsive size="sm">
                      <thead>
                        <tr className="header-color">
                          <th>{otherProductTranslation?.varients ? otherProductTranslation?.varients : "Variant"}</th>
                          <th>{otherProductTranslation?.price ? otherProductTranslation?.price : "Price"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {singleProduct?.attributes?.length > 0
                          ? singleProduct?.attributes?.map((item, index) =>
                              item.variants.map((j) => (
                                <tr>
                                  <td>{ChatGPTTranslated?.[0]?.attribute ? ChatGPTTranslated[0]?.attribute : item.name}</td>
                                  <td>{j}</td>
                                </tr>
                              ))
                            )
                          : ""}
                      </tbody>
                    </Table>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
              {singleProduct?.priceTier?.length > 0 ? (
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="12" sm="12">
                        <b>{otherProductTranslation?.tierPrice ? otherProductTranslation?.tierPrice : "Tier Price"}</b>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="card-body">
                    <Table hover bordered striped responsive size="sm">
                      <thead>
                        <tr className="header-color">
                          <th>{otherProductTranslation?.quantity ? otherProductTranslation?.quantity : "Quantity"}</th>
                          <th>{otherProductTranslation?.discount ? otherProductTranslation?.discount : "Discount"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {singleProduct?.priceTier?.length > 0
                          ? singleProduct?.priceTier?.map((item, index) => (
                              <tr>
                                <td>{item.quantity} and above</td>
                                <td>{item.discount}%</td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              ) : (
                ""
              )}
              <div className="row">
                <section className="col-md-12">
                  <Label htmlFor="appendedPrependedInput" className="title">
                    <b>
                      <i>{addProductTranslation?.image ? addProductTranslation?.image : "Images"}</i>
                    </b>
                  </Label>
                </section>
              </div>
              <br />

              <div className="row">
                <Col xs="12" sm="12">
                  <section className="col-md-3">
                    {singleProduct?.images?.length > 0
                      ? singleProduct?.images?.map((item, index) => (
                          <img
                            src={item}
                            width="50"
                            height="50"
                            className="image-padding"
                          />
                        ))
                      : ""}
                  </section>
                </Col>
              </div>
              <br />
              <hr />

              <div className="row">
                <section className="col-md-12">
                  <Label htmlFor="appendedPrependedInput" className="title">
                    <b>
                      <i>{addProductTranslation?.main ? addProductTranslation?.main : "Categories"} </i>
                    </b>
                  </Label>
                </section>
              </div>
              <br />

              <div className="row">
                <CardBody className="card-body category-body">
                  <FormGroup>
                    <div class="row">
                      <div class="column" className="category-padding">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {addProductTranslation?.main ? addProductTranslation?.main : "Main Category"}
                        </div>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Col xs="12" sm="8">
                            <Label htmlFor="appendedPrependedInput">
                              {singleProduct?.mainCategory?.name}
                            </Label>
                          </Col>
                        </Row>
                      </div>
                      <div class="column" className="category-padding">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {singleProduct?.category?.length > 0
                            ? "Sub category"
                            : null}
                        </div>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Col xs="12" sm="8">
                            <Label htmlFor="appendedPrependedInput">
                              {singleProduct?.subCategory?.name}
                            </Label>
                          </Col>
                        </Row>
                      </div>
                      <div class="column" className="category-padding">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                            paddingRight: "20%",
                          }}
                        >
                          {singleProduct?.category?.length > 0
                            ? "Sub category"
                            : null}
                        </div>
                        {singleProduct?.category?.length > 0
                          ? singleProduct?.category?.map((item, index) => (
                              <div>
                                <div className="dd-wrapper">
                                  <div className="dd-header">
                                    <div className="dd-header-title"></div>
                                  </div>
                                  <Col xs="12" sm="12">
                                    <Label htmlFor="appendedPrependedInput">
                                      {item?.name}
                                    </Label>
                                  </Col>
                                </div>
                              </div>
                            ))
                          : "No Category Found "}
                      </div>
                    </div>
                  </FormGroup>
                </CardBody>
              </div>
              <br />
              <hr />
              <div className="row">
                <section className="col-md-12">
                  <Label htmlFor="appendedPrependedInput" className="title">
                    <b>
                      <i>{otherProductTranslation?.inventory ? otherProductTranslation?.inventory : "Inventory"}</i>
                    </b>
                  </Label>
                </section>
              </div>
              <br />

              <div className="row">
                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b> {otherProductTranslation?.stock ? otherProductTranslation?.stock : "Stock Quantity"}</b>
                  </Label>
                  <br />
                  {singleProduct.stockQuantity}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{otherProductTranslation?.unitWeight ? otherProductTranslation?.unitWeight : "Unit Weight"}</b>
                  </Label>
                  <br />
                  {singleProduct.unitWeight}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b> {otherProductTranslation?.unitWeight ? otherProductTranslation?.unitWeight : "Total Unit Weight"}</b>
                  </Label>
                  <br />
                  {singleProduct.totalUnitWeight}
                </section>

                <section className="col-md-3">
                  <Label htmlFor="appendedPrependedInput">
                    <b> {otherProductTranslation?.price ? otherProductTranslation?.price : "Total Quantity Cost"}</b>
                  </Label>
                  <br />
                  {singleProduct.totalQuantityCost}
                </section>
              </div>
              <br />

              <div className="row">
                <section className="col-md-12">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{otherProductTranslation?.description ? otherProductTranslation?.description : "Description"}</b>
                  </Label>
                  <br />
                  {singleProduct.description}
                </section>
              </div>
              <br />

              <div className="row">
                <section className="col-md-6">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{otherProductTranslation?.shippingCharges ? otherProductTranslation?.shippingCharges : "Shipping Charges"}</b>
                  </Label>
                  <br />
                  {singleProduct.shippingCharges}
                </section>

                <section className="col-md-6">
                  <Label htmlFor="appendedPrependedInput">
                    <b>{addProductTranslation?.weight ? addProductTranslation?.weight : "Weight Units"}</b>
                  </Label>
                  <br />
                  {singleProduct.weightUnit}
                </section>
              </div>
              <br />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewProduct;
