const NODE_ENV = process.env.NODE_ENV;

var  Env =  {
  url : "/yinn",
  //url : "/yinn"
  chatUrl: "https://yinn.ca/api/chat",
  // chatUrl: "http://yinn.ca/api/chat",
  // socketUrl : "http://192.168.1.72",
  socketUrl : "https://yinn.ca",
  // socketUrl : "http://yinn.ca",
  // kurentoWss: "wss://yinn.ca:",
  kurentoWss: "wss://yinn.ca",
  notificationUrl : "/api",
  janusUrl: "/janus"
}

switch (NODE_ENV) {
  case 'production':
    Env.url = "http://localhost:3001/yinn"
    break;
  case 'staging':
    Env.url = "http://localhost:3001/yinn"
    break;
  default:
    Env.url = "http://localhost:3001/yinn"
    Env.janusUrl = "https://yinn.ca/janus"
}



export default Env
