import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { verifyDigitalSignature } from "../../redux/reducers/auth";
import { Button, Col, Row, Table } from "reactstrap";
import { AppHeader } from "@coreui/react";
import DefaultHeader from "../Header/DefaultHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ProductVerification.css";

const ProductVerification = () => {
  const { translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);
  const verifyData = allWebContent?.[0]?.verify;
  const verifyTranslation =
    translationState?.[0]?.translations?.verify?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const paymentInfoTranslation =
    translationState?.[0]?.translations?.paymentInfo?.[userLanguage];
  const residencySwapTranslation =
    translationState?.[0]?.translations?.residencySwap?.[userLanguage];

  const dispatch = useDispatch();
  const history = useHistory();
  const [signature, setSignature] = useState(null);
  const { verified, verifiedProducts } = useSelector((state) => state.auth);
  console.log(
    "🚀 ~ file: ProductVerification.js:25 ~ ProductVerification ~ verifiedProducts:",
    verifiedProducts
  );
  const onFileChanged = (evt) => {
    const files = evt.target.files;
    if (files[0]) {
      const fileExt = files[0].name.split(".").pop();
      if (fileExt !== "txt") {
        toast.error("File format not supported.");
      } else if (fileExt === "txt") {
        files[0]?.text()?.then((data) => {
          setSignature(data);
        });
      }
    }
  };

  const verify = () => {
    if (!signature) {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Kindly select signatire file"
        }`
      );
    }
    dispatch(verifyDigitalSignature({ signature }));
  };

  return (
    <div
      className="app"
      style={{ "background-color": "white", minHeight: "100vh" }}
    >
      <ToastContainer autoClose={20000} />

      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>

      <div style={{ margin: "0 auto" }}>
        <Row style={{ marginTop: "130px" }}>
          <Col>
            <h4 style={{ textAlign: "center" }}>
              {verifyTranslation?.fisrtLine
                ? verifyTranslation?.fisrtLine
                : verifyData?.fisrtLine}
              {/* Verify Your Products */}
            </h4>
            <p style={{ textAlign: "center" }}>
              {verifyTranslation?.secondLine
                ? verifyTranslation?.secondLine
                : verifyData?.secondLine}
              {/* Upload the signature file you recieved with the order to verify
              its authenticity. */}
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                onChange={(e) => onFileChanged(e)}
                style={{ width: 180, marginBottom: 10 }}
              />
              <Button
                type="submit"
                disabled={!signature}
                onClick={verify}
                size="md"
                color="warning"
                style={{ width: 100, backgroundColor: "#eeac1a" }}
              >
                {verifyTranslation?.button
                  ? verifyTranslation?.button
                  : verifyData?.button}
                {/* Verify */}
              </Button>
            </div>
          </Col>
        </Row>

        {verified && verifiedProducts?.length > 0 && (
          <Row>
            <Col>
              <p
                style={{
                  color: "green",
                  marginTop: "10px",
                  textAlign: "center",
                }}
              >
                <b>
                  {toastTranslation?.success
                    ? toastTranslation?.success
                    : "Signature verification success!"}
                </b>
              </p>
              <p style={{ textAlign: "center" }}>
                {paymentInfoTranslation?.orderHeading
                  ? paymentInfoTranslation?.orderHeading
                  : "Following digital products are verified under this signature"}
              </p>
              <Table bordered>
                <thead>
                  <tr>
                    <th style={{ backgroundColor: "#000000" }}>
                      {residencySwapTranslation?.image
                        ? residencySwapTranslation?.image
                        : "Image"}
                    </th>
                    <th style={{ backgroundColor: "#000000" }}>
                      {residencySwapTranslation?.productName
                        ? residencySwapTranslation?.productName
                        : "Product Name"}
                    </th>
                    <th style={{ backgroundColor: "#000000" }}>
                      {residencySwapTranslation?.selectType
                        ? residencySwapTranslation?.selectType
                        : "Product Type"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {verifiedProducts?.map((product, i) => (
                    <tr key={i}>
                      <td>
                        <img src={product?.image} />
                      </td>
                      <td>{product?.name}</td>
                      <td>
                        {product?.type
                          ? product?.type
                          : "Category doesn't exist"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default ProductVerification;
