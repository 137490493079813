import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import axios from 'axios';
import Env from '../../../environment/environment';
import cookie from 'react-cookies';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from 'draft-js';
import { setState, singlePage } from "../../../redux/reducers/admin";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './viewpage.css';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

var divStyle = {
  marginLeft: "10%",
};

const ViewPage = () => {
  const history = useHistory();
  const pageId = new URLSearchParams(history.location.search).get("pageId");
  const dispatch = useDispatch();
  const { cmsSinglePage } = useSelector(state => state.admin);
  useEffect(() => {
    dispatch(singlePage(pageId));
  }, [])
  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
                {/* <div>
                  <Editor
                    editorState={cmsSinglePage.content}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    // onEditorStateChange={onEditorStateChange}
                  />
                </div> */}
            {cmsSinglePage.content && 
              <CardBody className="card-body">
                {/* <div className="ellipsis" > {draftToHtml(convertToRaw(cmsSinglePage.content))}</div> */}
              </CardBody>
            }
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default ViewPage;
