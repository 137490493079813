import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  SetState,
  getYinnExhangeRate,
  cashAllowed,
  buyProducts,
  toLocalBankTransferAccountDetails,
  setClearItem,
} from "../../redux/reducers/user";
import "./shipping.css";
import Summary from "./Summary";
import ProductSummary from "./ProductSummary";
import cashIcon from "../../assets/img/checkout/cash.svg";
import ynCoinIcon from "../../assets/img/checkout/yinncoin.jpg";
import bankTransferIcon from "../../assets/img/checkout/bank-transfer-svgrepo-com.svg";
import modalCloseIcon from "../../assets/img/checkout/modal-close.svg";
import AccountDetails from "./AccountDetails";
import { bluesnapSupportedData } from "../../assets/bluesnap/bluesnapSupport";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearCart, getExhangeRate } from "../../redux/reducers/auth";
import { Country, State, City } from "country-state-city";

const paymentMethods = [
  {
    name: "Local Bank Transfer",
    translatedName: "Local Bank Transfer",
    imgSrc: bankTransferIcon,
  },
  {
    name: "Yinn Coin",
    translatedName: "Yinn Coin",
    imgSrc: ynCoinIcon,
  },
  {
    name: "Cash on Delievry",
    translatedName: "Cash on Delievry",
    imgSrc: cashIcon,
  },
];
const today = new Date();
const months = Array(12)
  .fill()
  .map((val, i) => i + today.getMonth() + 1);
const years = Array(20)
  .fill()
  .map((val, i) => i + today.getFullYear());
const paymentMethodMapper = {
  "Local Bank Transfer": "local",
  "Yinn Coin": "yinnCoin",
  "Cash on Delievry": "cash",
};

const Payment = (props) => {
  const { usdRate, cart, paymentConfiguration, user } = useSelector(
    (state) => state.auth
  );
  const checkCart = cart?.map((item) => item?.product?.eventCategory);
  const showBankTransferAndCash = !checkCart.includes("event");
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const paymentInfoTranslation =
    translationState?.[0]?.translations?.paymentInfo?.[userLanguage];
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const myCartTranslation =
    translationState?.[0]?.translations?.myCart?.[userLanguage];
  const logInTranslation =
    translationState?.[0]?.translations?.logIn?.[userLanguage];
  const missedTranslation =
    translationState?.[0]?.translations?.missed?.[userLanguage];

  const dispatch = useDispatch();
  const [coinExchangeValue, setCoinExchangeValue] = useState(null);
  const [localBankTransferinfo, setLocalBankTransferinfo] = useState({
    amount: 0,
    firstName: "",
    lastName: "",
    country: "",
    currency: "",
    localBankTransferTransaction: {},
    paymentMethod: null,
  });
  const [isCashAllowed, setIsCashAllowed] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    localBankTransferinfo.paymentMethod
  );
  const { loading, localBankTransferAccountDetails, userCurrency, err } =
    useSelector((state) => state.user);

  const banknotAllowed = cart.some(
    (product) => product?.product?.sellerId?.localBankTransferSwitch !== true
  );
  const cashOnDeliveryNotAllowed = cart.some(
    (product) => product?.product?.productType === "digital"
  );
  const salesPrice =
    cart.reduce((a, b) => a + b.product?.unitPrice * b.qty, 0) * usdRate;
  const cartProductPrice = cart.reduce(
    (a, b) => a + b.product?.unitPrice * b.qty,
    0
  );
  const shippingPrice = cart.reduce(
    (a, b) => a + b.product?.shippingCharges * b.qty,
    0
  );
  const checkoutPercentage = (
    paymentConfiguration?.checkoutPaymentPercentageCharge * salesPrice
  ).toFixed(2);
  const membershipAllowed = cart.some(
    (product) =>
      product?.product?.sellerId?.membershipId?.membershipType === "Fremium"
  );

  const [finalRate, setFinalRate] = useState(0);
  const {
    moveStep,
    productData,
    pricingBreakdown,
    billingAdress,
    setBillingAdress,
    reuseShippingAddress,
    shippingInfo,
    addressReused,
    totalPrice,
    onlyDigitalProducts,
    onlyResidencyProducts,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    loadYinnCoinExchangeRate();
    checkCashPaymentAvailability();
    if (onlyResidencyProducts && !membershipAllowed) {
      // if(onlyResidencyProducts){
      setSelectedPaymentMethod("Cash on Delievry");
    }
    dispatch(setClearItem({ field: "localBankTransferAccountDetails" }));
  }, []);

  const loadYinnCoinExchangeRate = async () => {
    dispatch(getYinnExhangeRate()).then((response) => {
      setCoinExchangeValue(response?.payload?.yinnCoinExchnageRate);
    });
  };

  const [bluesnapSupported, setBluesnapSupported] = useState(
    bluesnapSupportedData
  );
  const [currency, setCurrency] = useState("");
  const [error, setError] = useState(false);
  const [states, setStates] = useState([
    { name: addressReused ? shippingInfo.state : billingAdress.state },
  ]);
  const [cities, setCities] = useState([
    { name: addressReused ? shippingInfo.city : billingAdress.city },
  ]);
  const [modal, setModal] = useState(false);
  const [clickedPaymentMethod, setClickedPaymentMethod] = useState({});

  const toggle = () => {
    setModal(!modal);
  };

  const selectPaymentMethod = (method) => {
    setSelectedPaymentMethod(method.name);
  };

  const handleShippingInfoInput = (e) => {
    const { name, value } = e.target;
    const update = { ...billingAdress };
    update[name] = value;
    setBillingAdress(update);
    if (name === "country") {
      const country = Country.getAllCountries(value).filter(
        (country) => country.name === value
      );
      const filteredStates = State.getStatesOfCountry(country[0]?.isoCode);
      setStates(filteredStates);
      setCities([]);
    } else if (name === "state") {
      const stateObj = State.getAllStates().filter(
        (state) => state.name === value
      )[0];

      const filteredCities = City.getCitiesOfState(
        stateObj.countryCode,
        stateObj.isoCode
      );
      setCities(filteredCities);
    }
  };

  const makePayment = () => {
    const {
      firstname,
      lastname,
      addressLineOne,
      addressLineTwo,
      zipCode,
      country,
      state,
    } = billingAdress;
    if (selectedPaymentMethod === null) {
      toast.error("Select a payment method", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      localBankTransferAccountDetails === null &&
      selectedPaymentMethod === "Local Bank Transfer"
    ) {
      toast.error("Select payment method required details compeletly", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (paymentMethodMapper[selectedPaymentMethod] === "cash") {
        if (
          (firstname &&
            lastname &&
            addressLineOne &&
            addressLineTwo &&
            zipCode &&
            country &&
            state &&
            selectedPaymentMethod) ||
          onlyResidencyProducts
        ) {
          handleCartCheckout();
          setError(false);
        } else {
          setError(true);
          toast.error("Complete billing information first", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        handleCartCheckout();
      }
    }
  };

  const handleCartCheckout = async () => {
    try {
      let data;
      if (paymentMethodMapper[selectedPaymentMethod] === "local") {
        data = {
          localBankTransferAccountDetails,
          actionStatusType: "Local Bank",
          paymentMethod: paymentMethodMapper[selectedPaymentMethod],
          shippingAddress: shippingInfo,
          cart,
          YinnFee: coinExchangeValue,
        };
      } else {
        await dispatch(getExhangeRate("USD")).then((response) => {
          data = {
            paymentMethod: paymentMethodMapper[selectedPaymentMethod],
            shippingAddress: shippingInfo,
            cart,
            YinnFee: coinExchangeValue,
            finalRate:
              cartProductPrice * response?.payload?.usdRate +
              shippingPrice * response?.payload?.usdRate +
              Number(
                paymentConfiguration?.checkoutPaymentPercentageCharge *
                  cartProductPrice
              ) +
              paymentConfiguration?.checkoutPaymentFixCharge,
          };
        });
      }
      dispatch(buyProducts(data)).then((response) => {
        if (response.payload.err) {
          toast.error(response.payload.err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          moveStep(3);
          dispatch(clearCart());
        }
      });
    } catch (e) {
      let errorMessage = e.payload?.err || "Unable to complete the transaction";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const openPaymentModal = (selected) => {
    setClickedPaymentMethod(selected);
    if (selected.name === "Local Bank Transfer" && !banknotAllowed) {
      if (selected.name !== selectedPaymentMethod) {
        selectPaymentMethod({ name: null });
      }
      setLocalBankTransferinfo({
        amount: totalPrice,
        firstName: "",
        lastName: "",
        country: "",
        currency: "",
        localBankTransferTransaction: {},
      });
      toggle();
      selectPaymentMethod(selected);
    } else {
      selectPaymentMethod(selected);
    }
  };

  const handlePaymentDataInput = (e) => {
    const { name, value } = e.target;
    const update = { ...localBankTransferinfo, [name]: value };
    if (name === "country") {
      const countryObj = bluesnapSupported.find(
        (obj) => obj.shoppersCountry === value
      );
      dispatch(
        SetState({ field: "userCurrency", value: countryObj?.currency })
      );
      setCurrency(countryObj?.currency);
      dispatch(getExhangeRate(countryObj?.currency)).then((response) => {
        setLocalBankTransferinfo({
          ...localBankTransferinfo,
          country: countryObj?.shoppersCountryCode,
          currency: countryObj?.currency,
          amount:
            (cartProductPrice +
              shippingPrice +
              Number(
                paymentConfiguration?.checkoutPaymentPercentageCharge *
                  cartProductPrice
              )) *
              response?.payload?.usdRate +
            // paymentConfiguration?.coinMaintenanceCharge +
            paymentConfiguration?.checkoutPaymentFixCharge,
        });
      });
    } else {
      setLocalBankTransferinfo(update);
    }
  };

  const addCardInfo = () => {
    if (
      localBankTransferinfo.firstName &&
      localBankTransferinfo.lastName &&
      localBankTransferinfo.amount &&
      localBankTransferinfo.country &&
      localBankTransferinfo.currency
    ) {
      const data = {
        amount: +localBankTransferinfo.amount,
        payerInfo: {
          firstName: localBankTransferinfo.firstName,
          lastName: localBankTransferinfo.lastName,
          country: localBankTransferinfo.country,
        },
        currency: localBankTransferinfo.currency,
        localBankTransferTransaction: {},
      };
      dispatch(toLocalBankTransferAccountDetails(data));
      selectPaymentMethod(clickedPaymentMethod);
      toggle();
    } else {
      toast.error("Enter All details to proceed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const checkCashPaymentAvailability = async () => {
    const data = {
      purchaseMethod: "cart",
      sellerId: productData?.sellerId,
    };
    dispatch(cashAllowed(data)).then((response) => {
      setIsCashAllowed(response?.data?.allowed);
    });
  };

  const setShipppingToBilling = (e) => {
    reuseShippingAddress(e);
    setStates([{ name: shippingInfo.state }]);
    setCities([{ name: shippingInfo.city }]);
  };

  return (
    <>
      <div hidden={!loading} className="overlay-purchase-processing">
        <b style={{ opacity: 1, color: "#ffffff", fontSize: 28 }}>
          {" "}
          {missedTranslation?.process
            ? missedTranslation?.process
            : "Processing transaction"}
        </b>
      </div>
      <Row>
        <ToastContainer autoClose={20000} />
        <Col xs="12" lg="7" className="shipping-form">
          <Row>
            <Col xs="0" lg="1" />
            <Col xs="12" lg="10">
              <div className="shipping-title">
                {paymentInfoTranslation?.paymentInfo
                  ? paymentInfoTranslation?.paymentInfo
                  : "Payment Information"}
              </div>
              {localBankTransferAccountDetails === false && (
                <div style={{ color: "red", fontSize: "20px" }}>
                  Bad Request Please Try Again
                </div>
              )}
              {!localBankTransferAccountDetails ? (
                <div>
                  <div style={{ marginTop: 30, marginBottom: 20 }}>
                    {paymentInfoTranslation?.paymentMethod
                      ? paymentInfoTranslation?.paymentMethod
                      : "Payment method :"}
                  </div>
                  <div className="payment-method-select-area">
                    {checkCart.includes("event")
                      ? paymentMethods.map((method, index) => {
                          if (method.name === "Yinn Coin") {
                            return (
                              <PaymentMethod
                                {...method}
                                onClick={() => {
                                  openPaymentModal(method);
                                }}
                                selected={selectedPaymentMethod === method.name}
                                isCashAllowed={isCashAllowed}
                                finalRate={finalRate}
                                setFinalRate={setFinalRate}
                                banknotAllowed={banknotAllowed}
                                cashOnDeliveryNotAllowed={
                                  cashOnDeliveryNotAllowed
                                }
                                coinExchangeValue={coinExchangeValue}
                                onlyResidencyProducts={onlyResidencyProducts}
                                membershipAllowed={membershipAllowed}
                                usdRate={usdRate}
                              />
                            );
                          }
                        })
                      : paymentMethods.map((method, index) => (
                          <PaymentMethod
                            {...method}
                            onClick={() => {
                              openPaymentModal(method);
                            }}
                            selected={selectedPaymentMethod === method.name}
                            isCashAllowed={isCashAllowed}
                            finalRate={finalRate}
                            setFinalRate={setFinalRate}
                            banknotAllowed={banknotAllowed}
                            cashOnDeliveryNotAllowed={cashOnDeliveryNotAllowed}
                            coinExchangeValue={coinExchangeValue}
                            onlyResidencyProducts={onlyResidencyProducts}
                            membershipAllowed={membershipAllowed}
                            usdRate={usdRate}
                          />
                        ))}
                  </div>
                </div>
              ) : (
                <AccountDetails
                  accountDetails={localBankTransferAccountDetails}
                  coins={"undefined"}
                  totalPrice={totalPrice}
                />
              )}
              <div
                hidden={
                  selectedPaymentMethod !== "Cash on Delievry" ||
                  onlyResidencyProducts
                }
                className="mt-5"
              >
                <div className="billing-Address-title">
                  Billing Address :
                  <div>
                    <input
                      onChange={setShipppingToBilling}
                      type="checkbox"
                      id="reuseShipping"
                      name="reuseShipping"
                      checked={addressReused}
                    />
                    <label
                      htmlFor="reuseShipping"
                      style={{ marginLeft: 4, fontSize: 12 }}
                    >
                      Use shipping address
                    </label>
                  </div>
                </div>
                <div className="billing-separator"></div>

                <div
                  style={{ marginTop: 30 }}
                  className="shipping-form-input-row"
                >
                  <div className="shipping-form-input-label">
                    {shippingCartTranslation?.firsrtName
                      ? shippingCartTranslation?.firsrtName
                      : "First Name :"}{" "}
                    <sup className={error ? "text-danger" : ""}>*</sup>
                  </div>
                  <input
                    className="shipping-form-input-input"
                    name="firstname"
                    value={billingAdress.firstname}
                    onChange={handleShippingInfoInput}
                  />
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    {shippingCartTranslation?.lastName
                      ? shippingCartTranslation?.lastName
                      : "Last Name :"}{" "}
                    <sup className={error ? "text-danger" : ""}>*</sup>
                  </div>
                  <input
                    className="shipping-form-input-input"
                    name="lastname"
                    value={billingAdress.lastname}
                    onChange={handleShippingInfoInput}
                  />
                </div>

                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    {shippingCartTranslation?.houseNo
                      ? shippingCartTranslation?.houseNo
                      : "House/ Apartment Number :"}{" "}
                    <sup className={error ? "text-danger" : ""}>*</sup>
                  </div>
                  <input
                    className="shipping-form-input-input"
                    name="addressLineOne"
                    value={billingAdress.addressLineOne}
                    onChange={handleShippingInfoInput}
                  />
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    {shippingCartTranslation?.street
                      ? shippingCartTranslation?.street
                      : "Street/ Lane :"}{" "}
                    <sup className={error ? "text-danger" : ""}>*</sup>
                  </div>
                  <input
                    className="shipping-form-input-input"
                    name="addressLineTwo"
                    value={billingAdress.addressLineTwo}
                    onChange={handleShippingInfoInput}
                  />
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    {shippingCartTranslation?.zipCode
                      ? shippingCartTranslation?.zipCode
                      : "Zip Code :"}
                    <sup className={error ? "text-danger" : ""}>*</sup>
                  </div>
                  <input
                    className="shipping-form-input-input"
                    name="zipCode"
                    value={billingAdress.zipCode}
                    onChange={handleShippingInfoInput}
                  />
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    {shippingCartTranslation?.country
                      ? shippingCartTranslation?.country
                      : "Country :"}{" "}
                    <sup className={error ? "text-danger" : ""}>*</sup>
                  </div>
                  <select
                    type="select"
                    className="shipping-form-input-input"
                    name="country"
                    value={billingAdress.country}
                    onChange={handleShippingInfoInput}
                  >
                    <option value="">
                      {shippingCartTranslation?.country
                        ? shippingCartTranslation?.country
                        : "Select Country"}
                    </option>
                    {Country.getAllCountries().map((country, index) => (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    {shippingCartTranslation?.state
                      ? shippingCartTranslation?.state
                      : "State/ Province :"}{" "}
                    <sup className={error ? "text-danger" : ""}>*</sup>
                  </div>
                  <select
                    type="select"
                    className="shipping-form-input-input"
                    name="state"
                    value={billingAdress.state}
                    onChange={handleShippingInfoInput}
                  >
                    <option value="">
                      {shippingCartTranslation?.state
                        ? shippingCartTranslation?.state
                        : "Select State"}
                    </option>
                    {states.map((state, index) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    {shippingCartTranslation?.city
                      ? shippingCartTranslation?.city
                      : "City :"}{" "}
                    <sup className={error ? "text-danger" : ""}>*</sup>
                  </div>
                  <select
                    type="select"
                    className="shipping-form-input-input"
                    name="city"
                    value={billingAdress.city}
                    onChange={handleShippingInfoInput}
                  >
                    <option value="">
                      {shippingCartTranslation?.city
                        ? shippingCartTranslation?.city
                        : "Select City"}
                    </option>
                    {cities.map((city, index) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="checkout-next-button-container">
                {onlyResidencyProducts || onlyDigitalProducts ? (
                  ""
                ) : (
                  <button
                    onClick={() => {
                      moveStep(1);
                    }}
                    className="checkout-next-button"
                  >
                    {logInTranslation?.backLink
                      ? logInTranslation?.backLink
                      : "Back"}
                  </button>
                )}

                <div style={{ width: 20 }}></div>
                <button
                  hidden={selectedPaymentMethod !== "Paypal"}
                  className="checkout-next-button paypal-button"
                >
                  Buy with Paypal
                </button>
                <button
                  hidden={selectedPaymentMethod === "Paypal"}
                  onClick={makePayment}
                  className="checkout-next-button"
                  disabled={loading}
                >
                  {myCartTranslation?.checkOut
                    ? myCartTranslation?.checkOut
                    : "Checkout"}
                </button>
              </div>
            </Col>
            <Col xs="0" lg="1" />
          </Row>
        </Col>
        <Col xs="12" lg="5">
          <Row style={{ marginBottom: 30 }}>
            <Col xs="0" lg="1" />
            <Col xs="12" lg="11">
              <Summary
                pricingBreakdown={pricingBreakdown}
                yinnPercentage={checkoutPercentage}
                totalPrice={totalPrice}
                paymentData={""}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="0" lg="1" />
            <Col xs="12" lg="11">
              <ProductSummary {...props} />
            </Col>
          </Row>
        </Col>

        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalBody className="payament-modal-container">
            <div className="payament-modal-title-container">
              <div style={{ display: "flex" }}>
                <img
                  src={clickedPaymentMethod.imgSrc}
                  style={{ marginRight: 18 }}
                />
                <div className="payament-modal-title">
                  {paymentInfoTranslation?.lbt
                    ? paymentInfoTranslation?.lbt
                    : `${clickedPaymentMethod.name}`}
                </div>
              </div>
              <img
                src={modalCloseIcon}
                alt="close"
                style={{ cursor: "pointer" }}
                onClick={toggle}
              />
            </div>
            <div className="payment-modal-content">
              <div className="shipping-form-input-row">
                <div className="shipping-form-input-label">
                  {shippingCartTranslation?.firsrtName
                    ? shippingCartTranslation?.firsrtName
                    : "First Name :"}
                </div>
                <input
                  className="shipping-form-input-input"
                  name="firstName"
                  type="text"
                  min={0}
                  value={localBankTransferinfo.firstName}
                  onChange={handlePaymentDataInput}
                />
              </div>
              <div className="shipping-form-input-row">
                <div className="shipping-form-input-label">
                  {shippingCartTranslation?.lastName
                    ? shippingCartTranslation?.lastName
                    : "Last Name :"}
                </div>
                <input
                  className="shipping-form-input-input"
                  name="lastName"
                  type="text"
                  min={0}
                  value={localBankTransferinfo.lastName}
                  onChange={handlePaymentDataInput}
                />
              </div>
              <div className="shipping-form-input-row">
                <div className="shipping-form-input-label">
                  {shippingCartTranslation?.country
                    ? shippingCartTranslation?.country
                    : "Country :"}
                </div>
                <select
                  type="select"
                  className="shipping-form-input-input"
                  name="country"
                  onChange={handlePaymentDataInput}
                >
                  <option value="">
                    {shippingCartTranslation?.country
                      ? shippingCartTranslation?.country
                      : "Select Country"}
                  </option>
                  {bluesnapSupported?.map((obj, index) => (
                    <option key={index} value={obj?.shoppersCountry}>
                      {obj.shoppersCountry}
                    </option>
                  ))}
                </select>
              </div>
              <div className="shipping-form-input-row">
                <div className="shipping-form-input-label">
                  {myCartTranslation?.currency
                    ? myCartTranslation?.currency
                    : "Currency :"}
                </div>
                <select
                  type="select"
                  className="shipping-form-input-input"
                  name="currency"
                  value={localBankTransferinfo.currency}
                  onChange={handlePaymentDataInput}
                >
                  <option value="">
                    {myCartTranslation?.currency
                      ? myCartTranslation?.currency
                      : "Select Currency"}
                  </option>
                  <option value={currency}>{currency}</option>
                </select>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={addCardInfo} className="checkout-next-button">
                {myCartTranslation?.checkOut
                  ? myCartTranslation?.checkOut
                  : "Checkout"}
              </button>
            </div>
          </ModalBody>
        </Modal>
      </Row>
    </>
  );
};

const PaymentMethod = (props) => {
  const {
    name,
    onClick,
    selected,
    imgSrc,
    isCashAllowed,
    finalRate,
    setFinalRate,
    banknotAllowed,
    cashOnDeliveryNotAllowed,
    coinExchangeValue,
    onlyResidencyProducts,
    membershipAllowed,
    usdRate,
  } = props;

  const [paymentMethodState, setPaymentMethodState] = useState(paymentMethods);
  const { cart, paymentConfiguration } = useSelector((state) => state.auth);
  const [totalYinn, setTotalYinn] = useState(0);

  const disabled =
    isCashAllowed || (membershipAllowed && name === "Cash on Delievry");

  useEffect(() => {
    const salesPrice =
      cart.reduce((a, b) => a + b.product?.unitPrice * b.qty, 0) * 1; // default set to "USD" currency rate i.e. "1"
    const shippingPrice =
      cart.reduce((a, b) => a + b.product?.shippingCharges * b.qty, 0) * 1; // default set to "USD" currency rate i.e. "1"
    const yinnPercentage =
      paymentConfiguration?.yinncoinPurchasePercentageCharge * salesPrice;
    const finalAmount =
      (coinExchangeValue / 1) * // default set to "USD" currency rate i.e. "1"
      (salesPrice +
        shippingPrice +
        Number(yinnPercentage) +
        paymentConfiguration.checkoutPaymentFixCharge);
    setFinalRate(Number(finalAmount).toFixed(2));
    setTotalYinn(Number(finalAmount).toFixed(2));
  }, [usdRate, selected]);

  return (
    <div
      className={
        disabled ||
        (banknotAllowed && name === "Local Bank Transfer") ||
        (cashOnDeliveryNotAllowed && name === "Cash on Delievry") ||
        onlyResidencyProducts
          ? "payment-method-container-disabled"
          : ""
      }
    >
      <div
        className={
          !selected
            ? "payment-method-container"
            : "payment-method-container-selected"
        }
        onClick={
          disabled ||
          banknotAllowed ||
          (cashOnDeliveryNotAllowed && name === "Cash on Delievry") ||
          onlyResidencyProducts
            ? () => {}
            : onClick
        }
      >
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <div
            className={
              !selected
                ? "payment-method-radio"
                : "payment-method-radio-selected"
            }
          ></div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              fontSize: 13,
              font: "Roboto",
            }}
          >
            <div>
              {name}
              {selected && name === "Yinn Coin" && (
                <div> Pay {finalRate} coins </div>
              )}
              {disabled && (
                <div style={{ fontSize: 10 }}>
                  Not available for 'Freemium' sellers
                </div>
              )}
              {(banknotAllowed && name === "Local Bank Transfer") ||
                (cashOnDeliveryNotAllowed && name === "Cash on Delievry" && (
                  <div style={{ fontSize: 10 }}>Not Allowed By sellers</div>
                ))}
            </div>
          </div>
        </div>
        <img style={{ width: 58, height: 34 }} src={imgSrc} />
      </div>
    </div>
  );
};

export default Payment;
