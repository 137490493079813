import React, { Component, useEffect, useRef, useState } from "react";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBlock,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./cust.css";
import SkyLight from "react-skylight";
import Env from "../../environment/environment";
import Header from "../../token/token";
import moment from "moment";
import cookie from "react-cookies";
import { AppSwitch } from "@coreui/react";
import { useSelector } from "react-redux";
import { addFilter, getCustomers } from "../../redux/reducers/seller";
import { useDispatch } from "react-redux";
import { nextPagesItems } from "../../helpers/custom";
import { deleteSeller, updateUser } from "../../redux/reducers/admin";

var divStyle = {
  marginLeft: "81%",
};

// https://daveceddia.com/open-modal-in-react/

const Cust = () => {
  const { user } = useSelector((state) => state.auth);
  const deleteSellerModal = useRef(null);
  const [selectedId, setSelectedId] = useState(0);
  const { allCustomers, count, filters, loading } = useSelector(
    (state) => state.seller
  );

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const customerTranslation =
    translationState?.[0]?.translations?.customer?.[userLanguage];
  const sellerHomeTranslation =
    translationState?.[0]?.translations?.sellerHome?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const sellerTranslation =
    translationState?.[0]?.translations?.seller?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];

  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    setSelectedPerPage(5);
    dispatch(getCustomers());
  }, []);

  var myBigGreenDialog = {
    width: "40%",
    minHeight: "20%",
  };
  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getCustomers());
  };
  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getCustomers());
    }
  };

  const deleteModal = (id) => {
    setSelectedId(id);
    deleteSellerModal.current.show();
  };
  const deleteItem = () => {
    dispatch(deleteSeller(selectedId)).then(() => {
      deleteSellerModal.current.hide();
      dispatch(getCustomers());
    });
  };

  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getCustomers());
  };

  const updateStatus = (userId, isActive) => {
    const data = {
      userId,
      isActive,
    };
    dispatch(updateUser(data)).then(() => {
      dispatch(getCustomers());
    });
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={deleteSellerModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>
                {customerTranslation?.skylightOne
                  ? customerTranslation?.skylightOne
                  : "Are you sure you want to delete this Seller?"}
              </h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            {customerTranslation?.yes ? customerTranslation?.yes : "Yes"}
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            onClick={() => deleteSellerModal.current.hide()}
            color="danger"
          >
            {customerTranslation?.no ? customerTranslation?.no : "No"}
          </Button>
        </Row>
      </SkyLight>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="9">
                  <b>
                    {customerTranslation?.customer
                      ? customerTranslation?.customer
                      : "Customers List"}
                  </b>
                </Col>
                {/* {user?.userType == "admin" && (
                  <Col xs="12" sm="2">
                    <Button
                      type="submit"
                      size="sm"
                      style={divStyle}
                      color="warning"
                      onClick={() =>history.push('/admin/customer/add')}
                    >
                      {" "}
                      <b>Add Seller</b>{" "}
                    </Button>
                  </Col>
                )} */}
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              {user?.userType == "admin" && (
                <Row>
                  <Col xs="12" sm="4">
                    <InputGroup>
                      <Input
                        type="text"
                        id="input1-group2"
                        onChange={searchElement}
                        className="search-color"
                        name="input1-group2"
                        placeholder="Seller Name/Email"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <br />
              {user?.userType == "admin" && (
                <div className="tableFixHead">
                  <Table hover bordered striped responsive size="sm">
                    <thead align="center" style={{ position: "sticky" }}>
                      <tr className="header-color">
                        <th> Name</th>
                        <th> Email Address</th>
                        <th> Seller ID</th>
                        <th> Phone</th>
                        <th> Joining Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <td colSpan="8" className="py-5">
                          <div style={{ textAlign: "center" }}>
                            <i
                              className="fas fa-spinner fa-pulse fa-2x"
                              style={{ color: "green" }}
                            ></i>
                          </div>
                        </td>
                      ) : allCustomers?.length > 0 && !loading ? (
                        allCustomers?.map((item, index) => (
                          <tr hidden={item._id.is_staff === false}>
                            <td className="pl-2">
                              {item._id.firstname + " " + item._id.lastname ||
                                "NA"}
                            </td>
                            <td className="pl-2">{item._id.email}</td>
                            <td className="pl-2">{item._id._id}</td>
                            <td className="pl-2">{item._id.phone || "NA"}</td>
                            <td className="pl-2">
                              {moment(item._id.createdAt).format(
                                "MMM D YYYY , hh:m A"
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <p style={{ color: "red" }}>
                          {walletTranslation?.notFound
                            ? walletTranslation?.notFound
                            : "Records Not Found"}
                        </p>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
              {user?.userType == "seller" && (
                <Row>
                  <Col xs="12" sm="4">
                    <InputGroup>
                      <Input
                        type="text"
                        id="input1-group2"
                        onChange={searchElement}
                        className="search-color"
                        name="input1-group2"
                        placeholder={
                          reportingTranslation?.customerName
                            ? reportingTranslation?.customerName +
                              "/" +
                              reportingTranslation?.customerEmail
                            : "Customer Name/Email"
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <br />
              {user?.userType == "seller" && (
                <div className="tableFixHead">
                  <Table hover bordered striped responsive size="sm">
                    <thead align="center" style={{ position: "sticky" }}>
                      <tr className="header-color">
                        <th>
                          {customerTranslation?.serialNO
                            ? customerTranslation?.serialNO
                            : "Sr.no"}
                        </th>
                        <th>
                          {" "}
                          {shippingCartTranslation?.firsrtName
                            ? shippingCartTranslation?.firsrtName
                            : "First Name "}
                        </th>
                        <th>
                          {" "}
                          {shippingCartTranslation?.lastName
                            ? shippingCartTranslation?.lastName
                            : "Last Name"}{" "}
                        </th>
                        <th>
                          {" "}
                          {sellerHomeTranslation?.email
                            ? sellerHomeTranslation?.email
                            : "Email Address "}
                        </th>
                        <th>
                          {" "}
                          {sellerTranslation?.Orders
                            ? sellerTranslation?.Orders
                            : "Orders Count"}
                        </th>
                        <th>
                          {" "}
                          {sellerHomeTranslation?.phone
                            ? sellerHomeTranslation?.phone
                            : "Phone"}{" "}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {allCustomers?.length > 0 ? (
                        allCustomers?.map((item, index) => (
                          <tr
                            hidden={
                              item._id.is_staff === true ||
                              item._id.is_superuser === true
                            }
                          >
                            <td align="center">{index + 1}</td>
                            <td>{item._id.firstname || "NA"}</td>
                            <td>{item._id.lastname || "NA"}</td>
                            <td>{item._id.email}</td>
                            <td align="center">{item.orderCount}</td>
                            <td align="center">{item._id.phone || "NA"}</td>
                          </tr>
                        ))
                      ) : (
                        <p style={{ color: "red" }}>
                          {walletTranslation?.notFound
                            ? walletTranslation?.notFound
                            : "Records Not Found"}
                        </p>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
              <nav className="mt-3">
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo - 1)}
                      disabled={pageNo === 1 ? true : false}
                      style={{
                        backgroundColor:
                          (pageNo === 1 ? true : false) && "#e4e7ea",
                      }}
                    >
                      {reportingTranslation?.previousButton
                        ? reportingTranslation?.previousButton
                        : "Prev"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => paginate(pageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: !clickableNext ? "#e4e7ea" : null,
                      }}
                      className="mx-2"
                    >
                      {reportingTranslation?.nextButton
                        ? reportingTranslation?.nextButton
                        : "Next"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        {productTranslation?.itemPerPage
                          ? productTranslation?.itemPerPage
                          : "Items per Page"}
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, pageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Cust;
