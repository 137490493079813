import React, { useState, useEffect } from "react";
import "./Orders.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, addFilter } from "../../redux/reducers/seller";
import "./Download.css";

const Downloads = () => {
  const { orders, loading } = useSelector((state) => state.seller);
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];

  useEffect(() => {
    dispatch(
      addFilter({
        field: "productType",
        value: "digital",
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 100,
      })
    );
    dispatch(getOrders());
  }, [dispatch]);

  const downloadProduct = (productUrl) => {
    window.open(productUrl);
  };

  return (
    <>
      <div className="main-row">
        <div className="row row-gap">
          {loading ? (
            <div className="col-12 d-flex justify-content-center align-items-center">
              <i
                className="fas fa-spinner fa-pulse fa-2x"
                style={{ color: "green", textAlign: "center" }}
              ></i>
            </div>
          ) : orders?.length ? (
            orders?.map((order) => {
              return order?.products?.map((item, index2) => {
                if (item.product)
                  // filter empty products due to buggy api getOrder() digital
                  return (
                    <div className="col-sm-6 col-xl-4 col-12" key={index2}>
                      <div className="download-box-card">
                        <img
                          className="card-img-top card-img-margin"
                          src={item?.product?.images[0]}
                          alt="Card image cap"
                        />
                        <div className="ml-3">
                          <h5 className="card-title card-title-margin">
                            {item?.product?.name}
                          </h5>
                        </div>
                        <div style={{ padding: "10px" }}>
                          <p className="download-card-text ml-2">
                            {item?.product?.specification?.blocks[0]?.text}
                          </p>
                        </div>
                        <div>
                          <button
                            className="button-color ml-3 mb-3"
                            onClick={() =>
                              downloadProduct(item?.product?.productUrl)
                            }
                          >
                            {userDashboardTranslation?.download
                              ? userDashboardTranslation?.download
                              : "Download"}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
              });
            })
          ) : (
            <div className="col-12 d-flex justify-content-center align-items-center">
              <p style={{ color: "red" }}>
                {walletTranslation?.notFound
                  ? walletTranslation?.notFound
                  : "Records Not Found"}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Downloads;
