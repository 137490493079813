import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { getSingleProduct } from "../../../../redux/reducers/seller";
import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";
import moment from "moment";
import "./viewproduct.css";

const ViewProduct = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  useEffect(() => {
    dispatch(getSingleProduct(productId));
  }, []);
  const { singleProduct, loading } = useSelector((state) => state.seller);
  const convertCommentFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw({ entityMap: {}, blocks: text.blocks }));
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <b>
                <i> Product Details</i>
              </b>
            </CardHeader>
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <i
                  className="fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green", margin: "240px" }}
                ></i>
              </div>
            ) : (
              <CardBody className="card-body">
                <div className="row">
                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b>Product Name</b>
                    </Label>
                    <br />
                    {singleProduct.name}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b>Brand</b>
                    </Label>
                    <br />
                    {singleProduct.brand}
                  </section>
                </div>
                <br />

                <div className="row">
                  <section className="col-md-12">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Product Specification</b>
                    </Label>

                    <div id="comment-div">
                      {singleProduct && singleProduct.specification && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCommentFromJSONToHTML(
                              singleProduct.specification
                            ),
                          }}
                        />
                      )}
                    </div>
                  </section>
                </div>

                <div className="row">
                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Regular Price</b>
                    </Label>
                    <br />
                    {singleProduct.regularPrice}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Cost</b>
                    </Label>
                    <br />
                    {singleProduct.cost}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Dimension Unit</b>
                    </Label>
                    <br />
                    {singleProduct.dimensionUnit}
                  </section>
                </div>
                <div className="row">
                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Width</b>
                    </Label>
                    <br />
                    {singleProduct.width}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Depth</b>
                    </Label>
                    <br />
                    {singleProduct.depth}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Height</b>
                    </Label>
                    <br />
                    {singleProduct.height}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Girth</b>
                    </Label>
                    <br />
                    {singleProduct.girth}
                  </section>
                </div>
                <br />
                <hr />
                {singleProduct?.attributes?.length > 0 ? (
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col xs="12" sm="12">
                          <b>Product Attributes</b>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="card-body">
                      <Row>
                        {singleProduct?.attributes?.length > 0
                          ? singleProduct?.attributes?.map((item, index) => (
                              <section className="col-md-3">
                                <Label htmlFor="appendedPrependedInput">
                                  <b> {item.name}</b>
                                </Label>
                                <br />
                              </section>
                            ))
                          : ""}
                      </Row>
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )}
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="12" sm="12">
                        <b>Product variants</b>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="card-body">
                    {singleProduct?.attributes?.length > 0 ? (
                      <Table hover bordered striped responsive size="sm">
                        <thead>
                          <tr className="header-color">
                            <th>Variant</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {singleProduct?.attributes?.length > 0
                            ? singleProduct?.attributes?.map((item, index) =>
                                item.variants.map((j) => (
                                  <tr>
                                    <td>{item.name}</td>
                                    <td>{j}</td>
                                  </tr>
                                ))
                              )
                            : ""}
                        </tbody>
                      </Table>
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
                {singleProduct?.priceTier?.length > 0 ? (
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col xs="12" sm="12">
                          <b>Price tier</b>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="card-body">
                      <Table hover bordered striped responsive size="sm">
                        <thead>
                          <tr className="header-color">
                            <th>Quantity</th>
                            <th>Discount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {singleProduct?.priceTier?.length > 0
                            ? singleProduct?.priceTier?.map((item, index) => (
                                <tr>
                                  <td>{item.quantity} and above</td>
                                  <td>{item.discount}%</td>
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )}
                <div className="row">
                  <section className="col-md-12">
                    <Label htmlFor="appendedPrependedInput" className="title">
                      <b>
                        <i> Images</i>
                      </b>
                    </Label>
                  </section>
                </div>
                <br />

                <div className="row">
                  <Col xs="12" sm="12">
                    <section className="col-md-3">
                      {singleProduct?.images?.length > 0
                        ? singleProduct?.images?.map((item, index) => (
                            <img
                              src={item}
                              width="50"
                              height="50"
                              className="image-padding"
                            />
                          ))
                        : ""}
                    </section>
                  </Col>
                </div>
                <br />
                <hr />

                <div className="row">
                  <section className="col-md-12">
                    <Label htmlFor="appendedPrependedInput" className="title">
                      <b>
                        <i> Categories</i>
                      </b>
                    </Label>
                  </section>
                </div>
                <br />

                <div className="row">
                  <CardBody className="card-body category-body">
                    <FormGroup>
                      <div class="row">
                        <div class="column" className="category-padding">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Main category
                          </div>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Col xs="12" sm="8">
                              <Label htmlFor="appendedPrependedInput">
                                {singleProduct?.mainCategory?.name}
                              </Label>
                            </Col>
                          </Row>
                        </div>
                        <div class="column" className="category-padding">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                            }}
                          >
                            {singleProduct?.category?.length > 0
                              ? "Sub category"
                              : null}
                          </div>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Col xs="12" sm="8">
                              <Label htmlFor="appendedPrependedInput">
                                {singleProduct?.subCategory?.name}
                              </Label>
                            </Col>
                          </Row>
                        </div>
                        <div class="column" className="category-padding">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                              paddingRight: "20%",
                            }}
                          >
                            {singleProduct?.category?.length > 0
                              ? "Sub Sub category"
                              : null}
                          </div>
                          {singleProduct?.category?.length > 0
                            ? singleProduct?.category?.map((item, index) => (
                                <div>
                                  <div className="dd-wrapper">
                                    <div className="dd-header">
                                      <div className="dd-header-title"></div>
                                    </div>
                                    <Col xs="12" sm="12">
                                      <Label htmlFor="appendedPrependedInput">
                                        {item?.name}
                                      </Label>
                                    </Col>
                                  </div>
                                </div>
                              ))
                            : "No Category Found "}
                        </div>
                      </div>
                    </FormGroup>
                  </CardBody>
                </div>
                <br />
                <hr />
                <div className="row">
                  <section className="col-md-12">
                    <Label htmlFor="appendedPrependedInput" className="title">
                      <b>
                        <i> Inventory</i>
                      </b>
                    </Label>
                  </section>
                </div>
                <br />

                <div className="row">
                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Stock Quantity</b>
                    </Label>
                    <br />
                    {singleProduct.stockQuantity}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Unit Weight</b>
                    </Label>
                    <br />
                    {singleProduct.unitWeight}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Total Unit Weight</b>
                    </Label>
                    <br />
                    {singleProduct.totalUnitWeight}
                  </section>

                  <section className="col-md-3">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Total Quantity Cost</b>
                    </Label>
                    <br />
                    {singleProduct.totalQuantityCost}
                  </section>
                </div>
                <br />

                <div className="row">
                  <section className="col-md-12">
                    <Label htmlFor="appendedPrependedInput">
                      <b> Description</b>
                    </Label>
                    <br />
                    {singleProduct.description}
                  </section>
                </div>
                <br />

                <div className="row">
                  <section className="col-md-6">
                    <Label htmlFor="appendedPrependedInput">
                      <b>Shipping Charges</b>
                    </Label>
                    <br />
                    {singleProduct.shippingCharges}
                  </section>

                  <section className="col-md-6">
                    <Label htmlFor="appendedPrependedInput">
                      <b> weight unit</b>
                    </Label>
                    <br />
                    {singleProduct.weightUnit}
                  </section>
                </div>
                <br />
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewProduct;
