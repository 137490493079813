import React, { useEffect, useState, useRef } from "react";
import {
  InputGroup,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SkyLight from "react-skylight";
import "react-toastify/dist/ReactToastify.css";
import {
  getCmsPages,
  addFilter,
  deletePages,
  updateCMSPage,
} from "../../redux/reducers/admin";
import { AppSwitch } from "@coreui/react";
import "./cms.css";
import { nextPagesItems } from "../../helpers/custom";
var divStyle = {
  marginLeft: "10%",
};
const myDeleteDialog = {
  minHeight: "130px",
};
const CMS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const deleteCategoryModal = useRef(null);
  const { cmspages, filters, loading, count } = useSelector(
    (state) => state.admin
  );
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [selectedId, setSelectedId] = useState(0);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(getCmsPages());
  }, []);
  const updatePage = (pageId, publish) => {
    const data = {
      pageId,
      publish,
    };
    dispatch(updateCMSPage(data));
    dispatch(getCmsPages());
  };
  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getCmsPages());
  };
  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(getCmsPages());
    }
  };
  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getCmsPages());
  };
  const editPage = (id) => {
    history.push(`/admin/content-management/update-page?pageId=${id}`);
  };
  const showPage = (id) => {
    history.push(`/pages/${id}`);
  };
  const deleteModal = (id) => {
    setSelectedId(id);
    deleteCategoryModal.current.show();
  };
  const deleteItem = () => {
    dispatch(deletePages(selectedId)).then(() => {
      deleteCategoryModal.current.hide();
      dispatch(getCmsPages());
    });
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myDeleteDialog}
        hideOnOverlayClicked
        ref={deleteCategoryModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>Are you sure you want to delete?</h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            Yes
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            onClick={() => deleteCategoryModal.current.hide()}
            color="danger"
          >
            No
          </Button>
        </Row>
      </SkyLight>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <div
                  className="d-flex justify-content-between  align-items-center"
                  style={{ width: "100%" }}
                >
                  <div>
                    <b>Pages</b>
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        history.push("/admin/content-management/page")
                      }
                      size="md"
                      color="warning"
                    >
                      <b>Add Page</b>
                    </Button>
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              <Row>
                <Col xs="12" sm="4">
                  <InputGroup>
                    <Input
                      type="text"
                      id="input1-group2"
                      onChange={searchElement}
                      className="search-color"
                      name="input1-group2"
                      placeholder=" Search Pages"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color">
                    <th> Page Title </th>
                    <th> Category </th>
                    <th> Created Date </th>
                    <th> Publish </th>
                    <th> Action </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={5}>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : cmspages.length > 0 && !loading ? (
                    cmspages.map((page, index) => (
                      <tr key={index}>
                        <td> {page.title} </td>
                        <td> {page.cmsCategoryId?.categoryName} </td>
                        <td>
                          {moment(page.createdAt).format("MMM D YYYY , hh:m A")}
                        </td>
                        <td align="center">
                          {
                            <AppSwitch
                              className={"mx-1"}
                              variant={"pill"}
                              color={"primary"}
                              name="publish"
                              onChange={() =>
                                updatePage(page._id, !page.publish)
                              }
                              checked={page.publish}
                            />
                          }
                        </td>
                        <td align="center">
                          <i
                            className="fa fa-edit fa-lg mt-4"
                            onClick={(e) => editPage(page._id)}
                          ></i>
                          &nbsp;
                          <i
                            className="fa fa-trash fa-lg mt-4"
                            onClick={(e) => deleteModal(page._id)}
                          ></i>
                          &nbsp;
                          <i
                            className="fa fa-eye fa-lg mt-4"
                            onClick={(e) => showPage(page._id)}
                          ></i>
                          &nbsp;
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={5} className="py-5 text-center">
                      <p style={{ color: "red" }}>Records Not Found</p>
                    </td>
                  )}
                </tbody>
              </Table>
              <nav>
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo - 1)}
                      disabled={pageNo === 1 ? true : false}
                      style={{
                        backgroundColor: "#E4E7EA",
                      }}
                    >
                      Prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: "#E4E7EA",
                      }}
                      className="mx-2"
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        Items per Page
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, pageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default CMS;
