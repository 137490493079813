import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SetState,
  singleSiteInfo,
  updateSiteInfo,
} from "../../../redux/reducers/admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

const EditInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { singleInfo, loading } = useSelector((state) => state.admin);
  const chargeId = new URLSearchParams(history.location.search).get("chargeId");
  const [infoObj, setInfoObj] = useState({});
  useEffect(() => {
    dispatch(singleSiteInfo(chargeId));
  }, []);
  useEffect(() => {
    if (singleInfo) {
      setInfoObj({ ...singleInfo });
    }
  }, [singleInfo]);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setInfoObj({
      ...infoObj,
      [name]: value,
    });
  };
  const editPersonalInfo = () => {
    const { contact1, contact2, email1, email2, address } = infoObj;
    if (!contact1 || !email1 || !address) {
      toast.error("Kindly fill required fields");
      return;
    }
    const data = {
      chargeId,
      contact1,
      contact2,
      email1,
      email2,
      address,
    };
    dispatch(updateSiteInfo(data)).then(() =>
      history.push("/admin/personalInfo")
    );
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col xs="12" lg="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <i className="fa fa-align-justify"></i>
                  <strong> Edit Site Info </strong>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Row>
                  <Col xs="12" sm="5" md="5" lg="5">
                    <Label>
                      <b>Contact Number Primary</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="text"
                      name="contact1"
                      onChange={handleInputChange}
                      placeholder="name"
                      value={infoObj.contact1}
                    />
                    {/* <p className="onerror"> {state.formErrors.contact1}</p> */}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col xs="12" sm="5" md="5" lg="5">
                    <Label>
                      <b>Contact Number Secondary</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="text"
                      name="contact2"
                      onChange={handleInputChange}
                      placeholder="name"
                      value={infoObj.contact2}
                    />
                    {/* <p className="onerror"> {state.formErrors.contact2}</p> */}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col xs="12" sm="5" md="5" lg="5">
                    <Label>
                      <b>Email Primary</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="email"
                      name="email1"
                      onChange={handleInputChange}
                      placeholder="email"
                      value={infoObj.email1}
                    />
                    {/* <p className="onerror"> {state.formErrors.email1}</p> */}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col xs="12" sm="5" md="5" lg="5">
                    <Label>
                      <b>Email Secondary</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="email"
                      name="email2"
                      onChange={handleInputChange}
                      placeholder="email"
                      value={infoObj.email2}
                    />
                    {/* <p className="onerror"> {state.formErrors.email2}</p> */}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col xs="12" sm="5" md="5" lg="5">
                    <Label>
                      <b>Address</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="text"
                      name="address"
                      onChange={handleInputChange}
                      placeholder="address"
                      value={infoObj.address}
                    />
                    {/* <p className="onerror"> {state.formErrors.address}</p> */}
                  </Col>
                </Row>
              </FormGroup>

              <Row>
                <Col xs="12" sm="4" md="4" lg="3"></Col>
                <Col xs="12" sm="4" md="5" lg="4">
                  <Button
                    color="primary"
                    type="submit"
                    // disabled={!state.formValid}
                    className="btn btn-sm"
                    onClick={() => editPersonalInfo()}
                    block
                  >
                    Save
                  </Button>
                </Col>
                <Col xs="12" sm="4" md="4" lg="3"></Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditInfo;
