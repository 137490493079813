import React from "react";

import orderedSrc from "../../assets/img/checkout/tracker-ordered.svg";
import prepSrc from "../../assets/img/checkout/tracker-preping.svg";
import shippingSrc from "../../assets/img/checkout/tracker-delivery.svg";
import homeSrc from "../../assets/img/checkout/tracker-home.svg";
import "./order-tracker.css";
import { useSelector } from "react-redux";

export default function OrderTracker(props) {
  const { translationState } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
    const confirmationTranslation = translationState?.[0]?.translations?.confirmation?.[userLanguage];

  return (
    <div className="order-tracker-container">
      <div className="icon-container">
        <div className="tracker-step-text">{confirmationTranslation?.order ? confirmationTranslation?.order : "Ordered"}</div>
        <img style={{ width: 60, height: 60 }} src={orderedSrc} />
      </div>
      <div className="tracker-line" />
      <div className="icon-container">
        <div className="tracker-step-text">
          {confirmationTranslation?.perparing ? confirmationTranslation?.perparing : "Preparing"}</div>
        <img style={{ width: 60, height: 60 }} src={prepSrc} />
      </div>
      <div className="tracker-line" />

      <div className="icon-container">
        <div className="tracker-step-text">
          {confirmationTranslation?.shipping ? confirmationTranslation?.shipping : "Shipping"}</div>
        <img style={{ width: 60, height: 60 }} src={shippingSrc} />
      </div>
      <div className="tracker-line" />

      <div className="icon-container">
        <div className="tracker-step-text">
          {confirmationTranslation?.delivered ? confirmationTranslation?.delivered : "Delivered"}</div>
        <img style={{ width: 60, height: 60 }} src={homeSrc} />
      </div>
    </div>
  );
}
