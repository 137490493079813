import React from "react";

const AuctionSetting = () => {
  return (
    <>
      <div style={{ marginTop: "90px" }}>
        <div className="my-2">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
            <label class="form-check-label" for="flexCheckChecked">
              <b>
                Get email notification for my auctions ending soon (optional)
              </b>
            </label>
          </div>
        </div>
        <div>
          <button
            className="button-color"
          >
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default AuctionSetting;
