import axios from 'axios';
import Env from '../../../environment/environment';
import cookie from "react-cookies";

const defaultDiscount = async function (discount, discountedPrice) {
    // 
    var product_id = cookie.load('product_id')
    var offerObject = {
        finalPrice: discountedPrice,
        discountPercentage: 0,
        salesPrice: discountedPrice,
        message : "",
        discountId :""
    }
    //for everyOne
    var today = new Date()
    today.setHours(0, 0, 0, 0)
    for (var i in discount) {
        for (var j in discount[i].discountedProducts) {
            var endDate = new Date(discount[i].endDate)
            endDate.setHours(0, 0, 0, 0)
            var startDate = new Date(discount[i].startDate)
            startDate.setHours(0, 0, 0, 0)
            var activeFlag = false;
            if (today >= startDate && today <= endDate) {
                console.log("true")
                activeFlag = true
            } else {
                console.log("false")
                
            }
            if (discount[i].discountedProducts[j].productId == product_id && activeFlag) {
                if (discount[i].eligibilities == "Everyone") {
                    offerObject.finalPrice = (discountedPrice - (discountedPrice * discount[i].discountPercentage) / 100).toFixed(2)
                    offerObject.discountPercentage = discount[i].discountPercentage
                    offerObject.discountId = discount[i]._id
                }
            }
        }

    }
    return Promise.resolve(offerObject);
};

const setQuantityAndPrice = async function (quantity,offerObject,operation) {
    if(operation == "increment"){
        
        offerObject.finalPrice = ((offerObject.finalPrice/(quantity-1))*quantity).toFixed(2)
        offerObject.salesPrice = ((offerObject.salesPrice/(quantity-1))*quantity).toFixed(2)
    }
    if(operation == "decrement"){
        
        offerObject.finalPrice = ((offerObject.finalPrice/(quantity+1))*quantity).toFixed(2)
        offerObject.salesPrice = ((offerObject.salesPrice/(quantity+1))*quantity).toFixed(2)
    }
    
    return offerObject;
};

const ApplyCustomOffer = async function (discount, offerObject) {
    // 
    var product_id = cookie.load('product_id')
    
    //for everyOne
    var today = new Date()
    today.setHours(0, 0, 0, 0)
        // for (var j in discount.discountedProducts) {
            var endDate = new Date(discount.endDate)
            endDate.setHours(0, 0, 0, 0)
            var startDate = new Date(discount.startDate)
            startDate.setHours(0, 0, 0, 0)
            var activeFlag = false;
            if (today >= startDate && today <= endDate) {
                console.log("true")
                activeFlag = true
            } else {
                console.log("false")
                
            }
            if (activeFlag) {
            // if (discount.discountedProducts[j].productId == product_id && activeFlag) {
                // if (discount.eligibilities == "Specific groups of customers") {
                    offerObject.finalPrice = (offerObject.salesPrice - (offerObject.salesPrice * discount.discountPercentage) / 100).toFixed(2)
                    offerObject.discountPercentage = discount.discountPercentage
                    offerObject.message=""
                    offerObject.discountId = discount._id
                // }
            }
        // }
        if(!activeFlag){
            offerObject.message = "Offer expired for this coupon code."
        }
    return Promise.resolve(offerObject);

};

const ApplyCustomOfferVariantWise = async function (offerObject) {
    offerObject.finalPrice = (offerObject.salesPrice - (offerObject.salesPrice * offerObject.discountPercentage) / 100).toFixed(2)
    return offerObject
}

const ApplyPriceTier = async function (priceTier,offerObject,quantity) {
    var temp = {}
    for(var i in priceTier){
        if(priceTier[i].quantity <= quantity){
            temp = priceTier[i];
        }
    }
    offerObject.tier = temp
    return offerObject
}

export { defaultDiscount,setQuantityAndPrice,ApplyCustomOffer,ApplyCustomOfferVariantWise,ApplyPriceTier };