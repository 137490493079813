import React, { useEffect } from "react";
import "./confirmation.css";
import { useHistory } from "react-router-dom";
import confirmationSrc from "../../assets/img/checkout/confirmed-icon.svg";
import OrderTracker from "./OrderTracker";
import { useSelector } from "react-redux";

export default function Confirmation(props) {

  const { translationState } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const confirmationTranslation = translationState?.[0]?.translations?.confirmation?.[userLanguage];

  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const continueShopping = () => {
    history.push("/search");
  };

  const goToMyOrders = (e) => {
    e.preventDefault();
    history.push("/my_orders");
  };

  return (
    <div className="confirmation-container">
      <div className="confirmation-main-title">
        {confirmationTranslation?.hOne ? confirmationTranslation?.hOne : "Your order is Confirmed!"}
        </div>
      <div className="confirmation-sub-title">
      {confirmationTranslation?.pOne ? confirmationTranslation?.pOne : "We will come to you very soon. Stay with us."}
      </div>
      <img className="confirmed-main-icon" src={confirmationSrc} />
      <div className="confirmation-helper-tex">
      {confirmationTranslation?.lineONe ? confirmationTranslation?.lineONe : <p>You can track all your orders <a onClick={goToMyOrders}> here</a></p>}
      </div>
      <OrderTracker />
      <button className="shop-more-button" onClick={continueShopping}>
      {confirmationTranslation?.shopMore ? confirmationTranslation?.shopMore : "Shop More"}
      </button>
    </div>
  );
}
