import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserWallets, addFilter } from "../../redux/reducers/admin";
import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { nextPagesItems } from "../../helpers/custom";

const WalletList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { usersWallets, filters, count, loading } = useSelector(
    (state) => state.admin
  );
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    dispatch(getUserWallets());
  }, []);
  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getUserWallets());
  };

  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getUserWallets());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getUserWallets());
  };
  const goToWalletDetails = (id) => {
    history.push(`/admin/wallet-lists/walletDetails/${id}`);
  };
  return (
    <div className="animated fadeIn">
      <Card className="mx-2 mt-5">
        <CardHeader>
          <Row>
            <Col xs="5" sm="5" md="6" className="p-0">
              <strong className="ml-2">Wallet List</strong>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Row>
            <Col xs="12" sm="4">
              <InputGroup>
                <Input
                  type="text"
                  id="input1-group2"
                  onChange={searchElement}
                  className="search-color"
                  name="input1-group2"
                  placeholder=" Search User Wallet"
                />
              </InputGroup>
            </Col>
          </Row>
          <br />
          <Table hover bordered striped responsive size="sm">
            <thead align="center">
              <tr className="header-color" align="center">
                <th> User Id </th>
                <th> User name</th>
                <th> User type </th>
                <th> Earnings </th>
                <th> Yinn Coins </th>
                <th> View Details </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <td colSpan={5}>
                  <div style={{ textAlign: "center" }}>
                    <i
                      className="fas fa-spinner fa-pulse fa-2x"
                      style={{ color: "green", margin: "80px" }}
                    ></i>
                  </div>
                </td>
              ) : usersWallets.length > 0 ? (
                usersWallets.map((wallet, index) => {
                  return (
                    <tr>
                      <td align="center">{wallet.userId?._id}</td>
                      <td align="center">
                        {wallet.userId?.firstname +
                          " " +
                          wallet.userId?.lastname}
                      </td>
                      <td align="center">{wallet.userId?.userType}</td>
                      <td align="center">$ {wallet.earnings?.toFixed(2)}</td>
                      <td align="center">$ {wallet.coinBalance?.toFixed(2)}</td>
                      <td
                        onClick={() => goToWalletDetails(wallet._id)}
                        align="center"
                      >
                        <i className="fa fa-eye fa-lg mt-4" />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan={5} className="py-5 text-center">
                  <p style={{ color: "red" }}>Records Not Found</p>
                </td>
              )}
            </tbody>
          </Table>
          <nav>
            <Pagination>
              <PaginationItem>
                <PaginationLink
                  onClick={(e) => paginate(pageNo - 1)}
                  disabled={pageNo === 1 ? true : false}
                  style={{
                    backgroundColor: "#e4e7ea",
                  }}
                >
                  Prev
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  onClick={() => paginate(pageNo + 1)}
                  disabled={!clickableNext}
                  style={{
                    backgroundColor: "#e4e7ea",
                  }}
                  className="mx-2"
                >
                  Next
                </PaginationLink>
              </PaginationItem>
              <PaginationItem className={"ml-auto"}>
                <PaginationLink
                  className="rounded"
                  style={{
                    color: "black",
                    hover: false,
                    backgroundColor: "white",
                  }}
                >
                  <div>
                    Items per Page
                    <select
                      className="ml-2"
                      value={selectedPerPage}
                      onChange={(e) => perPageHandler(e, pageNo)}
                    >
                      {itemsPerPageOptions.map((option, i) => (
                        <option key={i} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </CardBody>
      </Card>
    </div>
  );
};

export default WalletList;
