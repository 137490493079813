import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import speech from "../../../narrator";
import {
  addFilter,
  updateAd,
  getProducts,
  getAds,
  getIp,
} from "../../../redux/reducers/auth";
import {
  Button,
  Form,
  Card,
  Label,
  CarouselItem,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Container,
} from "reactstrap";

import "./Home.css";

import cookie from "react-cookies";
import privates from "../../../assets/img/secure-icon.svg";
import useful from "../../../assets/img/useful-icon.svg";
import overlayImg from "../../../assets/img/home/overlay-img.png";
import sell from "../../../assets/img/home/hand.png";
import pot from "../../../assets/img/home/hero-img.png";
import trust from "../../../assets/img/trusted-icon.svg";
import axios from "axios";
import Env from "../../../environment/environment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppHeader, AppSidebarToggler } from "@coreui/react";

import "pure-react-carousel/dist/react-carousel.es.css";
import HomeCarousel from "./Carousel";
import ProductPreviewBox from "./ProductPreviewBox";

import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import Content from "./Content";
import Services from "./Services";
import FeaturedCarouselItem from "./FeaturedCarouselItem";

import { getAllWebContent } from "../../../redux/reducers/user";

var checkedValue = "";
const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const { loading, filters, ads, currentIP } = useSelector(
    (state) => state.auth
  );

  const { translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);
  const webContent = allWebContent?.[0]?.home;
  const homeFeature = allWebContent?.[0]?.homeFeature;
  const homeTranslation =
    translationState?.[0]?.translations?.home?.[userLanguage];
  const homeFeatureTranslation =
    translationState?.[0]?.translations?.homeFeature?.[userLanguage];
  const speak = async () => {
    await speech.speak({
      queue: false,
      voice: "Fiona",
      text: "YINN. Online Transactions, Made Easy. Put access to the digital services and solutions you require right at your fingertips with YINN – the world’s first one-stop-shop for e-commerce, content streaming, and more.",
    });
  };
  useEffect(() => {
    // speak();
    dispatch(getAllWebContent());
    dispatch(
      addFilter({
        field: "perPage",
        value: 100,
      })
    );
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "featured",
        value: true,
      })
    );
    dispatch(getAds());
    dispatch(getIp());
  }, []);
  let toastId = null;
  let animating = false;
  let state = {
    activeIndex: 0,
    category: [],
    display_prod: false,
    products: [],
    search_product: {
      name: "",
      category: {
        name: [],
      },
      brand: [],
      range: [],
      pageno: 0,
      sort: "all",
      fetchedDiscount: false,
      pageNumber: 1,
    },
    brands: [],
    brandsFlag: false,
    categoryFlag: false,
    subCategoryFlag: false,
    rangeFlag: false,
    subcategory: [],
    SearchingFor: "YINN",
    hasMore: true,
    checkedValue: false,
    totalSearchedProducts: 0,
    totalProductsShowing: 0,
    publish_data: [],
    items: [],
    showAds: false,
    products: [],
    count: 1,
    currency: "USD",
    currencyIcon: "$",
    exchangeRate: 1,
    active: "true",
    count_clicks: 0,
    ip_address: "",
    ads_data: {},
    ads_id: "",
    seller_id: "",
    wallet: {},
    isOpen: false,
    isLoggedIn: false,
  };

  const componentDidMount = () => {
    var currency = cookie.load("currency");
    var icon = cookie.load("currencyIcon");
    var exchangeRate = cookie.load("exchangeRate");
    if (currency != undefined) {
      //setState({ currency: currency });
      //setState({ currencyIcon: icon });
      //setState({ exchangeRate: exchangeRate });
    }
    axios.get(Env.url + "/product/category/get").then((response) => {
      var cat = response.data;
      axios.get(Env.url + "/product/subcategory/get").then((response) => {
        var sc = response.data;
        var category = [];
        for (var c in cat) {
          var subChild = [];
          for (var s in sc) {
            if (cat[c]["_id"] == sc[s]["category_id"]) {
              var child = [];
              for (var i in sc) {
                if (sc[s]["_id"] === sc[i]["category_id"]) {
                  child.push(sc[i]);
                }
              }
              sc[s]["child"] = child;
              subChild.push(sc[s]);
            }
          }
          cat[c]["sub_category"] = subChild;
          category.push(cat[c]);
        }
        //setState({ category: category });
      });
    });

    axios
      .get(Env.url + "/advertisement/get/promotions/publish/" + state.active, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //setState({ publish_data: response.data });
          if (response.data.length > 0) {
            //setState({ showAds: true });
            prepareAdvertise();
          }
        }
      });

    var user = cookie.load("_id");
    if (user != undefined) {
      //setState({ isLoggedIn: true });
    }
  };

  const prepareAdvertise = () => {
    var items = [];
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    for (var i in state.publish_data) {
      var endDate = new Date(state.publish_data[i].endDate);
      endDate.setHours(0, 0, 0, 0);
      var startDate = new Date(state.publish_data[i].startDate);
      startDate.setHours(0, 0, 0, 0);
      if (startDate < today && endDate < today) {
        var data = {
          isPublished: false,
        };
        axios
          .put(
            Env.url + "/advertisement/update/" + state.publish_data[i]._id,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token"),
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((err) => {
            if (err.response != undefined) {
              if (
                err.response.status == 401 ||
                err.response.status == 400 ||
                err.response.status == 500
              ) {
                window.location = "#/login";
              }
            }
          });
      } else {
        var prepareItems = {
          src: state.publish_data[i].images[0],
          seller_id: state.publish_data[i].seller_id,
          ad_id: state.publish_data[i]._id,
        };
        items.push(prepareItems);
        //setState({ items: items });
      }
    }
  };

  const searchElement = (evt) => {
    if (evt.charCode == 13) {
      var data = state.search_product;
      data.name = evt.target.value;
      data.pageno = 0;
      evt.preventDefault();
      evt.stopPropagation();
      //setState({ search_product: data });
      var text = state.SearchingFor;
      text = data.name;
      //setState({ SearchingFor: text });
      //dynamicSearch(state.search_product)
    } else {
      var data = state.search_product;
      data.name = evt.target.value;
      data.pageno = 0;
      //setState({ search_product: data });
    }
  };

  const search = () => {
    axios
      .post(Env.url + "/product/search/", state.search_product)
      .then((response) => {
        //setState({ products: response.data.products });
        var search_product = state.search_product;
        search_product.name = "";
        //setState({ search_product: search_product });
        //setState({ display_prod: true });
      });
  };

  const onExiting = () => {
    animating = true;
  };

  const onExited = () => {
    animating = false;
  };

  const next = () => {
    if (animating) return;
    const nextIndex =
      state.activeIndex === state.items.length - 1 ? 0 : state.activeIndex + 1;
    //setState({ activeIndex: nextIndex });
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      state.activeIndex === 0 ? state.items.length - 1 : state.activeIndex - 1;
    //setState({ activeIndex: nextIndex });
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    //setState({ activeIndex: newIndex });
  };

  const searchNames = () => {
    const route = `/search?txt=${searchText}`;
    history.push(route);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchNames();
    }
  };
  const searchByCategory = (item, mainIndex, subIndex) => {
    var category = state.category;
    var searchString = "";
    searchString = searchString + "&category=" + item;
    if (mainIndex != undefined) {
      searchString = searchString + "&category=" + category[mainIndex].name;
    }
    if (subIndex != undefined) {
      searchString =
        searchString +
        "&category=" +
        category[mainIndex].sub_category[subIndex].name;
    }

    searchString = searchString + "&sort=all";
    setTimeout(function () {
      window.location = "#/search?" + searchString;
    }, 200);
  };

  const getAdvertisement = async (ad_id) => {
    await axios
      .get(Env.url + "/advertisement/get/promotions/" + ad_id.ad_id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          state.ads_data = res.data[0];
          //setState({ ads_data: state.ads_data });

          // updateAdvertisement(state.ads_data);
          getSellerWallet(state.ads_data);
        }
      })
      .catch((err) => {
        if (err.res != undefined) {
          if (
            err.res.status == 401 ||
            err.res.status == 400 ||
            err.res.status == 500
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const updateAdvertisement = async (ads_data) => {
    if (ads_data.paymentPlan === "Pay per click") {
      var data = {
        count_clicks: ads_data.count_clicks + 1,
      };
      await axios
        .put(Env.url + "/advertisement/update/" + ads_data._id, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // getSellerWallet(ads_data);
            getSellerWalletBalance(state.wallet, ads_data);
          }
        })
        .catch((err) => {
          if (err.response != undefined) {
            if (
              err.response.status == 401 ||
              err.response.status == 400 ||
              err.response.status == 500
            ) {
              window.location = "#/login";
            }
          }
        });
    }
  };

  const postIp = (ip, ads_id, seller_id) => {
    var ip_address = {
      ip_address: ip,
      advertisement_id: ads_id,
    };
    axios
      .post(Env.url + "/ip_address/create", ip_address, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 ||
            err.response.status == 400 ||
            err.response.status == 500
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const getSellerWallet = async (ads_data) => {
    await axios
      .get(Env.url + "/wallet/get/" + ads_data.seller_id)
      .then(function (response) {
        if (response.status === 200) {
          //setState({ wallet: response.data[0] });
          var paymentStatus = state.wallet.balance - ads_data.deduct_per_click;
          if (paymentStatus >= 0)
            // getSellerWalletBalance(state.wallet,ads_data);
            updateAdvertisement(state.ads_data);
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 ||
            err.response.status == 400 ||
            err.response.status == 500
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const getSellerWalletBalance = async (getWallet, ads_data) => {
    var wallet = {
      transaction_type: "DEBIT",
      balance: state.wallet.balance - ads_data.deduct_per_click,
    };
    await axios
      .put(Env.url + "/wallet/update/" + getWallet._id, wallet)
      .then(function (response) {
        if (response.status === 200) {
          window.location = "#/sellerdetail?seller=" + ads_data.seller_id;
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 ||
            err.response.status == 400 ||
            err.response.status == 500
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const loadMore = (direction, num) => {
    var fetchedDiscount = state.fetchedDiscount;
    var that = this;
    if (!fetchedDiscount) {
      var discounts = [];
      axios
        .get(Env.url + "/discount/discounted/products/get", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth_token"),
          },
        })
        .then(
          function (response) {
            if (response.status === 200) {
              //setState({ discounts: response.data });
              state.fetchedDiscount = true;
              //setState({ fetchedDiscount: true });
              if (direction == "next") {
                num = num + 1;
              } else {
                num = num - 1;
              }
              if (num > 0) {
                axios
                  .get(
                    Env.url +
                      "/product/get/newestitem/allitems" +
                      "?pageno=" +
                      num,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("auth_token"),
                      },
                    }
                  )
                  .then(async function (response) {
                    if (response.data.length) {
                      // that.//setState({ pageNumber: num });
                      var data = response.data;

                      for (var i in data) {
                        var currencyWisePrice = (
                          that.state.exchangeRate * data[i].sales_price
                        ).toFixed(2);
                        data[i].sales_price = currencyWisePrice;
                        var discount = await that.applyDiscount(data[i]);
                        if (discount != null) {
                          data[i].discount = discount;
                        }
                      }
                      // that.//setState({ products: data });
                    }
                  })
                  .catch((err) => {
                    if (err.response != undefined) {
                      if (
                        err.response.status == 401 &&
                        err.response.message == "Invalid Token"
                      ) {
                        window.location = "#/login";
                      }
                    }
                  });
              }
            }
          }.bind(that)
        )
        .catch((err) => {
          if (err.response != undefined) {
            if (err.response.status == 401) {
              window.location = "#/login";
            }
          }
        });
    } else {
      if (direction == "next") {
        num = num + 1;
      } else {
        num = num - 1;
      }
      if (num > 0) {
        axios
          .get(
            Env.url + "/product/get/newestitem/allitems" + "?pageno=" + num,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token"),
              },
            }
          )
          .then(async function (response) {
            if (response.data.length) {
              // that.//setState({ pageNumber: num });
              var products = that.state.products;
              var data = response.data;
              //products.concat(data)
              var discount = that.state.discounts;
              for (var i in data) {
                var currencyWisePrice = (
                  that.state.exchangeRate * data[i].sales_price
                ).toFixed(2);
                data[i].sales_price = currencyWisePrice;
                var discount = await that.applyDiscount(data[i]);
                if (discount != null) {
                  data[i].discount = discount;
                }
                products.push(data[i]);
              }

              // that.//setState({ products: products });
            }
          })
          .catch((err) => {
            if (err.response != undefined) {
              if (
                err.response.status == 401 &&
                err.response.message == "Invalid Token"
              ) {
                window.location = "#/login";
              }
            }
          });
      }
    }
  };

  const applyDiscount = async (data) => {
    var discount = state.discounts;
    var tempDiscount = null;
    for (var p in discount) {
      for (var q in discount[p].discountsApplicable) {
        if (discount[p].eligibilities == "Everyone") {
          if (discount[p].discountsApplicable[q]._id == data._id) {
            tempDiscount = discount[p];
          }
        }
      }
    }
    return tempDiscount;
  };
  const viewProduct = (item, user) => {
    const ip = item?.ips?.findIndex((ip) => ip === currentIP);
    let id = 0;
    if (user !== null) {
      id = item?.userIds?.findIndex((id) => id === user?._id);
    }
    if (ip === -1 || id === -1) {
      const data = {
        productId: item?.productId?._id,
        currentIP,
        userId: user?._id || null,
      };
      dispatch(updateAd(data)).then(() => dispatch(getAds()));
    }
    history.push(
      `/${
        item?.productId?.type == "digital" ? "digitalproduct" : "productdetail"
      }/${item?.productId?._id}`
    );
  };

  const sliderItems = ads?.slice(0, 5) || [];

  const Featured = state?.items?.map((item) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item?.src}>
        <Row>
          <Col xs="1" sm="1" md="1"></Col>
          <Col xs="10" sm="10" md="10">
            <Card style={{ height: "250px" }}>
              <img
                style={{
                  display: "block",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                src={item.src}
                onClick={(e) => storePage(e, item?.seller_id, item)}
              />
            </Card>
          </Col>
          <Col xs="1" sm="1" md="1"></Col>
        </Row>
      </CarouselItem>
    );
  });

  return (
    <div
      style={{ marginLeft: "0px !important", marginRight: "0px !importtant" }}
    >
      <ToastContainer autoClose={20000} />

      <AppHeader style={{ borderBottom: 0 }} fixed>
        <DefaultHeader />
      </AppHeader>

      <div hidden={state.display_prod}>
        {/* Hero area */}
        <div style={{ background: "#000" }} className="position-relative">
          <Container fluid>
            <Row className="hero-area">
              <Col
                xs="12"
                md="6"
                className="d-flex mt-5 mt-md-0 align-items-center text-center text-md-left left-section pl-md-5 content-padding"
              >
                <div>
                  <div className="name p-small font-weight-light">
                    <svg style={{ width: 55, height: 5 }}>
                      <line
                        x1="0"
                        y1={0}
                        x2={35}
                        y2="0"
                        style={{ stroke: "#fffd00", strokeWidth: 2 }}
                      />
                    </svg>
                    YINN
                  </div>
                  <div className="header-font font-weight-normal mt-1 mb-4">
                    {homeTranslation?.headingOne
                      ? homeTranslation?.headingOne
                      : webContent?.headingOne}
                    {/* {t("home.onlineTransactions")} */}
                  </div>
                  <div
                    className="p-small font-weight-light content-width"
                    style={{ color: "#929292" }}
                  >
                    {/* {t("home.onlineTransactions1")} */}
                    {homeTranslation?.paraOne
                      ? homeTranslation?.paraOne
                      : webContent?.paraOne}
                    {/* Put access to the digital services and solutions you require
                    right at your fingertips with YINN – the world’s first
                    one-stop-shop for e-commerce, content streaming, and more. */}
                  </div>
                  <div className="mt-5">
                    <button
                      className="btn black-bg-btn button-text"
                      onClick={() => history.push("/auth/login")}
                      style={{
                        border: "1px solid #fff",
                        padding: "0.6rem 2.5rem",
                      }}
                    >
                      {homeTranslation?.buttonOne
                        ? homeTranslation?.buttonOne
                        : webContent?.buttonOne}
                      {/* {t("home.startButton")} */}
                    </button>
                  </div>
                </div>
              </Col>
              <Col
                xs="12"
                md="6"
                className="seach-bar-desc-container search-bar-padding d-flex align-items-md-center justify-content-md-end pr-md-5 position-relative"
              >
                <div style={{ zIndex: 1 }} className="form-class">
                  <InputGroup className="search-bar">
                    <Input
                      type="text"
                      id="input1-group2"
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={handleKeyDown}
                      value={searchText}
                      className="search-input p-large font-weight-normal"
                      name="input1-group2"
                      // placeholder={t("home.searchHome")}
                      placeholder={
                        homeTranslation?.searchPlaceholder
                          ? homeTranslation?.searchPlaceholder
                          : webContent?.searchPlaceholder
                      }
                    />
                    <InputGroupAddon
                      className="search-btn-group"
                      addonType="prepend"
                    >
                      <Button
                        type="button"
                        className="search-button"
                        onClick={searchNames}
                      >
                        <i className="fa fa-search search-icon"></i>{" "}
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>

                <img src={pot} alt="" className="hero-img" />
              </Col>
            </Row>

            {/* Product details carousel */}
            {/* <div className="details-carousel">
              <HomeCarousel
                sliderItems={sliderItems}
                handleCheckNow={(product) => {
                  viewProduct(product);
                }}
              />
            </div> */}
          </Container>
        </div>

        <div className="body-area container-fluid">
          <Row className="body-area justify-content-center">
            <div className="header-font title-text">
              {homeTranslation?.headingTwo
                ? homeTranslation?.headingTwo
                : webContent?.headingTwo}
            </div>
          </Row>
          <div className="p-large text-center mt-2 mt-sm-3 mt-md-4">
            {homeTranslation?.paraOneline1
              ? homeTranslation?.paraOneline1
              : webContent?.paraOneline1}
          </div>
          <div className="p-large text-center">
            <span className="font-weight-bold mt-2 mt-sm-0">YINN</span>
            {homeTranslation?.paraOneline2
              ? homeTranslation?.paraOneline2
              : webContent?.paraOneline2}
          </div>
        </div>
        {Featured}

        <Content />

        <div className="image-container mt-sm-3 mt-md-5">
          <div className="overlay-img" style={{ width: "100%" }}></div>
          <div className="centered">
            <div className="header-font mb-2 mb-sm-4 mb-xl-5">
              {homeFeatureTranslation?.headingOne
                ? homeFeatureTranslation?.headingOne
                : homeFeature?.headingOne}

              {/* How <span className="font-weight-bold"> YINN</span> Works */}
            </div>

            <div>
              <p className="p-small mb-1">
                <span style={{ color: "#fff" }} className="font-weight-bold">
                  YINN
                </span>{" "}
                {homeFeatureTranslation?.paraLineOne
                  ? homeFeatureTranslation?.paraLineOne
                  : homeFeature?.paraLineOne}
                {/* leverages a combination of AI, customer feedback, and
                cooperation to facilitate a sustainable pricing model for
                creators to sell online goods and services at a viable price. */}
              </p>
              <p className="p-small">
                {homeFeatureTranslation?.paraLineTwo
                  ? homeFeatureTranslation?.paraLineTwo
                  : homeFeature?.paraLineTwo}
                {/* Creatives like authors, comedians, artists, designers,
                filmmakers, musicians and independent manufacturers get access
                to valuable consumer data related to the services they offer.
                The platform encourages users to share information regarding
                time investment and material costs to find the most equitable
                selling point. */}
              </p>
              <p className="p-small">
                {homeFeatureTranslation?.paraLineThree
                  ? homeFeatureTranslation?.paraLineThree
                  : homeFeature?.paraLineThree}
                {/* In addition to our flagship product - an equitable e-commerce
                marketplace - we have leveraged the underlying technology to
                provide fair pricing in other areas. */}
              </p>
            </div>
          </div>
        </div>

        <Services />

        <FeaturedCarouselItem
          sliderItems={sliderItems}
          viewProduct={(item, user) => viewProduct(item, user)}
        />
        <Container className="message d-block pt-md-3">
          <Row className="justify-content-center pt-3 pt-sm-4 pt-xl-5">
            <img src={sell} alt="" className="d-block mx-auto img-fluid" />
          </Row>

          <div className="header-font font-weight-light text-center d-block mt-3 mt-sm-4 mt-md-5">
            {homeFeatureTranslation?.LastLine
              ? homeFeatureTranslation?.LastLine
              : homeFeature?.LastLine}
            {/* Selling online <span className="font-weight-bold">should not</span>{" "}
            be a zero-sum game… */}
          </div>

          <div className="p-large font-weight-light text-center d-block mt-3 mt-sm-4 mb-5  discover-text">
            {homeFeatureTranslation?.LastPara
              ? homeFeatureTranslation?.LastPara
              : homeFeature?.LastPara}
            {/* Discover how the AI-powered PWIW pricing model can enhance your
            online transactions today. Become a{" "}
            <span className="font-weight-bold">YINN</span> member for free below
            to get started. */}
          </div>

          <div className="text-center d-block mt-4 mt-sm-5">
            <button
              onClick={() => history.push("/auth/login")}
              className="btn black-bg-btn px-4 py-2 button-text"
            >
              {homeFeatureTranslation?.LastButton
                ? homeFeatureTranslation?.LastButton
                : homeFeature?.LastButton}
              {/* Create FREE Account */}
            </button>
          </div>
        </Container>
      </div>

      <DefaultFooter />
    </div>
  );
};

export default Home;
