import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../config/aws";
import {
  updateProduct,
  getSingleProduct,
} from "../../../redux/reducers/seller";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import "../coin.css";
import { toast } from "react-toastify";
import {
  getPresigedUrl,
  uploadToS3ViaSignedUrl,
} from "../../../helpers/s3Utils";

const AddCoin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  useEffect(() => {
    dispatch(getSingleProduct(productId));
  }, []);
  const { singleProduct } = useSelector((state) => state.seller);
  const [pageContent, setPageContent] = useState({
    images: [],
  });
  useEffect(() => {
    if (singleProduct) {
      setPageContent({
        ...singleProduct,
      });
    }
  }, [singleProduct]);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const uploadImage = async (blobs) => {
    for (let i = 0; i < blobs.length; i++) {
      let newImages = [...pageContent.images];
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };

      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const imageUrl = `${Config.digitalOceanSpaces}/` + blob.name;
        newImages = [...newImages, imageUrl];
        setPageContent({
          ...pageContent,
          images: newImages,
        });
      } catch (error) {
        toast.error("Error in uploading image! Please try again");
      }
    }
  };
  const uploadProductImages = (event) => {
    uploadImage(event.target.files);
  };
  const imageClick = (image) => {
    let newImages = _.cloneDeep(pageContent.images);
    newImages = newImages.filter((item) => item !== image);
    setPageContent({
      ...pageContent,
      images: newImages,
    });
  };
  const createCoin = () => {
    const {
      packageName,
      coinQuantity,
      salesPrice,
      totalPrice,
      maintenanceCharge,
      images,
    } = pageContent;
    const data = {
      productId,
      packageName,
      coinQuantity,
      salesPrice,
      totalPrice,
      maintenanceCharge,
      images,
      productType: "YINN-COIN",
    };
    dispatch(updateProduct(data));
    history.push("/admin/coin");
  };
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <i className="fa fa-align-justify"></i>
                  <strong> Add Coin </strong>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Row>
                  <Col xs="12" sm="4" md="4" lg="3">
                    <Label>
                      <b>Package Name</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="text"
                      name="packageName"
                      value={pageContent.packageName}
                      onChange={handleInputChange}
                      placeholder="Package name"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs="12" sm="4" md="4" lg="3">
                    <Label>
                      <b>Quantity</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="Number"
                      min="0"
                      name="coinQuantity"
                      value={pageContent.coinQuantity}
                      onChange={handleInputChange}
                      placeholder="Add Coins"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs="12" sm="4" md="4" lg="3">
                    <Label>
                      <b>Price</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="Number"
                      min="0"
                      name="salesPrice"
                      value={pageContent.salesPrice}
                      onChange={handleInputChange}
                      placeholder="price"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs="12" sm="4" md="4" lg="3">
                    <Label>
                      <b>Maintenance charge</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="Number"
                      min="0"
                      name="maintenanceCharge"
                      value={pageContent.maintenanceCharge}
                      onChange={handleInputChange}
                      placeholder="coin maintanance charge"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs="12" sm="4" md="4" lg="3">
                    <Label>
                      <b>Total price</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      disabled
                      type="Number"
                      min="0"
                      name="totalPrice"
                      value={
                        pageContent.maintenanceCharge + pageContent.salesPrice
                      }
                      placeholder="total price"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row style={{ width: "50%" }}>
                  <Col>
                    <Label>
                      <b>Please select coin image</b>
                    </Label>
                  </Col>
                  <Col>
                    {pageContent?.images?.length > 0
                      ? pageContent?.images?.map((item, index) => (
                          <Col>
                            <div class="container">
                              <img
                                style={{ width: "100%" }}
                                src={item}
                                alt="Avatar"
                                class="image"
                              />
                              <div onClick={() => imageClick(item)} class="">
                                <div class="close"></div>
                              </div>
                            </div>
                          </Col>
                        ))
                      : ""}
                    <Col>
                      <div className="file-uploader">
                        <input
                          type="file"
                          multiple={true}
                          onChange={uploadProductImages}
                          ref={fileInput}
                          hidden
                        />
                        <button
                          onClick={(e) =>
                            fileInput.current && fileInput.current.click()
                          }
                          className="btn btn-primary"
                        >
                          Upload Images
                        </button>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </FormGroup>
              <Row>
                <Col xs="12" sm="4" md="4" lg="3"></Col>
                <Col xs="12" sm="4" md="5" lg="4">
                  <Button
                    // disabled={!state.formValid}
                    color="primary"
                    type="submit"
                    className="btn btn-sm"
                    onClick={(e) => createCoin()}
                    block
                  >
                    Save
                  </Button>
                </Col>
                <Col xs="12" sm="4" md="4" lg="3"></Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddCoin;
