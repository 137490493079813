import React, { useEffect } from "react";
import { Button } from "reactstrap";
import "./Membership.css";

import { useDispatch, useSelector } from "react-redux";
const CustomCard = (props) => {
  const dispatch = useDispatch();


  const {translationState } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const membershipTranslation = translationState?.[0]?.translations?.membership?.[userLanguage]
  const otherMemberShipTranslation = translationState?.[0]?.translations?.otherMemberShip?.[userLanguage]
  
  const { userSubscription, onSubscribe, item } = props;
  const { id, type, name, amount, duration, features } = item;
  const isSubscribed = userSubscription?.membershipType == type;
  const subscribtion = userSubscription?.membershipType;
  const isDowngradeRequested = userSubscription?.isDowngradeRequested;
  const userSubscriptionId =
    subscribtion == "Fremium" ? 0 : subscribtion == "Solo" ? 1 : 2;
  const actionType = userSubscriptionId < id ? 
  `${otherMemberShipTranslation?.upgrade ? otherMemberShipTranslation?.upgrade : "Upgrade"}`
   : 
  `${otherMemberShipTranslation?.downGrande ? otherMemberShipTranslation?.downGrande : "Downgrade"}`
  return (
    <div
      className={`custom-card ${
        userSubscription?.membershipType == type ? 
        `${otherMemberShipTranslation?.subscribe ? otherMemberShipTranslation?.subscribe : "subscribed"}` : ""
      }`}
    >
      <div className="custom-card-header">
        <h3 className="custom-card-header-h">{name}</h3>
      </div>
      <div className="custom-card-body">
        <div className="custom-card-price-container">
          <p className="custom-card-dollor-sign">$</p>
          <p className="custom-card-amount">{amount}</p>
          <p className="custom-card-duration">{duration}</p>
        </div>
        <div className="features-container">
          {features.map((item, i) => (
            <div className="single-feature">
              {item.included ? (
                <i class="fa fa-solid fa-check check-green"></i>
              ) : (
                <i class="fa fa-solid fa-close check-red"></i>
              )}
              <p className="single-feature-h">{item.feature}</p>
            </div>
          ))}
        </div>
        <Button
          disabled={isSubscribed || isDowngradeRequested}
          onClick={() =>
            !isSubscribed &&
            !isDowngradeRequested &&
            onSubscribe(type, actionType)
          }
          size="sm"
          className="subscription-button"
        >
          <b>{isSubscribed ? `${otherMemberShipTranslation?.subscribe ? otherMemberShipTranslation?.subscribe : "Subscribed"}` : actionType}</b>
        </Button>
      </div>
    </div>
  );
};

export default CustomCard;
