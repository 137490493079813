import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getInvoices } from "../../redux/reducers/user";
import moment from "moment";
import { FaEye } from "react-icons/fa";

const AccountInvoice = () => {
  const { invoices, loading } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const eVoucherTranslation =
    translationState?.[0]?.translations?.eVoucher?.[userLanguage];
  const advertisementsTranslation =
    translationState?.[0]?.translations?.advertisements?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];
  const sellerTranslation =
    translationState?.[0]?.translations?.seller?.[userLanguage];
  const dashboardTranslation =
    translationState?.[0]?.translations?.dashboard?.[userLanguage];
  const residencySwapTranslation =
    translationState?.[0]?.translations?.residencySwap?.[userLanguage];

  useEffect(() => {
    dispatch(getInvoices());
  }, []);

  return (
    <>
      <div className="main-row">
        <h3 className="mx-3 fw-bold" style={{ fontWeight: "bold" }}>
          {userDashboardTranslation?.history
            ? userDashboardTranslation?.history
            : "Payment History"}
        </h3>
        <div className="row">
          <div className="col-12 user-table-invoice-height overflow-auto ">
            <table
              style={{ border: "0.5px solid grey", border: "none" }}
              className="table"
            >
              <thead className="text-left bg-color">
                <tr>
                  <th className="pl-4">
                    {walletTranslation?.id ? walletTranslation?.id : "Id"}
                  </th>
                  <th>
                    {eVoucherTranslation?.status
                      ? eVoucherTranslation?.status
                      : "Status"}
                  </th>
                  <th style={{ minWidth: "140px" }}>
                    {advertisementsTranslation?.date
                      ? advertisementsTranslation?.date
                      : "Date"}
                  </th>
                  <th>
                    {eVoucherTranslation?.action
                      ? eVoucherTranslation?.action
                      : "Action"}
                  </th>
                </tr>
              </thead>

              {loading ? (
                <td colSpan={4} className="py-5">
                  <div style={{ textAlign: "center", margin: "90px" }}>
                    <i
                      className="fas fa-spinner fa-pulse fa-2x"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                </td>
              ) : (
                invoices?.map((data, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>{data._id}</td>
                        {data.isPaid == true ? (
                          <td className="text-success">
                            {sellerTranslation?.Payouts
                              ? sellerTranslation?.Payouts
                              : "Paid"}{" "}
                          </td>
                        ) : (
                          <td className="text-danger">
                            {dashboardTranslation?.unpaid
                              ? dashboardTranslation?.unpaid
                              : "Unpaid"}
                          </td>
                        )}
                        <td>
                          {moment(data.createdAt).format("MMM / Do / YYYY")}
                        </td>
                        <td>
                          {/* <button
                            className="button-color"
                            onClick={() =>
                              history.push(
                                `/customer-account/view-invoice/${data._id}`
                              )
                            }
                          >
                            {userDashboardTranslation?.history
                              ? userDashboardTranslation?.history
                              : "View"}
                          </button> */}
                          <div>
                            <FaEye
                              onClick={() =>
                                history.push(
                                  `/customer-account/view-invoice/${data._id}`
                                )
                              }
                              className="support-detail-view-icons"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountInvoice;
