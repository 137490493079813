import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../config/axios";
import { getMessage } from "../helpers";

export const getCategories = createAsyncThunk(
  "/seller/product/categories",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      let { filters } = state.seller;
      if (data && data?.action === "no_filter") {
        filters = [
          { field: "pageNo", value: 1 },
          { field: "perPage", value: "undefined" },
        ];
      }
      const response = await axios.get(
        `/product/category/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getCategoriesByType = createAsyncThunk(
  "/seller/product/category/get-category-by-type",
  async (type, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/product/category/get-category-by-type?filters=${JSON.stringify(
          filters
        )}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getAdvertisments = createAsyncThunk(
  "/seller/advertisement/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/advertisement/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getDisputes = createAsyncThunk(
  "/seller/disputes/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/dispute/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getAllResidency = createAsyncThunk(
  "/seller/recidency/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/recidency/get/all?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getProductSubCategories = createAsyncThunk(
  "/admin/product/subcategories",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/product/subcategory/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getAllSubCategories = createAsyncThunk(
  "/admin/product/all/subcategories",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/product/subcategory/get`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getSubCategoryByType = createAsyncThunk(
  "/seller/product/subcategory/get-subcategory-by-type",
  async (type, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `/product/subcategory/get-subcategory-by-type?type=${JSON.stringify(
          type
        )}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getResidencyCategories = createAsyncThunk(
  "/admin/product/residency/subcategories",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/product/subcategory/residency-swap`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getProductSubSubCategories = createAsyncThunk(
  "/admin/product/subsubcategories",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/product/subcategory/sub/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getReports = createAsyncThunk(
  "/admin/reports/get",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/reports/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getLoans = createAsyncThunk(
  "/admin/loans/all",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/loans/all?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getCustomers = createAsyncThunk(
  "/seller/customers/all",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/orders/customer?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getPayouts = createAsyncThunk(
  "/admin/payouts/all",
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/user/payouts/all?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getVouchers = createAsyncThunk(
  "/seller/eVoucher/all",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/user/eVoucher/all?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateVoucher = createAsyncThunk(
  "/seller/updateEVoucher",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(
        `/user/eVoucher/update/${data?.voucherId}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation ? toastTranslation : response?.data?.message
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getRequested = createAsyncThunk(
  "/admin/loans/requested",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/loans/requested?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getAddCharges = createAsyncThunk(
  "/admin/charges/get",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/charges/get`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createLoan = createAsyncThunk(
  "/seller/loans/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/loans/create`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation ? toastTranslation : "Loan submitted successfully!"
      );
      // toast.success("Loan submitted successfully!");
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createSubscription = createAsyncThunk(
  "/seller/subscriptions/create",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/membership/create`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation ? toastTranslation : "Subscription update successfully"
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getSubscription = createAsyncThunk(
  "/seller/subscriptions/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/membership/get`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getMembershipPlans = createAsyncThunk(
  "/seller/subscriptions/get/plans",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/membership/plans/get`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createPayoutRequest = createAsyncThunk(
  "/seller/payout/create",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/user/payouts/create`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation ? toastTranslation : response.data.message
      );
      // toast.success(response.data.message);
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createEVoucher = createAsyncThunk(
  "/seller/eVoucher/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/user/eVoucher/create`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation ? toastTranslation : "E-Voucher Created successfully!"
      );
      // toast.success("E-Voucher Created successfully!");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
        toast.error(err.response.data.message);
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const claimEVoucher = createAsyncThunk(
  "/seller/eVoucher/claim",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/user/eVoucher/claim`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation ? toastTranslation : "E-Voucher Claimed successfully!"
      );
      // toast.success("E-Voucher Claimed successfully!");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const lendLoan = createAsyncThunk(
  "/seller/loans/lendLoan",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/loans/lend/${data.loanId}`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createProduct = createAsyncThunk(
  "/seller/product/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/product/create`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation ? toastTranslation : "Product created successfully!"
      );
      // toast.success("Product created successfully!");
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const carbonEmissionData = createAsyncThunk(
  "/seller/product/create",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/product/carbon-emission`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const digitalcarbonEmission = createAsyncThunk(
  "/seller/digital/carbon-emission",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `/product/digital/carbon-emission`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createAd = createAsyncThunk(
  "/seller/createAd/create",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/advertisement/create`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation
          ? toastTranslation
          : "advertisement created successfully!"
      );
      // toast.success("advertisement created successfully!");
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateSingleDispute = createAsyncThunk(
  "/seller/disputes/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(
        `/dispute/update/${data.disputeId}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updateProduct = createAsyncThunk(
  "/seller/product/update",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { userType } = state.auth.user;
      const response = await axios.post(
        `/product/update/${data.id}`,
        { data, userType },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation
          ? toastTranslation
          : "Product/Status Updated Successfully"
      );
      // toast.success(`Product/Status Updated Successfully`);
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.data.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateAdvertisement = createAsyncThunk(
  "/seller/advertisement/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `/advertisement/update/${data.addId}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation
          ? toastTranslation
          : "Advertisement update successfully"
      );
      // toast.success("Advertisement update successfully");
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updateOrder = createAsyncThunk(
  "/seller/order/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(`/orders/update/${data.OId}`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updateItemOrder = createAsyncThunk(
  "/seller/orderItem/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(
        `/orders/update/item/${data.OId}`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getProducts = createAsyncThunk(
  "/seller/products/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/product/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getOrders = createAsyncThunk(
  "/seller/orders/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters } = state.seller;
      const response = await axios.get(
        `/orders/get?filters=${JSON.stringify(filters)}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getProductBrands = createAsyncThunk(
  "/seller/products/brands",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/product/get-all/brandList/brand`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getSingleProduct = createAsyncThunk(
  "/seller/products/get/id",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/product/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getsingleDispute = createAsyncThunk(
  "/seller/disputes/get/id",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/dispute/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getSuggestedPrice = createAsyncThunk(
  "/seller/products/suggestedPrice",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `/product/pricing/suggest/${data.productId}?price=${data.price}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getSingleOrder = createAsyncThunk(
  "/seller/orders/get/id",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/orders/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "/seller/products/delete/id",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/product/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "/user/order/delete/id",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/orders/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deleteDispute = createAsyncThunk(
  "/seller/disputes/delete",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/dispute/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const deleteAdvertisement = createAsyncThunk(
  "/seller/advertisement/delete",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(`/advertisement/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success");
      toast.success(
        toastTranslation
          ? toastTranslation
          : "Advertisement delete successfully"
      );
      // toast.success("Advertisement delete successfully");
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const eventCreation = createAsyncThunk(
  "/seller/event/create",
  async (data, { getState, rejectWithValue }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/event/create`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      // const toastTranslation = getMessage(state, "success")
      // toast.success(toastTranslation ? toastTranslation : "Advertisement delete successfully");
      // toast.success("Advertisement delete successfully");
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getEvent = createAsyncThunk(
  "/seller/event/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { token } = state.auth;
      const { filters } = state.seller;
      const params = { ...data, filters: JSON.stringify(filters) };
      const response = await axios.get(`/event/get/${data?.id || ""}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (err) {
      // toast.info(err.response.data?.message);
      // toast.error(err.response.data.error || err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
        });
      }
      return rejectWithValue({ err: "Network Error" });
    }
  }
);

export const getOrderEvent = createAsyncThunk(
  "/seller/order/event/get",
  async (id, { getState, rejectWithValue }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/event/get-order-event`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updateOrderStreaming = createAsyncThunk(
  "/seller/event/update-order",
  async (data, { getState, rejectWithValue }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/event/update-order`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response?.data) {
        toast.info(err.response.data?.message);
        toast.error(err.response.data.error || err.response.data);
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ err: "Network Error" });
      }
    }
  }
);
export const updateOrderStreamingFalse = createAsyncThunk(
  "/seller/event/update-order-after-streaming",
  async (data, { getState, rejectWithValue }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `/event/update-order-after-streaming`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response?.data) {
        toast.info(err.response.data?.message);
        toast.error(err.response.data.error || err.response.data);
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ err: "Network Error" });
      }
    }
  }
);
export const createEventAnnouncement = createAsyncThunk(
  "/seller/event/create-announcement",
  async (data, { getState, rejectWithValue }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/event/create-announcement`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const updateEventAnnouncement = createAsyncThunk(
  "/seller/event/update-event-announcement",
  async (data, { getState, rejectWithValue }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(
        `/event/update-event-announcement`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const editUserInfo = createAsyncThunk(
  "/streaming/update-user",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { token } = state.auth;
      const response = await axios.put(
        `/streaming/update-user/${data.id ? data.id : ""}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({ err: "Network Error" });
    }
  }
);
export const StopRecording = createAsyncThunk(
  "seller/streaming/stop-recording",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { token } = state.auth;
      const response = await axios.put(`/streaming/stop-recording`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (err) {
      // toast.info(err.response.data?.message);
      // toast.error(err.response.data.error || err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
        });
      }
      return rejectWithValue({ err: "Network Error" });
    }
  }
);

// SHOP DETAILS
export const AddShopDetails = createAsyncThunk(
  "seller/shopDetails/add",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const response = await axios.post(`/shopDetails/add`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response?.data) {
        toast.info(err.response.data?.message);
        toast.error(err.response.data.error || err.response.data);
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ err: "Network Error" });
      }
    }
  }
);
export const EditShopDetails = createAsyncThunk(
  "seller/shopDetails/edit",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const response = await axios.put(
        `/shopDetails/edit/${data?.id ? data.id : ""}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response?.data) {
        toast.info(err.response.data?.message);
        toast.error(err.response.data.error || err.response.data);
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ err: "Network Error" });
      }
    }
  }
);

// LIVE CHAT
export const GetCustomerChat = createAsyncThunk(
  "/seller/chat/get-customer-chat",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const { filters } = getState().seller;
      const params = { filters: JSON.stringify(filters) };
      const response = await axios.get(`/chat/get-customer-chat`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (err) {
      // toast.info(err.response.data?.message);
      // toast.error(err.response.data.error || err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
        });
      }
      return rejectWithValue({ err: "Network Error" });
    }
  }
);
export const DeleteChat = createAsyncThunk(
  "seller/chat/delete",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const response = await axios.delete(`/chat/delete/${id || ""}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response?.data) {
        // toast.info(err.response.data?.message);
        // toast.error(err.response.data.error || err.response.data);
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ err: "Network Error" });
      }
    }
  }
);

const initialState = {
  // recordings: [],
  customerChats: [],
  productCategories: [],
  advertisments: [],
  disputes: [],
  products: [],
  orders: [],
  reports: [],
  count: 0,
  filters: [
    { field: "pageNo", value: 1 },
    { field: "perPage", value: 5 },
  ],
  productSubCategories: [],
  productSubSubCategories: [],
  allSubCategories: [],
  subCategoriesByType: [],
  brands: [],
  allLoans: [],
  allCustomers: [],
  allPayouts: [],
  allVouchers: [],
  requestedLoans: [],
  allResidency: [],
  singleProduct: {},
  singleDispute: {},
  singleOrder: {},
  addCharges: {},
  singleSubscription: {},
  message: "",
  addCreated: null,
  loading: false,
  suggestedPrice: false,
  events: [],
  orderEvents: [],
  err: "",
  membershipPlans: [],
};

const seller = createSlice({
  name: "seller",
  initialState,
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    addFilter(state, { payload: { field, value } }) {
      const filters = [...state.filters];
      const checkFilter = filters.findIndex((item) => item.field === field);
      if (checkFilter !== -1) {
        filters[checkFilter] = { field, value };
      } else {
        filters.push({ field, value });
      }
      state["filters"] = filters;
    },
    ClearState(state, action) {
      return initialState;
    },
  },
  extraReducers: {
    [getCategories.pending]: (state, action) => ({
      ...state,
      productSubCategories: [],
      productSubSubCategories: [],
      loading: true,
    }),
    [getCategories.fulfilled]: (state, action) => ({
      ...state,
      productCategories: action.payload.data,
      loading: false,
      err: "",
    }),
    [getCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getCategoriesByType.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getCategoriesByType.fulfilled]: (state, { payload }) => ({
      ...state,
      productCategories: payload.data,
      loading: false,
      err: "",
    }),
    [getCategoriesByType.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAdvertisments.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAdvertisments.fulfilled]: (state, action) => ({
      ...state,
      advertisments: action.payload.data,
      count: action.payload.count,
      loading: false,
      err: "",
    }),
    [getAdvertisments.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getDisputes.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getDisputes.fulfilled]: (state, action) => ({
      ...state,
      disputes: action.payload.data,
      count: action.payload.count,
      loading: false,
      err: "",
    }),
    [getDisputes.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAllResidency.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAllResidency.fulfilled]: (state, action) => ({
      ...state,
      allResidency: action.payload.data,
      count: action.payload.count,
      loading: false,
      err: "",
    }),
    [getAllResidency.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getProductSubCategories.pending]: (state, action) => ({
      ...state,
      productSubSubCategories: [],
      loading: true,
    }),
    [getProductSubCategories.fulfilled]: (state, action) => ({
      ...state,
      productSubCategories: action.payload.data,
      loading: false,
      err: "",
    }),
    [getProductSubCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getProductSubSubCategories.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getProductSubSubCategories.fulfilled]: (state, action) => ({
      ...state,
      productSubSubCategories: action.payload.data,
      loading: false,
      err: "",
    }),
    [getProductSubSubCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getLoans.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getLoans.fulfilled]: (state, action) => ({
      ...state,
      allLoans: action.payload.data,
      loading: false,
      err: "",
    }),
    [getLoans.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getCustomers.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getCustomers.fulfilled]: (state, action) => ({
      ...state,
      allCustomers: action.payload.data,
      count: action.payload.count,
      loading: false,
      err: "",
    }),
    [getCustomers.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getPayouts.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getPayouts.fulfilled]: (state, action) => ({
      ...state,
      allPayouts: action.payload.data,
      count: action.payload.count,
      loading: false,
      err: "",
    }),
    [getPayouts.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getVouchers.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getVouchers.fulfilled]: (state, action) => ({
      ...state,
      allVouchers: action.payload.data,
      count: action.payload.count,
      loading: false,
      err: "",
    }),
    [getVouchers.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateVoucher.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateVoucher.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updateVoucher.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getRequested.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getRequested.fulfilled]: (state, action) => ({
      ...state,
      requestedLoans: action.payload.data,
      count: action.payload.count,
      loading: false,
      err: "",
    }),
    [getRequested.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAddCharges.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAddCharges.fulfilled]: (state, action) => ({
      ...state,
      addCharges: action.payload.data,
      loading: false,
      err: "",
    }),
    [getAddCharges.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getProducts.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getProducts.fulfilled]: (state, action) => ({
      ...state,
      products: action.payload.data.products,
      count: action.payload.data.count,
      loading: false,
      err: "",
    }),
    [getProducts.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getOrders.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getOrders.fulfilled]: (state, action) => ({
      ...state,
      orders: action.payload.data.orders,
      count: action.payload.data.count,
      loading: false,
      err: "",
    }),
    [getOrders.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getReports.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getReports.fulfilled]: (state, action) => ({
      ...state,
      reports: action.payload.data,
      loading: false,
      err: "",
    }),
    [getReports.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createProduct.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createProduct.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [createProduct.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createAd.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createAd.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      addCreated: action.payload.success,
      err: "",
    }),
    [createAd.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createLoan.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createLoan.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [createLoan.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createSubscription.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createSubscription.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [createSubscription.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSubscription.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSubscription.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      singleSubscription: action.payload.data,
      err: "",
    }),
    [getSubscription.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getMembershipPlans.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getMembershipPlans.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      membershipPlans: action.payload.data,
      err: "",
    }),
    [getMembershipPlans.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createPayoutRequest.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createPayoutRequest.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
      message: action.payload.message,
    }),
    [createPayoutRequest.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [createEVoucher.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createEVoucher.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [createEVoucher.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [claimEVoucher.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [claimEVoucher.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [claimEVoucher.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err,
    }),
    [updateProduct.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateProduct.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updateProduct.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateSingleDispute.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateSingleDispute.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updateSingleDispute.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateAdvertisement.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateAdvertisement.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updateAdvertisement.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateOrder.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateOrder.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updateOrder.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateItemOrder.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateItemOrder.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updateItemOrder.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSingleProduct.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSingleProduct.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      singleProduct: action.payload.data,
      err: "",
    }),
    [getSingleProduct.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getsingleDispute.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getsingleDispute.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      singleDispute: action.payload.data,
      err: "",
    }),
    [getsingleDispute.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),

    [getSingleOrder.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSingleOrder.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      singleOrder: action.payload.data,
      err: "",
    }),
    [getSingleOrder.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),

    [deleteProduct.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteProduct.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [deleteProduct.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [cancelOrder.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [cancelOrder.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [cancelOrder.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteAdvertisement.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteAdvertisement.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [deleteAdvertisement.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteDispute.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteDispute.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [deleteDispute.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getProductBrands.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getProductBrands.fulfilled]: (state, action) => ({
      ...state,
      brands: action.payload.data,
      loading: false,
      err: "",
    }),
    [getProductBrands.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAllSubCategories.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAllSubCategories.fulfilled]: (state, action) => ({
      ...state,
      allSubCategories: action.payload.data,
      loading: false,
      err: "",
    }),
    [getAllSubCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSubCategoryByType.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSubCategoryByType.fulfilled]: (state, { payload }) => ({
      ...state,
      subCategoriesByType: payload.data,
      loading: false,
      err: "",
    }),
    [getSubCategoryByType.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getResidencyCategories.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getResidencyCategories.fulfilled]: (state, action) => ({
      ...state,
      allSubCategories: action.payload.data,
      loading: false,
      err: "",
    }),
    [getResidencyCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSuggestedPrice.pending]: (state, action) => ({
      ...state,
      suggestedPrice: false,
      loading: true,
    }),
    [getSuggestedPrice.fulfilled]: (state, action) => ({
      ...state,
      suggestedPrice: action.payload.message,
      loading: false,
      err: "",
    }),
    [getSuggestedPrice.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [eventCreation.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [eventCreation.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [eventCreation.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getEvent.pending]: (state, action) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [getEvent.fulfilled]: (state, { payload }) => ({
      ...state,
      events: payload.data,
      count: payload.count,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [getEvent.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),
    [getOrderEvent.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getOrderEvent.fulfilled]: (state, action) => ({
      ...state,
      orderEvents: action.payload.data,
      loading: false,
      err: "",
    }),
    [getOrderEvent.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateOrderStreaming.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [updateOrderStreaming.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [updateOrderStreaming.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),
    [updateOrderStreamingFalse.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [updateOrderStreamingFalse.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [updateOrderStreamingFalse.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),

    [editUserInfo.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [editUserInfo.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
      isLogged: true,
    }),
    [editUserInfo.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [StopRecording.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [StopRecording.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [StopRecording.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),

    // SHOP DETAILS
    [AddShopDetails.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [AddShopDetails.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [AddShopDetails.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),
    [EditShopDetails.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [EditShopDetails.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [EditShopDetails.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),

    // LIVE CHAT
    [GetCustomerChat.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetCustomerChat.fulfilled]: (state, action) => ({
      ...state,
      customerChats: action.payload.data,
      count: action.payload.count,
      loading: false,
      err: "",
    }),
    [GetCustomerChat.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [DeleteChat.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [DeleteChat.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [DeleteChat.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),
  },
});

const { reducer, actions } = seller;

export const { SetState, ClearState, addFilter } = actions;

export default reducer;
