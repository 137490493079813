import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavLink,
} from "reactstrap";
import PropTypes from "prop-types";

import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import logo from "../assets/img/brand/logo.png";
import sygnet from "../assets/img/brand/logo.png";
import "./common.css";
import moment from "moment";
import "./notification.css";
import { useDispatch, useSelector } from "react-redux";
import ChatUserImage from "../assets/img/brand/chat-user-image.png";
import {
  logout,
  switchRole,
  getExhangeRate,
  SetState as AuthSetState,
} from "../redux/reducers/auth";
import { getWallet, SetState } from "../redux/reducers/user";
import { getAnnouncements, readAnnouncements } from "../redux/reducers/admin";
import { getSubscription } from "../redux/reducers/seller";
import { currencyAndFlags } from "../constants/bluesnapCurrences";
import { IoMdArrowDropdown } from "react-icons/io";
import "./header.css";

const propTypes = {
  children: PropTypes.node,
};
const defaultProps = {};

const DefaultHeader = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getWallet());
    dispatch(getSubscription());
  }, []);
  const {
    user: { userType, firstname, lastname, profileAvatar },
  } = useSelector((state) => state.auth);
  const { wallet, userCurrency, localBankTransferAccountDetails } = useSelector(
    (state) => state.user
  );

  const { userLanguage } = useSelector((state) => state.auth);
  const renderFlagIcon = (code) => {
    return <span className={`flag-icon flag-icon-${code.toLowerCase()}`} />;
  };
  // const changeLanguage = (lng) => {
  //   dispatch(SetState({ field: "userLanguage", value: lng }));
  // };
  const changeLanguage = (lng) => {
    dispatch(AuthSetState({ field: "userLanguage", value: lng }));
  };
  const { announcements } = useSelector((state) => state.admin);
  const { singleSubscription } = useSelector((state) => state.seller);
  const switchToBuyerView = () => {
    const data = {
      role: userType === "seller" ? "user" : "seller",
    };
    dispatch(switchRole(data)).then(({ payload }) => {
      if (payload.user) {
        history.push(`/${payload.user.userType}/profile`);
      }
    });
  };
  const viewAnnouncement = (data) => {
    history.push(`/${userType}/announcementlist/view/${data?._id}`);
  };
  const markAllRead = () => {
    const ids = announcements.map((item) => item._id);
    dispatch(readAnnouncements(ids)).then(() => {
      dispatch(getAnnouncements());
    });
  };

  const currencyDropDown = currencyAndFlags.map((currency, value) => {
    return (
      <DropdownItem
        key={value}
        style={{ borderBottom: "none" }}
        onClick={() => {
          // do not update summary when local Bank is selected as payment method
          if (!localBankTransferAccountDetails) {
            dispatch(
              SetState({
                field: "userCurrency",
                value: Object.keys(currency)[0],
              })
            );
            dispatch(getExhangeRate(Object.keys(currency)[0]));
          }
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {Object.keys(currency)[0]}
          {renderFlagIcon(Object.values(currency)[0])}
        </div>
      </DropdownItem>
    );
  });
  const redirectProfile = (evt) => {
    if (userType === "user") {
      history.push(`/customer-account/account-details`);
    } else {
      history.push(`/${userType}/profile`);
    }
  };

  const redirectWallet = () => {
    if (userType === "user") {
      history.push(`/customer-account/payment-method`);
    } else {
      history.push(`/${userType}/wallet`);
    }
  };
  const redirectToHome = () => {
    history.push("/");
  };

  // eslint-disable-next-line
  const { children, ...attributes } = props;

  return (
    <React.Fragment>
      {["seller", "admin"].includes(userType) && (
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
      )}
      <img
        src={logo}
        onClick={redirectToHome}
        className="seller-view-logo mx-2"
      />

      {(userType === "seller" || userType === "admin") && (
        <AppSidebarToggler className="d-md-down-none" display="lg" />
      )}
      <Nav className="ml-sm-auto  mx-auto mx-sm-0" navbar>
        {userType !== "admin" && (
          <AppHeaderDropdown direction="down" left="true">
            <DropdownToggle nav>
              {/* <NavLink href="#" style={{ marginTop: "13%" }}> */}
              <i
                className="fa fa-bell-o"
                style={{ color: "rgb(250, 204, 5)", fontSize: "20px" }}
              />
              <Badge pill color="danger">
                {announcements?.filter((item) => !item.isRead).length}
              </Badge>
              {/* </NavLink> */}
            </DropdownToggle>
            <DropdownMenu
              left="true"
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: (data) => {
                    return {
                      ...data,
                      styles: {
                        ...data.style,
                        overflowX: "scroll",
                        maxHeight: 500,
                        left: -100,
                      },
                    };
                  },
                },
              }}
              className={"dropdown-menu dropdown-menu-lg"}
            >
              {announcements.length > 0 ? (
                <DropdownItem onClick={() => markAllRead()} float-left="true">
                  <div>
                    <small className="text-muted float-right mt-1">
                      <u hover="true">Mark all as read</u>
                    </small>
                  </div>
                </DropdownItem>
              ) : (
                ""
              )}
              <div>
                {announcements.length > 0 ? (
                  announcements.map((data, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => viewAnnouncement(data)}
                      style={{
                        backgroundColor: data.isRead
                          ? ""
                          : "rgb(250, 204, 5, 0.5)",
                      }}
                    >
                      <div className="message">
                        <div className="text-truncate font-weight-bold fontz">
                          {data.message}
                        </div>
                        <small className="text-muted float-right mt-1">
                          {moment(data.date).format("MMM Do YYYY, h:mm:ss a")}
                        </small>
                      </div>
                    </DropdownItem>
                  ))
                ) : (
                  <div>
                    <div align="center">
                      <small className="text-muted fa fa-4x fa-bell lg"></small>
                    </div>
                    {singleSubscription?.membershipType === "Fremium" ? (
                      <div align="center">
                        <small> Not available for Fremium Membership</small>
                      </div>
                    ) : (
                      <div align="center">
                        <small> Notification not found</small>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </DropdownMenu>
          </AppHeaderDropdown>
        )}
        <AppHeaderDropdown direction="down" style={{ width: "10px" }}>
          <DropdownToggle nav>
            <p className="profile-nav-link d-flex align-items-center  mb-0">
              {userLanguage}
              <IoMdArrowDropdown size={25} />
            </p>
          </DropdownToggle>
          <DropdownMenu
            className="profile-dropdown profle-dropdown-text"
            style={{ right: "auto" }}
          >
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("ar")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Arabic
                {renderFlagIcon("sa")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("zh")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Chinese
                {renderFlagIcon("cn")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("en")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                English
                {renderFlagIcon("gb")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("fr")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                French
                {renderFlagIcon("fr")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("pt")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Portuguese
                {renderFlagIcon("pt")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("es")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Spanish
                {renderFlagIcon("es")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav>
            <p className="profile-nav-link d-flex align-items-center justify-content-center  mb-0">
              {userCurrency}
              <IoMdArrowDropdown size={25} />
            </p>
          </DropdownToggle>
          <DropdownMenu
            className="profile-dropdown profle-dropdown-text"
            right
            style={{
              right: "auto",
              height: "300px",
              overflowY: "scroll",
            }}
          >
            {currencyDropDown}
          </DropdownMenu>
        </AppHeaderDropdown>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav className="profile-picture-container">
            <div className="d-flex align-items-center ml-0">
              <span className="userName">
                {firstname} {lastname}
              </span>
              <span>
                <IoMdArrowDropdown size={25} />
              </span>
              {profileAvatar !== undefined ? (
                <span>
                  <img className="profile_image_seller" src={profileAvatar} />
                </span>
              ) : (
                <span>
                  <img className="profile_image_seller" src={ChatUserImage} />
                </span>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu right style={{ right: "auto" }}>
            {userType !== "admin" && (
              <DropdownItem
                onClick={redirectProfile}
                className="user-dropdown-wrapper"
              >
                <i
                  className="fa fa-lg fa-user user-dropdown"
                  style={{ color: "#ffc107" }}
                ></i>
                Profile Setting
              </DropdownItem>
            )}

            {userType !== "admin" && (
              <DropdownItem
                onClick={redirectWallet}
                className="user-dropdown-wrapper"
              >
                <i
                  className="fas fa-lg fa-wallet user-dropdown"
                  style={{ color: "#ffc107" }}
                ></i>
                Wallet
              </DropdownItem>
            )}
            {userType !== "admin" && (
              <DropdownItem
                onClick={switchToBuyerView}
                className="user-dropdown-wrapper"
              >
                <i className="fa fa-lg fa-user user-dropdown"></i>
                Switch to {userType === "seller" ? "user" : "seller"} view
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() => dispatch(logout())}
              className="user-dropdown-wrapper"
            >
              <i
                className="fa fa-lg fa-lock user-dropdown"
                style={{ color: "#ffc107" }}
              ></i>
              Logout
            </DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
      </Nav>
      {userType !== "admin" && (
        <div onClick={redirectWallet} className="wallet-holder">
          <i className="fas fa-wallet user-wallat"></i>
          <span className="user-wallat-amont">
            {wallet?.coinBalance?.toFixed(2)}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
