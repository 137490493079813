export const bluesnapSupportedData = [
  {
    shoppersCountryCode: "AL",
    shoppersCountry: "Albania",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "DZ",
    shoppersCountry: "Algeria",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "AS",
    shoppersCountry: "American Samoa",
    currency: "USD",
  },
  {
    shoppersCountryCode: "AD",
    shoppersCountry: "Andorra",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "AI",
    shoppersCountry: "Anguilla",
    currency: "USD",
  },
  {
    shoppersCountryCode: "AQ",
    shoppersCountry: "Antarctica",
    currency: "NOK",
  },
  {
    shoppersCountryCode: "AG",
    shoppersCountry: "Antigua and Barbuda",
    currency: "USD",
  },
  {
    shoppersCountryCode: "AM",
    shoppersCountry: "Armenia",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "AW",
    shoppersCountry: "Aruba",
    currency: "USD",
  },
  {
    shoppersCountryCode: "AT",
    shoppersCountry: "Austria",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "AZ",
    shoppersCountry: "Azerbaijan",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "BS",
    shoppersCountry: "Bahamas",
    currency: "USD",
  },
  {
    shoppersCountryCode: "BD",
    shoppersCountry: "Bangladesh",
    currency: "USD",
  },
  {
    shoppersCountryCode: "BB",
    shoppersCountry: "Barbados",
    currency: "USD",
  },
  {
    shoppersCountryCode: "BY",
    shoppersCountry: "Belarus",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "BZ",
    shoppersCountry: "Belize",
    currency: "USD",
  },
  {
    shoppersCountryCode: "BJ",
    shoppersCountry: "Benin",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "BM",
    shoppersCountry: "Bermuda",
    currency: "USD",
  },
  {
    shoppersCountryCode: "BT",
    shoppersCountry: "Bhutan",
    currency: "USD",
  },
  {
    shoppersCountryCode: "BO",
    shoppersCountry: "Bolivia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "BA",
    shoppersCountry: "Bosnia and Herzegovina",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "BW",
    shoppersCountry: "Botswana",
    currency: "ZAR",
  },
  {
    shoppersCountryCode: "BV",
    shoppersCountry: "Bouvet Island",
    currency: "NOK",
  },
  {
    shoppersCountryCode: "BR",
    shoppersCountry: "Brazil",
    currency: "BRL",
  },
  {
    shoppersCountryCode: "BF",
    shoppersCountry: "Burkina Faso",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "BI",
    shoppersCountry: "Burundi",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "CM",
    shoppersCountry: "Cameroon",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "CA",
    shoppersCountry: "Canada",
    currency: "CAD",
  },
  {
    shoppersCountryCode: "CV",
    shoppersCountry: "Cape Verde",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "KY",
    shoppersCountry: "Cayman Islands",
    currency: "USD",
  },
  {
    shoppersCountryCode: "CF",
    shoppersCountry: "Central African Republic",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "TD",
    shoppersCountry: "Chad",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "CL",
    shoppersCountry: "Chile",
    currency: "USD",
  },
  {
    shoppersCountryCode: "CN",
    shoppersCountry: "China",
    currency: "USD",
  },
  {
    shoppersCountryCode: "KM",
    shoppersCountry: "Comoros",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "CG",
    shoppersCountry: "Congo",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "CD",
    shoppersCountry: "Congo, the Democratic Republic of the",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "CK",
    shoppersCountry: "Cook Islands",
    currency: "NZD",
  },
  {
    shoppersCountryCode: "CR",
    shoppersCountry: "Costa Rica",
    currency: "USD",
  },
  {
    shoppersCountryCode: "CI",
    shoppersCountry: "Cote d’Ivoire",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "CY",
    shoppersCountry: "Cyprus",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "CZ",
    shoppersCountry: "Czech Republic",
    currency: "CZK",
  },
  {
    shoppersCountryCode: "DJ",
    shoppersCountry: "Djibouti",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "DM",
    shoppersCountry: "Dominica",
    currency: "USD",
  },
  {
    shoppersCountryCode: "DO",
    shoppersCountry: "Dominican Republic",
    currency: "USD",
  },
  {
    shoppersCountryCode: "EG",
    shoppersCountry: "Egypt",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "SV",
    shoppersCountry: "El Salvador",
    currency: "USD",
  },
  {
    shoppersCountryCode: "GQ",
    shoppersCountry: "Equatorial guinea",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "ER",
    shoppersCountry: "Eritrea",
    currency: "USD",
  },
  {
    shoppersCountryCode: "EE",
    shoppersCountry: "Estonia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "ET",
    shoppersCountry: "Ethiopia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "FK",
    shoppersCountry: "Falkland Islands",
    currency: "GBP",
  },
  {
    shoppersCountryCode: "FI",
    shoppersCountry: "Finland",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "FR",
    shoppersCountry: "France",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "GF",
    shoppersCountry: "French Guiana",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "PF",
    shoppersCountry: "French Polynesia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "TF",
    shoppersCountry: "French Southern Territories",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "GA",
    shoppersCountry: "Gabon",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "GM",
    shoppersCountry: "Gambia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "GE",
    shoppersCountry: "Georgia",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "DE",
    shoppersCountry: "Germany",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "GI",
    shoppersCountry: "Gibraltar",
    currency: "GBP",
  },
  {
    shoppersCountryCode: "GR",
    shoppersCountry: "Greece",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "GD",
    shoppersCountry: "Grenada",
    currency: "USD",
  },
  {
    shoppersCountryCode: "GP",
    shoppersCountry: "Guadeloupe",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "GU",
    shoppersCountry: "Guam",
    currency: "USD",
  },
  {
    shoppersCountryCode: "GT",
    shoppersCountry: "Guatemala",
    currency: "USD",
  },
  {
    shoppersCountryCode: "GG",
    shoppersCountry: "Guernsey",
    currency: "GBP",
  },
  {
    shoppersCountryCode: "GN",
    shoppersCountry: "Guinea",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "GW",
    shoppersCountry: "Guinea-Bissau",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "GY",
    shoppersCountry: "Guyana",
    currency: "USD",
  },
  {
    shoppersCountryCode: "HT",
    shoppersCountry: "Haiti",
    currency: "USD",
  },
  {
    shoppersCountryCode: "HN",
    shoppersCountry: "Honduras",
    currency: "USD",
  },
  {
    shoppersCountryCode: "ID",
    shoppersCountry: "Indonesia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "IE",
    shoppersCountry: "Ireland",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "IM",
    shoppersCountry: "Isle of Man",
    currency: "GBP",
  },
  {
    shoppersCountryCode: "IL",
    shoppersCountry: "Israel",
    currency: "USD",
  },
  {
    shoppersCountryCode: "IT",
    shoppersCountry: "Italy",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "JM",
    shoppersCountry: "Jamaica",
    currency: "USD",
  },
  {
    shoppersCountryCode: "JP",
    shoppersCountry: "Japan",
    currency: "JPY",
  },
  {
    shoppersCountryCode: "JE",
    shoppersCountry: "Jersey",
    currency: "GBP",
  },
  {
    shoppersCountryCode: "JO",
    shoppersCountry: "Jordan",
    currency: "USD",
  },
  {
    shoppersCountryCode: "KZ",
    shoppersCountry: "Kazakhstan",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "KE",
    shoppersCountry: "Kenya",
    currency: "USD",
  },
  {
    shoppersCountryCode: "KR",
    shoppersCountry: "Korea, South",
    currency: "USD",
  },
  {
    shoppersCountryCode: "KG",
    shoppersCountry: "Kyrgyzstan",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "LV",
    shoppersCountry: "Latvia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "LS",
    shoppersCountry: "Lesotho",
    currency: "ZAR",
  },
  {
    shoppersCountryCode: "LR",
    shoppersCountry: "Liberia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "LI",
    shoppersCountry: "Liechtenstein",
    currency: "CHF",
  },
  {
    shoppersCountryCode: "LT",
    shoppersCountry: "Lithuania",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "LU",
    shoppersCountry: "Luxembourg",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MK",
    shoppersCountry: "Macedonia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MG",
    shoppersCountry: "Madagascar",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MW",
    shoppersCountry: "Malawi",
    currency: "USD",
  },
  {
    shoppersCountryCode: "MV",
    shoppersCountry: "Maldives",
    currency: "USD",
  },
  {
    shoppersCountryCode: "ML",
    shoppersCountry: "Mali",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MT",
    shoppersCountry: "Malta",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MH",
    shoppersCountry: "Marshall Islands",
    currency: "USD",
  },
  {
    shoppersCountryCode: "MQ",
    shoppersCountry: "Martinique",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MR",
    shoppersCountry: "Mauritania",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MU",
    shoppersCountry: "Mauritius",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "YT",
    shoppersCountry: "Mayotte",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "FM",
    shoppersCountry: "Micronesia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "MD",
    shoppersCountry: "Moldova",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "MC",
    shoppersCountry: "Monaco",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "ME",
    shoppersCountry: "Montenegro",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MS",
    shoppersCountry: "Montserrat",
    currency: "USD",
  },
  {
    shoppersCountryCode: "MA",
    shoppersCountry: "Morocco",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "MZ",
    shoppersCountry: "Mozambique",
    currency: "ZAR",
  },
  {
    shoppersCountryCode: "NA",
    shoppersCountry: "Namibia",
    currency: "ZAR",
  },
  {
    shoppersCountryCode: "NP",
    shoppersCountry: "Nepal",
    currency: "USD",
  },
  {
    shoppersCountryCode: "NL",
    shoppersCountry: "Netherlands",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "AN",
    shoppersCountry: "Netherlands Antilles",
    currency: "USD",
  },
  {
    shoppersCountryCode: "NC",
    shoppersCountry: "New Caledonia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "NZ",
    shoppersCountry: "New Zealand",
    currency: "NZD",
  },
  {
    shoppersCountryCode: "NI",
    shoppersCountry: "Nicaragua",
    currency: "USD",
  },
  {
    shoppersCountryCode: "NE",
    shoppersCountry: "Niger",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "NG",
    shoppersCountry: "Nigeria",
    currency: "USD",
  },
  {
    shoppersCountryCode: "NU",
    shoppersCountry: "Niue",
    currency: "NZD",
  },
  {
    shoppersCountryCode: "MP",
    shoppersCountry: "Northern Mariana Islands",
    currency: "USD",
  },
  {
    shoppersCountryCode: "NO",
    shoppersCountry: "Norway",
    currency: "NOK",
  },
  {
    shoppersCountryCode: "OM",
    shoppersCountry: "Oman",
    currency: "USD",
  },
  {
    shoppersCountryCode: "PK",
    shoppersCountry: "Pakistan",
    currency: "USD",
  },
  {
    shoppersCountryCode: "PW",
    shoppersCountry: "Palau",
    currency: "USD",
  },
  {
    shoppersCountryCode: "PA",
    shoppersCountry: "Panama",
    currency: "USD",
  },
  {
    shoppersCountryCode: "PY",
    shoppersCountry: "Paraguay",
    currency: "USD",
  },
  {
    shoppersCountryCode: "PE",
    shoppersCountry: "Peru",
    currency: "USD",
  },
  {
    shoppersCountryCode: "PH",
    shoppersCountry: "Philippines",
    currency: "USD",
  },
  {
    shoppersCountryCode: "PN",
    shoppersCountry: "Pitcairn",
    currency: "NZD",
  },
  {
    shoppersCountryCode: "PL",
    shoppersCountry: "Poland",
    currency: "PLN",
  },
  {
    shoppersCountryCode: "PT",
    shoppersCountry: "Portugal",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "PR",
    shoppersCountry: "Puerto Rico",
    currency: "USD",
  },
  {
    shoppersCountryCode: "RE",
    shoppersCountry: "Reunion",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "RO",
    shoppersCountry: "Romania",
    currency: "RON",
  },
  {
    shoppersCountryCode: "RW",
    shoppersCountry: "Rwanda",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "SH",
    shoppersCountry: "Saint Helena",
    currency: "GBP",
  },
  {
    shoppersCountryCode: "KN",
    shoppersCountry: "Saint Kitts and Nevis",
    currency: "USD",
  },
  {
    shoppersCountryCode: "LC",
    shoppersCountry: "Saint Lucia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "MF",
    shoppersCountry: "Saint Martin (French)",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "PM",
    shoppersCountry: "Saint Pierre and Miquelon",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "VC",
    shoppersCountry: "Saint Vincent and The Grenadines",
    currency: "USD",
  },
  {
    shoppersCountryCode: "SM",
    shoppersCountry: "San Marino",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "ST",
    shoppersCountry: "Sao Tome and Principe",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "SA",
    shoppersCountry: "Saudi Arabia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "SN",
    shoppersCountry: "Senegal",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "RS",
    shoppersCountry: "Serbia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "SC",
    shoppersCountry: "Seychelles",
    currency: "USD",
  },
  {
    shoppersCountryCode: "SL",
    shoppersCountry: "Sierra Leone",
    currency: "USD",
  },
  {
    shoppersCountryCode: "SX",
    shoppersCountry: "Sint Maarten (Dutch)",
    currency: "USD",
  },
  {
    shoppersCountryCode: "SK",
    shoppersCountry: "Slovakia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "SI",
    shoppersCountry: "Slovenia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "SO",
    shoppersCountry: "Somalia",
    currency: "USD",
  },
  {
    shoppersCountryCode: "ZA",
    shoppersCountry: "South Africa",
    currency: "USD",
  },
  {
    shoppersCountryCode: "ES",
    shoppersCountry: "Spain",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "LK",
    shoppersCountry: "Sri Lanka",
    currency: "USD",
  },
  {
    shoppersCountryCode: "SR",
    shoppersCountry: "Suriname",
    currency: "USD",
  },
  {
    shoppersCountryCode: "SJ",
    shoppersCountry: "Svalbard and Jan Mayen",
    currency: "NOK",
  },
  {
    shoppersCountryCode: "SZ",
    shoppersCountry: "Swaziland",
    currency: "ZAR",
  },
  {
    shoppersCountryCode: "TJ",
    shoppersCountry: "Tajikistan",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "TZ",
    shoppersCountry: "Tanzania",
    currency: "USD",
  },
  {
    shoppersCountryCode: "TH",
    shoppersCountry: "Thailand",
    currency: "USD",
  },
  {
    shoppersCountryCode: "TG",
    shoppersCountry: "Togo",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "TK",
    shoppersCountry: "Tokelau",
    currency: "NZD",
  },
  {
    shoppersCountryCode: "TT",
    shoppersCountry: "Trinidad and Tobago",
    currency: "USD",
  },
  {
    shoppersCountryCode: "TN",
    shoppersCountry: "Tunisia",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "TM",
    shoppersCountry: "Turkmenistan",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "TC",
    shoppersCountry: "Turks and Caicos Islands",
    currency: "USD",
  },
  {
    shoppersCountryCode: "UG",
    shoppersCountry: "Uganda",
    currency: "USD",
  },
  {
    shoppersCountryCode: "UA",
    shoppersCountry: "Ukraine",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "AE",
    shoppersCountry: "United Arab Emirates",
    currency: "AED",
  },
  {
    shoppersCountryCode: "GB",
    shoppersCountry: "United Kingdom",
    currency: "GBP",
  },
  {
    shoppersCountryCode: "UY",
    shoppersCountry: "Uruguay",
    currency: "USD",
  },
  {
    shoppersCountryCode: "UZ",
    shoppersCountry: "Uzbekistan",
    currency: "RUB",
  },
  {
    shoppersCountryCode: "VA",
    shoppersCountry: "Vatican State",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "VE",
    shoppersCountry: "Venezuela",
    currency: "USD",
  },
  {
    shoppersCountryCode: "VG",
    shoppersCountry: "Virgin Islands, British",
    currency: "USD",
  },
  {
    shoppersCountryCode: "VI",
    shoppersCountry: "Virgin Islands, U.S.",
    currency: "USD",
  },
  {
    shoppersCountryCode: "WF",
    shoppersCountry: "Wallis and Futana",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "EH",
    shoppersCountry: "Western Sahara",
    currency: "EUR",
  },
  {
    shoppersCountryCode: "ZM",
    shoppersCountry: "Zambia",
    currency: "ZAR",
  },
  {
    shoppersCountryCode: "ZW",
    shoppersCountry: "Zimbabwe",
    currency: "ZAR",
  },
];

export const bluesnapSupportedBrazilState = [
  {
    StateCode: "AC",
    StateName: "Acre",
  },
  {
    StateCode: "XH",
    StateName: "Alagoas",
  },
  {
    StateCode: "XI",
    StateName: "Amapá",
  },
  {
    StateCode: "AM",
    StateName: "Amazonas",
  },
  {
    StateCode: "BA",
    StateName: "Bahia",
  },
  {
    StateCode: "CE",
    StateName: "Ceará",
  },
  {
    StateCode: "DF",
    StateName: "Distrito Federal",
  },
  {
    StateCode: "ES",
    StateName: "Espírito Santo",
  },
  {
    StateCode: "GO",
    StateName: "Goiás",
  },
  {
    StateCode: "XA",
    StateName: "Maranhão",
  },
  {
    StateCode: "XB",
    StateName: "	Mato Grosso do Sul",
  },
  {
    StateCode: "XC",
    StateName: "Mato Grosso",
  },
  {
    StateCode: "MG",
    StateName: "Minas Gerais",
  },
  {
    StateCode: "XE",
    StateName: "Pará",
  },
  {
    StateCode: "XF",
    StateName: "Pernambuco",
  },
  {
    StateCode: "XG",
    StateName: "Paraná",
  },
  {
    StateCode: "PB",
    StateName: "Paraíba",
  },
  {
    StateCode: "PI",
    StateName: "Piauí",
  },
  {
    StateCode: "RJ",
    StateName: "Rio de Janeiro",
  },
  {
    StateCode: "RN",
    StateName: "Rio Grande do Norte",
  },
  {
    StateCode: "RS",
    StateName: "Rio Grande do Sull",
  },
  {
    StateCode: "RO",
    StateName: "Rondônia",
  },
  {
    StateCode: "RR",
    StateName: "Roraima",
  },
  {
    StateCode: "XD",
    StateName: "Santa Catarina",
  },
  {
    StateCode: "SP",
    StateName: "São Paulo",
  },
  {
    StateCode: "SE",
    StateName: "Sergipe",
  },
  {
    StateCode: "TO",
    StateName: "Tocantins",
  },
];

export const bluesnapSupportedCanadaState = [
  {
    StateCode: "AB",
    StateName: "Alberta",
  },
  {
    StateCode: "BC",
    StateName: "British Columbia",
  },
  {
    StateCode: "MB",
    StateName: "Manitoba",
  },
  {
    StateCode: "NB",
    StateName: "New Brunswick",
  },
  {
    StateCode: "NL",
    StateName: "Newfoundland and Labrador",
  },
  {
    StateCode: "NS",
    StateName: "Nova Scotia",
  },
  {
    StateCode: "NT",
    StateName: "Northwest Territories",
  },
  {
    StateCode: "NU",
    StateName: "Nunavut",
  },
  {
    StateCode: "ON",
    StateName: "Ontario",
  },
  {
    StateCode: "PE",
    StateName: "Prince Edward Island",
  },
  {
    StateCode: "QC",
    StateName: "Quebec",
  },
  {
    StateCode: "SK",
    StateName: "Saskatchewan",
  },
  {
    StateCode: "YT",
    StateName: "Yukon Territory",
  },
];

export const bluesnapSupportedUnitedStatesState = [
  {
    StateCode: "AA",
    StateName: "Armed Forces Americas",
  },
  {
    StateCode: "AE",
    StateName: "Armed Forces (AE)",
  },
  {
    StateCode: "AK",
    StateName: "Alaska",
  },
  {
    StateCode: "AL",
    StateName: "Alabama",
  },
  {
    StateCode: "AP",
    StateName: "Armed Forces Pacific",
  },
  {
    StateCode: "AR",
    StateName: "Arkansas",
  },
  {
    StateCode: "AS",
    StateName: "American Samoa",
  },
  {
    StateCode: "AZ",
    StateName: "Arizona",
  },
  {
    StateCode: "CA",
    StateName: "California",
  },
  {
    StateCode: "CO",
    StateName: "Colorado",
  },
  {
    StateCode: "CT",
    StateName: "Connecticut",
  },
  {
    StateCode: "DC",
    StateName: "District Of Columbia",
  },
  {
    StateCode: "DE",
    StateName: "Delaware",
  },
  {
    StateCode: "FL",
    StateName: "Florida",
  },
  {
    StateCode: "GA",
    StateName: "Georgia",
  },
  {
    StateCode: "GU",
    StateName: "Guam",
  },
  {
    StateCode: "HI",
    StateName: "Hawaii",
  },
  {
    StateCode: "IA",
    StateName: "Iowa",
  },
  {
    StateCode: "ID",
    StateName: "Idaho",
  },
  {
    StateCode: "IL",
    StateName: "Illinois",
  },
  {
    StateCode: "IN",
    StateName: "Indiana",
  },
  {
    StateCode: "KS",
    StateName: "Kansas",
  },
  {
    StateCode: "KY",
    StateName: "Kentucky",
  },
  {
    StateCode: "LA",
    StateName: "Louisiana",
  },
  {
    StateCode: "MA",
    StateName: "Massachusetts",
  },
  {
    StateCode: "MD",
    StateName: "Maryland",
  },
  {
    StateCode: "ME",
    StateName: "Maine",
  },
  {
    StateCode: "MI",
    StateName: "Michigan",
  },
  {
    StateCode: "MN",
    StateName: "Minnesota",
  },
  {
    StateCode: "MO",
    StateName: "Missouri",
  },
  {
    StateCode: "MS",
    StateName: "Mississippi",
  },
  {
    StateCode: "MT",
    StateName: "Montana",
  },
  {
    StateCode: "NC",
    StateName: "North Carolina",
  },
  {
    StateCode: "ND",
    StateName: "North Dakota",
  },
  {
    StateCode: "NE",
    StateName: "Nebraska",
  },
  {
    StateCode: "NH",
    StateName: "New Hampshire",
  },
  {
    StateCode: "NJ",
    StateName: "New Jersey",
  },
  {
    StateCode: "NM",
    StateName: "New Mexico",
  },
  {
    StateCode: "NV",
    StateName: "Nevada",
  },
  {
    StateCode: "NY",
    StateName: "New York",
  },
  {
    StateCode: "OH",
    StateName: "Ohio",
  },
  {
    StateCode: "OK",
    StateName: "Oklahoma",
  },
  {
    StateCode: "OR",
    StateName: "Oregon",
  },
  {
    StateCode: "PA",
    StateName: "Pennsylvania",
  },
  {
    StateCode: "PR",
    StateName: "Puerto Rico",
  },
  {
    StateCode: "RI",
    StateName: "Rhode Island",
  },
  {
    StateCode: "SC",
    StateName: "South Carolina",
  },
  {
    StateCode: "SD",
    StateName: "South Dakota",
  },
  {
    StateCode: "TN",
    StateName: "Tennessee",
  },
  {
    StateCode: "TX",
    StateName: "Texas",
  },
  {
    StateCode: "UT",
    StateName: "Utah",
  },
  {
    StateCode: "VA",
    StateName: "Virginia",
  },
  {
    StateCode: "VI",
    StateName: "Virgin Islands",
  },
  {
    StateCode: "VT",
    StateName: "Vermont",
  },
  {
    StateCode: "WA",
    StateName: "Washington",
  },
  {
    StateCode: "WI",
    StateName: "Wisconsin",
  },
  {
    StateCode: "WV",
    StateName: "West Virginia",
  },
  {
    StateCode: "WY",
    StateName: "Wyoming",
  },
];

export const bluesnapSupportedCountries = [
  { Code: "ad", Country: "Andorra" },
  { Code: "ae", Country: "United Arab Emirates" },
  { Code: "ag", Country: "Antigua and Barbuda" },
  { Code: "ai", Country: "Anguilla" },
  { Code: "al", Country: "Albania" },
  { Code: "am", Country: "Armenia" },
  { Code: "ao", Country: "Angola" },
  { Code: "aq", Country: "Antarctica" },
  { Code: "ar", Country: "Argentina" },
  { Code: "as", Country: "American Samoa" },
  { Code: "at", Country: "Austria" },
  { Code: "au", Country: "Australia" },
  { Code: "aw", Country: "Aruba" },
  { Code: "az", Country: "Azerbaijan" },
  { Code: "ba", Country: "Bosnia-Herzegovina" },
  { Code: "bb", Country: "Barbados" },
  { Code: "bd", Country: "Bangladesh" },
  { Code: "be", Country: "Belgium" },
  { Code: "bf", Country: "Burkina Faso" },
  { Code: "bg", Country: "Bulgaria" },
  { Code: "bh", Country: "Bahrain" },
  { Code: "bi", Country: "Burundi" },
  { Code: "bj", Country: "Benin" },
  { Code: "bm", Country: "Bermuda" },
  { Code: "bn", Country: "Brunei Darussalam" },
  { Code: "bo", Country: "Bolivia" },
  { Code: "br", Country: "Brazil" },
  { Code: "bs", Country: "Bahamas" },
  { Code: "bt", Country: "Bhutan" },
  { Code: "bv", Country: "Bouvet Island" },
  { Code: "bw", Country: "Botswana" },
  { Code: "by", Country: "Belarus" },
  { Code: "bz", Country: "Belize" },
  { Code: "ca", Country: "Canada" },
  { Code: "cc", Country: "Cocos (Keeling) Islands" },
  { Code: "cd", Country: "Congo (Brazzaville)" },
  { Code: "cf", Country: "Central African Republic" },
  { Code: "cg", Country: "Congo" },
  { Code: "ch", Country: "Switzerland" },
  { Code: "ci", Country: "Ivory Coast (Cote D'Ivoire)" },
  { Code: "ck", Country: "Cook Islands" },
  { Code: "cl", Country: "Chile" },
  { Code: "cm", Country: "Cameroon" },
  { Code: "cn", Country: "China" },
  { Code: "cr", Country: "Costa Rica" },
  { Code: "cv", Country: "Cape Verde" },
  { Code: "cw", Country: "Curacao" },
  { Code: "cx", Country: "Christmas Island" },
  { Code: "cy", Country: "Cyprus" },
  { Code: "cz", Country: "Czech Republic" },
  { Code: "de", Country: "Germany" },
  { Code: "dj", Country: "Djibouti" },
  { Code: "dk", Country: "Denmark" },
  { Code: "dm", Country: "Dominica" },
  { Code: "do", Country: "Dominican Republic" },
  { Code: "dz", Country: "Algeria" },
  { Code: "ec", Country: "Ecuador" },
  { Code: "ee", Country: "Estonia" },
  { Code: "eg", Country: "Egypt" },
  { Code: "eh", Country: "Western Sahara" },
  { Code: "er", Country: "Eritrea" },
  { Code: "es", Country: "Spain" },
  { Code: "et", Country: "Ethiopia" },
  { Code: "fi", Country: "Finland" },
  { Code: "fj", Country: "Fiji" },
  { Code: "fk", Country: "Falkland Islands" },
  { Code: "fm", Country: "Micronesia" },
  { Code: "fo", Country: "Faroe Islands" },
  { Code: "fr", Country: "France" },
  { Code: "ga", Country: "Gabon" },
  { Code: "gb", Country: "Great Britain" },
  { Code: "gb", Country: "United Kingdom" },
  { Code: "gd", Country: "Grenada" },
  { Code: "ge", Country: "Georgia" },
  { Code: "gf", Country: "French Guiana" },
  { Code: "gg", Country: "Guernsey" },
  { Code: "gh", Country: "Ghana" },
  { Code: "gi", Country: "Gibraltar" },
  { Code: "gl", Country: "Greenland" },
  { Code: "gm", Country: "Gambia" },
  { Code: "gn", Country: "Guinea" },
  { Code: "gp", Country: "Guadeloupe (French)" },
  { Code: "gq", Country: "Equatorial Guinea" },
  { Code: "gr", Country: "Greece" },
  { Code: "gs", Country: "S. Georgia & S. Sandwich Isls." },
  { Code: "gt", Country: "Guatemala" },
  { Code: "gu", Country: "Guam (USA)" },
  { Code: "gw", Country: "Guinea Bissau" },
  { Code: "gy", Country: "Guyana" },
  { Code: "hk", Country: "Hong Kong" },
  { Code: "hm", Country: "Heard and McDonald Islands" },
  { Code: "hn", Country: "Honduras" },
  { Code: "hr", Country: "Croatia" },
  { Code: "ht", Country: "Haiti" },
  { Code: "hu", Country: "Hungary" },
  { Code: "id", Country: "Indonesia" },
  { Code: "ie", Country: "Ireland" },
  { Code: "il", Country: "Israel" },
  { Code: "im", Country: "Isle of Man" },
  { Code: "in", Country: "India" },
  { Code: "io", Country: "British Indian Ocean Territory" },
  { Code: "is", Country: "Iceland" },
  { Code: "it", Country: "Italy" },
  { Code: "je", Country: "Jersey" },
  { Code: "jm", Country: "Jamaica" },
  { Code: "jo", Country: "Jordan" },
  { Code: "jp", Country: "Japan" },
  { Code: "ke", Country: "Kenya" },
  { Code: "kg", Country: "Kyrgyz Republic (Kyrgyzstan)" },
  { Code: "kh", Country: "Cambodia" },
  { Code: "ki", Country: "Kiribati" },
  { Code: "km", Country: "Comoros" },
  { Code: "kn", Country: "Saint Kitts & Nevis Anguilla" },
  { Code: "kr", Country: "South Korea" },
  { Code: "kw", Country: "Kuwait" },
  { Code: "ky", Country: "Cayman Islands" },
  { Code: "kz", Country: "Kazakhstan" },
  { Code: "la", Country: "Laos" },
  { Code: "lc", Country: "Saint Lucia" },
  { Code: "li", Country: "Liechtenstein" },
  { Code: "lk", Country: "Sri Lanka" },
  { Code: "lr", Country: "Liberia" },
  { Code: "ls", Country: "Lesotho" },
  { Code: "lt", Country: "Lithuania" },
  { Code: "lu", Country: "Luxembourg" },
  { Code: "lv", Country: "Latvia" },
  { Code: "ma", Country: "Morocco" },
  { Code: "mc", Country: "Monaco" },
  { Code: "md", Country: "Moldova" },
  { Code: "me", Country: "Montenegro" },
  { Code: "mf", Country: "Saint Martin" },
  { Code: "mg", Country: "Madagascar" },
  { Code: "mh", Country: "Marshall Islands" },
  { Code: "mk", Country: "Macedonia" },
  { Code: "ml", Country: "Mali" },
  { Code: "mn", Country: "Mongolia" },
  { Code: "mo", Country: "Macao" },
  { Code: "mp", Country: "Northern Mariana Islands" },
  { Code: "mq", Country: "Martinique (French)" },
  { Code: "mr", Country: "Mauritania" },
  { Code: "ms", Country: "Montserrat" },
  { Code: "mt", Country: "Malta" },
  { Code: "mu", Country: "Mauritius" },
  { Code: "mv", Country: "Maldives" },
  { Code: "mw", Country: "Malawi" },
  { Code: "mx", Country: "Mexico" },
  { Code: "my", Country: "Malaysia" },
  { Code: "mz", Country: "Mozambique" },
  { Code: "na", Country: "Namibia" },
  { Code: "nc", Country: "New Caledonia (French)" },
  { Code: "ne", Country: "Niger" },
  { Code: "nf", Country: "Norfolk Island" },
  { Code: "ng", Country: "Nigeria" },
  { Code: "ni", Country: "Nicaragua" },
  { Code: "nl", Country: "Netherlands" },
  { Code: "no", Country: "Norway" },
  { Code: "np", Country: "Nepal" },
  { Code: "nr", Country: "Nauru" },
  { Code: "nu", Country: "Niue" },
  { Code: "nz", Country: "New Zealand" },
  { Code: "om", Country: "Oman" },
  { Code: "pa", Country: "Panama" },
  { Code: "pe", Country: "Peru" },
  { Code: "pf", Country: "Polynesia (French)" },
  { Code: "pg", Country: "Papua New Guinea" },
  { Code: "ph", Country: "Philippines" },
  { Code: "pk", Country: "Pakistan" },
  { Code: "pl", Country: "Poland" },
  { Code: "pm", Country: "Saint Pierre and Miquelon" },
  { Code: "pn", Country: "Pitcairn Island" },
  { Code: "pr", Country: "Puerto Rico" },
  { Code: "ps", Country: "Palestine" },
  { Code: "pt", Country: "Portugal" },
  { Code: "pw", Country: "Palau" },
  { Code: "py", Country: "Paraguay" },
  { Code: "qa", Country: "Qatar" },
  { Code: "re", Country: "Reunion (French)" },
  { Code: "ro", Country: "Romania" },
  { Code: "rs", Country: "Serbia" },
  { Code: "ru", Country: "Russian Federation" },
  { Code: "rw", Country: "Rwanda" },
  { Code: "sa", Country: "Saudi Arabia" },
  { Code: "sb", Country: "Solomon Islands" },
  { Code: "sc", Country: "Seychelles" },
  { Code: "se", Country: "Sweden" },
  { Code: "sg", Country: "Singapore" },
  { Code: "sh", Country: "Saint Helena" },
  { Code: "si", Country: "Slovenia" },
  { Code: "sj", Country: "Svalbard and Jan Mayen Islands" },
  { Code: "sk", Country: "Slovak Republic" },
  { Code: "sl", Country: "Sierra Leone" },
  { Code: "sm", Country: "San Marino" },
  { Code: "sn", Country: "Senegal" },
  { Code: "so", Country: "Somalia" },
  { Code: "sr", Country: "Suriname" },
  { Code: "st", Country: "Saint Tome (Sao Tome) and Principe" },
  { Code: "sv", Country: "El Salvador" },
  { Code: "sx", Country: "Sint Maarten" },
  { Code: "sz", Country: "Swaziland" },
  { Code: "tc", Country: "Turks and Caicos Islands" },
  { Code: "td", Country: "Chad" },
  { Code: "tf", Country: "French Southern Territories" },
  { Code: "tg", Country: "Togo" },
  { Code: "th", Country: "Thailand" },
  { Code: "tj", Country: "Tadjikistan" },
  { Code: "tk", Country: "Tokelau" },
  { Code: "tm", Country: "Turkmenistan" },
  { Code: "tn", Country: "Tunisia" },
  { Code: "to", Country: "Tonga" },
  { Code: "tl", Country: "East Timor" },
  { Code: "tr", Country: "Turkey" },
  { Code: "tt", Country: "Trinidad and Tobago" },
  { Code: "tv", Country: "Tuvalu" },
  { Code: "tw", Country: "Taiwan" },
  { Code: "tz", Country: "Tanzania" },
  { Code: "ua", Country: "Ukraine" },
  { Code: "ug", Country: "Uganda" },
  { Code: "us", Country: "United States" },
  { Code: "uy", Country: "Uruguay" },
  { Code: "uz", Country: "Uzbekistan" },
  { Code: "va", Country: "Holy See (Vatican City State)" },
  { Code: "vc", Country: "Saint Vincent & Grenadines" },
  { Code: "ve", Country: "Venezuela" },
  { Code: "vg", Country: "Virgin Islands (British)" },
  { Code: "vi", Country: "Virgin Islands (USA)" },
  { Code: "vn", Country: "Vietnam" },
  { Code: "vu", Country: "Vanuatu" },
  { Code: "wf", Country: "Wallis and Futuna Islands" },
  { Code: "ws", Country: "Samoa" },
  { Code: "yt", Country: "Mayotte" },
  { Code: "za", Country: "South Africa" },
  { Code: "zm", Country: "Zambia" },
  { Code: "zw", Country: "Zimbabwe" },
];

export const bluesnapSupportedCurrencies = [
  { currency: "AFN" },
  { currency: "ALL" },
  { currency: "DZD" },
  { currency: "ARS" },
  { currency: "AMD" },
  { currency: "AWG" },
  { currency: "AUD" },
  { currency: "BSD" },
  { currency: "BHD" },
  { currency: "BBD" },
  { currency: "BYR" },
  { currency: "BMD" },
  { currency: "BOB" },
  { currency: "BAM" },
  { currency: "BWP" },
  { currency: "BRL" },
  { currency: "GBP" },
  { currency: "BND" },
  { currency: "BGN" },
  { currency: "KHR" },
  { currency: "CAD" },
  { currency: "XOF" },
  { currency: "CLP" },
  { currency: "CNY" },
  { currency: "COP" },
  { currency: "CRC" },
  { currency: "HRK" },
  { currency: "CZK" },
  { currency: "DKK" },
  { currency: "DOP" },
  { currency: "XCD" },
  { currency: "EGP" },
  { currency: "EUR" },
  { currency: "FJD" },
  { currency: "GEL" },
  { currency: "GIP" },
  { currency: "GTQ" },
  { currency: "HKD" },
  { currency: "HUF" },
  { currency: "ISK" },
  { currency: "INR" },
  { currency: "IDR" },
  { currency: "ILS" },
  { currency: "JMD" },
  { currency: "JPY" },
  { currency: "JOD" },
  { currency: "KZT" },
  { currency: "KES" },
  { currency: "KWD" },
  { currency: "LBP" },
  { currency: "MKD" },
  { currency: "MWK" },
  { currency: "MYR" },
  { currency: "MRO" },
  { currency: "MUR" },
  { currency: "MXN" },
  { currency: "MDL" },
  { currency: "MAD" },
  { currency: "NAD" },
  { currency: "NPR" },
  { currency: "ANG" },
  { currency: "NZD" },
  { currency: "NGN" },
  { currency: "NOK" },
  { currency: "OMR" },
  { currency: "PKR" },
  { currency: "PAB" },
  { currency: "PGK" },
  { currency: "PEN" },
  { currency: "PHP" },
  { currency: "PLN" },
  { currency: "QAR" },
  { currency: "RON" },
  { currency: "RUB" },
  { currency: "SAR" },
  { currency: "RSD" },
  { currency: "SCR" },
  { currency: "SGD" },
  { currency: "ZAR" },
  { currency: "KRW" },
  { currency: "LKR" },
  { currency: "SDG" },
  { currency: "SEK" },
  { currency: "CHF" },
  { currency: "TWD" },
  { currency: "TZS" },
  { currency: "THB" },
  { currency: "TTD" },
  { currency: "TND" },
  { currency: "TRY" },
  { currency: "UAH" },
  { currency: "AED" },
  { currency: "USD" },
  { currency: "UYU" },
  { currency: "UZS" },
  { currency: "VEF" },
  { currency: "VND" },
];
