import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Col, Container, Row } from "reactstrap";
import { addFilter, getProducts } from "../../redux/reducers/seller";
import { paymentConfig } from "../../redux/reducers/auth";
import { SetState, getTranslation, getYinnExhangeRate } from "../../redux/reducers/user";
import { getWallet } from "../../redux/reducers/user";
import "../../";
import buyCoinSrc from "../../assets/img/yinn-coin/buy-coin.svg";
import paymentSrc from "../../assets/img/yinn-coin/payment.svg";
import paymentActiveSrc from "../../assets/img/yinn-coin/payment-active.svg";

import { toast } from "react-toastify";
import Packages from "../../views/Auth/YinnCoin/Packages";
import CoinPayment from "../../views/Auth/YinnCoin/CoinPayment";
import CoinConfirm from "../../views/Auth/YinnCoin/CoinConfirm";

const UserPurchaseCoin = () => {
  const dispatch = useDispatch();

  const {translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];
  const walletTranslation = translationState?.[0]?.translations?.wallet?.[userLanguage]

  const [salesPrice, setSalesPrice] = useState(0);
  const [coins, setCoins] = useState(null);
  const [selectedPackageId, setSelectedPackageId] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [step, setStep] = useState(1);
  const { products } = useSelector((state) => state.seller);
  const { wallet } = useSelector((state) => state.user);
  const { paymentConfiguration } = useSelector((state) => state.auth);
  useEffect(() => {
    if (step === 1) {
      dispatch(SetState({ field: "paymentConfirm", value: false }));
    }
  }, [step]);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: "YINN-COIN",
      })
    );
    dispatch(getProducts());
    dispatch(getWallet());
    dispatch(paymentConfig());
    dispatch(getYinnExhangeRate());
  }, []);
  const goToPaymentPage = () => {
    if (salesPrice > 0) {
      setStep(2);
    } else {
      toast.error(`${toastTranslation?.success ? toastTranslation?.success : "Order Status Updated Successfully"}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const selectPackage = (pkg) => {
    setSelectedPackageId(pkg._id);
    setSelectedPackage(pkg);
  };

  const yinnPercentage = (
    paymentConfiguration?.yinncoinPurchasePercentageCharge * salesPrice
  ).toFixed(2);

  const totalPrice = (
    Number(yinnPercentage) +
    salesPrice +
    paymentConfiguration?.coinMaintenanceCharge
  ).toFixed(2);
  return (
    <>
      <div className="row">
        <div className="checkout-top-bar">
          <div className="checkout-top-bar-inner">
            <div className="checkout-top-bar-inner-section">
              <img
                className="checkout-top-bar-inner-section-img"
                src={buyCoinSrc}
              />
              <div className="hide-text-on-mobile checkout-top-bar-inner-section-text-completed">
              {walletTranslation?.buyCoin ? walletTranslation?.buyCoin : "BUY COINS"}
              </div>
            </div>
            <div className="checkout-top-bar-inner-section-last">
              <img
                className="checkout-top-bar-inner-section-img"
                src={step < 2 ? paymentSrc : paymentActiveSrc}
              />
              <div
                className={
                  step < 2
                    ? "checkout-top-bar-inner-section-text hide-text-on-mobile"
                    : "checkout-top-bar-inner-section-text-completed hide-text-on-mobile"
                }
              >
               {walletTranslation?.payment ? walletTranslation?.payment : "PAYMENT"} 
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-2 row">
        <div className="">
          {step === 1 && (
            <div>
              <div className="yinn-coin-top-bar">
                <div>
                {walletTranslation?.haveCoin ? `${wallet.coinBalance}`+walletTranslation?.haveCoin :
                `${wallet.coinBalance}`+" coins you have. Buy more to keep enjoying all the benefits of Yinn coin."
                 }
                </div>
                <button
                  onClick={goToPaymentPage}
                  style={{ border: "none" }}
                  className="yinn-coin-button"
                >
                  {walletTranslation?.butIt ? walletTranslation?.butIt : "Buy it now"} 
                </button>
              </div>
              <Packages
                packageList={products}
                selectPackage={selectPackage}
                selectedPkg={selectedPackageId}
                paymentConfiguration={paymentConfiguration}
                salesPrice={salesPrice}
                setSalesPrice={setSalesPrice}
                coins={coins}
                setCoins={setCoins}
                yinnPercentage={yinnPercentage}
                totalPrice={totalPrice}
              />
            </div>
          )}
        </div>
      </div>
      {(step === 2 || step === 3) && (
        <div className="row checkout-content">
          <div className="">
            {step === 2 && (
              <CoinPayment
                moveBack={() => setStep(1)}
                showConfirmation={() => setStep(3)}
                selectedPkg={selectedPackage}
                salesPrice={salesPrice}
                paymentConfiguration={paymentConfiguration}
                coins={coins}
                yinnPercentage={yinnPercentage}
                totalPrice={totalPrice}
                setSalesPrice={setSalesPrice}
              />
            )}
            {step === 3 && (
              <CoinConfirm selectedPkg={selectedPackage} coins={coins} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserPurchaseCoin;
