import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateSingleDispute,
  getsingleDispute,
} from "../../../redux/reducers/seller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  InputGroup,
  InputGroupAddon,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import "./updateDispute.css";

const UpdateDispute = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlArray = history.location.pathname.split("/");
  const disputeId = urlArray[urlArray.length - 1];
  const [pageContent, setPageContent] = useState({
    message: "",
  });
  const { singleDispute } = useSelector((state) => state.seller);
  useEffect(() => {
    dispatch(getsingleDispute(disputeId));
  }, []);
  const disputeStatus = ["Pending", "In Progress", "Resolved"];
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const updateStatus = (event) => {
    const status = event.target.value;
    const data = {
      status,
      disputeId,
      type: "status",
    };
    dispatch(updateSingleDispute(data)).then(() => {
      dispatch(getsingleDispute(disputeId));
    });
  };
  const sendAnnouncement = () => {
    const { message } = pageContent;
    const data = {
      disputeId,
      message,
      type: "announcement",
    };
    dispatch(updateSingleDispute(data)).then(() => {
      dispatch(getsingleDispute(disputeId));
      setPageContent({ message: "" });
    });
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col xs="12" lg="12">
          <Card className="mt-5">
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <i className="fa fa-align-justify"></i>
                  <b> Update Dispute</b>
                </Col>
                <Col xs="12" sm="2">
                  <Input
                    type="select"
                    onChange={updateStatus}
                    value={singleDispute.status}
                  >
                    {disputeStatus.map((disputeStatus, i) => (
                      <option value={disputeStatus}> {disputeStatus} </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Row>
                  <Col lg="12">
                    <div className="row">
                      <div className="col-sm-6 col-12">
                        <b>Messages</b>
                        {singleDispute.anouncements != undefined &&
                          singleDispute.anouncements.map((msg, i) => (
                            <FormGroup>
                              <Row>
                                <Col lg="4">
                                  <Label>
                                    <b>Administrator</b>
                                  </Label>
                                </Col>
                                <Col lg="8">
                                  <Label>{msg.message}</Label>
                                </Col>
                              </Row>
                            </FormGroup>
                          ))}
                      </div>
                      <div className="col-sm-6 col-12 d-flex justify-content-sm-end justify-content-start ">
                        <strong>Status :</strong>
                        <st>{singleDispute.status || "Pending"}</st>
                      </div>
                    </div>
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Type your message here"
                        name="message"
                        style={{
                          width: "100%",
                          height: "200px",
                          marginTop: "20px",
                        }}
                        size="100"
                        type="textarea"
                        value={pageContent.message}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    <Button
                      type="submit"
                      style={{
                        width: "200px",
                        borderRadious: "10px",
                        marginTop: "20px",
                      }}
                      onClick={sendAnnouncement}
                      color="success"
                      pill
                    >
                      Send
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateDispute;
