import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import PublicRoute from './publicRoute';
import '../App.css';
import '../scss/style.css'
import { Login, Register, Reset, Page404, AccountConfirmed, ConfirmAccount, AccountConfirmedNotice } from '../views';
import PrivateRoute from "./privateRoute";

const AuthRoute = () => {

  return (
    <Switch>
        <PublicRoute exact path="/auth/login" name="Login Page" component={Login} />
        <PublicRoute exact path="/auth/register" name="Register Page" component={Register} />
        <PublicRoute exact path="/auth/reset" name="Reset Page" component={Reset} />
        <PublicRoute exact path="/auth/account-confirm" name="Confirm Your Account" component={AccountConfirmedNotice} />
        <PublicRoute exact path="/auth/confirm-account" name="Confirm Account" component={ConfirmAccount} />
      {/* <PrivateRoute
        exact path="/auth/advertising"
        component={Success}
        withLayout={DashboardLayout}
      /> */}
      <Route path="/not-found" component={Page404} />
      <Redirect from='*' to='/not-found' />

    </Switch>
  );
};

export default AuthRoute;
