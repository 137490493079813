import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Label,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import "./copyrights.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClaimCopyright } from "../../../redux/reducers/auth";
import { AppHeader } from "@coreui/react";
import SkyLight from "react-skylight";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Copyrights = () => {
  const dispatch = useDispatch();
  const {translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];
  const copyRightTranslation = translationState?.[0]?.translations?.copyRight?.[userLanguage];
  const sellerHomeTranslation = translationState?.[0]?.translations?.sellerHome?.[userLanguage];
  const digitalProductTranslation = translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const otherProductTranslation = translationState?.[0]?.translations?.otherProduct?.[userLanguage];
  const reportingTranslation = translationState?.[0]?.translations?.reporting?.[userLanguage];
  const contectUsTranslation = translationState?.[0]?.translations?.contectUs?.[userLanguage];
  
  
  
  
  const copyRightModal = useRef(null);
  const copyrightTypes = [
    `${copyRightTranslation?.pointOne ? copyRightTranslation?.pointOne : 
    "has posted my copyrighted/trademarked work without my permission"}`,
    `${copyRightTranslation?.pointTwo ? copyRightTranslation?.pointTwo : 
    "has violated my privacy by posting personal information, pictures, or other private data not in the public record"}`,
    `${copyRightTranslation?.pointThree ? copyRightTranslation?.pointThree : 
    "has engaged in libel/slander/defamation against myself, my family, or my company"}`,
    `${copyRightTranslation?.pointFour ? copyRightTranslation?.pointFour : 
    "has posted spam, pornography, or hate speech"}`,
    `${copyRightTranslation?.pointFive ? copyRightTranslation?.pointFive : 
    "has made a false claim resulting in removal of my content"}`,
  ];
  const [pageObject, setPageObject] = useState({
    name: "",
    email: "",
    address: "",
    contact: "",
    url: "",
    copyrights:
    `${copyRightTranslation?.pointOne ? copyRightTranslation?.pointOne : "has posted my copyrighted/trademarked work without my permission"}`,
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageObject({
      ...pageObject,
      [name]: value,
    });
  };
  const handleContactInfo = () => {
    const { name, email, address, contact, url } = pageObject;
    if (
      name == "" ||
      email == "" ||
      address == "" ||
      contact == "" ||
      url == ""
    ) {
      toast.error(`${toastTranslation?.requried ? toastTranslation?.requried : "All fields are required"}`);
      return;
    }
    dispatch(ClaimCopyright(pageObject));
    toast.success(`${toastTranslation?.success ? toastTranslation?.success :"We have sent you email please check the details ."}`);
    copyRightModal.current.hide();
  };
  var PopupDialog = {
    top: "43%",
    width: "50%",
    height: "520px",
    overflow: "scroll",
  };
  return (
    <div className="app" style={{ "background-color": "white" }}>
      <ToastContainer autoClose={20000} />
      <Row>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </Row>
      <SkyLight
        dialogStyles={PopupDialog}
        hideOnOverlayClicked
        ref={copyRightModal}
        style={{ display: "flex", align: "center" }}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>{reportingTranslation?.infoHeadingOne ? reportingTranslation?.infoHeadingOne : "Contact Information"}</h4>
            </b>
          </p>
        }
      >
        <div>
          <FormGroup>
            <Label>{otherProductTranslation?.name ? otherProductTranslation?.name : "Name"}</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>{sellerHomeTranslation?.email ? sellerHomeTranslation?.email : "Email"}</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>{sellerHomeTranslation?.address ? sellerHomeTranslation?.address : "Address"}</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="text"
                  name="address"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>{sellerHomeTranslation?.phone ? sellerHomeTranslation?.phone :  "Contact Number"}</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="tel"
                  name="contact"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Website Url</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="url"
                  name="url"
                  placeholder="http://.*"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>

          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              size="md"
              color="warning"
              onClick={handleContactInfo}
            >
             {digitalProductTranslation?.saveButton ? digitalProductTranslation?.saveButton :  "Save"}
            </Button>
            <Col xs="12" sm="1"></Col>
            <Button
              type="submit"
              size="md"
              onClick={() => copyRightModal.current.hide()}
              color="danger"
            >
             {digitalProductTranslation?.cancel ? digitalProductTranslation?.cancel :  "Cancel"}
            </Button>
          </Row>
        </div>
      </SkyLight>
      <div className="container-fluid">
        <div className="col-12 col-lg-12">
          <div className="card">
            <div className="card-block clearfix">
              <i className="fa fa-flag bg-warning p-1 font-4xl mr-1 float-left"></i>
              <div className="h2 text-warning mb-0 mt-h">
              {copyRightTranslation?.pointOne ? copyRightTranslation?.pointOne : "Report copyright infringement"}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Card>
            <CardBody>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {copyRightTranslation?.lineOne ? copyRightTranslation?.lineOne : "Report copyright infringements and abuse on Yinn"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {copyRightTranslation?.lineTwo ? copyRightTranslation?.lineTwo : 
               "If someone has posted your copyrighted content or your personal information to Yinn, use this form to let us know. You will receive a response within 2 business days."}
              </div>
              <div style={{ paddingTop: "10px" }}>
              {copyRightTranslation?.lineThree ? copyRightTranslation?.lineThree :
                "If you have any questions about this form, please contact" }{" "}
                <a>info@yinn.ca</a>
              </div>
              <div className="col-6 col-lg-5">
                <hr style={{ marginTop: "1em" }} />
              </div>
              <div className="col-7 col-lg-7">
                <Form>
                  <h6>
                    <b>{copyRightTranslation?.headingTwo ? copyRightTranslation?.headingTwo :"THIS IS AN OFFICIAL NOTIFICATION THAT A USER OF YINN"}</b>
                  </h6>
                  {copyrightTypes.map((notif, index) => (
                    <Col>
                      <FormGroup>
                        <Input
                          type="radio"
                          name="copyrights"
                          id={notif}
                          checked={notif == pageObject.copyrights}
                          className="radio-alignA"
                          value={notif}
                          onChange={handleInputChange}
                        />
                        <Label>{notif}</Label>
                      </FormGroup>
                    </Col>
                  ))}
                  <Button
                    color="warning"
                    className="btn btn-md"
                    onClick={() => copyRightModal.current.show()}
                  >
                    <b>{contectUsTranslation?.button ? contectUsTranslation?.button :"Submit"}</b>
                  </Button>
                </Form>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

export default Copyrights;
