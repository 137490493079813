export const materials = ['PLA (Polylactic Acid)', 'Steel', 'ABS (Acrylonitrile Butadiene Styrene)',
    'Resin', 'Nylon (Polyamide)', 'Mixed Electronics', 'Canvas/Acrylic', 'Acrylic', 'Cotton',
    'Stainless Steel', 'Wool', 'Polyester', 'Cotton/Synthetic', 'Synthetic', ' Recycle Wool',
    'Leather', 'Plastic', 'Ceramic', 'Glass', 'Metal', 'Wood', 'Mixed Materials', 'Metal/Wood',
    'Iron ', 'Recycle iron', 'Copper', 'Nickle', 'Glass/Ceramic', 'Bronze (Copper/tin)', 'Brass (zinc)',
    'Aluminium', 'Titanium', 'Lead', 'Metal (Steel)', 'Sugar', 'Cocoa', 'Coffee', 'Paper', 'Fabric',
    'Canvas (Cotton)', 'Essential Oils', 'Wax', 'Cardboard/Paper', 'Electronics', 'Fabric/Textile', 'Canvas',
    'Digital', 'PLA/ABS', 'Laser Cutters( per 100 m)', 'Foam Boards/Modeling Clay/Breadboards:', 'Plastic/Metal',
    'Metal/Plastic', 'Cashmere', 'Cotton/Thread', 'Various', 'Pashmina', 'Silk', 'Rubber', 'Rope', 'Leather/Plastic',
    'Leather/Fabric', 'Mixed Chemicals', 'Mixed Ingredients', 'Oils', 'Stone', 'Digital Media', 'Organic Material',
    'Live Performance', 'Leather/Wood', 'Fabric/Rope']

