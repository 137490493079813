import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

const UserRoute = ({
  component: Component,
  withLayout: Layout,
  role,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, user } = useSelector((state) => state.auth);
  const renderRoute = () => (
    <Route
      {...rest}
      render={(props) =>
        <Component {...props} />
      }
    />
  );
  useEffect(() => {
    if (!token || user.userType == "seller") {
      history.push('/auth/login');
    }
  }, [token]);

  return token ? ( Layout? <Layout>{renderRoute()}</Layout> : renderRoute()) : (
    <Backdrop open={true} style={{ zIndex: '9' }}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default UserRoute;
