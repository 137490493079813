import React, { useEffect } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAccount } from '../../../redux/reducers/auth';
const ConfirmAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authToken = new URLSearchParams(history.location.search).get("token");
  useEffect(() => {
    dispatch(confirmAccount(authToken));
  },[])
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h3 className="text-muted">Congratulations Your account has been confirmed </h3>
              </div>
              <Button color="info" type="button" onClick={() => history.push('/auth/login')}>back to home</Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export default ConfirmAccount;
