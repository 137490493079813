import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  SetState,
  addFilter,
  getProducts,
  getAddCharges,
  createAd,
} from "../../../redux/reducers/seller";
import Config from "../../../config/aws";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "./ads.css";
import { getWallet } from "../../../redux/reducers/user";
import {
  getPresigedUrl,
  uploadToS3ViaSignedUrl,
} from "../../../helpers/s3Utils";

const Ads = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const fileInput = useRef(null);
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const advertisementsTranslation =
    translationState?.[0]?.translations?.advertisements?.[userLanguage];
  const addAdvertAndAnnouncementTranslation =
    translationState?.[0]?.translations?.addAdvertAndAnnouncement?.[
      userLanguage
    ];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const sellerTranslation =
    translationState?.[0]?.translations?.seller?.[userLanguage];
  const addProductTranslation =
    translationState?.[0]?.translations?.addProduct?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];

  const { addCharges, products, addCreated } = useSelector(
    (state) => state.seller
  );
  const [pageContent, setPageContent] = useState({
    companyName: "",
    title: "",
    pay: 0,
    description: "",
    paymentPlan: "",
    startDate: "",
    endDate: moment().add(5, "days"),
    images: [],
    productId: null,
  });
  const paymentPlans = [
    {
      name: `${
        addAdvertAndAnnouncementTranslation?.click
          ? addAdvertAndAnnouncementTranslation?.click
          : "Pay per click"
      }`,
      key: "perClick",
    },
    {
      name: `${
        addAdvertAndAnnouncementTranslation?.day
          ? addAdvertAndAnnouncementTranslation?.day
          : "Pay per day"
      }`,
      key: "perDay",
    },
    {
      name: `${
        addAdvertAndAnnouncementTranslation?.week
          ? addAdvertAndAnnouncementTranslation?.week
          : "Pay per week"
      }`,
      key: "perWeek",
    },
    {
      name: `${
        addAdvertAndAnnouncementTranslation?.month
          ? addAdvertAndAnnouncementTranslation?.month
          : "Pay per month"
      }`,
      key: "perMonth",
    },
  ];
  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageSize",
        value: 1000,
      })
    );
    dispatch(getProducts());
    dispatch(getAddCharges());
  }, []);
  useEffect(() => {
    if (addCreated) {
      history.push("/seller/advertisement");
    }
  }, [addCreated]);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const handlePlanChange = (event) => {
    const value = event.target.value;
    const planType = paymentPlans.find((item) => item.key == value);
    const charges = (addCharges.length && addCharges[0][planType.key]) || 0;
    setPageContent({ ...pageContent, paymentPlan: value, pay: charges });
  };
  const uploadImage = async (blobs) => {
    for (let i = 0; i < blobs.length; i++) {
      let newImages = [...pageContent.images];
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };

      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const imageUrl = `${Config.digitalOceanSpaces}/` + blob.name;
        newImages = [...newImages, imageUrl];
        setPageContent({
          ...pageContent,
          images: newImages,
        });
      } catch (error) {
        toast.error("Error in uploading image! Please try again");
      }
    }
  };
  const uploadProductImages = (event) => {
    uploadImage(event.target.files);
  };

  const save = () => {
    const {
      companyName,
      title,
      pay,
      description,
      paymentPlan,
      startDate,
      endDate,
      images,
      productId,
    } = pageContent;
    if (
      companyName == "" ||
      title == "" ||
      pay == 0 ||
      description == "" ||
      productId == null ||
      paymentPlan == "" ||
      startDate == "" ||
      endDate == "" ||
      !images.length
    ) {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "All fields are required"
        }`
      );
      return;
    }
    dispatch(createAd(pageContent)).then(() => {
      dispatch(getWallet());
    });
  };

  const imageClick = (image) => {
    let newImages = _.cloneDeep(pageContent.images);
    newImages = newImages.filter((item) => item !== image);
    setPageContent({
      ...pageContent,
      images: newImages,
    });
  };

  return (
    <div className="animated fadeIn" style={{ marginTop: "30px" }}>
      <ToastContainer autoClose={20000} />
      <Row>
        <Col sm="1" md="1"></Col>
        <Col xs="12" sm="10">
          <Card>
            <CardHeader>
              <Row>
                <b>
                  {advertisementsTranslation?.advertisement
                    ? advertisementsTranslation?.advertisement
                    : "Create Advertisement and Promotions"}
                </b>
              </Row>
            </CardHeader>

            <CardBody className="card-body">
              <Row className="flex-col">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" sm="4">
                        <Label>
                          <b>
                            {advertisementsTranslation?.name
                              ? advertisementsTranslation?.name
                              : "Advertisement Name"}
                          </b>
                          <span className="star">*</span>
                        </Label>
                      </Col>
                      <Col xs="12" sm="8">
                        <Input
                          type="text"
                          placeholder={
                            advertisementsTranslation?.name
                              ? advertisementsTranslation?.name
                              : "Advertisement Name"
                          }
                          name="companyName"
                          onChange={handleInputChange}
                          value={pageContent.companyName}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs="4" sm="4" md="4">
                        <Label>
                          <b>
                            {digitalProductTranslation?.title
                              ? digitalProductTranslation?.title
                              : "Title"}
                          </b>
                          <span className="star">*</span>
                        </Label>
                      </Col>
                      <Col xs="8" sm="8" md="8">
                        <Input
                          type="text"
                          placeholder={
                            digitalProductTranslation?.title
                              ? digitalProductTranslation?.title
                              : "Title"
                          }
                          name="title"
                          onChange={handleInputChange}
                          value={pageContent.title}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs="12" sm="4" md="4">
                        <Label>
                          <b>
                            {digitalProductTranslation?.description
                              ? digitalProductTranslation?.description
                              : "Description"}
                          </b>
                          <span className="star">*</span>
                        </Label>
                      </Col>
                      <Col xs="12" sm="8" md="8">
                        <Input
                          type="textarea"
                          placeholder={
                            digitalProductTranslation?.description
                              ? digitalProductTranslation?.description
                              : "Description..."
                          }
                          name="description"
                          onChange={handleInputChange}
                          value={pageContent.description}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs="12" sm="4" md="4">
                        <Label>
                          <b>
                            {addProductTranslation?.productName
                              ? addProductTranslation?.productName
                              : "Product"}
                          </b>
                          <span className="star">*</span>
                        </Label>
                      </Col>
                      <Col xs="12" sm="8" md="8">
                        <Input
                          type="select"
                          placeholder="Product"
                          name="productId"
                          onChange={handleInputChange}
                          value={pageContent.productId}
                        >
                          <option>
                            {addProductTranslation?.productName
                              ? addProductTranslation?.productName
                              : "Select Product"}
                          </option>
                          {products
                            ? products.map((ct, i) => (
                                <option value={ct._id}>{ct.name}</option>
                              ))
                            : "Products not found"}
                        </Input>
                      </Col>
                    </Row>
                    <br />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" sm="4" md="4" lg="4">
                        <Label>
                          <b>
                            {addAdvertAndAnnouncementTranslation?.selectBanner
                              ? addAdvertAndAnnouncementTranslation?.selectBanner
                              : "Please select Banner"}
                          </b>
                          <span className="star">*</span>
                        </Label>
                      </Col>
                      <CardBody className="card-body">
                        <Row action="" method="post">
                          {pageContent.images.length > 0
                            ? pageContent.images.map((item, index) => (
                                <Col xs="12" lg="3">
                                  <div class="container">
                                    <img
                                      style={{ width: "100%" }}
                                      src={item}
                                      alt="Avatar"
                                      class="image"
                                    />
                                    <div
                                      class="close-img"
                                      style={{ right: "0 !important" }}
                                      onClick={() => imageClick(item)}
                                    ></div>
                                    <div class=""></div>
                                  </div>
                                </Col>
                              ))
                            : ""}
                          <Col xs="12" lg="12">
                            <div className="file-uploader">
                              <input
                                type="file"
                                multiple={true}
                                onChange={uploadProductImages}
                                ref={fileInput}
                                hidden
                              />
                              <button
                                onClick={(e) =>
                                  fileInput.current && fileInput.current.click()
                                }
                                className="btn btn-primary"
                              >
                                {addProductTranslation?.image
                                  ? addProductTranslation?.image
                                  : "Upload Image"}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Row>
                    <br />
                  </CardBody>
                </Card>
                <Row>
                  <h6 style={{ color: "red" }}>
                    {addAdvertAndAnnouncementTranslation?.note
                      ? addAdvertAndAnnouncementTranslation?.note +
                        `${pageContent.pay || 0}$`
                      : `${
                          "*Note : Seller will have to pay minimum" +
                          `${pageContent.pay || 0}$` +
                          "for per click advertisement plan."
                        }`}
                  </h6>
                </Row>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" sm="4" md="4">
                        <Label>
                          <b>
                            {addAdvertAndAnnouncementTranslation?.paymethode
                              ? addAdvertAndAnnouncementTranslation?.paymethode
                              : "Payment methods"}
                          </b>
                          <span className="star">*</span>
                        </Label>
                      </Col>
                      <Col xs="12" sm="8" md="8">
                        <Input
                          type="select"
                          placeholder="Select a Payment Plan"
                          optionLabel="name"
                          name="paymentPlan"
                          value={pageContent.paymentPlan}
                          onChange={handlePlanChange}
                        >
                          <option>
                            {addAdvertAndAnnouncementTranslation?.paymethode
                              ? addAdvertAndAnnouncementTranslation?.paymethode
                              : "Select Payment Plan"}
                          </option>
                          {paymentPlans.map((ct, i) => (
                            <option value={ct.key}>{ct.name}</option>
                          ))}
                        </Input>
                        <div style={{ marginTop: ".5em" }}>
                          <Label
                            hidden={
                              pageContent.pay == 0 ||
                              pageContent.pay ===
                                `${
                                  addAdvertAndAnnouncementTranslation?.noOption
                                    ? addAdvertAndAnnouncementTranslation?.noOption
                                    : "No option selected"
                                }`
                            }
                          >
                            Amount for
                          </Label>{" "}
                          {pageContent.paymentPlan
                            ? pageContent.paymentPlan +
                              " : $ " +
                              pageContent.pay
                            : `${
                                addAdvertAndAnnouncementTranslation?.noOption
                                  ? addAdvertAndAnnouncementTranslation?.noOption
                                  : "No option selected"
                              }`}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" sm="4">
                        <Label htmlFor="appendedPrependedInput">
                          {addAdvertAndAnnouncementTranslation?.start
                            ? addAdvertAndAnnouncementTranslation?.start
                            : "Start Date"}
                        </Label>
                      </Col>
                      <Col xs="12" sm="8">
                        <div className="controls">
                          <Input
                            name="startDate"
                            value={pageContent.startDate}
                            onChange={handleInputChange}
                            type="date"
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs="12" sm="4">
                        <Label htmlFor="appendedPrependedInput">
                          {addAdvertAndAnnouncementTranslation?.end
                            ? addAdvertAndAnnouncementTranslation?.end
                            : "End Date"}
                        </Label>
                      </Col>
                      <Col xs="12" sm="8">
                        <div className="controls">
                          <Input
                            name="endDate"
                            value={pageContent.endDate}
                            onChange={handleInputChange}
                            type="date"
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col xs="6" sm="3" md="3">
                    <Button
                      className="btn btn-md btn-full"
                      color="primary"
                      onClick={() => save()}
                    >
                      <b>
                        {addAdvertAndAnnouncementTranslation?.Pay
                          ? addAdvertAndAnnouncementTranslation?.Pay
                          : "Pay now"}
                      </b>
                    </Button>
                  </Col>
                  <Col xs="6" sm="3" md="3">
                    <Button
                      color="danger"
                      className="btn btn-md"
                      onClick={(e) => history.push("/seller/advertisement")}
                      block
                    >
                      <b>
                        {digitalProductTranslation?.cancel
                          ? digitalProductTranslation?.cancel
                          : "Cancel"}
                      </b>
                    </Button>
                  </Col>
                </Row>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="1" md="1"></Col>
      </Row>
    </div>
  );
};

export default Ads;
