import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./Cart.css";
import { clearCart, getExhangeRate } from "../../../redux/reducers/auth";
import { AppHeader } from "@coreui/react";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import CartEntry from "./CartEntry";
import cartEmptySrc from "../../../assets/img/cart/cart-empty.svg";

const Cart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cart, token } = useSelector((state) => state.auth);
  const { userCurrency } = useSelector((state) => state.user);
  const { usdRate, user, loading } = useSelector((state) => state.auth);

  const {translationState, allWebContent } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const myCartTranslation = translationState?.[0]?.translations?.myCart?.[userLanguage];
  useEffect(() => {
    dispatch(getExhangeRate(userCurrency))
  }, [])
  
  const continueShopping = () => {
    history.push("/search");
  };
  const placeOrder = (items) => {
    if (token) {
      history.push("/shipping?&type=cart");
    } else {
      history.push("/auth/login?redirect=/my-cart");
    }
  };

  return (
    <div className='app' style={{ "background-color": "#f6f6f6" }}>
      <Row>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </Row>
      <div
        className='container-fluid'
        style={{
          paddingLeft: "7%",
          paddingRight: "7%",
          marginTop: 130,
          "background-color": "#f6f6f6",
        }}
      >
        <div className='cart-header-area'>
          <div>
            <div className='cart-header-text'>{myCartTranslation?.yourCart ? myCartTranslation?.yourCart : "Your Cart"}</div>
            <div className='cart-sub-header-text'>
            {myCartTranslation?.itemInCart ? myCartTranslation?.itemInCart+" "+cart?.length : `${"You have "+cart?.length+" items in your cart"}`}
            </div>
          </div>
          <div
            className='cart-header-clear-cart'
            onClick={() => dispatch(clearCart())}
          >
            {myCartTranslation?.clearCart ? myCartTranslation?.clearCart : "Clear Cart"}
          </div>
        </div>
        <div hidden={cart?.length === 0} className='cart-main-area'>
          <div className='cart-main-area-header cart-entry-hide-on-mobile'>
            <div style={{ width: "5%" }}>{myCartTranslation?.product ? myCartTranslation?.product : "Product"}</div>
            <div>{myCartTranslation?.status ? myCartTranslation?.status : "Status"}</div>
            <div>{myCartTranslation?.price ? myCartTranslation?.price : "Price"}</div>
            <div>{myCartTranslation?.quantity ? myCartTranslation?.quantity : "Qty"}</div>
            <div>{myCartTranslation?.total ? myCartTranslation?.total : "Total"}</div>
            <div style={{ width: "3%" }} />
          </div>
          <div className='cart-entry-header-seperator' />
          {loading ? (
              <div style={{ textAlign: "center" , margin:'100px' }}>
                <i
                  className='fas fa-spinner fa-pulse fa-2x'
                  style={{ color: "green" }}
                ></i>
              </div>
          ) : cart?.length > 0 && !loading ? (
            user &&
            cart.map((item, i) =>
              item?.product.sellerId?.id === user?.id ? (
                <CartEntry item={item} key={i} message={myCartTranslation?.ownProduct ? myCartTranslation?.ownProduct : "Your own Product"} />
              ) : (
                <CartEntry item={item} key={i} message={myCartTranslation?.buyAble ? myCartTranslation?.buyAble : "Buy Able"} />
              )
            )
          ) : null}
          <div className='cart-entry-footer-seperator' />
          <div className='cart-total-info'>
            <div>{myCartTranslation?.subtotal ? myCartTranslation?.subtotal : "Sub-Total"}</div>
            {cart && cart?.[0]?.product?.eventCategory !== "event" ?
              (<div>
                {userCurrency}.{" "}
                {(cart.reduce((a, b) => a + b?.product?.unitPrice * b.qty, 0) *
                  usdRate).toFixed(2)}
              </div>
              ):(
                <div>
                  YINN COIN{" "}
                {(cart.reduce((a, b) => a + b?.product?.unitPrice * b.qty, 0) *
                  usdRate).toFixed(2) * 100}
              </div>
              )
            }
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className='cart-button'
              onClick={continueShopping}
            >
              {myCartTranslation?.shopMore ? myCartTranslation?.shopMore : "Shop More"}
            </button>
            <button
              className='cart-button'
              onClick={(e) => {
                placeOrder();
              }}
            >
              {myCartTranslation?.checkOut ? myCartTranslation?.checkOut : "Proceed to Checkout"}
            </button>
          </div>
        </div>
        {cart?.length === 0 && (
          <CartEmptyView continueShopping={continueShopping} />
        )}
      </div>
      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

const CartEmptyView = (props) => {
  const {translationState } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth)
  const myCartTranslation = translationState?.[0]?.translations?.myCart?.[userLanguage];
  const { loading } = useSelector((state) => state.auth);
  return (
    <div className='cart-empty-container'>
      <img src={cartEmptySrc} />
      {loading ? (
        <div className='cart-empty-shop-more-message' style={{ marginTop: 5 }}>
          Loading cart items...
        </div>
      ) : (
        <React.Fragment>
          <div className='cart-empty-message'>{myCartTranslation?.emptyCart ? myCartTranslation?.emptyCart : "Your Cart is Empty"}</div>
          <div className='cart-empty-shop-more-message'>
          {myCartTranslation?.emptyCart ? myCartTranslation?.emptyCart : "Click below to get back to Shopping"}
            {/* click below to get back to shopping */}
          </div>
          <button
            className='cart-button'
            onClick={
              props.continueShopping
            }
          >
            {myCartTranslation?.shopMore ? myCartTranslation?.shopMore : "Shop More"}
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default Cart;
