import React, { useRef, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import "./CarouselImageMagnify.css";

import Slider from "react-slick";

export default function CarouselImageMagnify({ images }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {images?.length > 1 ? (
        <Slider {...settings}>
          {images?.map((image, index) => (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "Product image",
                  width: 430,
                  height: 430,
                  src: image,
                },
                largeImage: {
                  src: image,
                  width: 1200,
                  height: 1200,
                  onLoad: () => {
                    console.log("magnify");
                  },
                },
                enlargedImagePosition: "over",
                isHintEnabled: true,
                shouldHideHintAfterFirstActivation: false,
              }}
            />
          ))}
        </Slider>
      ) : (
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: "Product image",
              width: 430,
              height: 430,
              src: images?.length>0 && images[0],
            },
            largeImage: {
              src: images?.length>0 && images[0],
              width: 1200,
              height: 1200,
              onLoad: () => {
                console.log("magnify");
              },
            },
            enlargedImagePosition: "over",
            isHintEnabled: true,
            shouldHideHintAfterFirstActivation: false,
          }}
        />
      )}
    </>
  );
}
