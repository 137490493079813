import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  Input,
  Form,
  FormGroup,
  FormText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import "./pages.css";
import { useDispatch, useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import { AppSwitch } from "@coreui/react";
import {
  setState,
  createCMSCategory,
  deleteCMSCategory,
  CMSCategories,
  createPage,
} from "../../../redux/reducers/admin";

const Pages = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cmsCategories } = useSelector((state) => state.admin);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [pageContent, setPageContent] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [categoryModalShow, setCategoryModalShow] = useState(false);
  const [type, setType] = useState("add");
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  useEffect(() => {
    dispatch(CMSCategories());
  }, []);

  const handleCategoryInput = (e) => {
    setCategoryName(e.target.value);
  };
  const openCategoryModal = (e, type) => {
    setCategoryModalShow(true);
    setType(type);
  };
  const hideModal = () => {
    setCategoryModalShow(false);
  };

  const saveCategory = (e) => {
    if (type === "add") {
      if (categoryName == "") {
        toast.error("Please fill all mandatory fields");
        return;
      }

      dispatch(createCMSCategory({ categoryName: categoryName })).then(() =>
        dispatch(CMSCategories())
      );

      setCategoryModalShow(false);
    } else {
      const { categoryName } = pageContent;
      const cmsCategory = cmsCategories.find(
        (item) => item.categoryName === categoryName
      );
      if (!categoryName) {
        toast.error("Please fill all mandatory fields");
        return;
      }
      dispatch(deleteCMSCategory(cmsCategory._id)).then(() => {
        setCategoryModalShow(false);
        dispatch(CMSCategories());
      });
    }
  };
  const savePage = () => {
    const { title, publish, categoryName } = pageContent;
    const cmsCategory = cmsCategories.find(
      (item) => item.categoryName === categoryName
    );
    if (!title || !categoryName || !editorState || !cmsCategory) {
      toast.error("Please fill all mandatory fields");
      return;
    }
    const content = convertToRaw(editorState.getCurrentContent());
    const data = {
      title,
      publish,
      content,
      cmsCategoryId: cmsCategory._id,
      categoryName,
    };
    dispatch(createPage(data));
    history.push("/admin/content-management");
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Modal
        isOpen={categoryModalShow}
        toggle={saveCategory}
        className={"modal-warning " + props.className}
      >
        {type === "add" ? (
          <ModalHeader>Add New CMS Category</ModalHeader>
        ) : (
          <ModalHeader>Delete Any CMS Category</ModalHeader>
        )}
        <ModalBody>
          <Form action="" method="post" className="form-horizontal">
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="hf-title">
                  Category <span className="required">*</span>
                </Label>
              </Col>
              {type === "add" ? (
                <Col xs="12" md="7">
                  <Input
                    type="text"
                    name="categoryName"
                    onChange={handleCategoryInput}
                    value={categoryName}
                    placeholder="Enter Category..."
                    autoComplete="title"
                    required
                  />
                </Col>
              ) : (
                <Col xs="15" md="7">
                  <Input
                    type="select"
                    name="categoryName"
                    onChange={handleInputChange}
                    value={pageContent.categoryName}
                  >
                    <option>Select Category</option>
                    {cmsCategories
                      ? cmsCategories.map((ct, i) => (
                          <option>{ct.categoryName}</option>
                        ))
                      : "Category not found"}
                  </Input>
                </Col>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={(e) => saveCategory()}>
            {type === "add" ? "Save" : "Delete"}
          </Button>{" "}
          <Button color="danger" onClick={(e) => hideModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col sm="1" md="1"></Col>
        <Col xs="12" sm="10" md="10">
          <Card>
            <div className="form-horizontal">
              <CardHeader>
                <b>Manage Home Page Footer Content</b>
              </CardHeader>
              <CardBody className="card-body">
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="title">
                      Title <span className="required">*</span>{" "}
                    </Label>
                  </Col>
                  <Col xs="12" md="7">
                    <Input
                      type="text"
                      name="title"
                      placeholder="Enter Title"
                      autoComplete="title"
                      onChange={handleInputChange}
                      value={pageContent.title}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="select">
                      Category <span className="required">*</span>{" "}
                    </Label>
                  </Col>
                  <Col xs="12" md="7">
                    <Input
                      type="select"
                      name="categoryName"
                      onChange={handleInputChange}
                      value={pageContent.categoryName}
                    >
                      <option>Select Category</option>
                      {cmsCategories
                        ? cmsCategories.map((ct, i) => (
                            <option>{ct.categoryName}</option>
                          ))
                        : "Category not found"}
                    </Input>
                  </Col>
                  <Col md="2">
                    <Row>
                      <i
                        className="fa fa-plus"
                        onClick={(e) => openCategoryModal(e, "add")}
                      >
                        &nbsp;<b>Add New</b>
                      </i>
                    </Row>
                    <Row>
                      <i
                        className="fa fa-minus "
                        onClick={(e) => openCategoryModal(e, "delete")}
                      >
                        &nbsp;<b>Remove</b>
                      </i>
                    </Row>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="Publish">Publish</Label>
                  </Col>
                  <Col md="5">
                    <AppSwitch
                      className={"mx-1"}
                      variant={"pill"}
                      color={"primary"}
                      onChange={handleInputChange}
                      name="publish"
                      value={pageContent.publish}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <div className="controls">
                      <Editor
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onEditorStateChange={onEditorStateChange}
                      ></Editor>
                    </div>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col sm="4" md="4">
                    <Button
                      type="submit"
                      size="sm"
                      color="primary"
                      onClick={savePage}
                      block
                    >
                      <i className="fa fa-dot-circle-o"></i> Submit
                    </Button>
                  </Col>
                  <Col sm="4" md="4">
                    <Button
                      type="reset"
                      size="sm"
                      color="danger"
                      onClick={() => history.push("/admin/content-management")}
                      block
                    >
                      <i className="fa fa-ban"></i> Reset
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </div>
          </Card>
        </Col>
        <Col sm="1" md="1"></Col>
      </Row>
    </div>
  );
};

export default Pages;
