import React, { useEffect, useRef } from "react";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {} from "../../redux/reducers/auth";
import { AppHeader } from "@coreui/react";
import DefaultFooter from "../Footer/DefaultFooter";
import DefaultHeader from "../Header/DefaultHeader";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import "react-toastify/dist/ReactToastify.css";
import { singlePage } from "../../redux/reducers/admin";
import "./CMSPage.css";

const CMSPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cmsSinglePage } = useSelector((state) => state.admin);
  const location = history.location.pathname.split("/");
  const pageId = location[location.length - 1];
  
  const {cmsPages} = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(singlePage(pageId));
  }, [pageId]);
  const convertCommentFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw({ entityMap: {}, blocks: text.blocks }));
  };
  
  return (
    <div className="app" style={{ "background-color": "white" }}>
      <Row>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </Row>
      <div className="cms-container-fluid">
        <div id="comment-div">
          {cmsSinglePage && cmsSinglePage.content && (
            <div
              dangerouslySetInnerHTML={{
                __html: convertCommentFromJSONToHTML(
                  cmsSinglePage.content
                ),
              }}
            />
          )}
        </div>
        <div>{}</div>
      </div>

      <div className="custom-footer">
        <DefaultFooter />
      </div>
    </div>
  );
};

export default CMSPage;
