import React, { Component, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Col,
  Row,
  Nav,
  Button,
  Input,
  InputGroup,
  ButtonDropdown,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import "./coinPayment.css";
import PaypalExpressBtn from "react-paypal-express-checkout";
import cookie from "react-cookies";
import axios from "axios";
import Env from "../../../../environment/environment";
import creditcardutils from "creditcardutils";
import YinnCoin from "../YinnCoin";
import { AppFooter, AppHeader } from "@coreui/react";
import DefaultFooter from "../../../Footer/DefaultFooter";
import DefaultHeader from "../../../Header/DefaultHeader";
import { PayPalButton } from "react-paypal-button-v2";
import { useSelector , useDispatch } from "react-redux";
// todo's (Do not remove these comments)
// remove direct image urls
// Implement parent child communication
// Write card validations handlers into its separate service component
// Seperate payment integration part to its own service

var payment_message = "Payment is in progress. Please do not refresh page.";
const CoinPayment = (props) => {
  const dispatch = useDispatch();
  const {translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];

  let payment_details = {};
  let state = {
    paymentChecked: true,
    paypalChecked: true,
    card: {
      number: "",
      exp_month: "01",
      exp_year: "",
      cvv: "",
      zip: "",
      payment_token: "",
    },
    userCardDetails: {
      user_id: "",
      braintree_customer_id: "",
      cards: [],
      card: {
        number: "",
        exp_date: "",
        cvv: "",
        zip: "",
        payment_token: "",
      },
      amount_payable: 0,
    },
    creditCardNumber: "",
    cvvMaxLength: 3,
    month: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    orders: [],
    wallets: [],
  };
  // constructor(props) {
  //     super(props);
  //     payment_details = JSON.parse(localStorage.getItem('coin_data'));
  //     state.userCardDetails.amount_payable =  payment_details.price
  //     payByCard = payByCard;
  //     payByPaypal = payByPaypal;
  // }

  const payByCard = () => {
    //setState({
    //     paymentChecked: false,
    //     paypalChecked: true
    // })
  };

  const payByPaypal = () => {
    //setState({
    //     paymentChecked: true,
    //     paypalChecked: false
    // })
  };

  const handleCardNumber = (evt) => {
    var number = evt.target.value.replace(/ +/g, "");
    if (/^[0-9]{1,19}$/.test(number)) {
      var cardFormat = creditcardutils.formatCardNumber(number);
      //setState({creditCardNumber : cardFormat})
      var card = state.card;
      card.number = cardFormat.split(" ").join("");
      //setState({card: card})
    } else if (number == "") {
      var cardFormat = creditcardutils.formatCardNumber(number);
      //setState({creditCardNumber : cardFormat})
      var card = state.card;
      card.number = number;
      //setState({card: card})
    }
  };

  const handleExpirationMonth = (evt) => {
    if (/^[0-9]{1,2}$/.test(evt.target.value)) {
      var card = state.card;
      var month = "0";
      if (evt.target.value.length == 1) {
        month = month + evt.target.value;
      } else {
        month = evt.target.value;
      }
      card.exp_month = month;
      //setState({
      //   card: card
      // })
    } else if (evt.target.value == "") {
      var card = state.card;
      card.exp_month = evt.target.value;
      //setState({
      //   card: card
      // })
    }
  };
  const handleExpirationYear = (evt) => {
    if (/^[0-9]{1,2}$/.test(evt.target.value)) {
      var card = state.card;
      card.exp_year = evt.target.value;
      //setState({
      //     card: card
      // })
    } else if (evt.target.value == "") {
      var card = state.card;
      card.exp_year = evt.target.value;
      // //setState({
      //     card: card
      // })
    }
  };
  const handleCVV = (evt) => {
    if (/^[0-9]{1,4}$/.test(evt.target.value)) {
      var card = state.card;
      card.cvv = evt.target.value;
      //setState({
      //     card: card
      // })
    } else if (evt.target.value == "") {
      var card = state.card;
      card.cvv = evt.target.value;
      //setState({
      //     card: card
      // })
    }
  };

  const handleZipCode = (evt) => {
    if (/^[a-zA-Z0-9]{1,6}$/.test(evt.target.value)) {
      var card = state.card;
      card.zip = evt.target.value;
      //setState({
      //     card: card
      // })
    } else if (evt.target.value == "") {
      var card = state.card;
      card.zip = evt.target.value;
      //setState({
      //     card: card
      // })
    }
  };

  const chargeNewCard = () => {
    var card = state.card;
    var userCardDetails = state.userCardDetails;
    userCardDetails.user_id = cookie.load("_id");
    if (card.exp_month == "" || card.exp_month.length != 2) {
      //setState({cardExp_month: true})
    } else {
      if (card.exp_month.length == 2) {
        var num = parseInt(card.exp_month);
        if (num >= 13) {
          //setState({cardExp_month: true})
        } else {
          //setState({cardExp_month: false})
        }
      } else {
        //setState({cardExp_month: false})
      }
      if (card.exp_year == "" || card.exp_year.length != 2) {
        //setState({cardExp_year: true})
      } else {
        if (card.cvv == "" || card.cvv.length == 2 || card.cvv.length == 1) {
          //setState({cardCVV: true})
          //setState({cardExp_month: false})
          //setState({cardExp_year: false})
        } else {
          if (card.zip == "" || card.zip.length != 6) {
            //setState({cardZip: true})
            //setState({cardCVV: false})
            //setState({cardExp_month: false})
            //setState({cardExp_year: false})
          } else {
            //setState({cardZip: false})
            //setState({cardCVV: false})
            //setState({cardExp_month: false})
            //setState({cardExp_year: false })
            var crd = userCardDetails.card;
            crd.number = card.number;
            crd.exp_date = card.exp_month + "/" + card.exp_year;
            crd.cvv = card.cvv;
            crd.zip = card.zip;
            userCardDetails.card = crd;

            //setState({userCardDetails: userCardDetails})
            if (
              !state.cardNumber &&
              !state.cardExp_month &&
              !state.cardExp_year &&
              !state.cardCVV &&
              !state.zip
            ) {
              //setState({ payment_response_flag: true })
              //setState({ payment_response_wait: true })
              toast.success(
                `${toastTranslation?.success ? toastTranslation?.success : "Payment is in progress please don't click other part of application."}`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );

              axios
                .post(
                  Env.url + "/payments/yinncoin/buy",
                  {
                    ...state.userCardDetails,
                    coinAmount: payment_details.quantity,
                    paymentMethod: "card",
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: localStorage.getItem("auth_token"),
                    },
                  }
                )
                .then(function (response) {
                  //setState({ payment_response_wait: false })
                  if (response.data.paymentResult.success) {
                    if (response.status == 200) {
                      payment_message = response.data.message;
                      //setState({
                      //     payment_response_wait: false
                      // })
                      //setState({
                      //     payment_response_success: false
                      // })
                      if (
                        response.data.paymentResult.transaction != undefined
                      ) {
                        //setState({
                        //     transaction_amount: response.data.paymentResult.transaction.amount
                        // })
                        //setState({
                        //     transaction_id: response.data.paymentResult.transaction.id
                        // })
                        //setState({
                        //     currancy: response.data.paymentResult.transaction.currencyIsoCode
                        // })
                      }
                    } else {
                      state.payment_response_wait = false;
                      //setState({
                      //     payment_response_wait: false
                      // })
                      state.payment_response_success = true;
                      //setState({
                      //     payment_response_success: true
                      // })
                      state.payment_error_message = "Payment failed.";
                      //setState({
                      //     payment_error_message: "Payment failed."
                      // })
                    }
                  } else {
                    if (response.data.paymentResult.errors != undefined) {
                      state.payment_response_wait = false;
                      //setState({
                      //     payment_response_wait: false
                      // })
                      state.payment_response_success = true;
                      //setState({
                      //     payment_response_success: true
                      // })
                      state.payment_error_message =
                        response.data.paymentResult.message;
                      //setState({
                      //     payment_error_message: response.data.paymentResult.message
                      // })
                    }
                  }
                })
                .catch((err) => {
                  if (err.response != undefined) {
                    if (
                      err.response.status == 401 ||
                      err.response.status == 400 ||
                      err.response.status == 500
                    ) {
                      window.location = "#/login";
                    }
                  }
                });
            }
          }
        }
      }
    }
    //}
  };

  const onPaypalSuccess = (paymentDetails) => {
    //call backend and validate payment
    axios
      .post(
        Env.url + "/payments/yinncoin/buy",
        {
          ...state.userCardDetails,
          coinAmount: payment_details.quantity,
          paymentMethod: "paypal",
          paypalData: paymentDetails,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth_token"),
          },
        }
      )
      .then(function (response) {
        payment_message = "Payment is successful";
        ////setState({payment_message : response.data.message})
        //setState({ payment_response_flag: true })
        //setState({ payment_response_wait: false })
        //setState({ payment_response_success: false })
        state.transaction_id = paymentDetails.paymentID;
        state.transaction_amount = state.amount_payable;
        state.currancy = "USD";
        window.location = "#/yinn_coin";
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 ||
            err.response.status == 400 ||
            err.response.status == 500
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const saveTransaction = (transactionResponse) => {
    var order = [
      {
        product: {
          quantity: payment_details.quantity,
          product_name: "Yn Coin",
          product_id: payment_details.coin_id,
          amount_payable: payment_details.price,
          images: payment_details.images,
        },
        _id: "Yinn" + Date.now(),
        order_status: "complete",
        user_id: payment_details.id,
        total: payment_details.price,
      },
    ];
    axios
      .post(Env.url + "/orders/create", order, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //setState({orders:response.data});
          let transaction_id;
          if (transactionResponse.transaction != undefined) {
            transaction_id = transactionResponse.transaction.id;
          } else {
            transaction_id = transactionResponse.paymentID;
          }
          var transaction = {
            transaction_id: transaction_id,
            Transaction_Response: transactionResponse,
            order_id: response.data[0]._id,
          };
          axios
            .post(Env.url + "/transaction/create", transaction, {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token"),
              },
            })
            .then(function (response) {
              wallet();
            })
            .catch((err) => {
              if (err.response != undefined) {
                if (
                  err.response.status == 401 ||
                  err.response.status == 400 ||
                  err.response.status == 500
                ) {
                  window.location = "#/login";
                } else {
                }
              }
            });
        }
      });
  };

  const checkCardValid = (cardNumber, evt) => {
    // Validate a credit card number
    var is_Valid = creditcardutils.validateCardNumber(cardNumber); //=> true
    if (is_Valid) {
      state.cardNumber = false;
      //setState({cardNumber : false})
    } else {
      state.cardNumber = true;
      //setState({cardNumber : true})
    }
    // Get card type from number
    var cardType = creditcardutils.parseCardType(cardNumber);
    if (cardType == "amex") {
      state.cvvMaxLength = 4;
      //setState({cvvMaxLength :4})
    } else {
      state.cvvMaxLength = 3;
      //setState({cvvMaxLength :3})
    }
  };

  const wallet = () => {
    axios
      .get(Env.url + "/wallet/get/" + payment_details.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        state.wallets = response.data;
        //setState({wallets : state.wallets});
        if (response.data.length <= 0) {
          var data = {
            user_id: payment_details.id,
            type: "YINN COIN",
            quantity: payment_details.quantity,
            transaction_type: "CREDIT",
            balance: state.userCardDetails.amount_payable,
            userType: "customer",
            user_name: cookie.load("name"),
          };
          axios
            .post(Env.url + "/wallet/create", data, {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token"),
              },
            })
            .then(function (response) {
              //setState({ wallets: response.data });
              props.history.push("/yinn_coin");
            })
            .catch((err) => {
              if (err.response != undefined) {
                if (err.response.status == 401) {
                  window.location = "#/login";
                }
              }
            });
        } else {
          var data = {
            user_id: payment_details.id,
            type: "YINN COIN",
            transaction_type: "CREDIT",
            quantity: state.wallets[0].quantity + payment_details.quantity,
            balance:
              state.wallets[0].balance + state.userCardDetails.amount_payable,
            userType: "customer",
            user_name: cookie.load("name"),
          };
          axios
            .put(Env.url + "/wallet/update/" + state.wallets[0]._id, data, {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token"),
              },
            })
            .then(function (response) {
              //setState({ wallets: response.data });
              props.history.push("/yinn_coin");
            })
            .catch((err) => {
              if (err.response != undefined) {
                if (
                  err.response.status == 401 ||
                  err.response.status == 400 ||
                  err.response.status == 500
                ) {
                  window.location = "#/login";
                }
              }
            });
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 ||
            err.response.status == 400 ||
            err.response.status == 500
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const onCancel = (data) => {};

  const onError = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    console.log("Error!", err);
    // onPaypalError(err)
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  let env = "sandbox"; // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state
  let total = state.userCardDetails.amount_payable; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
    sandbox:
      "ASQHvNZTgxugU6CHeydBRQFXCSA7lGVvFijLzuewhu8ouQwsMGCkIpQrsc7GhlUVdV90ttSuCVkBJ1Cc",
    production: "YOUR-PRODUCTION-APP-ID",
  };

  // const client = {
  // sandbox: 'APP-80W284485P519543T',
  // production: 'YOUR-PRODUCTION-APP-ID',
  // }

  return (
    <div className="animated fadeIn">
      <Row>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </Row>

      <ToastContainer autoClose={20000} />
      {/* select payment modes */}

      <Row className="row-alignment">
        <Col lg="3"></Col>
        <Col lg="6">
          <div class="container">
            <div class="card">
              <div class="title">
                <h1>Select Payment Method</h1>
              </div>

              <a>
                <div class="item item-selected" onClick={payByCard}>
                  <div class="item-image">
                    <img
                      class="paypal-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/200px-Mastercard-logo.svg.png"
                    ></img>
                  </div>
                  <div class="item-text">Credit/Debit/ATM Card</div>
                  <svg
                    hidden={state.paymentChecked}
                    class="check"
                    fill="#5AC550"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                  </svg>
                  {/* card details ui */}
                  <Row hidden={state.payment_response_wait}>
                    <span class="pad-top-2"></span>
                    <Col xs="12" sm="12" hidden={state.paymentChecked}>
                      <Card>
                        <CardHeader>
                          <strong>Enter Card Details</strong>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col xs="12" sm="12">
                              <label>Card Number</label>
                              <Input
                                className="input-border"
                                type="text"
                                placeholder="0000 0000 0000 0000"
                                onChange={(e) => handleCardNumber(e)}
                                onBlur={checkCardValid.bind(
                                  this,
                                  state.card.number
                                )}
                                value={state.creditCardNumber}
                              />
                              <InputGroup
                                className="mb-3"
                                hidden={!state.cardNumber}
                              >
                                <div className="validations">
                                  Enter valid number.
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" sm="6">
                              <label className="form__label payment-ui">
                                Expiration month
                              </label>
                              <Input
                                className="input-border"
                                type="select"
                                onChange={(e) => handleExpirationMonth(e)}
                                maxLength={2}
                              >
                                {state.month.map((month, index) => (
                                  <option value={month}>{month}</option>
                                ))}
                              </Input>
                              <InputGroup
                                className="mb-3"
                                hidden={!state.cardExp_month}
                              >
                                <div className="validations">
                                  Enter valid month.
                                </div>
                              </InputGroup>
                            </Col>
                            <Col xs="12" sm="6">
                              <label className="form__label payment-ui">
                                Expiration year
                              </label>
                              <Input
                                className="input-border"
                                type="text"
                                placeholder="00"
                                onChange={(e) => handleExpirationYear(e)}
                                value={state.card.exp_year}
                                maxLength={2}
                              />
                              <InputGroup
                                className="mb-3"
                                hidden={!state.cardExp_year}
                              >
                                <div className="validations">
                                  Enter valid year.
                                </div>
                              </InputGroup>
                            </Col>
                            <Col xs="12" sm="6">
                              <label
                                className="form__label payment-ui"
                                for="cvv"
                              >
                                CVV
                              </label>
                              <Input
                                className="input-border"
                                type="text"
                                onChange={(e) => handleCVV(e)}
                                value={state.card.cvv}
                                maxLength={state.cvvMaxLength}
                              />
                              <InputGroup
                                className="mb-3"
                                hidden={!state.cardCVV}
                              >
                                <div className="validations">
                                  Enter valid cvv.
                                </div>
                              </InputGroup>
                            </Col>
                            <Col xs="12" sm="6">
                              <label
                                className="form__label payment-ui"
                                for="cvv"
                              >
                                Billing Zip Code
                              </label>
                              <Input
                                className="input-border"
                                type="text"
                                onChange={(e) => handleZipCode(e)}
                                value={state.card.zip}
                                maxLength={6}
                              />
                              <InputGroup
                                className="mb-3"
                                hidden={!state.cardZip}
                              >
                                <div className="validations">
                                  Enter valid zip.
                                </div>
                              </InputGroup>
                            </Col>
                            <Col xs="12" sm="1">
                              <label
                                className="form__label payment-ui"
                                for="cvv"
                              >
                                &nbsp;
                              </label>
                              <Button
                                type="button"
                                className="payment-button"
                                onClick={(e) => chargeNewCard(e)}
                              >
                                PAY $ {state.userCardDetails.amount_payable}
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {/* end of card details ui */}
                  {/* show spinner*/}
                  <Row hidden={!state.payment_response_wait}>
                    <div class="spinner-wave">
                      <div class="mar-right-4"></div>
                      <div class="mar-right-4"></div>
                      <div class="mar-right-4"></div>
                      <div class="mar-right-4"></div>
                      <div class="mar-right-4"></div>
                    </div>
                  </Row>
                  {/* end of spinner */}
                </div>
              </a>
              <PayPalButton
                amount={payment_details.price}
                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details, data) => {
                  onPaypalSuccess(details);
                  // OPTIONAL: Call your server to save the transaction
                }}
                options={{
                  clientId:
                    "AcBy0s6ShxWoOTUFmVqLgt61HTUhzWEP5IXnpOgYkq0GAODr1tYvyzHUGSX-IPcFx6k0dLUMb0FvtC_F",
                  disableCard: "visa,mastercard,amex",
                }}
              />
              <a></a>
            </div>
          </div>
        </Col>
        <Col xs="3" md="3"></Col>
      </Row>
      {/* endo of select payment modes */}

      {/* card payment page */}

      {/* end of card payment page */}
      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

export default CoinPayment;
