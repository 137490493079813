import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./AnnouncementList.css";
import {
  deleteAnnouncement,
  getAnnouncements,
  addFilter,
} from "../../redux/reducers/admin";
import {
  InputGroup,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Input,
  Tooltip,
} from "reactstrap";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { nextPagesItems } from "../../helpers/custom";
import { getSubscription } from "../../redux/reducers/seller";
import FreemiumPage from "../Seller/CustomPages/FreemiumPage";

const divStyle = { marginLeft: "10%" };

const AnnouncementList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const advertisementsTranslation =
    translationState?.[0]?.translations?.advertisements?.[userLanguage];
  const addAdvertAndAnnouncementTranslation =
    translationState?.[0]?.translations?.addAdvertAndAnnouncement?.[
      userLanguage
    ];

  const { announcements, filters, count, loading } = useSelector(
    (state) => state.admin
  );
  const { singleSubscription, loading: Membershiploading } = useSelector(
    (state) => state.seller
  );
  const fremiumText1 =
    "Only SOLO and Partner users can use the Live Notifications feature. Please upgrade your membership to access this and many more exciting features.";

  const { user } = useSelector((state) => state.auth);
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    dispatch(addFilter({ field: "pageNo", value: 1 }));
    dispatch(addFilter({ field: "perPage", value: 5 }));
    setSelectedPerPage(5);
    dispatch(getSubscription()).then((res) => {
      if (res?.payload?.data?.membershipType !== "Gold") {
        dispatch(getAnnouncements());
      }
    });
  }, []);

  const deleteAnn = () => {
    setOpen(false);
    dispatch(deleteAnnouncement(selected)).then(() => {
      dispatch(getAnnouncements());
    });
  };

  const deleteConfirm = (id) => {
    setOpen(true);
    setSelected(id);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const searchElement = (evt) => {
    dispatch(addFilter({ field: "pageNo", value: 1 }));
    dispatch(addFilter({ field: "search", value: evt.target.value }));
    dispatch(getAnnouncements());
  };

  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(addFilter({ field: "pageNo", value: pageNo }));
      dispatch(addFilter({ field: "perPage", value: perPage }));
      dispatch(getAnnouncements());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(addFilter({ field: "pageNo", value: pageNo }));
    dispatch(addFilter({ field: "perPage", value: Number(e.target.value) }));
    dispatch(getAnnouncements());
  };

  const showModal = (id) => {
    history.push(`/${user.userType}/announcementlist/view/${id}`);
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      {Membershiploading ? (
        <div className="mt-5" style={{ textAlign: "center" }}>
          <i
            className="fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : singleSubscription?.membershipType !== "Gold" ? (
        <Row>
          <Col>
            <Card className="mt-5">
              <CardHeader>
                <Row>
                  <Col xs="6" sm="3" md="9">
                    <b>
                      {addAdvertAndAnnouncementTranslation?.list
                        ? addAdvertAndAnnouncementTranslation?.list
                        : "Announcements List"}
                    </b>
                  </Col>
                  {user.userType == "admin" && (
                    <Col xs="6" sm="3" md="3">
                      <Button
                        onClick={() =>
                          history.push("/admin/announcementlist/add")
                        }
                        size="sm"
                        style={divStyle}
                        color="warning"
                      >
                        <b>Make Announcement</b>
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody className="card-body">
                <Row>
                  <Col xs="12" sm="4">
                    <InputGroup>
                      <Input
                        type="text"
                        id="input1-group2"
                        onChange={searchElement}
                        className="search-color"
                        name="input1-group2"
                        placeholder={
                          addAdvertAndAnnouncementTranslation?.search
                            ? addAdvertAndAnnouncementTranslation?.search
                            : "Search Announcements"
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <br />
                <Table hover bordered striped responsive size="sm">
                  <thead align="center">
                    <tr className="header-color">
                      <th>
                        {addAdvertAndAnnouncementTranslation?.id
                          ? addAdvertAndAnnouncementTranslation?.id
                          : "Announcement Id"}{" "}
                      </th>
                      <th>
                        {digitalProductTranslation?.description
                          ? digitalProductTranslation?.description
                          : "Description"}{" "}
                      </th>
                      <th>
                        {addAdvertAndAnnouncementTranslation?.create
                          ? addAdvertAndAnnouncementTranslation?.create
                          : "Created Date"}
                      </th>
                      <th>
                        {addAdvertAndAnnouncementTranslation?.update
                          ? addAdvertAndAnnouncementTranslation?.update
                          : "Updated Date"}
                      </th>
                      <th>
                        {advertisementsTranslation?.action
                          ? advertisementsTranslation?.action
                          : "Action"}{" "}
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <td colSpan={5}>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : announcements.length > 0 && !loading ? (
                    announcements.map((item, index) => (
                      <tbody>
                        <tr
                          style={{
                            backgroundColor:
                              item.thread?.some((item) => !item.isRead) &&
                              user.userType !== "admin"
                                ? "rgb(250, 204, 5, 0.5)"
                                : "",
                          }}
                        >
                          <td
                            align="center"
                            style={{ verticalAlign: "middle" }}
                          >
                            {" "}
                            {item._id}{" "}
                          </td>
                          <td> {item.message} </td>
                          <td align="center">
                            {moment(item.createdDate).format(
                              "MMM D YYYY, hh:m A"
                            )}
                          </td>
                          <td align="center">
                            {moment(item.updatedAt).format(
                              "MMM D YYYY, hh:m A"
                            )}
                          </td>
                          {/* <td align="center">
                            <i className="fa fa-eye fa-lg mt-4" style={{ marginRight:'3px' }} onClick={(e) => showModal(item._id)}></i>
                            <i className="fa fa-trash fa-lg mt-4" onClick={(e) => deleteConfirm(item._id)}></i>

                          </td> */}
                          <td align="center">
                            <i
                              id={`view-tooltip-${item._id}`}
                              className="fa fa-eye"
                              style={{
                                cursor: "pointer",
                                color: "#000",
                                marginRight: "10px",
                              }}
                              onClick={() => showModal(item._id)}
                            />
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[`view-${item._id}`]}
                              target={`view-tooltip-${item._id}`}
                              toggle={() => toggleTooltip(`view-${item._id}`)}
                              className="custom-tooltip"
                            >
                              {addAdvertAndAnnouncementTranslation?.viewDetailsTooltip ||
                                "View Details"}
                            </Tooltip>

                            <i
                              id={`delete-tooltip-${item._id}`}
                              className="fa fa-trash"
                              style={{ cursor: "pointer", color: "#000" }}
                              onClick={() => deleteConfirm(item._id)}
                            />
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[`delete-${item._id}`]}
                              target={`delete-tooltip-${item._id}`}
                              toggle={() => toggleTooltip(`delete-${item._id}`)}
                              className="custom-tooltip"
                            >
                              {addAdvertAndAnnouncementTranslation?.deleteTooltip ||
                                "Delete Announcement"}
                            </Tooltip>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <td colSpan={5} className="py-5 text-center">
                      <p style={{ color: "red" }}>
                        {walletTranslation?.notFound
                          ? walletTranslation?.notFound
                          : "Records Not Found"}
                      </p>
                    </td>
                  )}
                  <Modal
                    isOpen={open}
                    toggle={deleteAnn}
                    className={"modal-danger"}
                  >
                    <ModalHeader>Delete Announcement</ModalHeader>
                    <ModalBody>
                      Do you really want to delete this Announcement?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={(e) => deleteAnn()}>
                        Yes
                      </Button>
                      <Button color="secondary" onClick={(e) => hideModal()}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Table>
                <nav>
                  <Pagination className="mt-3">
                    <PaginationItem>
                      <PaginationLink
                        onClick={(e) => paginate(pageNo - 1)}
                        disabled={pageNo === 1 ? true : false}
                        style={{
                          backgroundColor:
                            (pageNo === 1 ? true : false) && "#e4e7ea",
                        }}
                      >
                        {reportingTranslation?.previousButton
                          ? reportingTranslation?.previousButton
                          : "Prev"}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => paginate(pageNo + 1)}
                        disabled={!clickableNext}
                        style={{
                          backgroundColor: !clickableNext ? "#e4e7ea" : null,
                        }}
                        className="mx-2"
                      >
                        {reportingTranslation?.nextButton
                          ? reportingTranslation?.nextButton
                          : "Next"}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={"ml-auto"}>
                      <PaginationLink
                        className="rounded"
                        style={{
                          color: "black",
                          hover: false,
                          backgroundColor: "white",
                        }}
                      >
                        <div>
                          {productTranslation?.itemPerPage
                            ? productTranslation?.itemPerPage
                            : "Items per Page"}
                          <select
                            className="ml-2"
                            value={selectedPerPage}
                            onChange={(e) => perPageHandler(e, pageNo)}
                          >
                            {itemsPerPageOptions.map((option, i) => (
                              <option key={i} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <FreemiumPage text={fremiumText1} />
      )}
    </div>
  );
};
export default AnnouncementList;
