import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../config/axios";
import { getMessage } from "../helpers";

export const buyYinnCoin = createAsyncThunk(
  "/user/buyYinnCoin",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/payments/yinncoin/buy`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const buyProducts = createAsyncThunk(
  "/user/buyProducts",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`payments/cart/checkout`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getWallet = createAsyncThunk(
  "/user/getWallet",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/wallet/user`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getYinnExhangeRate = createAsyncThunk(
  "/user/getYinnExhangeRate",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/currency/yinnCoinExchangeRate`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const toLocalBankTransferAccountDetails = createAsyncThunk(
  "/user/toLocalBankTransferAccountDetails",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `/paymentProcedure/localBankTransferAccountDetails`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err?.response && err?.response?.data) {
        return thunkAPI.rejectWithValue({
          err: err?.response?.data,
          status: err?.response?.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const cashAllowed = createAsyncThunk(
  "/user/cashAllowed",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/payments/checkcashallowed`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createProfilePayout = createAsyncThunk(
  "/user/createProfilePayout",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/user/payouts/createprofile`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success")
        toast.success(toastTranslation ? toastTranslation : "profile updated successfully")
        // toast.success("profile updated successfully");
      return response.data;
    } catch (err) {
      toast.error(err.message);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const createPayoutMethod = createAsyncThunk(
  "/user/createPayoutMethod",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/user/payouts/configurePayout`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success")
        toast.success(toastTranslation ? toastTranslation : "Vendor created successfully");
      // toast.success("Vendor created successfully");
      return response.data;
    } catch (err) {
      toast.error(err.message);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updatePayoutMethod = createAsyncThunk(
  "/user/updatePayoutMethod",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `/user/payouts/configurePayoutUpdate`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const toastTranslation = getMessage(state, "success")
        toast.success(toastTranslation ? toastTranslation : "Payout updated successfully");
      // toast.success("Payout updated successfully");
      return response.data;
    } catch (err) {
      toast.error(err.message);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getTransactions = createAsyncThunk(
  "/user/transaction",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/transaction/gettransaction`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.message);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getInvoices = createAsyncThunk(
  "/user/invoices",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/invoices/get`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.message);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getSingleInvoice = createAsyncThunk(
  "/user/singleinvoice",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/invoices/getsingleinvoice/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.message);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
 
// LIVE CHAT
export const CreateChat = createAsyncThunk("/user/chat/create/", async (data, { getState }, thunkAPI) => {
  try {
    const state = getState();
    const { token } = state.auth;
    const res = await axios.post("/chat/create", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (err) {
    toast.error(err.response.data);
    if (err.response && err.response.data) {
      return thunkAPI.rejectWithValue({
        err: err.response.data,
        status: err.response.status,
      });
    }
    return thunkAPI.rejectWithValue({
      err: "Network Error",
    });
  }
});
export const GetChat = createAsyncThunk("user/chat/get", async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().auth;
    const params = {
      sellerId: data.sellerId,
    };
    const response = await axios.get(`/chat/get/${data?.id ? data.id : ""}`, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (err) {
    if (err.response?.data) {
      // toast.info(err.response.data?.message);
      // toast.error(err.response.data.error || err.response.data);
      return rejectWithValue({
        err: err.response.data.error || err.response.data,
        message: err.response.data.message,
        status: err.response.status,
      });
    } else {
      return rejectWithValue({ err: "Network Error" });
    }
  }
});
export const UpdateChatThread = createAsyncThunk(
  "user/chat/update-thread",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const response = await axios.put("/chat/update-thread", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (err) {
      if (err.response?.data) {
        // toast.info(err.response.data?.message);
        // toast.error(err.response.data.error || err.response.data);
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ err: "Network Error" });
      }
    }
  }
);
export const UpdateReadChats = createAsyncThunk(
  "user/chat/update-read-chat",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const response = await axios.patch("/chat/update-read-chat", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (err) {
      if (err.response?.data) {
        // toast.info(err.response.data?.message);
        // toast.error(err.response.data.error || err.response.data);
        return rejectWithValue({
          err: err.response.data.error || err.response.data,
          message: err.response.data.message,
          status: err.response.status,
        });
      } else {
        return rejectWithValue({ err: "Network Error" });
      }
    }
  }
);

// SELLER/CUSTOMER SUPPORT
export const createsellerSupport = createAsyncThunk(
  "/user/sellerSupport",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const res = await axios.post("/sellersupport/create", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return res.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getsellerSupport = createAsyncThunk(
  "/user/sellerSupport",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const res = await axios.get("/sellersupport/get", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return res.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getsingleSellerSupport = createAsyncThunk(
  "/user/singlesellersupport",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const res = await axios.get(
        `/sellersupport/getsinglesellersupport/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return res.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const replySellerSupport = createAsyncThunk(
  "/user/replySellersupport",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const res = await axios.put(`/sellersupport/update/`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return res.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const readTickets = createAsyncThunk(
  "/user/readTickets",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.patch(`/sellersupport/read`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getTranslation = createAsyncThunk(
  "/user/getTranslation",
  async (data, thunkAPI) => {
    try {
      const res = await axios.get("translation/get-translation");
      return res.data;
    } catch (err) {
      // toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getAllWebContent = createAsyncThunk(
  "/user/getallwebcontent",
  async (data, thunkAPI) => {
    try {
      const res = await axios.get("translation/get-web-content");
      return res.data;
    } catch (err) {
      // toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getUser = createAsyncThunk(
  "/user/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { token } = state.auth;
      const response = await axios.get(`/user/get/${data.id ? data.id : ""}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({ err: err.response.data, status: err.response.status });
      }
      return rejectWithValue({ err: "Network Error" });
    }
  }
);
export const deleteUser = createAsyncThunk(
  "/user/deleteUser",
  async (data, {getState}, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const res = await axios.post(`/user/feedback-delete-user/`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return res.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
})
 
export const temporaryUpdate = createAsyncThunk(
  "/admin/user",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(`/users/temporary-update/${data.userId}`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

// SHOP DETAILS
export const GetShopDetails = createAsyncThunk("user/shopDetails/get", async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().auth;
    const params = {
      sellerId: data.sellerId,
    };
    const response = await axios.get(`/shopDetails/get/${data?.id ? data.id : ""}`, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (err) {
    if (err.response?.data) {
      // toast.info(err.response.data?.message);
      // toast.error(err.response.data.error || err.response.data);
      return rejectWithValue({
        err: err.response.data.error || err.response.data,
        message: err.response.data.message,
        status: err.response.status,
      });
    } else {
      return rejectWithValue({ err: "Network Error" });
    }
  }
});


const initialState = {
  userProfileData: null,
  wallet: {},
  exchangeRate: 0,
  localBankTransferAccountDetails: null,
  userCurrency: "USD",
  userLanguage: "en",
  transaction: [],
  invoices: [],
  singleInvoice: {},
  sellersupport: [],
  singlesellersupport: {},
  message: "",
  loading: false,
  paymentConfirm: false,
  err: "",
  done: false,
  translationState: [],
  allWebContent: [],
  shopDetails: null,
  chatList: [],
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState(state, action) {
      return initialState;
    },
    setClearItem(state, { payload: { field } }) {
      state[field] = null;
    },
  },
  extraReducers: {
    [createProfilePayout.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createProfilePayout.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [createProfilePayout.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updatePayoutMethod.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updatePayoutMethod.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [updatePayoutMethod.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err.error,
    }),
    [createPayoutMethod.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [createPayoutMethod.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [createPayoutMethod.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err.error,
    }),
    [getWallet.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getWallet.fulfilled]: (state, action) => ({
      ...state,
      wallet: action.payload.data || {},
      loading: false,
      err: "",
    }),
    [getWallet.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getYinnExhangeRate.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getYinnExhangeRate.fulfilled]: (state, action) => ({
      ...state,
      exchangeRate: action.payload.yinnCoinExchnageRate || {},
      loading: false,
      err: "",
    }),
    [getYinnExhangeRate.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [toLocalBankTransferAccountDetails.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [toLocalBankTransferAccountDetails.fulfilled]: (state, action) => ({
      ...state,
      localBankTransferAccountDetails: action.payload || {},
      loading: false,
      err: "",
    }),
    [toLocalBankTransferAccountDetails.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [cashAllowed.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [cashAllowed.fulfilled]: (state, action) => ({
      ...state,
      isCashAllowed: action.payload?.data?.allowed || {},
      loading: false,
      err: "",
    }),
    [cashAllowed.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [buyYinnCoin.pending]: (state, action) => ({
      ...state,
      paymentConfirm: false,
      loading: true,
    }),
    [buyYinnCoin.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      paymentConfirm: true,
      err: "",
    }),
    [buyYinnCoin.rejected]: (state, action) => ({
      ...state,
      loading: false,
      paymentConfirm: false,
      err: action.error.message,
    }),
    [buyProducts.pending]: (state, action) => ({
      ...state,
      paymentConfirm: false,
      loading: true,
    }),
    [buyProducts.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      paymentConfirm: true,
      err: "",
    }),
    [buyProducts.rejected]: (state, action) => ({
      ...state,
      loading: false,
      paymentConfirm: false,
      err: action.payload.err,
    }),

    [getTransactions.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getTransactions.fulfilled]: (state, action) => ({
      ...state,
      transaction: action.payload.data,
      loading: false,
      err: "",
    }),
    [getTransactions.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),

    [getInvoices.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getInvoices.fulfilled]: (state, action) => ({
      ...state,
      invoices: action.payload.data,
      loading: false,
      err: "",
    }),
    [getInvoices.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),

    [getSingleInvoice.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSingleInvoice.fulfilled]: (state, action) => ({
      ...state,
      singleInvoice: action.payload.data,
      loading: false,
      err: "",
    }),
    [getSingleInvoice.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),

    // LIVE CHAT
    [CreateChat.pending]: (state, action) => ({
      ...state,
      loading: true,
      done: false,
    }),
    [CreateChat.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
      isLogged: true,
    }),
    [CreateChat.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [GetChat.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [GetChat.fulfilled]: (state, { payload }) => ({
      ...state,
      chatList: payload?.data,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [GetChat.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),
    [UpdateChatThread.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [UpdateChatThread.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [UpdateChatThread.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),
    [UpdateReadChats.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [UpdateReadChats.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [UpdateReadChats.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),

    // SELLER/CUSTOMER SUPPORT
    [createsellerSupport.pending]: (state, action) => ({
      ...state,
      loading: true,
      done: false,
    }),
    [createsellerSupport.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
      isLogged: true,
    }),
    [createsellerSupport.rejected]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: action.error.message,
    }),
    [getsellerSupport.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getsellerSupport.fulfilled]: (state, action) => ({
      ...state,
      sellersupport: action.payload.data,
      loading: false,
      done: true,
      err: "",
      isLogged: true,
    }),
    [getsellerSupport.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getsingleSellerSupport.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getsingleSellerSupport.fulfilled]: (state, action) => ({
      ...state,
      singlesellersupport: action.payload.data,
      loading: false,
      done: true,
      err: "",
      isLogged: true,
    }),
    [getsingleSellerSupport.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [replySellerSupport.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [replySellerSupport.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [replySellerSupport.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [readTickets.pending]: (state, action) => ({
      ...state,
    }),
    [readTickets.fulfilled]: (state, action) => ({
      ...state,
      done: true,
      err: "",
    }),
    [readTickets.rejected]: (state, action) => ({
      ...state,
      err: action.error.message,
    }),
    [getTranslation.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getTranslation.fulfilled]: (state, action) => ({
      ...state,
      translationState: action.payload,
      loading: false,
      err: "",
    }),
    [getTranslation.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAllWebContent.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAllWebContent.fulfilled]: (state, action) => ({
      ...state,
      allWebContent: action.payload,
      loading: false,
      err: "",
    }),
    [getAllWebContent.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getUser.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getUser.fulfilled]: (state, action) => ({
      ...state,
      userProfileData: action.payload.data,
      loading: false,
      err: "",
    }),
    [getUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [deleteUser.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [deleteUser.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [deleteUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),

    // SHOP DETAILS
    [GetShopDetails.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
      done: false,
      message: "",
      err: "",
    }),
    [GetShopDetails.fulfilled]: (state, { payload }) => ({
      ...state,
      shopDetails: payload?.data,
      message: payload?.message,
      loading: false,
      done: true,
    }),
    [GetShopDetails.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload?.err,
      message: payload?.message,
      status: payload?.status,
    }),
  },
});

const { reducer, actions } = user;

export const { SetState, setClearItem, SetNewState } = actions;

export default reducer;
