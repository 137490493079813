import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { AppHeader } from "@coreui/react";
import DefaultFooter from "../Footer/DefaultFooter";
import DefaultHeader from "../Header/DefaultHeader";
import { useDispatch, useSelector } from "react-redux";
import "./checkout.css";
import shippingSrc from "../../assets/img/checkout/shipping.svg";
import paymentSrc from "../../assets/img/checkout/payment.svg";
import paymentActiveSrc from "../../assets/img/checkout/payment-active.svg";
import confirmSrc from "../../assets/img/checkout/confirmation.svg";
import confirmActiveSrc from "../../assets/img/checkout/confirmation-active.svg";
import Shipping from "./Shipping";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
import { getExhangeRate } from "../../redux/reducers/auth";

const Checkout = (props) => {
  const dispatch = useDispatch();
  const { cart, loading, usdRate, paymentConfiguration } = useSelector(
    (state) => state.auth
  );
  const { userCurrency } = useSelector((state) => state.user);
  const [step, setStep] = useState(1);
  const [shippingInfo, setShippingInfo] = useState({
    firstname: "",
    lastname: "",
    addressLineOne: "",
    addressLineTwo: "",
    zipCode: "",
    country: "",
    state: "",
    city: "",
  });
  const [billingAdress, setBillingAdress] = useState({
    firstname: "",
    lastname: "",
    addressLineOne: "",
    addressLineTwo: "",
    zipCode: "",
    country: "",
    state: "",
    city: "",
  });
  const [paymentData, setPaymentData] = useState({
    card: {
      cardNo: "",
      expMonth: "",
      expYear: "",
      cvv: "",
      zipCode: "",
    },
    paymentMethod: null,
  });
  const [pricingBreakdown, setPricingBreakdown] = useState({ total: usdRate });
  const [addressReused, setAddressReused] = useState(false);
  let onlyResidencyProducts = cart.every(
    (pro) =>
      pro.product.productType === "physical" && pro.product.isResidency === true
  );
  let onlyDigitalProducts = cart.every(
    (pro) => pro.product.productType === "digital"
  );
  useEffect(() => {
    if (onlyResidencyProducts) {
      setStep(2);
    }
    if (onlyDigitalProducts && billingAdress.addressLineOne !== "") {
      setStep(2);
    }
    dispatch(getExhangeRate(userCurrency));
  }, []);
  const changePaymentData = (paymentData) => {
    setPaymentData(paymentData);
  };

  const reuseShippingAddress = (e) => {
    if (e.target.checked) {
      setBillingAdress(shippingInfo);
      setAddressReused(true);
    } else {
      setBillingAdress({
        firstname: "",
        lastname: "",
        addressLineOne: "",
        addressLineTwo: "",
        zipCode: "",
        country: "",
        state: "",
        city: "",
      });
      setAddressReused(false);
    }
  };
  const salesPrice =
    cart.reduce((a, b) => a + b.product?.unitPrice * b.qty, 0) * usdRate;

  const shippingCharges =
    cart.reduce((a, b) => a + b.product?.shippingCharges * b.qty, 0) * usdRate;

  const yinnPercentage = (
    paymentConfiguration?.checkoutPaymentPercentageCharge * salesPrice
  ).toFixed(2);

  const totalPrice = (
    Number(yinnPercentage) +
    salesPrice +
    // paymentConfiguration?.coinMaintenanceCharge +
    shippingCharges +
    paymentConfiguration?.checkoutPaymentFixCharge
  ).toFixed(2);

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const confirmationTranslation =
    translationState?.[0]?.translations?.confirmation?.[userLanguage];

  return (
    <Container fluid style={{ backgroundColor: "#f6f6f6", padding: 0 }}>
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <Row xs={12}>
        <div className="checkout-top-bar">
          <div className="checkout-top-bar-inner">
            <div className="checkout-top-bar-inner-section">
              <img
                className="checkout-top-bar-inner-section-img"
                src={shippingSrc}
              />
              <div className="hide-text-on-mobile checkout-top-bar-inner-section-text-completed">
                {confirmationTranslation?.shipping
                  ? confirmationTranslation?.shipping
                  : "SHIPPING"}
              </div>
            </div>
            <div className="checkout-top-bar-inner-section">
              <img
                className="checkout-top-bar-inner-section-img"
                src={step < 2 ? paymentSrc : paymentActiveSrc}
              />
              <div
                className={
                  step < 2
                    ? "checkout-top-bar-inner-section-text hide-text-on-mobile"
                    : "checkout-top-bar-inner-section-text-completed hide-text-on-mobile"
                }
              >
                {confirmationTranslation?.payment
                  ? confirmationTranslation?.payment
                  : "PAYMENT"}
              </div>
            </div>
            <div className="checkout-top-bar-inner-section-last">
              <img
                className="checkout-top-bar-inner-section-img"
                src={step < 3 ? confirmSrc : confirmActiveSrc}
              />
              <div
                className={
                  step < 3
                    ? "checkout-top-bar-inner-section-text hide-text-on-mobile"
                    : "checkout-top-bar-inner-section-text-completed hide-text-on-mobile"
                }
              >
                {confirmationTranslation?.confirmation
                  ? confirmationTranslation?.confirmation
                  : "CONFIRMATION"}
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row className="checkout-content">
        <Col xs="0" sm="1" />
        <Col xs="12" sm="10">
          {step === 1 && (
            <Shipping
              shippingInfo={shippingInfo}
              setShippingInfo={setShippingInfo}
              moveStep={setStep}
              cartData={cart}
              pricingBreakdown={pricingBreakdown}
              paymentData={paymentData}
              dataLoading={loading}
              yinnPercentage={yinnPercentage}
              totalPrice={totalPrice}
            />
          )}
          {step === 2 && (
            <Payment
              billingAdress={billingAdress}
              setBillingAdress={setBillingAdress}
              paymentData={paymentData}
              setPaymentData={changePaymentData}
              moveStep={setStep}
              cartData={cart}
              pricingBreakdown={pricingBreakdown}
              reuseShippingAddress={reuseShippingAddress}
              shippingInfo={shippingInfo}
              addressReused={addressReused}
              dataLoading={loading}
              yinnPercentage={yinnPercentage}
              totalPrice={totalPrice}
              shippingCharges={shippingCharges}
              onlyResidencyProducts={onlyResidencyProducts}
              onlyDigitalProducts={onlyDigitalProducts}
            />
          )}
          {step === 3 && <Confirmation moveStep={setStep} />}
        </Col>
        <Col xs="0" sm="1" />
      </Row>

      <DefaultFooter />
    </Container>
  );
};

export default Checkout;
