import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { AppSwitch } from "@coreui/react";
import { getProfileData } from "../../redux/reducers/auth";
import { updateUser } from "../../redux/reducers/admin";
import {
  bluesnapSupportedData,
  bluesnapSupportedCountries,
  bluesnapSupportedCurrencies,
} from "../../assets/bluesnap/bluesnapSupport";
import Config from "../../config/aws";
import { EditShopDetails } from "../../redux/reducers/seller";
import { GetShopDetails } from "../../redux/reducers/user";
import { getPresigedUrl, uploadToS3ViaSignedUrl } from "../../helpers/s3Utils";

const initialState = {
  _id: "",
  sellerId: "",
  membershipNo: "",
  address: "",
  businessName: "",
  city: "",
  country: "",
  countryCode: "",
  postalCode: "",
  profileUrl: "",
  state: "",
  phone: 0,
};
const Setting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { translationState, shopDetails } = useSelector((state) => state.user);
  const {
    user: { id, localBankTransferSwitch, email },
    user,
    userLanguage,
    vendorId,
    loading,
  } = useSelector((state) => state.auth);
  const profileRef = useRef(null);
  const profileCoverRef = useRef(null);
  const [translatedContent, setTranslatedContent] = useState({});
  const [formError, setFormError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [settingData, setSettingData] = useState(initialState);

  useEffect(() => {
    dispatch(getProfileData());
    dispatch(GetShopDetails({ sellerId: id }));
  }, []);

  useEffect(() => {
    setSettingData({ ...settingData, ...shopDetails, email });
  }, [shopDetails]);

  useEffect(() => {
    if (translationState?.length) {
      const missedTranslation =
        translationState?.[0]?.translations?.missed?.[userLanguage];
      missedTranslation
        ? setTranslatedContent({ ...translatedContent, ...missedTranslation })
        : setTranslatedContent({});
    }
  }, [translationState, userLanguage]);

  const handleSwitchInput = (e, userId) => {
    const value = e.target.checked;
    const data = {
      userId,
      localBankTransferSwitch: value,
    };
    dispatch(updateUser(data));
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "country") {
      const countryObj = bluesnapSupportedCountries.find(
        (obj) => obj.Country === value
      );
      setSettingData({
        ...settingData,
        country: countryObj?.Country,
        countryCode: countryObj?.Code,
      });
    } else {
      setSettingData({ ...settingData, [name]: value });
    }
  };
  const uploadImage = async (blobs, type) => {
    for (let i = 0; i < blobs.length; i++) {
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };

      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const url = `${Config.digitalOceanSpaces}/` + blob.name;
        if (type === "profilePhoto") {
          toast.success(
            `${
              translatedContent?.success
                ? translatedContent?.success
                : "uploaded successfully"
            }`
          );
          setSettingData({ ...settingData, profileUrl: url });
        } else {
          toast.success(
            `${
              translatedContent?.success
                ? translatedContent?.success
                : "Cover uploaded successfully"
            }`
          );
          setSettingData({ ...settingData, coverUrl: url });
        }
      } catch (error) {
        toast.error(
          `${
            translatedContent?.error
              ? translatedContent?.error
              : "error in upload file"
          }`
        );
      }
    }
  };

  const submitPayoutDetails = async () => {
    const {
      businessName,
      email,
      address,
      city,
      country,
      countryCode,
      state,
      postalCode,
      phone,
    } = settingData;
    if (
      !businessName ||
      !email ||
      !address ||
      !city ||
      !country ||
      !countryCode ||
      !state ||
      !postalCode ||
      !phone
    ) {
      toast.error(
        `${
          translatedContent?.success
            ? translatedContent?.success
            : "Kindly fill the required fields"
        }`
      );
      return;
    }
    await dispatch(EditShopDetails(settingData));
    dispatch(GetShopDetails({ sellerId: id }));
  };

  return (
    <div
      style={{
        paddingLeft: "5%",
        paddingRight: "5%",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <ToastContainer autoClose={20000} />
      <Card>
        <CardHeader>
          {translatedContent?.setting || "All Settings Configration"}
        </CardHeader>
        {loading ? (
          <div style={{ margin: "240px", textAlign: "center" }}>
            <i
              className="mx-auto fas fa-spinner fa-pulse fa-2x"
              style={{ color: "green" }}
            ></i>
          </div>
        ) : (
          <CardBody style={{ paddingLeft: 20, paddingTop: 40 }}>
            <Row>
              <Col>
                <h4 style={{ marginBottom: 10 }}>
                  {translatedContent?.local
                    ? translatedContent?.local
                    : "Local Bank Transfer Availability"}
                </h4>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <AppSwitch
                  className={"mx-1 "}
                  variant={"pill"}
                  color={"primary"}
                  name="isPublished"
                  checked={localBankTransferSwitch}
                  onChange={(e) => handleSwitchInput(e, id)}
                />
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
      <Card>
        <CardHeader>
          {translatedContent?.streamingConfigs || "Shop Details"}
        </CardHeader>
        {loading ? (
          <div style={{ margin: "240px", textAlign: "center" }}>
            <i
              className="mx-auto fas fa-spinner fa-pulse fa-2x"
              style={{ color: "green" }}
            ></i>
          </div>
        ) : (
          <CardBody style={{ paddingLeft: 20, paddingTop: 40 }}>
            <Row>
              <Col>
                {/* <h4 style={{ marginBottom: 35, marginTop: 20 }}>
                  {translatedContent?.headingOne ? translatedContent?.headingOne : "Payout account holder details"}
                </h4> */}
                <Form>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="businessName">
                      {translatedContent?.firsrtName
                        ? translatedContent?.firsrtName
                        : "Business Name "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      onChange={handleInputChange}
                      value={settingData.businessName}
                      type="text"
                      name="businessName"
                      id="businessName"
                      placeholder="type in your business name here"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="country">
                      {translatedContent?.country
                        ? translatedContent?.country
                        : "Country "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={settingData.country}
                      onChange={handleInputChange}
                      type="select"
                      name="country"
                      id="country"
                    >
                      <option value="">
                        {translatedContent?.country
                          ? translatedContent?.country
                          : "select Country"}
                      </option>
                      {bluesnapSupportedCountries?.map((obj, index) => (
                        <option key={index} value={obj?.Country}>
                          {obj.Country}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="address">
                      {translatedContent?.address
                        ? translatedContent?.address
                        : "Address "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={settingData.address}
                      onChange={handleInputChange}
                      type="text"
                      name="address"
                      id="address"
                      placeholder="House no, Street Name"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="phone">
                      {translatedContent?.phone
                        ? translatedContent?.phone
                        : "Phone No. "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={settingData.phone}
                      onChange={handleInputChange}
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Phone No"
                    />
                  </FormGroup>
                  <FormGroup row className="flex-column">
                    <div style={{ display: "flex" }}>
                      <Label htmlFor="file-multiple-input">
                        {translatedContent?.uploadCover
                          ? translatedContent?.uploadCover
                          : "Upload Profile Photo"}
                      </Label>
                    </div>
                    <Col xs="12" md="9" className="p-0">
                      <Button
                        className={`input-prepend ${
                          !settingData.profileUrl && formError && "form-error"
                        }`}
                        onClick={() => profileRef.current.click()}
                      >
                        {translatedContent?.uploadMusic
                          ? translatedContent?.uploadMusic
                          : "Upload a file"}
                      </Button>
                      <Label
                        style={{
                          marginLeft: 10,
                          fontSize: "13px",
                          fontWeight: "normal",
                        }}
                      >
                        {translatedContent?.note
                          ? translatedContent?.note +
                            ":" +
                            translatedContent?.noteExplain
                          : "Note: Recommended size for image is 120 * 120"}
                      </Label>
                      <input
                        type="file"
                        onChange={(e) =>
                          uploadImage(e.target.files, "profilePhoto")
                        }
                        ref={profileRef}
                        name="files"
                        style={{ display: "none" }}
                      />
                      <p>{settingData.profileUrl?.split("/")?.slice(-1)}</p>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
              <Col>
                <Form>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="email">
                      {translatedContent?.email
                        ? translatedContent?.email
                        : "Email "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      disabled
                      value={settingData.email}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="your email address"
                    />
                  </FormGroup>{" "}
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="state">
                      {translatedContent?.state
                        ? translatedContent?.state
                        : "State/Province Code"}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={settingData.state}
                      onChange={handleInputChange}
                      type="text"
                      name="state"
                      id="state"
                      placeholder="e.g, CA"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="city">
                      {translatedContent?.city
                        ? translatedContent?.city
                        : "City"}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={settingData.city}
                      onChange={handleInputChange}
                      type="text"
                      name="city"
                      id="city"
                      placeholder="e.g, Los Angeles"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="postalCode">
                      {translatedContent?.postal
                        ? translatedContent?.postal
                        : "Postal Code "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={settingData.postalCode}
                      onChange={handleInputChange}
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      placeholder="Postal Code"
                    />
                  </FormGroup>
                  <FormGroup row className="flex-column">
                    <div style={{ display: "flex" }}>
                      <Label htmlFor="file-multiple-input">
                        {translatedContent?.uploadCover
                          ? translatedContent?.uploadCover
                          : "Upload Profile Cover"}
                      </Label>
                    </div>
                    <Col xs="12" md="9" className="p-0">
                      <Button
                        className={`input-prepend ${
                          !settingData.coverUrl && formError && "form-error"
                        }`}
                        onClick={() => profileCoverRef.current.click()}
                      >
                        {translatedContent?.uploadMusic
                          ? translatedContent?.uploadMusic
                          : "Upload a file"}
                      </Button>
                      <Label
                        style={{
                          marginLeft: 10,
                          fontSize: "13px",
                          fontWeight: "normal",
                        }}
                      >
                        {translatedContent?.note
                          ? translatedContent?.note +
                            ":" +
                            translatedContent?.noteExplain
                          : "Note: Recommended size for image is 300 * 300"}
                      </Label>
                      <input
                        type="file"
                        onChange={(e) =>
                          uploadImage(e.target.files, "profileCover")
                        }
                        ref={profileCoverRef}
                        name="files"
                        style={{ display: "none" }}
                      />
                      <p>{settingData.coverUrl?.split("/")?.slice(-1)}</p>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md={{ size: 8, offset: 5 }}>
                <Button
                  size="lg"
                  color="warning"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                  disabled={loading}
                  hidden={vendorId}
                  onClick={submitPayoutDetails}
                >
                  {translatedContent?.profile
                    ? translatedContent?.profile
                    : "Update Info"}
                </Button>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default Setting;
