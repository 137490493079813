import React, { Component, useEffect } from "react";
import {
  Button,
  Form,
  Nav,
  NavItem,
  DropdownToggle,
  UncontrolledDropdown,
  Navbar,
  NavbarToggler,
  Collapse,
  NavLink,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Label,
  CardHeader,
  FormGroup,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { render } from "react-dom";
import logo from "../../../assets/img/brand/logo.png";
import sygnet from "../../../assets/img/brand/logo.png";
import classnames from "classnames";
import axios from "axios";
import Env from "../../../environment/environment";
import "./digitalproductdetail.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ReactPlayer from "react-player";
import {
  defaultDiscount,
  ApplyCustomOffer,
  ApplyCustomOfferVariantWise,
  setQuantityAndPrice,
  ApplyPriceTier,
} from "../ProductDetail/priceService";
import { AppFooter, AppHeader } from "@coreui/react";

import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import { useSelector , useDispatch } from "react-redux";

const DigitalProductDetail = () => {
const dispatch = useDispatch();
  const {translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];

  let toastId = null;
  let state = {
    activeTab: "1",
    counter: 1,
    video: "",
    category: [],
    display_prod: false,
    products: [],
    search_product: null,
    singleImage: null,
    singleVideo: null,
    sellerDetail: {},
    seller_id: "",
    attributeArray: [],
    discountedPrice: 0,
    attributesForPrice: [],
    variants: [],
    availableQuantites: 0,
    newSalesPrice: 0,
    quantityFlag: true,
    priceTier: [],
    discounts: [],
    customers: {},
    applicableDiscount: false,
    productUpdateForDiscount: [],
    updatedProduct: [],
    offerObject: {
      finalPrice: 0,
      discountPercentage: 0,
      salesPrice: 0,
      message: "",
      tier: {},
      discountId: "",
    },
    selectedVarient: [],
    offerFlag: true,
    currency: "USD",
    currencyIcon: "$",
    exchangeRate: 1,
    goTOCartFlag: false,
    isOpen: false,
    validDiscounts: [],
  };
  // constructor(props) {
  //     super(props);
  //     changePosition = changePosition;
  //     toggleNav = toggleNav;
  // }

  const toggleNav = () => {
    //setState({
    //   isOpen: !state.isOpen,
    // });
  };

  const componentWillMount = () => {
    var currency = cookie.load("currency");
    var icon = cookie.load("currencyIcon");
    var exchangeRate = cookie.load("exchangeRate");
    if (currency != undefined) {
      //setState({currency :currency})
      //setState({currencyIcon :icon})
      //setState({exchangeRate :exchangeRate})
    }
    axios.get(Env.url + "/product/category/get").then((response) => {
      var cat = response.data;
      axios.get(Env.url + "/product/subcategory/get").then((response) => {
        var sc = response.data;
        var category = [];
        for (var c in cat) {
          var subChild = [];
          for (var s in sc) {
            if (cat[c]["_id"] == sc[s]["category_id"]) {
              var child = [];
              for (var i in sc) {
                if (sc[s]["_id"] === sc[i]["category_id"]) {
                  child.push(sc[i]);
                }
              }
              sc[s]["child"] = child;
              subChild.push(sc[s]);
            }
          }
          cat[c]["sub_category"] = subChild;
          category.push(cat[c]);
        }
        //setState({ category: category });
      });
    });

    axios
      .get(Env.url + "/discount/get", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //setState({ discounts: response.data });
          let discounts = response.data;
          if (discounts.length > 0) {
            for (var discount of discounts) {
              let productIn = false;
              let customerIn = false;
              if (discount.discountedProducts != undefined) {
                for (var discountedProduct of discount.discountedProducts) {
                  if (discountedProduct.productId === state.products[0]._id) {
                    productIn = true;
                    break;
                  }
                }
              }
              if (!productIn && discount.discountedCategories != undefined) {
                var productCategoryIds = state.products[0].category.map(
                  (category) => category._id
                );
                console.log(productCategoryIds);
                for (var discountedCategory of discount.discountedCategories) {
                  if (
                    productCategoryIds.includes(discountedCategory.categoryId)
                  ) {
                    console.log("Yes");
                    productIn = true;
                    break;
                  }
                }
              }
              if (discount.eligibilities === "Everyone") {
                customerIn = true;
              } else {
                for (var group of discount.groups) {
                  for (var customer of group.customers) {
                    if (cookie.load("_id") === customer.customerId) {
                      customerIn = true;
                      break;
                    }
                  }
                  if (customerIn) break;
                }
              }
              if (customerIn && productIn) {
                state.validDiscounts.push(discount);
              }
            }
          }
        }
      });
  };

  const componentDidMount = () => {
    axios
      .get(Env.url + "/product/get/" + cookie.load("product_id"))
      .then((response) => {
        //setState({ products: response.data });
        //setState({ singleImage: state.products[0].images[0] })
        if (state.products[0].priceTier != undefined) {
          //setState({ priceTier: state.products[0].priceTier })
          setPriceTierOrder();
        }
        var attributeArray = state.products[0].attributes;
        var finalAttributeArray = [];
        for (var i in attributeArray) {
          var splitedAttributes = attributeArray[i].value.split(",");
          var attributeObject = {
            name: attributeArray[i].name,
            value: splitedAttributes,
          };
          finalAttributeArray.push(attributeObject);
        }
        //setState({ attributeArray: finalAttributeArray })
        if (response.data[0].variants != undefined) {
          if (response.data[0].variants.length > 0) {
            //setState({ variants: response.data[0].variants })
            loadPrice();
          } else {
            loadDefaultPrice();
          }
        } else {
          loadDefaultPrice();
        }
        var auth = localStorage.getItem("auth_token");
        var that = this;
        if (auth != undefined) {
          axios
            .get(Env.url + "/cart/get/" + cookie.load("_id"), {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token"),
              },
            })
            .then(function (response) {
              if (response.data.length > 0) {
                for (var i in response.data[0].product) {
                  if (
                    response.data[0].product[i].product_id ==
                    cookie.load("product_id")
                  ) {
                    that.break; //setState({goTOCartFlag : true})
                  }
                }
              }
            });
        }

        axios
          .get(
            Env.url + "/seller/sellerdetails/get/" + response.data[0].seller_id
          )
          .then((res) => {
            //setState({ sellerDetail: res.data.data[0] });
            console.log(state.sellerDetail);
          });
      });
  };

  const setPriceTierOrder = () => {
    //
    var priceTier = state.priceTier;
    var temp = [];
    for (var i = 0; i < priceTier.length; i++) {
      for (var j = i + 1; j < priceTier.length; j++) {
        if (priceTier[i].quantity > priceTier[j].quantity) {
          temp = priceTier[i];
          priceTier[i] = priceTier[j];
          priceTier[j] = temp;
        }
      }
    }
    state.priceTier = priceTier;
    //setState({ priceTier: priceTier })
  };
  const loadDefaultPrice = async () => {
    //
    //setState({ newSalesPrice: state.products[0].sales_price })
    if (state.products[0].stock_quantity <= 0) {
      //setState({ quantityFlag: false })
    } else {
      //setState({ quantityFlag: true })
    }

    var discount = state.discounts;
    var discountedPrice = state.newSalesPrice;
    var sales_price = await defaultDiscount(discount, discountedPrice);
    //
    if (
      sales_price.discountPercentage != 0 ||
      sales_price.discountPercentage != "0"
    ) {
      state.offerFlag = false;
      //setState({offerFlag :false})
    }
    //setState({ offerObject: sales_price })
  };
  const toggle = (tab) => {
    if (state.activeTab !== tab) {
      //setState({
      //     activeTab: tab,
      // });
    }
  };

  const incrementCounter = async () => {
    var selectedVarient = state.selectedVarient;
    var priceTier = state.priceTier;
    if (selectedVarient.length == 0) {
      if (state.counter < state.products[0].stock_quantity) {
        var offerObject = state.offerObject;
        var quantity = state.counter + 1;
        var operation = "increment";
        var oferObject = await setQuantityAndPrice(
          quantity,
          offerObject,
          operation
        );
        if (state.priceTier.length > 0) {
          var oferrObject = await ApplyPriceTier(
            priceTier,
            offerObject,
            quantity
          );
          //setState({offerObject : oferrObject})
          //setState({ counter: state.counter + 1 });
          if (oferrObject.tier.discount != undefined) {
            toastId = toast.success(
              `${toastTranslation?.success ? toastTranslation?.success :  "Extra " + oferrObject.tier.discount + "% discount applied"}`,
              { position: toast.POSITION.TOP_RIGHT }
            );
          }
        } else {
          //setState({offerObject : oferObject})
          //setState({ counter: state.counter + 1 });
        }
      }
    } else {
      for (var i in selectedVarient) {
        if (selectedVarient[i].quantity != undefined) {
          if (state.counter < selectedVarient[i].quantity) {
            var offerObject = state.offerObject;
            var quantity = state.counter + 1;
            var operation = "increment";
            var oferObject = await setQuantityAndPrice(
              quantity,
              offerObject,
              operation
            );
            if (state.priceTier.length > 0) {
              var oferrObject = await ApplyPriceTier(
                priceTier,
                offerObject,
                quantity
              );
              //setState({offerObject : oferrObject})
              //setState({ counter: state.counter + 1 });
              if (oferrObject.tier.discount != undefined) {
                toastId = toast.success(
                  `${toastTranslation?.success ? toastTranslation?.success :  "Extra " + oferrObject.tier.discount + "% discount applied"}`,
                  { position: toast.POSITION.TOP_RIGHT }
                );
              }
            } else {
              //setState({offerObject : oferObject})
              //setState({ counter: state.counter + 1 });
            }
          }
        }
      }
    }
  };

  const decrementCounter = async () => {
    //
    if (state.counter > 1) {
      var priceTier = state.priceTier;
      var selectedVarient = state.selectedVarient;
      if (selectedVarient.length == 0) {
        var offerObject = state.offerObject;
        var quantity = state.counter - 1;
        var operation = "decrement";
        var oferObject = await setQuantityAndPrice(
          quantity,
          offerObject,
          operation
        );
        //
        if (state.priceTier.length > 0) {
          var oferrObject = await ApplyPriceTier(
            priceTier,
            offerObject,
            quantity
          );
          //setState({offerObject : oferObject})
          //setState({ counter: state.counter - 1 });
          if (oferrObject.tier.discount != undefined) {
            toastId = toast.success(
              `${toastTranslation?.success ? toastTranslation?.success : "Extra " + oferrObject.tier.discount + "% discount applied"}`,
              { position: toast.POSITION.TOP_RIGHT }
            );
          }
        } else {
          //setState({offerObject : oferObject})
          //setState({ counter: state.counter - 1 });
        }
      } else {
        for (var i in selectedVarient) {
          if (selectedVarient[i].quantity != undefined) {
            var offerObject = state.offerObject;
            var quantity = state.counter - 1;
            var operation = "decrement";
            var oferObject = await setQuantityAndPrice(
              quantity,
              offerObject,
              operation
            );
            //
            if (state.priceTier.length > 0) {
              var oferrObject = await ApplyPriceTier(
                priceTier,
                offerObject,
                quantity
              );
              //setState({offerObject : oferObject})
              //setState({ counter: state.counter - 1 });
              if (oferrObject.tier.discount != undefined) {
                toastId = toast.success(
                  `${toastTranslation?.success ? toastTranslation?.success :  "Extra " + oferrObject.tier.discount + "% discount applied"}`,
                  { position: toast.POSITION.TOP_RIGHT }
                );
              }
            } else {
              //setState({offerObject : oferObject})
              //setState({ counter: state.counter - 1 });
            }
          }
        }
      }
    }
  };

  const shipping = () => {
    var selectedVarient = state.selectedVarient;
    var offerObject = state.offerObject;
    var shipingDetails = {};
    if (selectedVarient.length != 0) {
      shipingDetails.selectedVarient = selectedVarient;
    }
    shipingDetails.offerObject = offerObject;
    localStorage.setItem("shipingDetails", JSON.stringify(shipingDetails));
    window.location =
      "#/shipping?product=" +
      cookie.load("product_id") +
      "&qty=" +
      state.counter +
      "&type=buyNow";
  };
  const changePosition = (image) => {
    //setState({ singleImage: image })
  };

  const searchByCategory = (item) => {
    var searchString = "";
    searchString = searchString + "&category=" + item;
    searchString = searchString + "&sort=all";
    setTimeout(function () {
      window.location = "#/search?" + searchString;
    }, 2500);
  };

  const goTOStore = () => {
    window.location = "#/sellerdetail?seller=" + state.sellerDetail.seller_id;
  };
  const goTOCart = () => {
    window.location = "#/my_cart";
  };
  const addTOCart = () => {
    //
    var selectedVarient = state.selectedVarient;
    var offerObject = state.offerObject;
    var shipingDetails = {};
    if (selectedVarient.length != 0) {
      shipingDetails.selectedVarient = selectedVarient;
    }
    shipingDetails.offerObject = offerObject;
    axios
      .get(Env.url + "/cart/get/" + cookie.load("_id"), {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        //
        if (response.data.length <= 0) {
          var data = {
            product: [
              {
                product_id: cookie.load("product_id"),
                quantity: state.counter,
                seller_id: state.sellerDetail.seller_id,
                offerObject: shipingDetails,
                discountId: state.offerObject.discountId,
              },
            ],
            user_id: cookie.load("_id"),
          };

          axios
            .post(Env.url + "/cart/create", data, {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token"),
              },
            })
            .then(function (response) {
              //setState({ items: response.data });
              window.location = "#/my_cart";
            })
            .catch((err) => {
              if (err.response != undefined) {
                if (err.response.status == 401) {
                  window.location = "#/login";
                }
              }
            });
        } else {
          var data = {
            product_id: cookie.load("product_id"),
            quantity: state.counter,
            seller_id: state.sellerDetail.seller_id,
            offerObject: shipingDetails,
            discountId: state.offerObject.discountId,
          };
          axios
            .put(
              Env.url +
                "/cart/update/push/" +
                cookie.load("_id") +
                "/" +
                cookie.load("product_id"),
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: localStorage.getItem("auth_token"),
                },
              }
            )
            .then(function (response) {
              //setState({ items: response.data });
              window.location = "#/my_cart";
            })
            .catch((err) => {
              if (err.response != undefined) {
                if (
                  err.response.status == 401 ||
                  err.response.status == 400 ||
                  err.response.status == 500
                ) {
                  window.location = "#/login";
                }
              }
            });
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 ||
            err.response.status == 400 ||
            err.response.status == 500
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const loadPrice = () => {
    var attributeArray = state.attributeArray;
    var attributesForPrice = state.attributesForPrice;
    for (var i in attributeArray) {
      var obj = {
        name: attributeArray[i].name,
        value: attributeArray[i].value[0],
      };
      attributesForPrice.push(obj);
    }
    //setState({ attributesForPrice: attributesForPrice })
    setDynamicPrice();
  };

  const loadVariantsDynamically = (name, evt) => {
    //
    var attributesForPrice = state.attributesForPrice;
    for (var i in attributesForPrice) {
      if (attributesForPrice[i].name == name) {
        attributesForPrice[i].value = evt.target.value;
      }
    }
    //setState({ attributesForPrice: attributesForPrice })
    setDynamicPrice();
  };
  const setDynamicPrice = async () => {
    //
    var attributesForPrice = state.attributesForPrice;
    var tempAttributes = [];
    for (var n in attributesForPrice) {
      tempAttributes.push(attributesForPrice[n].value);
    }
    //setState({ counter: 1 });
    var variantIndex = null;
    var variants = state.variants;
    for (var i in variants) {
      var flag = false;
      for (var k in variants[i]) {
        if (variants[i][k].name != undefined) {
          if (tempAttributes.includes(variants[i][k].value)) {
            flag = true;
            variantIndex = i;
          } else {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        console.log(variantIndex);
        for (var m in variants[variantIndex]) {
          if (variants[variantIndex][m].price != undefined) {
            state.discountedPrice = variants[variantIndex][m].price;
            state.availableQuantites = variants[variantIndex][m].quantity;
            if (state.availableQuantites <= 0) {
              //setState({ quantityFlag: false })
            } else {
              //setState({ quantityFlag: true })
            }
            if (state.products[0].discountCode != undefined) {
              var discountPrice =
                (state.products[0].discountPercentage * state.discountedPrice) /
                100;
              console.log(discountPrice, "discount");
              var newSP = state.discountedPrice - discountPrice;
              //setState({ newSalesPrice: newSP })
            } else {
              //setState({ newSalesPrice: state.discountedPrice })
            }
            //setState({selectedVarient : variants[variantIndex]})
            var offerObject = state.offerObject;
            if (offerObject.discountPercentage == 0) {
              var discount = state.discounts;
              var discountedPrice = state.newSalesPrice;
              var sales_price = await defaultDiscount(
                discount,
                discountedPrice
              );
              if (
                sales_price.discountPercentage != 0 ||
                sales_price.discountPercentage != "0"
              ) {
                state.offerFlag = false;
                //setState({offerFlag :false})
              }
              //
              //setState({ offerObject: sales_price })
            } else {
              offerObject.salesPrice = state.discountedPrice;
              var sales_price = await ApplyCustomOfferVariantWise(offerObject);
              //
              if (
                sales_price.discountPercentage != 0 ||
                sales_price.discountPercentage != "0"
              ) {
                state.offerFlag = false;
                //setState({offerFlag :false})
              }
              //setState({ offerObject: sales_price })
            }
          }
        }
        break;
      }
    }
  };

  const handleDiscount = async (data, index) => {
    console.log(data, "discounted data");
    //
    var offerObject = state.offerObject;
    var discount = data;
    var sales_price_object = await ApplyCustomOffer(discount, offerObject);
    if (
      sales_price_object.discountPercentage != 0 ||
      sales_price_object.discountPercentage != "0"
    ) {
      state.offerFlag = false;
      //setState({offerFlag :false})
    }
    //setState({ offerObject: sales_price_object })
    if (sales_price_object.message == "") {
      if (!toast.isActive(toastId)) {
        toastId = toast.success(`${toastTranslation?.success ? toastTranslation?.success : "Coupon Code applied Successfully "}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toastId = toast.error(sales_price_object.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const { activeIndex } = state;
  return (
    <div className="app" style={{ backgroundColor: "white" }}>
      <ToastContainer autoClose={20000} />
      <div>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </div>
      <div hidden={state.display_prod}>
        <br />
        <br />
        {/* <div className="row">
                        <ul className="navB" >
                            {state.category.length > 0 && state.category.map((cat, index) => (
                                <li>
                                    <a className="a-cat">{cat.name}  <i className="fa fa-angle-down rotate"></i></a>
                                    <div>
                                        <div className="nav-column" >
                                            {cat.sub_category != undefined && cat.sub_category.map((sub_cat, index) => (
                                                <ul>
                                                    <li onClick={e => searchByCategory(sub_cat.name)}> <h5>{sub_cat.name}</h5></li>
                                                    {sub_cat.child != undefined && sub_cat.child.map((ch, index) => (
                                                        <ul>
                                                            <li onClick={e => searchByCategory(sub_cat.name)}>{ch.name}</li>
                                                        </ul>
                                                    ))}
                                                    <ul>
                                                        <li></li>
                                                    </ul>
                                                </ul>
                                            ))}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div> */}

        <Navbar
          color="white"
          light
          expand="lg"
          style={{
            marginTop: "0.5rem",
            boxShadow: "0 0 3px 0 rgba(50, 50, 50, 0.6)",
            zIndex: "99",
            width: "100%",
          }}
        >
          <NavbarToggler onClick={toggleNav} />
          <Collapse isOpen={state.isOpen} navbar>
            {state.category.length > 0 &&
              state.category.map((cat, mainIndex) => (
                <Nav className="ml-4" navbar>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      {cat.name}
                    </DropdownToggle>
                    <DropdownMenu>
                      {cat.sub_category != undefined &&
                        cat.sub_category.map((sub_cat, subIndex) => (
                          <Dropdown>
                            <DropdownItem
                              onClick={(e) =>
                                searchByCategory(sub_cat.name, mainIndex)
                              }
                            >
                              {sub_cat.name}
                            </DropdownItem>
                            <ul>
                              {sub_cat.child != undefined &&
                                sub_cat.child.map((ch, childIndex) => (
                                  <DropdownItem
                                    onClick={(e) =>
                                      searchByCategory(
                                        ch.name,
                                        mainIndex,
                                        subIndex
                                      )
                                    }
                                  >
                                    {ch.name}
                                  </DropdownItem>
                                ))}
                            </ul>
                          </Dropdown>
                        ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              ))}
          </Collapse>
        </Navbar>
      </div>
      <br />
      {/* <div><br />
                    <Row>
                        <Col xs="12" sm="1"></Col>
                        <Col xs="12" sm="4">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <Button type="button" color="warning" onClick={search} ><i className="fa fa-search search-color"></i> Search</Button>
                                </InputGroupAddon>
                                <Input type="text" id="input1-group2" onChange={searchElement} className="search-color" name="input1-group2" placeholder="Search for products" />
                            </InputGroup>
                        </Col>
                    </Row>
                </div><br /> */}
      <div>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="12" sm="4">
                    {state.products.length > 0
                      ? state.products.map((product, i) => (
                          <b>{product.name}</b>
                        ))
                      : ""}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="card-body">
                <Row></Row>
                <br />
                <br />
                <Row>
                  <Col xs="12" sm="8">
                    <Row>
                      <Col xs="12" sm="4">
                        <div>
                          {state.products.length > 0
                            ? state.products[0].images.map((product, index) => (
                                <div>
                                  <img
                                    style={{
                                      maxHeight: "100px",
                                      maxWidth: "150px",
                                      height: "auto",
                                      width: "auto",
                                      marginTop: "5px",
                                    }}
                                    src={product}
                                    className="imageB"
                                    onMouseOver={(e) => {
                                      changePosition(product);
                                    }}
                                  />
                                  <br />
                                  <br />
                                </div>
                              ))
                            : ""}
                        </div>
                      </Col>

                      <Col xs="12" sm="4">
                        <Col xs="12" sm="12">
                          <img
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              height: "auto",
                              width: "auto",
                              paddingTop: "73px",
                            }}
                            src={state.singleImage}
                            className="pimage"
                          />
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" sm="4">
                    <Card>
                      <CardBody className="card-body">
                        <Row>
                          <Col xs="12" sm="12">
                            &nbsp;&nbsp;&nbsp;
                            <Label hidden={!state.quantityFlag}>
                              This item is in Stock
                            </Label>
                            <Label
                              hidden={state.quantityFlag}
                              className="detail-price"
                            >
                              This item is out of Stock
                            </Label>
                            <br />
                            {state.attributeArray.length > 0
                              ? state.attributeArray.map(
                                  (attributes, index) => (
                                    <div>
                                      <Col xs="12" sm="4">
                                        Select {attributes.name}
                                      </Col>
                                      <FormGroup>
                                        <InputGroup>
                                          &nbsp;&nbsp;&nbsp;
                                          <Input
                                            type="select"
                                            name="select"
                                            id="exampleSelect"
                                            onChange={loadVariantsDynamically.bind(
                                              this,
                                              attributes.name
                                            )}
                                          >
                                            {attributes.value.length > 0
                                              ? attributes.value.map(
                                                  (value, i) => (
                                                    <option value={value}>
                                                      {" "}
                                                      {value}{" "}
                                                    </option>
                                                  )
                                                )
                                              : "State not found"}
                                          </Input>
                                        </InputGroup>
                                      </FormGroup>
                                    </div>
                                  )
                                )
                              : ""}
                            <FormGroup>
                              {/* <div >
                                                                    &nbsp;&nbsp;&nbsp;<Label>Select Quantity</Label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <Button className="qty-button" onClick={decrementCounter}>-</Button>&nbsp;&nbsp;&nbsp;
                                                                     <Button className="qty-btn" id='counter' >{state.counter}</Button>&nbsp;&nbsp;&nbsp;
                                                                     <Button className="qty-button" onClick={incrementCounter}>+</Button>
                                                                </div> */}
                            </FormGroup>
                            <div>
                              &nbsp;&nbsp;&nbsp;
                              <Label>
                                Price:
                                <span
                                  hidden={!state.offerFlag}
                                  style={{ fontSize: "18px" }}
                                >
                                  {state.currencyIcon}
                                  {(
                                    state.offerObject.salesPrice *
                                    state.exchangeRate
                                  ).toFixed(2)}
                                </span>
                                <span
                                  hidden={state.offerFlag}
                                  style={{ fontSize: "18px" }}
                                >
                                  {" "}
                                  {state.currencyIcon}
                                  {(
                                    state.offerObject.finalPrice *
                                    state.exchangeRate
                                  ).toFixed(2)}
                                </span>
                                &nbsp;&nbsp;&nbsp;
                                <strike
                                  hidden={state.offerFlag}
                                  style={{ color: "grey", fontSize: "18px" }}
                                >
                                  {state.currencyIcon}
                                  {(
                                    state.offerObject.salesPrice *
                                    state.exchangeRate
                                  ).toFixed(2)}
                                </strike>
                                &nbsp;&nbsp;&nbsp;
                                <span
                                  hidden={state.offerFlag}
                                  style={{ color: "red", fontSize: "15px" }}
                                >
                                  {state.offerObject.discountPercentage} % OFF
                                </span>
                              </Label>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div>
                              <Row>
                                <Col xs="12" sm="12">
                                  {state.priceTier.length > 0
                                    ? state.priceTier.map((item, index) => (
                                        <div className="detail-price">
                                          Buy {item.quantity} or more to get{" "}
                                          {item.discount} % extra discount.{" "}
                                          <br />
                                        </div>
                                      ))
                                    : ""}
                                </Col>
                              </Row>
                              <br />
                              {/* <Row hidden={!state.goTOCartFlag}>
                                                                    <Col xs="12" sm="12" >
                                                                        <Button type="submit" size="md" color="warning" style={{ 'width': '106%' }} onClick={(e) => goTOCart()} block disabled={!state.quantityFlag}><b> Go To Cart </b></Button>
                                                                    </Col>
                                                                </Row> */}
                              {/* <Row hidden={state.goTOCartFlag}>
                                                                    <Col xs="12" sm="12" >
                                                                        <Button type="submit" size="md" color="warning" style={{ 'width': '106%' }} onClick={(e) => addTOCart()} block disabled={!state.quantityFlag}><b> Add To Cart </b></Button>
                                                                    </Col>
                                                                </Row><br /> */}
                            </div>
                            <div>
                              <Row>
                                <Col xs="12" sm="12">
                                  <Button
                                    type="submit"
                                    size="md"
                                    color="warning"
                                    style={{ width: "106%" }}
                                    block
                                    onClick={(evt) => shipping()}
                                    disabled={!state.quantityFlag}
                                  >
                                    <b> Buy Now </b>
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                            <br />
                            <FormGroup>
                              {/* <div>
                                                                    <Row>
                                                                        <Col xs="12" sm="12" >
                                                                            <Button type="submit" size="md" color="warning" style={{ 'width': '106%' }} block><b> Pay later at Store </b></Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div> */}
                            </FormGroup>
                            <FormGroup>
                              {/* <div >
                                                                        &nbsp;&nbsp;&nbsp;<Label>Delivery</Label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <input type="text" placeholder="enter pincode here!!!"></input>
                                                                        <button>check</button>

                                                                    </div> */}
                            </FormGroup>
                            <div>
                              &nbsp;&nbsp;&nbsp;
                              <Label className="offers">
                                <b>*OFFERS </b>
                              </Label>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <hr />
                            {state.validDiscounts.map((discount, d) => (
                              <div className="offers-manage">
                                {" "}
                                &nbsp;&nbsp;&nbsp;
                                <Col xs="1" sm="1" md="1">
                                  <Input
                                    type="radio"
                                    name="inline-aradios"
                                    className="radio-alignA"
                                    id={discount}
                                    onClick={(e) => handleDiscount(discount, d)}
                                  />
                                </Col>
                                &nbsp;&nbsp;&nbsp;
                                <Label>{discount.discountCode}</Label>
                                &nbsp;&nbsp;
                                <Label>
                                  {discount.discountPercentage + "% OFF"}
                                </Label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        {" "}
        <Row>
          <Col xs="12" md="12" className="mb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: state.activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Product Details
                </NavLink>
              </NavItem>
              {/* <NavItem>
                                <NavLink
                                    className={classnames({ active: state.activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    Rating and Reviews
                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: state.activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    Questions and Answers
                </NavLink>
                            </NavItem> */}
              <NavItem>
                <NavLink
                  className={classnames({ active: state.activeTab === "3" })}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  Seller Details
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    {state.products.length > 0
                      ? state.products.map((product, index) => (
                          <div className="ellipsis">
                            {" "}
                            {ReactHtmlParser(product.specification)}
                          </div>
                        ))
                      : ""}
                    <br />
                  </Col>
                </Row>
              </TabPane>
              {/* <TabPane tabId="2">
                                Rating and Reviews
                            </TabPane>
                            <TabPane tabId="3">
                                Questions and Answers
                            <Row>
                                    <Col>
                                        <Card>
                                            <CardBody className="card-body">
                                                <Row>
                                                </Row><br />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane> */}
              <TabPane tabId="4">
                {state.sellerDetail != undefined ? (
                  <div>
                    <Row>
                      <Col className="col-sm-10">
                        <b>
                          <i>{state.sellerDetail.displayName}</i>
                        </b>
                      </Col>
                      <Col className="col-sm-2">
                        <Button
                          type="submit"
                          size="md"
                          color="warning"
                          onClick={(evt) => goTOStore()}
                        >
                          <b>VIEW STORE</b>
                        </Button>
                      </Col>
                    </Row>

                    {/* <Row>
                                    <Col className="col-sm-1">
                                        <h6>Address 1 :</h6>
                                    </Col>
                                    <Col className="col-sm-11">
                                        {state.sellerDetail.address1}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-sm-1">
                                        <h6>Address 2 :</h6>
                                    </Col>
                                    <Col className="col-sm-11">
                                        {state.sellerDetail.address2}&nbsp;{state.sellerDetail.city}&nbsp;
                                        {state.sellerDetail.state}&nbsp;{state.sellerDetail.pinCode}
                                    </Col>
                                </Row>
 */}
                    <Row>
                      <Col className="col-sm-6">
                        {state.sellerDetail.description}
                      </Col>
                      <Col className="col-sm-6"></Col>
                    </Row>
                  </div>
                ) : (
                  "Seller details unavailable"
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>

      <DefaultFooter />
    </div>
  );
};

export default DigitalProductDetail;
