import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SetState,
  paymentConfig,
  updatePaymentConfig,
} from "../../redux/reducers/admin";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Env from "../../environment/environment";

const PaymentConfig = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { paymentConfiguration, loading } = useSelector((state) => state.admin);
  const [pageContent, setPageContent] = useState({
    advancedSellerFixCharge: 0,
    advancedSellerPercentageCharge: 0,
    yinncoinPurchaseFixCharge: 0,
    yinncoinPurchasePercentageCharge: 0,
    freemiumSellerFixCharge: 0,
    freemiumSellerPercentageCharge: 0,
    checkoutPaymentFixCharge: 0,
    checkoutPaymentPercentageCharge: 0,
    premiumSellerFixCharge: 0,
    premiumSellerPercentageCharge: 0,
    coinMaintenanceCharge: 0,
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  useEffect(() => {
    dispatch(paymentConfig());
  }, []);
  useEffect(() => {
    setPageContent({ ...paymentConfiguration });
  }, [paymentConfiguration]);
  const submitValues = () => {
    const data = { ...pageContent };
    dispatch(updatePaymentConfig(data));
    dispatch(paymentConfig());
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Card className="mt-5 mx-2">
        <CardHeader>
          <Row>
            <Col xs="5" sm="5" md="6" className="p-0">
              <strong>Payment Config</strong>
            </Col>
          </Row>
        </CardHeader>
        {loading ? (
          <div style={{ margin: "220px", textAlign: "center" }}>
            <i
              hidden={!loading}
              className="mx-auto fas fa-spinner fa-pulse fa-2x"
              style={{ color: "green" }}
            ></i>
          </div>
        ) : (
          <CardBody>
            <Row>
              <Col xs="12" sm="4">
                <FormGroup>
                  <Label> Checkout fixed fee (in dollars)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="checkoutPaymentFixCharge"
                        value={pageContent.checkoutPaymentFixCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label>Checkout variable fee (in percentage)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="checkoutPaymentPercentageCharge"
                        value={pageContent.checkoutPaymentPercentageCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Yinn Coin purchase fixed fee (in dollars)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="yinncoinPurchaseFixCharge"
                        value={pageContent.yinncoinPurchaseFixCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label>
                    {" "}
                    Yinn Coin purchase variable fee (in percentage)
                  </Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="yinncoinPurchasePercentageCharge"
                        value={pageContent.yinncoinPurchasePercentageCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Freemium seller fixed fee (in dollars)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="freemiumSellerFixCharge"
                        value={pageContent.freemiumSellerFixCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Freemium seller variable fee (as a percentage)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="freemiumSellerPercentageCharge"
                        value={pageContent.freemiumSellerPercentageCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Solo seller fixed fee (in dollars)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="advancedSellerFixCharge"
                        value={pageContent.advancedSellerFixCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Solo seller variable fee (in percentage)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="advancedSellerPercentageCharge"
                        value={pageContent.advancedSellerPercentageCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Partner seller fixed fee (in dollars)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="premiumSellerFixCharge"
                        value={pageContent.premiumSellerFixCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label>
                    {" "}
                    Partner seller variable fixed fee (in percentage)
                  </Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="premiumSellerPercentageCharge"
                        value={pageContent.premiumSellerPercentageCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Coin Maintenance Fee (per coin)</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Attribute Name"
                        name="coinMaintenanceCharge"
                        value={pageContent.coinMaintenanceCharge}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ display: "flex", justifyContent: "right" }}>
              <Button
                type="button"
                size="md"
                color="warning"
                onClick={() => submitValues()}
              >
                Update
              </Button>
            </Row>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default PaymentConfig;
