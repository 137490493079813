import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config/aws";
import {
  createProduct,
  getSingleProduct,
  updateProduct,
  getResidencyCategories,
} from "../../redux/reducers/seller";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Row,
  Col,
  Button,
  InputGroupText,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPresigedUrl, uploadToS3ViaSignedUrl } from "../../helpers/s3Utils";

const AddResidency = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const residencySwapTranslation =
    translationState?.[0]?.translations?.residencySwap?.[userLanguage];
  const addProductTranslation =
    translationState?.[0]?.translations?.addProduct?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const addAdvertAndAnnouncementTranslation =
    translationState?.[0]?.translations?.addAdvertAndAnnouncement?.[
      userLanguage
    ];

  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  const [formError, setFormError] = useState(false);
  const [productDescriptionData, setProductDescriptionData] = useState("");
  const { allSubCategories, singleProduct, loading } = useSelector(
    (state) => state.seller
  );
  const [availabilityOptions, setAvailabilityOptions] = useState([
    { value: 1000, label: "Available" },
    { value: 0, label: "Unavailable" },
  ]);
  const [pageContent, setPageContent] = useState({
    name: "",
    category: "",
    images: [],
    stockQuantity: 1000,
    startDate: null,
    endDate: null,
  });
  const [productDescription, setProductDescription] = useState({
    editorState: EditorState.createEmpty(),
  });
  useEffect(() => {
    dispatch(getResidencyCategories()).then((category) => {
      setPageContent({
        ...pageContent,
        category: [category?.payload?.data?.[0]?._id],
      });
    });
    if (productId !== "add") {
      dispatch(getSingleProduct(productId));
    }
  }, []);
  useEffect(() => {
    if (singleProduct._id) {
      const {
        name,
        specification,
        category,
        endDate,
        images,
        residencyData: { propertySize },
        regularPrice,
        startDate,
        stockQuantity,
        isResidency,
      } = singleProduct;
      setPageContent({
        name,
        category,
        endDate,
        images,
        propertySize,
        regularPrice,
        startDate,
        stockQuantity,
        isResidency,
        productType: "residency",
      });
      setProductDescription({
        editorState: EditorState.createWithContent(
          convertFromRaw({
            entityMap: {},
            blocks: specification?.blocks,
          })
        ),
      });
    }
  }, [singleProduct]);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const changeProductDescription = (editorState) => {
    setProductDescription({ editorState });
  };
  const uploadImage = async (blobs) => {
    for (let i = 0; i < blobs.length; i++) {
      let newImages = [...pageContent.images];
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };

      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const imageUrl = `${Config.digitalOceanSpaces}/` + blob.name;
        newImages = [...newImages, imageUrl];
        setPageContent({
          ...pageContent,
          images: newImages,
        });
      } catch (error) {
        toast.error("Error in uploading image! Please try again");
      }
    }
  };
  const uploadProductImages = (event) => {
    uploadImage(event.target.files);
  };
  const imageClick = (image) => {
    let newImages = _.cloneDeep(pageContent.images);
    newImages = newImages.filter((item) => item !== image);
    setPageContent({
      ...pageContent,
      images: newImages,
    });
  };

  const save = () => {
    const specification = convertToRaw(
      productDescription.editorState.getCurrentContent()
    );
    setProductDescriptionData(specification?.blocks[0]?.text);
    const {
      category,
      endDate,
      images,
      name,
      propertySize,
      regularPrice,
      startDate,
      stockQuantity,
    } = pageContent;
    if (
      !category ||
      !endDate ||
      !images.length ||
      !name ||
      !propertySize ||
      !regularPrice ||
      !startDate ||
      !stockQuantity
    ) {
      setFormError(true);
      toast.error("Kindly fill the required fields");
      return;
    }
    setFormError(false);
    const data = {
      name,
      specification,
      category,
      endDate,
      images,
      residencyData: {
        propertySize,
      },
      dynamicPrice: {
        basePrice: regularPrice,
        currentPrice: regularPrice,
      },
      regularPrice,
      startDate,
      stockQuantity,
      isResidency: true,
      productType: "physical",
    };
    if (productId !== "add") {
      dispatch(updateProduct({ ...data, id: productId })).then(() => {
        history.push("/seller/residancySwap/list");
      });
    } else {
      dispatch(createProduct(data)).then(() => {
        history.push("/seller/residancySwap/list");
      });
    }
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <CardHeader>
              <b>
                {residencySwapTranslation?.General
                  ? residencySwapTranslation?.General
                  : "General"}
              </b>
            </CardHeader>
            <CardBody className="card-body">
              <Row>
                <Col xs="12" sm="12">
                  <Card>
                    <CardHeader>
                      <b>
                        {digitalProductTranslation?.title
                          ? digitalProductTranslation?.title
                          : "Title"}
                      </b>
                    </CardHeader>
                    <CardBody className="card-body">
                      <FormGroup>
                        <div className="controls">
                          <InputGroup className="input-prepend">
                            <InputGroupAddon></InputGroupAddon>
                            <Input
                              className={`input-prepend ${
                                !pageContent?.name && formError && "form-error"
                              }`}
                              onChange={handleInputChange}
                              name="name"
                              size="16"
                              type="text"
                              placeholder={
                                residencySwapTranslation?.productName
                                  ? residencySwapTranslation?.productName
                                  : "Product Name"
                              }
                              value={pageContent.name}
                              required
                            />
                          </InputGroup>
                        </div>

                        <InputGroup style={{ paddingTop: 10 }}>
                          <InputGroupText className="py-0">
                            {residencySwapTranslation?.selectType
                              ? residencySwapTranslation?.selectType
                              : "Select the property type:"}
                          </InputGroupText>
                          <Input
                            className={`input-prepend ${
                              !pageContent?.category &&
                              formError &&
                              "form-error"
                            }`}
                            type="select"
                            name="category"
                            onChange={handleInputChange}
                            value={pageContent.category}
                          >
                            {allSubCategories.map((ct) => (
                              <option value={ct._id}> {ct.name} </option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <Card>
                    <CardHeader>
                      <b>
                        {digitalProductTranslation?.description
                          ? digitalProductTranslation?.description
                          : "Description"}
                      </b>
                    </CardHeader>
                    <CardBody className="card-body">
                      <FormGroup>
                        <div className="controls">
                          <Editor
                            toolbarClassName="toolbar-class"
                            placeholder="Product Description"
                            wrapperClassName="demo-wrapper"
                            editorClassName="editer-content"
                            editorState={productDescription.editorState}
                            onEditorStateChange={changeProductDescription}
                            editorStyle={{
                              border: `${
                                formError && !productDescriptionData
                                  ? "2px solid red"
                                  : ""
                              }`,
                            }}
                          />
                        </div>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <Card>
                    <CardHeader>
                      <b>
                        {residencySwapTranslation?.image
                          ? residencySwapTranslation?.image
                          : "Images"}
                      </b>
                    </CardHeader>
                    <CardBody className="card-body">
                      <Row action="" method="post">
                        {pageContent.images.length > 0
                          ? pageContent.images.map((item, index) => (
                              <Col xs="12" lg="3">
                                <div class="container">
                                  <img
                                    style={{ width: "100%" }}
                                    src={item}
                                    alt="Avatar"
                                    class="image"
                                  />
                                  <div
                                    onClick={() => imageClick(item)}
                                    class=""
                                  >
                                    <div class="close"></div>
                                  </div>
                                </div>
                              </Col>
                            ))
                          : ""}
                        <Col xs="12" lg="12">
                          <div className="file-uploader">
                            <input
                              type="file"
                              multiple={true}
                              onChange={uploadProductImages}
                              ref={fileInput}
                              hidden
                            />
                            <button
                              className={` btn btn-primary input-prepend ${
                                pageContent?.images?.length == 0 &&
                                formError &&
                                "form-error"
                              }`}
                              onClick={(e) =>
                                fileInput.current && fileInput.current.click()
                              }
                            >
                              {addProductTranslation?.image
                                ? addProductTranslation?.image
                                : "Upload Images"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xs="12" sm="12">
                  <Card>
                    <CardHeader>
                      <b>
                        {residencySwapTranslation?.availability
                          ? residencySwapTranslation?.availability
                          : "Availability"}
                      </b>
                    </CardHeader>
                    <CardBody className="card-body">
                      <Form action="" method="post" className="form-horizontal">
                        <Row>
                          <Col xs="6" sm="6">
                            <FormGroup>
                              <Label>
                                {addAdvertAndAnnouncementTranslation?.start
                                  ? addAdvertAndAnnouncementTranslation?.start
                                  : "From"}
                              </Label>
                              <div className="controls">
                                <InputGroup className="input-prepend">
                                  <Input
                                    className={`input-prepend ${
                                      !pageContent?.startDate &&
                                      formError &&
                                      "form-error"
                                    }`}
                                    onChange={handleInputChange}
                                    value={moment(pageContent.startDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                    name="startDate"
                                    min={new Date().toISOString().split("T")[0]}
                                    type="date"
                                  />
                                </InputGroup>
                              </div>
                            </FormGroup>
                          </Col>

                          <Col xs="6" sm="6">
                            <FormGroup>
                              <Label>
                                {addAdvertAndAnnouncementTranslation?.end
                                  ? addAdvertAndAnnouncementTranslation?.end
                                  : "To"}
                              </Label>
                              <div className="controls">
                                <InputGroup className="input-prepend">
                                  <Input
                                    className={`input-prepend ${
                                      !pageContent?.endDate &&
                                      formError &&
                                      "form-error"
                                    }`}
                                    onChange={handleInputChange}
                                    value={moment(pageContent.endDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                    name="endDate"
                                    min={new Date().toISOString().split("T")[0]}
                                    type="date"
                                  />
                                </InputGroup>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <InputGroup style={{ paddingTop: 10 }}>
                            <InputGroupText className="py-0">
                              {residencySwapTranslation?.availability
                                ? residencySwapTranslation?.availability
                                : "Availability:"}
                            </InputGroupText>
                            <Input
                              className={`input-prepend ${
                                !pageContent?.stockQuantity &&
                                formError &&
                                "form-error"
                              }`}
                              type="select"
                              name="stockQuantity"
                              value={pageContent.stockQuantity}
                              onChange={handleInputChange}
                            >
                              {availabilityOptions.map((option, i) => (
                                <option value={option.value}>
                                  {option.label === "Available"
                                    ? residencySwapTranslation?.availability
                                      ? residencySwapTranslation?.availability
                                      : "Available"
                                    : residencySwapTranslation?.unavailable
                                    ? residencySwapTranslation?.unavailable
                                    : "Unavailable"}
                                </option>
                              ))}
                            </Input>
                          </InputGroup>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xs="12" sm="12">
                  <Card>
                    <CardHeader>
                      <b>
                        {residencySwapTranslation?.detail
                          ? residencySwapTranslation?.detail
                          : "Details"}
                      </b>
                    </CardHeader>
                    <CardBody className="card-body">
                      <Form action="" method="post" className="form-horizontal">
                        <Row>
                          <Col xs="12" sm="4">
                            <FormGroup>
                              <Label>
                                {residencySwapTranslation?.PropertySize
                                  ? residencySwapTranslation?.PropertySize
                                  : "Property size"}
                              </Label>
                              <div className="controls">
                                <InputGroup className="input-prepend">
                                  <Input
                                    className={`input-prepend ${
                                      !pageContent?.propertySize &&
                                      formError &&
                                      "form-error"
                                    }`}
                                    placeholder={
                                      residencySwapTranslation?.PropertySize
                                        ? residencySwapTranslation?.PropertySize +
                                          residencySwapTranslation?.squarefeet
                                        : "Property size(sqft)"
                                    }
                                    name="propertySize"
                                    onChange={handleInputChange}
                                    id="propertySize"
                                    size="16"
                                    type="number"
                                    min={1}
                                    value={pageContent.propertySize}
                                    required
                                  />
                                </InputGroup>
                              </div>
                            </FormGroup>
                          </Col>

                          <Col xs="12" sm="4">
                            <FormGroup>
                              <Label>
                                {residencySwapTranslation?.renter
                                  ? residencySwapTranslation?.renter
                                  : "Rental (Per day):"}
                              </Label>
                              <div className="controls">
                                <InputGroup className="input-prepend">
                                  <Input
                                    className={`input-prepend ${
                                      !pageContent?.regularPrice &&
                                      formError &&
                                      "form-error"
                                    }`}
                                    placeholder={
                                      residencySwapTranslation?.rentalIN
                                        ? residencySwapTranslation?.rentalIN
                                        : "Daily rental is USD"
                                    }
                                    name="regularPrice"
                                    onChange={handleInputChange}
                                    id="appendedPrependedInput"
                                    size="16"
                                    type="number"
                                    min={1}
                                    value={pageContent.regularPrice}
                                    required
                                  />
                                </InputGroup>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="p-2">
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col xs="6" sm="2" hidden={loading}>
                  <Button
                    color="primary"
                    type="submit"
                    className="btn btn-sm"
                    onClick={save}
                    block
                  >
                    {digitalProductTranslation?.saveButton
                      ? digitalProductTranslation?.saveButton
                      : "Save"}
                  </Button>
                </Col>
                <Col xs="6" sm="2" hidden={!loading}>
                  <i
                    className="fas fa-spinner fa-pulse fa-2x"
                    style={{ color: "green", float: "right" }}
                  ></i>
                </Col>
                <Col xs="6" sm="2">
                  <Button
                    color="danger"
                    className="btn btn-sm"
                    onClick={(e) => history.push("/seller/residancySwap/list")}
                    block
                  >
                    {digitalProductTranslation?.cancel
                      ? digitalProductTranslation?.cancel
                      : "Cancel"}
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddResidency;
