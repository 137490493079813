import React from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import "./summary.css";

const Summary = (props) => {
  const {
    paymentData,
    additionalCharge,
    paymentProviderCharges,
    totalPrice,
    yinnPercentage,
  } = props;
  const { cart } = useSelector((state) => state.auth);
  const { userCurrency } = useSelector((state) => state.user);
  const { usdRate, paymentConfiguration, userLanguage } = useSelector((state) => state.auth);

  const { translationState } = useSelector((state) => state.user);
  const summaryTranslation = translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const myCartTranslation = translationState?.[0]?.translations?.myCart?.[userLanguage];

  return (
    <div className="summary-container">
      <Row>
        <Col xs="0" sm="1"></Col>
        <Col xs="12" sm="10">
          <div className="summary-title-text">
          {summaryTranslation?.summary ? summaryTranslation?.summary : "Summary"}</div>
          <div className="summary-description-text">
          {summaryTranslation?.paraLine ? summaryTranslation?.paraLine : "Additional charges will be added based on your payment method."}
            
          </div>
          <div className="summary-entry-row">
            <div className="summary-entry-text">{summaryTranslation?.basePrice ? summaryTranslation?.basePrice : "Base Price"}</div>
            <div className="summary-entry-value">
              {(
                cart.reduce((a, b) => a + b.product?.unitPrice * b.qty, 0) *
                usdRate
              ).toFixed(2)}{" "}
              {userCurrency}
            </div>
          </div>
          <div className="summary-entry-row">
            <div className="summary-entry-text">{summaryTranslation?.cost ? summaryTranslation?.cost : "Shipping Cost"}</div>
            <div className="summary-entry-value">
              {(
                cart.reduce(
                  (a, b) => a + b.product?.shippingCharges * b.qty,
                  0
                ) * usdRate
              ).toFixed(2)}{" "}
              {userCurrency}
            </div>
          </div>
          {additionalCharge && (
            <div className="summary-entry-row">
              <div className="summary-entry-text">Yinn fees</div>
              <div className="summary-entry-value">
                <div className="summary-entry-value">{additionalCharge}</div>
              </div>
            </div>
          )}
          <div className="summary-entry-row">
            <div className="summary-entry-text">{summaryTranslation?.paymentFee ? summaryTranslation?.paymentFee : "Payment processing fee"}</div>
            <div className="summary-entry-value">{yinnPercentage}</div>
          </div>

          <div className="summary-entry-row">
            <div className="summary-entry-text">{summaryTranslation?.checkoutFee ? summaryTranslation?.checkoutFee : "Checkout payment fee"}</div>
            <div className="summary-entry-value">
              {paymentConfiguration.checkoutPaymentFixCharge}
            </div>
          </div>

          <div className="summary-separator"></div>

          <div className="summary-entry-row">
            <div className="summary-total-text">{myCartTranslation?.total ? myCartTranslation?.total : "Total"}</div>
            <div className="summary-total-text">{totalPrice}</div>
          </div>
        </Col>
        <Col xs="0" sm="1"></Col>
      </Row>
    </div>
  );
};

export default Summary;
