import React, { useEffect, useState } from "react";
import "./TranslationField.css";
import button from "@material-ui/core/button";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAllWebContent } from "../../../redux/reducers/user";
import { toast, ToastContainer } from "react-toastify";

import { Link, useHistory } from "react-router-dom";

function Styledbuttons() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  return (
    <div>
      <ToastContainer autoClose={20000} />
      <h1
        className="d-flex justify-content-center mt-5 mb-4"
        style={{ fontSize: "28px", fontWeight: "bold" }}
      >
        Select and Add your content
      </h1>
      <div className="d-flex flex-wrap justify-content-center">
        <Link to="/admin/translationHomeField">
          <button className="content-button">Home Page</button>
        </Link>
        <Link to="/admin/HomeFeatureField">
          <button className="content-button">Home Features</button>
        </Link>

        <button
          className="content-button"
          onClick={() => history.push("/admin/contactUsField")}
        >
          Contact Us
        </button>

        <button
          className="content-button"
          onClick={() => history.push("/admin/ShopFeild")}
        >
          Shop
        </button>

        <Link to="/admin/VerifyField">
          <button className="content-button">Verify</button>
        </Link>
        <Link to="/admin/translationFooterField">
          <button className="content-button">Footer</button>
        </Link>
      </div>
      <div>
        <header />
      </div>
    </div>
  );
}

export default Styledbuttons;

// import React from "react";

// function Styledbuttons() {
//   return <div>TranslationField</div>;
// }

// export default Styledbuttons;
