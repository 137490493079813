import React, { useEffect, useState } from "react";
import "./TranslationField.css";
import { useDispatch, useSelector } from "react-redux";
import { TranslationContent } from "../../../redux/reducers/admin";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { getAllWebContent } from "../../../redux/reducers/user";
import { toast, ToastContainer } from "react-toastify";

const VerifyFeild = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  const { allWebContent } = useSelector((state) => state?.user);

  const verifyData = allWebContent?.[0]?.verify;

  const [diable, setDiable] = useState(true);

  const [formData, setFormData] = useState({
    page: "verify",
    isChange: false,
    fisrtLine: verifyData?.fisrtLine || "",
    secondLine: verifyData?.secondLine || "",
    // buttonOne: verifyData?.buttonOne || "",
    // buttonReport: verifyData?.buttonReport || "",
    // button: verifyData?.button || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiable(false);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    setDiable(true);
  };

  useEffect(() => {
    setFormData({ ...formData, ...verifyData, isChange: false });
  }, [verifyData]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <center>
        <h1 className="mt-3">Verify Page</h1>
      </center>
      <form onSubmit={handleSubmit} className="mx-5">
        <div className="form-group">
          <label> Content:</label>
          <input
            type="text"
            name="fisrtLine"
            value={formData.fisrtLine}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>secondLine :</label>
          <input
            type="text"
            name="secondLine"
            value={formData.secondLine}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* <div className="form-group">
          <label>buttonOne:</label>
          <input
            type="text"
            name="buttonOne"
            value={formData.buttonOne}
            onChange={handleInputChange}
            />
        </div>

        <div className="form-group">
          <label>buttonReport:</label>
          <input
            type="text"
            name="buttonReport"
            value={formData.buttonReport}
            onChange={handleInputChange}
            />
        </div>

        <div className="form-group">
          <label>button:</label>
          <input
            type="text"
            name="button"
            value={formData.button}
            onChange={handleInputChange}
            />
        </div> */}
        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default VerifyFeild;
