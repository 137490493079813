import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useSelector , useDispatch} from "react-redux";

const Summary = (props) => {
  const dispatch = useDispatch();
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const shippingCartTranslation = translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const walletTranslation = translationState?.[0]?.translations?.wallet?.[userLanguage];
  const orderTranslation = translationState?.[0]?.translations?.order?.[userLanguage];
  const paymentInfoTranslation = translationState?.[0]?.translations?.paymentInfo?.[userLanguage];
  
  const {
    paymentConfiguration,
    salesPrice,
    paymentMethod,
    totalPrice,
    additionalCharge,
    bordered,
    yinnPercentage,
  } = props;
  return (
    <div
      className="summary-container"
      style={bordered && { border: "solid 1px #707070" }}
    >
      <Row>
        <Col xs="0" sm="1"></Col>
        <Col xs="12" sm="10">
          <div className="summary-title-text">
            {shippingCartTranslation?.summary ? shippingCartTranslation?.summary :"Summary"}</div>
          <div className="summary-description-text">
          {shippingCartTranslation?.paraLine ? shippingCartTranslation?.paraLine :
           "Additional charges will be added based on your payment method."}
          </div>
          <div className="summary-entry-row">
            <div className="summary-entry-text">{walletTranslation?.coinPrice ? walletTranslation?.coinPrice :"Coin Price"}</div>
            <div className="summary-entry-value">
              {salesPrice ? salesPrice : `${walletTranslation?.inputCoin ? "- "+ walletTranslation?.inputCoin+ " -" : "-Input Coins-"}`}
            </div>
          </div>
          <div className="summary-entry-row">
            <div className="summary-entry-text">{walletTranslation?.maintanance ? walletTranslation?.maintanance : "Coin Maintanance Charge"}</div>
            <div className="summary-entry-value">
              {paymentConfiguration
                ? paymentConfiguration.coinMaintenanceCharge
                : `${walletTranslation?.inputCoin ? "- "+ walletTranslation?.inputCoin+ " -" : "-Input Coins-"}`}
            </div>
          </div>
          <div className="summary-entry-row">
            <div className="summary-entry-text">{shippingCartTranslation?.paymentFee ? shippingCartTranslation?.paymentFee :"Payment processing fees"}</div>
            <div className="summary-entry-value">
              {salesPrice ? yinnPercentage : `${walletTranslation?.inputCoin ? "- "+ walletTranslation?.inputCoin + " -" : "-Input Coins-"}`}
            </div>
          </div>

          <div className="summary-separator"></div>

          <div className="summary-entry-row"> 
            <div className="summary-total-text">{orderTranslation?.total ? orderTranslation?.total : "Total"}</div>
            <div className="summary-total-text">
              {totalPrice ? totalPrice : "-"}
            </div>
          </div>
        </Col>
        <Col xs="0" sm="1"></Col>
      </Row>
    </div>
  );
};
export default Summary;
