import React from "react";
import "./Content.css";

import Safe from "../../../assets/img/home/safe.png";
import Innovate from "../../../assets/img/home/innovative.png";
import Friendly from "../../../assets/img/home/user-friendly.png";
import Thumbs from "../../../assets/img/home/thumbs-up.png";
import Bulb from "../../../assets/img/home/bulb.png";
import Trust from "../../../assets/img/trusted-icon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Content = () => {
   const {translationState , allWebContent} = useSelector((state) => state.user)
   const {userLanguage} = useSelector((state) => state.auth);
   const webContent = allWebContent?.[0]?.home
   const orignalTranslation = translationState?.[0]?.translations?.home?.[userLanguage]

   const { t } = useTranslation();
   return (
      <section className="home-content pb-sm-3 pb-md-5">
         <div className="row justify-content-center custom-row section-container">
            <div className="col-sm-6 col-xl-6 section-text mb-4 mb-sm-0">
               <div className="secion-inner">
                  <div className="header-font text-center text-sm-left mb-3 mb-sm-4">{orignalTranslation?.categoryH1 ? orignalTranslation?.categoryH1 : webContent?.categoryH1}</div>
                  <div className="p-large text-center text-sm-left">
                     <span className="font-weight-bold">YINN’</span>s {orignalTranslation?.categoryP1 ? orignalTranslation?.categoryP1 : webContent?.categoryP1}
                  </div>
               </div>
            </div>
            <div className="col-sm-6 col-xl-4 position-relative">
               <svg className="svg1">
                  <line x1="100%" y1={0} x2={0} y2="100%" style={{ stroke: "#979797", strokeWidth: 1 }} />
               </svg>
               <div className="circle circle-left">
                  <div className="position-relative">
                     <img src={Bulb} alt=""/>
                  </div>
               </div>
               <img src={Innovate} className=" img-fluid d-block mx-auto mx-xl-0" />
            </div>
         </div>

         <div className="row justify-content-center custom-second-row section-container">
            <div className="col-sm-6 col-xl-4 position-relative order-2 order-sm-1">
               <svg className="svg2">
                  <line x1={0} y1={0} x2="100%" y2="100%" style={{ stroke: "#979797", strokeWidth: 1 }} />
               </svg>
               <div className="circle circle-right">
               <div className="position-relative">
                     <img src={Thumbs} alt=""/>
                  </div>
               </div>
               <img src={Friendly} className=" img-fluid d-block mx-auto ml-xl-auto mr-xl-0" />
            </div>

            <div className="col-sm-6 col-xl-4 offset-xl-2 section-text order-1 order-sm-2 mb-4 mb-sm-0">
               <div className="second-secion-inner">
                  <div className="header-font text-center text-sm-left mb-3 mb-sm-4 ">{orignalTranslation?.categoryH2 ? orignalTranslation?.categoryH2 : webContent?.categoryH2}</div>
                  <div className="p-large text-center text-sm-left">
                     <span className="font-weight-bold">YINN</span> {orignalTranslation?.categoryP2 ? orignalTranslation?.categoryP2 : webContent?.categoryP2}
                  </div>
               </div>
            </div>
         </div>

         <div className="row justify-content-center custom-row section-container">
            <div className="col-sm-6 col-xl-6 section-text mb-4 mb-sm-0">
               <div className="secion-inner">
                  <div className="header-font text-center text-sm-left mb-3 mb-sm-4">{orignalTranslation?.categoryH3 ? orignalTranslation?.categoryH3 : webContent?.categoryH3 }</div>
                  <div className="p-large text-center text-sm-left">
                  {orignalTranslation?.categoryP3 ? orignalTranslation?.categoryP3 : webContent?.categoryP3}
                  </div>
               </div>
            </div>
            <div className="col-sm-6 col-xl-4 position-relative">
               <div className="circle circle-left">
               <div className="position-relative">
                     <img src={Trust} alt="" style={{width:'63%'}}/>
                  </div>
               </div>
               <img src={Safe} className=" img-fluid d-block mx-auto mx-xl-0" />
            </div>
         </div>
      </section>
   );
};

export default Content;
