import axios from "axios";
import { io } from "socket.io-client";
const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://api.yinn.ca";
axios.defaults.baseURL = `${API_URL}/yinn`;
export const socket = io(API_URL);
socket.io.on("error", (error) => {
  console.log("🚀 socket error", error);
});
socket.io.on("reconnect", (attempt) => {
  console.log("🚀 socket is reconnecting", attempt);
});
export default axios;
