import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDMCA, updateSingleDMCA } from "../../../redux/reducers/admin";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TakedownDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlArray = history.location.pathname.split("/");
  const dmcaId = urlArray[urlArray.length - 1];
  const [dmcaStatus, setDmcaStatus] = useState("");
  const { singleDmca } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getSingleDMCA(dmcaId));
  }, []);

  useEffect(() => {
    setDmcaStatus(singleDmca.status);
  }, [singleDmca]);

  const handleStatus = (status) => {
    const data = {
      dmcaId,
      status,
    };
    setDmcaStatus(status);
    dispatch(updateSingleDMCA(data));
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />

      <Row>
        <Col xs="12" sm="12" md="12" lg="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6" sm="7" md="10" lg="10">
                  <strong>Copyright Infringement details</strong>
                </Col>
                <Col xs="6" sm="5" md="2" lg="2">
                  <Input
                    type="select"
                    onChange={(e) => handleStatus(e.target.value)}
                    value={dmcaStatus}
                    disabled={dmcaStatus == "Resolved"}
                  >
                    {["Open", "Resolved"].map((status, i) => (
                      <option value={status}> {status} </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="name">
                      <strong>User Name : </strong>
                    </Label>
                    {singleDmca.name}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">
                      <strong>Email : </strong>
                    </Label>
                    {singleDmca.email}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">
                      <strong>Url : </strong>
                    </Label>
                    {singleDmca.url}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">
                      <strong>Complaint about: </strong>
                    </Label>
                    {singleDmca.copyrights}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">
                      <strong>Address : </strong>
                    </Label>
                    {singleDmca.address}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TakedownDetails;
