import React, { useState, useRef, useEffect } from "react";
import { useHistory, onPopstate } from "react-router-dom";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Card,
  InputGroupText,
  CardBody,
  CardFooter,
  CardHeader,
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./register.css";
import Country from "../../../assets/country/countries";
import Env from "../../../environment/environment";
import SkyLight from "react-skylight";
import cookie from "react-cookies";
import { setState, SignUp, ClearState } from "../../../redux/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { AppHeader } from "@coreui/react";
import DefaultHeader from "../../Header/DefaultHeader";
// const queryString = require("query-string");

const Register = () => {
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const registerTranslation =
    translationState?.[0]?.translations?.register?.[userLanguage];
  const logInTranslation =
    translationState?.[0]?.translations?.logIn?.[userLanguage];
  const history = useHistory();
  const dispatch = useDispatch();
  const [signUpObject, setSignUpObject] = useState({
    firstname: "",
    lastname: "",
    country: "",
    email: "",
    password: "",
    confirmPassword: "",
    termcondition: false,
  });
  const [fNameError, setFNameError] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [lNameError, setLNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [TandCError, setTandCError] = useState(false);
  const [thirdPartyUser, setThirdPartyUser] = useState(false);
  const simpleDialog = useRef(null);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSignUpObject({
      ...signUpObject,
      [name]: value,
    });
  };

  // const componentDidMount = () => {
  //     let queryParams = queryString.parse(props.location.search)
  //     if (queryParams.thirdPartyUser) {
  //         data = {
  //             firstname: queryParams.firstName && queryParams.firstName,
  //             lastname: queryParams.lastName && queryParams.lastName,
  //             id: cookie.load("_id")
  //         }
  //         //setState({
  //             thirdPartyUser: true,

  //         })

  //     }
  // }

  const condition = () => {
    //simpleDialog.show()
  };
  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };
  const clearErrors = () => {
    setFNameError(false);
    setLNameError(false);
    setCountryError(false);
    setTandCError(false);
    setEmailError(false);
    setPasswordError(false);
    setPasswordMatchError(false);
  };
  const signUp = () => {
    clearErrors();
    if (signUpObject.firstname === "") {
      setFNameError(true);
    } else if (signUpObject.lastname === "") {
      setLNameError(true);
    } else if (
      signUpObject.email === "" ||
      !validateEmail(signUpObject.email)
    ) {
      setEmailError(true);
    } else if (signUpObject.password === "") {
      setPasswordError(true);
    } else if (signUpObject.password !== signUpObject.confirmPassword) {
      setPasswordMatchError(true);
    } else if (signUpObject.country === "") {
      setCountryError(true);
    } else if (termsAndConditions === false) {
      setTandCError(true);
    } else {
      dispatch(SignUp(signUpObject)).then(({ payload }) => {
        if (payload.success) {
          history.push("/auth/account-confirm");
        }
      });
    }
  };

  const onSuccess = (response) => {
    const token = response.headers.get("x-auth-token");
    response.json().then((user) => {
      if (token) {
        //setState({ isAuthenticated: true, user: user, token: token });
        localStorage.setItem("auth_token", token);
        cookie.save("user", "user", { path: "/" });
        cookie.save("_id", user._id, { path: "/" });
        setTimeout(function () {
          window.location = "#/home";
        }, 2500);
      }
    });
  };

  const onFailed = (error) => {
    alert(error);
  };

  const handleThirdPartyUserSellerSignup = () => {
    let data = {};
    if (!data.id) {
      console.log("user not logged in");
      return;
    }

    axios
      .post(Env.url + "/user/becomeseller/thirdparty/", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        let userData = response.data;

        if (userData.is_staff) {
          cookie.save("user", "seller", { path: "/" });
        } else {
          cookie.save("user", "user", { path: "/" });
        }
        cookie.save("_id", userData._id, { path: "/" });
        cookie.save("country", userData.country, { path: "/" });
        cookie.save("name", userData.firstname, { path: "/" });
        cookie.save("thirdParty", true, { path: "/" });

        // state.toster_condition = 1
        // if (!toast.isActive(toastId)) {
        //     toastId = toast.success("Registration Successful", { position: toast.POSITION.TOP_RIGHT });
        // }
        setTimeout(function () {
          window.location = "#/profile";
        }, 2500);
      })
      .catch((err) => {
        // if (state.toster_condition === 0) {
        //     state.toster_condition = 1
        //     if (!toast.isActive(toastId)) {
        //         toastId = toast.error("Email already exists!", { position: toast.POSITION.TOP_RIGHT });
        //     }
        // }
      });
  };

  const customHeader = {};
  customHeader["Test"] = "test-header";

  return (
    <div className="register-main-wrapper">
      <Row className="fixed-header">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </Row>
      <div className="app flex-row align-items-center register-background">
        <ToastContainer autoClose={20000} />
        <Container>
          <Row>
            <Col xs="0" sm="0" md="6" lg="6">
              <div className="hide-on-mobile the-door-is-here">
                {registerTranslation?.headingOne
                  ? registerTranslation?.headingOne
                  : "Here is The door"}
                {/* Here is The door, */}
              </div>
              <div className="hide-on-mobile and-the-key ">
                {registerTranslation?.headingTwo
                  ? registerTranslation?.headingTwo
                  : "the key is"}
                {/* the key is */}
              </div>
              <div className="hide-on-mobile in-your-hand">
                {registerTranslation?.headingTwo ? "" : "in your hand!"}
                {/* in your hand! */}
              </div>
              <div className="hide-on-mobile create-your-own-key">
                {registerTranslation?.headingThree
                  ? registerTranslation?.headingThree
                  : "Create your own key for the new journey"}
                {/* Create your own key for the new journey */}
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <Card style={{ width: "100%", padding: "0", height: 720 }}>
                <CardHeader
                  style={{
                    display: "flex",
                    backgroundColor: "#ffffff",
                    padding: "0",
                    borderBottom: "0",
                  }}
                >
                  <div
                    className="register-tab-text-register"
                    style={{
                      width: "50%",
                      height: "80px",
                      backgroundColor: "#ffffff",
                    }}
                    onClick={() => history.push("/auth/register")}
                  >
                    {logInTranslation?.registerButton
                      ? logInTranslation?.registerButton
                      : "Register"}
                    {/* Register */}
                  </div>
                  <div
                    onClick={() => history.push("/auth/login")}
                    className="signin-tab-text-register"
                    style={{
                      width: "50%",
                      height: "80px",
                      backgroundColor: "#000000",
                    }}
                  >
                    {logInTranslation?.signInButton
                      ? logInTranslation?.signInButton
                      : "Sign In"}
                    {/* Sign In */}
                  </div>
                </CardHeader>
                <CardBody style={{ height: "600px" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "30px",
                    }}
                  >
                    <div className="title-register">
                      {logInTranslation?.registerButton
                        ? logInTranslation?.registerButton
                        : "Register"}
                      {/* REGISTER */}
                    </div>
                    <div className="login-tagline">
                      {registerTranslation?.paraOne
                        ? registerTranslation?.paraOne
                        : "Create your Account , Buy the unlimited Products"}
                      {/* Create your Account , Buy the unlimited Products{" "} */}
                    </div>
                    <InputGroup className="mb-1" style={{ marginTop: "50px" }}>
                      <Input
                        className="login-input"
                        type="text"
                        maxlength="20"
                        onChange={handleInputChange}
                        placeholder={
                          registerTranslation?.firstName
                            ? registerTranslation?.firstName
                            : "First name"
                        }
                        name="firstname"
                        value={signUpObject.firstname}
                        autoFocus
                      />
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <div className="error-text" hidden={!fNameError}>
                        {registerTranslation?.firstName
                          ? registerTranslation?.firstName
                          : "Please enter a valid first name."}
                      </div>
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <Input
                        className="login-input"
                        type="text"
                        maxlength="20"
                        onChange={handleInputChange}
                        placeholder={
                          registerTranslation?.lastName
                            ? registerTranslation?.lastName
                            : "Last name"
                        }
                        name="lastname"
                        value={signUpObject.lastname}
                      />
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <div className="error-text" hidden={!lNameError}>
                        {registerTranslation?.lastName
                          ? registerTranslation?.lastName
                          : "Please enter a valid last name."}
                      </div>
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <Input
                        className="login-input"
                        type="text"
                        onChange={handleInputChange}
                        value={signUpObject.email}
                        name="email"
                        placeholder={
                          registerTranslation?.emailPlaceHolder
                            ? registerTranslation?.emailPlaceHolder
                            : "Email"
                        }
                      />
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <div className="error-text" hidden={!emailError}>
                        {registerTranslation?.emailPlaceHolder
                          ? registerTranslation?.emailPlaceHolder
                          : "Please enter valid email id."}
                      </div>
                    </InputGroup>
                    {!thirdPartyUser && (
                      <InputGroup className="mb-2">
                        <Input
                          className="login-input"
                          type="password"
                          onChange={handleInputChange}
                          value={signUpObject.password}
                          name="password"
                          placeholder={
                            registerTranslation?.passwordPlaceHolder
                              ? registerTranslation?.passwordPlaceHolder
                              : "Password"
                          }
                        />
                      </InputGroup>
                    )}
                    {!thirdPartyUser && (
                      <InputGroup className="mb-1">
                        <div className="error-text" hidden={!passwordError}>
                          {registerTranslation?.passwordPlaceHolder
                            ? registerTranslation?.passwordPlaceHolder
                            : "Please enter valid password."}
                        </div>
                      </InputGroup>
                    )}
                    {!thirdPartyUser && (
                      <InputGroup className="mb-2">
                        <Input
                          className="login-input"
                          type="password"
                          onChange={handleInputChange}
                          value={signUpObject.confirmPassword}
                          name="confirmPassword"
                          placeholder={
                            registerTranslation?.cPasswordPlaceHolder
                              ? registerTranslation?.cPasswordPlaceHolder
                              : "Confirm Password"
                          }
                        />
                      </InputGroup>
                    )}
                    {!thirdPartyUser && (
                      <InputGroup className="mb-1">
                        <div
                          className="error-text"
                          hidden={!passwordMatchError}
                        >
                          {registerTranslation?.cPasswordPlaceHolder
                            ? registerTranslation?.cPasswordPlaceHolder
                            : "Your password and confirm password do not match."}
                        </div>
                      </InputGroup>
                    )}
                    <InputGroup className="mb-2">
                      <Input
                        className="login-input"
                        type="select"
                        onChange={handleInputChange}
                        value={signUpObject.country}
                        name="country"
                        id="exampleSelect"
                      >
                        <option>
                          {registerTranslation?.country
                            ? registerTranslation?.country
                            : "Select Country"}
                        </option>
                        {Country
                          ? Country.map((ct, i) => <option>{ct.name}</option>)
                          : "Country not found"}
                      </Input>
                    </InputGroup>

                    <InputGroup hidden={!countryError} className="mb-1">
                      <div className="error-text">
                        {registerTranslation?.country
                          ? registerTranslation?.country
                          : "Please select country."}
                      </div>
                    </InputGroup>

                    <InputGroup className="mb-2">
                      <Col xs="12">
                        <Input
                          type="checkbox"
                          onChange={() =>
                            setTermsAndConditions(!termsAndConditions)
                          }
                          name="termcondition"
                          checked={termsAndConditions}
                        />
                        <span>
                          {registerTranslation?.iAggree
                            ? registerTranslation?.iAggree
                            : "I agree to Yinn's"}
                          <a target="_blank" href="/terms-and-conditions">
                            <u>
                              {registerTranslation?.link
                                ? registerTranslation?.link
                                : "Terms & Conditions"}
                            </u>
                          </a>
                        </span>
                      </Col>
                    </InputGroup>

                    <InputGroup>
                      <div className="error-text" hidden={!TandCError}>
                        {registerTranslation?.iAggree
                          ? registerTranslation?.iAggree +
                            " " +
                            registerTranslation?.link
                          : "Please agree terms & conditions."}
                      </div>
                    </InputGroup>

                    <Button
                      className="register-button"
                      disabled={!termsAndConditions}
                      onClick={() => signUp()}
                      block
                    >
                      <b>
                        {logInTranslation?.registerButton
                          ? logInTranslation?.registerButton
                          : "Register"}
                      </b>
                    </Button>

                    <InputGroup
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "25px",
                      }}
                    >
                      <Button
                        color="link"
                        onClick={() => history.push("/")}
                        className="px-0"
                      >
                        {logInTranslation?.backLink
                          ? logInTranslation?.backLink
                          : "Go Back"}
                      </Button>
                    </InputGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <SkyLight
          hideOnOverlayClicked
          ref={simpleDialog}
          dialogStyles={{ marginTop: "-300px" }}
          title={
            <p style={{ display: "flex", justifyContent: "center" }}>
              <b>
                <h3>Terms and Conditions</h3>
              </b>
            </p>
          }
        >
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the http://www.mywebsite.com
          (change this) website and the My Mobile App (change this) mobile
          application (the "Service") operated by My Company (change this)
          ("us", "we", or "our"). Your access to and use of the Service is
          conditioned on your acceptance of and compliance with these Terms.
          These Terms apply to all visitors, users and others who access or use
          the Service. By accessing or using the Service you agree to be bound
          by these Terms. If you disagree with any part of the terms then you
          may not access the Service. Purchases If you wish to purchase any
          product or service made available through the Service ("Purchase"),
          you may be asked to supply certain information relevant to your
          Purchase including, without limitation, your … The Purchases section
          is for businesses that sell online (physical or digital). For the full
          disclosure section, create your own Terms and Conditions.
          Subscriptions Some parts of the Service are billed on a subscription
          basis ("Subscription(s)"). You will be billed in advance on a
          recurring ... The Subscriptions section is for SaaS businesses. For
          the full disclosure section, create your own Terms and Conditions.
          Content Our Service allows you to post, link, store, share and
          otherwise make available certain information, text, graphics, videos,
          or other material ("Content"). You are responsible for the … The
          Content section is for businesses that allow users to create, edit,
          share, make content on their websites or apps. For the full disclosure
          section, create your own Terms and Conditions. Links To Other Web
          Sites Our Service may contain links to third-party web sites or
          services that are not owned or controlled by My Company (change this).
          My Company (change this) has no control over, and assumes no
          responsibility for, the content, privacy policies, or practices of any
          third party web sites or services. You further acknowledge and agree
          that My Company (change this) shall not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be
          caused by or in connection with use of or reliance on any such
          content, goods or services available on or through any such web sites
          or services. Changes We reserve the right, at our sole discretion, to
          modify or replace these Terms at any time. If a revision is material
          we will try to provide at least 30 (change this) days' notice prior to
          any new terms taking effect. What constitutes a material change will
          be determined at our sole discretion. Contact Us If you have any
          questions about these Terms, please contact us.
        </SkyLight>
      </div>
    </div>
  );
};

export default Register;
