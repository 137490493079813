import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardHeader, Row, Button, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import {
  StopRecording,
  editUserInfo,
  getSubscription,
} from "../../../redux/reducers/seller.js";
import FreemiumPage from "../CustomPages/FreemiumPage";
import WebRTCComponent from "../../Events/videoEvent/publisher.js";
import { sleep } from "../../../helpers/custom.js";
import axios from "../../../config/axios.js";
import "./Streaming.css";
import "./index.js";

const divStyle = { marginLeft: "10%" };

const Streaming = () => {
  const dispatch = useDispatch();
  const {
    user: { userType, id: userId, streamingDetails },
    userLanguage,
  } = useSelector((state) => state.auth);
  const { translationState } = useSelector((state) => state.user);
  const { singleSubscription } = useSelector((state) => state.seller);

  const [translatedContent, setTranslatedContent] = useState({
    streamingTranslation: {},
  });
  const [rtcProp, setRtcProp] = useState({ eventTokenId: "" });
  const [streamingObj, setStreamingObj] = useState({
    isEnabled: true,
    isLive: false,
  });

  useEffect(() => {
    dispatch(getSubscription());
    dispatch(editUserInfo({ id: userId, streamingStatus: false }));
  }, []);

  useEffect(() => {
    if (translationState?.length) {
      const streamingTranslation =
        translationState[0].translations?.streaming?.[userLanguage];
      streamingTranslation
        ? setTranslatedContent({ ...translatedContent, streamingTranslation })
        : setTranslatedContent({ streamingTranslation: {} });
    }
    setStreamingObj({
      ...streamingObj,
      isEnabled: streamingDetails?.isEnabled || false,
    });
  }, [translationState, userLanguage, streamingDetails]);

  const handleCloseLiveButton = async () => {
    try {
      dispatch(editUserInfo({ id: userId, streamingStatus: false }));
      await dispatch(StopRecording({ streamName: rtcProp.eventTokenId }));
      await sleep(5);
      // window.open(`https://live.yinn.ca:5443/live/streams/${rtcProp.eventTokenId}.flv?accessToken=Root001`);
      const recordingUrl = `https://live.yinn.ca:5443/live/streams/${rtcProp.eventTokenId}.flv?accessToken=Root001`;
      const response = await axios.get(recordingUrl, { responseType: "blob" });
      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.data;
      if (blob.size === 0) {
        throw new Error("Downloaded file is empty");
      }
      const urlBlob = URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = urlBlob;
      a.download = `${rtcProp.eventTokenId}.flv`;
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
      URL.revokeObjectURL(urlBlob);
      setStreamingObj({ ...streamingObj, isLive: false });
    } catch (err) {
      console.error("🚀 ~ file: Streaming.js:76 ~ err:", err.message);
      toast.error(err.message);
      setStreamingObj({ ...streamingObj, isLive: false });
    }
  };
  const handleStartLive = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    } catch (error) {
      // Show a toast message that access to camera and microphone is blocked please allow it
      toast.error(
        "Access to camera and microphone is blocked please grant the required permissions"
      );
      return;
    }
    dispatch(editUserInfo({ id: userId, streamingStatus: true }));
    setRtcProp({ ...rtcProp, eventTokenId: userId });
    setStreamingObj({ ...streamingObj, isLive: true });
  };

  return (
    <div className="animated fadeIn">
      <FreemiumPage
        text={
          "Streaming now has recording feature. It will be asked to save the video after streaming ends"
        }
      />
      {singleSubscription?.membershipType === "Fremium" && (
        <FreemiumPage
          text={
            translatedContent.streamingTranslation.infoLineOne ||
            "Only SOLO and Partner users can use the Live Video Streaming feature. Please upgrade your membership to access this and many more exciting features."
          }
        />
      )}
      {!streamingObj.isEnabled && (
        <FreemiumPage
          text={
            translatedContent.streamingTranslation.infoLineTwo ||
            "Streaming module is disabled for your seller account. Please contact admin/support for more information."
          }
        />
      )}

      {singleSubscription?.membershipType !== "Fremium" &&
      streamingObj.isEnabled &&
      streamingObj.isLive ? (
        <CardBody className="stream-rtc-body">
          <WebRTCComponent rtcProp={rtcProp} onClose={handleCloseLiveButton} />
        </CardBody>
      ) : (
        singleSubscription?.membershipType !== "Fremium" &&
        streamingObj.isEnabled && (
          <section style={{ marginTop: "3rem" }}>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6" sm="6">
                        <b>
                          {translatedContent?.streamingTranslation
                            ?.liveStreaming || "Live Streaming"}
                        </b>
                      </Col>
                      <Col xs="6" sm="6" className="d-flex justify-content-end">
                        {userType == "seller" && (
                          <Button
                            onClick={handleStartLive}
                            size="sm"
                            style={divStyle}
                            color="warning"
                          >
                            <b>
                              {translatedContent.streamingTranslation.goLive ||
                                "Go Live"}
                              !
                            </b>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </section>
        )
      )}
    </div>
  );
};

export default Streaming;
