import React, { Component } from "react";
import {
  Button,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Label,
  Badge,
  CardHeader,
  FormGroup,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { render } from "react-dom";
import logo from "../../../assets/img/brand/logo.png";
import sygnet from "../../../assets/img/brand/logo.png";
import classnames from "classnames";
import axios from "axios";
import Env from "../../../environment/environment";
import "./Browse.css";

import { AppFooter, AppHeader } from "@coreui/react";

import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import ValidationProvider from "../../Providers/ValidationProvider";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";

const BrowseTvseries = (props) => {
  let toastId = null;
  let state = {
    seriesDetails: "",
    coverPhoto: "",
  };
  // constructor(props) {
  //     super(props);
  //     console.log('================================================');
  //     // function defination
  //     //setSeriesValue = setSeriesValue;
  //     getProduct();
  // }

  const componentDidMount = () => {};
  const setSeriesValue = (item, evt) => {
    // setValue(item);
    props.history.push("/viewtv");
    // console.log(getValue());
  };

  const getProduct = () => {
    // axios.get(Env.url + '/product/get/' + getOrderIdValue()).then(response => {

    //setState({
    //     seriesDetails:response.data[0],
    //     coverPhoto : response.data[0].images[0]
    //   });
    console.log("in get ", state);
    // //setState({ items: response.data });

    // })
  };
  const { activeIndex } = state;
  return (
    <div className="app" style={{ backgroundColor: "white" }}>
      <ToastContainer autoClose={20000} />
      <div>
        <Row>
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
        </Row>
        <br />
        <br />
      </div>
      <Row style={{ marginTop: "5%" }}>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <strong>View Series</strong>
            </CardHeader>
            <CardBody>
              <section class="purchase" id="buy-now">
                <div class="container">
                  {}
                  <Row>
                    {state.seriesDetails != "" &&
                    state.seriesDetails != undefined &&
                    state.seriesDetails.season != null ? (
                      state.seriesDetails.season.map((item, index) => (
                        <Col hidden={item.length <= 0} xs="12" sm="6" md="4">
                          <Card>
                            <CardHeader>
                              Season {index + 1}
                              <Badge
                                onClick={setSeriesValue.bind(this, item)}
                                hidden={item.length <= 0}
                                color="success"
                                className="float-right"
                              >
                                Click to watch
                              </Badge>
                            </CardHeader>
                            <CardBody>
                              <img src={state.coverPhoto} />
                            </CardBody>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <p style={{ color: "red" }}>
                        No video are available to watch
                      </p>
                    )}
                  </Row>
                </div>
              </section>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>{/* series */}</Row>

      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

export default BrowseTvseries;
