export const Ebook = [" Textbooks", "Novels", "Magazines"];

export const Movies = [
  "Streaming (e.g., Netflix, Amazon Prime per hour)",
  "Blu-ray Discs",
  " DVDs",
];

export const Music = [
  "Streaming (e.g., Spotify, Apple Music)",
  "Downloaded Files (e.g., MP3, FLAC)",
  " CDs",
];

export const Games = [
  "Digital Downloads (PC, Console)",
  "Physical Discs (Blu-ray, DVD)",
  " Streaming (Cloud Gaming per hour play)",
];

export const TVSeries = [
  "Streaming (e.g., Netflix, Hulu)",
  "Physical Discs (DVD, Blu-ray)",
  "Downloaded Episodes(per episode)",
];

export const APPSoftware = [
  "Mobile Apps ",
  " Desktop Software",
  " Web Applications(per minute use) ",
];

export const StageArt = [
  "Mobile Apps ",
  " Desktop Software",
  " Recorded Performances (Digital streaming, DVDs)",
];
