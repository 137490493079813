import React, { Component } from "react";
import moment from 'moment';

import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  Label
} from "reactstrap";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import Env from '../../environment/environment';
import './Invoice.css';
import cookie from 'react-cookies';
import DefaultFooter from '../Footer/DefaultFooter';
import DefaultHeader from '../Header/DefaultHeader';
import {
  AppFooter,
  AppHeader,
} from '@coreui/react';
import { generateInvoice } from './InvoiceService';
import { CurrencyContext, convertCurrency } from '../../contexts/currency-context'

const Invoice = () => {
  let state = {
    order: [],
  };
  // constructor(props) {
  //   super(props);
  // }

  const componentWillMount = () => {
    axios.get(Env.url + '/orders/get/' + cookie.load('order_id'),
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('auth_token')
        }
      }).then(function (response) {
        //setState({ order: response.data });
        console.log(state.order, 'orderssssssssss')
      }).catch(err => {
        if (err.response != undefined) {
          if (err.response.status == 401 || err.response.status == 400 || err.response.status == 500) {
            window.location = "#/login";
          }
        }
      });
  }

  const goToSearchPage = () => {
    window.location = '#/search'
  }
    return (
      <CurrencyContext.Consumer>
            {({changeCurrency, currency}) => (
      <div className="app" style={{ "backgroundColor": "white" }}>
        <Row>
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
        </Row><br /><br /><br /><br /><br /><br />
        <div className="container firefox-margin"  >

          <Row>
            <Col  md="9" >
              <a className="button-hollow noprint" onClick={(e) => goToSearchPage(e)}><span className="cui-chevron-left" aria-hidden="true"></span> Go Back to Purchases</a>
            </Col>
            <Col  md="3" >
              <a className="invoice-button noprint" onClick={(e) => { window.print() }}> Print Invoice</a>
            </Col>
          </Row><br /><br />

          <Card className="card-shadow" style={{ paddingLeft: '7.5%' }}>
            {state.order.length > 0 ? state.order.map((o, i) => (
              <CardBody id="divToPrint">

                <Row>
                  <Col xs="5" sm="5" md="5" >
                    <img src={'assets/img/logo.png'} width="100" height="50" />
                  </Col>
                  <Col  md="3" >
                  </Col>
                  <Col  md="3" >
                    <h2 className="invoice-name">Invoice</h2>
                  </Col>
                </Row><hr style={{ 'width': '90%', marginRight: '10%' }} /><br />

                {o.customerdetails.length > 0 ? o.customerdetails.map((cd, index) => (
                  <Row>

                    <Col md="5" >
                      <Label>Hello,{cd.firstname + ' ' + cd.lastname}.</Label>
                    </Col>
                    <Col  md="3" >
                    </Col>
                    <Col  md="4" >
                      <Label>Order ID #{o._id}</Label>
                    </Col>
                  </Row>)) : ''}

                <Row>

                  <Col md="5" >
                    <Label>Thank you for your order.</Label>
                  </Col>
                  <Col md="3" >
                  </Col>
                  <Col  md="4" >
                    <Label>{moment(o.order_place_date).format("MMM D YYYY, h:mm A")}</Label>
                  </Col>

                </Row><br /><br />

                <Row>
                  <Col xs="12" sm="12" md="12">
                    <Row>
                      <Col xs="5" sm="5" md="5" >
                        <h5><b>Item Description</b></h5>
                      </Col>
                      <Col xs="3" sm="3" md="3" >
                        <h5><b>Item ID</b></h5>
                      </Col>
                      <Col xs="4" sm="4" md="4" >
                        <h5><b>Quantity</b></h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {o.orderdetails.length > 0 ? o.orderdetails.map((od, index) => (
                  <Row className="invoice-desc">
                    {o.product.length > 0 ? o.product.map((p, index) => (
                      <Col xs="12" sm="12" md="12">

                        <Row hidden={od._id != p.product_id}>
                          <Col xs="5" sm="5" md="5" >
                            <h6>{od.name}</h6>
                          </Col>
                          <Col xs="3" sm="3" md="3" >
                            <h6>{od._id}</h6>
                          </Col>
                          <br></br>
                          <br></br>
                          <Col xs="4" sm="4" md="4" >
                            <Col xs="4" sm="4" md="3" >
                              <h6 align="center">{p.quantity}</h6>
                            </Col>
                          </Col>
                        </Row>
                      </Col>)) : ''}
                  </Row>)) : ''}

                <Row>
                  <Col xs="8" sm="8" md="8" >
                  </Col>

                  <Col xs="4" sm="4" md="4" >
                    <strong style={{ "font-size": "large" }}>Total =</strong>
                    &nbsp;<strong style={{ "font-size": "large" }}>{currency} {( convertCurrency(Number(o.product[0].amount_payable ), currency) ).toFixed(2)}</strong>
                    {/* <h6 style={{ paddingRight: '10%' }}>$ {o.total}</h6> */}
                  </Col>
                  {/* <Col xs="2" sm="2" md="2" style={{ paddingRight: '10%' }}>
                    <h6>$ {o.total}</h6>
                  </Col> */}
                </Row>

                <br /><hr style={{ 'width': '90%', marginRight: '10%' }} />
                <Row style={{ 'marginRight': '7.5%' }}>
                  {/* <Col xs = "1" sm = "1" md = "1" >
                    </Col> */}
                  <Col hidden={!o.shipping_address} xs="4" sm="4" md="4" >
                    <h5 className="billing-invoice">BILLING INFORMATION</h5>
                  </Col>
                  <Col xs="5" sm="5" md="5" >
                    <h5></h5>
                  </Col>
                  <Col xs="3" sm="3" md="3" >
                    <h5></h5>
                  </Col>
                </Row>

                {o.customerdetails.length > 0 ? o.customerdetails.map((cd, index) => (
                  <Row>
                    {/* <Col xs = "1" sm = "1" md = "1" >
                    </Col> */}
                    <Col xs="4" sm="4" md="4" >
                      <h3 className="invoice-address">{cd.firstname + ' ' + cd.lastname + ' ' + o.shipping_address ? false:o.shipping_address.addressline_1 + ' ' + o.shipping_address?false:o.shipping_address.addressline_2 + ' ' + o.shipping_address.city + ' ' + o.shipping_address.states + ' ' + o.shipping_address.country + ' ' + o.shipping_address.zip}</h3>
                    </Col>
                    <Col xs="5" sm="5" md="5" >
                      <h5></h5>
                    </Col>
                    <Col xs="3" sm="3" md="3" >
                    </Col>
                  </Row>)) : ''}

              </CardBody>)) : ''}
          </Card>

        </div>
        </div>
        )}
        </CurrencyContext.Consumer>
    )
}

export default Invoice;