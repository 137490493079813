import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./row.css";
import closeIconSrc from "../../../assets/img/cart/close-icon.svg";
import plusImgSrc from "../../../assets/img/product-detail/add-quantity.svg";
import minusImgSrc from "../../../assets/img/product-detail/remove-quantity.svg";
import { updateCart, removeFromCart } from "../../../redux/reducers/auth";

const CartEntry = (props) => {
  const { item, message } = props;
  const dispatch = useDispatch();
  const { userCurrency } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const { usdRate } = useSelector((state) => state.auth);
  const ChatGPTTranslated = item?.product?.translatedArray?.find((item)=> item.language == userLanguage)

  const update = (qty) => {
    if (item.product.stockQuantity >= qty && qty > 0) {
      const data = {
        product: item.product,
        qty,
      };
      dispatch(updateCart(data));
    }
  };
  return (
    <div className="cart-entry-row" style={{ padding: "20px 16px" }}>
      <div className="cart-entry-row-product-name-section">
        <img
          className="cart-entry-row-image"
          src={item.product?.images && item.product?.images[0]}
        />
        <div className="mr-5">
          {ChatGPTTranslated && ChatGPTTranslated?.name ? ChatGPTTranslated?.name : item.product?.name}
          {/* {item.product?.name} */}
          <div> {item.product?.brand}</div>
          <div className="cart-entry-show-on-mobile">
            {item.qty}X{item.product?.unitPrice}
          </div>
        </div>
        {message && <div style={{ color: "red" }}>{message}</div>}
      </div>
      <div className="cart-entry-hide-on-mobile">
        {(item.product?.unitPrice * usdRate).toFixed(2)} {userCurrency}
      </div>
      <div
        className="cart-entry-hide-on-mobile"
        style={{ display: "flex", alignItems: "center" }}
      >
        <img
          src={minusImgSrc}
          id="decrement-btn"
          className="cart-entry-quantity-minus cart-entry-hide-on-mobile"
          onClick={() => {
            update(item.qty - 1);
          }}
        ></img>
        <div
          className="cart-entry-quanttity-count cart-entry-hide-on-mobile"
          id="counter"
        >
          {item.qty}
        </div>
        <img
          src={plusImgSrc}
          id="increment-btn"
          className="cart-entry-quantity-plus cart-entry-hide-on-mobile"
          onClick={() => {
            update(item.qty + 1);
          }}
        ></img>
      </div>
      <div className="cart-entry-hide-on-mobile">
        {(item.product?.unitPrice * item.qty * usdRate).toFixed(2)}{" "}
        {userCurrency}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "3%",
          minWidth: 20,
          justifyContent: "space-between",
        }}
      >
        <div className="cart-entry-seperator cart-entry-hide-on-mobile" />
        <img
          className="cart-entry-close-icon"
          alt="remove"
          src={closeIconSrc}
          onClick={(e) => dispatch(removeFromCart(item.product._id))}
        />
      </div>
    </div>
  );
};

export default CartEntry;
