import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";

import { addFilter, getAllUsers, updateUser } from "../../redux/reducers/admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./users.css";
import Env from "../../environment/environment";
import moment from "moment";
import { AppSwitch } from "@coreui/react";
import { nextPagesItems } from "../../helpers/custom";

var divStyle = {
  marginLeft: "81%",
};

const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { users, count, filters, loading } = useSelector(
    (state) => state.admin
  );
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "userType",
        value: "",
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(getAllUsers());
  }, []);
  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getAllUsers());
  };
  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(getAllUsers());
    }
  };
  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getAllUsers());
  };

  const updateStatus = (userId, isActive) => {
    const data = {
      userId,
      isActive,
    };
    dispatch(updateUser(data)).then(() => {
      dispatch(getAllUsers());
    });
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col>
          <Card className="mt-5">
            <CardHeader>
              <Row>
                <Col xs="12" sm="9">
                  <b>Users List</b>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              <Row>
                <Col xs="12" sm="4">
                  <InputGroup>
                    <Input
                      type="text"
                      id="input1-group2"
                      onChange={searchElement}
                      className="search-color"
                      name="input1-group2"
                      placeholder="User Name/Email"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color">
                    <th> Name</th>
                    <th> Email Address</th>
                    <th> Phone</th>
                    <th> Joining Date</th>
                    <th> Membership </th>
                    <th> Activate/Deactivate</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={6}>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : users.length > 0 && !loading ? (
                    users.map((item, index) => (
                      <tr>
                        <td className="pl-2">
                          {item.firstname + " " + item.lastname || "NA"}
                        </td>
                        <td className="pl-2">{item.email}</td>
                        <td align="center">{item.phone || "NA"}</td>
                        <td className="pl-2">
                          {moment(item.createdAt).format("MMM D YYYY ,hh:m A")}
                        </td>
                        <td>
                          {item.membership
                            ? item.membership.membershipType
                            : "Freemuim"}
                        </td>
                        <td align="center">
                          {
                            <AppSwitch
                              className={"mx-1"}
                              variant={"pill"}
                              color={"primary"}
                              name="isActive"
                              checked={item.isActive}
                              onChange={(e) =>
                                updateStatus(item._id, e.target.checked)
                              }
                            />
                          }
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={6} className="py-5 text-center">
                      <p style={{ color: "red" }}>Records Not Found</p>
                    </td>
                  )}
                </tbody>
              </Table>
              <br />
              <nav>
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo - 1)}
                      disabled={pageNo === 1 ? true : false}
                      style={{
                        backgroundColor: "#e4e7ea",
                      }}
                    >
                      Prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: "#e4e7ea",
                      }}
                      className="mx-2"
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        Items per Page
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, pageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Users;
