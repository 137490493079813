import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Widget02 from "./Widget02";
import { Col, Row, Card, CardBody, CardHeader } from "reactstrap";
import "./DigitalProduct.css";

const DigitalProduct = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const handleEbooks = () => {
    history.push("/seller/digitalproduct/ebook");
  };
  const handleMovies = () => {
    history.push("/seller/digitalproduct/movie");
  };
  const handleGames = () => {
    history.push("/seller/digitalproduct/game");
  };
  const handleMusics = () => {
    history.push("/seller/digitalproduct/music");
  };
  const handleSoftwares = () => {
    history.push("/seller/digitalproduct/software");
  };
  const handleTvseries = () => {
    history.push("/seller/digitalproduct/series");
  };
  const handleTheater = () => {
    history.push("/seller/digitalproduct/theater");
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" md="12">
          <Card style={{ marginTop: "10px" }}>
            <CardHeader>
              <strong style={{ fontSize: "14px" }}>
                {digitalProductTranslation?.digitalProduct
                  ? digitalProductTranslation?.digitalProduct
                  : "Digital Products"}
              </strong>
            </CardHeader>
            <CardBody>
              <div className="animated fadeIn">
                <Row>
                  <Col xs="12" sm="6" lg="3" style={{ cursor: "pointer" }}>
                    <Widget02
                      onClick={handleEbooks}
                      mainText={
                        digitalProductTranslation?.ebook
                          ? digitalProductTranslation?.ebook
                          : "Ebook"
                      }
                      icon="fa fa-book"
                      color="primary"
                      variant="1"
                      iconClass="fixed-size-icon"
                    />
                  </Col>
                  <Col xs="12" sm="6" lg="3" style={{ cursor: "pointer" }}>
                    <Widget02
                      onClick={handleMusics}
                      mainText={
                        digitalProductTranslation?.music
                          ? digitalProductTranslation?.music
                          : "Music"
                      }
                      icon="fa fa-music"
                      color="warning"
                      variant="1"
                      iconClass="fixed-size-icon"
                    />
                  </Col>
                  <Col xs="12" sm="6" lg="3" style={{ cursor: "pointer" }}>
                    <Widget02
                      onClick={handleMovies}
                      mainText={
                        digitalProductTranslation?.movie
                          ? digitalProductTranslation?.movie
                          : "Movies"
                      }
                      icon="fa fa-file-movie-o"
                      color="primary"
                      variant="1"
                      iconClass="fixed-size-icon"
                    />
                  </Col>
                  <Col xs="12" sm="6" lg="3" style={{ cursor: "pointer" }}>
                    <Widget02
                      onClick={handleGames}
                      mainText={
                        digitalProductTranslation?.game
                          ? digitalProductTranslation?.game
                          : "Games"
                      }
                      icon="fa fa-gamepad"
                      color="primary"
                      variant="1"
                      iconClass="fixed-size-icon"
                    />
                  </Col>
                  <Col xs="12" sm="6" lg="3" style={{ cursor: "pointer" }}>
                    <Widget02
                      onClick={handleTvseries}
                      mainText={
                        digitalProductTranslation?.series
                          ? digitalProductTranslation?.series
                          : "TV series "
                      }
                      icon="fa fa-television"
                      color="warning"
                      variant="1"
                      iconClass="fixed-size-icon"
                    />
                  </Col>
                  <Col xs="12" sm="6" lg="3" style={{ cursor: "pointer" }}>
                    <Widget02
                      onClick={handleSoftwares}
                      mainText={
                        digitalProductTranslation?.software
                          ? digitalProductTranslation?.software
                          : "APP software "
                      }
                      icon="fa fa-mobile"
                      color="primary"
                      variant="1"
                      iconClass="fixed-size-icon"
                    />
                  </Col>
                  <Col xs="12" sm="6" lg="3" style={{ cursor: "pointer" }}>
                    <Widget02
                      onClick={handleTheater}
                      mainText={
                        digitalProductTranslation?.theater
                          ? digitalProductTranslation?.theater
                          : "Stage art"
                      }
                      icon="fa fa-moon-o"
                      color="warning"
                      variant="1"
                      iconClass="fixed-size-icon"
                    />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DigitalProduct;
