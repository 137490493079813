import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubscription,
  getSubscription,
  getMembershipPlans,
} from "../../../redux/reducers/seller";
import { getWallet } from "../../../redux/reducers/user";
import CustomCard from "./customCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import "./Membership.css";
const Membership = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { singleSubscription, loading } = useSelector((state) => state.seller);
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSubscription());
    dispatch(getMembershipPlans());
  }, []);

  const { membershipPlans } = useSelector((state) => state.seller);

  const membershipTranslation =
    translationState?.[0]?.translations?.membership?.[userLanguage];
  const otherMemberShipTranslation =
    translationState?.[0]?.translations?.otherMemberShip?.[userLanguage];
  const plans = [
    {
      id: 0,
      type: "Fremium",
      name: `${
        otherMemberShipTranslation?.premium
          ? otherMemberShipTranslation?.premium
          : "Yinn Fremium"
      }`,
      amount: 0,
      duration: `${
        otherMemberShipTranslation?.month
          ? otherMemberShipTranslation?.month
          : "per month"
      }`,
      features: [
        {
          feature: `${
            membershipTranslation?.unlimited
              ? membershipTranslation?.unlimited
              : "Unlimited Listings"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.noListingFee
              ? membershipTranslation?.noListingFee
              : "No listing Fees"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.sellHandmade
              ? membershipTranslation?.sellHandmade
              : "Sell Handmade products"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.reletedService
              ? membershipTranslation?.reletedService
              : "Sell Handmade products related services"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.sellDigital
              ? membershipTranslation?.sellDigital
              : "Sell Digital Downloads products"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.acceptYinnCoin
              ? membershipTranslation?.acceptYinnCoin
              : "Accept Yncoin payments"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.buyYinncoin
              ? membershipTranslation?.buyYinncoin
              : "Buy Yncoin"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.liveChat
              ? membershipTranslation?.liveChat
              : "Live chat with YINN Adnin"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.multiLang
              ? membershipTranslation?.multiLang
              : "Multiple language"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.multiLang
              ? membershipTranslation?.multiLang
              : "Multiple currencies"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.acceptLBT
              ? membershipTranslation?.acceptLBT
              : "Accept Local Bank Transfer Payments"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.basicVaribale
              ? membershipTranslation?.basicVaribale
              : "Basic and Variable Listings"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.createDigital
              ? membershipTranslation?.createDigital
              : "Create Digital signature"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.gst
              ? membershipTranslation?.gst
              : "GST , PST , or no TAX Options"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.flexible
              ? membershipTranslation?.flexible
              : "Flexible shipping Rates"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.escrowService
              ? membershipTranslation?.escrowService
              : "YINN Escrow Service"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.optimied
              ? membershipTranslation?.optimied
              : "Optimized For Mobile"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.exportOrder
              ? membershipTranslation?.exportOrder
              : "Export Orders to CSV"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.stock
              ? otherMemberShipTranslation?.stock
              : "Stock inventory Tool"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.sale
              ? otherMemberShipTranslation?.sale
              : "Advance Sale Reports Tool"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.saleReport
              ? otherMemberShipTranslation?.saleReport
              : "Export Sale Reports customer wise and Country wise to CSV, PDF , Excel"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.offline
              ? otherMemberShipTranslation?.offline
              : "Online to Offline Option"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.residency
              ? otherMemberShipTranslation?.residency
              : "Residency swap"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.loan
              ? otherMemberShipTranslation?.loan
              : "Have access to loan /Crowdfunding"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.delivery
              ? otherMemberShipTranslation?.delivery
              : "Local delivery Options"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.variable
              ? otherMemberShipTranslation?.variable
              : "Variable pricing Tool"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.streaming
              ? otherMemberShipTranslation?.streaming
              : "Live Streaming Tool"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.social
              ? otherMemberShipTranslation?.social
              : "Share to Social Network Options"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.order
              ? otherMemberShipTranslation?.order
              : "Live Order Notification"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.rate
              ? otherMemberShipTranslation?.rate
              : "Price rate system"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.profile
              ? otherMemberShipTranslation?.profile
              : "Store Profile Listing"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.support
              ? otherMemberShipTranslation?.support
              : "24/7 Support"
          }`,
          included: false,
        },
        {
          feature: `${
            otherMemberShipTranslation?.wallet
              ? otherMemberShipTranslation?.wallet
              : "Move money out of your YINN wallet, $0.99/transaction"
          }`,
          included: true,
        },
      ],
    },
    {
      id: 1,
      type: "Solo",
      name: `${
        otherMemberShipTranslation?.solo
          ? otherMemberShipTranslation?.solo
          : "Yinn Solo"
      }`,
      amount: 12,
      duration: `${
        otherMemberShipTranslation?.month
          ? otherMemberShipTranslation?.month
          : "per month"
      }`,
      features: [
        {
          feature: `${
            membershipTranslation?.unlimited
              ? membershipTranslation?.unlimited
              : "Unlimited Listings"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.noListingFee
              ? membershipTranslation?.noListingFee
              : "No listing Fees"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.sellHandmade
              ? membershipTranslation?.sellHandmade
              : "Sell Handmade products"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.reletedService
              ? membershipTranslation?.reletedService
              : "Sell Handmade products related services"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.sellDigital
              ? membershipTranslation?.sellDigital
              : "Sell Digital Downloads products"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.acceptYinnCoin
              ? membershipTranslation?.acceptYinnCoin
              : "Accept Yncoin payments"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.buyYinncoin
              ? membershipTranslation?.buyYinncoin
              : "Buy Yncoin"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.liveChat
              ? membershipTranslation?.liveChat
              : "Live chat with YINN Adnin"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.multiLang
              ? membershipTranslation?.multiLang
              : "Multiple language"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.multiLang
              ? membershipTranslation?.multiLang
              : "Multiple currencies"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.acceptLBT
              ? membershipTranslation?.acceptLBT
              : "Accept Local Bank Transfer Payments"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.basicVaribale
              ? membershipTranslation?.basicVaribale
              : "Basic and Variable Listings"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.createDigital
              ? membershipTranslation?.createDigital
              : "Create Digital signature"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.gst
              ? membershipTranslation?.gst
              : "GST , PST , or no TAX Options"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.flexible
              ? membershipTranslation?.flexible
              : "Flexible shipping Rates"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.escrowService
              ? membershipTranslation?.escrowService
              : "YINN Escrow Service"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.optimied
              ? membershipTranslation?.optimied
              : "Optimized For Mobile"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.exportOrder
              ? membershipTranslation?.exportOrder
              : "Export Orders to CSV"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.stock
              ? otherMemberShipTranslation?.stock
              : "Stock inventory Tool"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.sale
              ? otherMemberShipTranslation?.sale
              : "Advance Sale Reports Tool"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.saleReport
              ? otherMemberShipTranslation?.saleReport
              : "Export Sale Reports customer wise and Country wise to CSV, PDF , Excel"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.offline
              ? otherMemberShipTranslation?.offline
              : "Online to Offline Option"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.residency
              ? otherMemberShipTranslation?.residency
              : "Residency swap"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.loan
              ? otherMemberShipTranslation?.loan
              : "Have access to loan /Crowdfunding"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.delivery
              ? otherMemberShipTranslation?.delivery
              : "Local delivery Options"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.variable
              ? otherMemberShipTranslation?.variable
              : "Variable pricing Tool"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.streaming
              ? otherMemberShipTranslation?.streaming
              : "Live Streaming Tool"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.social
              ? otherMemberShipTranslation?.social
              : "Share to Social Network Options"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.order
              ? otherMemberShipTranslation?.order
              : "Live Order Notification"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.rate
              ? otherMemberShipTranslation?.rate
              : "Price rate system"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.profile
              ? otherMemberShipTranslation?.profile
              : "Store Profile Listing"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.support
              ? otherMemberShipTranslation?.support
              : "24/7 Support"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.wallet
              ? otherMemberShipTranslation?.wallet
              : "Move money out of your YINN wallet, $0.99/transaction"
          }`,
          included: true,
        },
      ],
    },
    {
      id: 2,
      type: "Partner",
      name: `${
        otherMemberShipTranslation?.partner
          ? otherMemberShipTranslation?.partner
          : "Yinn Partner"
      }`,
      amount: 108,
      duration: `${
        otherMemberShipTranslation?.month
          ? otherMemberShipTranslation?.month
          : "per Year"
      }`,
      features: [
        {
          feature: `${
            membershipTranslation?.unlimited
              ? membershipTranslation?.unlimited
              : "Unlimited Listings"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.noListingFee
              ? membershipTranslation?.noListingFee
              : "No listing Fees"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.sellHandmade
              ? membershipTranslation?.sellHandmade
              : "Sell Handmade products"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.reletedService
              ? membershipTranslation?.reletedService
              : "Sell Handmade products related services"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.sellDigital
              ? membershipTranslation?.sellDigital
              : "Sell Digital Downloads products"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.acceptYinnCoin
              ? membershipTranslation?.acceptYinnCoin
              : "Accept Yncoin payments"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.buyYinncoin
              ? membershipTranslation?.buyYinncoin
              : "Buy Yncoin"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.liveChat
              ? membershipTranslation?.liveChat
              : "Live chat with YINN Adnin"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.multiLang
              ? membershipTranslation?.multiLang
              : "Multiple language"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.multiLang
              ? membershipTranslation?.multiLang
              : "Multiple currencies"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.acceptLBT
              ? membershipTranslation?.acceptLBT
              : "Accept Local Bank Transfer Payments"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.basicVaribale
              ? membershipTranslation?.basicVaribale
              : "Basic and Variable Listings"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.createDigital
              ? membershipTranslation?.createDigital
              : "Create Digital signature"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.gst
              ? membershipTranslation?.gst
              : "GST , PST , or no TAX Options"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.flexible
              ? membershipTranslation?.flexible
              : "Flexible shipping Rates"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.escrowService
              ? membershipTranslation?.escrowService
              : "YINN Escrow Service"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.optimied
              ? membershipTranslation?.optimied
              : "Optimized For Mobile"
          }`,
          included: true,
        },
        {
          feature: `${
            membershipTranslation?.exportOrder
              ? membershipTranslation?.exportOrder
              : "Export Orders to CSV"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.stock
              ? otherMemberShipTranslation?.stock
              : "Stock inventory Tool"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.sale
              ? otherMemberShipTranslation?.sale
              : "Advance Sale Reports Tool"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.saleReport
              ? otherMemberShipTranslation?.saleReport
              : "Export Sale Reports customer wise and Country wise to CSV, PDF , Excel"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.offline
              ? otherMemberShipTranslation?.offline
              : "Online to Offline Option"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.residency
              ? otherMemberShipTranslation?.residency
              : "Residency swap"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.loan
              ? otherMemberShipTranslation?.loan
              : "Have access to loan /Crowdfunding"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.delivery
              ? otherMemberShipTranslation?.delivery
              : "Local delivery Options"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.variable
              ? otherMemberShipTranslation?.variable
              : "Variable pricing Tool"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.streaming
              ? otherMemberShipTranslation?.streaming
              : "Live Streaming Tool"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.social
              ? otherMemberShipTranslation?.social
              : "Share to Social Network Options"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.order
              ? otherMemberShipTranslation?.order
              : "Live Order Notification"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.rate
              ? otherMemberShipTranslation?.rate
              : "Price rate system"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.profile
              ? otherMemberShipTranslation?.profile
              : "Store Profile Listing"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.support
              ? otherMemberShipTranslation?.support
              : "24/7 Support"
          }`,
          included: true,
        },
        {
          feature: `${
            otherMemberShipTranslation?.wallet
              ? otherMemberShipTranslation?.wallet
              : "Move money out of your YINN wallet, $0.99/transaction"
          }`,
          included: true,
        },
      ],
    },
  ];

  const updatedPlans = membershipPlans.map((plan) => {
    const index = plans.findIndex((planItem) => planItem.id === plan.planId);

    if (index !== -1) {
      return {
        ...plans[index],
        amount: plan.planPrice,
      };
    }
  });

  const subscribe = (subscriptionId, actionType) => {
    const data = {
      membershipType: subscriptionId,
      actionType,
    };
    console.log({ data });
    dispatch(createSubscription(data)).then(() => {
      dispatch(getSubscription());
      dispatch(getWallet());
    });
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <div className="custom-section">
        <div className="heading-container">
          <h3 className="select-plan-heading">
            {membershipTranslation?.selectPlan
              ? membershipTranslation?.selectPlan
              : "Select Plan"}
          </h3>
          <p className="select-plan-p">
            {membershipTranslation?.paralineOne
              ? membershipTranslation?.paralineOne
              : "Upgrade for premium to see exiting features"}
          </p>
        </div>
        <div className="cards-container">
          {loading ? (
            <div style={{ margin: "170px", textAlign: "center" }}>
              <i
                className="mx-auto fas fa-spinner fa-pulse fa-2x"
                style={{ color: "green" }}
              ></i>
            </div>
          ) : (
            updatedPlans.map((item) => (
              <CustomCard
                item={item}
                onSubscribe={subscribe}
                userSubscription={singleSubscription}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Membership;
