import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleWallet,
  updateSingleWallet,
} from "../../../redux/reducers/admin";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  CardFooter,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppSwitch } from "@coreui/react";
import "./walletDetails.css";

const WalletDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { singleWallet, loading } = useSelector((state) => state.admin);
  const urlArray = history.location.pathname.split("/");
  const walletId = urlArray[urlArray.length - 1];
  const [pageContent, setPageContent] = useState({
    adSpend: 0,
    coinBalance: 0,
    earnings: 0,
    loanedCoinBalance: 0,
  });
  useEffect(() => {
    setPageContent({ ...singleWallet });
  }, [singleWallet]);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };

  useEffect(() => {
    const data = {
      id: walletId,
    };
    dispatch(getSingleWallet(data));
  }, []);

  const updateWalletValue = () => {
    const data = {
      id: walletId,
      ...pageContent,
    };
    dispatch(updateSingleWallet(data)).then(() => {
      dispatch(getSingleWallet({ id: walletId }));
    });
  };

  const updateWalletStatus = (key, value) => {
    const data = {
      id: walletId,
      [key]: value,
    };
    dispatch(updateSingleWallet(data)).then(() => {
      dispatch(getSingleWallet({ id: walletId }));
    });
  };
  const coninstext = { fontSize: "16px", fontWeight: "bold" };
  const coninInput = {
    width: "300px",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid rgb(228, 231, 234)",
    textAlign: "right",
    color: "#ffc101",
    outline: "none",
    maxWidth: "100%",
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      {loading ? (
        <div style={{ marginTop: "240px", textAlign: "center" }}>
          <i
            className="mx-auto fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : (
        <>
          <Card className="mt-5 mx-2 mx-md-5">
            <CardHeader>
              <Row>
                <Col xs="5" sm="5" md="6">
                  <h5>
                    <b>Wallet Details </b>
                  </h5>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <div className="d-flex justify-content-around align-items-center flex-wrap">
                <div className="d-flex" style={{ width: "51%" }}>
                  <h4 style={coninstext}>Earnings($):</h4>
                </div>
                <div className="d-flex" style={{ width: "49%" }}>
                  <input
                    name="earnings"
                    value={pageContent.earnings}
                    onChange={handleInputChange}
                    className="my-2"
                    style={coninInput}
                    type="number"
                  />
                </div>
              </div>
              <div
                className="d-flex justify-content-around align-items-center flex-wrap"
                style={{ width: "100%" }}
              >
                <div className="d-flex" style={{ width: "51%" }}>
                  <h4 style={coninstext}>Coin balance: </h4>
                </div>
                <div className="d-flex" style={{ width: "49%" }}>
                  <input
                    name="coinBalance"
                    value={pageContent.coinBalance}
                    onChange={handleInputChange}
                    className="my-2"
                    style={coninInput}
                    type="number"
                  />
                </div>
              </div>
              <div
                className="d-flex justify-content-around align-items-center flex-wrap"
                style={{ width: "100%" }}
              >
                <div className="d-flex" style={{ width: "51%" }}>
                  <h4 style={coninstext}>Loaned coins: </h4>
                </div>
                <div className="d-flex" style={{ width: "49%" }}>
                  <input
                    name="loanedCoinBalance"
                    value={pageContent.loanedCoinBalance}
                    onChange={handleInputChange}
                    className="my-2"
                    style={coninInput}
                    type="number"
                  />
                </div>
              </div>
              <div
                className="d-flex justify-content-around align-items-center flex-wrap"
                style={{ width: "100%" }}
              >
                <div className="d-flex" style={{ width: "51%" }}>
                  <h4 style={coninstext}>Ad spend: </h4>
                </div>
                <div className="d-flex" style={{ width: "49%" }}>
                  <input
                    name="adSpend"
                    value={pageContent.adSpend}
                    onChange={handleInputChange}
                    className=""
                    style={coninInput}
                    type="number"
                  />
                </div>
              </div>
            </CardBody>

            <CardFooter>
              <Button
                onClick={updateWalletValue}
                style={{
                  background: "#ffc101",
                }}
              >
                Update
              </Button>
            </CardFooter>
          </Card>
          <Card className="mx-lg-5 mx-2">
            <CardHeader>
              <Col className="col-sm-2"></Col>
              <Col className="col-sm-9">
                <h5>
                  <b>Paypal Details</b>
                </h5>
              </Col>
            </CardHeader>
            <CardBody>
              <div className="row">
                <div className="d-flex justify-content-left align-items-center col-md-6 col-12  ">
                  <h4 style={coninstext}>Paypal Email Id:</h4>
                </div>
                <div className="d-flex justify-content-left align-items-center col-md-6 col-12 ">
                  {singleWallet?.userId?.email}
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="mx-lg-5 mx-2">
            <CardHeader>
              <Col className="col-sm-2"></Col>
              <Col className="col-sm-9">
                <h5>
                  <b>Wallet Settings</b>
                </h5>
              </Col>
            </CardHeader>
            <CardBody>
              <div className="row my-2">
                <div className="d-flex justify-content-left align-items-center col-md-6 col-12   ">
                  <h4 style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Earnings Lock (to freeze the amount in earnings) :{" "}
                    <span>
                      {singleWallet?.earningsLock ? "Locked" : "Allowed"}
                    </span>
                  </h4>
                </div>
                <div className="d-flex justify-content-end align-items-center col-md-6 col-12 ">
                  <AppSwitch
                    className={"mx-1"}
                    variant={"pill"}
                    color={"primary"}
                    name="earningsLock"
                    checked={singleWallet?.earningsLock}
                    value={!singleWallet?.earningsLock}
                    onChange={(e) =>
                      updateWalletStatus("earningsLock", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="row my-2">
                <div className="d-flex justify-content-left align-items-center col-md-6 col-12   ">
                  <h4 style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Coin balance Lock (to freeze the yinn coin balance) :{" "}
                    <span>
                      {singleWallet?.coinBalanceLock ? "Locked" : "Allowed"}
                    </span>
                  </h4>
                </div>
                <div className="d-flex justify-content-end align-items-center col-md-6 col-12 ">
                  <AppSwitch
                    className={"mx-1"}
                    variant={"pill"}
                    color={"primary"}
                    name="coinBalanceLock"
                    checked={singleWallet?.coinBalanceLock}
                    value={!singleWallet?.coinBalanceLock}
                    onChange={(e) =>
                      updateWalletStatus("coinBalanceLock", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="row my-2">
                <div className="d-flex justify-content-left align-items-center col-md-6 col-12   ">
                  <h4 style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Loan blanace lock (to freeze the loaned amount) :{" "}
                    <span>
                      {singleWallet?.loanedCoinBalanceLock
                        ? "Locked"
                        : "Allowed"}
                    </span>
                  </h4>
                </div>
                <div className="d-flex justify-content-end align-items-center col-md-6 col-12 ">
                  <AppSwitch
                    className={"mx-1"}
                    variant={"pill"}
                    color={"primary"}
                    name="loanedCoinBalanceLock"
                    checked={singleWallet?.loanedCoinBalanceLock}
                    value={!singleWallet?.loanedCoinBalanceLock}
                    onChange={(e) =>
                      updateWalletStatus(
                        "loanedCoinBalanceLock",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>

              <div className="row my-2">
                <div className="d-flex justify-content-left align-items-center col-md-6 col-12   ">
                  <h4 style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Ad spend Lock (to freeze the amount in ad spend) :{" "}
                    <span>
                      {singleWallet?.adSpendLock ? "Locked" : "Allowed"}
                    </span>
                  </h4>
                </div>
                <div className="d-flex justify-content-end align-items-center col-md-6 col-12 ">
                  <AppSwitch
                    className={"mx-1"}
                    variant={"pill"}
                    color={"primary"}
                    name="adSpendLock"
                    checked={singleWallet?.adSpendLock}
                    value={!singleWallet?.adSpendLock}
                    onChange={(e) =>
                      updateWalletStatus("adSpendLock", e.target.value)
                    }
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default WalletDetails;
