import Speech from "speak-tts";
try {
  var speech = new Speech();

  (async function () {
    try {
      await speech.init({
        volume: 1,
        lang: "en-GB",
        rate: 1,
        pitch: 1,
        voice: "Google UK English Male",
        splitSentences: true,
        listeners: {
          onvoiceschanged: (voices) => {
            console.log("Event voiceschanged", voices);
          },
        },
      });
    } catch (error) {
      console.log("An error occurred while initializing : ", error);
    }
  })();
} catch (error) {
  console.log("An error occurred while initializing : ", error);
}

export default speech;
