import React from "react";
import { Card } from "reactstrap";
import "./AccountDetails.css";
import { useSelector } from "react-redux";

const AccountDetails = ({ accountDetails, coins, totalPrice }) => {
  // const { usdRate, paymentConfiguration } = useSelector((state) => state.auth);
  // const { exchangeRate } = useSelector((state) => state.user);

  // useEffect(() => {
  //   if (coins !== "undefined") {
  //     const coinPrice =
  //       coins / (usdRate * 100) +
  //       (Number(paymentConfiguration?.yinncoinPurchasePercentageCharge) *
  //         coins) /
  //         (usdRate * 100) +
  //       paymentConfiguration?.coinMaintenanceCharge;
  //     setFinalPrice(coinPrice);
  //     // setCoinPrice(coinPrice);
  //   }
  // }, [usdRate]);

  const { amount, currency, payerInfo, localBankTransferTransaction } =
    accountDetails;
  const { localBankTransferInfo, orderId } = localBankTransferTransaction || {};

  const { translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const paymentInfoTranslation = translationState?.[0]?.translations?.paymentInfo?.[userLanguage];
  const shippingCartTranslation = translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const myCartTranslation = translationState?.[0]?.translations?.myCart?.[userLanguage];
  return (
    <Card className="mt-3">
      <div className="main-container">
        <h5>
          <strong>
          {paymentInfoTranslation?.dear ? paymentInfoTranslation?.dear : "Dear"} {payerInfo?.firstName} {payerInfo?.lastName}
            <br />
            <br />
            {paymentInfoTranslation?.orderHeading ? paymentInfoTranslation?.orderHeading +`${orderId}`  : `Your Order ${orderId} has been successfully created`}
            
          </strong>
        </h5>
        <p>
        {paymentInfoTranslation?.paraLineOne ? paymentInfoTranslation?.paraLineOne : 
        " Please continue and follow the instructions below to complete your order by Bank Transfer"}
         
        </p>
        <p>
        {paymentInfoTranslation?.paraLineTwo ? paymentInfoTranslation?.paraLineTwo : 
        "If you have any questions, you can contact customer support at anytime."}
          
        </p>
        <div className="main-account-details">
          <h3 className="mb-4">
            <strong>{paymentInfoTranslation?.transferThrough ? paymentInfoTranslation?.transferThrough :"Payment By Bank Transfer"}
            </strong>
          </h3>
          <ul className="account-details-ul">
            <li>
              <b>{paymentInfoTranslation?.amout ? paymentInfoTranslation?.amout :"Amount to Transfer :"}</b>
            </li>
            <li>
              {coins === "undefined"
                ? Number(amount)?.toFixed(2)
                : // : finalPrice?.toFixed(2)}{" "}
                  Number(totalPrice)?.toFixed(2)}{" "}
              {currency}
            </li>
          </ul>
          <ul className="account-details-ul">
            <li>
              <b>{paymentInfoTranslation?.accountNO ? paymentInfoTranslation?.accountNO :"Account Number :"}</b>
            </li>
            <li>{localBankTransferInfo?.bankAccountNumber}</li>
          </ul>
          <ul className="account-details-ul">
            <li>
              <b>{paymentInfoTranslation?.bandName ? paymentInfoTranslation?.bandName :"Bank Name :"}</b>
            </li>
            <li>{localBankTransferInfo?.bankName}</li>
          </ul>
          <ul className="account-details-ul">
            <li>
              <b>{paymentInfoTranslation?.bankCountry ? paymentInfoTranslation?.bankCountry :"Bank Country :"}</b>
            </li>
            <li>{localBankTransferInfo?.countryDescription}</li>
          </ul>
          <ul className="account-details-ul">
            <li>
              <b>{paymentInfoTranslation?.bankCity ? paymentInfoTranslation?.bankCity :"Bank City :"}</b>
            </li>
            <li>{localBankTransferInfo?.bankCity}</li>
          </ul>
          <ul className="account-details-ul">
            <li>
              <b>{paymentInfoTranslation?.bankCode ? paymentInfoTranslation?.bankCode :"Bank Code :"}</b>
            </li>
            <li>{localBankTransferInfo?.bankAccountNumber}</li>
          </ul>
          {/* <ul className="account-details-ul">
          <li>
            <b>IBAN :</b>
          </li>
          <li>{iban}</li>
        </ul> */}
          <ul className="account-details-ul">
            <li>
              <b>{paymentInfoTranslation?.swiftCode ? paymentInfoTranslation?.swiftCode :"Swiftcode :"}</b>
            </li>
            <li>{localBankTransferInfo?.swiftCode}</li>
          </ul>
          <ul className="account-details-ul">
            <li>
              <b>{paymentInfoTranslation?.paymentReference ? paymentInfoTranslation?.paymentReference :"Payment Reference :"}</b>
            </li>
            <li>{localBankTransferInfo?.paymentReference}</li>
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default AccountDetails;
