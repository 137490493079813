import React, { useState, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Label,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import "./privacyPoilcy.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClaimCopyright } from "../../../redux/reducers/auth";
import { AppHeader } from "@coreui/react";
import SkyLight from "react-skylight";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PrivacyPoilcy = () => {
  const dispatch = useDispatch();
  
  const { translationState } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];

  const copyRightModal = useRef(null);
  const copyrightTypes = [
    "has posted my copyrighted/trademarked work without my permission",
    "has violated my privacy by posting personal information, pictures, or other private data not in the public record",
    "has engaged in libel/slander/defamation against myself, my family, or my company",
    "has posted spam, pornography, or hate speech",
    "has made a false claim resulting in removal of my content",
  ];
  const [pageObject, setPageObject] = useState({
    name: "",
    email: "",
    address: "",
    contact: "",
    url: "",
    copyrights:
      "has posted my copyrighted/trademarked work without my permission",
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageObject({
      ...pageObject,
      [name]: value,
    });
  };
  const handleContactInfo = () => {
    const { name, email, address, contact, url } = pageObject;
    if (
      name == "" ||
      email == "" ||
      address == "" ||
      contact == "" ||
      url == ""
    ) {
      toast.error(`${toastTranslation?.requried ? toastTranslation?.requried : "All fields are required"}`);
      return;
    }
    dispatch(ClaimCopyright(pageObject));
    toast.success(`${toastTranslation?.success ? toastTranslation?.success :"We have sent you email please check the details."}`);
    copyRightModal.current.hide();
  };
  var PopupDialog = {
    top: "43%",
    width: "50%",
    height: "520px",
    overflow: "scroll",
  };
  return (
    <div className="app" style={{ "background-color": "white" }}>
      <ToastContainer autoClose={20000} />
      <Row>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </Row>
      <div className="container-fluid mt-100">
        <div className="col-12 col-lg-12">
          <div className="card">
            <div className="card-block clearfix">
              <i className="fa fa-flag bg-warning p-1 font-4xl mr-1 float-left"></i>
              <div className="h2 text-warning mb-0 mt-h">
                Yinn Privacy Poilcy
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Card>
            <CardBody>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                1. Scope
              </div>
              <div className="text-muted font-weight-bold font-xs">
                This Privacy Policy describes how we handle your personal
                information for our Service on the YINN websites, tools, and
                mobile applications. It applies generally to information
                collected on the YINN.ca website, mobile application and content
                (collectively, the "Site" or “Sites”) or though the use of our
                Service. Capitalized terms used in this Privacy Policy shall
                have the meaning set forth herein or in the User Agreement
                posted on the Site.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                2. Types of Data We Collect
              </div>
              <div className="text-muted font-weight-bold font-xs">
                We may collect and store the following Personal D
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Information You Provide to Us.
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ol>
                  <li>
                    Contact information, such as name, user ID, e-mail address,
                    and phone number
                  </li>
                  <li>
                    Information necessary for us to remit payments to sellers,
                    such as financial account transactional information based on
                    your activities on the Site as a seller (such as item and
                    content you generate or that relates to your account);
                    community discussions, chats, dispute resolution,
                    correspondence through our Site, and correspondence sent to
                    us; information we ask you to submit to authenticate
                    yourself or if we believe you are violating site policies
                    (for example, we may ask you to send us an ID or bill to
                    verify your address, or to answer additional questions
                    online to help verify your identity or ownership of an item
                    you list);
                  </li>
                  <li>
                    {" "}
                    Marketing information, such your preferences for receiving
                    marketing communications and details about how you engage
                    with marketing communications; and
                  </li>
                  <li>Other personal information you choose to submit to us</li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Information We Collect About Buyers From or on Behalf of Our
                Sellers
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ol>
                  <li>
                    We may collect information about buyers from or on behalf of
                    our sellers. The sellers determine the scope of the
                    information we collect on their behalf, that they transfer
                    to us, or that we otherwise collect, and the information we
                    receive may vary by seller. Typically, the information we
                    collect about individuals from or on behalf of our sellers
                    includes:
                  </li>
                  <li>
                    transactional information based on your activities on the
                    Site as a buyer (such as item and content you purchase)
                  </li>
                  <li>payment information, such as credit card number</li>
                  <li>
                    {" "}
                    your contact information (such as your name, user ID,
                    email-address, and shipping, billing and other information
                    you provide to purchase an item or receive delivery of an
                    item
                  </li>
                  <li>
                    other personal information that our sellers may provide
                    about y
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Information Automatically Collected.
              </div>
              <div className="text-muted font-weight-bold font-xs">
                When you access the Site, we, our service providers, and our
                business partners may automatically log information about you,
                your computer or mobile device, and activity on our Site. The
                information that may be collected automatically includes your
                computer or mobile device operating system type and version
                number, manufacturer and model, device identifier (such as the
                Google Advertising ID or Apple ID for Advertising), browser
                type, screen resolution, IP address, the website you visited
                before browsing to our Site, general location information such
                as city, state or geographic area; and information about your
                use of and actions on our Sites, such as pages you viewed, how
                long you spent on a page, navigation paths between pages,
                information about your activity on a page, access times, and
                length of access. Our service providers and business partners
                may collect this type of information over time and across
                third-party websites. This information is collected using
                cookies and similar technologies. Please refer to the Cookies
                and Similar Technologies section for more details
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Social Media Networks and other Third Party Platforms.
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ol>
                  <li>
                    We may offer single sign-on services that allow you to use
                    third party login credentials to sign into Service. With
                    your permission, YINN may also collect profile information
                    contained in your third-party profile. YINN may also, for
                    your use, enable you to import information about who you are
                    connected to, as well as enable you to share information
                    with those third-party sites. If you wish to discontinue
                    such sharing, please refer to the settings on the
                    third-party service ..
                  </li>
                  <li>
                    We may also maintain pages for our company and our products
                    on a variety of third-party platforms, such as Facebook,
                    Twitter, Google+, YouTube, Instagram, and other social
                    networking services. When you interact with our pages on
                    those third-party platforms, the thirdparty’s privacy policy
                    will govern your interactions on the relevant platform. If
                    the third party platform provides us with information about
                    our pages on those platforms or your interactions with them,
                    we will treat that information in accordance with this
                    Privacy Policy.
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Sensitive Personal Information.
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ol>
                  <li>
                    We ask that you not send us, and you not disclose, any
                    sensitive personal information (e.g., social security
                    numbers, information related to racial or ethnic origin,
                    political opinions, religion or other beliefs, health,
                    biometrics or genetic characteristics, criminal background
                    or trade union membership) on the Sites, or otherwise to us.
                  </li>
                  <li>
                    If you send or disclose any sensitive personal information
                    to us when you use the Services, you must consent to our
                    processing and use of such sensitive personal information in
                    accordance with this Privacy Policy. If you do not consent
                    to our processing and use of such sensitive personal
                    information, you must not submit such sensitive personal
                    information through our Sites.
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                3. Cookies and Similar Technologies
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                What are cookies?
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Cookies are small data files stored on your computer or mobile
                device by a website. Our Sites may use both session cookies
                (which expire once you close your web browser) and persistent
                cookies (which stay on your computer or mobile device until you
                delete them) to provide you with a more personal and interactive
                experience on our Site. We use two broad categories of cookies:
                (1) first party cookies, served directly by us to your computer
                or mobile device, which we use to recognize your computer or
                mobile device when it revisits our Sites; and (2) third party
                cookies, which are served by service providers or business
                partners on our Sites, and can be used by such service providers
                or business partners to recognise your computer or mobile device
                when it visits other websites.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Cookies we use
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Our Site uses the following types of cookies for the purposes
                set out below:
                <ol>
                  <li>
                    Essential Cookies - These cookies are essential to provide
                    you with services available through our Site and to enable
                    you to use some of its features. Without these cookies, the
                    services that you have asked for cannot be provided, and we
                    only use these cookies to provide you with those services.
                  </li>
                  <li>
                    Functionality Cookies - These cookies allow our Sites to
                    remember choices you make when you use our Sites. The
                    purpose of these cookies is to provide you with a more
                    personal experience and to avoid you having to re-select
                    your preferences every time you visit our Site.
                  </li>
                  <li>
                    Analytics and Performance Cookies - These cookies collect
                    information about traffic to our Sites and about how
                    individuals use our Sites. The information gathered may
                    include the types of information described above in the
                    section titled “Information automatically collected.” We use
                    this information to help operate our Site more efficiently,
                    to gather broad demographic information, monitor the level
                    of activity on our Site, and improve the Site. We use Google
                    Analytics, Optimizely, and New Relic for this purpose. These
                    parties use their own cookies. You can find out more
                    information about Google Analytics cookies here:
                    https://developers.google.com/analytics/resources/concepts/gaConceptsCookies,
                    and about how Google protects your data here:
                    www.google.com/analytics/learn/privacy.html. You can find
                    out more about New Relic’s services here:
                    https://newrelic.com/, and its privacy practices here:
                    https://newrelic.com/termsandconditions/privacy.
                  </li>
                  <li>
                    Social Media Cookies - These cookies are used when you share
                    information using a social media sharing button or “like”
                    (or similar) button on our Site or you link your account or
                    engage with our content on or through a social networking
                    website such as Facebook or Twitter. The social network will
                    record that you have done this. Social networks use their
                    own cookies.
                  </li>
                  <li>
                    Targeted and advertising cookies - These cookies track your
                    browsing habits to enable us and third-party advertising
                    networks to deliver ads that may be of interest to you.
                    These cookies use information about your browsing history to
                    group you with other users who have similar interests or
                    browsing behavior. Based on the cookies that the third-party
                    advertising network sets on our Site and other sites,
                    advertisers can display advertisements that may be relevant
                    to your interests on our Site and while you are on third
                    party websites. You can choose to disable cookies, as
                    described below, or to opt out of the use of your browsing
                    behaviour for purposes of targeted advertising. For opt out
                    instructions, please review the “Targeted online
                    advertising” portion of the “Your Choices” section of this
                    Privacy Policy."
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Disabling cookies
              </div>
              <div className="text-muted font-weight-bold font-xs">
                You can typically remove or reject cookies via your browser
                settings. In order to do this, follow the instructions provided
                by your browser (usually located within the “settings,” “help”
                “tools” or “edit” menus). Many browsers are set to accept
                cookies until you change your settings. For further information
                about cookies, including how to see what cookies have been set
                on your computer or mobile device and how to manage and delete
                them, visit www.allaboutcookies.org. If you do not accept our
                cookies, you may experience some inconvenience in your use of
                our Site. For example, we may not be able to recognize your
                computer or mobile device and you may need to log in every time
                you visit our Site.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Flash Technolo
              </div>
              <div className="text-muted font-weight-bold font-xs">
                We may use Flash cookies (which are also known as Flash Local
                Shared Object (“LSOs”)) on our Site to collect and store
                information about your use of our Site. Unlike other cookies,
                Flash cookies cannot be removed or rejected via your browser
                settings. If you do not want Flash cookies stored on your
                computer or mobile device, you can adjust the settings of your
                Flash player to block Flash LSO storage using the tools
                contained in the Website Storage Settings Panel at this website.
                You can also control Flash LSOs by going to the Global Storage
                Settings Panel at this website and following the instructions.
                Please note that setting the Flash Player to restrict or limit
                acceptance of Flash LSOs may reduce or impede the functionality
                of some Flash applications, including, potentially, Flash
                applications used in connection with our Site.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Pixel tags
              </div>
              <div className="text-muted font-weight-bold font-xs">
                We may also use pixel tags (which are also known as web beacons
                and clear GIFs) on our Site and in our HTML formatted emails to
                track the actions of users on our Site and interactions with our
                emails. Unlike cookies, which are stored on the hard drive of
                your computer or mobile device by a website, pixel tags are
                embedded invisibly on webpages or within HTML formatted emails.
                Pixel tags are used to demonstrate that a webpage was accessed
                or that certain content was viewed, typically to measure the
                success of our marketing campaigns or engagement with our emails
                and to compile statistics about usage of the Site, so that we
                can manage our content more effectively.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Software Development
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Our mobile applications may use software development kits
                (“SDKs”) provided by third parties. SDKs enable us to provide
                features and functionality developed by third-party developers,
                including to provide us with analytics, social media
                integration, and advertising. The SDKs we use may enable third
                parties to collect information about the users of our mobile
                applications. The types of SDKs we use include:
                <ol>
                  <li>
                    Analytics SDKs. These SDKs are used to collect information
                    about use of our mobile applications. The information
                    gathered may include the types of information described
                    above in the section titled “Information automatically
                    collected.” We use this information to help operate our
                    mobile applications more efficiently, to gather broad
                    demographic information, monitor the level of activity on
                    and within the application, diagnose errors, and improve the
                    mobile application.
                  </li>
                  <li>
                    Social Media SDKs. These SDKs help you to interact with
                    social networks you are signed into while using our mobile
                    application, such as by sharing content with the social
                    network and other features you use with the social network.
                    Social networks may also work with our apps for analytics or
                    advertising purposes, as discussed above.
                  </li>
                  <li>
                    Advertising SDKs. These SDKs allow our advertising partners
                    to collect information about your devices, and how you use
                    our mobile application and other sites and applications over
                    time; and to use this information to show you ads of
                    potential interest and measure how the ads perform. These
                    third parties’ collection, use, and sharing of your personal
                    information is subject to their own privacy policies. You
                    may be able to control or limit use of certain information
                    collected through advertising SDKs for purposes of targeted
                    advertising. For additional information, please review the
                    “Targeted online advertising” portion of the “Your Choices”
                    section of this Privacy Policy.
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                4. Use of Your Personal
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                To provide our Sites and Services.
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ol>
                  <li>
                    facilitate the creation of and secure your account on our
                    network
                  </li>
                  <li>identify you as a User in the sys</li>
                  <li>
                    send you a welcome e-mail to verify ownership of the e-mail
                    address provided when your account was created;
                  </li>
                  <li>
                    respond to your inquiries related to employment
                    opportunities or other requests;
                  </li>
                  <li>provide the Service and customer support you reque</li>
                  <li>
                    resolve disputes, collect fees, and troubleshoot problems;
                  </li>
                  <li>
                    prevent, detect, and investigate potentially prohibited or
                    illegal activities, and enforce our User Agreement;
                  </li>
                  <li>
                    customize, measure and improve our Service and content;
                  </li>
                  <li>
                    tell you about our Service, service updates, and promotional
                    offers based on your communication preferences;
                  </li>
                  <li>
                    compare information for accuracy, and verify it with third
                    partie
                  </li>
                  <li>
                    and other uses as described when we collect the information.
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                To communicate with you
              </div>
              <div className="text-muted font-weight-bold font-xs">
                If you request information from us (such as signing up for our
                newsletter), register on the Sites, or participate in our
                contests or promotions, we may send you YINN-related marketing
                communications as permitted by law. You will have the ability to
                opt out of such communications
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Use of Personal Data About Buyers on Behalf of Our Sellers.
              </div>
              <div className="text-muted font-weight-bold font-xs">
                We use personal information we collect about buyers from or on
                behalf of our sellers to provide services only as directed or
                authorized by the seller. We do not use this information for our
                own purposes. Typically, our clients direct or authorize us to
                use personal information collected on their behalf to enable
                ecommerce and payments functionality on our clients’ websites,
                to manage the delivery of electronic goods, and to deliver
                communications from the sellers to their buyers.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                To comply with law.
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ul>
                  <li>
                    We use your personal information as we believe necessary or
                    appropriate to comply with applicable laws, lawful requests
                    and legal process, such as to respond to subpoenas or
                    requests from government authorities.
                  </li>
                  <li>For compliance, fraud prevention and safety</li>
                  <li>
                    We use your personal information as we believe necessary or
                    appropriate to (a) enforce the terms and conditions that
                    govern the Sites; (b) protect our rights, privacy, safety or
                    property, and/or that of you or others; and (c) protect,
                    investigate and deter against fraudulent, harmful,
                    unauthorized, unethical or illegal activity.
                  </li>
                </ul>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                With your consent.
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ul>
                  <li>
                    We will request your consent to use your personal
                    information where required by law, such as where we would
                    like to send you certain marketing messages. If we request
                    your consent to use your personal information, you have the
                    right to withdraw your consent any time in the manner
                    indicated when we requested the consent or by contacting us.
                    If you have consented to receive marketing communications
                    from our third party partners, you may withdraw your consent
                    by contacting those partners directly
                  </li>
                </ul>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                To create anonymous data
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ul>
                  <li>
                    We may create Anonymous Data records from Personal Data by
                    excluding information that make the data personally
                    identifiable to you. We use this Anonymous Data to analyze
                    request and usage patterns so that we may enhance the
                    content of our Service and improve site navigation. We
                    reserve the right to use Anonymous Data for any purpose and
                    disclose Anonymous Data to third parties in our sole
                    discretion.
                  </li>
                </ul>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                5. Our Disclosure of Your Personal Data
              </div>
              <div className="text-muted font-weight-bold font-xs">
                We may disclose Personal Data to respond to legal requirements,
                enforce our policies, respond to claims that a listing or other
                content violates the rights of others, or protect anyone's
                rights, property, or safety. Such information will be disclosed
                in accordance with applicable laws and regulations. We may also
                share your Personal Data with:
                <ul>
                  <li>
                    Service providers under contract who help with our business
                    operations (such as fraud investigations, bill collection,
                    affiliate and rewards programs).
                  </li>
                  <li>
                    Our subsidiaries and corporate affiliates for purposes
                    consistent with this Privacy Policy
                  </li>
                  <li>
                    Our sellers, when you make a purchase with them using our
                    Services.
                  </li>
                  <li>
                    Other third parties to whom you explicitly ask us to send
                    your information (or about whom you are otherwise explicitly
                    notified and consent to when using a specific service).
                  </li>
                  <li>
                    A third party payments processor to process payments made to
                    us. Currently, we use Braintree & Paypal to process our
                    payments. Braintree & Paypal may use personal information it
                    collects when processing our payments as directed by us and
                    for certain additional purposes. The information that
                    Braintree & Paypal obtain in connection with processing our
                    payments is subject to Braintree’s Privacy Policy.
                  </li>
                  <li>
                    Professional advisors, such as lawyers, bankers, auditors
                    and insurers, where necessary in the course of the
                    professional services that they render to us.
                  </li>
                  <li>
                    Law enforcement, governmental agencies, or authorized
                    third-parties, in response to a verified request relating to
                    a criminal investigation or alleged illegal activity or any
                    other activity that may expose us, you, or any other YINN
                    User to legal liability. In such events, we will disclose
                    information relevant to the investigation, such as name,
                    city, state, ZIP code, telephone number, e-mail address,
                    User ID history, IP address, fraud complaints, bidding and
                    listing history, and anything else we may deem relevant to
                    the investigation
                  </li>
                  <li>
                    Other business entities with which we perform a business
                    transaction (or potential business transaction), such as a
                    merger, consolidation, or acquisition, in which case we will
                    make reasonable efforts to require that the recipient follow
                    this Privacy Policy with respect to your Personal Data. In
                    the event of an insolvency, bankruptcy or receivership, your
                    Personal Data may also be transferred as a business asset.
                  </li>
                </ul>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                6. Account Protection
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Your password is the key to your account. Use unique numbers,
                letters and special characters, and do not disclose your YINN
                password to anyone. If you do share your password or your
                Personal Data with others, remember that you are responsible for
                all actions taken in the name of your account. If you lose
                control of your password, you may lose substantial control over
                your Personal Data and may be subject to legally binding actions
                taken on your behalf. Therefore, if your password has been
                compromised for any reason, you should immediately notify YINN
                and change your password.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                7. Your Choices
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Accessing, Reviewing and Changing Your Personal Data
              </div>
              <div className="text-muted font-weight-bold font-xs">
                You may change any of your Personal Data in your account by
                editing your profile within your account or by sending an e-mail
                to us at the e-mail address set forth below. You may request
                deletion of your Personal Data by us, but please note that we
                may be required to keep this information and not delete it (or
                to keep this information for a certain time, in which case we
                will comply with your deletion request only after we have
                fulfilled such requirements). Upon your request, we will close
                your account and remove your Personal Data from view as soon as
                reasonably possible, based on your account activity and in
                accordance with applicable law. We do retain Personal Data from
                closed accounts to comply with law, prevent fraud, collect any
                fees owed, resolve disputes, troubleshoot problems, assist with
                any investigations, enforce our User Agreement, and take other
                actions otherwise permitted by law
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Marketing communications
              </div>
              <div className="text-muted font-weight-bold font-xs">
                You may opt out of marketing-related emails by clicking on a
                link at the bottom of our marketing emails, or by contacting us
                at support@ yinn.ca. You may continue to receive service-related
                and other non-marketing emails.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Targeted online advertising
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Some of the business partners that collect information about
                users’ activities on our Sites may be members of organizations
                or programs that provide choices to individuals regarding the
                use of their browsing behavior or mobile application usage for
                purposes of targeted advertising. Users may opt out of receiving
                targeted advertising on websites through members of the Network
                Advertising Initiative by clicking here:
                www.networkadvertising.org/choices, or the Digital Advertising
                Alliance by clicking here: www.aboutads.info/choices. European
                users may opt out of receiving targeted advertising on websites
                through members of the European Interactive Digital Advertising
                Alliance by clicking here: https://www.youronlinechoices.eu/,
                selecting the user’s country, and then clicking “Choices” (or
                similarly-titled link). Mobile app users may opt out of
                receiving targeted advertising in mobile apps through
                participating members of the Digital Advertising Alliance by
                installing the AppChoices mobile app, available here:
                https://www.youradchoices.com/appchoices, and selecting the
                user’s choices. Please note that we also may work with companies
                that offer their own opt-out mechanisms and may not participate
                in the opt-out mechanisms that we linked above. If you choose to
                opt-out of targeted advertisements, you will still see
                advertisements online but they may not be relevant to you. Even
                if you do choose to opt out, not all companies that serve online
                behavioural advertising are included in this list, and so you
                may still receive some cookies and tailored advertisements from
                companies that are not listed. In addition, your mobile device
                settings may provide to limit our, or our partners’, ability to
                engage in ad tracking or targeted advertising using the Google
                Advertising ID or Apple ID for Advertising associated with your
                mobile device.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Do Not Track Signals
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Some Internet browsers may be configured to send "Do Not Track"
                signals to the online services that you visit. We currently do
                not currently respond to do not track signals. To find out more
                about "Do Not Track," please visit http://www.allaboutdnt.com.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Choosing not to share your personal information
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Where we are required by law to collect your personal
                information, or where we need your personal information in order
                to provide our services to you, if you do not provide this
                information when requested (or you later ask to delete it), we
                may not be able to provide you with our services. We will tell
                you what information you must provide to receive the services by
                designating it as required in or on the Sites or through other
                appropriate means.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                8. Security
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Your information is stored on our servers located in the United
                States. We use a variety of security technologies and procedures
                to help protect your Personal Data from unauthorized access, use
                or disclosure. However, as you probably know, third parties may
                unlawfully intercept or access transmissions or private
                communications, and other Users may abuse or misuse your
                Personal Data that they collect from the Site. Therefore, we do
                not promise, and you should not expect, that your Personal Data
                or private communications will always remain private.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                9. International Transfer
              </div>
              <div className="text-muted font-weight-bold font-xs">
                YINN is headquartered in Canada and has service providers in
                other countries, and your personal information may be
                transferred to Canada , the United States or other locations
                outside of your state, province, country or other governmental
                jurisdiction where privacy laws may not be as protective as
                those in your jurisdiction. European Union users should read the
                important information provided here about transfer of personal
                information outside of the European Economic Area:
                https://www.dataprivacymonitor.com/cybersecurity/cross-border-data-transfers-cutting-throughthe-complexity/.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                10. Third Parties
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Except as otherwise expressly included in this Privacy Policy,
                this document addresses only the use and disclosure of
                information we collect from you. If you disclose your
                information to others, whether they are bidders, buyers or
                sellers on our Site or other sites throughout the Internet,
                different rules may apply to their use or disclosure of the
                information you disclose to them. YINN does not control the
                privacy policies of third parties, and you are subject to the
                privacy policies of those third parties where applicable. We
                encourage you to ask questions before you disclose your Personal
                Data to others.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                11. Changes to this Privacy Policy
              </div>
              <div className="text-muted font-weight-bold font-xs">
                We reserve the right to modify this Privacy Policy at any time.
                We encourage you to periodically review this page for the latest
                information on our privacy practices. If we make material
                changes to this Privacy Policy, we will notify you by updating
                the date of this Privacy Policy and posting it on the Sites . We
                may (and, where required by law, will) also provide notification
                of changes in another way that we believe is reasonably likely
                to reach you, such as via e-mail (if you have an account where
                we have your contact information) or another manner through the
                Sites. Any modifications to this Privacy Policy will be
                effective upon our posting of the new terms and/or upon
                implementation of the new changes on the Sites (or as otherwise
                indicated at the time of posting). In all cases, your continued
                use of the Sites after the posting of any modified Privacy
                Policy indicates your acceptance of the terms of the modified
                Privacy Policy.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                12. Contact Us
              </div>
              <div className="text-muted font-weight-bold font-xs">
                If you have any questions or concerns at all about our Privacy
                Policy, please feel free to email us at support@ yinn.ca
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                13. Additional Information for European Union Users
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Personal information
              </div>
              <div className="text-muted font-weight-bold font-xs">
                References to “personal information” in this Privacy Policy are
                equivalent to “personal data” governed by European data
                protection legislation.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Controller
              </div>
              <div className="text-muted font-weight-bold font-xs">
                For purposes of European data protection legislation, YINN GROUP
                INC.. is the controller of personal information that we collect
                for our own business purposes. See the Contact Us section above
                for contact details. YINN acts as a processor to sellers through
                the Services, as described throughout this Privacy Policy. When
                we act as a processor to a seller, the relevant seller is the
                data controller of your personal information processed in
                connection with the sale or delivery of goods to you.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Legal bases for processing
              </div>
              <div className="text-muted font-weight-bold font-xs">
                We use your personal information only as permitted by law. We
                are required to inform you of the legal bases of our processing
                of your personal information, which are described in the table
                below. If you have questions about the legal basis of how we
                process your personal information, contact us at
                support@yinn.ca.
                <ol>
                  <li>
                    To provide our Sites and Services - Processing is necessary
                    to perform the contract governing our provision of the
                    Services or to take steps that you request prior to signing
                    up for the Services.
                  </li>
                  <li>
                    To communicate with you, To create anonymous data, For
                    compliance, fraud prevention and safety - These processing
                    activities constitute our legitimate interests. We make sure
                    we consider and balance any potential impact on you (both
                    positive and negative) and your rights before we process
                    your personal information for our legitimate interests. We
                    do not use your personal information for activities where
                    our interests are overridden by the impact on you (unless we
                    have your consent or are otherwise required or permitted to
                    by law).
                  </li>
                  <li>
                    To comply with law - Processing is necessary to comply with
                    our legal obligations
                  </li>
                  <li>
                    With your consent - Processing is based on your consent.
                    Where we rely on your consent you have the right to withdraw
                    it anytime in the manner indicated in the Service or by
                    contacting us at support@ yinn.ca.
                  </li>
                  <li>
                    Use of Personal Data on Behalf of Our Sellers - We use
                    personal data to comply with the instructions of the data
                    controller, our sellers and, to the extent necessary, to
                    perform the contract governing our provision of the Services
                    or to take steps that you request prior to signing up for
                    the Services.
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Use for new purposes
              </div>
              <div className="text-muted font-weight-bold font-xs">
                When we act as a data controller, we may use your personal
                information for reasons not described in this Privacy Policy
                where permitted by law and the reason is compatible with the
                purpose for which we collected it. If we need to use your
                personal information for an unrelated purpose, we will notify
                you and explain the applicable legal basis.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Retention
              </div>
              <div className="text-muted font-weight-bold font-xs">
                We will only retain your personal information for as long as
                necessary to fulfill the purposes for which we collected it,
                including for the purposes of satisfying any legal, accounting,
                or reporting requirements. To determine the appropriate
                retention period for personal information, we consider the
                amount, nature, and sensitivity of the personal information, the
                potential risk of harm from unauthorized use or disclosure of
                your personal information, the purposes for which we process
                your personal information and whether we can achieve those
                purposes through other means, and the applicable legal
                requirements. When we no longer require the personal information
                we have otherwise collected about you, we will dissociate such
                information from the information attached to your content. In
                some circumstances we may anonymize your personal information
                (so that it can no longer be associated with you), in which case
                we may use this information indefinitely without further notice
                to you.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Your rights
              </div>
              <div className="text-muted font-weight-bold font-xs">
                European data protection laws give European Union users certain
                rights regarding their personal information. If you are located
                within the European Union, you may ask us to take the following
                actions in relation to your personal information that we hold in
                the capacity of a data controller:
                <ul>
                  <ol>
                    Opt-out. Stop sending you direct marketing communications.
                    You may continue to receive service-related and other
                    non-marketing emails.
                  </ol>
                  <ol>
                    Access. Provide you with information about our processing of
                    your personal information and give you access to your
                    personal information.
                  </ol>
                  <ol>
                    Correct. Update or correct inaccuracies in your personal
                    information.
                  </ol>
                  <ol>Delete. Delete your personal information.</ol>
                  <ol>
                    Transfer. Transfer a machine-readable copy of your personal
                    information to you or a third party of your choice.
                  </ol>
                  <ol>
                    Restrict. Restrict the processing of your personal
                    information.
                  </ol>
                  <ol>
                    Object. Object to our reliance on our legitimate interests
                    as the basis of our processing of your personal information.
                  </ol>
                </ul>
                You can submit these requests by email to support@ yinn.ca or
                our postal address provided above on our website We may request
                specific information from you to help us confirm your identity
                and process your request. Applicable law may require or permit
                us to decline your request. If we decline your request, we will
                tell you why, subject to legal restrictions. If you would like
                to submit a complaint about our use of your personal information
                or response to your requests regarding your personal
                information, you may contact us as described above or submit a
                complaint to the data protection regulator in your jurisdiction.
                You can find your data protection regulator here. To the extent
                we act as a processor on behalf of a seller, individuals should
                contact the relevant seller to exercise the rights and choices
                described in this section.
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                Cross-Border Data Transfer
              </div>
              <div className="text-muted font-weight-bold font-xs">
                Whenever we transfer your personal information out of the EEA to
                countries not deemed by the European Commission to provide an
                adequate level of personal information protection, the transfer
                will be based:
                <ul>
                  <ol>
                    Pursuant to the recipient’s compliance with standard
                    contractual clauses, EU-US Privacy Shield, or Binding
                    Corporate Rules
                  </ol>
                  <ol>
                    Pursuant to the consent of the individual to whom the
                    personal information pertains
                  </ol>
                  <ol>
                    As otherwise permitted by applicable EEA requirements.
                  </ol>
                </ul>
                Please contact us if you want further information on the
                specific mechanism used by us when transferring your personal
                information out of the EEA.
              </div>
              <b>This privacy policy is effective on March , 20, 2022 . </b>
            </CardBody>
          </Card>
        </div>
      </div>
      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

export default PrivacyPoilcy;
