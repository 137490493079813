import React, { useEffect, useState, useRef } from "react";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addFilter,
  createPayoutRequest,
  getPayouts,
} from "../../redux/reducers/seller";
import { getWallet } from "../../redux/reducers/user";
import { approvePayment } from "../../redux/reducers/admin";
import { ToastContainer, toast } from "react-toastify";
import SkyLight from "react-skylight";
import moment from "moment";
import "./payout.css";
import { nextPagesItems } from "../../helpers/custom";

var divStyle = {
  marginLeft: "10%",
};

const Payout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const sellerTranslation =
    translationState?.[0]?.translations?.seller?.[userLanguage];
  const eVoucherTranslation =
    translationState?.[0]?.translations?.eVoucher?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const missedTranslation =
    translationState?.[0]?.translations?.missed?.[userLanguage];
  const microLoanTranslation =
    translationState?.[0]?.translations?.microLoan?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];

  const modal = useRef(null);
  const { allPayouts, filters, count, loading } = useSelector(
    (state) => state.seller
  );
  const { wallet } = useSelector((state) => state.user);
  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const [pageContent, setPageContent] = useState({
    amount: 10,
  });
  const [dropDownState, setDropDownState] = useState("Earning");
  const handleDropdownChange = (e) => {
    setDropDownState(e.target.value);
  };
  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    setSelectedPerPage(5);
    dispatch(getPayouts());
    dispatch(getWallet());
  }, []);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };

  const oncreatePayoutRequest = () => {
    modal.current.show();
  };

  const onSubmitPayout = async () => {
    const { amount } = pageContent;
    let data;
    if (dropDownState == "yinnCoin") {
      if (wallet.coinBalance < amount) {
        return toast.error(
          `${
            toastTranslation?.lowBlance
              ? toastTranslation?.lowBlance
              : "Wallet doesn't have enough coins"
          }`
        );
      } else {
        data = {
          amount: amount / 100,
          amountType: "yinnCoin",
        };
      }
      dispatch(createPayoutRequest(data)).then(() => {
        dispatch(getPayouts());
        dispatch(getWallet());
      });
      setPageContent({
        amount: 10,
      });
      modal.current.hide();
    } else if (amount < 0 || wallet.earnings < amount) {
      toast.error(
        `${
          toastTranslation?.lowBlance
            ? toastTranslation?.lowBlance
            : "Wallet doesn't have enough coins"
        }`
      );
    } else {
      data = {
        amount,
        amountType: "earnings",
      };
      dispatch(createPayoutRequest(data)).then(() => {
        dispatch(getPayouts());
        dispatch(getWallet());
      });
      setPageContent({
        amount: 10,
      });
      modal.current.hide();
    }
  };

  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getPayouts());
  };

  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getPayouts());
      dispatch(getWallet());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getPayouts());
    dispatch(getWallet());
  };

  const updateStatus = (id, status) => {
    const data = {
      id,
      status,
    };
    dispatch(approvePayment(data)).then(() => {
      toast.success(
        `${
          toastTranslation?.success
            ? toastTranslation?.success
            : "Payout Updated"
        }`
      );
      dispatch(getPayouts());
    });
  };
  var myBigGreenDialog = {
    minWidth: "40%",
    minHeight: "20%",
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={modal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>
                {sellerTranslation?.Payouts
                  ? sellerTranslation?.Payouts
                  : "Payout"}
              </h4>
            </b>
          </p>
        }
      >
        <div>
          <FormGroup>
            <div className="d-flex justify-content-between">
              <Label>
                {walletTranslation?.amount
                  ? walletTranslation?.amount
                  : "Amount"}
              </Label>
              <div>
                <select onChange={handleDropdownChange}>
                  <option value="Earning" selected>
                    Earning
                  </option>
                  <option value="yinnCoin">Yinn Coin</option>
                </select>
              </div>
            </div>
            <Row>
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  placeholder={
                    walletTranslation?.amount
                      ? walletTranslation?.amount
                      : "Amount"
                  }
                  name="amount"
                  value={pageContent.amount}
                  onChange={handleInputChange}
                  type="number"
                />
              </InputGroup>
            </Row>
          </FormGroup>
          <Row>
            <Button
              onClick={onSubmitPayout}
              color="warning"
              style={divStyle}
              btn="sm"
            >
              <b>
                {missedTranslation?.create
                  ? missedTranslation?.create
                  : "Create Request"}
              </b>
            </Button>
          </Row>
        </div>
      </SkyLight>
      <Row className="">
        <Col className="padding-remove">
          <Card className="mx-2">
            <CardHeader>
              <Row>
                <Col xs="12" sm="9">
                  <b>
                    {sellerTranslation?.Payouts
                      ? sellerTranslation?.Payouts
                      : "Payouts"}
                  </b>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              {(userType == "seller" || userType == "user") && (
                <Row>
                  <Col xs="12" sm="4">
                    <InputGroup>
                      <Button
                        color="warning"
                        style={{ divStyle, marginLeft: "-10px" }}
                        btn="sm"
                        onClick={oncreatePayoutRequest}
                      >
                        <b>
                          {sellerTranslation?.Payouts
                            ? sellerTranslation?.Payouts
                            : "Request Payout"}
                        </b>
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              )}

              {userType == "admin" && (
                <Row>
                  <Col xs="12" sm="4">
                    <InputGroup>
                      <Input
                        type="text"
                        id="input1-group2"
                        onChange={searchElement}
                        className="search-color"
                        name="input1-group2"
                        placeholder=" Search by email/name"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              )}

              <br />
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color">
                    <th>
                      {userDashboardTranslation?.id
                        ? userDashboardTranslation?.id
                        : "Payout ID"}{" "}
                    </th>
                    {userType == "admin" && <th> Email </th>}
                    <th>
                      {" "}
                      {microLoanTranslation?.amount
                        ? microLoanTranslation?.amount
                        : "Amount"}{" "}
                    </th>
                    <th>
                      {" "}
                      {microLoanTranslation?.status
                        ? microLoanTranslation?.status
                        : "Status"}
                    </th>
                    <th>
                      {" "}
                      {missedTranslation?.process
                        ? missedTranslation?.process
                        : "Processig"}
                    </th>
                    <th>
                      {" "}
                      {eVoucherTranslation?.createDate
                        ? eVoucherTranslation?.createDate
                        : "Created Date"}
                    </th>
                    {userType == "admin" && <th> Action </th>}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={6}>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : allPayouts.length > 0 && !loading ? (
                    allPayouts.map((item, ind) => (
                      <tr align="center" key={ind}>
                        <td> {item?._id} </td>
                        {userType == "admin" && (
                          <td> {item?.userId?.email} </td>
                        )}
                        <td> {item?.amount} </td>
                        <td> {item?.status} </td>
                        <td>{item?.processingInfo?.processingStatus || "-"}</td>
                        <td>{moment(item.createdAt).format("MMM-D-YYYY")}</td>
                        {userType == "admin" && (
                          <td>
                            <select
                              type="select"
                              className="order-status-input"
                              name="country"
                              value={item.status}
                              disabled={item.status !== "Pending"}
                              onChange={(e) =>
                                updateStatus(item._id, e.target.value)
                              }
                            >
                              {["Pending", "Approved", "Rejected"].map(
                                (item, index) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                )
                              )}
                            </select>{" "}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <td colSpan={6} className="py-5 text-center">
                      <p style={{ color: "red" }}>
                        {digitalProductTranslation?.notAvailable
                          ? digitalProductTranslation?.notAvailable
                          : "Records Not Found"}
                      </p>
                    </td>
                  )}
                </tbody>
              </Table>
              <nav>
                <Pagination className="mt-3">
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo - 1)}
                      disabled={pageNo === 1 ? true : false}
                      style={{
                        backgroundColor:
                          (pageNo === 1 ? true : false) && "#e4e7ea",
                      }}
                    >
                      {reportingTranslation?.previousButton
                        ? reportingTranslation?.previousButton
                        : "Prev"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => paginate(pageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: !clickableNext ? "#e4e7ea" : null,
                      }}
                      className="mx-2"
                    >
                      {reportingTranslation?.nextButton
                        ? reportingTranslation?.nextButton
                        : "Next"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        {productTranslation?.itemPerPage
                          ? productTranslation?.itemPerPage
                          : "Items per Page"}
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, pageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Payout;
