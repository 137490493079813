import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./FeaturedCarouselItems.css";
import plusButton from "../../../assets/img/plus-button.svg";
import { Button, Col, Row } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import { useSelector } from "react-redux";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const FeaturedCarouselItem = (props) => {
  const {translationState , allWebContent} = useSelector((state) => state.user)
  const {userLanguage} = useSelector((state) => state.auth);
  const homeFeature = allWebContent?.[0]?.homeFeature;
  const homeFeatureTranslation = translationState?.[0]?.translations?.homeFeature?.[userLanguage]

  const history = useHistory();
  const { user } = useSelector(
    (state) => state.auth
  );
  const { sliderItems, viewProduct } = props;

  const settings = {
    speed: 500,
    slidesToShow: sliderItems.length >= 4 ? 4 : sliderItems.length,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid position-relative mt-5 pt-xl-5">
      <div className="slick-container">
        <h2 className="header-font font-weight-light text-center mb-3 mb-md-4 mb-xl-5">
          {" "}
          {homeFeatureTranslation?.featured ? homeFeatureTranslation?.featured : homeFeature?.featured}
          {/* Featured in our platform */}
          {" "}
        </h2>
        <div className="">
          <Slider className="features" {...settings}>
            {sliderItems.map((item, index) => {
              return (
                <div className="featured-carousel">
                  {/* image */}
                  <div className="">
                    <img
                      className="featured-img"
                      src={item.images[0]}
                      alt="Avatar"
                    />
                  </div>

                  {/* footer */}
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pl-3">
                      <div className="featured-name-text">{item.name}</div>
                      <div className="featured-brand-text">{item.brand}</div>
                    </div>
                    <div>
                      <a
                        type="button"
                        onClick={() => {
                          viewProduct(item, user);
                        }}
                        className=""
                      >
                        <img style={{ width: 66 }} src={plusButton} />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="mx-auto text-center pt-5 pb-1">
          <button
            onClick={() => history.push("/search")}
            className="btn black-bg-btn button-text py-2 px-4"
          >
          {homeFeatureTranslation?.buttonFeatured ? homeFeatureTranslation?.buttonFeatured : homeFeature?.buttonFeatured}

            {/* Shop More */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCarouselItem;
