import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SetState,
  getPlatformCharges,
  deleteAdsCharges,
} from "../../redux/reducers/admin";
import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
const Fees = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const { platformCharges } = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(getPlatformCharges());
  }, []);
  let state = {
    charges: [],
    chargeValid: true,
  };
  const deleteCharge = () => {
    dispatch(deleteAdsCharges(currentId));
    dispatch(getPlatformCharges());
    setIsDeleteModal(false);
  };

  const deleteConfirm = (id) => {
    setCurrentId(id);
    setIsDeleteModal(true);
  };
  const hideModal = () => {
    setIsDeleteModal(false);
  };

  const editCharge = (id) => {
    history.push(`/admin/fees/edit?chargeId=${id}`);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <Row>
            <Col xs="8" sm="8" md="9">
              <i className="fa fa-align-justify"></i>{" "}
              <strong>Platform Charges List</strong>
            </Col>
            <Col xs="4" sm="4" md="3">
              <Button
                onClick={() => history.push("/admin/fees/add")}
                size="md"
                color="warning"
              >
                <b> Add Charge </b>
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Table hover bordered striped responsive size="sm">
            <thead align="center">
              <tr className="header-color" align="center">
                <th> Type </th>
                <th> Percentage </th>
                <th> Updated At </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {platformCharges.length > 0
                ? adchplatformChargesarge.map((charge, index) => (
                    <tr>
                      <td align="center"> {charge.type}</td>
                      <td align="center"> {charge.percentage}</td>
                      <td align="center">{charge.updatedAt}</td>
                      <td align="center">
                        <i
                          className="fa fa-edit fa-lg mt-4"
                          onClick={(e) => editCharge(charge._id)}
                        ></i>
                        &nbsp;
                        <i
                          className="fa fa-trash fa-lg mt-4"
                          onClick={(e) => deleteConfirm(charge._id)}
                        ></i>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>

            <Modal
              isOpen={isDeleteModal}
              className={"modal-danger " + props.className}
            >
              <ModalHeader>Delete Fee Charges</ModalHeader>
              <ModalBody>
                <b> Do you really want to delete this Fee Charge? </b>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={(e) => deleteCharge()}>
                  Yes
                </Button>{" "}
                <Button color="secondary" onClick={(e) => hideModal()}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default Fees;
