import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getWallet } from "../../../redux/reducers/user";
import confirmationSrc from "../../../assets/img/yinn-coin/success.svg";

const Confirmation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];
  const myCartTranslation =
    translationState?.[0]?.translations?.myCart?.[userLanguage];

  const { wallet } = useSelector((state) => state.user);
  const { coins } = props;
  useEffect(() => {
    dispatch(getWallet());
  }, []);
  return (
    <div className="confirmation-container">
      <div className="confirmation-main-title">
        {userDashboardTranslation?.successfully
          ? userDashboardTranslation?.successfully
          : "Successfully!"}
      </div>
      <div className="confirmation-sub-title">
        {userDashboardTranslation?.yourCoin
          ? userDashboardTranslation?.yourCoin
          : "Your coins will be added to your wallet after payment is done"}
      </div>
      <img className="confirmed-main-icon" src={confirmationSrc} />
      <div className="confirmation-helper-tex"></div>
      <button
        className="shop-more-button"
        onClick={() => {
          history.push("/search");
        }}
      >
        {myCartTranslation?.shopMore
          ? myCartTranslation?.shopMore
          : "Shop More"}
      </button>
    </div>
  );
};
export default Confirmation;
