import React, { useEffect } from "react";
import image from "../../assets/img/home/financial.png";
import { useSelector , useDispatch } from "react-redux";

const BrowseProduct = () => {
  const dispatch = useDispatch()


  return (
    <>
      <div
        style={{
          marginTop: "50px",
          marginLeft: "50px",
        }}
      >
        <div className="row">
          {/* {filteredproduct?.map((prod) => {
            return prod.map((i) => {
              return (
                <div className="col-4">
                  <div class="card">
                    <img
                      class="card-img-top"
                      src={i.product.images[0]}
                      alt="Card image cap"
                      style={{height:'200px'}}
                    />
                    <div class="card-body row">
                      <div className="col-7">
                        <h5 class="card-title">{i.product.name}</h5>
                      </div>
                      <div className="col-5">
                        <button className="button-color">Download</button>{" "}
                      </div>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                      </p>
                    </div>
                  </div>
                </div>
              );
            });
          })}
           */}
           ok
        </div>
      </div>
    </>
  );
};

export default BrowseProduct;
