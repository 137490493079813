import React, { memo, useEffect, useState } from "react";
import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAds,
  updateAd,
  addFilter,
  getProducts,
} from "../../../redux/reducers/auth";
import plusButton from "../../../assets/img/plus-button.svg";
import { MiniRatingWidget } from "./RatingWidget";
import { nextPagesItems } from "../../../helpers/custom";
import {
  PAGINATION_MODEL,
  PER_PAGE,
  PER_PAGE_OPTIONS,
} from "../../../constants";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./productdetail.css";
import { IoMdAdd } from "react-icons/io";

const RelatedProducts = (props) => {
  const { type, products } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, currentIP, userLanguage, filters, count } = useSelector(
    (state) => state.auth
  );
  const { translationState } = useSelector((state) => state.user);
  const [pagination, setPagination] = useState(PAGINATION_MODEL);
  const [selectedPerPage, setSelectedPerPage] = useState(PER_PAGE);
  const [relatedData, setRelatedData] = useState({
    products: [],
  });

  const productDetailTranslation =
    translationState?.[0]?.translations?.productDetail?.[userLanguage];
  const nextCount = nextPagesItems(
    Math.abs(pagination.pageNo - 1),
    pagination.perPage,
    count
  );
  const clickableNext = nextCount > pagination.perPage;

  useEffect(() => {
    if (filters?.length) {
      const { perPage } =
        filters?.find((item) => item.field === "perPage")?.value || {};
      const { pageNo } =
        filters?.find((item) => item.field === "pageNo")?.value || {};
      setPagination({ ...pagination, pageNo, perPage });
    }
  }, [filters]);

  useEffect(() => {
    setRelatedData({
      ...relatedData,
      products: products?.length ? products : [],
    });
  }, [products]);

  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(addFilter({ field: "pageNo", value: pageNo }));
      dispatch(getProducts());
    }
  };
  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(addFilter({ field: "pageNo", value: pageNo }));
    dispatch(addFilter({ field: "perPage", value: Number(e.target.value) }));
    dispatch(getProducts());
  };
  const viewProduct = async (item, user) => {
    const ip = item?.ips?.findIndex((ip) => ip === currentIP);
    let id = 0;
    if (user !== null) {
      id = item?.userIds?.findIndex((id) => id === user?._id);
    }
    if (ip === -1 || id === -1) {
      const data = {
        productId: item?.productId?._id,
        currentIP,
        userId: user?._id || null,
      };
      await dispatch(updateAd(data));
      dispatch(getAds());
    }
    if (id == null) {
      history.push(
        `/${item?.type == "digital" ? "digitalproduct" : "productdetail"}/${
          item?._id
        }`
      );
    } else {
      history.push(
        `/${
          item?.productId.type == "digital" ? "digitalproduct" : "productdetail"
        }/${item?.productId._id}`
      );
    }
  };

  return (
    <>
      <Row className="mx-5">
        <div style={{ alignItems: "start" }}>
          <h2>
            {type !== "sellerShop"
              ? productDetailTranslation?.relatedProduct
                ? productDetailTranslation?.relatedProduct
                : "Related Products"
              : "All Products"}
          </h2>
        </div>
        <Col xs="12 d-flex justify-content-center">
          <div style={{ display: "inline-flex", flexWrap: "wrap" }}>
            {relatedData.products?.map((item, index) => (
              <Col lg="3 d-flex justify-content-center" key={index}>
                <div className="product-preview-container mr-0">
                  <div className="product-preview-container-image-wrapper">
                    <img
                      className="product-preview-container-image"
                      src={item.images[0]}
                      alt="Avatar"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "26.45%",
                    }}
                  >
                    <div style={{ width: "75%" }}>
                      <div className="product-preview-name-text">
                        {item.name}
                      </div>
                      <div className="product-preview-brand-text">
                        {item.brand}
                      </div>

                      <div className="d-flex justify-content-between">
                        <div style={{ marginLeft: 10 }}>
                          <MiniRatingWidget product={item} />
                        </div>
                        <button
                          className="product-detail-view-button"
                          onClick={() => {
                            viewProduct(item, user);
                          }}
                        >
                          <IoMdAdd size={18} type="button" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
            {relatedData.products?.length <= 0 && (
              <div style={{ marginTop: "80px" }}>
                <h5>
                  {productDetailTranslation?.relatedMessage
                    ? productDetailTranslation?.relatedMessage
                    : "We didn't have any Related products"}
                </h5>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/* PAGINATION */}
      {/* {type === "sellerShop" && (
        <Row className="mx-5 my-5">
          <Col xs="12 d-flex justify-content-center px-5">
            <nav>
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    onClick={(e) => paginate(pagination.pageNo - 1)}
                    disabled={pagination.pageNo === 1 ? true : false}
                    style={{
                      backgroundColor: (pagination.pageNo === 1 ? true : false) && "#e4e7ea",
                    }}
                  >
                    Prev
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    onClick={(e) => paginate(pagination.pageNo + 1)}
                    disabled={!clickableNext}
                    style={{
                      backgroundColor: !clickableNext ? "#e4e7ea" : null,
                    }}
                  >
                    Next
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={"ml-auto"}>
                  <PaginationLink
                    className="rounded"
                    style={{
                      color: "black",
                      hover: false,
                      backgroundColor: "white",
                    }}
                  >
                    <div>
                      Items per Page
                      <select
                        className="ml-2"
                        value={selectedPerPage}
                        onChange={(e) => perPageHandler(e, pagination.pageNo)}
                      >
                        {PER_PAGE_OPTIONS.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </Col>
        </Row>
      )} */}
    </>
  );
};

export default memo(RelatedProducts);
