import Axios from "axios";
import Store from "../redux/store";

export async function getPresigedUrl(params) {
  const signedUrlResp = await Axios.post(
    "/signedurl",
    {
      ...params,
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${Store.getState()?.auth?.token}`,
      },
    }
  );

  return signedUrlResp?.data?.data;
}

export async function uploadToS3ViaSignedUrl(params) {
  await Axios.put(params.signedUrl, params.body, {
    headers: {
      "Content-Type": params.contentType,
      "x-amz-acl": "public-read",
    },
  });
}
