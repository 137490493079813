import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { getSingleProduct } from "../../../../redux/reducers/seller";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import "./View.css";
const ViewEbooks = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  const digitalType = urlArray[urlArray.length - 3];
  useEffect(() => {
    dispatch(getSingleProduct(productId));
  }, []);
  const { singleProduct, loading } = useSelector((state) => state.seller);
  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const convertCommentFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw({ entityMap: {}, blocks: text.blocks }));
  };
  const viewAll = () => {
    if (userType === "admin") {
      history.push("/admin/digitalproductlist");
    } else {
      history.push(`/seller/digitalproduct/${digitalType}`);
    }
  };
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg="12">
          <Card className="mt-5">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div>
                  <i
                    className="fa fa-align-justify"
                    style={{ textTransform: "capitalize" }}
                  ></i>{" "}
                  View {digitalType}
                </div>
                <div>
                  <Button onClick={viewAll} size="sm" color="warning">
                    <b>View All</b>
                  </Button>
                </div>
              </div>
            </CardHeader>
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <i
                  className="fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green", margin: "240px" }}
                ></i>
              </div>
            ) : (
              <CardBody>
                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        Title
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.name}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        Description
                      </Label>
                    </Col>
                    <Col lg="9">
                      <div className="controls">
                        <div id="comment-div">
                          {singleProduct && singleProduct.specification && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertCommentFromJSONToHTML(
                                  singleProduct.specification
                                ),
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                {digitalType === "ebook" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Author Name
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.authorName}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Book Length
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.bookLength}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "theater" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Director
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.director}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Theater Length
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.runtime} Minutes
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Video Quality
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.videoQuality}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Type
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.theaterType}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Release Date
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "movie" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Director
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.director}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Movie Length
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.runtime} Minutes
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Video Quality
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.videoQuality}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Gerne
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.gerne}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Production Name
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.productionName}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Release Date
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "music" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Singer
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.singerName}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Music Length
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.runtime} Minutes
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Quality
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.audioQuality}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Production Name
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.productionName}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Release Date
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "game" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Size
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.gameSize} mb
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Release Date
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            Supported Device
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {singleProduct.requireDevice}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "series" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Director
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.director}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Gerne
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.gerne}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Release Date
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "software" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Size
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.softwareSize} mb
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            Release Date
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            Supported Device
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {singleProduct.requireDevice}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            Category
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {singleProduct.softwareCategory}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        Language
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.language}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        Regular Price
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.regularPrice}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        Sale Price
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.salesPrice}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        Stock Quantity
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.stockQuantity}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="file-multiple-input">Cover</Label>
                  </Col>

                  <Col xs="12" lg="3">
                    <div class="container">
                      {singleProduct?.images && singleProduct?.images[0] ? (
                        <>
                          <img
                            style={{ width: "100%" }}
                            src={singleProduct?.images[0]}
                            alt="Avatar"
                            class="image"
                          />
                        </>
                      ) : (
                        "no image"
                      )}
                    </div>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="file-multiple-input">View</Label>
                  </Col>

                  <Col xs="12" lg="3">
                    <div class="container">
                      <a target="_blank" href={singleProduct.productUrl}>
                        Click to download
                      </a>
                      {digitalType === "theater" ||
                        (digitalType === "movie" && (
                          <>
                            <video width="320" height="240" controls>
                              <source
                                src={singleProduct.productUrl}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </>
                        ))}
                      {digitalType === "music" && (
                        <>
                          <audio controls>
                            <source
                              src={singleProduct.productUrl}
                              type="audio/mpeg"
                            />
                          </audio>
                        </>
                      )}
                    </div>
                  </Col>
                </FormGroup>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewEbooks;
