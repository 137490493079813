import React, { useEffect, useState } from "react";
import "./AccountDetails.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  EditUserInfo,
  ResetPassword,
  getProfileData,
  validatePassword,
} from "../../redux/reducers/auth";
import {
  bluesnapSupportedCountries,
  bluesnapSupportedCurrencies,
  bluesnapSupportedData,
} from "../../assets/bluesnap/bluesnapSupport";
import moment from "moment";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import {
  createPayoutMethod,
  createProfilePayout,
  updatePayoutMethod,
} from "../../redux/reducers/user";
import { ToastContainer, toast } from "react-toastify";

const AccountDetailsEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];
  const {
    user: {
      firstname,
      lastname,
      email,
      dateOfBirth,
      address,
      city,
      country,
      state,
      postalCode,
      phone,
      currency,
      countryCode,
      isPayoutSetup,
      vendorId,
      vendorInfoId,
    },
    token,
    loading,
  } = useSelector((state) => state.auth);
  const { err } = useSelector((state) => state.user);
  const [
    bluesnapSupportedVendorCountries,
    setBluesnapSupportedVendorCountries,
  ] = useState(bluesnapSupportedCountries);
  const [
    bluesnapSupportedVendorCurrencies,
    setBluesnapSupportedVendorCurrencies,
  ] = useState(bluesnapSupportedCurrencies);
  const [pageContent, setPageContent] = useState({
    firstname,
    lastname,
    email,
    dateOfBirth: dateOfBirth
      ? moment(dateOfBirth).format("YYYY-MM-DD")
      : moment().subtract(18, "years").format("YYYY-MM-DD"),
    address,
    city,
    country,
    state,
    postalCode,
    phone,
    currency,
    countryCode,
    isPayoutSetup,
    vendorId,
    vendorInfoId,
    displayname: "",
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [showPayout, setShowPayout] = useState(false);
  const [payoutData, setPayoutData] = useState({
    payoutType: "WIRE",
    baseCurrency: currency,
    nameOnAccount: "",
    bankAccountType: "",
    bankAccountClass: "",
    bankName: "",
    swiftBic: "",
    country: countryCode,
    state,
    address,
    zip: postalCode,
    city,
    iban: vendorInfoId?.payoutInfo?.iban || "",
  });

  useEffect(() => {
    dispatch(getProfileData()).then((res) => {
      const { vendorInfoId, isPayoutSetup } = res.payload.data;
      setPayoutData({
        ...payoutData,
        nameOnAccount: vendorInfoId?.payoutInfo?.nameOnAccount, //Name on bank account for vendor.
        bankAccountType: vendorInfoId?.payoutInfo?.bankAccountType, //CHECKING SAVINGS
        bankAccountClass: vendorInfoId?.payoutInfo?.bankAccountClass, // PERSONAL CORPORATE INTERNATIONAL
        bankName: vendorInfoId?.payoutInfo?.bankName,
        swiftBic: vendorInfoId?.payoutInfo?.swiftBic, // swiftBic
        iban: vendorInfoId?.payoutInfo?.iban, //iban
      });
      setShowPayout(isPayoutSetup);
    });
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "country") {
      const countryObj = bluesnapSupportedVendorCountries.find(
        (obj) => obj.Country === value
      );
      setPageContent({
        ...pageContent,
        country: countryObj?.Country,
        countryCode: countryObj?.Code,
      });
    } else {
      setPageContent({
        ...pageContent,
        [name]: value,
      });
    }
  };

  const EditInfo = async () => {
    const {
      firstname,
      lastname,
      email,
      dateOfBirth,
      address,
      city,
      country,
      state,
      postalCode,
      phone,
      currency,
      countryCode,
      password,
      confirmPassword,
      currentPassword,
    } = pageContent;
    if (password.trim() === "" && confirmPassword.trim() === "") {
      if (
        !firstname ||
        !lastname ||
        !email ||
        !dateOfBirth ||
        !address ||
        !city ||
        !country ||
        !state ||
        !postalCode ||
        !currency ||
        !phone ||
        !countryCode
      ) {
        toast.error(`${toastTranslation?.requried ? toastTranslation?.requried : "Kindly fill the required fields"}`);
        return;
      } else {
        dispatch(createProfilePayout(pageContent)).then((response) => {
          if (!response.error) {
            dispatch(getProfileData());
            setPayoutData({
              ...payoutData,
              baseCurrency: currency,
              country: countryCode,
              state,
              address,
              zip: postalCode,
              city,
              iban: vendorInfoId?.payoutInfo?.iban,
            });
            setShowPayout(true);
            history.push("/customer-account/accountdetailsedit");
          }
        });
        return;
      }
    } else if (password.trim() !== confirmPassword.trim()) {
      toast.error(`${toastTranslation?.error ? toastTranslation?.error : "New Passwords do not match"}`);
      return;
    }
    dispatch(
      validatePassword({ password, confirmPassword, currentPassword })
    ).then(({ payload }) => {
      if (payload.success) {
        dispatch(ResetPassword({ password, token })).then(() =>
          history.push("/customer-account/account-details")
        );
      }
    });
  };

  const editPayoutData = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPayoutData({
      ...payoutData,
      [name]: value,
    });
  };

  const submitPayout = async () => {
    const {
      nameOnAccount,
      bankAccountType,
      bankAccountClass,
      bankName,
      swiftBic,
      iban,
    } = payoutData;
    if (
      !nameOnAccount ||
      !bankAccountType ||
      !bankAccountClass ||
      !bankName ||
      !swiftBic ||
      !iban
    ) {
      toast.error(`${toastTranslation?.requried ? toastTranslation?.requried : "Kindly fill the required fields"}`);
      return;
    }
    const finalDetails = {
      email,
      state,
      address,
      city,
      phone,
      firstName: firstname,
      lastName: lastname,
      country: countryCode,
      zip: postalCode,
      defaultPayoutCurrency: currency,
      vendorPrincipal: {
        email,
        city,
        address,
        firstName: firstname,
        lastName: lastname,
        country: countryCode, //country code
        zip: postalCode,
        dob: moment(dateOfBirth).format("DD-MM-YYYY"), // 28-09-9999
        personalIdentificationNumber: "1234",
      },
      vendorAgreement: {
        commissionPercent: 0,
        accountStatus: "ACTIVE",
        recurringCommission: "N",
      },
      payoutInfo: [{ ...payoutData }],
    };

    if (!vendorId) {
      dispatch(createPayoutMethod(finalDetails)).then(() => {
        dispatch(getProfileData());
      });
    } else {
      dispatch(updatePayoutMethod(finalDetails)).then(() => {
        dispatch(getProfileData());
      });
    }
  };

  return (
    <>
      <ToastContainer autoClose={20000} />
      <div className="main-row">
        <h3 className="mx-3 my-3">Update your account Details</h3>
        <div className="row edit-form justify-content-center">
          {loading ? (
            <div>
              <i
                className="mx-auto fas fa-spinner fa-pulse fa-2x"
                style={{ color: "green" }}
              ></i>
            </div>
          ) : (
            <div className="col-md-12 ">
              <div>
                {typeof err !== "string" && err.length && 
                  err.map((e) => (
                    <li className="text-danger pl-3 mb-2">{e.description}</li>
                  ))}
                <div className="row">
                  <div className="col-6 mb-2">
                    <b>
                      <label>First name *</label>
                    </b>
                    <Input
                      name="firstname"
                      value={pageContent?.firstname}
                      onChange={handleInputChange}
                      placeholder="First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <b>
                      <label>Last name *</label>
                    </b>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                      value={pageContent?.lastname}
                      name="lastname"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label>Email Address *</label>
                    </b>
                    <Input
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                      disabled
                      value={pageContent?.email}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label htmlFor="dateOfBirth">Date of Birth *</label>
                    </b>
                    <Input
                      className="form-control"
                      value={pageContent?.dateOfBirth}
                      onChange={handleInputChange}
                      type="date"
                      name="dateOfBirth"
                      id="dateOfBirth"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label htmlFor="country">Country *</label>
                    </b>
                    <Input
                      className="form-control"
                      value={pageContent?.country}
                      onChange={handleInputChange}
                      type="select"
                      name="country"
                      id="country"
                    >
                      <option value="">select Country *</option>
                      {bluesnapSupportedVendorCountries?.map((obj, index) => (
                        <option key={index} value={obj?.Country}>
                          {obj.Country}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label htmlFor="currency">Currency *</label>
                    </b>
                    <Input
                      className="form-control"
                      value={pageContent?.currency}
                      onChange={handleInputChange}
                      type="select"
                      name="currency"
                      id="currency"
                    >
                      <option value="">select currency</option>
                      {bluesnapSupportedVendorCurrencies?.map((obj, index) => (
                        <option key={index} value={obj?.currency}>
                          {obj.currency}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label htmlFor="state">State/Province Code*</label>
                    </b>
                    <Input
                      className="form-control"
                      value={pageContent?.state}
                      onChange={handleInputChange}
                      type="text"
                      name="state"
                      id="state"
                      placeholder="e.g, CA"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label htmlFor="city">city *</label>
                    </b>
                    <Input
                      className="form-control"
                      value={pageContent?.city}
                      onChange={handleInputChange}
                      type="text"
                      name="city"
                      id="city"
                      placeholder="e.g, Los Angeles"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label htmlFor="address">Address Line 1 *</label>
                    </b>
                    <Input
                      className="form-control"
                      value={pageContent?.address}
                      onChange={handleInputChange}
                      type="text"
                      name="address"
                      id="address"
                      placeholder="House no, Street Name"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label htmlFor="postalCode">Postal Code *</label>
                    </b>
                    <Input
                      className="form-control"
                      value={pageContent?.postalCode}
                      onChange={handleInputChange}
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      placeholder="postal Code"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label htmlFor="phone">Phone No. *</label>
                    </b>
                    <Input
                      className="form-control"
                      value={pageContent?.phone}
                      onChange={handleInputChange}
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Phone No"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <b>
                      <p>Password Change</p>
                    </b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label>
                        Current password (leave blank to leave unchanged)
                      </label>
                    </b>
                    <Input
                      type="password"
                      name="currentPassword"
                      placeholder="current password"
                      className="form-control"
                      value={pageContent.currentPassword}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label>
                        New password (leave blank to leave unchanged)
                      </label>
                    </b>
                    <Input
                      type="password"
                      name="password"
                      placeholder="new password"
                      className="form-control"
                      value={pageContent.password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <b>
                      <label>
                        Confirm new password (leave blank to leave unchanged)
                      </label>
                    </b>
                    <Input
                      type="password"
                      name="confirmPassword"
                      placeholder="password"
                      className="form-control"
                      value={pageContent.confirmPassword}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div>
                  <Button
                    size="sm"
                    type="submit"
                    className="ml-3 mx-1"
                    color="warning"
                    disabled={loading}
                    onClick={EditInfo}
                  >
                    Save changes
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    disabled={loading}
                    onClick={() =>
                      history.push("/customer-account/account-details")
                    }
                  >
                    cancel
                  </Button>
                </div>
              </div>
              {showPayout && !loading ? (
                <>
                  <h3 className="mx-3 mt-5">Update Payout Details</h3>
                  <Col>
                    <Form>
                      <FormGroup>
                        <Label for="state">Account Type *</Label>
                        <Input
                          value={"Local Bank Transfer"}
                          type="text"
                          name="type"
                          id="type"
                          placeholder="Account Type"
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="nameOnAccount">
                          Name on Bank Account *
                        </Label>
                        <Input
                          value={payoutData?.nameOnAccount}
                          onChange={editPayoutData}
                          type="text"
                          name="nameOnAccount"
                          id="nameOnAccount"
                          placeholder="Name on account"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="bankName">Bank Name *</Label>
                        <Input
                          value={payoutData?.bankName}
                          onChange={editPayoutData}
                          type="text"
                          name="bankName"
                          id="bankName"
                          placeholder="Bank name"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="swiftBic">swift Bic *</Label>
                        <Input
                          value={payoutData?.swiftBic}
                          type="text"
                          onChange={editPayoutData}
                          name="swiftBic"
                          id="swiftBic"
                          placeholder="swift Bic"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="iban">Iban *</Label>
                        <Input
                          value={payoutData?.iban}
                          onChange={editPayoutData}
                          type="text"
                          name="iban"
                          id="iban"
                          placeholder="Iban"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="bankAccountType">Bank Account Type *</Label>
                        <Input
                          value={payoutData?.bankAccountType}
                          onChange={editPayoutData}
                          type="select"
                          name="bankAccountType"
                          id="bankAccountType"
                        >
                          <option value="">select type</option>
                          <option value={"CHECKING"}>CHECKING</option>
                          <option value={"SAVINGS"}>SAVINGS</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="bankAccountClass">
                          Bank Account Class *
                        </Label>
                        <Input
                          value={payoutData?.bankAccountClass}
                          onChange={editPayoutData}
                          type="select"
                          name="bankAccountClass"
                          id="bankAccountClass"
                        >
                          <option value="">select type</option>
                          <option value={"PERSONAL"}>PERSONAL</option>
                          <option value={"CORPORATE"}>CORPORATE</option>
                          <option value={"INTERNATIONAL"}>INTERNATIONAL</option>
                        </Input>
                      </FormGroup>
                      <Button
                        size="sm"
                        color="warning"
                        disabled={loading}
                        onClick={submitPayout}
                      >
                        Submit payout
                      </Button>
                    </Form>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountDetailsEdit;
