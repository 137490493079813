import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RTCSubscriber } from "red5pro-webrtc-sdk";

const SubscriberRTCComponent = memo((props) => {
  const { subscriberProductToken, onClose } = props;
  const videoRef = useRef(null);
  const { userLanguage } = useSelector((state) => state.auth);
  const { translationState } = useSelector((state) => state.user);
  const [translatedContent, setTranslatedContent] = useState({});

  useEffect(() => {
    if (videoRef?.current && subscriberProductToken) {
      start();
    }
  }, [videoRef]);

  useEffect(() => {
    if (translationState?.length) {
      const eventTranslation =
        translationState[0].translations?.event?.[userLanguage];
      eventTranslation
        ? setTranslatedContent({ ...translatedContent, ...eventTranslation })
        : setTranslatedContent({});
    }
  }, [translationState, userLanguage]);

  const start = async () => {
    try {
      const subscriber = new RTCSubscriber();
      await subscriber.init({
        protocol: "wss",
        port: 5443,
        host: "live.yinn.ca",
        app: "live",
        streamName: subscriberProductToken,
        rtcConfiguration: {
          iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
          iceCandidatePoolSize: 2,
          bundlePolicy: "max-bundle",
        },
        mediaElementId: "red5pro-subscriber",
        subscriptionId:
          subscriberProductToken +
          Math.floor(Math.random() * 0x10000).toString(16),
      });
      await subscriber.subscribe();
    } catch (e) {
      toast.error(
        `${
          translatedContent.subscriberError ||
          "An error occured in establishing a subscriber session:"
        } ${e}`
      );
      console.error(
        `🚀 ~ ${
          translatedContent.subscriberError ||
          "An error occured in establishing a subscriber session:"
        }`,
        e
      );
      handleDisclose();
    }
  };
  const handleDisclose = () => {
    const video = videoRef?.current;
    const mediaStream = video?.srcObject;
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      video.srcObject = null;
    }
    onClose();
  };

  return (
    <div>
      <video
        id="red5pro-subscriber"
        ref={videoRef}
        autoPlay
        controls
        playsInline
      ></video>
    </div>
  );
});

export default SubscriberRTCComponent;
