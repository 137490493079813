import React, { useEffect, useRef, useState } from "react";

import "./watch.css";
import { socket } from "../../config/axios";
const Watch = () => {
  const videoWatch = useRef(null);
  let peerConnection;
  const config = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
    ],
  };

  const [muted, setMuted] = useState(true);

  socket.on("offer", (id, description) => {
    peerConnection = new RTCPeerConnection(config);
    peerConnection
      ?.setRemoteDescription(description)
      .then(() => peerConnection.createAnswer())
      .then((sdp) => peerConnection.setLocalDescription(sdp))
      .then(() => {
        socket.emit("answer", id, peerConnection.localDescription);
      });
    peerConnection.ontrack = (event) => {
      videoWatch.current.srcObject = event.streams[0];
    };
    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emit("candidate", id, event.candidate);
      }
    };
  });

  socket.on("candidate", (id, candidate) => {
    peerConnection
      .addIceCandidate(new RTCIceCandidate(candidate))
      .catch((e) => console.error(e));
  });

  socket.on("connect", () => {
    socket.emit("watcher");
  });

  socket.on("broadcaster", () => {
    socket.emit("watcher");
  });

  window.onunload = window.onbeforeunload = () => {
    socket.close();
    peerConnection.close();
  };

  function enableAudio() {
    console.log("Enabling audio");
    setMuted(false);
  }
  return (
    <div className="">
      <h1>Hello its test watching</h1>
      <video
        ref={videoWatch}
        id="watch-video"
        playsInline
        autoPlay
        muted={muted}
      ></video>
      <button onClick={enableAudio}>
        Enable audio
      </button>
    </div>
  );
};
export default Watch;
