export const getMessage = (state, type) => {
  const { userLanguage } = state.auth;
  if (type == "success") {
    const translation = state.user.translationState?.[0]?.translations;
    const succes = translation?.toast?.[userLanguage];
    return succes?.success;
  } else {
    return "error";
  }
};
