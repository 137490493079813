import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Backdrop } from "@material-ui/core";

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const search = useLocation().search;
  const redirect = new URLSearchParams(search).get("redirect");
  const dispatch = useDispatch();
  const { isLogged, token, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      if (token && user) {
        if (redirect) {
          history.push(redirect);
        } else {
          if (user.userType === "admin")
            history.push("/admin/content-management");
          else if (user.userType === "user") history.push("/customer-account");
          else if (user.userType === "seller") history.push("/seller/profile");
        }
      }
    }
  }, [token]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
