import JsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { round } from "lodash";
import logo from "../../assets/img/brand/logo.png";
import { useSelector } from "react-redux";

const formatAmount = (amount) => {
  let value = "N/A";
  if (amount || amount === 0) {
    value = round(amount, 2).toFixed(2);
    value = `$${value}`;
  }
  return value;
};

const generateSubscriptionInvoicePDF = (singleInvoice, user) => {
  const items = [];
  let total = 0;
  for (let i = 0; i < singleInvoice.products.length; i++) {
    const product = singleInvoice.products[i];
    const { product: productInfo, qty, unitPrice } = product;
    const name = productInfo?.name ?? "";
    total += qty * unitPrice;
    items.push([name, qty, unitPrice, qty * unitPrice]);
  }

  const doc = new JsPDF("p", "pt", "letter");
  doc.setDrawColor(0);
  doc.setFillColor("#000");
  doc.rect(25, 25, 561, 15, "F");

  doc.setFillColor(245, 245, 245);
  doc.rect(25, 40, 561, 100, "F");

  doc.setFontSize(12);
  doc.text("User Name", 40, 75);
  doc.text(`${user.firstname}`, 110, 75);
  doc.text("Invoice No:", 40, 95);
  doc.text(`${singleInvoice._id}`, 110, 95);
  doc.addImage(logo, "PNG", 480, 55, 100, 50);

  autoTable(doc, {
    margin: { top: 150 },
    theme: "grid",
    headStyles: {
      fillColor: [0, 0, 0],
    },
    head: [["Title", "QTY", "Unit Price", "Total Price"]],
    body: items,
  });

  autoTable(doc, {
    margin: { top: 20 },
    theme: "plain",
    headStyles: {},
    head: [["Total:", { content: total, styles: { halign: "right" } }]],
  });

  return doc;
};

export const GeneratePDF = (singleInvoice, user) => {
  const doc = generateSubscriptionInvoicePDF(singleInvoice, user);
  return doc.save(`YINN_${"INVOICE_NUMBER"}_invoice.pdf`);
};
