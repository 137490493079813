import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  FormGroup,
  Label,
} from "reactstrap";


const FreemiumPage = ({text}) => {

  const dispatch = useDispatch();
  const {translationState } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const reportingTranslation = translationState?.[0]?.translations?.reporting?.[userLanguage];

  return (
       <Row>
       <Col>
         <Card className={"mt-5"}>
           <CardHeader>
             <Row>
               <Col xs="12" sm="9">
                 <b>{reportingTranslation?.infoHeadingOne ? reportingTranslation?.infoHeadingOne : "Information"}</b>
               </Col>
             </Row>
           </CardHeader>
           <CardBody className="card-body">
             <Row>
               <Col xs="12" >
                 <InputGroup>
                  {text}
                 </InputGroup>
               </Col>
             </Row>
           </CardBody>
         </Card>
       </Col>
     </Row>
  )
}

export default FreemiumPage