import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
let date = new Date();
let year = date.getFullYear();
const DefaultFooter = (props) => {
  const dispatch = useDispatch();
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const footerTranslation =
    translationState?.[0]?.translations?.footer?.[userLanguage];
  // eslint-disable-next-line
  const { children, ...attributes } = props;

  return (
    <React.Fragment>
      <span>
        <a href="#" style={{ color: "white" }}>
          {footerTranslation?.reserver
            ? footerTranslation?.reserver
            : "All Rights Reserved"}{" "}
          @YINN Group Inc {year}.
        </a>
      </span>
      {/* <span className="ml-auto" id = "google_translate_element" style={{marginRight: '2%'}}> </span> */}
    </React.Fragment>
  );
};

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
