import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBlock,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import { AppSwitch } from "@coreui/react";
import { addFilter, getAllUsers, updateUser } from "../../redux/reducers/admin";
import "./StreamingConfig.css";

const divStyle = { marginLeft: "81%" };

const StreamingConfig = () => {
  const dispatch = useDispatch();
  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.admin);

  const [configObj, setConfigObj] = useState({
    sellers: [],
    pagecount: 1,
    disablePrevButton: true,
    disableNextButton: false,
    search: "",
  });

  useEffect(() => {
    dispatch(addFilter({ field: "userType", value: "seller" }));
    dispatch(getAllUsers());
    paginate("next", 0);
    // paginate(1);
    return () => {
      dispatch(addFilter({ field: "userType", value: "" }));
      dispatch(addFilter({ field: "search", value: "" }));
      dispatch(addFilter({ field: "pageNo", value: 1 }));
    };
  }, []);

  useEffect(() => {
    setConfigObj({ ...configObj, sellers: users?.length ? users : [] });
  }, [users]);

  const searchElement = (evt) => {
    configObj.search = evt.target.value;
    if (evt.target.value.length == 0) {
      paginate("next", 0);
    } else {
      search();
    }
  };
  const search = () => {
    dispatch(addFilter({ field: "search", value: configObj.search }));
    dispatch(getAllUsers());
  };
  const paginate = (direction, num) => {
    if (direction == "next") {
      num = num + 1;
    } else {
      num = num - 1;
    }
    if (num == 1) {
      setConfigObj({ ...configObj, disablePrevButton: true });
    } else {
      setConfigObj({ ...configObj, disablePrevButton: false });
    }

    if (num > 0) {
      dispatch(addFilter({ field: "pageNo", value: num }));
      dispatch(getAllUsers());
      setConfigObj({ ...configObj, pagecount: num });
    }
  };
  const handleSwitchInput = async (e, item) => {
    const name = e.target.name;
    const value = e.target.checked;
    const data = {
      userId: item._id,
      streamingDetails: { isEnabled: value },
      type: "streamingBlockStatus",
    };
    await dispatch(updateUser(data));
    dispatch(getAllUsers({}));
    setConfigObj({ ...configObj, [name]: value });
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card className=" mt-5">
            <CardHeader>
              <Row>
                {userType == "admin" && (
                  <Col xs="12" sm="9">
                    <b>Sellers List</b>
                  </Col>
                )}
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              {userType == "admin" && (
                <Row>
                  <Col xs="12" sm="4">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <Button type="button" color="warning" onClick={search}>
                          <i className="fa fa-search search-color"></i> Search
                        </Button>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        id="input1-group2"
                        onChange={searchElement}
                        className="search-color"
                        name="input1-group2"
                        placeholder="Seller Name/Email/Seller ID"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <br />
              {userType == "admin" && (
                <Table hover bordered striped responsive size="sm">
                  <thead align="center">
                    <tr className="header-color">
                      <th> Name</th>
                      <th> Email Address</th>
                      <th> Seller ID</th>
                      <th> Membership </th>
                      <th> Enable/Disable Streaming</th>
                    </tr>
                  </thead>
                  <tbody>
                    {configObj.sellers.length > 0 ? (
                      configObj.sellers.map((item, index) => (
                        <tr hidden={item.is_staff === false} key={index}>
                          <td>
                            {item.firstname + " " + item.lastname || "NA"}
                          </td>
                          <td>{item.email}</td>
                          <td>{item._id}</td>
                          <td>
                            {item.membership
                              ? item.membership.membership_type
                              : "Freemuim"}
                          </td>
                          <td align="center">
                            {
                              <AppSwitch
                                className={"mx-1"}
                                variant={"pill"}
                                color={"primary"}
                                name="isEnabled"
                                checked={item.streamingDetails?.isEnabled}
                                onChange={(e) => handleSwitchInput(e, item)}
                              />
                            }
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td style={{ color: "red" }}>Records Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}

              <nav>
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate("prev", configObj.pagecount)}
                      style={{ backgroundColor: "#e4e7ea" }}
                    >
                      Prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate("next", configObj.pagecount)}
                      className="mx-2"
                      style={{ backgroundColor: "#e4e7ea" }}
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StreamingConfig;
