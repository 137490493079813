import React, { useState, useEffect } from "react";
import { round } from "lodash";
import { Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Summary from "./CoinSummary";
import YncoinSrc from "../../../assets/img/YNcoin.png";
import { getExhangeRate } from "../../../redux/reducers/auth";
import { getYinnExhangeRate } from "../../../redux/reducers/user";

const Packages = (props) => {
  const {
    coins,
    setCoins,
    salesPrice,
    setSalesPrice,
    paymentConfiguration,
    yinnPercentage,
    totalPrice,
  } = props;
  const dispatch = useDispatch();
  const { usdRate } = useSelector((state) => state.auth);
  const { userCurrency, exchangeRate, translationState } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getYinnExhangeRate());
    dispatch(getExhangeRate(userCurrency));
  }, []);

  const onCoinsChange = (value) => {
    setCoins(value);
    const salePrice = round((value * usdRate) / exchangeRate, 2);
    setSalesPrice(salePrice);
  };

  useEffect(() => {
    const salePrice = round((coins * usdRate) / exchangeRate, 2);
    setSalesPrice(salePrice);
  }, [coins, usdRate, exchangeRate]);

  return (
    <Row className="packages-main-area">
      <Col
        xs="12"
        xl="6"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h4 style={{ marginBotton: 20 }}>
          {walletTranslation?.hTwo
            ? walletTranslation?.hTwo
            : "Enter amount to buy or to top up your wallet"}
        </h4>

        <div className="coin-pacakge-box">
          <img className="coin-img" src={YncoinSrc} />
          <input
            type="number"
            onChange={(e) => onCoinsChange(e.target.value)}
            value={coins}
            name="coins"
            placeholder={
              walletTranslation?.placeholder
                ? walletTranslation?.placeholder
                : "Enter Number of coins"
            }
            className="coin-input"
          />
          <div className="coin-input-box">
            <div className="coin-package-price">{salesPrice}</div>
            <span className="mt-1">{userCurrency}</span>
          </div>
        </div>
      </Col>
      <Col xs="12" xl="6" style={{ paddingRight: 0 }}>
        <div>
          <div>
            <Summary
              paymentConfiguration={paymentConfiguration}
              salesPrice={salesPrice}
              yinnPercentage={yinnPercentage}
              totalPrice={totalPrice}
              bordered
            />
          </div>
        </div>
      </Col>
    </Row>
    // <div className="pakges_main_wrapper"></div>
  );
};

export default Packages;
