import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paymentConfig, updatePaymentConfig } from "../../redux/reducers/admin";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

const SocialLinks = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { paymentConfiguration, loading } = useSelector((state) => state.admin);
  const [pageContent, setPageContent] = useState({
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    linkedInLink: "",
  });
  console.log("🚀 ~ file: SocialLinks.js:31 ~ pageContent:", pageContent);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  useEffect(() => {
    dispatch(paymentConfig());
  }, []);
  useEffect(() => {
    setPageContent({ ...pageContent, ...paymentConfiguration });
  }, [paymentConfiguration]);
  const submitValues = () => {
    const { facebookLink, instagramLink, twitterLink, linkedInLink } =
      pageContent;
    // if (
    //   facebookLink.trim() ||
    //   instagramLink.trim() ||
    //   twitterLink.trim() ||
    //   linkedInLink.trim()
    // ) {
    const data = { ...pageContent };
    dispatch(updatePaymentConfig(data)).then(() => dispatch(paymentConfig()));
    // } else {
    //   toast.info("Must fill at least one Link Field");
    //   return;
    // }
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Card className="mx-2 mt-5">
        <CardHeader>
          <Row>
            <Col xs="5" sm="5" md="6" className="p-0">
              <strong>Social Links</strong>
            </Col>
          </Row>
        </CardHeader>

        {loading ? (
          <div style={{ margin: "220px", textAlign: "center" }}>
            <i
              className="mx-auto fas fa-spinner fa-pulse fa-2x"
              style={{ color: "green" }}
            ></i>
          </div>
        ) : (
          <CardBody>
            <Row>
              <Col xs="12" sm="4">
                <FormGroup>
                  <Label> Facebook Link</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Link"
                        name="facebookLink"
                        value={pageContent.facebookLink}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Instagram Link</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Link"
                        name="instagramLink"
                        value={pageContent.instagramLink}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> Twitter Link</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Link"
                        name="twitterLink"
                        value={pageContent.twitterLink}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label> LinkedIn Link</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Link"
                        name="linkedInLink"
                        value={pageContent.linkedInLink}
                        onChange={handleInputChange}
                        size="16"
                        type="text"
                      />
                    </InputGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ display: "flex" }}>
              <Button
                type="button"
                size="md"
                color="warning"
                onClick={() => submitValues()}
                className="ml-3 "
              >
                Update
              </Button>
            </Row>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default SocialLinks;
