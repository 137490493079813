import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { includes } from "lodash";
import {
  Button,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Label,
  Badge,
  CardHeader,
  FormGroup,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import classnames from "classnames";
import { AppFooter, AppHeader } from "@coreui/react";

import { stateToHTML } from "draft-js-export-html";
import RatingWidget from "../ProductDetail/RatingWidget";
import {
  getSingleProduct,
  getRelatedProducts,
  addToCart,
  getSuggestedPrice,
} from "../../../redux/reducers/auth";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import ValidationProvider from "../../Providers/ValidationProvider";
import ProductPreviewBox from "../Home/ProductPreviewBox";
import "./View.css";

import { convertFromRaw } from "draft-js";
import { delay, round } from "lodash";

const ViewTvSeries = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  const {
    singleProduct,
    loading,
    relatedProducts,
    isAlreadyPurchased,
    suggestedPrice,
  } = useSelector((state) => state.auth);
  const {translationState, allWebContent } = useSelector((state) => state.user);
  const {userLanguage, cart} = useSelector((state) => state.auth);
  const checkCart = cart?.map((item) => item?.product?.eventCategory);

  const selectedLanguage = singleProduct?.translatedArray?.filter(
    (item) => item.language == userLanguage
  );

  const productDetailContent = allWebContent?.[0]?.productDetail;
  const productDetailTranslation = translationState?.[0]?.translations?.productDetail?.[userLanguage];
  const shopTranslation = translationState?.[0]?.translations?.shop?.[userLanguage];
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];

  const [suggestedValue, setSuggestedValue] = useState(0);
  const [buttonType, setButtonType] = useState("");
  const [qty, setQty] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSingleProduct(id));
    dispatch(getRelatedProducts(id));
  }, []);
  useEffect(() => {
    if (suggestedPrice) {
      delay(() => {
        if (buttonType == "shipping") shipping();
        else if (buttonType == "addToCart") addCart();
      }, 1000);
    }
  }, [suggestedPrice]);
  // useEffect(() => {
  //   dispatch(getSingleProduct(productId));
  //   dispatch(getRelatedProducts(productId));
  // }, []);

  const shipping = () => {
    const data = {
      product: { ...singleProduct, unitPrice: parseFloat(suggestedValue) },
      qty,
    };
    if (checkCart?.[0] === "event"){
      if(data?.product?.eventCategory === "event") {
        dispatch(addToCart(data));
        history.push("/my-cart");
      }else{
        toast.error("Category are not same clear your cart");
      }
    }else{
      if(data?.product?.eventCategory !== "event"){
        dispatch(addToCart(data));
        history.push("/my-cart"); 
      }else if(cart.length === 0){
        dispatch(addToCart(data));
        history.push("/my-cart"); 
      }else{
        toast.error("Category are not same clear your cart");
      }
    }
  };
  const viewProduct = (id, type) => {
    dispatch(getSingleProduct(id));
    dispatch(getRelatedProducts(id));
    history.push(`/viewtv/${id}`);
    // history.push(
    //   `/${type == "digital" ? "digitalproduct" : "productdetail"}/${id}`
    // );
  };
  const getStarRating = () => {
    let price = singleProduct
      ? singleProduct?.dynamicPrice?.currentPrice
      : null;
    if (price) {
      if (price < 20) {
        return 1;
      } else if (price < 100) {
        return 2;
      } else if (price < 500) {
        return 3;
      } else if (price < 1500) {
        return 4;
      } else {
        return 5;
      }
    } else {
      return null;
    }
  };
  const checkSuggestedPrice = (type) => {
    setButtonType(type);
    const price = parseFloat(suggestedValue);
    dispatch(
      getSuggestedPrice({
        price,
        productId,
      })
    ).then(({ payload }) => {
      if (payload.message) {
        toast.success(`${toastTranslation?.success ? toastTranslation?.success : "You got product"}`);
      } else {
        toast.error(`${toastTranslation?.requried ? toastTranslation?.requried : "Price is too low"}`);
      }
    });
  };
  const convertCommentFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw({ entityMap: {}, blocks: text.blocks }));
  };
  const decrementCounter = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };
  const incrementCounter = () => {
    if (qty < singleProduct.stockQuantity) {
      setQty(qty + 1);
    }
  };
  const addCart = () => {
    if (isAlreadyPurchased) {
      window.open(singleProduct.productUrl);
    } else {
      const data = {
        product: {
          ...singleProduct,
          unitPrice: parseFloat(singleProduct.regularPrice),
        },
        qty: 1,
      };
      toast.success("Added to cart");
      dispatch(addToCart(data));
      history.push("/my-cart");
    }
  };
  const setSuggestedPrice = (price) => {
    setSuggestedValue(price);
  };

  return (
    <div className="app" style={{ backgroundColor: "white" }}>
      <ToastContainer autoClose={20000} />
      <div>
        <Row>
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
        </Row>
        <br />
        <br />
      </div>
      <Row>
        <div className="padding-bottom-10"></div>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              {/* <strong>View Series</strong> */}
              <Button
                type="submit"
                size="sm"
                color="warning"
                onClick={() => history.push("/search")}
              >
                {" "}
                <b>Back To Shop</b>{" "}
              </Button>
            </CardHeader>
            <CardBody>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <i
                    className="fas fa-spinner fa-pulse fa-2x"
                    style={{ color: "green", margin: "240px" }}
                  ></i>
                </div>
              ) : (
                <section className="purchase mb-0" id="buy-now">
                  <div className="container">
                    <FormGroup row>
                      {/* MAIN IMAGE COLUMN */}
                      <Col xs="12" md="8" lg="8">
                        <div className="container">
                          {/* <a target="_blank" href={singleProduct.productUrl}> */}
                          {["movie", "series", "theater"].includes(
                            singleProduct?.digitalType
                          ) && (
                            <>
                              <Col>
                                {singleProduct?.trailerUrl && (
                                  <video
                                    className="video"
                                    controls
                                    key={singleProduct.trailerUrl}
                                  >
                                    <source
                                      src={singleProduct.trailerUrl}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                                {/* <img
                                  src={singleProduct?.images[0]}
                                  className="video"
                                  controls
                                  id="video-thumbnail"
                                /> */}
                              </Col>
                              {
                                <Col className="flexSBNull flexCol">
                                  <Row>
                                    <h5 style={styles.mainTitle}>
                                      {selectedLanguage?.[0]
                                        ? selectedLanguage?.[0].name
                                        : singleProduct?.name}
                                    </h5>
                                  </Row>
                                  <Row>
                                    <h6 style={styles.mainVideoSubtitle}>
                                      {selectedLanguage?.[0]
                                        ? selectedLanguage?.[0].discription
                                        : singleProduct?.specification
                                            ?.blocks[0]?.text}
                                    </h6>
                                  </Row>
                                </Col>
                              }
                              <Col>
                                <div
                                  hidden={false}
                                  className={
                                    singleProduct?.stockQuantity > 0
                                      ? "availability-tag"
                                      : "unavailability-tag"
                                  }
                                >
                                  <b>
                                    {singleProduct?.stockQuantity > 0
                                      ? `${
                                          productDetailTranslation?.inStock
                                            ? productDetailTranslation?.inStock
                                            : "In stock"
                                        }`
                                      : `${
                                          productDetailTranslation?.outStock
                                            ? productDetailTranslation?.outStock
                                            : "Out of Stock"
                                        }`}
                                  </b>
                                </div>
                              </Col>
                            </>
                          )}
                          {singleProduct?.digitalType === "music" && (
                            <div>
                              <Col>
                                <img
                                  src={singleProduct?.images[0]}
                                  className="video"
                                  controls
                                  id="video-thumbnail"
                                />
                              </Col>
                              <div style={{ display: "flex", padding: "15px" }}>
                                <audio controls key={singleProduct?.trailerUrl}>
                                  <source
                                    src={singleProduct?.trailerUrl}
                                    type="audio/mpeg"
                                  />
                                </audio>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                      {/* SERIES COLUMN */}
                      <Col xs="12" md="4" lg="4">
                        {["movie", "series", "theater", "music"].includes(
                          singleProduct?.digitalType
                        ) &&
                          relatedProducts?.map((related, index) => {
                            const translationItem = related.translatedArray?.find(
                                (it) => it.language === userLanguage
                              );
                            return (
                              ["movie", "series", "theater", "music"].includes(
                                related?.digitalType
                              ) && (
                                <Row className={"pb-15"} key={index}>
                                  <Col className="pl-0">
                                    <img
                                      src={related.images[0]}
                                      className="video-series-image-thumbnail"
                                      controls
                                      id="video-thumbnail"
                                      onClick={() =>
                                        viewProduct(
                                          related._id,
                                          related.productType || type
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col className="p-0 flexSBNull flexCol">
                                    <Row>
                                      <h5 style={styles.title}>
                                        {translationItem? translationItem.name : related.name}
                                        {/* {related.name} */}
                                      </h5>
                                    </Row>
                                    <Row>
                                      <h6 style={styles.subtitle}>
                                        {translationItem ? translationItem.discription : related.specification?.blocks[0]?.text}
                                        {/* {related.specification?.blocks[0]?.text} */}
                                      </h6>
                                    </Row>
                                  </Col>
                                </Row>
                              )
                            );
                          })}
                      </Col>
                    </FormGroup>
                  </div>
                </section>
              )}

              <div className="digital-product-details mt-0">
                <div className="digital-product-title-holder"></div>
                <div className="digital-product-title-holder">
                  <div className="pricing-section mt-0">
                    {singleProduct?.dynamicPrice?.currentPrice !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          marginBottom: 10,
                          alignItems: "baseline",
                          flexWrap: "wrap",
                        }}
                      >
                        <div className="attribute-text">
                          {productDetailTranslation?.priceRating
                            ? productDetailTranslation?.priceRating
                            : "Price rating"}{" "}
                          :
                        </div>
                        <div>
                          <RatingWidget
                            product={singleProduct}
                            rating={getStarRating()}
                          />
                        </div>
                        <div className="seperator">|</div>
                        <div
                          className="what-is-price-rate"
                          onClick={() => priceModal.current.show()}
                        >
                          <u>
                            {shopTranslation?.popUpHeading
                              ? shopTranslation?.popUpHeading
                              : "What is price rate"}
                          </u>
                        </div>
                      </div>
                    )}
                    <div className="attribute-text">
                      {singleProduct?.isResidency
                        ? `${
                            productDetailTranslation?.suggestValue
                              ? productDetailTranslation?.suggestValue
                              : "Suggest a daily rate(USD)"
                          } :`
                        : `${
                            productDetailTranslation?.suggestValue
                              ? productDetailTranslation?.suggestValue
                              : "Suggest Value(USD)"
                          } :`}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 8,
                        justifyContent: "space-in-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          minWidth: 205,
                          marginBottom: 10,
                        }}
                      >
                        <input
                          autoFocus
                          className={
                            !suggestedPrice
                              ? "price-input"
                              : "price-input-accepted"
                          }
                          type="number"
                          min={0}
                          placeholder={
                            productDetailTranslation?.placeHolderUSD
                              ? productDetailTranslation?.placeHolderUSD +
                                " USD"
                              : "Offer in USD"
                          }
                          onChange={(e) => setSuggestedPrice(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          minWidth: 205,
                        }}
                      >
                        <button
                          className="buy-now"
                          disabled={singleProduct?.stockQuantity <= 0}
                          onClick={() => checkSuggestedPrice("shipping")}
                        >
                          {singleProduct?.isResidency
                            ? `${
                                productDetailTranslation?.rentbutton
                                  ? productDetailTranslation?.rentbutton
                                  : "Rent Now"
                              }`
                            : `${
                                productDetailTranslation?.buyButton
                                  ? productDetailTranslation?.buyButton
                                  : "Buy Now"
                              }`}
                          {/* {singleProduct?.isResidency ? "Rent Now" : "Buy Now"} */}
                        </button>
                        {!singleProduct?.isResidency && (
                          <button
                            disabled={singleProduct?.stockQuantity <= 0}
                            className="add-to-cart"
                            onClick={() => checkSuggestedPrice("addToCart")}
                          />
                        )}
                      </div>
                    </div>

                    <div className="pwiw-input-helper-text">
                      {loading
                        ? "Evaluating offer"
                        : singleProduct?.hasPriceError ||
                          !singleProduct?.hasMadeOffer
                        ? `${
                            productDetailTranslation?.instructedParaLine
                              ? productDetailTranslation?.instructedParaLine
                              : "Tell us how much you would value this product"
                          }`
                        : singleProduct?.isResidency
                        ? "You can rent this property now"
                        : "You can purchase this now"}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div className={"flexCenter"}>
        <h2>
          {productDetailTranslation?.relatedProduct
            ? productDetailTranslation?.relatedProduct
            : productDetailContent?.relatedProduct}
          {/* Related Products */}
        </h2>
      </div>

      <div className="related-item-holder flexCenter">
        {relatedProducts?.map(
          (product, index) =>
            ["movie", "series", "theater", "music"].includes(
              product?.digitalType
            ) && (
              <ProductPreviewBox
                key={index}
                product={product}
                viewProduct={(type) => {
                  viewProduct(product._id, product.productType || type);
                }}
              />
            )
        )}
      </div>
      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

export default ViewTvSeries;

const styles = {
  mainTitle: {
    margin: "10px 0px",
    fontWeight: 600,
    fontSize: "1.5rem",
    textAlign: "left",
  },
  mainVideoSubtitle: {
    textAlign: "left",
    opacity: 0.7,
    marginTop: 15,
  },
  title: {
    textAlign: "left",
    fontWeight: 600,
  },
  subtitle: {
    textAlign: "left",
    opacity: 0.7,
  },
};
