import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  InputGroup,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import { addFilter, getOrders, updateOrder } from "../../redux/reducers/seller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "./order.css";
import { nextPagesItems } from "../../helpers/custom";

var divStyle = {
  marginLeft: "10%",
};

const Order = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const ordersTranslation =
    translationState?.[0]?.translations?.order?.[userLanguage];
  const otherOrderTranslation =
    translationState?.[0]?.translations?.otherOrder?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];

  const [statusOptions, setStatusOptions] = useState([
    `${
      otherOrderTranslation?.pending
        ? otherOrderTranslation?.pending
        : "Pending"
    }`,
    `${
      otherOrderTranslation?.acknoeledge
        ? otherOrderTranslation?.acknoeledge
        : "Acknowledged"
    }`,
    `${
      otherOrderTranslation?.deliver
        ? otherOrderTranslation?.deliver
        : "Delivered"
    }`,
    `${
      otherOrderTranslation?.shipped
        ? otherOrderTranslation?.shipped
        : "Shipped"
    }`,
    `${
      otherOrderTranslation?.cancelled
        ? otherOrderTranslation?.cancelled
        : "Cancelled"
    }`,
  ]);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const { orders, count, filters, loading } = useSelector(
    (state) => state.seller
  );
  const { user } = useSelector(({ auth }) => auth);
  const { value: PageNo } = filters.find((item) => item.field === "pageNo");
  const { value: PerPage } = filters.find((item) => item.field === "perPage");
  const [selectedPerPage, setSelectedPerPage] = useState(5);
  const NextCount = nextPagesItems(Math.abs(PageNo - 1), PerPage, count);
  const clickableNext = NextCount > PerPage;

  useEffect(() => {
    dispatch(
      addFilter({
        field: "perPage",
        value: selectedPerPage,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    setSelectedPerPage(5);
    dispatch(getOrders());
  }, []);
  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getOrders());
  };

  const viewOrders = (order_id) => {
    history.push(`/${user?.userType}/order/view-order/${order_id}`);
  };

  const paginate = (pageNo) => {
    if (pageNo > 0 && NextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: PerPage,
        })
      );
      dispatch(getOrders());
    }
  };
  const updateStatus = (OId, orderStatus) => {
    const data = {
      OId,
      orderStatus,
    };
    dispatch(updateOrder(data)).then(() => {
      toast.success(
        `${
          toastTranslation?.success
            ? toastTranslation?.success
            : "Order Status Updated Successfully"
        }`
      );
      dispatch(getOrders());
    });
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getOrders());
  };

  return (
    <div className="animated fadeIn" style={{ marginTop: "10px" }}>
      <ToastContainer autoClose={20000} />
      <Row>
        <Col>
          <Card className="mt-5">
            <CardHeader>
              <Row>
                <Col xs="12" sm="9">
                  <b>
                    {ordersTranslation?.orders
                      ? ordersTranslation?.orders
                      : "Orders"}
                  </b>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              <Row>
                <Col xs="12" sm="4">
                  <InputGroup>
                    <Input
                      type="text"
                      id="input1-group2"
                      onChange={searchElement}
                      className="search-color"
                      name="input1-group2"
                      placeholder={
                        ordersTranslation?.search
                          ? ordersTranslation?.search
                          : " Search Order by id"
                      }
                    />
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <div className="tableFixHead">
                <Table hover bordered striped responsive size="sm">
                  <thead align="center" style={{ position: "sticky" }}>
                    <tr className="header-color">
                      <th>
                        {ordersTranslation?.orderNo
                          ? ordersTranslation?.orderNo
                          : "Order Number"}
                      </th>
                      <th>
                        {ordersTranslation?.orderstatus
                          ? ordersTranslation?.orderstatus
                          : "Order Status"}
                      </th>
                      <th>
                        {ordersTranslation?.total
                          ? ordersTranslation?.total
                          : "Total"}
                      </th>
                      <th>
                        {ordersTranslation?.data
                          ? ordersTranslation?.data
                          : "Order Date"}
                      </th>
                      <th>
                        {ordersTranslation?.action
                          ? ordersTranslation?.action
                          : "Action"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length > 0 && !loading ? (
                      orders.map((item, index) => (
                        <tr align="center">
                          <td
                            align="center"
                            style={{ verticalAlign: "middle" }}
                          >
                            {item.orderId}
                          </td>
                          <td
                            align="center"
                            style={{ verticalAlign: "middle" }}
                          >
                            <select
                              type="select"
                              className="order-status-input"
                              name="country"
                              value={item.orderStatus}
                              onChange={(e) =>
                                updateStatus(item._id, e.target.value)
                              }
                              disabled={user.userType === "admin"}
                            >
                              {statusOptions.map((itemstatus, index) =>
                                item.paymentMethod === "cash" ? (
                                  index <= 1 && (
                                    <option key={index} value={itemstatus}>
                                      {itemstatus}
                                    </option>
                                  )
                                ) : (
                                  <option key={index} value={itemstatus}>
                                    {itemstatus}
                                  </option>
                                )
                              )}
                            </select>
                          </td>
                          <td
                            align="center"
                            style={{ verticalAlign: "middle" }}
                          >
                            {item.total}
                          </td>
                          <td
                            align="center"
                            style={{ verticalAlign: "middle" }}
                          >
                            {moment(item.orderDate).format(
                              "MMM D YYYY, h:mm A"
                            )}
                          </td>
                          <td align="center">
                            <Button
                              type="submit"
                              onClick={() => {
                                viewOrders(item._id);
                              }}
                              color="warning"
                              style={divStyle}
                              btn="sm"
                            >
                              <b>
                                {otherOrderTranslation?.viewOrder
                                  ? otherOrderTranslation?.viewOrder
                                  : "View Order"}
                              </b>
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : loading ? (
                      <td colSpan="8" className="py-5">
                        <div style={{ textAlign: "center" }}>
                          <i
                            className="fas fa-spinner fa-pulse fa-2x"
                            style={{ color: "green" }}
                          ></i>
                        </div>
                      </td>
                    ) : (
                      <tr
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginTop: "50px",
                        }}
                      >
                        <td colSpan="8" className="py-5">
                          <p>
                            {ordersTranslation?.notAvail
                              ? ordersTranslation?.notAvail
                              : "Orders not Available"}
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <nav>
                <Pagination className="mt-3">
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(PageNo - 1)}
                      disabled={PageNo === 1 ? true : false}
                      style={{
                        backgroundColor: "#e4e7ea",
                      }}
                    >
                      {reportingTranslation?.previousButton
                        ? reportingTranslation?.previousButton
                        : "Prev"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(PageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: "#e4e7ea",
                      }}
                      className="mx-2"
                    >
                      {reportingTranslation?.nextButton
                        ? reportingTranslation?.nextButton
                        : "Next"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        {productTranslation?.itemPerPage
                          ? productTranslation?.itemPerPage
                          : "Items per Page"}
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, PageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Order;
