import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  getsingleSellerSupport,
  replySellerSupport,
} from "../../../redux/reducers/admin";
import {
  GetChat,
  UpdateChatThread,
  UpdateReadChats,
  readTickets,
} from "../../../redux/reducers/user";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-picky/dist/picky.css"; // Include CSS

const ViewTicket = () => {
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { pathname } = history.location;

  const { user } = useSelector((state) => state.auth);
  const { singlesellersupport, loading } = useSelector((state) => state.admin);
  const { chatList } = useSelector((state) => state.user);

  const [message, setMessage] = useState("");
  const [chatType, setChatType] = useState("seller"); // `customer` for customer-seller chat
  const [viewTicketData, setViewTicketData] = useState({ chat: {}, to: "" });

  useEffect(() => {
    if (id) {
      if (pathname.includes("/seller/support/view-customer-ticket")) {
        dispatch(GetChat({ id }));
        dispatch(UpdateReadChats({ id }));
        setChatType("customer");
      } else {
        dispatch(getsingleSellerSupport(id));
        dispatch(readTickets([id]));
      }
    }
  }, []);

  useEffect(() => {
    let data = {};
    if (chatType === "customer" /* && chatList?.length */) {
      data = {
        ...data,
        chat: chatList?.length ? chatList[0] : {},
        to: chatList[0]?.from?._id,
      };
    } else if (chatType === "seller" /* && singlesellersupport?._id */) {
      data = {
        ...data,
        chat: singlesellersupport || {},
        to: singlesellersupport.from?._id,
      };
    } else {
      data = { ...data, chat: {} };
    }
    setViewTicketData({ ...viewTicketData, ...data });
  }, [singlesellersupport, chatList]);
  //   useEffect(() => {
  //     if (user.userType !== "admin" && singleAnnouncement?._id) {
  //       dispatch(readAnnouncements([id]));
  //       dispatch(getAnnouncements());
  //     }
  //   }, [singleAnnouncement, id]);

  //   const send = () => {
  //     const data = {
  //       message,
  //       _id: id,
  //     };
  //     dispatch(replyAnnouncement(data)).then(() => {
  //       setMessage("");
  //       dispatch(getSingleAnnouncement(id));
  //     });
  //   };

  const send = async (id) => {
    if (!message.trim()) {
      return toast.info("Type your message...");
    }
    const data = { message, id, to: viewTicketData.to };
    if (chatType === "customer") {
      await dispatch(UpdateChatThread(data));
      dispatch(GetChat({ id }));
    } else {
      await dispatch(replySellerSupport(data));
      dispatch(getsingleSellerSupport(id));
    }
    setMessage("");
  };

  useEffect(() => {
    divRef.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  });

  return (
    <>
      <div className="animated fadeIn">
        <ToastContainer autoClose={20000} />
        <Row>
          <Col sm="2" md="2"></Col>
          <Col xs="12" sm="8" md="8">
            {loading ? (
              <div style={{ margin: "240px", textAlign: "center" }}>
                <i
                  className="mx-auto fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green" }}
                ></i>
              </div>
            ) : (
              <>
                <Card className="mt-5">
                  <CardHeader>
                    <b>
                      {chatType === "customer" ? "View Chat" : "View Ticket"}
                    </b>
                  </CardHeader>
                  <CardBody className="card-body">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs="4" sm="4" md="4">
                            <Label>
                              <b>
                                {chatType === "customer"
                                  ? "Chat Id"
                                  : "Ticket Id"}
                              </b>
                            </Label>
                          </Col>

                          <Col xs="8" sm="8" md="8">
                            <Label>{viewTicketData.chat?._id}</Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="4" sm="4" md="4">
                            <Label>
                              <b>Subject</b>
                            </Label>
                          </Col>

                          <Col xs="8" sm="8" md="8">
                            <Label>{viewTicketData.chat?.subject}</Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="4" sm="4" md="4">
                            <Label>
                              <b>from</b>
                            </Label>
                          </Col>
                          <Col xs="8" sm="8" md="8">
                            <Row>
                              <Label>{viewTicketData.chat?.from?.email},</Label>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="4" sm="4" md="4">
                            <Label>
                              <b>Date & Time</b>
                            </Label>
                          </Col>

                          <Col xs="8" sm="8" md="8">
                            <Label>
                              {moment(viewTicketData.chat?.createdAt).format(
                                "MMM Do YYYY, h:mm:ss a"
                              )}
                            </Label>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
                <div className="messages-wrapper">
                  {viewTicketData.chat?.message && (
                    <Row
                      className="announcementMessage"
                      style={{
                        float:
                          viewTicketData.chat?.from == user._id
                            ? "right"
                            : "left",
                      }}
                    >
                      {viewTicketData.chat?.message}
                    </Row>
                  )}
                  <br />
                  {viewTicketData.chat?.thread?.map((item, index) => (
                    <div key={index}>
                      <Row
                        className="announcementMessage"
                        style={{
                          float: item.from == user._id ? "right" : "left",
                        }}
                      >
                        {item.message}
                      </Row>
                      <br />
                    </div>
                  ))}
                  <div ref={divRef} />
                </div>
                <Row className="send-field">
                  <InputGroup className="input-prepend">
                    <Input
                      placeholder="Type..."
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      size="16"
                      type="text"
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={() => send(viewTicketData.chat?._id)}
                        color="warning"
                      >
                        Send
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Row>
              </>
            )}
          </Col>
          <Col sm="2" md="2"></Col>
        </Row>
      </div>
    </>
  );
};

export default ViewTicket;
