export default {
  items: [
    {
      name: "Dashboard",
      url: "/user/profile",
      icon: "icon-speedometer",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Wallet",
      url: "/user/wallet",
      icon: "fas fa-wallet",
    },
    {
      name: "Lend Loan",
      url: "/user/lend-loan",
      icon: "fas fa-dollar",
    },
  ],
};
