import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../config/axios";
import { getMessage } from "../helpers";

export const getCategories = createAsyncThunk(
  "/auth/product/categories",
  async (data, { getState }, thunkAPI) => {
    try {
      const response = await axios.get("/search/category/get");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getCmsPages = createAsyncThunk(
  "/cms/pages",
  async (data, { getState }, thunkAPI) => {
    try {
      const response = await axios.get("/search/cms-pages/get");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getAds = createAsyncThunk(
  "/user/ads/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const { filters } = state.auth;
      const response = await axios.get(
        `/search/ads/get?filters=${JSON.stringify(filters)}`
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getIp = createAsyncThunk(
  "/user/Ip/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const response = await axios.get(`/search/getIp`);
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const updateAd = createAsyncThunk(
  "/user/ads/update",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const response = await axios.post(`/search/ads/update`, data);
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getProducts = createAsyncThunk(
  "/user/products/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const { filters, user } = state.auth;
      let newfilters = JSON.parse(JSON.stringify(filters));
      if (user !== null) {
        newfilters.push({ field: "userId", value: user._id });
      }
      const response = await axios.get(
        `/search/products/get?filters=${JSON.stringify(newfilters)}`
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getAdvertisementProducts = createAsyncThunk(
  "/user/feactured/products/get",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const { filters, user } = state.auth;
      let newfilters = JSON.parse(JSON.stringify(filters));
      if (user !== null) {
        newfilters.push({ field: "userId", value: user._id });
      }
      const response = await axios.get(
        `/search/products/get?filters=${JSON.stringify(newfilters)}`
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "/user/products/get/id",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/search/product/get/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getRelatedProducts = createAsyncThunk(
  "/user/products/related/id",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/search/product/related/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getSuggestedPrice = createAsyncThunk(
  "/user/products/suggestedPrice",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `/search/product/pricing/suggest/${data.productId}?price=${data.price}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getProductBrands = createAsyncThunk(
  "/seller/products/brands",
  async (data, { getState }, thunkAPI) => {
    try {
      const response = await axios.get(`/search/products/brands`);
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getSingleFooterPage = createAsyncThunk(
  "/footer/pages/id",
  async (data, { getState }, thunkAPI) => {
    try {
      const response = await axios.get(`/search/products/brands`);
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const verifyDigitalSignature = createAsyncThunk(
  "/seller/signature/verify",
  async (data, { getState }, thunkAPI) => {
    try {
      const response = await axios.post(`/digital-signature/verify`, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getAllSubCategories = createAsyncThunk(
  "/admin/product/all/subcategories",
  async (id, { getState }, thunkAPI) => {
    try {
      const response = await axios.get(`/search/products/subcategory`);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const getProfileData = createAsyncThunk(
  "/user/profileData",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/user/userdetails/get`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getSellerAuthToken = createAsyncThunk(
  "/seller/authToken",
  async (id, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(`/user/payouts/refreshtoken`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const saveTransferMethod = createAsyncThunk(
  "/seller/saveTransferMethod",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `/user/payouts/add-transfermethod`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const SignIn = createAsyncThunk(
  "/auth/login",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/auth/login", data);
      response.data.message === "Resume" ? toast.error("Your values are not correct or account Deactive") : ""
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const SocialSignIn = createAsyncThunk(
  "/auth/sociallogin",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/auth/sociallogin", data);
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const confirmAccount = createAsyncThunk(
  "/auth/confirmAccount",
  async (authToken, thunkAPI) => {
    try {
      const response = await axios.post(
        "/user/confirmAccount",
        {},
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const SignUp = createAsyncThunk(
  "/auth/signup",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/auth/signup", data);
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const ResetPassword = createAsyncThunk(
  "/user/resetpassword",
  async (user, thunkAPI) => {
    try {
      const response = await axios.put(
        "/user/resetpassword",
        { password: user.password },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
        }
      );
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      toast.error(err.message);
      if (err.response && err.message) {
        return thunkAPI.rejectWithValue({
          err: err.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);
export const ForgotPassword = createAsyncThunk(
  "/user/forgotPassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/auth/forgotPassword", data);
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const validatePassword = createAsyncThunk(
  "/user/validatePassword",
  async (user, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post("/user/validatePassword", user, {
        headers: {
          Authorization: `bearer ${authToken}`,
        },
      });
      const toastTranslation = getMessage(state, "success")
        toast.success(toastTranslation ? toastTranslation : "Passwords Verified");
      // toast.success("Passwords Verified");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const switchRole = createAsyncThunk(
  "/user/switchRole",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/sellers/switchRole`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const saveDispute = createAsyncThunk(
  "/user/dispute",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(`/dispute/create`, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const toastTranslation = getMessage(state, "success")
        toast.success(toastTranslation ? toastTranslation : "Dispute sent to admin");
      // toast.success("Dispute sent to admin");
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const ClaimCopyright = createAsyncThunk(
  "/user/claim-copy-right",
  async (data, { getState }, thunkAPI) => {
    try {
      const response = await axios.post(`/copyright/create`, data, {});
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const ContactUs = createAsyncThunk(
  "/user/contact-us",
  async (data, { getState }, thunkAPI) => {
    try {
      const response = await axios.post(`/contact/email`, data, {});
      return response.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const getExhangeRate = createAsyncThunk(
  "/user/getExhangeRate",
  async (base, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `/search/currency/usd-exhange?base=${base}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const paymentConfig = createAsyncThunk(
  "/auth/payments/config",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get("/search/payments/config", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.message,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const EditUserInfo = createAsyncThunk(
  "/auth/edit",
  async (data, { getState }, thunkAPI) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const res = await axios.put("/user/updateinfo", data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return res.data;
    } catch (err) {
      toast.error(err.response.data);
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

const auth = createSlice({
  name: "auth",
  initialState: {
    userLanguage: "en",
    message: "",
    loading: false,
    err: "",
    done: false,
    emailSent: null,
    signup: null,
    routeLoading: true,
    user: {},
    singleProduct: {},
    isAlreadyPurchased: false,
    verified: false,
    verifiedProducts: [],
    relatedProducts: [],
    suggestedPrice: false,
    cmsPages: [],
    usdRate: 1,
    paymentConfiguration: {},
    braintreeToken: "",
    isLogged: null,
    token: "",
    editImageLoader: false,
    cart: [],
    products: [],
    brands: [],
    ads: [],
    currentIP: "",
    allSubCategories: [],
    filters: [
      {
        field: "pageNo",
        value: 1,
      },
    ],
    count: 0,
    productCategories: [],
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    addFilter(state, { payload: { field, value } }) {
      const filters = state?.filters ? [...state.filters] : [];
      const checkFilter = filters.findIndex((item) => item.field === field);
      if (checkFilter !== -1) {
        filters[checkFilter] = { field, value };
      } else {
        filters.push({ field, value });
      }
      state["filters"] = filters;
    },
    addToCart(state, { payload }) {
      const newCart = [...state.cart];
      const product = newCart.findIndex(
        (item) => item.product._id === payload.product._id
      );
      if (product !== -1) {
        newCart[product] = payload;
      } else {
        newCart.push(payload);
      }
      return {
        ...state,
        cart: newCart,
      };
    },
    updateCart(state, { payload }) {
      const newCart = [...state.cart];
      const product = newCart.findIndex(
        (item) => item.product._id === payload.product._id
      );
      newCart[product] = payload;
      return {
        ...state,
        cart: newCart,
      };
    },
    removeFromCart(state, { payload }) {
      let newCart = [...state.cart];
      newCart = newCart.filter((item) => item.product._id !== payload);
      return {
        ...state,
        cart: newCart,
      };
    },
    clearCart(state, action) {
      return {
        ...state,
        cart: [],
      };
    },
    clearProduct(state, action) {
      return {
        ...state,
        products: [],
      };
    },
    ClearState(state, action) {
      return {
        user: {},
        err: "",
        done: false,
        confirmPassword: "",
        emailSent: null,
        signup: null,
        agreePolicy: false,
      };
    },
    logout(state, action) {
      return {
        ...state,
        user: null,
        cart: [],
        usdRate: 1,
        token: "",
        loading: false,
        done: false,
        isLogged: false,
        imageKey: "",
      };
    },
  },
  extraReducers: {
    [SignIn.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [SignIn.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload.user,
      token: action.payload.token.token,
      filters: [
        {
          field: "pageNo",
          value: 1,
        },
      ],
      loading: false,
      done: true,
      err: "",
      isLogged: true,
      userLanguage: "en"
    }),
    [SignIn.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err,
    }),
    [getCategories.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getCategories.fulfilled]: (state, action) => ({
      ...state,
      productCategories: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [getCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getCmsPages.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getCmsPages.fulfilled]: (state, action) => ({
      ...state,
      cmsPages: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [getCmsPages.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getProducts.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getProducts.fulfilled]: (state, action) => ({
      ...state,
      products: [...state?.products, ...action.payload.data.products],
      count: action.payload.data.count,
      loading: false,
      done: true,
      err: "",
    }),
    [getProducts.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAdvertisementProducts.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAdvertisementProducts.fulfilled]: (state, action) => ({
      ...state,
      products: action.payload.data.products,
      count: action.payload.data.count,
      loading: false,
      done: true,
      err: "",
    }),
    [getAdvertisementProducts.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSingleProduct.pending]: (state, action) => ({
      ...state,
      isAlreadyPurchased: false,
      loading: true,
    }),
    [getSingleProduct.fulfilled]: (state, action) => ({
      ...state,
      singleProduct: action.payload.data,
      isAlreadyPurchased: action.payload.isAlreadyPurchased,
      loading: false,
      done: true,
      err: "",
    }),
    [getSingleProduct.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getRelatedProducts.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getRelatedProducts.fulfilled]: (state, action) => ({
      ...state,
      relatedProducts: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [getRelatedProducts.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSuggestedPrice.pending]: (state, action) => ({
      ...state,
      suggestedPrice: false,
      loading: true,
    }),
    [getSuggestedPrice.fulfilled]: (state, action) => ({
      ...state,
      suggestedPrice: action.payload.message,
      loading: false,
      err: "",
    }),
    [getSuggestedPrice.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [paymentConfig.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [paymentConfig.fulfilled]: (state, action) => ({
      ...state,
      paymentConfiguration: action.payload.data,
      loading: false,
      done: true,
      err: "",
    }),
    [paymentConfig.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAds.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAds.fulfilled]: (state, action) => ({
      ...state,
      ads: action.payload.data,
      count: action.payload.count,
      loading: false,
      done: true,
      err: "",
    }),
    [getAds.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getIp.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getIp.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      currentIP: action.payload.data,
      done: true,
      err: "",
    }),
    [getIp.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [updateAd.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [updateAd.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      done: true,
      err: "",
    }),
    [updateAd.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getProductBrands.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getProductBrands.fulfilled]: (state, action) => ({
      ...state,
      brands: action.payload.data,
      loading: false,
      err: "",
    }),
    [getProductBrands.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [verifyDigitalSignature.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [verifyDigitalSignature.fulfilled]: (state, action) => ({
      ...state,
      verified: action.payload?.verified,
      verifiedProducts: action.payload?.products,
      loading: false,
      err: "",
    }),
    [verifyDigitalSignature.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getAllSubCategories.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getAllSubCategories.fulfilled]: (state, action) => ({
      ...state,
      allSubCategories: action.payload.data,
      loading: false,
      err: "",
    }),
    [getAllSubCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getProfileData.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getProfileData.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      user: action.payload.data,
      err: "",
    }),
    [getProfileData.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [getSellerAuthToken.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getSellerAuthToken.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      braintreeToken: action.payload.authToken,
      err: "",
    }),
    [getSellerAuthToken.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [saveTransferMethod.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [saveTransferMethod.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [saveTransferMethod.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [saveDispute.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [saveDispute.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
    }),
    [saveDispute.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
    [switchRole.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [switchRole.fulfilled]: (state, action) => ({
      userLanguage: state.userLanguage,
      user: action.payload.user,
      token: action.payload.token.token,
      cart: [],
      loading: false,
      done: true,
      err: "",
      isLogged: true,
      usdRate: 1,
    }),
    [switchRole.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err,
    }),
    [SocialSignIn.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [SocialSignIn.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload.user,
      token: action.payload.token.token,
      cart: [],
      loading: false,
      done: true,
      err: "",
      isLogged: true,
    }),
    [SocialSignIn.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err,
    }),
    [SignUp.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [SignUp.fulfilled]: (state, action) => ({
      loading: false,
      done: true,
      err: "",
    }),
    [SignUp.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err,
    }),

    [confirmAccount.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [confirmAccount.fulfilled]: (state, action) => ({
      loading: false,
      done: true,
      err: "",
    }),
    [confirmAccount.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err,
    }),

    // update password
    [validatePassword.pending]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [validatePassword.fulfilled]: (state, { payload }) => ({
      ...state,
      message: payload?.message,
      loading: false,
      err: "",
    }),
    [validatePassword.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      err: payload?.err,
    }),

    [ResetPassword.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [ResetPassword.fulfilled]: (state, { payload }) => ({
      ...state,
      loading: false,
      err: "",
      done: true,
      message: payload.message,
    }),
    [ResetPassword.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      done: false,
      err: payload.err,
    }),
    [ForgotPassword.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [ForgotPassword.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
      done: true,
      message: action.payload.message,
    }),
    [ForgotPassword.rejected]: (state, action) => ({
      ...state,
      loading: false,
      done: false,
      err: action.payload.err,
    }),
    [ClaimCopyright.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [ClaimCopyright.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
      done: true,
      message: action.payload.message,
    }),
    [ClaimCopyright.rejected]: (state, action) => ({
      ...state,
      loading: false,
      done: false,
      err: action.payload.err,
    }),
    [ContactUs.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [ContactUs.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: "",
      done: true,
      message: action.payload.message,
    }),
    [ContactUs.rejected]: (state, action) => ({
      ...state,
      loading: false,
      done: false,
      err: action.payload.err,
    }),
    [getExhangeRate.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [getExhangeRate.fulfilled]: (state, action) => ({
      ...state,
      usdRate: action.payload.usdRate || 1,
      loading: false,
      err: "",
    }),
    [getExhangeRate.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),

    [EditUserInfo.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [EditUserInfo.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload.response,
      loading: false,
      done: true,
      err: "",
      isLogged: true,
    }),
    [EditUserInfo.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.error.message,
    }),
  },
});

const { reducer, actions } = auth;

export const {
  SetState,
  ClearState,
  logout,
  addToCart,
  updateCart,
  removeFromCart,
  clearCart,
  addFilter,
  clearProduct,
} = actions;

export default reducer;
