import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFilter, getCategories } from "../../../redux/reducers/admin";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBlock,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../product.css";
import { nextPagesItems } from "../../../helpers/custom";
import moment from "moment";
import { styled } from "@material-ui/core";

var divStyle = {
  marginLeft: "65%",
};

const Category = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const urlArray = history.location.pathname.split("/");
  const categoryType = urlArray[urlArray.length - 1];
  const { productCategories, filters, count, loading } = useSelector(
    (state) => state.admin
  );
  const { value: PageNo } = filters.find((item) => item.field === "pageNo");
  const { value: PerPage } = filters.find((item) => item.field === "perPage");
  const [selectedPerPage, setSelectedPerPage] = useState(5);
  const NextCount = nextPagesItems(Math.abs(PageNo - 1), PerPage, count);
  const clickableNext = NextCount > PerPage;

  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: categoryType,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: selectedPerPage,
      })
    );
    dispatch(getCategories());
  }, [categoryType]);

  const paginate = (pageNo) => {
    if (pageNo > 0 && NextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: PerPage,
        })
      );
      dispatch(getCategories());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getCategories());
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col>
          <Card style={{ marginTop: "50px" }}>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div>
                  <b>Category List</b>
                </div>
                <div>
                  <Button
                    onClick={() =>
                      history.push(
                        `/admin/product/category/${categoryType}/add`
                      )
                    }
                    size="sm"
                    // style={divStyle}
                    color="warning"
                    style={{ width: "auto" }}
                  >
                    <b>Add Category</b>
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBlock className="card-body tableFixHead">
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr>
                    <th style={{ width: "50%" }}> Name</th>
                    <th style={{ width: "20%" }}> Status</th>
                    <th style={{ width: "30%" }}> Created Date</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan="8" className="py-5">
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green" }}
                        ></i>
                      </div>
                    </td>
                  ) : productCategories.length > 0 && !loading ? (
                    productCategories.map((item, index) => (
                      <tr>
                        <td> {item.name}</td>
                        <td align="center"> Active</td>
                        <td align="center">
                          {moment(item.createdAt).format("MMM D YYYY, hh:m A")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      <td colSpan="8" className="py-5">
                        <p>Categories not Available</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <nav className="mt-3">
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(PageNo - 1)}
                      disabled={PageNo === 1 ? true : false}
                      style={{
                        backgroundColor:
                          (PageNo === 1 ? true : false) && "#e4e7ea",
                      }}
                    >
                      Prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(PageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: !clickableNext ? "#e4e7ea" : null,
                      }}
                      className="ml-2 mr-2"
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        Items per Page
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, PageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBlock>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Category;
