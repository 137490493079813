import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button, Collapse } from "reactstrap";
import { AppHeader } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { Fab, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { chunk } from "lodash";
import {
  CreateChat,
  GetChat,
  GetShopDetails,
  getUser,
} from "../../../redux/reducers/user";
import { SetState, addFilter, getProducts } from "../../../redux/reducers/auth";
import DefaultHeader from "../../Header/DefaultHeader";
import SubscriberRTCComponent from "../../Events/videoEvent/subscriber";
import ViewSellerSupportDetail from "../../CustomerAccount/ViewSellerSupportDetail";
import RelatedProducts from "../../Auth/ProductDetail/RelatedProducts";
import ProductPreviewBox from "../../Auth/Home/ProductPreviewBox";
import { PAGINATION_MODEL } from "../../../constants";
import profilePlaceholder from "../User/images/other/profpic-1.png";
import coverPlaceholder from "../User/images/other/group-1.png";
import oflineImage from "../User/images/other/oflineImages.png";

import "./UserProfile.css";

const divStyle = { marginLeft: "10%" };

const UserProfile = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { singleProduct, userLanguage, filters, count } = useSelector(
    (state) => state.auth
  );
  const { userProfileData, translationState, shopDetails, allWebContent } =
    useSelector((state) => state.user);
  const { products } = useSelector((state) => state.auth);
  const [currTab, setCurrTab] = useState("streaming");
  const [openChatFab, setOpenChatFab] = useState(false);
  const [sellerDetail, setSellerDetail] = useState({});
  const [translatedContent, setTranslatedContent] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [pagination, setPagination] = useState(PAGINATION_MODEL);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [userProfile, setUserProfile] = useState({
    subscriberProductToken: "",
    isLive: false,
    sellerInfo: null,
    shopDetails: { coverUrl: "", profileUrl: "" },
    products: [],
  });
  const [isJoined, setIsJoined] = useState(false);

  const ShopContent = allWebContent?.[0]?.shop;
  const shopTranslation =
    translationState?.[0]?.translations?.shop?.[userLanguage];
  const slideItems = chunk(userProfile.products, 3);

  useEffect(() => {
    if (singleProduct) {
      const sellerId = singleProduct.sellerId?.id;
      dispatch(SetState({ field: "products", value: [] }));
      dispatch(addFilter({ field: "pageNo", value: 1 }));
      dispatch(addFilter({ field: "perPage", value: 10 }));
      dispatch(addFilter({ field: "sellerId", value: sellerId }));
      dispatch(getProducts());
      dispatch(getUser({ id: sellerId }));
      dispatch(GetShopDetails({ sellerId }));
      createOrFetchChat(sellerId);
    }
    return () => {
      dispatch(addFilter({ field: "sellerId", value: "" }));
    };
  }, []);

  useEffect(() => {
    const { value: perPage } = filters.find((item) => item.field === "perPage");
    const { value: pageNo } = filters.find((item) => item.field === "pageNo");
    setPagination({ ...pagination, pageNo, perPage });
  }, [filters]);

  useEffect(() => {
    if (products?.length == count) {
      setHasMoreItems(false);
    } else if (products?.length <= count) {
      setHasMoreItems(true);
    }
    setUserProfile({
      ...userProfile,
      products: products?.length ? products : [],
    });
  }, [products]);

  useEffect(() => {
    let data = {};
    if (userProfileData) {
      data = { ...data, sellerInfo: userProfileData };
    }
    if (shopDetails) {
      data = { ...data, shopDetails };
    }
    setUserProfile({ ...userProfile, ...data });
  }, [userProfileData, shopDetails]);

  useEffect(() => {
    if (translationState?.length) {
      const sellerInfoTranslation =
        translationState[0].translations?.sellerInfo?.[userLanguage];
      sellerInfoTranslation
        ? setTranslatedContent({
            ...translatedContent,
            ...sellerInfoTranslation,
          })
        : setTranslatedContent({});
    }
  }, [translationState, userLanguage]);

  const createOrFetchChat = async (sellerId) => {
    const data = {
      subject: "chat",
      message: "Hello! How may we assist you?",
      sellerId,
    };
    await dispatch(CreateChat(data));
    dispatch(GetChat({}));
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === slideItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? slideItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const viewProduct = (product) => {
    if (product.product_type == "digital") {
      window.location = "#/productdetail?id=" + product._id;
    } else {
      window.location = "#/productdetail?id=" + product._id;
    }
  };
  const convtDate = () => {
    if (userProfile.sellerInfo) {
      const d = new Date(userProfile.sellerInfo.createdAt);
      return <p>{d.toDateString()}</p>;
    } else {
      return <p>empty</p>;
    }
  };
  const displayMessage = () => {
    return (
      <div className="message-section-1 ">
        <Fab
          aria-label="Chat"
          className={classes.fab}
          color="primary"
          onClick={() => setOpenChatFab(!openChatFab)}
        >
          Chat
        </Fab>
        <Collapse
          className={classes.fab1}
          isOpen={openChatFab}
          horizontal="true"
        >
          <ViewSellerSupportDetail type="customer" />
        </Collapse>
      </div>
    );
  };
  const fetchData = () => {
    const { pageNo } = pagination;
    if (userProfile.products?.length <= count) {
      dispatch(addFilter({ field: "pageNo", value: Number(pageNo) + 1 }));
      dispatch(getProducts());
      setHasMoreItems(true);
    } else {
      setHasMoreItems(false);
    }
  };

  const coverUrl = userProfile.shopDetails.coverUrl || coverPlaceholder;

  const divStyle = {
    width: "100%",
    height: "400px",
    backgroundImage: `url(${coverUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <InfiniteScroll
      pageStart={1}
      dataLength={userProfile.products?.length || 10}
      next={fetchData}
      hasMore={hasMoreItems}
      style={{ backgroundColor: "lightgray", width: "100%" }}
      loader={
        <div className="loader" key={0}>
          <i
            className="fas fa-spinner fa-pulse fa-1x"
            style={{ marginTop: "80px" }}
          ></i>
        </div>
      }
      endMessage={
        <p className="mb-5" style={{ textAlign: "center", marginTop: "80px" }}>
          <b>
            {shopTranslation?.messgaeTwo
              ? shopTranslation?.messgaeTwo
              : ShopContent?.messgaeTwo}
          </b>
        </p>
      }
    >
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div style={divStyle} className="bodyImage">
        <div className="user-profile-detail-wrapper">
          <div className="userProfileBorder">
            <img
              className="userProfileBackground "
              src={userProfile.shopDetails.profileUrl || profilePlaceholder}
              alt="profile image"
              onError={(e) => (e.target.src = profilePlaceholder)}
            />
          </div>
          <div>
            {/* {sellerDetail && sellerDetail.data
                ? sellerDetail.data.user.firstname +
                  " " +
                  sellerDetail.data.user.lastname
                : ""}
              <br />
            </p>
            <p className="semiHeading">
              {sellerDetail && sellerDetail.data
                ? sellerDetail.data.user.country
                : ""} */}
            <p className="heading ">Oliver Shop</p>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "black" }}>
        <div className="d-md-flex">
          <button
            onClick={() => setCurrTab("streaming")}
            className={`user_profile_button ${
              currTab === "streaming" ? "active-tab" : ""
            }`}
          >
            {userProfile.sellerInfo?.streamingStatus
              ? translatedContent.content1 || "We are live"
              : translatedContent.content2 || "We are currently offline"}
          </button>
          <button
            onClick={() => setCurrTab("about")}
            className={`user_profile_button ${
              currTab === "about" ? "active-tab" : ""
            }`}
          >
            {translatedContent.about || "About"}
          </button>
        </div>
      </div>

      {currTab === "streaming" && !userProfile.isLive && (
        <div className="streamint_tab">
          <img src={oflineImage} className="ofline_image" />
        </div>
      )}

      {currTab === "streaming" && (
        <React.Fragment>
          <div className="topic ">
            <div className="topicText">
              {userProfile.sellerInfo && !userProfile.isLive && (
                <div
                  className="flex-center"
                  style={{ marginTop: "12px", flexWrap: "wrap" }}
                >
                  <div>
                    {`${userProfile.sellerInfo.firstname} ${
                      userProfile.sellerInfo.lastname
                    } ${userProfile.sellerInfo.is || "is"}`}
                    &nbsp;
                  </div>
                  <div className="flex-center">
                    {userProfile.sellerInfo?.streamingStatus &&
                    !userProfile.isLive ? (
                      <>
                        &nbsp;
                        <span className="liveText" style={{ marginTop: 0 }}>
                          {translatedContent.live || "LIVE"}
                        </span>
                        {!userProfile?.isLive && (
                          <button
                            onClick={() =>
                              setUserProfile({
                                ...userProfile,
                                isLive: true,
                              })
                            }
                            className="join_button_for_live"
                          >
                            Join
                          </button>
                        )}
                      </>
                    ) : (
                      translatedContent.offline || "OFFLINE!"
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="live-stream-camra-wrapper ">
            {userProfile.isLive && (
              <SubscriberRTCComponent
                subscriberProductToken={userProfile.sellerInfo._id}
                onClose={() => {
                  setUserProfile({
                    ...userProfile,
                    subscriberProductToken: "",
                  });
                }}
              />
            )}
          </div>

          <div className="flex-box-5">
            <div style={{ width: "51%", minWidth: 100 }}></div>
          </div>
        </React.Fragment>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="main_page">
          <div className={` ${currTab === "about" ? "tabcontent" : ""} `}>
            {currTab === "about" && (
              <React.Fragment>
                {userProfile.sellerInfo && userProfile.shopDetails ? (
                  <div className="about-section-1">
                    <table>
                      <tbody>
                        <tr>
                          <td className="user-info">
                            {translatedContent.name || "Name"}
                          </td>
                          <td className="discription" style={{ paddingTop: 5 }}>
                            {userProfile.shopDetails.businessName}
                          </td>
                        </tr>
                        <tr>
                          <td className="user-info">
                            {translatedContent.mobileNumber || "Mobile Number"}
                          </td>
                          <td className="discription" style={{ paddingTop: 5 }}>
                            {userProfile.shopDetails.phone}
                          </td>
                        </tr>
                        <tr>
                          <td className="user-info">
                            {translatedContent.country || "Country"}
                          </td>
                          <td className="discription" style={{ paddingTop: 5 }}>
                            {userProfile.shopDetails.country}
                          </td>
                        </tr>
                        <tr>
                          <td className="user-info">
                            YINN
                            {translatedContent.membershipNo || "Membership No."}
                          </td>
                          <td className="discription" style={{ paddingTop: 5 }}>
                            {userProfile.shopDetails.membershipNo}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="user-info"
                            style={{ paddingBottom: 35 }}
                          >
                            YINN
                            {translatedContent.membershipSince ||
                              "Member Since"}
                            :
                          </td>
                          <td
                            className="discription"
                            style={{ paddingBottom: 35, paddingTop: 5 }}
                          >
                            {convtDate()}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <span
                              className="divide-line"
                              style={{ width: "100%" }}
                            ></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="user-info" style={{ paddingTop: 35 }}>
                            {translatedContent.location || "Location"} :
                          </td>
                          <td
                            className="discription"
                            style={{ paddingTop: 35 }}
                          >
                            {userProfile.shopDetails.address}
                            <br />
                            {userProfile.shopDetails.city}
                            <br />
                            {`${userProfile.shopDetails.country} ${userProfile.shopDetails.postalCode}`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="user-info"
                            style={{ paddingTop: 45, paddingBottom: 45 }}
                          >
                            {translatedContent.billingDetails ||
                              "Billing Details"}
                          </td>
                          <td
                            className="secured-image"
                            style={{ marginTop: 45 }}
                          >
                            {translatedContent.securedDetails ||
                              "Secured the Details"}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <span
                              className="divide-line"
                              style={{ width: "100%" }}
                            ></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h4>
                      {translatedContent.content3 ||
                        "Didn't find any data you searched"}
                    </h4>
                  </div>
                )}
              </React.Fragment>
            )}

            {displayMessage()}
          </div>
          {/* ALL PRODUCTS / SUGGESTIONS */}
          <RelatedProducts type="sellerShop" products={userProfile.products} />

          {/* SLIDES */}
          <div className="flex-box-5"></div>
        </div>
      </div>
    </InfiniteScroll>
  );
};
export default UserProfile;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(3.75),
    },
  },
  fab1: {
    position: "fixed",
    bottom: "8.5rem",
    right: "2rem",
    width: "40%",
    backgroundColor: "beige",
    [theme.breakpoints.down("sm")]: {
      width: "67%",
    },
  },
}));
