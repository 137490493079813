import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addFilter,
  getAllResidency,
  getSubscription,
} from "../../redux/reducers/seller";
import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import FreemiumPage from "../Seller/CustomPages/FreemiumPage";
import { nextPagesItems } from "../../helpers/custom";
import moment from "moment";

const ResidancyList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const residencySwapTranslation =
    translationState?.[0]?.translations?.residencySwap?.[userLanguage];
  const sellerTranslation =
    translationState?.[0]?.translations?.seller?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];

  const { allResidency, count, filters, loading, singleSubscription } =
    useSelector((state) => state.seller);
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  useEffect(() => {
    dispatch(getSubscription());
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    setSelectedPerPage(5);
    dispatch(getAllResidency());
  }, []);
  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getAllResidency());
    }
  };
  const viewDetails = (id) => {
    history.push(`/seller/residancySwap/edit/${id}`);
  };
  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getAllResidency());
  };
  const fremiumText1 = `${
    reportingTranslation?.infoLineOne
      ? reportingTranslation?.infoLineOne
      : "Only SOLO and Partner users can use the Residancy Swap feature. Please upgrade your membership to access this and many more exciting features."
  }`;
  return (
    <div className="animated fadeIn">
      {loading ? (
        <div className="mt-5" style={{ textAlign: "center" }}>
          <i
            className="fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : !["Gold"].includes(singleSubscription?.membershipType) ? (
        <Card className="my-5 mx-2">
          <CardHeader>
            <Row style={{ marginTop: "4px" }}>
              <Col xs="5" sm="5" md="6">
                <strong>
                  {sellerTranslation?.Residancy_Swap
                    ? sellerTranslation?.Residancy_Swap
                    : "Residence List"}
                </strong>
              </Col>
              <Col xs="5" sm="5" md="6">
                <Button
                  onClick={() => history.push("/seller/residancySwap/add")}
                  size="sm"
                  style={{ marginLeft: "81%" }}
                  color="warning"
                >
                  <b style={{ padding: "10px 0px" }}>
                    {residencySwapTranslation?.addResident
                      ? residencySwapTranslation?.addResident
                      : "Add Resident"}
                  </b>
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CardBody>
            <div className="tableFixHead">
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color" align="center">
                    <th>
                      {digitalProductTranslation?.title
                        ? digitalProductTranslation?.title
                        : "Title"}
                    </th>
                    <th>
                      {residencySwapTranslation?.availableFrom
                        ? residencySwapTranslation?.availableFrom
                        : "Available from"}
                    </th>
                    <th>
                      {residencySwapTranslation?.availableTill
                        ? residencySwapTranslation?.availableTill
                        : "Available till"}
                    </th>
                    <th>
                      {residencySwapTranslation?.regularPrice
                        ? residencySwapTranslation?.regularPrice
                        : "Regular price"}
                    </th>
                    <th>
                      {residencySwapTranslation?.viewDetail
                        ? residencySwapTranslation?.viewDetail
                        : "View Details"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={5} className="py-5">
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green" }}
                        ></i>
                      </div>
                    </td>
                  ) : allResidency?.length && !loading ? (
                    allResidency.map((resident, index) => {
                      return (
                        <tr>
                          {resident.name != undefined ? (
                            <td align="center">{resident.name}</td>
                          ) : (
                            ""
                          )}
                          {resident.residencyData != undefined ? (
                            <td align="center">
                              {moment(resident.startDate).format("YYYY-MM-DD")}
                            </td>
                          ) : (
                            ""
                          )}
                          {resident.residencyData != undefined ? (
                            <td align="center">
                              {moment(resident.endDate).format("YYYY-MM-DD")}
                            </td>
                          ) : (
                            ""
                          )}
                          {resident.residencyData != undefined ? (
                            <td align="center">$ {resident.regularPrice}</td>
                          ) : (
                            ""
                          )}
                          {resident.residencyData != undefined ? (
                            <td align="center">
                              <a
                                onClick={() => {
                                  viewDetails(resident._id);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                view more
                              </a>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <td colSpan={5} className="py-5">
                      <div style={{ textAlign: "center" }}>
                        <p style={{ color: "red" }}>
                          {walletTranslation?.notFound
                            ? walletTranslation?.notFound
                            : "Records Not Found"}
                        </p>
                      </div>
                    </td>
                  )}
                </tbody>
              </Table>
            </div>
            <nav className="mt-3">
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    onClick={(e) => paginate(pageNo - 1)}
                    disabled={pageNo === 1 ? true : false}
                    style={{
                      backgroundColor:
                        (pageNo === 1 ? true : false) && "#e4e7ea",
                    }}
                  >
                    {reportingTranslation?.previousButton
                      ? reportingTranslation?.previousButton
                      : "Prev"}
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    onClick={() => paginate(pageNo + 1)}
                    disabled={!clickableNext}
                    style={{
                      backgroundColor: !clickableNext ? "#e4e7ea" : null,
                    }}
                    className="mx-2"
                  >
                    {reportingTranslation?.nextButton
                      ? reportingTranslation?.nextButton
                      : "Next"}
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={"ml-auto"}>
                  <PaginationLink
                    className="rounded"
                    style={{
                      color: "black",
                      hover: false,
                      backgroundColor: "white",
                    }}
                  >
                    <div>
                      {productTranslation?.itemPerPage
                        ? productTranslation?.itemPerPage
                        : "Items per Page"}

                      <select
                        className="ml-2"
                        value={selectedPerPage}
                        onChange={(e) => perPageHandler(e, pageNo)}
                      >
                        {itemsPerPageOptions.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardBody>
        </Card>
      ) : (
        <FreemiumPage text={fremiumText1} />
      )}
    </div>
  );
};

export default ResidancyList;
