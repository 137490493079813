import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import braintree from "braintree-web";
import {
  SetState,
  setClearItem,
  buyYinnCoin,
  toLocalBankTransferAccountDetails,
  getYinnExhangeRate,
} from "../../../redux/reducers/user";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import Summary from "./CoinSummary";
import modalCloseIcon from "../../../assets/img/checkout/modal-close.svg";
import bankTransferIcon from "../../../assets/img/checkout/bank-transfer-svgrepo-com.svg";
import { bluesnapSupportedData } from "../../../assets/bluesnap/bluesnapSupport";
import "react-toastify/dist/ReactToastify.css";
import { getExhangeRate } from "../../../redux/reducers/auth";
import AccountDetails from "../../checkout/AccountDetails";
import { Country, State, City } from "country-state-city";

const paymentMethods = [
  {
    name: "Local Bank Transfer",
    imgSrc: bankTransferIcon,
  },
];

const months = Array(12)
  .fill()
  .map((val, i) => i + 1);
const years = Array(20)
  .fill()
  .map((val, i) => i + 2020);

const Payment = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { wallet, translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const paymentInfoTranslation =
    translationState?.[0]?.translations?.paymentInfo?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const missedTranslation =
    translationState?.[0]?.translations?.missed?.[userLanguage];
  const {
    coins,
    salesPrice,
    paymentConfiguration,
    moveBack,
    showConfirmation,
    totalPrice,
    yinnPercentage,
    setSalesPrice,
  } = props;
  const {
    loading,
    paymentConfirm,
    localBankTransferAccountDetails,
    exchangeRate,
  } = useSelector((state) => state.user);
  const { usdRate } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getYinnExhangeRate());
    makePayPalPayment();
    dispatch(setClearItem({ field: "localBankTransferAccountDetails" }));
  }, []);
  useEffect(() => {
    if (paymentConfirm) {
      showConfirmation();
    }
  }, [paymentConfirm]);

  const [billingAdress, setBillingAdress] = useState({});
  const [bluesnapSupported, setBluesnapSupported] = useState(
    bluesnapSupportedData
  );
  const [currency, setCurrency] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [modal, setModal] = useState(false);
  const [clickedPaymentMethod, setClickedPaymentMethod] = useState({});
  const [localBankTransferinfo, setLocalBankTransferinfo] = useState({
    amount: 0,
    firstName: "",
    lastName: "",
    country: "",
    currency: "",
    localBankTransferTransaction: {},
    paymentMethod: null,
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    localBankTransferinfo.paymentMethod
  );
  const [paymentTempData, setPaymentTempData] = useState({
    cardNo: "",
    expMonth: "",
    expYear: "",
    cvv: "",
    zipCode: "",
  });
  const toggle = () => {
    setModal(!modal);
  };
  const selectPaymentMethod = (method) => {
    setSelectedPaymentMethod(method.name);
  };
  const handleShippingInfoInput = (e) => {
    const { name, value } = e.target;
    const update = { ...billingAdress };
    update[name] = value;
    setBillingAdress(update);
    if (name === "country") {
      const country = Country.getAllCountries(value).filter(
        (country) => country.name === value
      );
      const filteredStates = State.getStatesOfCountry(country[0]?.isoCode);
      setStates(filteredStates);
      setCities([]);
    } else if (name === "state") {
      const stateObj = State.getAllStates().filter(
        (state) => state.name === value
      )[0];

      const filteredCities = City.getCitiesOfState(
        stateObj.countryCode,
        stateObj.isoCode
      );
      setCities(filteredCities);
    }
  };
  const openPaymentModal = (selected) => {
    setClickedPaymentMethod(selected);
    if (selected.name === "Local Bank Transfer") {
      if (selected.name !== selectedPaymentMethod) {
        selectPaymentMethod({ name: null });
      }
      setLocalBankTransferinfo({
        amount: (Number(coins) * usdRate) / exchangeRate,
        firstName: "",
        lastName: "",
        country: "",
        currency: "",
        localBankTransferTransaction: {},
      });
      toggle();
      selectPaymentMethod(selected);
    } else {
      selectPaymentMethod(selected);
    }
  };
  const handlePaymentDataInput = (e) => {
    const { name, value } = e.target;
    const update = { ...localBankTransferinfo, [name]: value };
    if (name === "country") {
      const countryObj = bluesnapSupported.find(
        (obj) => obj.shoppersCountry === value
      );
      dispatch(SetState({ field: "userCurrency", value: countryObj.currency }));
      setCurrency(countryObj.currency);
      dispatch(getExhangeRate(countryObj.currency)).then((response) => {
        const updatedSalesPrice =
          (Number(coins) * response.payload.usdRate) / exchangeRate;
        setLocalBankTransferinfo({
          ...localBankTransferinfo,
          country: countryObj.shoppersCountryCode,
          currency: countryObj.currency,
          amount:
            updatedSalesPrice +
            paymentConfiguration?.yinncoinPurchasePercentageCharge *
              updatedSalesPrice +
            paymentConfiguration?.coinMaintenanceCharge,
        });
        setSalesPrice(updatedSalesPrice);
      });
    } else {
      setLocalBankTransferinfo(update);
    }
  };
  const addCardInfo = () => {
    const { firstName, lastName, country, currency } = localBankTransferinfo;
    if (firstName && lastName && salesPrice && country && currency) {
      const data = {
        amount: totalPrice,
        payerInfo: {
          firstName: firstName,
          lastName: lastName,
          country: country,
        },
        currency: currency,
        localBankTransferTransaction: {},
      };
      dispatch(toLocalBankTransferAccountDetails(data));
      selectPaymentMethod(clickedPaymentMethod);
      toggle();
    } else {
      toast.error("Enter All details to proceed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleCartCheckout = async () => {
    try {
      if (localBankTransferAccountDetails) {
        const payload = {
          localBankTransferAccountDetails,
          actionStatusType: "Yinn Coin",
          salesPrice,
          coinQuantity: coins,
          maintenanceCharge: paymentConfiguration.coinMaintenanceCharge,
          paymentMethod: "local",
        };
        dispatch(buyYinnCoin(payload));
      } else {
        toast.error("firstly proceed local bank transfer", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (e) {
      let errorMessage = e.payload?.err || "Unable to Buy the Coins";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handlePayment = async () => {
    if (selectedPaymentMethod === null) {
      toast.error("Select a payment method", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      handleCartCheckout();
    }
  };
  const makePayPalPayment = async () => {
    var paypalButton = document.querySelector(".paypal-button");
    const client = await braintree.client.create({
      authorization: "sandbox_fkrf6yxq_5j5z96qv7th27rtg",
    });
    const paypal = await braintree.paypal.create({
      client,
    });
    paypalButton.removeAttribute("disabled");
    paypalButton.addEventListener(
      "click",
      function (event) {
        paypal.tokenize(
          {
            flow: "vault",
          },
          function (tokenizeErr, payload) {
            if (tokenizeErr) {
              if (tokenizeErr.type !== "CUSTOMER") {
                console.error("Error tokenizing:", tokenizeErr);
              }
              return;
            }
            paypalButton.setAttribute("disabled", true);
            dispatch(
              buyYinnCoin({
                salesPrice,
                coinQuantity: coins,
                maintenanceCharge: paymentConfiguration.coinMaintenanceCharge,
                paymentId: payload.nonce,
                paymentMethod: "paypal",
              })
            );
          }
        );
      },
      false
    );
  };
  const getTotalPrice = () => {
    const basePrice = paymentConfiguration.coinMaintenanceCharge + salesPrice;
    const fixedCharges =
      selectedPaymentMethod === "Paypal"
        ? paymentConfiguration.paypalPaymentFixCharge
        : paymentConfiguration.cardPaymentFixCharge;
    const total =
      basePrice +
      basePrice * paymentConfiguration.paypalPaymentPercentageCharge +
      fixedCharges;
    return total;
  };
  const getPaymentCharges = () => {
    const basePrice = paymentConfiguration.coinMaintenanceCharge + salesPrice;
    const fixedCharges =
      selectedPaymentMethod === "Paypal"
        ? paymentConfiguration.paypalPaymentFixCharge
        : paymentConfiguration.cardPaymentFixCharge;

    const total =
      basePrice * paymentConfiguration.paypalPaymentPercentageCharge +
      fixedCharges;
    return total;
  };

  return (
    <React.Fragment>
      <div hidden={!loading} className="overlay-purchase-processing">
        <b style={{ opacity: 1, color: "#ffffff", fontSize: 28 }}>
          {missedTranslation?.process
            ? missedTranslation?.process
            : "Processing transaction"}
        </b>
      </div>
      <ToastContainer autoClose={20000} />
      <Row>
        <Col xs="12" lg="7" className="shipping-form">
          <Row>
            <Col xs="12" lg="12">
              <div className="shipping-title">
                {paymentInfoTranslation?.paymentInfo
                  ? paymentInfoTranslation?.paymentInfo
                  : "Payment Information"}
              </div>
              {localBankTransferAccountDetails === false && (
                <div style={{ color: "red", fontSize: "20px" }}>
                  Bad Request Please Try Again
                </div>
              )}
              {localBankTransferAccountDetails === null ||
              localBankTransferAccountDetails === false ? (
                <div>
                  <div style={{ marginTop: 30, marginBottom: 20 }}>
                    {paymentInfoTranslation?.paymentMethod
                      ? paymentInfoTranslation?.paymentMethod
                      : "Payment method:"}
                  </div>
                  <div className="payment-method-select-area">
                    {paymentMethods.map((method, index) => {
                      return (
                        <PaymentMethod
                          {...method}
                          onClick={() => {
                            openPaymentModal(method);
                          }}
                          selected={selectedPaymentMethod === method.name}
                          isCashAllowed={true}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <AccountDetails
                  accountDetails={localBankTransferAccountDetails}
                  coins={coins}
                  totalPrice={totalPrice}
                />
              )}

              <div hidden={selectedPaymentMethod !== "Paypal"}>
                <div className="billing-Address-title">
                  Billing Address :<div></div>
                </div>
                <div className="billing-separator"></div>

                <div
                  style={{ marginTop: 30 }}
                  className="shipping-form-input-row"
                >
                  <div className="shipping-form-input-label">First Name</div>
                  <input
                    className="shipping-form-input-input"
                    name="firstname"
                    value={billingAdress.firstname}
                    onChange={handleShippingInfoInput}
                  />
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">Last Name :</div>
                  <input
                    className="shipping-form-input-input"
                    name="lastname"
                    value={billingAdress.lastname}
                    onChange={handleShippingInfoInput}
                  />
                </div>

                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    House/ Apartment Number :
                  </div>
                  <input
                    className="shipping-form-input-input"
                    name="addressLineOne"
                    value={billingAdress.addressLineOne}
                    onChange={handleShippingInfoInput}
                  />
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    Street/ Lane :
                  </div>
                  <input
                    className="shipping-form-input-input"
                    name="addressLineTwo"
                    value={billingAdress.addressLineTwo}
                    onChange={handleShippingInfoInput}
                  />
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">Zip Code :</div>
                  <input
                    className="shipping-form-input-input"
                    name="zipCode"
                    value={billingAdress.zipCode}
                    onChange={handleShippingInfoInput}
                  />
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">Country :</div>
                  <select
                    type="select"
                    className="shipping-form-input-input"
                    name="country"
                    value={billingAdress.country}
                    onChange={handleShippingInfoInput}
                  >
                    <option value="">select country</option>
                    {Country.getAllCountries().map((country, index) => (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">
                    State/ Province :
                  </div>
                  <select
                    type="select"
                    className="shipping-form-input-input"
                    name="state"
                    value={billingAdress.state}
                    onChange={handleShippingInfoInput}
                  >
                    <option value="">select state</option>
                    {states.map((state, index) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="shipping-form-input-row">
                  <div className="shipping-form-input-label">City :</div>
                  <select
                    type="select"
                    className="shipping-form-input-input"
                    name="city"
                    value={billingAdress.city}
                    onChange={handleShippingInfoInput}
                  >
                    <option value="">select city</option>
                    {cities.map((city, index) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="checkout-next-button-container">
                <button className="checkout-next-button" onClick={moveBack}>
                  {digitalProductTranslation?.cancel
                    ? digitalProductTranslation?.cancel
                    : "Cancel"}
                </button>
                <div style={{ width: 20 }}></div>
                <button
                  hidden={selectedPaymentMethod !== "Paypal"}
                  className="checkout-next-button paypal-button"
                >
                  Buy with Paypal
                </button>
                <button
                  hidden={selectedPaymentMethod === "Paypal"}
                  onClick={handlePayment}
                  className="checkout-next-button"
                >
                  {walletTranslation?.buyCoin
                    ? walletTranslation?.buyCoin
                    : "Buy Coins"}
                </button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="5">
          <Row style={{ marginBottom: 30 }}>
            {/* <Col xs="0" lg="1" /> */}
            <Col xs="12" lg="11">
              <Summary
                yinnPercentage={yinnPercentage}
                totalPrice={totalPrice}
                salesPrice={salesPrice}
                paymentConfiguration={paymentConfiguration}
                paymentMethod={selectedPaymentMethod}
                additionalCharge={getPaymentCharges()}
              />
            </Col>
          </Row>
        </Col>

        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalBody className="payament-modal-container">
            <div className="payament-modal-title-container">
              <div className="title-header">
                <img
                  src={clickedPaymentMethod.imgSrc}
                  style={{ marginRight: 18 }}
                />
                <div className="payament-modal-title">
                  {paymentInfoTranslation?.lbt
                    ? paymentInfoTranslation?.lbt
                    : clickedPaymentMethod.name}
                </div>
              </div>
              <img
                src={modalCloseIcon}
                alt="close"
                style={{ cursor: "pointer" }}
                onClick={toggle}
              />
            </div>
            <div className="payment-modal-content">
              <div className="shipping-form-input-row">
                <div className="shipping-form-input-label">
                  {shippingCartTranslation?.firsrtName
                    ? shippingCartTranslation?.firsrtName
                    : "First Name"}
                </div>
                <input
                  className="shipping-form-input-input"
                  name="firstName"
                  type="text"
                  min={0}
                  value={localBankTransferinfo.firstName}
                  onChange={handlePaymentDataInput}
                />
              </div>
              <div className="shipping-form-input-row">
                <div className="shipping-form-input-label">
                  {shippingCartTranslation?.lastName
                    ? shippingCartTranslation?.lastName
                    : "Last Name"}
                </div>
                <input
                  className="shipping-form-input-input"
                  name="lastName"
                  type="text"
                  min={0}
                  value={localBankTransferinfo.lastName}
                  onChange={handlePaymentDataInput}
                />
              </div>
              <div className="shipping-form-input-row">
                <div className="shipping-form-input-label">
                  {shippingCartTranslation?.country
                    ? shippingCartTranslation?.country
                    : "Country :"}
                </div>
                <select
                  type="select"
                  className="shipping-form-input-input"
                  name="country"
                  onChange={handlePaymentDataInput}
                >
                  <option value="">
                    {shippingCartTranslation?.country
                      ? shippingCartTranslation?.country
                      : "Select Country"}
                  </option>
                  {bluesnapSupported.map((obj, index) => (
                    <option key={index} value={obj.shoppersCountry}>
                      {obj.shoppersCountry}
                    </option>
                  ))}
                </select>
              </div>
              <div className="shipping-form-input-row">
                <div className="shipping-form-input-label">
                  {shippingCartTranslation?.currency
                    ? shippingCartTranslation?.currency
                    : "Currency :"}
                </div>
                <select
                  type="select"
                  className="shipping-form-input-input"
                  name="currency"
                  value={localBankTransferinfo.currency}
                  onChange={handlePaymentDataInput}
                >
                  <option value="">
                    {shippingCartTranslation?.currency
                      ? shippingCartTranslation?.currency
                      : "Select Currency"}
                  </option>
                  <option value={currency}>{currency}</option>
                </select>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={addCardInfo} className="checkout-next-button">
                {walletTranslation?.butIt ? walletTranslation?.butIt : "Buy"}
              </button>
            </div>
          </ModalBody>
        </Modal>
      </Row>
    </React.Fragment>
  );
};

function PaymentMethod(props) {
  const { name, onClick, selected, imgSrc } = props;
  return (
    <div>
      <div
        className={
          !selected
            ? "payment-method-container"
            : "payment-method-container-selected"
        }
        onClick={onClick}
      >
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <div
            className={
              !selected
                ? "payment-method-radio"
                : "payment-method-radio-selected"
            }
          ></div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              fontSize: 13,
              font: "Roboto",
            }}
          >
            <div>{name}</div>
          </div>
        </div>
        <img style={{ width: 58, height: 34 }} src={imgSrc} />
      </div>
    </div>
  );
}
export default Payment;
