import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  SetState,
  addFilter,
  getCategories,
  getProducts,
  getProductBrands,
  getAllSubCategories,
  clearProduct,
} from "../../../redux/reducers/auth";
import { Collapse, Col, Input, Row } from "reactstrap";
import { debounce } from "lodash";
import "./search.css";
import InfiniteScroll from "react-infinite-scroll-component";
import SkyLight from "react-skylight";
import { AppHeader } from "@coreui/react";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import ProductPreviewBox from "../Home/ProductPreviewBox";
import { IoIosArrowDropdown } from "react-icons/io";

const Search = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const yinnPriceModal = useRef(null);
  const searchField = useRef(null);
  const { translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const {
    count,
    filters,
    productCategories,
    products,
    brands,
    allSubCategories,
    userLanguage,
    token,
  } = useSelector((state) => state.auth);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [searchProduct, setSearchProduct] = useState({
    name: "",
    mainCategory: [],
    subCategory: [],
    brand: [],
  });

  const [mainCategoryChecked, setMainCategoryChecked] = useState([]);
  const [subCategoryChecked, setSubCategoryChecked] = useState([]);
  const [brandChekboxes, setBrandChekboxes] = useState([]); // for brand checkboxes

  const pageNo = filters?.find((item) => item.field === "pageNo");
  const ShopContent = allWebContent?.[0]?.shop;
  const shopTranslation =
    translationState?.[0]?.translations?.shop?.[userLanguage];

  useEffect(() => {
    dispatch(clearProduct());
    const type = new URLSearchParams(location.search).get("type");
    const defaultFilters = [
      { field: "pageNo", value: 1 },
      { field: "perPage", value: 10 },
    ];
    if (type && type == "digital") {
      defaultFilters.push({ field: "type", value: "digital" });
    }
    if (type && type == "residency-swap") {
      defaultFilters.push({ field: "productType", value: "residencySwap" });
    }
    dispatch(SetState({ field: "filters", value: defaultFilters }));
    dispatch(getProducts());
    dispatch(getProductBrands());
    dispatch(getCategories());
    dispatch(getAllSubCategories());
  }, [token]);

  useEffect(() => {
    const textValue = new URLSearchParams(location.search).get("txt");
    if (textValue) {
      searchElement(textValue);
      searchField.current.value = textValue;
    }
  }, [location]);

  useEffect(() => {
    if (products?.length == count) {
      setHasMoreItems(false);
    } else if (products?.length <= count) {
      setHasMoreItems(true);
    }
  }, [products]);

  // useEffect(() => {
  //   if (filters?.length) {
  //     const filtersObject = searchProduct;
  //     for (let i = 0; i < filters.length; i++) {
  //       const { field, value } = filters[i];
  //       filtersObject[field] = value;
  //     }
  //   }
  // }, [filters]);

  const searchElement = debounce((txt) => {
    dispatch(clearProduct());
    dispatch(addFilter({ field: "pageNo", value: 1 }));
    dispatch(addFilter({ field: "search", value: txt }));
    dispatch(getProducts());
  }, 500);

  const fetchData = () => {
    if (products?.length <= count) {
      setHasMoreItems(true);
      dispatch(addFilter({ field: "pageNo", value: pageNo?.value + 1 }));
      dispatch(getProducts());
    } else {
      setHasMoreItems(false);
    }
  };

  const handleBrandCheckboxChanges = (brand) => {
    let newFilters = [];
    const brandFilter = filters?.find((item) => item.field === "brand");
    if (brandFilter) {
      newFilters = [...brandFilter.value];
      const newBrand = newFilters?.findIndex((item) => item === brand);
      if (newBrand !== -1) {
        newFilters.splice(newBrand, 1);
      } else {
        newFilters.push(brand);
      }
    } else {
      newFilters.push(brand);
    }
    // Toggle the brand
    setBrandChekboxes((prev) => {
      const newChecked = [...prev];
      const index = newChecked.indexOf(brand);
      if (index === -1) {
        newChecked.push(brand);
      } else {
        newChecked.splice(index, 1);
      }
      return newChecked;
    });
    setHasMoreItems(true);
    dispatch(clearProduct());
    dispatch(addFilter({ field: "pageNo", value: 1 }));
    dispatch(addFilter({ field: "brand", value: newFilters }));
    dispatch(getProducts());
  };

  const changeCategory = (category) => {
    let newFilters = [];
    const categoryFilter = filters?.find(
      (item) => item.field === "mainCategory"
    );
    if (categoryFilter) {
      newFilters = [...categoryFilter.value];
      const newCategory = newFilters?.findIndex((item) => item === category);
      if (newCategory !== -1) {
        newFilters.splice(newCategory, 1);
      } else {
        newFilters.push(category);
      }
    } else {
      newFilters.push(category);
    }
    // Toggle the category
    setMainCategoryChecked((prev) => {
      const newChecked = [...prev];
      const index = newChecked.indexOf(category);
      if (index === -1) {
        newChecked.push(category);
      } else {
        newChecked.splice(index, 1);
      }
      return newChecked;
    });
    setHasMoreItems(true);
    dispatch(clearProduct());
    dispatch(addFilter({ field: "pageNo", value: 1 }));
    dispatch(addFilter({ field: "mainCategory", value: newFilters }));
    dispatch(getProducts());
  };
  const changeMainCategory = (event) => {
    const category = event.target.value;
    setHasMoreItems(true);
    dispatch(clearProduct());
    dispatch(addFilter({ field: "pageNo", value: 1 }));
    dispatch(addFilter({ field: "mainCategory", value: [category] }));
    dispatch(getProducts());
  };

  const handleSubCategoryCheckboxChanges = (category) => {
    let newFilters = [];
    const categoryFilter = filters?.find(
      (item) => item.field === "subCategory"
    );
    if (categoryFilter) {
      newFilters = [...categoryFilter.value];
      const newCategory = newFilters?.findIndex((item) => item === category);
      if (newCategory !== -1) {
        newFilters.splice(newCategory, 1);
      } else {
        newFilters.push(category);
      }
    } else {
      newFilters.push(category);
    }
    // Toggle the category
    setSubCategoryChecked((prev) => {
      const newChecked = [...prev];
      const index = newChecked.indexOf(category);
      if (index === -1) {
        newChecked.push(category);
      } else {
        newChecked.splice(index, 1);
      }
      return newChecked;
    });
    setHasMoreItems(true);
    dispatch(clearProduct());
    dispatch(addFilter({ field: "pageNo", value: 1 }));
    dispatch(addFilter({ field: "subCategory", value: newFilters }));
    dispatch(getProducts());
  };

  const viewProduct = (id, productType, digitalType) => {
    const isViewTv = ["movie", "series", "theater", "music"].includes(
      digitalType
    );
    if (!isViewTv)
      history.push(
        `/${
          productType == "digital" ? "digitalproduct" : "productdetail"
        }/${id}`
      );
    else
      history.push(
        `/${productType == "digital" ? "viewtv" : "productdetail"}/${id}`
      );
  };

  return (
    <div className="animated fadeIn">
      <div className="app" style={{ backgroundColor: "#f6f6f6" }}>
        <Row>
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
        </Row>
        <InfiniteScroll
          pageStart={1}
          height={"100vh"}
          dataLength={products?.length || 10}
          next={fetchData}
          hasMore={hasMoreItems}
          loader={
            <div className="loader" key={0}>
              <i
                className="fas fa-spinner fa-pulse fa-1x"
                style={{ marginTop: "80px" }}
              ></i>
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center", marginTop: "80px" }}>
              <b>
                {shopTranslation?.messgaeTwo
                  ? shopTranslation?.messgaeTwo
                  : ShopContent?.messgaeTwo}
              </b>
            </p>
          }
        >
          <div className="d-flex w-100 main-div-center">
            <div style={{ maxWidth: 1366 }}>
              <Row
                style={{
                  marginTop: 75,
                  paddingTop: 40,
                  paddingLeft: 4,
                  paddingRight: 4,
                }}
                className="d-flex justify-content-center"
              >
                <input
                  className="main-search-input pl-4"
                  placeholder={
                    shopTranslation?.search
                      ? shopTranslation?.search
                      : ShopContent?.search
                  }
                  ref={searchField}
                  onChange={(e) => searchElement(e.target.value)}
                />
              </Row>

              <Row className="pl-md-5 pt-5 d-flex flex-wrap-reverse">
                <Col xs="12" xl="3">
                  {/* <Row style={{ marginBottom: 30 }}>
                    <SelectedCategories
                      categorries={productCategories}
                      subCategories={allSubCategories || []}
                      brands={brands}
                      searchData={searchProduct}
                      handleBrandCheckboxChanges={handleBrandCheckboxChanges}
                      handleCategoryCheckboxChanges={changeCategory}
                      handleSubCategoryCheckboxChanges={
                        handleSubCategoryCheckboxChanges
                      }
                    />
                  </Row> */}
                  <Row>
                    <BrowseCategories
                      categorries={productCategories}
                      subCategories={allSubCategories || []}
                      brands={brands}
                      searchData={searchProduct}
                      handleCategoryCheckboxChanges={changeCategory}
                      handleSubCategoryCheckboxChanges={
                        handleSubCategoryCheckboxChanges
                      }
                      handleBrandCheckboxChanges={handleBrandCheckboxChanges}
                      mainCategoryChecked={mainCategoryChecked}
                      subCategoryChecked={subCategoryChecked}
                      brandChekboxes={brandChekboxes}
                    />
                  </Row>
                </Col>

                <Col xs="12" xl="9">
                  <Row className="category-dropdown-area ">
                    <label className="main-category-label">
                      {shopTranslation?.mainCategory
                        ? shopTranslation?.mainCategory
                        : ShopContent?.mainCategory}
                    </label>
                    <select
                      onChange={changeMainCategory}
                      className="category-dropdown "
                    >
                      <option value={"all"}>
                        {shopTranslation?.allCategory
                          ? shopTranslation?.allCategory
                          : ShopContent?.allCategory}
                      </option>
                      {productCategories?.map((item, i) => (
                        <option value={item._id} key={i}>
                          {userLanguage == "en"
                            ? item.name
                            : item.translatedArray.map((tr) => {
                                if (tr.language == userLanguage) {
                                  return tr.name;
                                }
                              })}
                          {/* {item.name} */}
                        </option>
                      ))}
                    </select>
                  </Row>
                  <Row className="product-area ">
                    <Col xs="12">
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: 100,
                          paddingLeft: "5%",
                          paddingRight: "5%",
                        }}
                      >
                        <div>
                          <strong style={{ color: "#6C6C6C" }}>
                            {searchProduct?.name
                              ? `Search results for "${searchProduct?.name}"`
                              : `${
                                  shopTranslation?.allProduct
                                    ? shopTranslation?.allProduct
                                    : ShopContent?.allProduct
                                }`}
                          </strong>
                        </div>
                        <div
                          onClick={() => yinnPriceModal.current.show()}
                          style={{ color: "#9baed4", cursor: "pointer" }}
                        >
                          <u>
                            {shopTranslation?.price
                              ? shopTranslation?.price
                              : ShopContent?.price}
                          </u>
                        </div>
                      </Row>

                      <Row className="mx-3">
                        <Col xs="12 d-flex justify-content-center pl-4">
                          <div
                            style={{
                              display: "inline-flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {products?.map(
                              (product, index) =>
                                product.status === "Active" && (
                                  <ProductPreviewBox
                                    key={index}
                                    product={product}
                                    viewProduct={() => {
                                      viewProduct(
                                        product._id,
                                        product.productType,
                                        product.digitalType
                                      );
                                    }}
                                  />
                                )
                            )}
                            {products?.length <= 0 && (
                              <div style={{ marginTop: "80px" }}>
                                <h5>
                                  {shopTranslation?.message
                                    ? shopTranslation?.message
                                    : ShopContent?.message}
                                </h5>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </InfiniteScroll>
        <SkyLight
          hideOnOverlayClicked
          ref={yinnPriceModal}
          title={
            shopTranslation?.popUpHeading
              ? shopTranslation?.popUpHeading
              : ShopContent?.popUpHeading
          }
        >
          {shopTranslation?.popUp ? shopTranslation?.popUp : ShopContent?.popUp}
        </SkyLight>

        <DefaultFooter />
      </div>
    </div>
  );
};

export default Search;

const SelectedCategories = (props) => {
  const { translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);
  const ShopContent = allWebContent?.[0]?.shop;
  const shopTranslation =
    translationState?.[0]?.translations?.shop?.[userLanguage];
  const {
    categorries,
    subCategories,
    brands,
    searchData,
    handleBrandCheckboxChanges,
    handleCategoryCheckboxChanges,
    handleSubCategoryCheckboxChanges,
  } = props;

  const closeCategory = (catName) => {
    handleCategoryCheckboxChanges(catName);
  };

  const closeSubCategory = (catName) => {
    handleSubCategoryCheckboxChanges(catName);
  };

  const [isOpen, setIsOpen] = useState(true);

  return (
    <Row
      className="selected-categories "
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Col xs="12">
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 50,
            paddingLeft: "3%",
            paddingRight: "3%",
          }}
        >
          <div>
            <strong style={{ color: "#575757" }}>
              {shopTranslation?.selected
                ? shopTranslation?.selected
                : ShopContent?.selected}
            </strong>
          </div>

          <IoIosArrowDropdown
            size={20}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="expand-button"
          />
        </Row>
        <Row>
          <div
            style={{
              backgroundColor: "#f6f6f6",
              width: "100%",
              height: 2,
              marginLeft: 20,
              marginRight: 20,
            }}
          />
        </Row>
        <Collapse isOpen={isOpen}>
          <Row className="pt-4">
            <Col xs="12 d-flex flex-wrap">
              {searchData?.brand?.map((brandName, i) => {
                return (
                  <div className="filter-tag mr-3 mb-2">
                    <div className="filter-tag-text-area">
                      <div className="filter-tag-subtitle ">brand</div>
                      <div className="filter-tag-title ">{brandName}</div>
                    </div>
                    <button
                      className="filter-tag-close"
                      onClick={() => handleBrandCheckboxChanges(brandName)}
                    />
                  </div>
                );
              })}
              {searchData?.mainCategory?.map((catName, i) => {
                const category = categorries?.find(
                  (item) => item._id == catName
                );
                return (
                  category && (
                    <div className="filter-tag mr-3 mb-2">
                      <div className="filter-tag-text-area">
                        <div className="filter-tag-subtitle ">category</div>
                        <div className="filter-tag-title ">
                          {category?.name}
                        </div>
                      </div>
                      <button
                        className="filter-tag-close"
                        onClick={() => {
                          closeCategory(category?._id);
                        }}
                      />
                    </div>
                  )
                );
              })}
              {searchData?.subCategory?.map((catName, i) => {
                const category = subCategories?.find(
                  (item) => item._id == catName
                );
                return (
                  category && (
                    <div className="filter-tag mr-3 mb-2">
                      <div className="filter-tag-text-area">
                        <div className="filter-tag-subtitle ">sub category</div>
                        <div className="filter-tag-title ">
                          {category?.name}
                        </div>
                      </div>
                      <button
                        className="filter-tag-close"
                        onClick={() => {
                          closeSubCategory(category?._id);
                        }}
                      />
                    </div>
                  )
                );
              })}
              {searchData.brand?.length === 0 &&
              searchData.mainCategory?.length === 0 &&
              searchData.subCategory?.length === 0 &&
              shopTranslation?.filterApplied
                ? shopTranslation?.filterApplied
                : "No filters applied"}
            </Col>
          </Row>
        </Collapse>
      </Col>
    </Row>
  );
};

const BrowseCategories = (props) => {
  const { translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);
  const ShopContent = allWebContent?.[0]?.shop;
  const shopTranslation =
    translationState?.[0]?.translations?.shop?.[userLanguage];
  const {
    categorries,
    subCategories,
    brands,
    searchData,
    handleBrandCheckboxChanges,
    handleCategoryCheckboxChanges,
    handleSubCategoryCheckboxChanges,
    mainCategoryChecked,
    subCategoryChecked,
    brandChekboxes,
  } = props;
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(true);
  const [isSubCategoriesOpen, setIsSubCategoriesOpen] = useState(false);
  const [isBrandOpen, setIsBrandOpen] = useState(false);

  return (
    <React.Fragment>
      <div className="category-box-wrapper">
        <Row
          className="browse-categories   "
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col xs="12">
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 50,
                paddingLeft: "3%",
                paddingRight: "3%",
              }}
            >
              <div>
                <strong style={{ color: "#575757" }}>
                  {shopTranslation?.categories
                    ? shopTranslation?.categories
                    : ShopContent?.categories}
                </strong>
              </div>

              <IoIosArrowDropdown
                size={20}
                onClick={() => {
                  setIsCategoriesOpen(!isCategoriesOpen);
                }}
                className="expand-button"
              />
            </Row>
            <Collapse isOpen={isCategoriesOpen}>
              <Row>
                <div
                  style={{
                    backgroundColor: "#f6f6f6",
                    width: "100%",
                    height: 2,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                />
              </Row>
              <Row className="pt-4">
                <Col xs="12 d-flex flex-wrap">
                  <ul className="radio-list pl-3">
                    {categorries?.map((item, index) => (
                      <li key={index}>
                        <Input
                          type="checkbox"
                          id="cat"
                          className="radio-cursor"
                          checked={mainCategoryChecked?.includes(item._id)}
                          onChange={(e) =>
                            handleCategoryCheckboxChanges(item._id)
                          }
                        />
                        <label
                          className="filter-list-item"
                          htmlFor="filter-item-46-"
                          style={{ marginLeft: "10px" }}
                        >
                          {userLanguage == "en"
                            ? item.name
                            : item.translatedArray.map((tr) => {
                                if (tr.language == userLanguage) {
                                  return tr.name;
                                }
                              })}
                          {/* {item.name} */}
                        </label>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Collapse>
          </Col>
        </Row>

        <Row
          className="browse-categories "
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col xs="12">
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 50,
                paddingLeft: "3%",
                paddingRight: "3%",
              }}
            >
              <div>
                <strong style={{ color: "#575757" }}>
                  {shopTranslation?.subcategories
                    ? shopTranslation?.subcategories
                    : ShopContent?.subcategories}
                </strong>
              </div>
              <IoIosArrowDropdown
                size={20}
                onClick={() => {
                  setIsSubCategoriesOpen(!isSubCategoriesOpen);
                }}
                className="expand-button"
              />
            </Row>
            <Collapse isOpen={isSubCategoriesOpen}>
              <Row>
                <div
                  style={{
                    backgroundColor: "#f6f6f6",
                    width: "100%",
                    height: 2,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                />
              </Row>
              <Row className="pt-4">
                <Col xs="12 d-flex flex-wrap">
                  <ul className="radio-list pl-3">
                    {subCategories?.map((item, index) => (
                      <li>
                        <Input
                          type="checkbox"
                          id="subcat"
                          className="radio-cursor"
                          checked={subCategoryChecked?.includes(item._id)}
                          value={item.name}
                          onChange={(e) =>
                            handleSubCategoryCheckboxChanges(item._id)
                          }
                        />
                        <label
                          className="filter-list-item"
                          htmlFor="filter-item-46-"
                          style={{ marginLeft: "10px" }}
                        >
                          {userLanguage == "en"
                            ? item.name
                            : item.translatedArray.map((tr) => {
                                if (tr.language == userLanguage) {
                                  return tr.name;
                                }
                              })}
                          {/* {item.name} */}
                        </label>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Collapse>
          </Col>
        </Row>

        <Row
          className="browse-categories"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col xs="12">
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 50,
                paddingLeft: "3%",
                paddingRight: "3%",
              }}
            >
              <div>
                <strong style={{ color: "#575757" }}>
                  {shopTranslation?.brand
                    ? shopTranslation?.brand
                    : ShopContent?.brand}
                </strong>
              </div>
              <IoIosArrowDropdown
                size={20}
                onClick={() => {
                  setIsBrandOpen(!isBrandOpen);
                }}
                className="expand-button"
              />
            </Row>
            <Collapse isOpen={isBrandOpen}>
              <Row>
                <div
                  style={{
                    backgroundColor: "#f6f6f6",
                    width: "100%",
                    height: 2,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                />
              </Row>
              <Row className="pt-4">
                <Col xs="12">
                  <ul className="radio-list pl-3">
                    {brands?.map((item, index) => (
                      <li key={index}>
                        <Input
                          type="checkbox"
                          className="radio-cursor"
                          checked={brandChekboxes?.includes(item)}
                          onChange={(e) => handleBrandCheckboxChanges(item)}
                        />
                        <label
                          className="filter-list-item"
                          htmlFor="filter-item-46-"
                          style={{ marginLeft: "10px" }}
                        >
                          {item}
                        </label>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Collapse>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
