import React, { Component, useEffect } from 'react';
import {
    Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, DropdownItem, DropdownMenu, Dropdown,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarToggler,
    Collapse,
    UncontrolledDropdown, Card, CardBody, Form, FormGroup, Label, CardGroup
} from 'reactstrap';
import axios from 'axios';
import classnames from 'classnames';
import Country from '../../assets/country/countries';
import States from '../../assets/country/states';
import Cities from '../../assets/country/cities';
import Env from '../../environment/environment';
import Header from '../../token/token';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SkyLight, { SkyLightStateless } from 'react-skylight';
import cookie from "react-cookies";
import FileBase64 from 'react-file-base64';


import {
    AppHeader,
} from '@coreui/react';


import DefaultFooter from '../Footer/DefaultFooter';
import DefaultHeader from '../Header/DefaultHeader';
import './CustomerProfile.css'
import { useSelector , useDispatch } from 'react-redux';
const user = {}
var address = []
var availabelAddress = []

const CustomerProfile = () => {
  const dispatch = useDispatch();
  const { translationState } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];

    let addrData = {};
    let state = {
        activeTab: '1',
        element: [],
        addrerr: true,
        states: 0,
        count: 0,
        addbtnstate: false,
        category: [],
        userData: [],
        states1: [],
        cities1: [],
        states2: [],
        cities2: [],
        search_product: {
            name: "",
            category: {
                name: []
            },
            brand: [],
            range: [],
            pageno: 0,
            sort: "all"
        },


        validationForm: [{
            address_type: true, addressline_1: true, country: true, state: true,
            city: true, zip: true, phone: true, zipIsNumber: true
        },
        {
            address_type: true, addressline_1: true, country: true, state: true,
            city: true, zip: true, phone: true, zipIsNumber: true
        }
        ],
        firstname: true, lastname: true, gender: true, contact: true, validateFormFlag: true,
        toastId: null, currentPasswordFlag: false, newPasswordFlag: false, confirmPasswordFlag: false,
        conflictPasswordFlag: false, passwordLengthFlag: false, wrongCurrentPasswordFlag: false,
        password: {
            user_id: "",
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        isOpen: false,
        profile_avatar_image: null
    };
    // constructor(props) {
    //     super(props);
    //     addrData = {}
    //     toggle = toggle;
    //     toggleNav = toggleNav;
    //     update = update;
    //     forceUpdate = forceUpdate;
    //     cpassword = null;
    //     passwordState = true;
    //     // submitAddr  = submitAddr;
    //     //loadUser = loadUser
    //     validateForm = validateForm
    // }

    const toggleNav = () => {
        //setState({
        //     isOpen: !state.isOpen,
        // });
    }

    const componentDidMount = () => {
        loadUser()

    }

    const loadUser = () => {
        var addr = [{
            address_type: "Permanent",
            addressline_1: "",
            addressline_2: "",
            country: "",
            states: "",
            city: "",
            zip: "",
            phone: ""
        },
        {
            address_type: "Current",
            addressline_1: "",
            addressline_2: "",
            country: "",
            states: "",
            city: "",
            zip: "",
            phone: ""
        }]
        axios.get(Env.url + '/user/get/' + cookie.load('_id'), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('auth_token')
            }
        }).then(response => {
            if (response.status == 200) {
                var userData = []
                userData = response.data
                //setState({ userData: userData });
                if (response.data[0].address == undefined) {
                    user['address'] = response.data[0].address
                    userData[0].address = addr
                    console.log('usedata', userData)
                    //setState({ userData: userData });
                } else {
                    for (var i in userData[0].address) {
                        var countryIndex = 1
                        if (userData[0].address[i] != undefined) {
                            for (var j in Country) {
                                if (userData[0].address[i].country == Country[j].name) {
                                    countryIndex = Country[j].id
                                }
                            }

                        }
                        if (i == 0) {
                            state.states1 = States.filter((cntry) => cntry.country_id == countryIndex)
                            var name = state.states1.find((state) => state.name == userData[0].address[i].states)
                            state.cities1 = Cities.filter((city) => city.state_id == name.id)

                        } else {
                            state.states2 = States.filter((cntry) => cntry.country_id == countryIndex)
                            var name = state.states2.find((state) => state.name == userData[0].address[i].states)
                            state.cities2 = Cities.filter((city) => city.state_id == name.id)
                        }

                    }
                    //setState({ userData: userData });
                    validation(userData[0])
                    validateForm(userData[0])
                }

            } else {
                console.log(Response.data)
            }
        }).catch(err => {
            if (err.response != undefined) {
                if (err.response.status == 401 || err.response.status == 400 || err.response.status == 500) {
                    window.location = "#/login";
                }
            }
        });
    }

    const componentWillMount = () => {

        axios.get(Env.url + '/product/category/get').then(response => {
            var cat = response.data
            axios.get(Env.url + '/product/subcategory/get').then(response => {
                var sc = response.data
                var category = []
                for (var c in cat) {
                    var subChild = []
                    for (var s in sc) {
                        if (cat[c]['_id'] == sc[s]['category_id']) {
                            var child = [];
                            for (var i in sc) {
                                if (sc[s]['_id'] === sc[i]['category_id']) {
                                    child.push(sc[i])
                                }
                            }
                            sc[s]['child'] = child
                            subChild.push(sc[s])
                        }
                    }
                    cat[c]['sub_category'] = subChild
                    category.push(cat[c])
                }
                //setState({ category: category });
            })
        })
    }

    const toggle = (tab) => {
        if (state.activeTab !== tab) {
            //setState({
            //     activeTab: tab,
            // });
        }
    }

    const validation = (userData) => {

        if (userData.firstname != "") {
            //setState({ firstname: true })
        }
        if (userData.lastname != "") {
            //setState({ lastname: true })
        }
        if (userData.phone != "") {
            //setState({ contact: true })
        }
        if (userData.gender != undefined) {
            //setState({ gender: true })
        }
        var validationForm = state.validationForm
        for (var i in userData.address) {
            if (userData.address[i].address_type != undefined && userData.address[i].address_type != "") {
                validationForm[i].address_type = true
            }
            if (userData.address[i].addressline_1 != "") {
                validationForm[i].addressline_1 = true
            }
            if (userData.address[i].country != undefined && userData.address[i].country != "") {
                validationForm[i].country = true
            }
            if (userData.address[i].states != undefined && userData.address[i].states != "") {
                validationForm[i].state = true
            }
            if (userData.address[i].city != undefined && userData.address[i].city != "") {
                validationForm[i].city = true
            }
            if (userData.address[i].zip != "") {
                validationForm[i].zip = true
            }
            if (userData.address[i].phone != "") {
                validationForm[i].phone = true
            }
        }
        //setState({ validationForm: validationForm })
    }

    const selectState = (event) => {
        // var data = state.element
        // //setState({ element: [...state.element, ...data] });
        state.states = States.filter(state => state.country_id == event.target.value)
        // //setState({ states: States.filter(let state => state.country_id == event.target.value)});

    }

    const selectFirstName = (event) => {
        var user = state.userData
        if (/^([a-zA-z]){1,20}$/.test(event.target.value)) {
            user[0].firstname = event.target.value
            //setState({ userData: user })
            state.firstname = true
            ////setState({ firstname: true })
        } else {
            if (event.target.value == "") {
                state.firstname = false
                ////setState({ firstname: false })
                user[0].firstname = event.target.value
                //setState({ userData: user })
            }
        }
        validateForm(user[0])
    }

    const selectLastName = (event) => {
        var user = state.userData
        if (/^([a-zA-z]){1,20}$/.test(event.target.value)) {
            user[0].lastname = event.target.value
            //setState({ userData: user })
            ////setState({ lastname: true })
            state.lastname = true
        } else {
            if (event.target.value == "") {
                ////setState({ lastname: false })
                state.lastname = false
                user[0].lastname = event.target.value
                //setState({ userData: user })
            }
        }
        validateForm(user[0])
    }
    const selectPhone = (event) => {
        var user = state.userData
        if (/^([0-9]){1,13}$/.test(event.target.value)) {
            user[0].phone = event.target.value
            //setState({ userData: user })
            ////setState({ contact: true })
            state.contact = true
        } else {
            if (event.target.value == "") {
                state.contact = false
                ////setState({ contact: false })
                user[0].phone = event.target.value
                //setState({ userData: user })
            }
        }
        validateForm(user[0])
    }

    const selectGender = (event) => {
        var user = state.userData
        if (event.target.value != 'Gender') {
            user[0].gender = event.target.value
            //setState({ userData: user })
            ////setState({ gender: true })
            state.gender = true
        } else {
            ////setState({ gender: false })
            state.gender = false
        }
        validateForm(user[0])
    }
    const selectEmail = (event) => {
        var user = state.userData
        user[0].email = event.target.value
        //setState({ userData: user })
    }
    const selectPassword = (event) => {
        user['password'] = event.target.value
    }
    const selectConfirm = (event) => {
        //setState({ confirm: event.target.value })
    }

    const close = (index, evt) => {
        const element = state.element;
        //setState({
        //     element: [...element.slice(0, state.count), ...element.slice(state.count + 1)]
        // });
        state.count = state.count - 1;
        const list = state.image;
        if (address.length > index) {
            let newAddress = address.splice(index, 1);
        }

    }

    const validateForm = (userData) => {




    }


    const addAddress = () => {
        addrData = {}
        state.addbtnstate = !state.addbtnstate;
        state.count = state.count + 1;
        // forceUpdate();
    }

    const addressType = (event, index) => {
        var user = state.userData
        if (event.target.value != 'Address Type') {
            if (index == undefined) {
                addrData['address_type'] = event.target.value

            } else {
                user[0].address[index].address_type = event.target.value
                //setState({ userData: user })
                var validationForm = state.validationForm
                validationForm[index].address_type = true
                //setState({ validationForm: validationForm })
            }

        } else {
            var validationForm = state.validationForm
            validationForm[index].address_type = true
            //setState({ validationForm: validationForm })
        }
        validateForm(user[0])
    }
    const addressLine1 = (event, index) => {
        var user = state.userData
        if (index == undefined) {
            addrData['addressline_1'] = event.target.value

        } else {
            user[0].address[index].addressline_1 = event.target.value
            //setState({ userData: user })
            var validationForm = state.validationForm
            if (event.target.value != "") {
                validationForm[index].addressline_1 = true
                //setState({ validationForm: validationForm })
            } else {
                validationForm[index].addressline_1 = false
                //setState({ validationForm: validationForm })
            }
        }
        validateForm(user[0])
    }
    const addressLine2 = (event, index) =>{
        var user = state.userData
        if (index == undefined) {
            addrData['addressline_2'] = event.target.value

        } else {
            user[0].address[index].addressline_2 = event.target.value
            //setState({ userData: user })
        }
        validateForm(user[0])
    }
    const country = (event, index) => {

        for (var i in Country) {
            if (event.target.value == Country[i].name) {
                state.countryIndex = Country[i].id
            }

        }
        if (index == 0) {
            state.states1 = States.filter((cntry) => cntry.country_id == state.countryIndex)

        } else {
            state.states2 = States.filter((cntry) => cntry.country_id == state.countryIndex)
        }
        //var cities = Cities.filter((cntry)=> cntry.state_id == "22")
        var user = state.userData
        if (event.target.value != 'Select Country') {
            if (index == undefined) {
                addrData['country'] = event.target.value

            } else {
                user[0].address[index].country = event.target.value
                //setState({ userData: user })
                var validationForm = state.validationForm
                validationForm[index].country = true
                //setState({ validationForm: validationForm })
            }
        } else {
            var validationForm = state.validationForm
            validationForm[index].country = true
            //setState({ validationForm: validationForm })
        }
        validateForm(user[0])
    }
    const states = (event, index) => {
        if (index == 0) {
            var name = state.states1.find((state) => state.name == event.target.value)
            //state.cities = States.filter((city)=> city.state_id == name.id )
            state.cities1 = Cities.filter((city) => city.state_id == name.id)

        } else {
            var name = state.states2.find((state) => state.name == event.target.value)
            //state.cities = States.filter((city)=> city.state_id == name.id )
            state.cities2 = Cities.filter((city) => city.state_id == name.id)
        }
        var user = state.userData
        if (event.target.value != 'Select State') {
            if (index == undefined) {
                addrData['states'] = event.target.value

            } else {
                user[0].address[index].states = event.target.value
                //setState({ userData: user })
                var validationForm = state.validationForm
                validationForm[index].state = true
                //setState({ validationForm: validationForm })
            }
        } else {
            var validationForm = state.validationForm
            validationForm[index].state = true
            //setState({ validationForm: validationForm })
        }
        validateForm(user[0])
    }
    const city = (event, index) =>{
        var user = state.userData
        if (event.target.value != 'Select City') {
            if (index == undefined) {
                addrData['city'] = event.target.value

            } else {
                user[0].address[index].city = event.target.value
                //setState({ userData: user })
                var validationForm = state.validationForm
                validationForm[index].city = true
                //setState({ validationForm: validationForm })
            }
        } else {
            var validationForm = state.validationForm
            validationForm[index].city = true
            //setState({ validationForm: validationForm })
        }
        validateForm(user[0])
    }
    const zip = (event, index) => {
        var user = state.userData
        if (index == undefined) {
            addrData['zip'] = event.target.value

        } else {
            if (/^[a-zA-Z0-9]*$/.test(event.target.value)) {
                user[0].address[index].zip = event.target.value
                //setState({ userData: user })
                var validationForm = state.validationForm
                if (event.target.value != "") {
                    validationForm[index].zip = true
                    validationForm[index].zipIsNumber = true
                    //setState({ validationForm: validationForm })
                } else {
                    validationForm[index].zip = false
                    //setState({ validationForm: validationForm })
                }
            } else {
                user[0].address[index].zip = event.target.value
                //setState({ userData: user })
                var validationForm = state.validationForm
                validationForm[index].zip = true
                validationForm[index].zipIsNumber = false
                //setState({ validationForm: validationForm })
            }
        }
        validateForm(user[0])
    }
    const phone = (event, index) => {
        var user = state.userData
        if (index == undefined) {
            addrData['phone'] = event.target.value

        } else {
            if (/^([0-9]){1,13}$/.test(event.target.value)) {
                user[0].address[index].phone = event.target.value
                //setState({ userData: user })
                var validationForm = state.validationForm
                validationForm[index].phone = true
            } else {
                if (event.target.value == "") {
                    user[0].address[index].phone = event.target.value
                    //setState({ userData: user })
                    var validationForm = state.validationForm
                    validationForm[index].phone = false
                    //setState({ validationForm: validationForm })
                }
            }
        }
        validateForm(user[0])
    }

    const submitAddr = () => {
        if (!availabelAddress.includes(state.count)) {
            address.push(addrData)
            //setState({ addbtnstate: !state.addbtnstate })
            availabelAddress.push(state.count)
        }
    }

    const changePassword = () => {
        // //simpleDialog.show();
        state.forgotState = true;
    }

    const update = () => {
        var user = state.userData
        var userData = user[0]
        if (userData.firstname != "") {
            //setState({ firstname: true })
        } else {
            //setState({ firstname: false })
        }
        if (userData.lastname != "") {
            //setState({ lastname: true })
        } else {
            //setState({ lastname: false })
        }
        if (userData.phone == undefined || userData.phone == "") {
            state.contact = false
            //setState({ contact: false })
        } else {
            //setState({ contact: true })
        }
        if (userData.gender != undefined) {
            //setState({ gender: true })
        } else {
            //setState({ gender: false })
        }
        var validationForm = state.validationForm
        for (var i in userData.address) {
            if (userData.address[i].address_type != undefined && userData.address[i].address_type != "") {
                validationForm[i].address_type = true
            } else {
                validationForm[i].address_type = false
            }
            if (userData.address[i].addressline_1 != "") {
                validationForm[i].addressline_1 = true
            } else {
                validationForm[i].addressline_1 = false
            }
            if (userData.address[i].country != undefined && userData.address[i].country != "") {
                validationForm[i].country = true
            } else {
                validationForm[i].country = false
            }
            if (userData.address[i].states != undefined && userData.address[i].states != "") {
                validationForm[i].state = true
            } else {
                validationForm[i].states = false
            }
            if (userData.address[i].city != undefined && userData.address[i].city != "") {
                validationForm[i].city = true
            } else {
                validationForm[i].city = false
            }
            if (userData.address[i].zip != "") {
                validationForm[i].zip = true
            } else {
                validationForm[i].zip = false
            }
            if (userData.address[i].phone != "") {
                validationForm[i].phone = true
            } else {
                validationForm[i].phone = false
            }
        }

        //setState({ validationForm: validationForm })

        var validationForm = state.validationForm
        if (state.firstname && state.lastname && state.contact && state.gender

        ) {
            //setState({ validateFormFlag: false })

            const data = state.userData[0]
            data.profile_avatar_image = state.profile_avatar_image

            axios.put(Env.url + '/user/update/' + cookie.load('_id'), data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('auth_token')
                    }
                }).then(function (response) {
                    if (response.status === 200) {

                        toast.success(`${toastTranslation?.success ? toastTranslation?.success : "Profile updated Successfully."}`, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loadUser()
                    } else {
                        toast.error(`${toastTranslation?.success ? toastTranslation?.success : "Profile not updated."}`, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }).catch(err => {
                    if (err.response != undefined) {
                        if (err.response.status == 401 || err.response.status == 400 || err.response.status == 500) {
                            window.location = "#/login";
                        }
                    }
                });

        } else {
            //setState({ validateFormFlag: true })
        }





        // }            
    }

    const handleCurrentPassword = (evt) => {
        var password = state.password
        password.currentPassword = evt.target.value
        //setState({ password: password })

    }

    const handleNewPassword = (evt) => {
        var password = state.password
        password.newPassword = evt.target.value
        //setState({ password: password })
    }

    const handleConfirmPassword = (evt) => {
        var password = state.password
        password.confirmPassword = evt.target.value
        //setState({ password: password })
    }
    const updatePassword = () => {
        var password = state.password
        password.user_id = cookie.load('_id')
        //setState({ password: password })

        if (password.currentPassword == "") {
            //setState({ currentPasswordFlag: true })
            //setState({ wrongCurrentPasswordFlag: false })
        } else if (password.currentPassword != "") {
            //setState({ currentPasswordFlag: false })
            if (password.newPassword.length < 7) {
                //setState({ passwordLengthFlag: true })
            } else {
                //setState({ passwordLengthFlag: false })
            } if (password.newPassword != password.confirmPassword) {
                //setState({ conflictPasswordFlag: true })
            } else {
                //setState({ conflictPasswordFlag: false })
            }
            if (password.newPassword.length > 7 && password.newPassword == password.confirmPassword) {

                axios.put(Env.url + '/user/change-password', state.password,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('auth_token')
                        }
                    }).then(function (response, err) {
                        if (response.data.status === 200) {
                            if (response.data.success) {
                                //setState({ currentPasswordFlag: false })
                                //setState({ passwordLengthFlag: false })
                                //setState({ conflictPasswordFlag: false })
                                toast.success(response.data.message, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                // //simpleDialog.hide();
                            } else {
                                //setState({ passwordLengthFlag: false })
                                //setState({ conflictPasswordFlag: false })
                                if (response.data.message == "Current password is wrong") {
                                    //setState({ wrongCurrentPasswordFlag: true })
                                    //setState({ currentPasswordFlag: false })
                                }
                            }
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT
                            });

                        }
                    }).catch(err => {
                        if (err.response != undefined) {
                            if (err.response.status == 401 || err.response.status == 400 || err.response.status == 500) {
                                window.location = "#/login";
                            } else {
                                toast.error(`${toastTranslation?.error ? toastTranslation?.error : "Error while changing password"}`, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }
                        }


                    })

            } else {
                if (password.newPassword.length < 7) {
                    //setState({ passwordLengthFlag: true })
                } else {
                    //setState({ passwordLengthFlag: false })
                } if (password.newPassword != password.confirmPassword) {
                    //setState({ conflictPasswordFlag: true })
                } else {
                    //setState({ conflictPasswordFlag: false })
                }
            }
        }
    }

    const searchByCategory = (item, mainIndex, subIndex) => {

        var category = state.category
        var searchString = ''
        searchString = searchString + "&category=" + item
        if (mainIndex != undefined) {
            searchString = searchString + "&category=" + category[mainIndex].name
        }
        if (subIndex != undefined) {
            searchString = searchString + "&category=" + category[mainIndex].sub_category[subIndex].name
        }

        searchString = searchString + "&sort=all"
        setTimeout(function () { window.location = "#/search?" + searchString; }, 200);
    }

    const handleImageUpdate = (file) => {
        //setState({
        //     profile_avatar_image: file
        // })
    }

        var myBigGreenDialog = {
            width: '27%',
            minHeight: '271px',
            top: '50%',
            left: '62%'
        };
        return (
            <div className="app" style={{ "background-color": "white" }}>
                <ToastContainer autoClose={1800} />
                <SkyLight dialogStyles={myBigGreenDialog} 
                // ref={ref => simpleDialog = ref} 
                >
                    <Row className="justify-content-center">
                        <Col md="12">
                            <CardGroup>
                                <Card style={{ border: 'none' }}>

                                    <Label style={{ display: 'flex', justifyContent: 'center', marginTop: '4%' }} check htmlFor="inline-checkbox1">
                                        <b>Set Your New Password</b>
                                    </Label>
                                    <Label style={{ display: 'flex', justifyContent: 'center' }} check htmlFor="inline-checkbox1">
                                        <b>{state.passwordMessage}</b>
                                    </Label>

                                    <CardBody>
                                        <Form action="" onSubmit={updatePassword} encType="multipart/form-data" className="form-horizontal">
                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password" onChange={handleCurrentPassword} placeholder="Current password" />
                                            </InputGroup>
                                            <InputGroup className="mb-3" hidden={!state.wrongCurrentPasswordFlag}>
                                                <div className="password-validations" >
                                                    Current password is wrong.
                                                </div>
                                            </InputGroup>
                                            <InputGroup className="mb-3" hidden={!state.currentPasswordFlag}>
                                                <div className="password-validations" >
                                                    Please enter current password.
                                                </div>
                                            </InputGroup>
                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password" onChange={handleNewPassword} placeholder="New password" />
                                            </InputGroup>
                                            <InputGroup className="mb-3" hidden={!state.passwordLengthFlag}>
                                                <div className="password-validations" >
                                                    Password length must be greater than 8 characters.
                                                </div>
                                            </InputGroup>
                                            {/* <InputGroup className="mb-3" hidden={!state.newPasswordFlag}>
                                                <div style={divStyle} >
                                                    Please enter new password.
                                                </div>
                                            </InputGroup> */}
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password" onChange={handleConfirmPassword} placeholder="Confirm password" />
                                            </InputGroup>
                                            <InputGroup className="mb-3" hidden={!state.conflictPasswordFlag}>
                                                <div className="password-validations" >
                                                    New password and confirm password must be same.
                                                </div>
                                            </InputGroup>
                                            <InputGroup className="mb-3" hidden={!state.confirmPasswordFlag}>
                                                <div className="password-validations" style={{ marginTop: '-5%' }} >
                                                    Please enter confirm password.
                                                </div>
                                            </InputGroup><br />
                                            <InputGroup className="mb-3" style={{ display: 'flex', justifyContent: 'center', marginTop: "-6%" }}>
                                                <Button color="warning" className="btn btn-md" onClick={updatePassword} block><b>Change password</b></Button>
                                            </InputGroup>
                                        </Form>
                                    </CardBody>

                                </Card>

                            </CardGroup>
                        </Col>
                    </Row>
                </SkyLight>

                    <AppHeader fixed>
                        <DefaultHeader />
                    </AppHeader>
                {cookie.load('user') == 'user' &&
                    <Row>
                        <Navbar color="white" light expand="lg" style={{ marginTop: '3.4rem', boxShadow: '0 0 3px 0 rgba(50, 50, 50, 0.6)', zIndex: '99', width: '100%' }}>
                            <NavbarToggler onClick={toggleNav} />
                            <Collapse isOpen={state.isOpen} navbar>
                                {state.category.length > 0 && state.category.map((cat, mainIndex) => (
                                    <Nav className="ml-4" navbar>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret >
                                                {cat.name}
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                {cat.sub_category != undefined && cat.sub_category.map((sub_cat, subIndex) => (
                                                    <Dropdown>
                                                        <DropdownItem onClick={e => searchByCategory(sub_cat.name, mainIndex)}>{sub_cat.name}</DropdownItem>
                                                        <ul>
                                                            {sub_cat.child != undefined && sub_cat.child.map((ch, childIndex) => (
                                                                <DropdownItem onClick={e => searchByCategory(ch.name, mainIndex, subIndex)}>
                                                                    {ch.name}
                                                                </DropdownItem>))}
                                                        </ul>
                                                    </Dropdown>))}
                                            </DropdownMenu>

                                        </UncontrolledDropdown>
                                    </Nav>))}
                            </Collapse>
                        </Navbar>

                    </Row>
                }
                <Card className="animated fadeIn" style={{ margin: '4%' }}>
                    <Row style={{ marginTop: '4%' }}>
                        <Col xs="12" sm="5"></Col>
                        <Col xs="12" sm="5">
                            <h3>Personal Information</h3>
                        </Col>
                    </Row>
                    {state.userData.length != 0 ? state.userData.map((item, index) => (
                        <div>
                            <Row style={{ paddingBottom: 5, marginTop:'4%', marginBottom:30 }}>
                                <Col xs="12" sm="1"></Col>
                                <Col xs="12" sm="1">
                                    <img alt="avatar unavailable" src={ state.profile_avatar_image? state.profile_avatar_image.base64 : state.userData[0].profile_avatar} 
                                    style={{width:100, height:100, minHeight:100, minWidth:100, border:"1px solid black"}}/>
                                </Col>
                                <Col xs="12" sm="5" style={{paddingTop:20}}>
                                    <div>
                                        {state.userData[0].profile_avatar?"Change ":"Upload "} Profile Pic:
                                    </div>
                                    <FileBase64
                                        onDone={handleImageUpdate}
                                        value={state.profile_avatar_image}
                                    />
                                </Col>
                            </Row>
                            <Row >
                                <Col xs="12" sm="1"></Col>
                                <Col xs="12" sm="5">
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon> <InputGroupText><i className="icon-user"></i></InputGroupText></InputGroupAddon>
                                        <Input onChange={(e) => selectFirstName(e)} value={item.firstname} type="text" maxlength="20" placeholder="First name" />
                                    </InputGroup>
                                    <InputGroup className="mb-3" hidden={state.firstname}>
                                        <div className="validations" >
                                            Please enter first name.
                            </div>
                                    </InputGroup>
                                </Col>
                                <Col xs="12" sm="5">
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon><InputGroupText><i className="icon-user"></i></InputGroupText></InputGroupAddon>
                                        <Input onChange={(e) => selectLastName(e)} value={item.lastname} type="text" maxlength="20" placeholder="Last name" />
                                    </InputGroup>
                                    <InputGroup className="mb-3" hidden={state.lastname}>
                                        <div className="validations" >
                                            Please enter last name.
                            </div>
                                    </InputGroup>
                                </Col><Col xs="12" sm="1"></Col>
                                <Col xs="12" sm="1"></Col>
                                <Col xs="12" sm="5">
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon><InputGroupText><i className="icon-phone"></i></InputGroupText></InputGroupAddon>
                                        <Input onChange={(e) => selectPhone(e)} value={item.phone} type="text" maxlength="20" placeholder="Contact Number" />
                                    </InputGroup>
                                    <InputGroup className="mb-3" hidden={state.contact}>
                                        <div className="validations" >
                                            Please enter phone number.
                                        </div>
                                    </InputGroup>
                                </Col>
                                <Col xs="12" sm="5">
                                    <InputGroup className="mb-3">
                                        <Input onChange={(e) => selectGender(e)} value={item.gender} type="select" name="select" id="exampleSelect">
                                            <option>Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                            <option>Others</option>
                                        </Input>
                                    </InputGroup>
                                    <InputGroup className="mb-3" hidden={state.gender}>
                                        <div className="validations" >
                                            Please select gender.
                                        </div>
                                    </InputGroup>
                                </Col>
                                <Col xs="12" sm="1"></Col>
                                <Col xs="12" sm="1"></Col>
                                <Col xs="12" sm="5">
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon><InputGroupText><i className="icon-envelope-letter"></i></InputGroupText></InputGroupAddon>
                                        <Input type="text" maxlength="20" value={item.email} onChange={(e) => selectEmail(e)} placeholder="Email" disabled />
                                    </InputGroup>
                                </Col>
                                <Col xs="12" sm="4">
                                    {!cookie.load('thirdParty') && <Button color="link" onClick={changePassword} className="px-0">Change password</Button>}
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs="12" sm="1"></Col>
                                <Col xs="12" sm="5">
                                    <Card>

                                        <CardBody className="card-body">
                                            <Form action="" method="post" className="form-horizontal">
                                                <FormGroup>
                                                    <Row>
                                                        <Col xs="12" sm="4">
                                                            <Label htmlFor="appendedPrependedInput"> Select Address Type</Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <FormGroup>
                                                                <Input 
                                                                    onChange={(e) => addressType(e, 0)} 
                                                                    value={state.userData[0].address && state.userData[0].address[0].address_type} 
                                                                    type="select" name="select" id="exampleSelect">
                                                                    <option>Address Type</option>
                                                                    <option>Permanent</option>
                                                                    <option>Current</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row hidden={state.validationForm[0].address_type}>
                                                        <Col xs="12" sm="4"></Col>
                                                        <Col xs="12" sm="8">

                                                            <InputGroup className="mb-3">
                                                                {/* <div className="validations" >
                                                                    Please select address type.
                                                                </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label > Address line 1 <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input 
                                                                    onChange={(e) => addressLine1(e, 0)} 
                                                                    value={ state.userData[0].address &&  state.userData[0].address[0].addressline_1} 
                                                                    type="text" />
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[0].addressline_1}>
                                                                {/* <div className="validations" >
                                                                    Please enter your address.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label > Address line 2</Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => addressLine2(e, 0)} 
                                                                value={state.userData[0].address &&  state.userData[0].address[0].addressline_2} type="text" />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label > Country <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => country(e, 0)} 
                                                                value={state.userData[0].address &&  state.userData[0].address[0].country} type="select" name="select" id="exampleSelect">
                                                                    <option>Select Country</option>
                                                                    {Country ? Country.map((ct, i) => (
                                                                        <option value={ct.name} >{ct.name}</option>
                                                                    )) : 'Country not found'}
                                                                </Input>
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[0].country}>
                                                                {/* <div className="validations" >
                                                                    Please select country.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label htmlFor="appendedPrependedInput"> State</Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => states(e, 0)} 
                                                                value={ state.userData[0].address &&  state.userData[0].address[0].states} 
                                                                type="select" name="select" id="exampleSelect">
                                                                    <option>Select State</option>
                                                                    {state.states1.length > 0 ? state.states1.map((ct, i) => (
                                                                        <option value={ct.index}>{ct.name}</option>
                                                                    )) : 'State not found'}
                                                                </Input>
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[0].state}>
                                                                {/* <div className="validations" >
                                                                    Please select state.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label htmlFor="appendedPrependedInput"> City <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => city(e, 0)} 
                                                                value={state.userData[0].address &&  state.userData[0].address[0].city} type="select" name="select" id="exampleSelect">
                                                                    <option>Select City</option>
                                                                    {state.cities1.length > 0 ? state.cities1.map((ct, i) => (
                                                                        <option>{ct.name}</option>
                                                                    )) : 'State not found'}
                                                                </Input>
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[0].city}>
                                                                {/* <div className="validations" >
                                                                    Please select city.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label > Zip/Pin/Postal code <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => zip(e, 0)} 
                                                                value={state.userData[0].address && state.userData[0].address[0].zip} type="text" maxLength={6} />
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[0].zip}>
                                                                {/* <div className="validations" >
                                                                    Please enter zip/pin/postal code.
                                                            </div> */}
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[0].zipIsNumber}>
                                                                {/* <div className="validations" >
                                                                    Please enter zip/pin/postal code.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label htmlFor="appendedPrependedInput"> Contact number <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => phone(e, 0)} value={
                                                                    state.userData[0].address && state.userData[0].address[0].phone} type="text" maxLength={10} />
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[0].phone}>
                                                                {/* <div className="validations" >
                                                                    Please enter valid contact number.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                </FormGroup>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs="12" sm="5">
                                    <Card>

                                        <CardBody className="card-body">
                                            <Form action="" method="post" className="form-horizontal">
                                                <FormGroup>
                                                    <Row>
                                                        <Col xs="12" sm="4">
                                                            <Label htmlFor="appendedPrependedInput"> Select Address Type</Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <FormGroup>
                                                                <Input onChange={(e) => addressType(e, 1)} 
                                                                value={state.userData[0].address &&  state.userData[0].address[1].address_type} type="select" name="select" id="exampleSelect">
                                                                    <option>Address Type</option>
                                                                    <option>Permanent</option>
                                                                    <option>Current</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row hidden={state.validationForm[1].address_type}>
                                                        <Col xs="12" sm="4"></Col>
                                                        <Col xs="12" sm="8">

                                                            <InputGroup className="mb-3">
                                                                {/* <div className="validations" >
                                                                    Please select address type.
                                                                </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label > Address line 1 <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => addressLine1(e, 1)} value={state.userData[0].address && state.userData[0].address[1].addressline_1} type="text" />
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[1].addressline_1}>
                                                                {/* <div className="validations" >
                                                                    Please enter your address.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label > Address line 2</Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => addressLine2(e, 1)} value={state.userData[0].address && state.userData[0].address[1].addressline_2} type="text" />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label > Country <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => country(e, 1)} value={state.userData[0].address && state.userData[0].address[1].country} type="select" name="select" id="exampleSelect">
                                                                    <option>Select Country</option>
                                                                    {Country ? Country.map((ct, i) => (
                                                                        <option value={ct.name} >{ct.name}</option>
                                                                    )) : 'Country not found'}
                                                                </Input>
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[1].country}>
                                                                {/* <div className="validations" >
                                                                    Please select country.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label htmlFor="appendedPrependedInput"> State</Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => states(e, 1)} value={state.userData[0].address && state.userData[0].address[1].states} type="select" name="select" id="exampleSelect">
                                                                    <option>Select State</option>
                                                                    {state.states2.length > 0 ? state.states2.map((ct, i) => (
                                                                        <option value={ct.index}>{ct.name}</option>
                                                                    )) : 'State not found'}
                                                                </Input>
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[1].state}>
                                                                {/* <div className="validations" >
                                                                    Please select state.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label htmlFor="appendedPrependedInput"> City <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => city(e, 1)} value={state.userData[0].address && state.userData[0].address[1].city} type="select" name="select" id="exampleSelect">
                                                                    <option>Select City</option>
                                                                    {state.cities2.length > 0 ? state.cities2.map((ct, i) => (
                                                                        <option>{ct.name}</option>
                                                                    )) : 'State not found'}
                                                                </Input>
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[1].city}>
                                                                {/* <div className="validations" >
                                                                    Please select city.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label > Zip/Pin/Postal code <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => zip(e, 1)} value={state.userData[0].address && item.address[1].zip} type="text" maxLength={6} />
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[1].zip}>
                                                                {/* <div className="validations" >
                                                                    Please enter zip/pin/postal code.
                                                            </div> */}
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[1].zipIsNumber}>
                                                                {/* <div className="validations" >
                                                                    Please enter zip/pin/postal code.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row className="row-validations">
                                                        <Col xs="12" sm="4">
                                                            <Label htmlFor="appendedPrependedInput"> Contact number <span style={{ color: 'red' }}>*</span></Label>
                                                        </Col>
                                                        <Col xs="12" sm="8">
                                                            <InputGroup className="input-prepend">
                                                                <Input onChange={(e) => phone(e, 1)} value={state.userData[0].address && state.userData[0].address[1].phone} type="text" maxLength={10} />
                                                            </InputGroup>
                                                            <InputGroup hidden={state.validationForm[1].phone}>
                                                                {/* <div className="validations" >
                                                                    Please enter valid contact number.
                                                            </div> */}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>

                                                </FormGroup>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                    )) : ' '}





                    {state.element.length > 0 ? state.element.map((item, index) => (
                        <Row>
                            <Col xs="12" sm="1"></Col>
                            <Col xs="12" sm="10">
                                {item}
                            </Col>
                            <Col xs="12" sm="1"></Col>
                        </Row>
                    )) : ' '}


                    <Row style={{ marginBottom: '4%' }}>
                        <Col xs="12" sm="5"></Col>
                        <Col col="12" sm="2"   >
                            <Button block color="warning" size="mm" onClick={(e) => update()}><b>Save</b></Button>
                        </Col>
                    </Row>

                </Card>
                <div className="local-footer" >
                    <DefaultFooter />
                </div>
            </div>

        );
}

export default CustomerProfile;
