import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Config from "../../../../config/aws";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import {
  updateProduct,
  getSingleProduct,
  addFilter,
  getCategoriesByType,
} from "../../../../redux/reducers/seller";
import "./Update.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Gerne from "../../../../assets/json/gerne";
import videoquality from "../../../../assets/json/videoquality";
import Language from "../../../../assets/language/language";
import Mp3 from "../../../../assets/json/mp3";
import SoftwareCategory from "../../../../assets/json/softwares";
import TheaterType from "../../../../assets/json/theaterType";
import {
  getPresigedUrl,
  uploadToS3ViaSignedUrl,
} from "../../../../helpers/s3Utils";

const UpdateDigitalProducts = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const bookUrlRef = useRef(null);
  const bookCoverUrlRef = useRef(null);
  const trailerUrlRef = useRef(null);
  const urlArray = history.location.pathname.split("/");
  const [formError, setFormError] = useState(false);
  const productId = urlArray[urlArray.length - 1];
  const digitalType = urlArray[urlArray.length - 3];
  const { loading, singleProduct, productCategories } = useSelector(
    (state) => state.seller
  );
  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const [bookUrl, setBookUrl] = useState("");
  const [bookCoverUrl, setBookCoverUrl] = useState("");
  const [trailerUrl, setTrailerUrl] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [productDescriptionData, setProductDescriptionData] = useState("");
  const [description, setDescription] = useState({
    editorState: EditorState.createEmpty(),
  });
  useEffect(() => {
    dispatch(getSingleProduct(productId));
    dispatch(
      addFilter({
        field: "type",
        value: "digital",
      })
    );
    dispatch(getCategoriesByType());
  }, []);
  useEffect(() => {
    if (singleProduct.specification) {
      setPageContent({
        ...singleProduct,
        mainCategory: singleProduct.mainCategory?._id,
      });
      setBookCoverUrl(singleProduct?.images[0]);
      setBookUrl(singleProduct?.productUrl);
      setTrailerUrl(singleProduct?.trailerUrl);
      setDescription({
        editorState: EditorState.createWithContent(
          convertFromRaw({
            entityMap: {},
            blocks: singleProduct?.specification?.blocks,
          })
        ),
      });
    }
  }, [singleProduct]);

  const changeDescription = (editorState) => {
    setDescription({ editorState });
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const handleClick = () => {
    bookUrlRef.current.click();
  };
  const handleCoverClick = () => {
    bookCoverUrlRef.current.click();
  };
  const handleTrailerClick = () => {
    trailerUrlRef.current.click();
  };
  const uploadImage = async (blobs, type) => {
    for (let i = 0; i < blobs.length; i++) {
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };

      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const imageUrl = `${Config.digitalOceanSpaces}/` + blob.name;
        if (type === "book") {
          toast.success(`${digitalType} uploaded successfully`);
          setBookUrl(imageUrl);
        } else if (type === "trailer") {
          toast.success(
            `${
              toastTranslation?.success
                ? toastTranslation?.success
                : "Trailer uploaded successfully"
            }`
          );
          setTrailerUrl(imageUrl);
        } else {
          toast.success(
            `${
              toastTranslation?.success
                ? toastTranslation?.success
                : "Cover uploaded successfully"
            }`
          );
          setBookCoverUrl(imageUrl);
        }
      } catch (error) {
        toast.error(
          `${
            toastTranslation?.error
              ? toastTranslation?.error
              : "error in upload file"
          }`
        );
      }
    }
  };
  const uploadBook = (event) => {
    uploadImage(event.target.files, "book");
  };
  const uploadTrailer = (event) => {
    uploadImage(event.target.files, "trailer");
  };
  const uploadBookCover = (event) => {
    uploadImage(event.target.files, "bookCover");
  };
  const save = () => {
    const specification = convertToRaw(
      description.editorState.getCurrentContent()
    );
    setProductDescriptionData(specification?.blocks[0]?.text);
    const {
      name,
      authorName,
      bookLength,
      language,
      director,
      runtime,
      releaseDate,
      seasonLength,
      gerne,
      videoQuality,
      productionName,
      regularPrice,
      stockQuantity,
      offeredBy,
      gameSize,
      requireDevice,
      singerName,
      audioQuality,
      softwareSize,
      softwareCategory,
      theaterType,
      mainCategory,
    } = pageContent;
    if (
      name &&
      bookUrl &&
      bookCoverUrl &&
      language &&
      regularPrice &&
      stockQuantity &&
      trailerUrl &&
      mainCategory
    ) {
      setFormError(false);
      if (digitalType === "series") {
        if (
          !seasonLength ||
          !director ||
          !releaseDate ||
          !productionName ||
          !gerne ||
          !videoQuality
        ) {
          setFormError(true);
          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "movie") {
        if (
          !runtime ||
          !director ||
          !releaseDate ||
          !productionName ||
          !gerne ||
          !videoQuality
        ) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "ebook") {
        if (!bookLength || !authorName) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "game") {
        if (!offeredBy || !gameSize || !releaseDate || !requireDevice) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "music") {
        if (
          !singerName ||
          !runtime ||
          !releaseDate ||
          !productionName ||
          !audioQuality
        ) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "software") {
        if (
          !offeredBy ||
          !softwareSize ||
          !releaseDate ||
          !requireDevice ||
          !softwareCategory
        ) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "theater") {
        if (
          !director ||
          !runtime ||
          !releaseDate ||
          !theaterType ||
          !videoQuality
        ) {
          setFormError(true);
          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      }
    } else {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Kindly fill the required fields"
        }`
      );
      setFormError(true);
      return;
    }
    setFormError(false);
    const data = {
      ...pageContent,
      id: productId,
      specification,
      productUrl: bookUrl,
      trailerUrl,
      images: [bookCoverUrl],
      digitalType: digitalType,
      dynamicPrice: {
        basePrice: pageContent.regularPrice,
        currentPrice: pageContent.regularPrice,
      },
      productType: "digital",
    };

    if (userType === "seller") {
      dispatch(updateProduct(data)).then(() =>
        history.push(`/seller/digitalproduct/${digitalType}`)
      );
    } else {
      dispatch(updateProduct(data)).then(() =>
        history.push(`/admin/digitalproductlist`)
      );
    }
  };
  return (
    <div className="animated fadeIn">
      {/* layout for prising table */}
      <Row>
        <Col xs="12" lg="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <i className="fa fa-align-justify"></i>
                  <b style={{ textTransform: "capitalize" }}>
                    Update {digitalType}
                  </b>
                </Col>
                <Col xs="12" sm="2">
                  <Button
                    size="sm"
                    onClick={() => {
                      userType === "admin"
                        ? history.push(`/admin/digitalproductlist`)
                        : history.push(`/seller/digitalproduct/${digitalType}`);
                    }}
                    color="warning"
                  >
                    <b>View All</b>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label
                      htmlFor="exampleInputName2"
                      className="pr-1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {digitalType} Title
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.name && formError && "form-error"
                      }`}
                      name="name"
                      onChange={handleInputChange}
                      value={pageContent.name}
                      type="text"
                      placeholder={`${digitalType} title`}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label
                      htmlFor="exampleInputName2"
                      className="pr-1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {digitalType} Description
                    </Label>
                  </Col>
                  <Col lg="9">
                    <div>
                      <Editor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        placeholder="Product Description"
                        editorStyle={{
                          border: `${
                            formError && !productDescriptionData
                              ? "2px solid red"
                              : ""
                          }`,
                        }}
                        // wrapperClassName="demo-wrapper"
                        // editorClassName="editer-content"
                        editorState={description.editorState}
                        onEditorStateChange={changeDescription}
                      />
                    </div>
                  </Col>
                </Row>
              </FormGroup>
              {(digitalType === "movie" || digitalType === "series") && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Director</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.director && formError && "form-error"
                          }`}
                          name="director"
                          onChange={handleInputChange}
                          value={pageContent.director}
                          type="text"
                          placeholder="Director Name"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {digitalType === "movie" && (
                    <>
                      <FormGroup className="pr-1">
                        <Row>
                          <Col lg="3">
                            <Label className="pr-1">Movie Length</Label>
                          </Col>
                          <Col lg="9">
                            <Input
                              className={`input-prepend ${
                                !pageContent?.runtime &&
                                formError &&
                                "form-error"
                              }`}
                              name="runtime"
                              onChange={handleInputChange}
                              value={pageContent.runtime}
                              type="number"
                              placeholder="Movie Length in minutes"
                              required
                              min={0}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </>
                  )}
                  {digitalType === "series" && (
                    <>
                      <FormGroup className="pr-1">
                        <Row>
                          <Col lg="3">
                            <Label className="pr-1">Number of Seasons</Label>
                          </Col>
                          <Col lg="9">
                            <Input
                              className={`input-prepend ${
                                !pageContent?.seasonLength &&
                                formError &&
                                "form-error"
                              }`}
                              name="seasonLength"
                              onChange={handleInputChange}
                              value={pageContent.seasonLength}
                              type="number"
                              placeholder="Season"
                              required
                              min={0}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </>
                  )}
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Release Date</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          name="release_date"
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder="Release date"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Filming and production</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.productionName &&
                            formError &&
                            "form-error"
                          }`}
                          name="productionName"
                          value={pageContent.productionName}
                          onChange={handleInputChange}
                          type="text"
                          placeholder="Filming and production"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Gerne</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.gerne && formError && "form-error"
                          }`}
                          type="select"
                          name="gerne"
                          value={pageContent.gerne}
                          onChange={handleInputChange}
                        >
                          <option>Select Gerne</option>
                          {Gerne
                            ? Gerne.map((ct, i) => <option>{ct.name}</option>)
                            : "Language not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Video quality</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.videoQuality &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="videoQuality"
                          value={pageContent.videoQuality}
                          onChange={handleInputChange}
                        >
                          <option>Select Video Quality</option>
                          {videoquality
                            ? videoquality.map((ct, i) => (
                                <option>{ct.name}</option>
                              ))
                            : "Language not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "ebook" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label htmlFor="exampleInputName2" className="pr-1">
                          Author Name
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.authorName &&
                            formError &&
                            "form-error"
                          }`}
                          name="authorName"
                          onChange={handleInputChange}
                          value={pageContent.authorName}
                          type="text"
                          placeholder="Author Name"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label htmlFor="exampleInputName2" className="pr-1">
                          Book Length
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.bookLength &&
                            formError &&
                            "form-error"
                          }`}
                          name="bookLength"
                          onChange={handleInputChange}
                          value={pageContent.bookLength}
                          type="number"
                          placeholder="Book Length"
                          required
                          min={0}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "game" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Offered by</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.offeredBy && formError && "form-error"
                          }`}
                          name="offeredBy"
                          onChange={handleInputChange}
                          value={pageContent.offeredBy}
                          type="text"
                          placeholder="Offered by"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Size</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.gameSize && formError && "form-error"
                          }`}
                          name="gameSize"
                          onChange={handleInputChange}
                          value={pageContent.gameSize}
                          type="number"
                          placeholder="Size in mb"
                          required
                          min={0}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Release Date</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          name="releaseDate"
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder="Release date"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Supported Device</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.requireDevice &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="requireDevice"
                          value={pageContent.requireDevice}
                          onChange={handleInputChange}
                        >
                          <option>Select Device</option>
                          <option>Android</option>
                          <option>IOS</option>
                          <option>Both android and ios</option>
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "music" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Singer</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.singerName &&
                            formError &&
                            "form-error"
                          }`}
                          name="singerName"
                          onChange={handleInputChange}
                          value={pageContent.singerName}
                          type="text"
                          placeholder="Singer Name"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Music Length</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.runtime && formError && "form-error"
                          }`}
                          name="runtime"
                          onChange={handleInputChange}
                          value={pageContent.runtime}
                          type="number"
                          placeholder="Music Length in minutes"
                          required
                          min={0}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Release Date</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          name="releaseDate"
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder="Release date"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">production</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.productionName &&
                            formError &&
                            "form-error"
                          }`}
                          name="productionName"
                          value={pageContent.productionName}
                          type="text"
                          placeholder="Filming and production"
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Quality</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.audioQuality &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="audioQuality"
                          value={pageContent.audioQuality}
                          onChange={handleInputChange}
                        >
                          <option>Select Quality</option>
                          {Mp3
                            ? Mp3.map((ct, i) => <option>{ct.name}</option>)
                            : " not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "software" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Offred by</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.offeredBy && formError && "form-error"
                          }`}
                          name="offeredBy"
                          onChange={handleInputChange}
                          value={pageContent.offeredBy}
                          type="text"
                          placeholder="Offred by"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Size</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.softwareSize &&
                            formError &&
                            "form-error"
                          }`}
                          name="softwareSize"
                          onChange={handleInputChange}
                          value={pageContent.softwareSize}
                          type="number"
                          placeholder="Size in mb"
                          required
                          min={0}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Release Date</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          name="releaseDate"
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder="Release date"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Supported Device</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.requireDevice &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="requireDevice"
                          value={pageContent.requireDevice}
                          onChange={handleInputChange}
                        >
                          <option>Select Device</option>
                          <option>Android</option>
                          <option>IOS</option>
                          <option>Both android and ios</option>
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label htmlFor="exampleInputName2" className="pr-1">
                          Category
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.softwareCategory &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="softwareCategory"
                          value={pageContent.softwareCategory}
                          onChange={handleInputChange}
                        >
                          <option>Select Category</option>
                          {SoftwareCategory
                            ? SoftwareCategory.map((ct, i) => (
                                <option>{ct.name}</option>
                              ))
                            : "Category not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "theater" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Director</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.director && formError && "form-error"
                          }`}
                          name="director"
                          onChange={handleInputChange}
                          value={pageContent.director}
                          type="text"
                          placeholder="Director Name"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Length</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.runtime && formError && "form-error"
                          }`}
                          name="runtime"
                          onChange={handleInputChange}
                          value={pageContent.runtime}
                          type="number"
                          placeholder="Stage Art Length in minutes"
                          required
                          min={0}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Release Date</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          name="releaseDate"
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder="Release date"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Type</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.theaterType &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="theaterType"
                          value={pageContent.theaterType}
                          onChange={handleInputChange}
                        >
                          <option>Select Theater Type</option>
                          {TheaterType
                            ? TheaterType.map((ct, i) => (
                                <option>{ct.name}</option>
                              ))
                            : "not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">Video quality</Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          type="select"
                          className={`input-prepend ${
                            !pageContent?.videoQuality &&
                            formError &&
                            "form-error"
                          }`}
                          name="videoQuality"
                          value={pageContent.videoQuality}
                          onChange={handleInputChange}
                        >
                          <option>Select Video Quality</option>
                          {videoquality
                            ? videoquality.map((ct, i) => (
                                <option>{ct.name}</option>
                              ))
                            : "Language not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      Language
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.language && formError && "form-error"
                      }`}
                      type="select"
                      name="language"
                      value={pageContent.language}
                      onChange={handleInputChange}
                    >
                      <option>Select Language</option>
                      {Language
                        ? Language.map((ct, i) => <option>{ct.name}</option>)
                        : "Language not found"}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      Regular Price
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.regularPrice && formError && "form-error"
                      }`}
                      name="regularPrice"
                      onChange={handleInputChange}
                      value={pageContent.regularPrice}
                      type="number"
                      placeholder="Regular Price"
                      required
                      min={0}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      Stock Quantity
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.stockQuantity && formError && "form-error"
                      }`}
                      name="stockQuantity"
                      onChange={handleInputChange}
                      value={pageContent.stockQuantity}
                      type="number"
                      placeholder="Stock Quantity"
                      required
                      min={0}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      Category
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      name="shippingCategory"
                      value={`${digitalType}`}
                      type="text"
                      disabled="true"
                      placeholder="Shipping Charges"
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      Main Category
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.mainCategory && formError && "form-error"
                      }`}
                      type="select"
                      name="mainCategory"
                      value={pageContent.mainCategory}
                      onChange={handleInputChange}
                    >
                      <option>Select main category</option>
                      {productCategories?.map(
                        (item, i) =>
                          item.type === "digital" && (
                            <option key={i} value={item._id}>
                              {item.name}
                            </option>
                          )
                      )}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              {/* UPLOAD COVER */}
              <FormGroup row>
                <Col md="3">
                  <Label htmlFor="file-multiple-input">
                    Upload {digitalType} cover
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Button
                    className={`input-prepend ${
                      !bookCoverUrl && formError && "form-error"
                    }`}
                    onClick={handleCoverClick}
                  >
                    Upload a file
                  </Button>
                  <input
                    type="file"
                    onChange={uploadBookCover}
                    ref={bookCoverUrlRef}
                    name="files"
                    style={{ display: "none" }}
                  />
                  <p>{bookCoverUrl?.split("/")?.slice(-1)}</p>
                </Col>
              </FormGroup>

              {/* UPLOAD TRAILER */}
              {["movie", "series", "theater"].includes(digitalType) && (
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="file-multiple-input">
                      Upload {digitalType} trailer
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Button
                      className={`input-prepend ${
                        !trailerUrl && formError && "form-error"
                      }`}
                      onClick={handleTrailerClick}
                    >
                      Upload a file
                    </Button>
                    <input
                      type="file"
                      onChange={uploadTrailer}
                      ref={trailerUrlRef}
                      name="files"
                      style={{ display: "none" }}
                    />
                    <p>{trailerUrl?.split("/")?.slice(-1)}</p>
                  </Col>
                </FormGroup>
              )}

              <FormGroup row>
                <Col md="3">
                  <Label htmlFor="file-multiple-input">
                    Upload new {digitalType}
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Button
                    className={`input-prepend ${
                      !bookUrl && formError && "form-error"
                    }`}
                    onClick={handleClick}
                  >
                    Upload a file
                  </Button>
                  <input
                    type="file"
                    onChange={uploadBook}
                    ref={bookUrlRef}
                    style={{ display: "none" }}
                  />
                  <p>{bookUrl?.split("/")?.slice(-1)}</p>
                </Col>
              </FormGroup>

              <Button
                hidden={loading}
                color="primary"
                type="submit"
                className="btn btn-sm"
                onClick={save}
                block
              >
                Save
              </Button>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <i
                  hidden={!loading}
                  className="fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green", float: "right" }}
                ></i>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* end of prising table layout */}
    </div>
  );
};

export default UpdateDigitalProducts;
