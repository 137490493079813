import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  announcementSellers,
  createAnnouncement,
} from "../../../redux/reducers/admin";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  InputGroup,
  Dropdown,
  Form,
  FormGroup,
  InputGroupAddon,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./announcement.css";

const Announcement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { sellers, loading } = useSelector((state) => state.admin);
  const [pageContent, setPageContent] = useState({
    sellerList: [],
  });
  useEffect(() => {
    dispatch(announcementSellers());
  }, []);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const announcementTypes = [
    {
      name: "Everyone",
      checked: true,
    },
    {
      name: "Specific Seller",
      checked: false,
    },
  ];
  const handleSellerArray = (id) => {
    const sellerList = pageContent.sellerList;
    sellerList.push(id);
    setPageContent({
      ...pageContent,
      sellerList,
    });
  };

  const announce = () => {
    const { announcementType, sellerList, description } = pageContent;
    if (!description) {
      toast.error("notification is empty");
    } else {
      let to = sellerList;
      if (announcementType == "Everyone") {
        to = sellers.map((item) => item._id);
      }
      const data = {
        message: description,
        to,
        date: new Date(),
      };
      dispatch(createAnnouncement(data)).then(() =>
        history.push("/admin/announcementlist")
      );
    }
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col sm="2" md="2"></Col>
        <Col xs="12" sm="8" md="8" className="mt-5">
          <Card>
            <CardHeader>
              <b>Make Announcement</b>
            </CardHeader>
            <CardBody className="card-body">
              <Form onSubmit={announce}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" sm="12" md="12" className="p-0">
                        <Label>
                          <b>Type Of Announcement</b>
                          <span className="star">*</span>
                        </Label>
                      </Col>
                      {announcementTypes.map((item, index) => (
                        <Col xs="12" sm="12" md="12">
                          <Col xs="1" sm="1" md="1">
                            <Input
                              type="radio"
                              id={item.name}
                              name="announcementType"
                              checked={
                                pageContent.announcementType === item.name
                              }
                              value={item.name}
                              onClick={handleInputChange}
                              className="radio-alignA"
                            />
                          </Col>
                          <Col xs="11" sm="11" md="11">
                            <Label> {item.name}</Label>
                          </Col>
                        </Col>
                      ))}
                      {sellers.length > 0
                        ? sellers.map((seller, index) => (
                            <Col
                              xs="12"
                              sm="12"
                              md="12"
                              hidden={
                                pageContent.announcementType !==
                                "Specific Seller"
                              }
                            >
                              <Row>
                                <Col xs="12" sm="12" md="12">
                                  <Input
                                    type="checkbox"
                                    className="radio-cursor"
                                    id={seller.firstname}
                                    value={seller.id}
                                    onChange={(e) =>
                                      handleSellerArray(seller._id)
                                    }
                                  />
                                  <div className="product-name">
                                    {seller.firstname} {seller.lastname}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          ))
                        : ""}
                    </Row>
                  </CardBody>
                </Card>
                <FormGroup>
                  <Label> Announcement Description</Label>
                  <div className="controls">
                    <InputGroup className="input-prepend">
                      <InputGroupAddon addonType="prepend"></InputGroupAddon>
                      <Input
                        placeholder="Description"
                        name="description"
                        value={pageContent.description}
                        size="16"
                        type="textarea"
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col xs="6" sm="4" md="4"></Col>
                  <Col xs="6" sm="4" md="4">
                    <Button
                      color="warning"
                      className="btn btn-sm"
                      onClick={(e) => announce()}
                      block
                      // disabled={state.announcementFlag}
                    >
                      <b>Announce</b>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col sm="2" md="2"></Col>
      </Row>
    </div>
  );
};

export default Announcement;
