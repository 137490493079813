import React, { useEffect, useState, useRef } from "react";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addFilter,
  createLoan,
  getLoans,
  getSubscription,
} from "../../redux/reducers/seller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkyLight from "react-skylight";
import moment from "moment";
import "./loan.css";
import FreemiumPage from "../Seller/CustomPages/FreemiumPage";

var divStyle = {
  marginLeft: "10%",
};

const Loan = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const microLoanTranslation =
    translationState?.[0]?.translations?.microLoan?.[userLanguage];

  const modal = useRef(null);
  const { allLoans, filters, loading } = useSelector((state) => state.seller);
  const { value: PageNo } = filters.find((item) => item.field === "pageNo");
  const [pageContent, setPageContent] = useState({
    amount: 100,
    installments: 3,
    purpose: "",
  });
  const { singleSubscription, loading: Membershiploading } = useSelector(
    (state) => state.seller
  );
  useEffect(() => {
    dispatch(getSubscription());
  }, []);
  useEffect(() => {
    dispatch(getLoans());
  }, []);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };

  const onCreateLoan = () => {
    modal.current.show();
  };

  const search = () => {};

  const searchElement = (evt) => {};

  const onSubmitLoan = async () => {
    const { amount, installments, purpose } = pageContent;
    if (amount < 0 && purpose == "") {
      toast.show(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Please input valid data"
        }`
      );
    } else {
      const data = {
        amount,
        installments,
        purpose,
      };
      dispatch(createLoan(data)).then(() => {
        dispatch(getLoans());
        setPageContent({
          amount: 100,
          installments: 3,
          purpose: "",
        });
        modal.current.hide();
      });
    }
  };
  const paginate = (pageNo) => {
    if (pageNo > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(getLoans());
    }
  };
  const fremiumText1 = `${
    reportingTranslation?.infoLineOne
      ? reportingTranslation?.infoLineOne
      : "Only SOLO and Partner users can use Micro Loan Feature. Please upgrade your membership to access this and many more exciting features."
  }`;
  var myBigGreenDialog = {
    width: "40%",
    minHeight: "20%",
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      {Membershiploading ? (
        <div className="mt-5" style={{ textAlign: "center" }}>
          <i
            className="fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : singleSubscription?.membershipType !== "Fremium" ? (
        <>
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={modal}
            title={
              <p style={{ display: "flex", justifyContent: "center" }}>
                <b>
                  <h4>
                    {microLoanTranslation?.create
                      ? microLoanTranslation?.create
                      : "Create New Loan"}
                  </h4>
                </b>
              </p>
            }
          >
            <div>
              <FormGroup>
                <Row>
                  <Label>
                    {microLoanTranslation?.loanAmount
                      ? microLoanTranslation?.loanAmount
                      : "Loan Amount"}
                  </Label>
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      placeholder={
                        microLoanTranslation?.loanAmount
                          ? microLoanTranslation?.loanAmount
                          : "Loan Amount"
                      }
                      name="amount"
                      value={pageContent.amount}
                      onChange={handleInputChange}
                      type="number"
                    />
                  </InputGroup>
                </Row>

                <Row>
                  <Label>
                    {microLoanTranslation?.paymentInstallment
                      ? microLoanTranslation?.paymentInstallment
                      : "Payment Installments"}
                  </Label>
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      placeholder={
                        microLoanTranslation?.paymentInstallment
                          ? microLoanTranslation?.paymentInstallment
                          : "Payment Installments"
                      }
                      name="installments"
                      value={pageContent.installments}
                      onChange={handleInputChange}
                      type="select"
                    >
                      <option value={3}>
                        3{" "}
                        {microLoanTranslation?.month
                          ? microLoanTranslation?.month
                          : "Month"}
                      </option>
                      <option value={6}>
                        6{" "}
                        {microLoanTranslation?.month
                          ? microLoanTranslation?.month
                          : "Month"}
                      </option>
                      <option value={12}>
                        12{" "}
                        {microLoanTranslation?.month
                          ? microLoanTranslation?.month
                          : "Month"}
                      </option>
                      <option value={18}>
                        18{" "}
                        {microLoanTranslation?.month
                          ? microLoanTranslation?.month
                          : "Month"}
                      </option>
                      <option value={36}>
                        36{" "}
                        {microLoanTranslation?.month
                          ? microLoanTranslation?.month
                          : "Month"}
                      </option>
                    </Input>
                  </InputGroup>
                </Row>

                <Row>
                  <Label>
                    {microLoanTranslation?.purpose
                      ? microLoanTranslation?.purpose
                      : "Purpose"}
                  </Label>
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      placeholder={
                        microLoanTranslation?.purpose
                          ? microLoanTranslation?.purpose
                          : "Purpose"
                      }
                      name="purpose"
                      value={pageContent.purpose}
                      onChange={handleInputChange}
                      type="textarea"
                    />
                  </InputGroup>
                </Row>

                <Row>
                  <Label>
                    {microLoanTranslation?.calculate
                      ? microLoanTranslation?.calculate
                      : "Calculated Monthly Installment"}
                  </Label>
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      placeholder={
                        microLoanTranslation?.calculate
                          ? microLoanTranslation?.calculate
                          : "Calculated Monthly Installment"
                      }
                      value={(
                        pageContent.amount / pageContent.installments
                      ).toFixed(2)}
                      type="number"
                      disabled={true}
                    />
                  </InputGroup>
                </Row>
              </FormGroup>
              <Row>
                <Button
                  onClick={onSubmitLoan}
                  color="warning"
                  style={divStyle}
                  btn="sm"
                >
                  <b>
                    {microLoanTranslation?.createLoan
                      ? microLoanTranslation?.createLoan
                      : "Create Loan"}
                  </b>
                </Button>
              </Row>
            </div>
          </SkyLight>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="12" sm="9">
                      <b>
                        {microLoanTranslation?.loan
                          ? microLoanTranslation?.loan
                          : "Loans"}
                      </b>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="card-body">
                  <Row>
                    <Col xs="12" sm="4">
                      <InputGroup>
                        <Button
                          color="warning"
                          style={{ ...divStyle, marginLeft: "-10px" }}
                          btn="sm"
                          onClick={onCreateLoan}
                        >
                          <b>
                            {microLoanTranslation?.createLoan
                              ? microLoanTranslation?.createLoan
                              : "Create Loan"}
                          </b>
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                  <br />
                  <Table hover bordered striped responsive size="sm">
                    <thead align="center">
                      <tr className="header-color">
                        <th>
                          {microLoanTranslation?.id
                            ? microLoanTranslation?.id
                            : "Loan Application ID"}{" "}
                        </th>
                        <th>
                          {microLoanTranslation?.amount
                            ? microLoanTranslation?.amount
                            : "Amount"}{" "}
                        </th>
                        <th>
                          {microLoanTranslation?.number
                            ? microLoanTranslation?.number
                            : "No. of Installments"}{" "}
                        </th>
                        <th>
                          {microLoanTranslation?.status
                            ? microLoanTranslation?.status
                            : "Status"}{" "}
                        </th>
                        <th>
                          {microLoanTranslation?.nextDate
                            ? microLoanTranslation?.nextDate
                            : " Next installment date"}
                        </th>
                        <th>
                          {microLoanTranslation?.dueDate
                            ? microLoanTranslation?.dueDate
                            : "Due Date"}{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <td colSpan={6} className="py-5">
                          <div style={{ textAlign: "center" }}>
                            <i
                              className="fas fa-spinner fa-pulse fa-2x"
                              style={{ color: "green" }}
                            ></i>
                          </div>
                        </td>
                      ) : allLoans.length > 0 && !loading ? (
                        allLoans.map((item, ind) => (
                          <tr align="center" key={ind}>
                            <td> {item._id} </td>
                            <td> {item.amount} </td>
                            <td> {item.installments.length} </td>
                            <td> {item.status} </td>
                            <td>
                              {moment(
                                item?.installments.find((i) => !i.isPaid)
                                  .dueDate
                              ).format("MMM-D-YYYY")}
                            </td>
                            <td>{moment(item.dueDate).format("MMM-D-YYYY")}</td>
                          </tr>
                        ))
                      ) : (
                        <td colSpan={6} className="py-5">
                          <div style={{ textAlign: "center" }}>
                            <p style={{ color: "red" }}>
                              {walletTranslation?.notFound
                                ? walletTranslation?.notFound
                                : "Records Not Found"}
                            </p>
                          </div>
                        </td>
                      )}
                    </tbody>
                  </Table>
                  <nav>
                    <Pagination>
                      <PaginationItem style={{ marginRight: "3px" }}>
                        <PaginationLink
                          onClick={(e) => paginate(PageNo - 1)}
                          style={{ backgroundColor: "rgb(228, 231, 234)" }}
                        >
                          {reportingTranslation?.previousButton
                            ? reportingTranslation?.previousButton
                            : "Prev"}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          onClick={(e) => paginate(PageNo + 1)}
                          className="mx-2"
                          style={{ backgroundColor: "rgb(228, 231, 234)" }}
                        >
                          {reportingTranslation?.nextButton
                            ? reportingTranslation?.nextButton
                            : "Next"}
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <FreemiumPage text={fremiumText1} />
      )}
    </div>
  );
};
export default Loan;
