import React, { useEffect, useState } from "react";
import "./TranslationField.css";
import { useDispatch, useSelector } from "react-redux";
import { TranslationContent } from "../../../redux/reducers/admin";
import { getAllWebContent } from "../../../redux/reducers/user";
import { toast, ToastContainer } from "react-toastify";

const HomeField = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  const { allWebContent } = useSelector((state) => state?.user);
  const homeData = allWebContent?.[0]?.home;

  const [diable, setDiable] = useState(true);
  const [formData, setFormData] = useState({
    page: "home",
    isChange: false,
    headingOne: homeData?.headingOne || " ",
    paraOne: homeData?.paraOne || " ",
    // buttonOne:homeData?.buttonOne || " " ,
    // searchPlaceholder:homeData?.searchPlaceholder || " " ,
    headingTwo: homeData?.headingTwo || " ",
    paraOneline1: homeData?.paraOneline1 || " ",
    paraOneline2: homeData?.paraOneline2 || " ",
    categoryH1: homeData?.categoryH1 || " ",
    categoryP1: homeData?.categoryP1 || " ",
    categoryH2: homeData?.categoryH2 || " ",
    categoryP2: homeData?.categoryP2 || " ",
    categoryH3: homeData?.categoryH3 || " ",
    categoryP3: homeData?.categoryP3 || " ",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiable(false);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      "🚀 ~ file: homeContent.js:47 ~ handleSubmit ~ formData:",
      formData
    );
    dispatch(TranslationContent(formData));
    setDiable(true);
  };

  useEffect(() => {
    setFormData({ ...formData, ...homeData, isChange: false });
  }, [homeData]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <center>
        <h1 className="mt-3">Home Page</h1>
      </center>
      <form onSubmit={handleSubmit} className="mx-5">
        <div className="form-group">
          <label>Heading One:</label>
          <input
            type="text"
            name="headingOne"
            value={formData.headingOne}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Paragraph One:</label>
          <input
            type="text"
            name="paraOne"
            value={formData.paraOne}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* <div className="form-group">
          <label>First Button:</label>
          <input
            type="text"
            name="buttonOne"
            value={formData.buttonOne}
            onChange={handleInputChange}
            />
        </div> */}

        {/* <div className="form-group">
          <label>Search PlaceHolder:</label>
          <input
            type="text"
            name="searchPlaceholder"
            value={formData.searchPlaceholder}
            onChange={handleInputChange}
            />
        </div> */}

        <div className="form-group">
          <label>Heading Two:</label>
          <input
            type="text"
            name="headingTwo"
            value={formData.headingTwo}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Second Paragraph Line One:</label>
          <input
            type="text"
            name="paraOneline1"
            value={formData.paraOneline1}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Second Paragraph Line Two</label>
          <input
            type="text"
            name="paraOneline2"
            value={formData.paraOneline2}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>categoryH1</label>
          <input
            type="text"
            name="categoryH1"
            value={formData.categoryH1}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>categoryP1</label>
          <input
            type="text"
            name="categoryP1"
            value={formData.categoryP1}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>categoryH2</label>
          <input
            type="text"
            name="categoryH2"
            value={formData.categoryH2}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>categoryP2</label>
          <input
            type="text"
            name="categoryP2"
            value={formData.categoryP2}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>categoryH3</label>
          <input
            type="text"
            name="categoryH3"
            value={formData.categoryH3}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>categoryP3</label>
          <input
            type="text"
            name="categoryP3"
            value={formData.categoryP3}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default HomeField;
