import axios from 'axios';
import Env from '../../../environment/environment';


const saveComplaint = async function(data) {
    
    var returnResp = "";
    await axios.post(Env.url + '/fraud/create',data,
    {headers: {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('auth_token') 
  }}).then(function (response) {
      returnResp = response.data;
      
    //return response
    }).catch(err=>{
      if(err.response != undefined){
      if(err.response.status == 401 || err.response.status == 400 || err.response.status == 500){
          window.location = "#/login";
      }
    }
  });
  return returnResp;
};


export { saveComplaint };