import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  InputGroup,
  Input,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardHeader,
} from "reactstrap";
import SkyLight from "react-skylight";
import { ToastContainer, toast } from "react-toastify";
import {
  addFilter,
  deleteProduct,
  getProducts,
  updateProduct,
} from "../../redux/reducers/seller";
import { nextPagesItems } from "../../helpers/custom";

const DigitalProducts = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedId, setSelectedId] = useState(0);

  const deleteProductModal = useRef(null);
  const approveProductModal = useRef(null);
  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const { products, count, filters, loading } = useSelector(
    (state) => state.seller
  );
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);

  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: "digital",
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(getProducts());
  }, []);

  const deleteModal = (id) => {
    setSelectedId(id);
    deleteProductModal.current.show();
  };
  const deleteItem = () => {
    dispatch(deleteProduct(selectedId)).then(() => {
      deleteProductModal.current.hide();
      dispatch(getProducts());
    });
  };
  const editProduct = (item) => {
    if (userType === "admin") {
      history.push(
        `/admin/digitalproduct/${item.digitalType}/update/${item._id}`
      );
    } else {
      // no impact on edit product for admin
      history.push(`/admin/products/editproduct/${id}`);
    }
  };

  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getProducts());
  };
  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(getProducts());
    }
  };
  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getProducts());
  };

  const showProduct = (item) => {
    history.push(`/admin/digitalproduct/${item.digitalType}/view/${item._id}`);
  };

  const approveModal = (id) => {
    setSelectedId(id);
    approveProductModal.current.show();
  };
  const approveProduct = () => {
    const data = {
      id: selectedId,
      status: "Active",
    };
    dispatch(updateProduct(data)).then(() => {
      approveProductModal.current.hide();
      dispatch(getProducts());
    });
  };
  const rejectProduct = () => {
    const data = {
      id: selectedId,
      status: "Rejected",
    };
    dispatch(updateProduct(data)).then(() => {
      approveProductModal.current.hide();
      dispatch(getProducts());
    });
  };

  const myBigGreenDialog = {
    width: "40%",
    minHeight: "20%",
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={approveProductModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>Are you sure you want to Approve/Reject this Product?</h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {products?.find((item) => selectedId === item._id)?.status !==
            "Active" && (
            <>
              <Button
                type="submit"
                onClick={approveProduct}
                size="md"
                color="warning"
              >
                Approved
              </Button>
              <Col xs="12" sm="1"></Col>
            </>
          )}
          {products?.find((item) => selectedId === item._id)?.status !==
            "Rejected" && (
            <Button
              type="submit"
              size="md"
              onClick={rejectProduct}
              color="danger"
            >
              Reject
            </Button>
          )}
        </Row>
      </SkyLight>
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={deleteProductModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>Are you sure you want to delete this Product ?</h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            Yes
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            onClick={() => deleteProductModal.current.hide()}
            color="danger"
          >
            No
          </Button>
        </Row>
      </SkyLight>
      {/* layout for prising table */}
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <strong>Digital Product List</strong>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="12" sm="4">
                  <InputGroup>
                    <Input
                      type="text"
                      id="input1-group2"
                      onChange={searchElement}
                      className="search-color"
                      name="input1-group2"
                      placeholder="search ..."
                    />
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color">
                    <th> Name</th>
                    <th>Regular Price</th>
                    <th>Sale Price</th>
                    <th> Action</th>
                    <th> Approve</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={5}>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : products.length > 0 && !loading ? (
                    products.map((item, index) => (
                      <tr>
                        <td>{item.name}</td>
                        <td align="center">${item.regularPrice}</td>
                        <td align="center">${item.salesPrice}</td>
                        <td align="center">
                          <i
                            className="fa fa-eye fa-lg m-1"
                            onClick={(e) => showProduct(item)}
                          ></i>
                          <i
                            className="fa fa-edit fa-lg  m-1"
                            onClick={(e) => editProduct(item)}
                          ></i>
                          <i
                            className="fa fa-trash fa-lg  m-1"
                            onClick={(e) => deleteModal(item._id)}
                          ></i>
                        </td>
                        <td align="center">
                          <Button
                            // disabled={item.status !== "Pending"}
                            onClick={() => {
                              approveModal(item._id);
                            }}
                            style={{
                              backgroundColor:
                                item.status === "Pending"
                                  ? "#FFCC00"
                                  : item.status === "Active"
                                  ? "green"
                                  : "red",
                              color: "white",
                              // width: 80,
                            }}
                          >
                            {item.status === "Pending"
                              ? item.status
                              : item.status === "Active"
                              ? "Approved"
                              : item.status}
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={5} className="py-5 text-center">
                      <p style={{ color: "red" }}>Records Not Found</p>
                    </td>
                  )}
                </tbody>
              </Table>
              <nav>
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo - 1)}
                      disabled={pageNo === 1 ? true : false}
                      style={{
                        backgroundColor:
                          (pageNo === 1 ? true : false) && "#e4e7ea",
                      }}
                    >
                      Prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: !clickableNext ? "#e4e7ea" : null,
                      }}
                      className="mx-2"
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        Items per Page
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, pageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DigitalProducts;
