import React from 'react'

const getStarRating = (product) => {
    let price = product ? product?.dynamicPrice?.currentPrice : null
    if (price) {
        if (price < 20) {
            return 1
        } else if (price < 100) {
            return 2
        } else if (price < 500) {
            return 3
        } else if (price < 1500) {
            return 4
        } else {
            return 5
        }
    } else {
        return null;
    }
}

export default function RatingWidget({ product }) {
    const rating = getStarRating(product)
    const extendedRating = rating*2

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8, color: "#9090b4" }}><b>$</b></span>
            <div
                style={{display:'flex', justifyContent:'space-around', minWidth:120}}
            >
                {Array(extendedRating).fill().map((star, i) => (
                    <svg width="10" height="18" key={i}>
                        <rect width="20" height="50" style={{ "fill": "#00ff6f", "strokeWidth": "3" }} />
                    </svg>
                ))}
                {Array(10 - extendedRating).fill().map((star, i) => (
                    <svg width="10" height="18" key={i}>
                        <rect width="20" height="50" style={{ "fill": "#cecedb", "strokeWidth": "3" }} />
                    </svg>
                ))}
            </div>
            <span className="rating-value" >{extendedRating}/10</span>
        </div>
    );

}


export function MiniRatingWidget({ product }) {
    const rating = getStarRating(product)
    const extendedRating = rating*2

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8, color: "#9090b4", fontSize:12 }}><b>$</b></span>
            <div
                style={{display:'flex', justifyContent:'space-around', minWidth:80}}
            >
                {Array(extendedRating).fill().map((star, i) => (
                    <svg width="6" height="14" key={i}>
                        <rect width="20" height="50" style={{ "fill": "#00ff6f", "strokeWidth": "3" }} />
                    </svg>
                ))}
                {Array(10 - extendedRating).fill().map((star, i) => (
                    <svg width="6" height="14" key={i}>
                        <rect width="20" height="50" style={{ "fill": "#cecedb", "strokeWidth": "3" }} />
                    </svg>
                ))}
            </div>
            <span className="rating-value" style={{fontSize:12}} >{extendedRating}/10</span>
        </div>
    );

}