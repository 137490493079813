import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SetState,
  getAllSellerSupports,
  getAnnouncements,
  getSingleAnnouncement,
  getsingleSellerSupport as getsingleSellerSupportAdmin,
} from "./redux/reducers/admin";
import { GetCustomerChat } from "./redux/reducers/seller";
import {
  GetChat,
  getTranslation,
  getsellerSupport,
  getsingleSellerSupport as getsingleSellerSupportUser,
} from "./redux/reducers/user";
import AuthRoute from "./routes/AuthRoute";
import AppRoute from "./routes/AppRoute";
import { socket } from "./config/axios";
import "./App.css";
import "@coreui/icons/css/coreui-icons.min.css";
import "flag-icon-css/css/flag-icon.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
import "simple-line-icons/css/simple-line-icons.css";
import "./scss/style.css";
import "./translations/i18n";

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { singleSubscription } = useSelector((state) => state.seller);
  const { translationState } = useSelector((state) => state.user);

  useEffect(() => {
    !translationState?.length && dispatch(getTranslation());
    socket.on(`notification${user?._id}`, async () => {
      dispatch(getAnnouncements());
    });
    socket.on(`single-notification${user?._id}`, async (data) => {
      dispatch(getAnnouncements());
      dispatch(getSingleAnnouncement(data._id));
    });
    socket.on(`chat_notification-${user?._id}`, async (data) => {
      const { _id: id, type } = data;
      if (type === "customer") {
        dispatch(GetChat({ id }));
        dispatch(GetCustomerChat());
      } else {
        dispatch(getsingleSellerSupportAdmin(id));
        dispatch(getsingleSellerSupportUser(id));
        dispatch(getsellerSupport());
        dispatch(getAllSellerSupports());
      }
    });
  }, []);

  useEffect(() => {
    dispatch(SetState({ field: "announcements", value: [] }));
    if (user && user?._id) {
      if (user?.userType !== "admin" && singleSubscription?.membershipType) {
        dispatch(getAnnouncements());
      }
    }
  }, [user]);

  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute path="/auth" />
        <AppRoute path="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
