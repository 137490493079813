import React, { useEffect, useState } from "react";
import "./Orders.css";
import "./TableStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  getOrders,
  getSingleOrder,
  cancelOrder,
  addFilter,
  updateOrder,
} from "../../redux/reducers/seller";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const Orders = () => {
  const search = new URLSearchParams(location.search).get("type");
  const { orders, loading: sellerLoading } = useSelector(
    (state) => state.seller
  );
  const dispatch = useDispatch();
  const [type, setType] = useState("main");
  const history = useHistory();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const orderTranslation =
    translationState?.[0]?.translations?.order?.[userLanguage];
  const dashboardTranslation =
    translationState?.[0]?.translations?.dashboard?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const residencySwapTranslation =
    translationState?.[0]?.translations?.residencySwap?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  useEffect(() => {
    dispatch(
      addFilter({
        field: "productType",
        value: "",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      addFilter({
        field: "perPage",
        value: 100,
      })
    );
    dispatch(
      addFilter({
        field: "navigate",
        value: search,
      })
    );
    dispatch(getOrders());
  }, [dispatch]);

  const HandleView = (id) => {
    history.push(`/customer-account/vieworder/${id}`);
  };

  const Main = () => {
    const HandleDelete = async (id, status) => {
      if (status == "Acknowledged") {
        alert("You cannot cancel this order because order is in process");
      } else {
        await dispatch(cancelOrder(id));
        await dispatch(getOrders());
      }
    };
    const updateStatus = (OId, orderStatus) => {
      const data = {
        OId,
        orderStatus,
      };
      dispatch(updateOrder(data)).then(() => {
        toast.success(
          `${
            toastTranslation?.success
              ? toastTranslation?.success
              : "Order Status Updated Successfully"
          }`
        );
        dispatch(getOrders());
      });
    };
    return (
      <>
        <div className="row d-block">
          <div className="main-order-table overflow-auto user-table-order-height">
            <table className="table">
              <thead className="text-left bg-color">
                <tr>
                  <th className="pl-3">
                    {dashboardTranslation?.order
                      ? dashboardTranslation?.order
                      : "Order"}
                  </th>
                  <th className="date-width pl-4">
                    {dashboardTranslation?.date
                      ? dashboardTranslation?.date
                      : "Date"}
                  </th>
                  <th className="pl-3">
                    {dashboardTranslation?.status
                      ? dashboardTranslation?.status
                      : "Status"}
                  </th>
                  <th className="shipping-width">
                    {dashboardTranslation?.shippingFee
                      ? dashboardTranslation?.shippingFee
                      : "Shipping Fee"}
                  </th>
                  <th>
                    {dashboardTranslation?.total
                      ? dashboardTranslation?.total
                      : "Total"}
                  </th>
                  <th>
                    {dashboardTranslation?.dispute
                      ? dashboardTranslation?.dispute
                      : "In Dispute"}
                  </th>
                  <th className="textCenter">
                    {orderTranslation?.action
                      ? orderTranslation?.action
                      : "Actions"}{" "}
                  </th>
                </tr>
              </thead>
              {sellerLoading ? (
                <td colSpan={6} className="py-5">
                  <div className="text-center">
                    <i
                      className="mx-auto fas fa-spinner fa-pulse fa-2x"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                </td>
              ) : orders?.length > 0 ? (
                orders?.map((order, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>{order?.products[0]?.product?.name}</td>
                        <td>
                          {moment(order?.orderDate).format("MMM Do YYYY")}
                        </td>
                        <td>{order?.orderStatus}</td>
                        <td>{order?.shippingFee}</td>
                        <td>${order?.total}</td>
                        <td> {order?.disputeId ? "True" : "False"} </td>
                        <td
                          className="d-flex justify-content-center"
                          style={{
                            border: "none",
                          }}
                        >
                          <div
                            onClick={() => HandleView(order._id)}
                            className="order-icons"
                          >
                            <FaEye />
                          </div>
                          {order.orderStatus == "Pending" && (
                            <div
                              onClick={() =>
                                updateStatus(order._id, "Cancelled")
                              }
                              className="order-icons"
                            >
                              <IoClose />
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <td colSpan={6} className="py-5">
                  <div style={{ textAlign: "center" }}>
                    <p style={{ color: "red" }}>
                      {walletTranslation?.notFound
                        ? walletTranslation?.notFound
                        : "Records Not Found"}
                    </p>
                  </div>
                </td>
              )}
            </table>
          </div>
        </div>
      </>
    );
  };

  const Pay = () => {
    return (
      <>
        <p>Pay</p>
      </>
    );
  };
  ///////////////////////////////////

  const Waranty = () => {
    return (
      <>
        <div className="" style={{ marginTop: "90px" }}>
          <div>
            <b>Store Name:</b>{" "}
            <span className="link-style">Authentic Corner</span>
          </div>
          <div className="my-3">
            <div className="p-1">
              <table
                style={{ border: "0.5px solid grey", borderRadius: "10px" }}
                className="table"
              >
                <thead className="">
                  <tr>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Vendor</th>
                    <th>Qty</th>
                    <th>Warranty Details</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr>
                    <td>
                      <span className="link-style">Hoodie with Pocket</span>
                    </td>
                    <td>€45,00 €35,00</td>
                    <td>
                      <span className="link-style">Authentic Cornert</span>
                    </td>
                    <td>
                      <select>
                        <option>1</option>
                        <option>2</option>
                      </select>
                    </td>
                    <td>Product has no warranty</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {type == "main" ? (
        <Main />
      ) : type == "pay" ? (
        <Pay />
      ) : type == "warranty" ? (
        <Waranty />
      ) : (
        <p></p>
      )}
    </>
  );
};

export default Orders;
