import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  SetState,
  addFilter,
  getAdvertisments,
  deleteAdvertisement,
  updateAdvertisement,
} from "../../redux/reducers/seller";
import moment from "moment";
import {
  InputGroup,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import "./advertise.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkyLight, { SkyLightStateless } from "react-skylight";
import { AppSwitch } from "@coreui/react";
import { nextPagesItems } from "../../helpers/custom";

const divStyle = { marginLeft: "10%" };

const Advertise = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const advertisementsTranslation =
    translationState?.[0]?.translations?.advertisements?.[userLanguage];
  const sellerHomeTranslation =
    translationState?.[0]?.translations?.sellerHome?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const sellerTranslation =
    translationState?.[0]?.translations?.seller?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];

  const deleteProductModal = useRef(null);
  const [selectedId, setSelectedId] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const { advertisments, count, filters, loading } = useSelector(
    (state) => state.seller
  );
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    dispatch(getAdvertisments());
    dispatch(SetState({ field: "addCreated", value: null }));
  }, []);

  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getAdvertisments());
  };

  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getAdvertisments());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getAdvertisments());
  };

  const deleteModal = (id) => {
    setSelectedId(id);
    deleteProductModal.current.show();
  };
  const deleteItem = () => {
    dispatch(deleteAdvertisement(selectedId)).then(() => {
      dispatch(getAdvertisments());
    });
    deleteProductModal.current.hide();
  };

  const handleSwitchInput = (e, item) => {
    const value = e.target.checked;
    let status;
    value === true ? (status = "Active") : (status = "Inactive");
    const data = {
      addId: item._id,
      status,
    };
    dispatch(updateAdvertisement(data)).then(() => {
      dispatch(getAdvertisments());
    });
  };

  var myBigGreenDialog = {
    width: "40%",
    // height: '39%',
    minHeight: "20%",
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        hideOnOverlayClicked
        dialogStyles={myBigGreenDialog}
        ref={deleteProductModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>Are you sure you want to delete this Advertisement ?</h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            Yes
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            size="md"
            onClick={() => deleteProductModal.current.hide()}
            color="danger"
          >
            No
          </Button>
        </Row>
      </SkyLight>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row
                style={{ marginTop: "5px", justifyContent: "space-between" }}
              >
                <Col xs="12" sm="8" className="p-0">
                  <b>
                    {advertisementsTranslation?.advertisement
                      ? advertisementsTranslation?.advertisement
                      : "Advertisements & Promotions"}
                  </b>
                </Col>
                <Col xs="12" sm="2" className="p-0">
                  {user.userType == "seller" && (
                    <div className="d-flex justify-content-end w-100 create-advertisement-div">
                      <Button
                        onClick={() =>
                          history.push("/seller/advertisement/add")
                        }
                        size="sm"
                        style={{ ...divStyle, marginLeft: 0 }}
                        color="warning"
                      >
                        <b>
                          {advertisementsTranslation?.createA
                            ? advertisementsTranslation?.createA
                            : "Create Advertisement"}
                        </b>
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              <Row>
                <Col xs="12" sm="4">
                  <InputGroup>
                    <Input
                      type="text"
                      id="input1-group2"
                      onChange={searchElement}
                      className="search-color"
                      name="input1-group2"
                      placeholder={
                        productTranslation?.search
                          ? productTranslation?.search
                          : "Search"
                      }
                    />
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs="12" sm="12" md="12">
                  <Table hover bordered striped responsive size="sm">
                    <thead align="center">
                      <tr className="header-color">
                        <th>
                          {advertisementsTranslation?.action
                            ? advertisementsTranslation?.action
                            : "Action"}
                        </th>
                        <th>
                          {advertisementsTranslation?.campaign
                            ? advertisementsTranslation?.campaign
                            : "Campaign ID"}{" "}
                        </th>
                        <th>
                          {advertisementsTranslation?.name
                            ? advertisementsTranslation?.name
                            : "Advertisement Name"}{" "}
                        </th>
                        <th>
                          {advertisementsTranslation?.seller
                            ? advertisementsTranslation?.seller
                            : "Seller ID"}{" "}
                        </th>
                        <th>
                          {advertisementsTranslation?.status
                            ? advertisementsTranslation?.status
                            : "Status"}{" "}
                        </th>
                        <th>
                          {advertisementsTranslation?.posted
                            ? advertisementsTranslation?.posted
                            : "Posted Date"}{" "}
                        </th>
                        <th>
                          {advertisementsTranslation?.endDate
                            ? advertisementsTranslation?.endDate
                            : "End Date"}{" "}
                        </th>
                        <th>
                          {advertisementsTranslation?.action
                            ? advertisementsTranslation?.action
                            : "Action"}{" "}
                        </th>{" "}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <i
                              className="fas fa-spinner fa-pulse fa-2x"
                              style={{ color: "green", margin: "80px" }}
                            ></i>
                          </div>
                        </td>
                      ) : advertisments.length > 0 && !loading ? (
                        advertisments.map((item, index) => (
                          <tr>
                            <td>
                              <AppSwitch
                                className={"m-1"}
                                variant={"pill"}
                                color={"primary"}
                                name="isPublished"
                                checked={
                                  item.status === "Active" ? true : false
                                }
                                onChange={(e) => handleSwitchInput(e, item)}
                              />
                            </td>
                            <td align="center"> {item._id} </td>
                            <td align="center"> {item.companyName} </td>
                            <td align="center"> {item.sellerId} </td>
                            <td align="center">{item.status}</td>

                            <td align="center">
                              {moment(item.startDate).format(
                                "MMM D YYYY, h:mm A"
                              )}
                            </td>
                            <td align="center">
                              {moment(item.endDate).format(
                                "MMM D YYYY, h:mm A"
                              )}
                            </td>

                            <td align="center">
                              <i
                                className="fa fa-trash fa-lg mt-4"
                                onClick={(e) => deleteModal(item._id)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td colSpan={8} className="py-5 text-center">
                          <p style={{ color: "red" }}>
                            {walletTranslation?.notFound
                              ? walletTranslation?.notFound
                              : "Records Not Found"}
                          </p>
                        </td>
                      )}
                    </tbody>
                  </Table>
                  <nav>
                    <Pagination className="mt-3">
                      <PaginationItem>
                        <PaginationLink
                          onClick={(e) => paginate(pageNo - 1)}
                          disabled={pageNo === 1 ? true : false}
                          style={{
                            backgroundColor: "#e4e7ea",
                          }}
                        >
                          {reportingTranslation?.previousButton
                            ? reportingTranslation?.previousButton
                            : "Prev"}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => paginate(pageNo + 1)}
                          disabled={!clickableNext}
                          style={{
                            backgroundColor: "#e4e7ea",
                          }}
                          className="mx-2"
                        >
                          {reportingTranslation?.nextButton
                            ? reportingTranslation?.nextButton
                            : "Next"}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className={"ml-auto"}>
                        <PaginationLink
                          className="rounded"
                          style={{
                            color: "black",
                            hover: false,
                            backgroundColor: "white",
                          }}
                        >
                          <div>
                            {productTranslation?.itemPerPage
                              ? productTranslation?.itemPerPage
                              : "Items per Page"}
                            <select
                              className="ml-2"
                              value={selectedPerPage}
                              onChange={(e) => perPageHandler(e, pageNo)}
                            >
                              {itemsPerPageOptions.map((option, i) => (
                                <option key={i} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Advertise;
