import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addFilter,
  getDisputes,
  deleteDispute,
} from "../../redux/reducers/seller";
import SkyLight, { SkyLightStateless } from "react-skylight";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardHeader,
} from "reactstrap";
import moment from "moment";
import { nextPagesItems } from "../../helpers/custom";
import { FaRegEdit } from "react-icons/fa";

const DisputeManagement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteModal = useRef(null);
  const [selectedId, setSelectedId] = useState(0);
  const { disputes, count, filters, loading } = useSelector(
    (state) => state.seller
  );
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    dispatch(getDisputes());
  }, []);

  const viewDispute = (id) => {
    history.push(`/admin/disputemanagement/view/${id}`);
  };

  const deleteItem = () => {
    dispatch(deleteDispute(selectedId)).then(() => {
      dispatch(getDisputes());
    });
    deleteModal.current.hide();
  };

  const deleteConfirm = (id) => {
    setSelectedId(id);
    deleteModal.current.show();
  };

  const editDispute = (id) => {
    props.history.push(`/admin/disputemanagement/update/${id}`);
  };

  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getDisputes());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getDisputes());
  };

  var myBigGreenDialog = {
    width: "40%",
    // height: '39%',
    minHeight: "20%",
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        hideOnOverlayClicked
        dialogStyles={myBigGreenDialog}
        ref={deleteModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>Are you sure you want to delete this Dispute?</h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            Yes
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            size="md"
            onClick={() => deleteModal.current.hide()}
            color="danger"
          >
            No
          </Button>
        </Row>
      </SkyLight>
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="10" className="p-0">
                  <strong> Dispute List </strong>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color">
                    <th> Order ID</th>
                    <th> Seller ID</th>
                    <th> Customer Name</th>
                    <th> Dispute Status</th>
                    <th> Created Date</th>
                    <th> Action </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={6}>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : disputes.length > 0 && !loading ? (
                    disputes.map((item, index) => (
                      <tr>
                        <td align="center">{item.orderId || "N/"}</td>
                        <td align="center">{item.sellerId || "N/A"}</td>
                        <td>{item.customerName}</td>
                        <td>{item.status || "New"}</td>
                        <td align="center">
                          {moment(item.createdAt).format("MMM D YYYY, h:mm A")}
                        </td>
                        <td align="center">
                          <i
                            className="fa fa-eye fa-lg mt-4"
                            onClick={(e) => viewDispute(item._id)}
                          ></i>

                          <FaRegEdit
                            onClick={(e) => editDispute(item._id)}
                            size={19}
                            className="mx-1"
                          />

                          <i
                            className="fa fa-trash fa-lg mt-4"
                            onClick={(e) => deleteConfirm(item._id)}
                          ></i>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={6} className="py-5 text-center">
                      <p style={{ color: "red" }}>Records Not Found</p>
                    </td>
                  )}
                </tbody>
              </Table>
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    onClick={(e) => paginate(pageNo - 1)}
                    disabled={pageNo === 1 ? true : false}
                    style={{
                      backgroundColor: "#e4e7ea",
                    }}
                  >
                    Prev
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    onClick={() => paginate(pageNo + 1)}
                    disabled={!clickableNext}
                    style={{
                      backgroundColor: "#e4e7ea",
                    }}
                    className="mx-2"
                  >
                    Next
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={"ml-auto"}>
                  <PaginationLink
                    className="rounded"
                    style={{
                      color: "black",
                      hover: false,
                      backgroundColor: "white",
                    }}
                  >
                    <div>
                      Items per Page
                      <select
                        className="ml-2"
                        value={selectedPerPage}
                        onChange={(e) => perPageHandler(e, pageNo)}
                      >
                        {itemsPerPageOptions.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* end of prising table layout */}
    </div>
  );
};

export default DisputeManagement;
