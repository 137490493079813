import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Store from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import reportWebVitals from "./reportWebVitals";
import './index.css';

let persistor = persistStore(Store);
ReactDOM.render(
    <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
reportWebVitals();
