import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Nav,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getReports,
  addFilter,
  getSubscription,
} from "../../redux/reducers/seller";
import axios from "axios";
import classnames from "classnames";
import Env from "../../environment/environment";
import cookie from "react-cookies";
import "./Reporting.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import FreemiumPage from "../Seller/CustomPages/FreemiumPage";

const Reporting = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const { filters, reports, loading } = useSelector((state) => state.seller);
  const { value: PageNo } = filters.find((item) => item.field === "pageNo");
  const [activeTab, setActiveTab] = useState("customer");
  const fremiumText1 = `${
    reportingTranslation?.infoLineOne
      ? reportingTranslation?.infoLineOne
      : "Only SOLO and Partner users can use the Sale Reports feature. Please upgrade your membership to access this and many more exciting features."
  }`;
  const { singleSubscription, loading: Membershiploading } = useSelector(
    (state) => state.seller
  );
  useEffect(() => {
    dispatch(getSubscription());
  }, []);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: "customer",
      })
    );
    dispatch(getReports());
  }, []);
  let state = {
    activeTab: "1",
    customer_reports: [],
    country_reports: [],
    pagecount1: 1,
    pagecount2: 1,
    nextPage: 2,
    prevPage: 0,
    disablePrevButton1: true,
    disableNextButton1: false,
    disablePrevButton2: true,
    disableNextButton2: false,
    membershipFlag: false,
  };

  const generateCustomerwise = () => {
    var doc = new jsPDF("p", "pt");
    doc.text(180, 50, "YINN Sales Report Customerwise!");
    var res = doc.autoTableHtmlToJson(document.getElementById("table-to-xls"));
    doc.autoTable(res.columns, res.data, { margin: { top: 80 } });
    var header = function (data) {
      doc.setFontSize(18);
      doc.setTextColor(40);
      doc.setFontStyle("normal");
      doc.text("Testing Report", data.settings.margin.left, 50);
    };
    doc.save("SalesReport.pdf");
  };

  const generateCountrywise = () => {
    var doc = new jsPDF("p", "pt");
    doc.text(180, 50, "YINN Sales Report Countrywise!");
    var res = doc.autoTableHtmlToJson(document.getElementById("table-to-xls1"));
    doc.autoTable(res.columns, res.data, { margin: { top: 80 } });
    var header = function (data) {
      doc.setFontSize(18);
      doc.setTextColor(40);
      doc.setFontStyle("normal");
      doc.text("Testing Report", data.settings.margin.left, 50);
    };
    doc.save("SalesReport.pdf");
  };

  const changeReportType = (type) => {
    setActiveTab(type);
    dispatch(
      addFilter({
        field: "type",
        value: type,
      })
    );
    dispatch(getReports());
  };

  const paginate = (pageNo) => {
    if (pageNo > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(getReports());
    }
  };
  return (
    <div className="animated fadeIn" style={{ marginTop: "20px" }}>
      {Membershiploading ? (
        <div className="mt-5" style={{ textAlign: "center" }}>
          <i
            className="fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : singleSubscription?.membershipType !== "Fremium" ? (
        <Row>
          <Col xs="12" md="12" className="mb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "customer" })}
                  onClick={() => {
                    changeReportType("customer");
                  }}
                >
                  <b style={{ color: "black" }}>
                    {reportingTranslation?.headingOne
                      ? reportingTranslation?.headingOne
                      : "Sales Report Customerswise"}
                  </b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "country" })}
                  onClick={() => {
                    changeReportType("country");
                  }}
                >
                  <b style={{ color: "black" }}>
                    {reportingTranslation?.headingTwo
                      ? reportingTranslation?.headingTwo
                      : "Sales Report Countrywise"}
                  </b>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="customer">
                <Row>
                  <Col xs="12" sm="12" md="12">
                    <Card>
                      <CardHeader>
                        <div className="d-flex justify-content-end">
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename="csv file"
                            sheet="sheet1"
                            buttonText="CSV"
                          />
                        </div>
                      </CardHeader>
                      <div>
                        <Row>
                          <div className="col-12 px-md-5 py-md-4 p-3 ">
                            <Table
                              hover
                              bordered
                              striped
                              responsive
                              size="sm"
                              border="1"
                              id="table-to-xls"
                            >
                              <thead align="center">
                                <tr className="header-color">
                                  <th>
                                    {reportingTranslation?.customerName
                                      ? reportingTranslation?.customerName
                                      : "Customer Name"}
                                  </th>
                                  <th>
                                    {reportingTranslation?.customerEmail
                                      ? reportingTranslation?.customerEmail
                                      : "Customer Email"}
                                  </th>
                                  <th>
                                    {reportingTranslation?.placeOrder
                                      ? reportingTranslation?.placeOrder
                                      : "Orders Placed"}
                                  </th>
                                  <th>
                                    {reportingTranslation?.totalSale
                                      ? reportingTranslation?.totalSale
                                      : "Total Sales"}
                                  </th>
                                </tr>
                              </thead>
                              {reports?.length > 0
                                ? reports?.map((report, index) => (
                                    <tbody>
                                      {loading ? (
                                        <td colSpan="4" className="py-5">
                                          <div style={{ textAlign: "center" }}>
                                            <i
                                              className="fas fa-spinner fa-pulse fa-2x"
                                              style={{ color: "green" }}
                                            ></i>
                                          </div>
                                        </td>
                                      ) : report?.usersdetail?.length > 0 &&
                                        !loading ? (
                                        report?.usersdetail?.map(
                                          (userdetail, index) => (
                                            <tr>
                                              <td>
                                                {`${userdetail.firstname} ${userdetail.lastname}`}
                                              </td>
                                              <td> {userdetail.email} </td>
                                              <td align="center">
                                                {report.orderCount}
                                              </td>
                                              <td align="center">
                                                {report.totalSales}
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </tbody>
                                  ))
                                : ""}
                            </Table>
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                  <Pagination>
                    <PaginationItem>
                      <PaginationLink
                        onClick={(e) => paginate(PageNo - 1)}
                        style={{ backgroundColor: "rgb(228, 231, 234)" }}
                      >
                        {reportingTranslation?.previousButton
                          ? reportingTranslation?.previousButton
                          : "Prev"}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        onClick={(e) => paginate(PageNo + 1)}
                        style={{ backgroundColor: "rgb(228, 231, 234)" }}
                        className="mx-2"
                      >
                        {reportingTranslation?.nextButton
                          ? reportingTranslation?.nextButton
                          : "Next"}
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </Row>
              </TabPane>
              <TabPane tabId="country">
                <Row>
                  <Col xs="12" sm="12" md="12">
                    <Card>
                      <CardHeader>
                        <div className="d-flex justify-content-end">
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls1"
                            filename="csv file"
                            sheet="sheet1"
                            buttonText="CSV"
                          />
                        </div>
                      </CardHeader>
                      <div>
                        <Row>
                          <div className="col-12 px-md-5 py-md-4 p-3 ">
                            <Table
                              hover
                              bordered
                              striped
                              responsive
                              size="sm"
                              border="1"
                              id="table-to-xls1"
                            >
                              <thead align="center">
                                <tr className="header-color">
                                  <th>
                                    {reportingTranslation?.billing
                                      ? reportingTranslation?.billing
                                      : "Billing Country"}
                                  </th>
                                  <th>
                                    {reportingTranslation?.placeOrder
                                      ? reportingTranslation?.placeOrder
                                      : "Orders Placed"}
                                  </th>
                                  <th>
                                    {reportingTranslation?.totalSale
                                      ? reportingTranslation?.totalSale
                                      : "Total Sales"}
                                  </th>
                                </tr>
                              </thead>
                              {reports.length > 0
                                ? reports.map((report, index) => (
                                    <tbody>
                                      {loading ? (
                                        <td colSpan="8" className="py-5">
                                          <div style={{ textAlign: "center" }}>
                                            <i
                                              className="fas fa-spinner fa-pulse fa-2x"
                                              style={{ color: "green" }}
                                            ></i>
                                          </div>
                                        </td>
                                      ) : (
                                        <tr>
                                          <td> {report._id} </td>
                                          <td align="center">
                                            {report.orderCount}
                                          </td>
                                          <td align="center">
                                            {report.totalSales}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  ))
                                : ""}
                            </Table>
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                  <nav>
                    <Pagination>
                      <PaginationItem>
                        <PaginationLink
                          onClick={(e) => paginate(PageNo - 1)}
                          style={{ backgroundColor: "rgb(228, 231, 234)" }}
                        >
                          {reportingTranslation?.previousButton
                            ? reportingTranslation?.previousButton
                            : "Prev"}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          onClick={(e) => paginate(PageNo + 1)}
                          style={{ backgroundColor: "rgb(228, 231, 234)" }}
                          className="mx-2"
                        >
                          {reportingTranslation?.nextButton
                            ? reportingTranslation?.nextButton
                            : "Next"}
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      ) : (
        <FreemiumPage text={fremiumText1} />
      )}
    </div>
  );
};

export default Reporting;
