import React, { useEffect } from "react";
import "./Addresses.css";
import "./Orders.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Addresses = () => {
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const dashboardTranslation =
    translationState?.[0]?.translations?.dashboard?.[userLanguage];

  const { user, loading } = useSelector((state) => state.auth);
  const history = useHistory();

  return (
    <>
      <div className="main-row">
        <span>
          {dashboardTranslation?.paraOneAddress
            ? dashboardTranslation?.paraOneAddress
            : "The following addresses will be used on the checkout page by default."}
        </span>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <i
              className="fas fa-spinner fa-pulse fa-2x"
              style={{ color: "green" }}
            ></i>
          </div>
        ) : (
          <div className="row mt-3">
            <div className="col-12 col-md-6 address-border">
              <h3 className="adress-edit-text">
                {dashboardTranslation?.billing
                  ? dashboardTranslation?.billing
                  : "Billing Address"}
              </h3>

              <div className="row">
                <div className="col-12 col-xl-3">
                  {user.billingAddress?.firstname}
                  <br />
                  {user.billingAddress?.lastname}
                  <br />
                  {user.billingAddress?.cityname}
                  <br />
                  {user.billingAddress?.statename}
                  <br />
                  {user.billingAddress?.countryname}
                </div>
                <div className="col-12  row justify-content-end">
                  <button
                    onClick={() =>
                      history.push("/customer-account/Edit-billing-address")
                    }
                    className="button-color"
                  >
                    {dashboardTranslation?.edit
                      ? dashboardTranslation?.edit
                      : "Edit"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 address-border">
              <h3 className="adress-edit-text">
                {shippingCartTranslation?.shippingAddress
                  ? shippingCartTranslation?.shippingAddress
                  : "Shipping Address"}
              </h3>
              <div className="row">
                <div className="col-12 ">
                  {user.shippingAddress?.firstname}
                  <br />
                  {user.shippingAddress?.lastname}
                  <br />
                  {user.shippingAddress?.cityname}
                  <br />
                  {user.shippingAddress?.statename}
                  <br />
                  {user.shippingAddress?.countryname}{" "}
                </div>
                <div className="col-12 row justify-content-end">
                  <button
                    onClick={() =>
                      history.push("/customer-account/Edit-shipping-address")
                    }
                    className="button-color"
                  >
                    {dashboardTranslation?.edit
                      ? dashboardTranslation?.edit
                      : "Edit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Addresses;
