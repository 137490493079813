import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMembershipPrices } from "../../redux/reducers/admin";
import { toast } from "react-toastify";
import "./updateMembershipPrices.css";
import { getMembershipPlans } from "../../redux/reducers/seller";

const UpdateMembershipPrices = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.admin);

  const [updatedPlan, setUpdatedPlan] = useState({
    planId: null,
    planPrice: null,
  });

  useEffect(() => {
    dispatch(getMembershipPlans());
  }, []);

  const { membershipPlans } = useSelector((state) => state.seller);

  const handleUpdatePlan = async () => {
    if (updatedPlan.planId === null || updatedPlan.planId === undefined) {
      return toast.error("Please select a plan");
    }

    if (updatedPlan.planPrice === undefined || updatedPlan.planPrice === null) {
      return toast.error("Please enter a price");
    }

    if (updatedPlan.planPrice < 0) {
      return toast.error("Price cannot be a negative number");
    }

    dispatch(
      updateMembershipPrices({
        planId: updatedPlan.planId,
        planPrice: updatedPlan.planPrice,
      })
    );
  };

  const onHandleChangePlan = (event) => {
    event.persist();
    setUpdatedPlan((updatedPlan) => {
      return { ...updatedPlan, [event.target.name]: event.target.value.trim() };
    });
  };

  return (
    <div className="main-div">
      <div className="card-box">
        <h5 className="text-center ">Update Plan Prices</h5>
        <label for="planId">Select Plan</label>
        <select
          name="planId"
          id="planId"
          className="input-box"
          onChange={(e) => {
            onHandleChangePlan(e);
          }}
        >
          <option value="" selected disabled hidden>
            Choose here
          </option>
          {membershipPlans.map((plan) => {
            return <option value={plan.planId}>{plan.planName}</option>;
          })}
        </select>

        <label for="planPrice">New Price</label>
        <input
          type="number"
          name="planPrice"
          id="planPrice"
          onChange={(e) => onHandleChangePlan(e)}
          className="input-box"
        />

        <button
          className="update-password-button"
          onClick={(e) => handleUpdatePlan()}
        >
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <i
                className="fas fa-spinner fa-pulse"
                style={{ color: "green" }}
              ></i>
            </div>
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default UpdateMembershipPrices;
