import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addPlatformCharges } from "../../../redux/reducers/admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CardGroup,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import "../feemanagement.css";

const AddCharge = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { adcharge } = useSelector((state) => state.admin);
  const [pageContent, setPageContent] = useState({
    type: 0,
    percentage: 0,
    perDay: 0,
    perWeek: 0,
    perMonth: 0,
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const createCharge = () => {
    const { perClick, deductPerClick, perDay, perWeek, perMonth } = pageContent;
    if (
      perClick < 0 ||
      deductPerClick < 0 ||
      perDay < 0 ||
      perWeek < 0 ||
      perMonth < 0
    ) {
      toast.error("fill with positive values only");
      return;
    }
    const data = {
      perClick,
      deductPerClick,
      perDay,
      perWeek,
      perMonth,
    };
    dispatch(addPlatformCharges(data));
    history.push("/admin/fees");
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col xs="12" lg="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <i className="fa fa-align-justify"></i>
                  <strong> Add Charges </strong>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Row>
                  <Col xs="12" sm="5" md="5" lg="5">
                    <Label>
                      <b>Type</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="Select"
                      name="perClick"
                      value={pageContent.perClick}
                      onChange={handleInputChange}
                      placeholder="Amount"
                    >
                      <option>Select Type</option>
                      {[""].map((ct, i) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col xs="12" sm="5" md="5" lg="5">
                    <Label>
                      <b>Percentage</b>
                    </Label>
                  </Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Input
                      type="Number"
                      min="0"
                      name="percentage"
                      value={pageContent.percentage}
                      onChange={handleInputChange}
                      placeholder="Percentage"
                    />
                  </Col>
                </Row>
              </FormGroup>

              <Row>
                <Col xs="12" sm="5" md="5" lg="5"></Col>
                <Col xs="12" sm="4" md="5" lg="4">
                  <Button
                    // disabled={!state.formValid}
                    color="primary"
                    type="submit"
                    className="btn btn-sm"
                    onClick={() => createCharge()}
                    block
                  >
                    Save
                  </Button>
                </Col>
                <Col xs="12" sm="4" md="4" lg="3"></Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddCharge;
