import React, { useEffect, useRef } from "react";

import "./stream.css";
import { socket } from "../../config/axios";
const Stream = () => {
  const peerConnections = {};
  const config = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
      // {
      //   "urls": "turn:TURN_IP?transport=tcp",
      //   "username": "TURN_USERNAME",
      //   "credential": "TURN_CREDENTIALS"
      // }
    ],
  };
  const videoStream = useRef(null);
  const videoSelect = useRef(null);
  const audioSelect = useRef(null);
  socket.on("answer", (id, description) => {
	peerConnections[id].setRemoteDescription(description);
  });
  socket.on("watcher", (id) => {
	const peerConnection = new RTCPeerConnection(config);
	peerConnections[id] = peerConnection;

	let stream = videoStream.current.srcObject;
	stream
	  .getTracks()
	  .forEach((track) => peerConnection.addTrack(track, stream));

	peerConnection.onicecandidate = (event) => {
	  if (event.candidate) {
		socket.emit("candidate", id, event.candidate);
	  }
	};

	peerConnection
	  .createOffer()
	  .then((sdp) => peerConnection.setLocalDescription(sdp))
	  .then(() => {
		socket.emit("offer", id, peerConnection.localDescription);
	  });
  });
  useEffect(() => {
    //   const socket = io.connect(window.location.origin);
    socket.on("candidate", (id, candidate) => {
      peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate));
    });

    socket.on("disconnectPeer", (id) => {
      peerConnections[id].close();
      delete peerConnections[id];
    });

    window.onunload = window.onbeforeunload = () => {
      socket.close();
    };

    getStream().then(getDevices).then(gotDevices);

    function getDevices() {
      return navigator.mediaDevices.enumerateDevices();
    }

    function gotDevices(deviceInfos) {
      window.deviceInfos = deviceInfos;
      for (const deviceInfo of deviceInfos) {
        const option = document.createElement("option");
        option.value = deviceInfo.deviceId;
        if (deviceInfo.kind === "audioinput") {
          option.text =
            deviceInfo.label || `Microphone ${audioSelect.current.length + 1}`;
          audioSelect.current.appendChild(option);
        } else if (deviceInfo.kind === "videoinput") {
          option.text =
            deviceInfo.label || `Camera ${videoSelect.current.length + 1}`;
          videoSelect.current.appendChild(option);
        }
      }
    }

    function getStream() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      const audioSource = audioSelect.current.value;
      const videoSource = videoSelect.current.value;
      const constraints = {
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
        video: { deviceId: videoSource ? { exact: videoSource } : undefined },
      };
      return navigator.mediaDevices
        .getUserMedia(constraints)
        .then(gotStream)
        .catch(handleError);
    }

    function gotStream(stream) {
      window.stream = stream;
      audioSelect.current.selectedIndex = [
        ...audioSelect.current.options,
      ].findIndex((option) => option.text === stream.getAudioTracks()[0].label);
      videoSelect.current.selectedIndex = [
        ...videoSelect.current.options,
      ].findIndex((option) => option.text === stream.getVideoTracks()[0].label);
      videoStream.current.srcObject = stream;
      socket.emit("broadcaster");
    }

    function handleError(error) {
      console.error("Error: ", error);
    }
  });
  return (
    <div className="">
      <h1>Hello its test streaming</h1>
      <section className="select">
        <label htmlFor="audioSource">Audio source: </label>
        <select ref={audioSelect} id="audioSource"></select>
      </section>

      <section className="select">
        <label htmlFor="videoSource">Video source: </label>
        <select ref={videoSelect} id="videoSource"></select>
      </section>

      <video
        id="stream-video"
        playsInline
        autoPlay
        muted
        ref={videoStream}
      ></video>
    </div>
  );
};
export default Stream;
