import React, { Component } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./ProductPreviewBox.css";
import { MiniRatingWidget } from "../ProductDetail/RatingWidget";
import { useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";

const ProductPreviewBox = (props) => {
  const { singleProduct, userLanguage } = useSelector((state) => state.auth);
  const { viewProduct, product, style } = props;
  const { eventCategory } = product;
  const data = product?.translatedArray.map((translations) => translations);
  const nameTrans = data.filter((data) => data.language == userLanguage);

  return (
    <div className="product-preview-container  " style={{ style }}>
      <div className="product-preview-container-image-wrapper">
        <img
          className="product-preview-container-image"
          src={product.images[0]}
          alt="Avatar"
        />
      </div>
      {eventCategory === "event" ? (
        <div>
          <button
            className="even-ticket-button"
            onClick={() => {
              viewProduct(product.productType);
            }}
          >
            Buy Event Ticket
          </button>
        </div>
      ) : (
        <div style={{ display: "flex", width: "100%", height: "26.45%" }}>
          <div style={{ width: "75%" }}>
            {nameTrans?.[0] ? (
              <div className="product-preview-name-text">
                {nameTrans?.[0]?.name}
              </div>
            ) : (
              <div className="product-preview-name-text">{product.name}</div>
            )}
            <div className="product-preview-brand-text">{product.brand}</div>
            {eventCategory !== "event" && (
              <div className="d-flex justify-content-between">
                <div style={{ marginLeft: 10 }}>
                  <MiniRatingWidget product={product} />
                </div>
                <button
                  className="product-detail-view-button"
                  onClick={() => {
                    viewProduct(product.productType);
                  }}
                >
                  <IoMdAdd size={18} type="button" />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPreviewBox;
