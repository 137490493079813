import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SetState,
  adsCharges,
  deleteAdsCharges,
} from "../../redux/reducers/admin";
import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
const Charge = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const { adcharge, loading } = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(adsCharges());
  }, []);
  let state = {
    charges: [],
    chargeValid: true,
  };
  const deleteCharge = () => {
    dispatch(deleteAdsCharges(currentId)).then(() => {
      dispatch(adsCharges());
      setIsDeleteModal(false);
    });
  };

  const deleteConfirm = (id) => {
    setCurrentId(id);
    setIsDeleteModal(true);
  };
  const hideModal = () => {
    setIsDeleteModal(false);
  };

  const editCharge = (id) => {
    history.push(`/admin/charges/edit?chargeId=${id}`);
  };

  return (
    <div className="animated fadeIn">
      <Card className="mt-5 mx-2">
        <CardHeader>
          <Row>
            <Col xs="8" sm="8" md="9" className="mt-2">
              <strong>Advertisement Charges List</strong>
            </Col>
            <Col xs="4" sm="4" md="3">
              <Button
                onClick={() => history.push("/admin/charges/add")}
                size="md"
                color="warning"
                disabled={adcharge.length > 0}
                style={{ marginLeft: "150px" }}
              >
                <b> Add Charges </b>
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Table hover bordered striped responsive size="sm">
            <thead align="center">
              <tr className="header-color" align="center">
                <th> Pay per click </th>
                <th> Pay per day </th>
                <th> Pay per week </th>
                <th> Pay per month </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <td colSpan={5}>
                  <div style={{ textAlign: "center" }}>
                    <i
                      className="fas fa-spinner fa-pulse fa-2x"
                      style={{ color: "green", margin: "80px" }}
                    ></i>
                  </div>
                </td>
              ) : adcharge.length > 0 && !loading ? (
                adcharge.map((charge, index) => (
                  <tr>
                    <td align="center"> {charge.perClick}</td>
                    <td align="center"> {charge.perDay}</td>
                    <td align="center">{charge.perWeek}</td>
                    <td align="center">{charge.perMonth}</td>
                    <td align="center">
                      <i
                        className="fa fa-edit fa-lg mt-4"
                        onClick={(e) => editCharge(charge._id)}
                      ></i>
                      &nbsp;
                      <i
                        className="fa fa-trash fa-lg mt-4"
                        onClick={(e) => deleteConfirm(charge._id)}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <td colSpan={5} className="py-5 text-center">
                  <p style={{ color: "red" }}>Records Not Found</p>
                </td>
              )}
            </tbody>

            <Modal
              isOpen={isDeleteModal}
              className={"modal-danger " + props.className}
            >
              <ModalHeader>Delete Advertisement Charges</ModalHeader>
              <ModalBody>
                <b> Do you really want to delete this Advertisement Charge? </b>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={(e) => deleteCharge()}>
                  Yes
                </Button>{" "}
                <Button color="secondary" onClick={(e) => hideModal()}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default Charge;
