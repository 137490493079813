import React, { useEffect, useState } from "react";
import "./AccountDetails.css";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import profileimg from "../../../src/assets/img/brand/chat-user-image.png";
import cardImage from "../../../src/assets/img/brand/cardImages.png";
import Config from "../../config/aws";
import { useDispatch, useSelector } from "react-redux";
import { EditUserInfo } from "../../redux/reducers/auth";
import { getPresigedUrl, uploadToS3ViaSignedUrl } from "../../helpers/s3Utils";
import { toast } from "react-toastify";

const AccountDetails = () => {
  const [type, setType] = useState("show");
  const { user } = useSelector((state) => state.auth);

  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const sellerHomeTranslation =
    translationState?.[0]?.translations?.sellerHome?.[userLanguage];
  const dashboardTranslation =
    translationState?.[0]?.translations?.dashboard?.[userLanguage];

  const [pageContent, setPageContent] = useState({
    profileAvatar: null,
  });

  const uploadImage = async (blobs) => {
    for (let i = 0; i < blobs.length; i++) {
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };
      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const imageUrl = `${Config.digitalOceanSpaces}/` + blob.name;
        setPageContent({
          // ...pageContent,
          profileAvatar: imageUrl,
        });
        const data = {
          profileAvatar: imageUrl,
        };
        dispatch(EditUserInfo(data));
      } catch (error) {
        toast.error("Error in uploading image! Please try again");
      }
    }
  };

  return (
    <>
      <div className="row main-row">
        <div
          style={{
            backgroundImage: `url(${cardImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "auto",
            // boxShadow: `0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15)`,
            boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)`,
          }}
          className="main-card-wrapper"
        >
          <div className="card-wrapper">
            <div>
              <label htmlFor="image-upload">
                <img
                  className=" user-profile-image"
                  src={user.profileAvatar ? user.profileAvatar : profileimg}
                />
                <i
                  style={{ cursor: "pointer" }}
                  className=" fas fa-plus-circle addicon "
                ></i>
              </label>
              <input
                type="file"
                id="image-upload"
                hidden
                accept="image/png image/jpeg image/gif image/ico image/jpg "
                onChange={(event) => uploadImage(event.target.files)}
              ></input>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12 mt-2 mb-3">
                <h4 className="user-text-heading">
                  {shippingCartTranslation?.firsrtName
                    ? shippingCartTranslation?.firsrtName + " :"
                    : "First Name: "}
                </h4>
                <div className="user-text-wrapper">
                  {_.capitalize(user?.firstname)}
                </div>
              </div>
              <div className="col-sm-6 col-12 mt-2 mb-3">
                <h4 className="user-text-heading">
                  {shippingCartTranslation?.lastName
                    ? shippingCartTranslation?.lastName + " :"
                    : "Last Name: "}
                </h4>
                <div className="user-text-wrapper">
                  {_.capitalize(user?.lastname)}
                </div>
              </div>
              <div className="col-sm-6 col-12 mt-2 mb-3">
                <h4 className="user-text-heading">
                  {sellerHomeTranslation?.email
                    ? sellerHomeTranslation?.email + " :"
                    : "Email: "}
                </h4>
                <div className="user-text-wrapper">{user.email}</div>
              </div>
              <div className="col-12 mt-2 mb-3">
                <button
                  className="edit-button  "
                  onClick={() =>
                    history.push("/customer-account/accountdetailsedit")
                  }
                >
                  {dashboardTranslation?.edit
                    ? dashboardTranslation?.edit
                    : "Edit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
