import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SetState,
  adsCharges,
  deleteAdsCharges,
  updateAdsCharges,
  singleAdsCharge,
  addAdsCharges,
} from "../../../redux/reducers/admin";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import Env from "../../../environment/environment";
import "../charge.css";

const EditCharge = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { singleAdCharge, loading } = useSelector((state) => state.admin);
  const [pageContent, setPageContent] = useState({});
  const chargeId = new URLSearchParams(history.location.search).get("chargeId");
  useEffect(() => {
    dispatch(singleAdsCharge(chargeId));
  }, []);
  useEffect(() => {
    setPageContent({ ...singleAdCharge });
  }, [singleAdCharge]);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const editCharge = () => {
    const { perClick, deductPerClick, perDay, perWeek, perMonth } = pageContent;
    if (
      perClick < 0 ||
      deductPerClick < 0 ||
      perDay < 0 ||
      perWeek < 0 ||
      perMonth < 0
    ) {
      toast.error("fill with positive values only");
      return;
    }
    const data = {
      _id: chargeId,
      perClick,
      deductPerClick,
      perDay,
      perWeek,
      perMonth,
    };
    dispatch(updateAdsCharges(data));
    history.push("/admin/charges/");
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />

      <Row>
        <Col xs="12" lg="12">
          <Card className="mt-5">
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <i className="fa fa-align-justify"></i>
                  <strong> Edit Charge </strong>
                </Col>
              </Row>
            </CardHeader>
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <i
                  className="fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green", margin: "240px" }}
                ></i>
              </div>
            ) : (
              <CardBody>
                <FormGroup>
                  <Row>
                    <Col xs="12" sm="4" md="4" lg="3">
                      <Label>
                        <b>Minimum Amount for Pay per click</b>
                      </Label>
                    </Col>
                    <Col xs="12" sm="4" md="5" lg="4">
                      <Input
                        type="Number"
                        min="0"
                        name="perClick"
                        onChange={handleInputChange}
                        placeholder="Amount"
                        value={pageContent.perClick}
                      />
                      {/* <p className="onerror"> {state.formErrors.perClick}</p> */}
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col xs="12" sm="4" md="4" lg="3">
                      <Label>
                        <b>Amount Deducted per click</b>
                      </Label>
                    </Col>
                    <Col xs="12" sm="4" md="5" lg="4">
                      <Input
                        type="Number"
                        min="0"
                        name="deductPerClick"
                        onChange={handleInputChange}
                        placeholder="Amount"
                        value={pageContent.deductPerClick}
                      />
                      <p className="onerror">
                        {" "}
                        {/* {state.formErrors.deductPerClick} */}
                      </p>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col xs="12" sm="4" md="4" lg="3">
                      <Label>
                        <b>Pay per day</b>
                      </Label>
                    </Col>
                    <Col xs="12" sm="4" md="5" lg="4">
                      <Input
                        type="Number"
                        min="0"
                        name="perDay"
                        onChange={handleInputChange}
                        placeholder="Edit Amount"
                        value={pageContent.perDay}
                      />
                      {/* <p className="onerror"> {state.formErrors.perDay}</p> */}
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col xs="12" sm="4" md="4" lg="3">
                      <Label>
                        <b>Pay per week</b>
                      </Label>
                    </Col>
                    <Col xs="12" sm="4" md="5" lg="4">
                      <Input
                        type="Number"
                        min="0"
                        name="perWeek"
                        onChange={handleInputChange}
                        placeholder="Amount"
                        value={pageContent.perWeek}
                      />
                      {/* <p className="onerror"> {state.formErrors.perWeek}</p> */}
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col xs="12" sm="4" md="4" lg="3">
                      <Label>
                        <b>Pay per month</b>
                      </Label>
                    </Col>
                    <Col xs="12" sm="4" md="5" lg="4">
                      <Input
                        type="Number"
                        min="0"
                        name="perMonth"
                        onChange={handleInputChange}
                        placeholder="Edit Amount"
                        value={pageContent.perMonth}
                      />
                      {/* <p className="onerror"> {state.formErrors.perMonth}</p> */}
                    </Col>
                  </Row>
                </FormGroup>

                <Row>
                  <Col xs="12" sm="4" md="4" lg="3"></Col>
                  <Col xs="12" sm="4" md="5" lg="4">
                    <Button
                      color="primary"
                      type="submit"
                      // disabled={!state.formValid}
                      className="btn btn-sm"
                      onClick={() => editCharge()}
                      block
                    >
                      Save
                    </Button>
                  </Col>
                  <Col xs="12" sm="4" md="4" lg="3"></Col>
                </Row>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditCharge;
