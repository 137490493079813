import React from "react";
// import { Link } from "react-router-dom";
import { AppHeader, AppSidebarToggler } from "@coreui/react";
import DefaultHeader from "../Header/DefaultHeader";
import DefaultFooter from "../Footer/DefaultFooter";
import { Row } from "reactstrap";
import "./CustomerAccount.css";
import { useHistory } from "react-router-dom";

const CustomerAccount = () => {
  const history = useHistory();

  return (
    <>
      <Row className="mt-100">
        <div className="col-3">
          <ul className="list-group list-group-flush">
            <li
              onClick={() => history.push("/customer-account/dashboard")}
              className="list-group-item"
            >
              Dashboard
            </li>
            <li
              onClick={() => history.push("/customer-account/account-details")}
              className="list-group-item "
            >
              Account details
            </li>
            <li
              onClick={() => history.push("customer-account/orders")}
              className="list-group-item"
            >
              Orders
            </li>
            <li
              onClick={() => history.push("customer-account/subscription")}
              className="list-group-item"
            >
              Subscriptions
            </li>
            <li
              onClick={() => history.push("customer-account/request-quotes")}
              className="list-group-item"
            >
              Request Quotes
            </li>
            <li
              onClick={() => history.push("customer-account/downloads")}
              className="list-group-item "
            >
              Downloads
            </li>
            <li
              onClick={() => history.push("customer-account/rma-requests")}
              className="list-group-item"
            >
              RMA Requests
            </li>
            <li
              onClick={() => history.push("customer-account/addresses")}
              className="list-group-item"
            >
              Addresses
            </li>
            <li
              onClick={() =>
                history.push("customer-account/seller-support-tickets")
              }
              className="list-group-item"
            >
              Seller Support Tickets
            </li>
            <li
              onClick={() => history.push("customer-account/vendors")}
              className="list-group-item"
            >
              Vendors
            </li>
            <li
              onClick={() => history.push("customer-account/bookings")}
              className="list-group-item "
            >
              Bookings
            </li>
            <li
              onClick={() => history.push("customer-account/auctions-settings")}
              className="list-group-item"
            >
              Auctions settings
            </li>
          </ul>
        </div>
      </Row>

      <DefaultFooter />
    </>
  );
};

export default CustomerAccount;
