import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allDMCA, addFilter } from "../../../redux/reducers/admin";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { nextPagesItems } from "../../../helpers/custom";

const Takedown = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dmca, filters, loading, count } = useSelector((state) => state.admin);
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    dispatch(allDMCA());
  }, []);

  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(allDMCA());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(allDMCA());
  };

  const showModal = (id) => {
    history.push(`/admin/report-infringement/${id}`);
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />

      <Row>
        <Col>
          <Card className="mt-5">
            <CardHeader>
              <Row>
                <Col xs="12" sm="9">
                  <b>DMCA Takedown List</b>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color">
                    <th>Complaint Id</th>
                    <th> User Name</th>
                    <th> Email</th>
                    <th> Status</th>
                    <th> Last Updated</th>
                    <th> Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={6}>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : dmca.length > 0 && !loading ? (
                    dmca.map((item, index) => (
                      <tr>
                        <td align="center">{item._id}</td>
                        <td align="center">{item.name}</td>
                        <td align="center">{item.email}</td>
                        <td align="center">{item.status || "Open"}</td>
                        <td>{item.updatedAt}</td>
                        <td align="center">
                          <i
                            className="fa fa-eye fa-lg m-1"
                            onClick={(e) => showModal(item._id)}
                          ></i>
                          <span>{"  "} </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={6} className="py-5 text-center">
                      <p style={{ color: "red" }}>Records Not Found</p>
                    </td>
                  )}
                </tbody>
              </Table>
              <nav>
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo - 1)}
                      disabled={pageNo === 1 ? true : false}
                      style={{
                        backgroundColor: "#e4e7ea",
                      }}
                    >
                      Prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => paginate(pageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: "#e4e7ea",
                      }}
                      className="mx-2"
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        Items per Page
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, pageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Takedown;
