import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
//Component
import PrivateRoute from "./privateRoute";
import AdminRoutes from "./adminRoute";
import UserRoutes from "./userRoute";
import SellerRoutes from "./sellerRoute";
import DefaultLayout from "../layouts/DefaultLayout";
import "../scss/style.css";
import {
  AdminViewProduct,
  AdminEditProduct,
  Login,
  Register,
  Reset,
  Home,
  Search,
  CustomerProfile,
  Invoice,
  Checkout,
  Page404,
  CMS,
  Profile,
  Pages,
  ViewPage,
  UpdatePages,
  FeeManagement,
  AddFee,
  UpdateFee,
  DisputeManagement,
  ViewDispute,
  UpdateDispute,
  ViewFee,
  PersonalInfo,
  AddInfo,
  EditInfo,
  Product,
  AddProduct,
  EditProduct,
  ViewProduct,
  AddCategory,
  Category,
  DigitalProductList,
  Customer,
  AddCustomer,
  Order,
  ViewOrder,
  Charge,
  AddCharge,
  EditCharge,
  Advertise,
  Ads,
  AdsPayment,
  AnnouncementList,
  Announcement,
  ViewAnnouncement,
  Takedown,
  TakedownDetails,
  Coin,
  AddCoin,
  EditCoin,
  UserWallet,
  WalletList,
  WalletDetails,
  PaymentConfig,
  SocialLinks,
  TranslationInput,
  StreamingConfig,
  Membership,
  Reporting,
  DigitalProduct,
  AddResident,
  RecidencyList,
  Alerts,
  Streaming,
  Stream,
  Watch,
  MicroLoan,
  LendLoan,
  Payout,
  DigitalProductsList,
  DigitalProductsUpdate,
  DigitalProductsAdd,
  DigitalProductView,
  Users,
  Sellers,
  PrivacyPoilcy,
  TermsAndConditions,
  CMSPage,
  UpdateAdminPassword,
  UpdateMembershipPrices,
} from "../views";
import {
  ProductDetail,
  Shipping,
  SellerDetail,
  UserOrder,
  Cart,
  ContactUs,
  ContentManagement,
  Dispute,
  DigitalProductDetail,
  DigitalProductDetail1,
  BrowseTvseries,
  ViewTvSeries,
  YinnCoin,
  Copyrights,
  ContactYinn,
} from "../views/Auth";
import MyOrders from "../views/Shared/MyOrders";
import ProductVerification from "../views/ProductVerification/ProductVerification";
import UserProfile from "../views/Seller/User/UserProfile";
import CoinPayment from "../views/Auth/YinnCoin/CoinPayment/CoinPayment";
import CustomerAccount from "../views/CustomerAccount/CustomerAccount";
import User from "../layouts/menus/user";
import Dashboard from "../views/CustomerAccount/Dashboard";
import AccountDetails from "../views/CustomerAccount/AccountDetails";
import OrderView from "../views/CustomerAccount/OrderView";
import UserLayout from "../layouts/UserLayout";
import UserRoute from "./User";
import Orders from "../views/CustomerAccount/Orders";
import Downloads from "../views/CustomerAccount/Downloads";
import Addresses from "../views/CustomerAccount/Addresses";
import SupportTicket from "../views/CustomerAccount/SupportTicket";
import AuctionSetting from "../views/CustomerAccount/AuctionSetting";
import AccountInvoice from "../views/CustomerAccount/AccountInvoice";
import PaymentMethod from "../views/CustomerAccount/PaymentMethod";
import BrowseProduct from "../views/CustomerAccount/BrowseProduct";
import AccountDetailsEdit from "../views/CustomerAccount/AccountDetailsEdit";
import EditBillingAddress from "../views/CustomerAccount/EditBillingAddress";
import EditShippingAddress from "../views/CustomerAccount/EditShippingAddress";
import CreateSellerTicket from "../views/CustomerAccount/CreateSellerTicket";
import ViewSellerSupportDetail from "../views/CustomerAccount/ViewSellerSupportDetail";
import UserPurchaseCoin from "../views/CustomerAccount/UserPurchaseCoin";
import ViewInvoice from "../views/CustomerAccount/ViewInvoice";
import SellerSupport from "../views/SellerSupport/SellerSupport";
import ViewTicket from "../views/SellerSupport/ViewTicket/ViewTicket";
import Setting from "../views/Setting/Setting";
import Events from "../views/Events/Event";
import Evoucher from "../views/Evoucher/Evoucher";
import CancelAccount from "../views/CancelAccount/CancelAccount";
import TranslationField from "../views/Admin/Translation/TranslationField";
import HomeField from "../views/Admin/Translation/homeContent";
import FooterField from "../views/Admin/Translation/footerContent";
import HeaderField from "../views/Admin/Translation/headerContent";
import HomeFeatureFeild from "../views/Admin/Translation/homeFeatureContent";
import ContactUsFeild from "../views/Admin/Translation/contactUsContent";
import VerifyFeild from "../views/Admin/Translation/verifyContent";
import ShopFeild from "../views/Admin/Translation/shopContent";

const Index = () => {
  return (
    <Switch>
      <AdminRoutes
        exact
        path="/admin"
        component={CMS}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/content-management"
        component={CMS}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/content-management/page"
        component={Pages}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/content-management/view-page"
        component={ViewPage}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/content-management/update-page"
        component={UpdatePages}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/personalInfo"
        component={PersonalInfo}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/personalInfo/add"
        component={AddInfo}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/personalInfo/edit"
        component={EditInfo}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/products"
        component={Product}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/products/view/:id"
        component={AdminViewProduct}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/products/edit/:id"
        component={AdminEditProduct}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/digitalproduct/:type/view/:id"
        component={DigitalProductView}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/products/add"
        component={AddProduct}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/products/editproduct"
        component={EditProduct}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/products/viewproduct"
        component={ViewProduct}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/product/category/:type/add"
        component={AddCategory}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/product/category/:type"
        component={Category}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/digitalproductlist"
        component={DigitalProductList}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/users"
        component={Users}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/sellers"
        component={Sellers}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/customer/add"
        component={AddCustomer}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/order"
        component={Order}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/seller-support"
        component={SellerSupport}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/seller/support/view-ticket/:id"
        component={ViewTicket}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/order/view-order/:id"
        component={ViewOrder}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/charges"
        component={Charge}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/charges/add"
        component={AddCharge}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/charges/edit"
        component={EditCharge}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/advertisement"
        component={Advertise}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/advertisement/add"
        component={Ads}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/advertisement/add/payment"
        component={AdsPayment}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/announcementlist"
        component={AnnouncementList}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/announcementlist/add"
        component={Announcement}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        path="/admin/announcementlist/view/:id"
        component={ViewAnnouncement}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/dmca-copyrights"
        component={Takedown}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/report-infringement/:id"
        component={TakedownDetails}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/coin"
        component={Coin}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/coin/add"
        component={AddCoin}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/coin/edit/:id"
        component={EditCoin}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/wallet-lists"
        component={WalletList}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/wallet-lists/walletDetails/:id"
        component={WalletDetails}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/disputemanagement"
        component={DisputeManagement}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/disputemanagement/view/:id"
        component={ViewDispute}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/disputemanagement/update/:id"
        component={UpdateDispute}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/systemSettings"
        component={PaymentConfig}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/socialLinks"
        component={SocialLinks}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/translationFieldComponent"
        component={TranslationField}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/translationHomeField"
        component={HomeField}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/translationHeaderField"
        component={HeaderField}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/translationFooterField"
        component={FooterField}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/HomeFeatureField"
        component={HomeFeatureFeild}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/contactUsField"
        component={ContactUsFeild}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/VerifyField"
        component={VerifyFeild}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/ShopFeild"
        component={ShopFeild}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/streamingConfig"
        component={StreamingConfig}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/fees"
        component={FeeManagement}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/fees/add"
        component={AddFee}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/fees/update/:id"
        component={UpdateFee}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/lend-loan"
        component={LendLoan}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/payout-requests"
        component={Payout}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/wallet"
        component={UserWallet}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/profile"
        component={Profile}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/customer"
        component={Customer}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/digitalproduct/:type/update/:id"
        component={DigitalProductsUpdate}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/updateAdminPassword"
        component={UpdateAdminPassword}
        withLayout={DefaultLayout}
      />
      <AdminRoutes
        exact
        path="/admin/updateMembershipPrices"
        component={UpdateMembershipPrices}
        withLayout={DefaultLayout}
      />
      <UserRoutes
        exact
        path="/user"
        component={Profile}
        withLayout={DefaultLayout}
      />
      <UserRoutes
        exact
        path="/user/wallet"
        component={UserWallet}
        withLayout={DefaultLayout}
      />
      <UserRoutes
        exact
        path="/user/profile"
        component={Profile}
        withLayout={DefaultLayout}
      />
      <UserRoutes
        path="/user/announcementlist/view/:id"
        component={ViewAnnouncement}
        withLayout={DefaultLayout}
      />
      <UserRoutes
        exact
        path="/user/lend-loan"
        component={LendLoan}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller"
        component={Profile}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/profile"
        component={Profile}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/wallet"
        component={UserWallet}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/e-voucher"
        component={Evoucher}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/Membership"
        component={Membership}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/reporting"
        component={Reporting}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/products"
        component={Product}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/products/add"
        component={AddProduct}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/products/editproduct/:id"
        component={EditProduct}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/products/viewproduct/:id"
        component={ViewProduct}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/digitalproduct/:type"
        component={DigitalProductsList}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/digitalproduct/:type/add"
        component={DigitalProductsAdd}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/digitalproduct/:type/update/:id"
        component={DigitalProductsUpdate}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/digitalproduct/:type/view/:id"
        component={DigitalProductView}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/digitalproduct"
        component={DigitalProduct}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/residancySwap/list"
        component={RecidencyList}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/residancySwap/add"
        component={AddResident}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/residancySwap/edit/:id"
        component={AddResident}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/order"
        component={Order}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/order/view-order/:id"
        component={ViewOrder}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/customer"
        component={Customer}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/customer/add"
        component={AddCustomer}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/customer-support"
        component={SellerSupport}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/advertisement"
        component={Advertise}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/advertisement/add"
        component={Ads}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/advertisement/add/payment"
        component={AdsPayment}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/notifications"
        component={AnnouncementList}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/streaming"
        component={Streaming}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/loan"
        component={MicroLoan}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/payouts"
        component={Payout}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/lend-loan"
        component={LendLoan}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/cancel-account"
        component={CancelAccount}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/settings"
        component={Setting}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/events"
        component={Events}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        path="/seller/announcementlist/view/:id"
        component={ViewAnnouncement}
        withLayout={DefaultLayout}
      />
      <SellerRoutes
        exact
        path="/seller/support/view-customer-ticket/:id"
        component={ViewTicket}
        withLayout={DefaultLayout}
      />
      <UserRoutes
        exact
        path="/customer-account/event"
        component={Events}
        withLayout={UserLayout}
      />
      s
      <UserRoute
        exact
        path="/customer-account"
        component={Dashboard}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/dashboard"
        component={Dashboard}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/account-details"
        component={AccountDetails}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/orders"
        component={Orders}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/downloads"
        component={Downloads}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/addresses"
        component={Addresses}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/seller-support-tickets"
        component={SupportTicket}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/auctions-settings"
        component={AuctionSetting}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/invoice"
        component={AccountInvoice}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/payment-method"
        component={PaymentMethod}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/payouts"
        component={Payout}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/e-voucher"
        component={Evoucher}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/cancel-account"
        component={CancelAccount}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/vieworder/:id"
        component={OrderView}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/browseproduct"
        component={BrowseProduct}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/accountdetailsedit"
        component={AccountDetailsEdit}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/Edit-billing-address"
        component={EditBillingAddress}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/Edit-shipping-address"
        component={EditShippingAddress}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/open-sellersupport-ticket"
        component={CreateSellerTicket}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/view-sellersupport-detail/:id"
        component={ViewSellerSupportDetail}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/user-purchase-coin"
        component={UserPurchaseCoin}
        withLayout={UserLayout}
      />
      <UserRoute
        exact
        path="/customer-account/view-invoice/:id"
        component={ViewInvoice}
        withLayout={UserLayout}
      />
      <PrivateRoute exact path="/shipping" component={Checkout} />
      <PrivateRoute exact path="/yinn-coin" component={YinnCoin} />
      <Route exact path="/" component={Home} />
      <Route exact path="/page/:id" component={ContentManagement} />
      <Route exact path="/stream/:id" component={Stream} />
      <Route exact path="/watch/:id" component={Watch} />
      <Route exact path="/copyrights" component={Copyrights} />
      <Route exact path="/dispute" name="Dispute" component={Dispute} />
      <Route exact path="/invoice" component={Invoice} />
      <Route exact path="/my-orders" component={MyOrders} />
      <Route exact path="/ContactUs" component={ContactUs} />
      <Route exact path="/my-cart" component={Cart} />
      {/* <Route exact path="/yinn-coin" component={YinnCoin} /> */}
      <Route exact path="/coinPayment" component={CoinPayment} />
      <Route exact path="/sellerdetail" component={UserProfile} />
      {/* <Route exact path="/shipping" name="Shipping Page" component={Shipping} /> */}
      <Route exact path={`/search`} component={Search} />
      <Route exact path="/productdetail/:id" component={ProductDetail} />
      <Route
        exact
        path="/digitalproduct/:id"
        component={DigitalProductDetail}
      />
      <Route
        exact
        path="/digitalproductdetail/:id"
        component={DigitalProductDetail1}
      />
      <Route exact path="/browsetvseries" component={BrowseTvseries} />
      <Route exact path="/viewtv/:id" component={ViewTvSeries} />
      <Route exact path="/dashboard" component={DefaultLayout} />
      <Route exact path="/pages/:id" component={CMSPage} />
      <Route exact path="/customer-profile" component={CustomerProfile} />
      <Route exact path="/customer-account" component={CustomerAccount} />
      <Route exact path="/support" component={ContactYinn} />
      <Route exact path="/verify" component={ProductVerification} />
      {/* <Route exact path="/shipping" component={Checkout} /> */}
      <Route exact path="/user" component={UserProfile} />
      <Route exact path="/privacy-policy" component={PrivacyPoilcy} />
      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      {/* <Route path="/" name="Home" component={DefaultLayout} /> */}
      <Route path="/not-found" component={Page404} />
      <Redirect from="*" to="/not-found" />
    </Switch>
  );
};
export default Index;
