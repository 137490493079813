export default {
  items: [
    {
      name: "Home",
      url: "/seller/profile",
      icon: "fas fa-home",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Membership",
      url: "/seller/Membership",
      icon: "fab fa-meetup",
    },
    {
      name: "Reporting",
      url: "/seller/reporting",
      icon: "fas fa-registered",
    },
    {
      name: "Product",
      url: "/seller/products",
      icon: "fab fa-product-hunt",
    },
    {
      name: "Digital_Products",
      url: "/seller/digitalproduct",
      icon: "fab fa-dyalog",
    },
    {
      name: "Residancy_Swap",
      url: "/seller/residancySwap/list",
      icon: "fa fa-home",
    },
    {
      name: "Orders",
      url: "/seller/order",
      icon: "fab fa-first-order-alt",
    },
    {
      name: "Wallet",
      url: "/seller/wallet",
      icon: "fas fa-wallet",
    },
    {
      name: "E_Voucher",
      url: "/seller/e-voucher",
      icon: "fas fa-wallet",
    },
    {
      name: "Customers",
      url: "/seller/customer",
      icon: "fas fa-user-tie",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Customer_Support",
      url: "/seller/customer-support",
      icon: "fas fa-user-tie",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Advertise",
      url: "/seller/advertisement",
      icon: "fab fa-adversal",
    },
    {
      name: "Announcement",
      url: "/seller/notifications",
      icon: "fas fa-bullhorn",
    },
    {
      name: "Streaming",
      url: "/seller/streaming",
      icon: "fas fa-video",
    },
    {
      name: "Micro_Loan",
      url: "/seller/loan",
      icon: "fas fa-dollar",
    },
    {
      name: "Lend_Loan",
      url: "/seller/lend-loan",
      icon: "fas fa-dollar",
    },
    {
      name: "Payouts",
      url: "/seller/payouts",
      icon: "fas fa-dollar",
    },
    {
      name: "Settings",
      url: "/seller/settings",
      icon: "fas fa-cog",
    },
    {
      name: "Event",
      url: "/seller/events",
      icon: "fas fa-id-card-o ",
    },
    {
      name: "Cancel_Account",
      url: "/seller/cancel-account",
      icon: "fas fa-trash-o",
    },
  ],
};
