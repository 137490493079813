import React, { useEffect, useState, useRef } from "react";
import {
  InputGroup,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

import "./CancelAccount.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkyLight from "react-skylight";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, temporaryUpdate } from "../../redux/reducers/user";

var divStyle = {
  marginLeft: "10%",
};

const CancelAccount = () => {
  const dispatch = useDispatch();
  const { userType, id } = useSelector((state) => state.auth.user);
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const cancelAccountTranslation =
    translationState?.[0]?.translations?.cancelAccount?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const customerTranslation =
    translationState?.[0]?.translations?.customer?.[userLanguage];
  const [dateState, setDateState] = useState({ resumeDate: "", userId: id });
  const [reviewState, setReviewState] = useState({ review: "", userId: id });
  const [inputValue, setInputValue] = useState("");
  const permanentDeleteModal = useRef(null);

  const temporaryDeleteModal = useRef(null);
  const myClaimDialog = {
    minwidth: "40%",
    minHeight: "20%",
  };

  const permanentDelete = () => {
    permanentDeleteModal.current.show();
  };
  const temporaryDelete = () => {
    temporaryDeleteModal.current.show();
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setDateState({ [name]: value, userId: id });
  };
  const onSubmitDate = () => {
    dispatch(temporaryUpdate(dateState)).then((response) => {
      if (response.payload.success) {
        localStorage.clear();
        window.location.reload();
        temporaryDeleteModal.current.hide();
        toast.success(
          `${
            toastTranslation?.success
              ? toastTranslation?.success
              : "User Updated Successfully"
          }`
        );
        return;
      } else {
        temporaryDeleteModal.current.hide();
        toast.error(
          `${
            toastTranslation?.lowBlance
              ? toastTranslation?.lowBlance
              : "First Pay your Loan"
          }`
        );
      }
    });
  };

  const handleHide = () => {
    permanentDeleteModal.current.hide();
  };
  //user Delete Form
  const handleInputForDelete = (e) => {
    setInputValue(e.target.value);
    setReviewState({ review: inputValue, userId: id });
  };
  const handleSubmitForDelete = (e) => {
    e.preventDefault();
    dispatch(deleteUser(reviewState)).then((response) => {
      if (response.payload.success) {
        localStorage.clear();
        window.location.reload();
        permanentDeleteModal.current.hide();
        toast.success(
          `${
            toastTranslation?.success
              ? toastTranslation?.success
              : "User Updated Successfully"
          }`
        );
        return;
      } else {
        permanentDeleteModal.current.hide();
        toast.error(
          `${
            toastTranslation?.lowBlance
              ? toastTranslation?.lowBlance
              : "First Pay your Loan"
          }`
        );
      }
    });
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myClaimDialog}
        hideOnOverlayClicked
        ref={permanentDeleteModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>
                {cancelAccountTranslation?.permanentButton
                  ? cancelAccountTranslation?.permanentButton
                  : "Permanently Account Deleted"}
              </h4>
            </b>
          </p>
        }
      >
        <div>
          <div className="top-delete-message">
            <small>
              {cancelAccountTranslation?.perParaLine
                ? cancelAccountTranslation?.perParaLine
                : `Your decision to leave YINN family breaks our heart but before you
              go , please let us know the reason you are leaving. Please be 100%
              honest, we will use your feedback to improve. `}
              <b>
                {cancelAccountTranslation?.perBoldLine
                  ? cancelAccountTranslation?.perBoldLine
                  : `If you press YES then your account will be permanently DELETED`}
              </b>
            </small>
          </div>
          <div style={{ margin: "10px" }}>
            <form onSubmit={handleSubmitForDelete}>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputForDelete}
                placeholder={
                  cancelAccountTranslation?.feedback
                    ? cancelAccountTranslation?.feedback
                    : "Feedback..."
                }
              />

              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  type="submit"
                  // onClick={handlePermanentDelete}
                  color="warning"
                  style={divStyle}
                  btn="sm"
                >
                  <b className="mt-4">
                    {customerTranslation?.yes
                      ? customerTranslation?.yes
                      : "YES"}
                  </b>
                </Button>

                <Button
                  onClick={handleHide}
                  color="warning"
                  style={divStyle}
                  btn="sm"
                >
                  <b className="mt-4">
                    {customerTranslation?.no ? customerTranslation?.no : "NO"}
                  </b>
                </Button>
              </Row>
            </form>
          </div>
        </div>
      </SkyLight>

      <SkyLight
        dialogStyles={myClaimDialog}
        hideOnOverlayClicked
        ref={temporaryDeleteModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>
                {cancelAccountTranslation?.temporaryButtone
                  ? cancelAccountTranslation?.temporaryButtone
                  : "Temporary Account Deactive"}
              </h4>
            </b>
          </p>
        }
      >
        <div>
          <FormGroup>
            <Label for="expiryDate">
              {cancelAccountTranslation?.date
                ? cancelAccountTranslation?.date
                : "Resume Date"}
            </Label>
            <Input
              id="accountResumeId"
              name="accountResumeDate"
              type="date"
              //   min={new Date().toISOString()}
              value={dateState.resumeDate}
              onChange={handleInputChange}
            />
          </FormGroup>
          <Row>
            <Button
              onClick={onSubmitDate}
              color="warning"
              btn="sm"
              className="-ml-5"
            >
              <b>
                {cancelAccountTranslation?.send
                  ? cancelAccountTranslation?.send
                  : "Send"}
              </b>
            </Button>
          </Row>
        </div>
      </SkyLight>

      <div style={{ margin: "10px", align: "center" }}>
        <div className="top-delete-message">
          <small>
            <b>
              {cancelAccountTranslation?.permanentButton
                ? cancelAccountTranslation?.permanentButton
                : "Permanently"}
            </b>
            {cancelAccountTranslation?.permanentPara
              ? cancelAccountTranslation?.permanentPara
              : `Your account will be permanently deleted.
          All your data and information associated with the account will be removed from our system.`}
            <br />
            <b>
              {cancelAccountTranslation?.temporaryButtone
                ? cancelAccountTranslation?.temporaryButtone
                : "Temporary"}
            </b>
            {cancelAccountTranslation?.tempraryPara
              ? cancelAccountTranslation?.tempraryPara
              : `Your account will remain inactive for the next selected days. During this period, you won't be able to access your account.`}
          </small>
        </div>
        <Col className="padding-remove">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="9">
                  <b>
                    {cancelAccountTranslation?.cancelAccount
                      ? cancelAccountTranslation?.cancelAccount
                      : "Cancel Account"}
                  </b>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              {(userType == "seller" || userType == "user") && (
                <Row>
                  <Col xs="6" sm="6" className="padding-remove">
                    <InputGroup>
                      <Button
                        color="danger"
                        style={divStyle}
                        btn="sm"
                        onClick={permanentDelete}
                      >
                        <b>
                          {cancelAccountTranslation?.permanentButton
                            ? cancelAccountTranslation?.permanentButton
                            : "Permanently Delete"}
                        </b>
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col xs="6" sm="6" className="padding-remove">
                    <InputGroup className={"justify"}>
                      <Button
                        color="warning"
                        btn="sm"
                        onClick={temporaryDelete}
                      >
                        <b>
                          {cancelAccountTranslation?.temporaryButtone
                            ? cancelAccountTranslation?.temporaryButtone
                            : "Temporary Delete"}
                        </b>
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <br />
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};
export default CancelAccount;
