import React, {useEffect, useState} from 'react';
import Env from '../../environment/environment';
import './MyOrders.css';
import cookie from 'react-cookies';
import axios from "axios";
import {
    AppFooter,
    AppHeader
  } from '@coreui/react';
import {Row} from 'reactstrap';
import DefaultHeader from '../Header/DefaultHeader';
import orderPlacedSrc from '../../assets/img/my-orders/order-placed.svg';
import orderPlacedActiveSrc from '../../assets/img/my-orders/order-placed-active.svg';
import preparingSrc from '../../assets/img/my-orders/preparing.svg';
import preparingActiveSrc from '../../assets/img/my-orders/preparing-active.svg';
import shippingSrc from '../../assets/img/my-orders/shipping.svg';
import shippingActiveSrc from '../../assets/img/my-orders/shipping-active.svg';
import deliveredSrc from '../../assets/img/my-orders/delivered.svg';
import deliveredActiveSrc from '../../assets/img/my-orders/delivered-active.svg';
import invoiceSrc from "../../assets/img/my-orders/invoice.svg";
import { CurrencyContext, convertCurrency } from '../../contexts/currency-context'
import { useSelector } from 'react-redux';

const MyOrders = () => {

    const [orders, setOrders] = useState({});

    useEffect(() => {
        axios.get(Env.url + '/orders/get/customer/' + cookie.load('_id'), {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('auth_token')
            }
          }).then(res => {
            setOrders(res.data);
            console.log(res.data);
          });
    }, []);

    const IterateMyOrderItemContainer = () => {
        const myorders = []
        for (let i = 0; i < orders.length; i++) {
           myorders.push(
            <MyOrdersItemContainer 
                itemBrand={orders[i].orderdetails[0]?orders[i].orderdetails[0].brand:null}
                itemName={orders[i].product[0].product_name}
                itemImage={orders[i].product[0].images[0]}   
                itemShippingAdd={orders[i].shipping_address?orders[i].shipping_address.country:null}
                itemQuantity={orders[i].product[0].quantity}                                                                               
                itemTotalPrice={orders[i].total}
                orderStatus={orders[i].product[0].order_status}
                orderId={orders[i]._id}
            />
            );       
        }
        return myorders;
    }

    return(
        <div>
            <Row>
            <AppHeader fixed>
                <DefaultHeader />
            </AppHeader>
            </Row>
            <div className = "myorders-background">
                <div className="myorders-heading">My Orders</div>
                <div className="myorders-divideline"></div>
                {/* <IterateMyOrderItemContainer/> */}
                {IterateMyOrderItemContainer()}
            </div>
        </div>
    );
}

const MyOrdersItemContainer = (props) => {
        let a = props.itemImage
        return (
            <CurrencyContext.Consumer>
            {({changeCurrency, currency}) => (
            <div className="myorders-containers">
                <div className="full-container">
                    <div style={{display:'flex'}}>
                        <img src={props.itemImage} className="myorders-item-image"/>                                                      
                        <div className="myorders-item-description">
                        <div className="myorders-item-name">{props.itemName}
                        </div>
                            <div className="myorders-item-brand">{props.itemBrand}</div>
                            <div className="myorders-item-country">From {props.itemShippingAdd}</div>
                            <div className="myorders-item-quantity">
                                <p>Quantity : <span style={{ color: "#BFC9CA"}}>{props.itemQuantity}</span></p>
                            </div>
                            <div className="myorders-item-price">
                                <p>
                                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Total</span>
                                    <div className="myorders-total-price">
                                        <div className="myorders-currency">{currency}</div> 
                                        <div className="myorders-total-price-number">
                                            {( convertCurrency(Number(props.itemTotalPrice), currency) ).toFixed(2)}
                                        </div>
                                    </div>                                    
                                </p>
                            </div>
                        </div>
                    </div> 
                    <Progress orderStatus={props.orderStatus} orderId={props.orderId}/>
                </div>                         
            </div>
            )}
            </CurrencyContext.Consumer>
        );
}

const Progress = (props) => {

    const { translationState } = useSelector((state) => state.user);
    const {userLanguage} = useSelector((state) => state.auth);
    const confirmationTranslation = translationState?.[0]?.translations?.confirmation?.[userLanguage];
    const orderStatus = ["Ordered", "Preparing", "Shipping", "Delivered"]

    let step = orderStatus.indexOf(props.orderStatus)
    if(props.orderStatus==="inProgress"){
        step = 1;
    }

    const viewInvoice = () => {
        console.log(props.orderId)
        cookie.save('order_id', props.orderId, { path: '/' });
        window.location = "#/invoice";
    }

    return(
        <div className="myorders-progress">
            <div className="myorders-progress-step">
                <div className="myorders-progress-status-text">Order Placed</div>
                <img src={step>=0?orderPlacedActiveSrc:orderPlacedSrc}/>
            </div>
            <div className="myorders-progress-line"></div>

            <div className="myorders-progress-step">
                <div className="myorders-progress-status-text">
                {confirmationTranslation?.perparing ? confirmationTranslation?.perparing : "Preparing"}</div>
                <img src={step>=1?preparingActiveSrc:preparingSrc}/>
            </div>
            <div className="myorders-progress-line"></div>

            <div className="myorders-progress-step">
                <div className="myorders-progress-status-text">
                {confirmationTranslation?.shipping ? confirmationTranslation?.shipping : "Shipping"}</div>
                <img src={step>=2?shippingActiveSrc:shippingSrc}/>
            </div>
            <div className="myorders-progress-line"></div>

            <div className="myorders-progress-step">
                <div className="myorders-progress-status-text">
                {confirmationTranslation?.delivered ? confirmationTranslation?.delivered : "Delivered"}</div>
                <img src={step>=3?deliveredActiveSrc:deliveredSrc}/>
            </div>
            
            <div className="myorders-progress-view-reciept" onClick={viewInvoice}>
                <span style={{marginRight:10}}>View reciept</span>
                <img src={invoiceSrc}/>
            </div>
        </div>
    )
}

export default MyOrders;