import React, { useEffect, useState } from "react";
import "./BookingDate.css";
import { useDispatch, useSelector } from "react-redux";


const DateRangePicker = ({ setStartDate, setEndDate, validStartDate, validEndDate }) => {

const { translationState , allWebContent} = useSelector((state) => state.user)
const {userLanguage} = useSelector((state) => state.auth);
// const productDetailContent = allWebContent?.[0]?.productDetail;
const productDetailTranslation = translationState?.[0]?.translations?.productDetail?.[userLanguage]

  return (
    <div class="bookingdate-page">
      <div class="all-container">
        <div class="startdate-container">
          <div class="startdate-title">
          {productDetailTranslation?.startDate ? productDetailTranslation?.startDate : "Renting Start Date" } :</div>
          <div class="startdate-setdate">
            <input
              type="date"
              id="startdate"
              name="startdateset"
              min={new Date(validStartDate).toISOString().split("T")[0]}
              max={new Date(validEndDate).toISOString().split("T")[0]}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
        </div>

        <div class="date-divide-line"></div>

        <div class="enddate-container">
          <div class="enddate-title">{productDetailTranslation?.endDate ? productDetailTranslation?.endDate : "Renting End Date" } :</div>
          <div class="enddate-setdate">
            <input
              type="date"
              id="enddate"
              min={new Date(validStartDate).toISOString().split("T")[0]}
              max={new Date(validEndDate).toISOString().split("T")[0]}
              name="enddateset"
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
