import React, { useEffect } from "react";
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
import adminMenu from "./menus/admin";
import sellerMenu from "./menus/seller";
import userMenu from "./menus/user";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import "./sidebar.css";
import "react-toastify/dist/ReactToastify.css";

const mainStyle = {
  backgroundColor: "#F6F6F6",
};

const Layout = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, user, userLanguage } = useSelector((state) => state.auth);
  const { translationState  } = useSelector((state) => state.user);
  const sellerTranslation = translationState?.[0]?.translations?.seller?.[userLanguage];
  const getMenus = () => {
    const updatedSellerMenu = { ...sellerMenu }; 
    
    updatedSellerMenu.items = updatedSellerMenu.items.map((item) => {
      // .replace(/_/g, ' ')
      //  item.name.split('_').join(' ')
      return {
        ...item,
        name: `${sellerTranslation?.[item.name] ? sellerTranslation[item.name] : item.name.replace(/_/g, ' ') } `, 
      };
    });
    return (
      <div>
        {user.userType == "admin" ? (
          <AppSidebarNav style={{ marginTop: "20px" }} navConfig={adminMenu} />
        ) : user.userType == "user" ? (
          <AppSidebarNav style={{ marginTop: "20px" }} navConfig={userMenu} />
        ) : (
          <AppSidebarNav style={{ marginTop: "20px" }} navConfig={updatedSellerMenu} />
        )}
      </div>
    );
  };
  return (
    <div className="app">
      <ToastContainer autoClose={20000} />
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div className="app-body" style={{ marginTop: "75px", }}>
        {/* <AppSidebar fixed display="lg" style={{ overflow: scroll }}>
          {getMenus()}
          <AppSidebarMinimizer />
        </AppSidebar> */}
        <AppSidebar 
  fixed 
  display="lg" 
  style={{ 
    overflow: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(255, 255, 255, 0.5) transparent',
    // Webkit browsers (Chrome, Safari)
    '::-webkit-scrollbar': {
      width: '8px'
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: '20px',
      border: 'transparent',
      marginLeft:'-10px'
    }
  }}
>
  {getMenus()}
  <AppSidebarMinimizer />
</AppSidebar>
        <main className="main" style={mainStyle}>
          {children}
        </main>
      </div>
      <AppFooter>
        <DefaultFooter />
      </AppFooter>
    </div>
  );
};
export default Layout;
