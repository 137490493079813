import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SetState,
  personalInfo,
  deleteSiteInfo,
} from "../../redux/reducers/admin";

const PersonalInfo = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { personalinfo, loading } = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(personalInfo());
  }, []);
  let state = {
    charges: [],
    chargeValid: false,
  };
  const deleteCharge = () => {
    dispatch(deleteSiteInfo(deleteId));
    setDeleteId(null);
    setIsDeleteModal(false);
    dispatch(personalInfo());
  };

  const deleteConfirm = (id) => {
    setDeleteId(id);
    setIsDeleteModal(true);
  };
  const hideModal = () => {
    setDeleteId(null);
    setIsDeleteModal(false);
  };

  const editCharge = (id) => {
    history.push(`/admin/personalInfo/edit?chargeId=${id}`);
  };
  return (
    <div className="animated fadeIn">
      <Card className="mt-5 mx-2">
        <CardHeader>
          <Row>
            <div
              className="d-flex justify-content-between  align-items-center"
              style={{ width: "100%" }}
            >
              <div>
                <i className="fa fa-align-justify"></i>{" "}
                <strong>Site Info List</strong>
              </div>
              <div>
                <Button
                  onClick={() => history.push("/admin/personalInfo/add")}
                  disabled={personalinfo.length > 0}
                  size="md"
                  color="warning"
                >
                  <b> Add Site Info </b>
                </Button>
              </div>
            </div>
          </Row>
        </CardHeader>

        <CardBody>
          <Table hover bordered striped responsive size="sm">
            <thead align="center">
              <tr className="header-color" align="center">
                <th> Primary Contact </th>
                <th> Secondary Contact </th>
                <th> Primary Email </th>
                <th> Secondary Email </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <td colSpan={5}>
                  <div style={{ textAlign: "center" }}>
                    <i
                      className="fas fa-spinner fa-pulse fa-2x"
                      style={{ color: "green", margin: "80px" }}
                    ></i>
                  </div>
                </td>
              ) : personalinfo.length > 0 && !loading ? (
                personalinfo.map((charge, index) => (
                  <tr>
                    <td align="center"> {charge.contact1}</td>
                    <td align="center"> {charge.contact2 || "NA"}</td>
                    <td align="center"> {charge.email1}</td>
                    <td align="center"> {charge.email2 || "NA"}</td>
                    <td align="center">
                      <i
                        className="fa fa-edit fa-lg mt-4"
                        onClick={(e) => editCharge(charge._id)}
                      ></i>
                      &nbsp;
                      <i
                        className="fa fa-trash fa-lg mt-4"
                        onClick={(e) => deleteConfirm(charge._id)}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <td colSpan={5} className="py-5 text-center">
                  <p style={{ color: "red" }}>Records Not Found</p>
                </td>
              )}
            </tbody>

            <Modal
              isOpen={isDeleteModal}
              className={"modal-danger " + props.className}
            >
              <ModalHeader>Delete Site Info</ModalHeader>
              <ModalBody>
                <b> Do you really want to delete Site Info? </b>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={(e) => deleteCharge()}>
                  Yes
                </Button>{" "}
                <Button color="secondary" onClick={(e) => hideModal()}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default PersonalInfo;
