import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Card, CardBody,FormGroup, Label, CardGroup, Col, Container, Input, InputGroup, CardFooter, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import logo from '../../../assets/img/brand/logo.png';
import Env from '../../../environment/environment';
import axios from 'axios';
import cookie from 'react-cookies';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import DefaultFooter from '../../Footer/DefaultFooter';
import DefaultHeader from '../../Header/DefaultHeader';

import {
  AppHeader,
} from '@coreui/react';

const ContentManagement = (props) => {
  let state = {
    categories:[],
    pages:[],
    title :"",
    selectedPage:{},
    searchString :props.location.search.replace("%20" , " "),
    // searchString :"",
  }
  // constructor(props) {
  //   super(props);
  // }

  const componentWillMount = () => {
    
    axios.get(Env.url+'/pages/search'+state.searchString,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('auth_token')
      }
    }).then(function (response){
        if(response.status == 200){
      //setState({ selectedPage:  response.data[0]});
      console.log(state.selectedPage,'selectedPage')
      }
      }).catch(error => {
          console.log(error);
      });
}

    return (
      <div style={{ "background-color": "white"  }}>
      {/* <Row className="col-12 footer-part"  >
                    <Col xs="12" sm="1" ></Col>
                    {state.categories.length > 0 ? state.categories.map((category,i)=>(
                    <Col xs="12" sm="2" className="text-color" ><br/><br/><br/><br/>
                        <h3><Label><b>{ category.category_name }</b></Label></h3>
                        {state.pages.length > 0 ? state.pages.map((page,index)=>(
                    <Col xs="12" sm="2" className="text-color" >
                    <h6><a href = "#" className = "footer-contents" hidden ={category.category_name != page.category_name } onClick = {(e) => contentSearch(page.title,e)}>{ page.title }</a></h6>
                    </Col>)):''}
                    </Col>)):''}	
              </Row> */}
        <Row>
          <AppHeader fixed>
           <DefaultHeader />
          </AppHeader>
        </Row><br /><br /><br />
        <Row>
          <Col>
            <Card>
              <CardBody className="card-body">
                      <div className="ellipsis" > {ReactHtmlParser(state.selectedPage.content)}</div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div  >
          <DefaultFooter />
        </div>
        </div>
    );
}

export default ContentManagement;
