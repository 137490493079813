import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrder } from "../../redux/reducers/seller";
import SkyLight from "react-skylight";
import { saveDispute } from "../../redux/reducers/auth";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

const OrderView = () => {
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const { id } = useParams();
  const modal = useRef(null);
  const { singleOrder, loading } = useSelector((state) => state.seller);
  const [pageContent, setPageContent] = useState({
    customerName: "",
    orderId: "",
    productId: "",
    detailMessage: "",
  });
  useEffect(() => {
    dispatch(getSingleOrder(id));
  }, [dispatch]);

  const HandleDate = (date) => {
    var d = new Date(date);
    return d.toLocaleString();
  };

  const onDisputeOpen = () => {
    setPageContent({
      customerName: singleOrder?.userId?.firstname,
      orderId: singleOrder?._id,
      productId: singleOrder?.products?.[0]?.product?._id,
      detailMessage: "Dispute created by user side",
    });
    modal.current.show();
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const save = () => {
    const { orderId, productId } = pageContent;
    if (productId == "" && orderId == "") {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Product Id OR Order Id is required"
        }`
      );
      return;
    }
    dispatch(saveDispute(pageContent)).then(() => {
      modal.current.hide();
      toast.error(
        `${
          toastTranslation?.success
            ? toastTranslation?.success
            : "Dispute sent successfully"
        }`
      );
      history.push(`customer-account/account-details`);
    });
  };
  var myBigGreenDialog = {
    minWidth: "40%",
    minHeight: "20%",
  };

  return (
    <>
      {loading ? (
        <div className="order-view-loader">
          <i
            hidden={!loading}
            className="mx-auto fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green", float: "right" }}
          ></i>
        </div>
      ) : (
        <>
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={modal}
            title={
              <div className="d-none d-md-block mt-3">
                <div className="card">
                  <div className="card-block clearfix">
                    <i className="fa fa-flag bg-warning p-1 font-4xl mr-1 float-left"></i>
                    <div className="h2 text-warning mb-0 mt-h">
                      Report a Dispute
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            <div>
              <FormGroup>
                <div className="main-content">
                  <FormGroup>
                    <Label>Customer Name......</Label>
                    <div className="controls">
                      <InputGroup className="input-prepend">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          size="16"
                          type="text"
                          name="customerName"
                          value={pageContent.customerName}
                          onChange={handleInputChange}
                          disabled
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Order Id</Label>
                    <div className="controls">
                      <InputGroup className="input-prepend">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          size="16"
                          type="text"
                          name="orderId"
                          value={pageContent.orderId}
                          onChange={handleInputChange}
                          disabled
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Product Id</Label>
                    <div className="controls">
                      <InputGroup className="input-prepend">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          size="16"
                          type="text"
                          name="productId"
                          value={pageContent.productId}
                          onChange={handleInputChange}
                          disabled
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Detail Message</Label>
                    <div className="controls">
                      <InputGroup className="input-prepend">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          size="16"
                          type="textarea"
                          name="detailMessage"
                          value={pageContent.detailMessage}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>

                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      columnGap: "12px",
                    }}
                  >
                    <Button
                      type="submit"
                      size="md"
                      color="warning"
                      onClick={save}
                    >
                      Save
                    </Button>
                    <Button
                      type="submit"
                      size="md"
                      onClick={() => history.goBack()}
                      color="danger"
                    >
                      Cancel
                    </Button>
                  </Row>
                </div>
              </FormGroup>
            </div>
          </SkyLight>
          {singleOrder && (
            <div className="main-row">
              <p className="order-id-text">
                Order <span className="view-span">#{singleOrder?._id}</span> was
                placed on{" "}
                <span className="view-span">
                  {HandleDate(singleOrder?.orderDate)}
                </span>{" "}
                and is currently{" "}
                <span className="view-span">{singleOrder?.orderStatus}</span>.
              </p>
              <h2 className="order-view-text ">Order details</h2>
              {singleOrder?.products?.length &&
                singleOrder?.products?.map((myproduct, index) => {
                  return (
                    <div className="div-style" key={index}>
                      <div
                        style={{ borderBottom: "0.5px solid grey" }}
                        className="row my-3"
                      >
                        <div className="col-6">
                          <b>Product</b>
                        </div>
                        <div>
                          <b>Total</b>
                        </div>
                      </div>
                      <div
                        style={{ borderBottom: "0.5px solid grey" }}
                        className="row my-3"
                      >
                        {" "}
                        <div className="col-6 order-product-text">
                          <span className="">{myproduct.product?.name}</span>
                          <b className="mx-1">× {myproduct.qty}</b>
                          <br /> Vendor:<span>Authentic Corner</span>
                        </div>
                        <div>${myproduct?.unitPrice}</div>
                      </div>
                      <div
                        style={{ borderBottom: "0.5px solid grey" }}
                        className="row my-3"
                      >
                        {" "}
                        <div className="col-6">
                          <b>Subtotal:</b>
                        </div>
                        <div>
                          <b className="total-td-text">
                            ${myproduct?.unitPrice * myproduct?.qty}
                          </b>
                        </div>
                      </div>
                      <div
                        style={{ borderBottom: "0.5px solid grey" }}
                        className="row my-3"
                      >
                        {" "}
                        <div className="col-6">
                          <b>Payment method:</b>
                        </div>
                        <div>
                          <b className="total-td-text">
                            {singleOrder?.paymentMethod}
                          </b>
                        </div>
                      </div>
                      <div
                        className="row my-3"
                        style={{ borderBottom: "0.5px solid grey" }}
                      >
                        {" "}
                        <div className="col-6">
                          <b>Total:</b>
                        </div>
                        <div>
                          <b className="total-td-text">${singleOrder?.total}</b>
                        </div>
                      </div>
                      {singleOrder?.products ? (
                        <div className="row">
                          {" "}
                          <div className="col-6">
                            <b>Event Token Id:</b>
                          </div>
                          <div>
                            <b>{singleOrder?.orderEventTokenId}</b>
                          </div>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                  );
                })}

              <div className="my-3">
                {singleOrder?.disputeId === null && (
                  <button
                    className="button-color"
                    onClick={onDisputeOpen}
                    style={{ borderRadius: "3px" }}
                  >
                    Open dispute
                  </button>
                )}
              </div>
              <div>
                <h2 className="order-view-text">Shipping Address</h2>
              </div>
              {singleOrder?.shippingAddress && (
                <div className="div-style p-3">
                  <b>First Name</b> : {singleOrder?.shippingAddress?.firstname}
                  <br />
                  <b>Last Name</b> : {singleOrder?.shippingAddress?.lastname}
                  <br />
                  <b>AddressLineOne</b> :{" "}
                  {singleOrder?.shippingAddress?.addressLineOne}
                  <br />
                  <b>AddressLineTwo</b> :{" "}
                  {singleOrder?.shippingAddress?.addressLineTwo}
                  <br />
                  <b>ZipCode</b> : {singleOrder?.shippingAddress?.zipCode}
                  <br />
                  <b>Country</b> : {singleOrder?.shippingAddress?.country}
                  <br />
                  <b>State</b> : {singleOrder?.shippingAddress?.state}
                  <br />
                  <b>City</b> : {singleOrder?.shippingAddress?.city}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OrderView;
