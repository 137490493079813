import React, { Component, useEffect } from "react";
import {
  Button,
  Form,
  Card,
  CardBody,
  Label,
  CardHeader,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormGroup,
} from "reactstrap";
import { render } from "react-dom";
import Moment from "react-moment";
import "./ContactUS.css";
import logo from "../../../assets/img/brand/logo.png";
import cookie from "react-cookies";
import axios from "axios";
import Env from "../../../environment/environment";
import { ToastContainer, toast } from "react-toastify";
import SkyLight, { SkyLightStateless } from "react-skylight";
import { saveComplaint } from "./ContactUsService.js";
import { AppFooter, AppHeader } from "@coreui/react";

import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import { useSelector , useDispatch } from "react-redux";

const ContactUs = () => {
  const dispatch = useDispatch();
  const {translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];

  let state = {
    orders: [],
    total: 0,
    newSalesPrice: [],
    complaintObject: {
      order_id: "",
      description: "",
      user_id: "",
      status: "",
      seller_id: "",
      product_id: "",
    },
  };

  const componentWillMount = () => {
    axios
      .get(Env.url + "/orders/get/customer/" + cookie.load("_id"), {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        //setState({ orders: response.data });
        //setState({ orderdetails: response.data[0].orderdetails });
        console.log(state.orders);

        var newSalesPrice = [];
        for (var i in state.orders) {
          var sp;
          for (var c in state.orders[i].orderdetails) {
            if (state.orders[i].orderdetails[c].discountCode != undefined) {
              var discountPrice =
                (state.orders[i].orderdetails[c].discountPercentage *
                  state.orders[i].orderdetails[c].sales_price) /
                100;
              sp = state.orders[i].orderdetails[c].sales_price - discountPrice;
              newSalesPrice.push(sp);
              state.newSalesPrice = newSalesPrice;
              //setState({newSalesPrice : state.newSalesPrice });
              console.log(state.newSalesPrice, "new sp");
            }
          }
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 ||
            err.response.status == 400 ||
            err.response.status == 500
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const goToProduct = () => {
    window.location = "#/productdetail";
  };

  const attributeName = (evt) => {
    ////setState({ attribute_name: evt.target.value })
  };

  const attributeValue = (evt) => {
    var complaintObject = state.complaintObject;
    complaintObject.description = evt.target.value;
    //setState({complaintObject : complaintObject})
  };

  const attributeSave = async (evt) => {
    var data = state.complaintObject;
    if (data.description == "") {
      toast.error(`${toastTranslation?.requried ? toastTranslation?.requried : "Please fill the description box"}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let result = await saveComplaint(data);
      // //simpleDialog.hide()
    }
  };

  const deleteAttribute_val = (evt) => {};

  const openComplaintForm = (index, indx, evt) => {
    var orders = state.orders;
    var order = orders[index];
    var complaintObject = state.complaintObject;
    complaintObject.description = "";
    complaintObject.order_id = order._id;
    complaintObject.user_id = cookie.load("_id");
    complaintObject.seller_id = order.orderdetails[indx].seller_id;
    complaintObject.status = "new";
    complaintObject.product_id = order.orderdetails[indx]._id;
    //setState({complaintObject : complaintObject})
    state.attribute_title = "Send us a complaint";
    //setState({ attribute_name: '' })
    //setState({ attribute_value: '' })
    //setState({ show_attr_del_modal: true })
    //setState({ show_attr_modal: false })
    // //simpleDialog.show()
  };
  var myBigGreenDialog = {
    width: "40%",
    minHeight: "20%",
  };
  return (
    <div className="app" style={{ "background-color": "white" }}>
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        // ref={ref => simpleDialog = ref}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>{state.attribute_title}</h4>
            </b>
          </p>
        }
      >
        <div hidden={state.show_attr_modal}>
          <FormGroup>
            <Label> Order id</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  value={state.complaintObject.order_id}
                  size="16"
                  type="text"
                  disabled
                />
              </InputGroup>
            </div>
          </FormGroup>

          <FormGroup>
            <Label> Complaint description</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  placeholder="Description"
                  value={state.complaintObject.description}
                  onChange={attributeValue}
                  size="16"
                  type="textarea"
                />
              </InputGroup>
            </div>
          </FormGroup>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              onClick={attributeSave}
              size="md"
              color="warning"
            >
              {" "}
              Save{" "}
            </Button>
            <Col xs="12" sm="1"></Col>
            <Button
              type="submit"
              size="md"
              // onClick={() => //simpleDialog.hide()}
              color="danger"
            >
              {" "}
              Cancel{" "}
            </Button>
          </Row>
        </div>
        <div hidden={state.show_attr_del_modal}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              onClick={deleteAttribute_val}
              size="md"
              color="warning"
            >
              {" "}
              Yes{" "}
            </Button>
            <Col xs="12" sm="1"></Col>
            <Button
              type="submit"
              size="md"
              // onClick={() => //simpleDialog.hide()}
              color="danger"
            >
              {" "}
              No{" "}
            </Button>
          </Row>
        </div>
      </SkyLight>
      <Row>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </Row>
      <br />
      <br />
      <br />
      <br />

      <div className="container">
        <h5>
          <b>My Orders</b>
        </h5>
        {state.orders.length > 0
          ? state.orders.map((order, index) => (
              <Card>
                <CardHeader>
                  <h5>
                    <i>
                      <b>{order._id}</b>
                    </i>
                  </h5>
                </CardHeader>
                <CardBody>
                  <div>
                    {order.orderdetails.length > 0
                      ? order.orderdetails.map((orderdetails, i) => (
                          <Row>
                            <Col xs="12" sm="2" md="2">
                              <div>
                                <img
                                  style={{
                                    maxHeight: "150px",
                                    maxWidth: "150px",
                                    height: "auto",
                                    width: "auto",
                                    marginTop: "5px",
                                  }}
                                  src={orderdetails.images[0]}
                                  class="imageB"
                                  onClick={(e) => goToProduct(e)}
                                />
                                <br />
                                <br />
                              </div>
                            </Col>
                            <Col xs="12" sm="4" md="4">
                              <Row>
                                <Label>
                                  <b></b>
                                </Label>
                              </Row>
                              <Row>
                                <Label>
                                  <b></b>
                                </Label>
                              </Row>
                              <Label>
                                <i>
                                  <b>{orderdetails.name}</b>
                                </i>
                              </Label>
                              {order.product.length > 0
                                ? order.product.map((product, indx) => (
                                    <Row
                                      hidden={
                                        product.product_id != orderdetails._id
                                      }
                                    >
                                      <Label>Quantity:</Label>
                                      <h5>
                                        <i>{product.quantity}</i>
                                      </h5>
                                    </Row>
                                  ))
                                : ""}
                            </Col>
                            <Col xs="12" sm="1" md="2">
                              <Row>
                                <Label>
                                  <b></b>
                                </Label>
                              </Row>
                              <Row>
                                <Label>
                                  <b></b>
                                </Label>
                              </Row>
                              <Row>
                                <Label
                                  hidden={
                                    orderdetails.discountCode != undefined
                                  }
                                >
                                  <i className="fa fa-inr"></i>
                                  {orderdetails.sales_price}
                                </Label>
                                <Label
                                  hidden={
                                    orderdetails.discountCode === undefined
                                  }
                                >
                                  <i className="fa fa-inr"></i>
                                  {state.newSalesPrice[index]}
                                </Label>
                              </Row>
                            </Col>
                            <Col xs="12" sm="4" md="4">
                              <Row>
                                <Label>
                                  <b></b>
                                </Label>
                              </Row>
                              <Row>
                                <Label>
                                  <b></b>
                                </Label>
                              </Row>
                              <Row>
                                <Label>
                                  <b>Status </b>
                                </Label>
                                &nbsp;&nbsp;
                                <Label> {order.order_status}</Label>
                                {/* <Moment format="dddd DD -MM-YYYY" >{order.delivery_date}</Moment> */}
                              </Row>
                              <Row hidden={order.order_status !== "delivered"}>
                                <Col
                                  col="6"
                                  sm="4"
                                  md="2"
                                  xl
                                  className="mb-3 mb-xl-0"
                                >
                                  <Button
                                    block
                                    color="warning"
                                    onClick={openComplaintForm.bind(
                                      this,
                                      index,
                                      i
                                    )}
                                  >
                                    Need help?
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                            {/* <Col xs="12" sm="1" md="1">
                      <Row>
                        <Label><b></b></Label>
                      </Row>
                      <Row>
                        <Label><b></b></Label>
                      </Row>
                      <Row>
                        <Button type="submit" size="md" color="warning" className="saveB" ><i className="fa fa-map-marker" aria-hidden="true"></i><b> Track</b> </Button>
                      </Row>
                    </Col> */}
                          </Row>
                        ))
                      : ""}
                    <CardFooter>
                      <Row>
                        <Col xs="12" sm="10" md="10">
                          <Label>Ordered On </Label>&nbsp;&nbsp;
                          <Moment format="dddd DD -MM-YYYY">
                            {order.order_place_date}
                          </Moment>
                        </Col>
                        <Col xs="12" sm="2" md="2">
                          <Label>Order Total</Label>&nbsp;
                          <i className="fa fa-inr"></i>
                          {order.total}
                        </Col>
                      </Row>
                    </CardFooter>
                  </div>
                </CardBody>
              </Card>
            ))
          : "You dont have Orders"}
        <br />
        <br />
        <br />
      </div>
      <Row>
        <div>
          <Row className="col-12 footer-part">
            <Col xs="12" sm="1"></Col>
            <Col xs="12" sm="2" className="text-color">
              <br />
              <br />
              <br />
              <br />
              <h3>
                <Label>
                  <b>COMPANY</b>
                </Label>
              </h3>
              <h6>
                <Label>About Us</Label>
              </h6>
              <h6>
                <Label>Team</Label>
              </h6>
              <h6>
                <Label>Blog</Label>
              </h6>
              <h6>
                <Label>Partners</Label>
              </h6>
              <h6>
                <Label>Support & FAQ</Label>
              </h6>
              <h6>
                <Label>Contact Us</Label>
              </h6>
              <h6>
                <Label>What to sell on YINN</Label>
              </h6>
            </Col>
            <Col xs="12" sm="2" className="text-color">
              <br />
              <br />
              <br />
              <br />
              <h3>
                <Label>
                  <b>SHOP</b>
                </Label>
              </h3>
              <h6>
                <Label>Pricing & Features</Label>
              </h6>
              <h6>
                <Label>Ads</Label>
              </h6>
            </Col>
            <Col xs="12" sm="2" className="text-color">
              <br />
              <br />
              <br />
              <br />
              <h3>
                <Label>
                  <b>RESOURCES</b>
                </Label>
              </h3>
              <h6>
                <Label>YINN System Status</Label>
              </h6>
              <h6>
                <Label>Privacy Policy</Label>
              </h6>
              <h6>
                <Label>Terms of Use</Label>
              </h6>
              <h6>
                <Label>YINN API for Developer</Label>
              </h6>
            </Col>
            <Col xs="12" sm="1">
              <img
                style={{ height: "300px", width: "1px", "margin-top": "55px" }}
                src="https://images.pexels.com/photos/972887/pexels-photo-972887.jpeg?auto=compress&cs=tinysrgb&h=650&w=940"
              />
            </Col>
            <Col xs="12" sm="3">
              <br />
              <br />
              <br />
              <Row>
                <img style={{ height: "80px" }} src={logo} />
              </Row>

              <Row>
                <h6>
                  <Label className="text-color">
                    Yinn.ca is the intersection where local small businesses in
                    innovation and creation meet with shoppers . We help you
                    find your favorite handmade and digital goods online and at
                    stores near you .
                  </Label>
                </h6>
              </Row>
              <br />

              <Row>
                <i
                  class="cui-social-facebook icons font-4xl d-block mt-4"
                  style={{ color: "gray" }}
                ></i>{" "}
                &nbsp;&nbsp;&nbsp;
                <i
                  class="cui-social-twitter icons font-4xl d-block mt-4"
                  style={{ color: "gray" }}
                ></i>
              </Row>
            </Col>
            <Col xs="12" sm="1">
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Col>
          </Row>
        </div>
      </Row>
      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

export default ContactUs;
