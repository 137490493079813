import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Label,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import "./termsAndConditions.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClaimCopyright } from "../../../redux/reducers/auth";
import { AppHeader } from "@coreui/react";
import SkyLight from "react-skylight";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTranslation } from "../../../redux/reducers/user";

const TermsAndConditions = () => {
  const dispatch = useDispatch();

  useEffect(() =>{
    dispatch(getTranslation())
  },[])
  const {translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const termAndConditionTranslation = translationState?.[0]?.translations?.termAndCondition?.[userLanguage];
  const termPartTwoTranslation = translationState?.[0]?.translations?.termPartTwo?.[userLanguage];
  const termPartThreeTranslation = translationState?.[0]?.translations?.termPartThree?.[userLanguage];
  const termPartFourTranslation = translationState?.[0]?.translations?.termPartFour?.[userLanguage];
  const termPartFiveTranslation = translationState?.[0]?.translations?.termPartFive?.[userLanguage];
  const copyRightModal = useRef(null);
  const copyrightTypes = [
    "has posted my copyrighted/trademarked work without my permission",
    "has violated my privacy by posting personal information, pictures, or other private data not in the public record",
    "has engaged in libel/slander/defamation against myself, my family, or my company",
    "has posted spam, pornography, or hate speech",
    "has made a false claim resulting in removal of my content",
  ];
  const [pageObject, setPageObject] = useState({
    name: "",
    email: "",
    address: "",
    contact: "",
    url: "",
    copyrights:
      "has posted my copyrighted/trademarked work without my permission",
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageObject({
      ...pageObject,
      [name]: value,
    });
  };
  const handleContactInfo = () => {
    const { name, email, address, contact, url } = pageObject;
    if (
      name == "" ||
      email == "" ||
      address == "" ||
      contact == "" ||
      url == ""
    ) {
      toast.error("All fields are required");
      return;
    }
    dispatch(ClaimCopyright(pageObject));
    toast.success("We have sent you email please check the details .");
    copyRightModal.current.hide();
  };
  var PopupDialog = {
    top: "43%",
    width: "50%",
    height: "520px",
    overflow: "scroll",
  };
  return (
    <div className="app" style={{ "background-color": "white" }}>
      <ToastContainer autoClose={2000} />
      <Row>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
      </Row>
      <div className="container-fluid mt-100">
        <div className="col-12 col-lg-12">
          <div className="card">
            <div className="card-block clearfix">
              <i className="fa fa-flag bg-warning p-1 font-4xl mr-1 float-left"></i>
              <div className="h2 text-warning mb-0 mt-h">
              {termAndConditionTranslation?.services ? termAndConditionTranslation?.services : "YINN TERMS OF SERVICES"}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Card>
            <CardBody>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termAndConditionTranslation?.introduction ? termAndConditionTranslation?.introduction : " Introduction"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termAndConditionTranslation?.introPara ? termAndConditionTranslation?.introPara :
                `Please read these Terms OF Service ( ‘’ Terms’’) carefully .
                They contain the legal terms and conditions that govern your use
                of services provided to you by YINN , including information,
                text, images , graphics, data or other materials (‘’ Content’’)
                and products and services provided through www.yinn.ca ,
                shop.yinn.ca and other YINN mobile applications as well as all
                elements , software , programs and code forming or incorporated
                in to www.yinn.ca ( the’’ service ‘’) . This service is operated
                by YINN GROUP Inc , A Federal Canadian private corporation( ‘’
                YINN’’) . YINN is also referred to in these Terms as ‘’ we ‘’ ,
                ‘’our’’, and ‘’us’’. By using our service , you agree to be
                bound by section I of these Terms ( ‘’ General Terms ‘’), which
                contains provisions applicable to all users of our service ,
                including visitors to the YINN website ( the ‘’site’’). If you
                choose to register as member of our service or purchase products
                from YINN shop, you will be asked to check a box indicating that
                you have read and agree to be bound by , the additional terms
                set forth in section II of these Terms ( ‘’Additional Terms’’).`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termAndConditionTranslation?.section ? termAndConditionTranslation?.section :
                "Section I : General Terms"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termAndConditionTranslation?.headingOne ? termAndConditionTranslation?.headingOne : "1. Availability"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termAndConditionTranslation?.paraOne ? termAndConditionTranslation?.paraOne : 
                `This service is provided by YINN on an ‘’ As Is ‘’ and ‘’ As
                AVAILABLE’’ basis and YINN reserves the right to modify, suspend
                or discontinue the service , in its sole discretion , at any
                time and without notice . You agree that YINN is and will not be
                liable to you for any modification, suspension or discontinuance
                of the service .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termAndConditionTranslation?.headingTwo ? termAndConditionTranslation?.headingTwo : "2. Privacy"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termAndConditionTranslation?.paraTwo ? termAndConditionTranslation?.paraTwo : 
                `YINN has a firm commitment to safeguarding your privacy . please
                review YINN Privacy policy . The terms of YINN ‘s privacy policy
                are incorporated into , and form a part of these Terms .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termAndConditionTranslation?.headingThree ? termAndConditionTranslation?.headingThree : "3. Trademarks"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termAndConditionTranslation?.paraThree ? termAndConditionTranslation?.paraThree : 
                `All brand , product and service names used in this Service which
                identify YINN or third parties and their products and Services
                are proprietary marks of YINN and or relevant third parties and
                their products and services are proprietary marks of YINN and or
                relevant third parties . Nothing in this service shall be deemed
                to confer on any person ,any license or right on the part of
                YINN or any third party with respect to any such image , logo or
                name .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termAndConditionTranslation?.headingFour ? termAndConditionTranslation?.headingFour : "4. Copyright"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termAndConditionTranslation?.paraFour ? termAndConditionTranslation?.paraFour : 
                `YINN is , unless otherwise stated , the owner of all copyright
                and data rights in the service and its contents. Individuals who
                have posted works to YINN are either the copyright owner or his
                or her agent or otherwise as permitted by law . You may not
                reproduce , distribute , publicly displays or perform , or
                prepare derivative works based on any of the content including
                any such works without the express , written consent of YINN or
                the appropriate owner of copyright in such works . YINN does not
                claim ownership rights in your works or other materials posted
                by you to YINN ( your content ) . You agree not to distribute
                any part of the Service other than your content in any medium
                other than as permitted in these Terms of Service or by use of
                functions on the service provided by us . You agree not to alter
                or modify any part of the Service unless expressly permitted to
                do so by us or by use of functions on the Service provided by us
                .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termAndConditionTranslation?.headingFive ? termAndConditionTranslation?.headingFive : "5. Reporting Copyright Violations"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termAndConditionTranslation?.paraFive ? termAndConditionTranslation?.paraFive : 
                `YINN respects the intellectual property rights of others and
                expects users of the Service to do the same . At YINN ‘s
                discretion and in appropriate circumstances , YINN may remove
                your content submitted to the site , terminate the accounts of
                users or prevent access to the site by users who infringe the
                intellectual property rights of others . If you believe the
                copyright in your work or in the work for which you act as an
                agent has been infringed through this service , please contact
                YINN ‘s agent for notice of claims of copyright infringement ,
                Olivier Todoe who can be reached through support@yinn.ca , you
                must provide our agent with substantially the following , which
                YINN may then forward to the alleged infringer :`}
                <ul>
                  <li>
                  {termAndConditionTranslation?.oneOfFive ? termAndConditionTranslation?.oneOfFive : 
                    `A physical or electronic signature of a person authorized to
                    act on behalf of the owner of an exclusive right that is
                    allegedly infringed`}
                  </li>
                  <li>
                  {termAndConditionTranslation?.twoOfFive ? termAndConditionTranslation?.twoOfFive : 
                   `Identification of the copyrighted work claimed to have been
                    infringed , or ,if multiple copyrighted works at a single
                    online site are covered by a single notification , a
                    representative list of such works at that site .`}
                  </li>
                  <li>
                  {termAndConditionTranslation?.threeOfFive ? termAndConditionTranslation?.threeOfFive :
                    `Identification of the material that is claimed to be
                    infringing or to be the subject of infringing activity and
                    that is to be removed or access to which is to be disabled ,
                    and information reasonably sufficient to permit the service
                    provider to locate the material .`}
                  </li>
                  <li>
                  {termAndConditionTranslation?.fourOfFive ? termAndConditionTranslation?.fourOfFive :
                    `Information reasonably sufficient to permit the service
                    provider to contact the complaining party , such as an
                    address , telephone number , and if available , an
                    electronic mail address at which the complaining party may
                    be contacted`}
                  </li>
                  <li>
                  {termAndConditionTranslation?.fiveOfFive ? termAndConditionTranslation?.fiveOfFive :
                    `A statement that the complaining party has a good faith
                    belief that use of the material in the manner complained of
                    is not authorized by the copyright owner .`}
                  </li>
                  <li>
                  {termAndConditionTranslation?.sixOfFive ? termAndConditionTranslation?.sixOfFive :
                    `A statement that the information in the notification is
                    accurate, and under penalty of perjury that the complaining
                    party is authorized to act on behalf of the owner of an
                    exclusive right that is allegedly infringed .`}
                  </li>
                </ul>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.headingSix ? termPartTwoTranslation?.headingSix : "6. External links"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartTwoTranslation?.paraSix ? termPartTwoTranslation?.paraSix :
                `YINN may provide links to third –party websites or resources .
                You acknowledge and agree that YINN is not responsible or liable
                for : the availability or accuracy of such websites or resources
                : or the content , products , or services on or available from
                such websites or resources . Links to such websites or resources
                do not imply any endorsement by YINN of such websites or
                resources . You acknowledge sole responsibility for and assume
                all risk arising from your use of any such websites or resources
                .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.headingSeven ? termPartTwoTranslation?.headingSeven : 
                "7. Third party Software"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartTwoTranslation?.paraSeven ? termPartTwoTranslation?.paraSeven :
                `As convenience , we may make third-party software available
                through the service . To use the third-party software , you must
                agree to the terms and conditions imposed by the third party
                provider and the agreement to such software will be solely
                between you and the third party provider . By downloading third
                party software , you acknowledge and agree that the software on
                an ‘’ AS IS’’ basis without warranty of any kind . In no event
                shall YINN be liable for claims or damages of any nature ,
                whether direct or indirect , arising from or related to any
                third-party software downloaded through the service .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.headingEight ? termPartTwoTranslation?.headingEight : "8. Conduct"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartTwoTranslation?.paraEight ? termPartTwoTranslation?.paraEight : 
                `You agree that you shall not interfere with or disrupt ( or
                attempt to interfere with or disrupt ) this service or servers
                or networks connected to this service , or to disobey any
                requirements , procedures , policies or regulations of networks
                connected to this service ; or provide any information to YINN
                that is false or misleading , that attempts to hide your
                identity or that you do not have the right to disclose . YINN
                does not endorse any content placed on the Service any content
                placed on the service by third parties or any opinions or advice
                contained in such content . You agree to defend , indemnify ,
                and hold harmless YINN , its officers , directors , employees
                and agents , from and against any claims , liabilities , damages
                , losses , and expenses , including without limitation ,
                reasonable legal and expert fees , arising out of or in any way
                connected with your access to or use of the services , or your
                violation of these Terms .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.headingNine ? termPartTwoTranslation?.headingNine : 
                "9. Disclaimer of warranty and Limitation of Liability"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartTwoTranslation?.paraNine ? termPartTwoTranslation?.paraNine : 
                `YINN MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND ,
                EXPRESS OR IMPLIED AS TO THE OPERATION OF THE SERVICE , OR THE
                CONTENT OR PRODUCTS, PROVIDED THROUGH THE SERVICE. YOU EXPRESSLY
                AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK . YINN
                DISCLAIMS ALL WARRANTIES , EXPRESS OR IMPLIED , INCLUDING
                WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY ,
                FITNESS FOR A  PARICULAR PURPOSE AND NON-INFRINGEMENT TO THE
                FULLEST EXTENT PERMITTED BY LAW. YINN MAKES NO WARRANTY AS TO
                THE SECURITY , RELIABILITY, TIMELINESS, AND PERFORMANCE OF THIS
                SERVICE. YOU SPECIFICALLY ACKNOWLEDGE THAT YINN IS NOT LIABLE
                FOR YOUR DIFAMATORY , OFFENSIVE OR ILLEGAL CONDUCT , OR SUCH
                CONDUCT BY THIRD PARTIES , AND YOU EXPRESSLY ASSUME ALL RISK AND
                RESPONSIBILITY DAMAGES AND LOSSES ARISING FOR SUCH CONDUCT.
                EXCEPT FOR THE EXPRESS, LIMITED REMEDIES PROVIDED HEREIN , AND
                TO THE FULLEST EXTEND ALLOWED BY LAW , YINN SHALL NOT BE LIABLE
                FOR ANY DAMAGES OF ANY KIND ARISING FROM USE OF THE SERVICE ,
                INCLUDING BUT NOT LIMITED TO DIRECT , INDIRECT , INCIDENTAL ,
                CONSEQUENTIAL , SPECIAL , EXEMPLARY, OR PUNITIVE DAMAGES , EVENT
                IF YINN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES .
                THE FOREGOING DISCLAIMERS , WAIVERS AND LIMITATIONS SHALL APPLY
                NOTHIITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
                REMEDY . SOME JURIDICTIONS DO NOT ALLOW THE EXCLUSION OF OR
                LIMITATIONS ON CERTAIN WARRANTIES OR DAMAGES . THEREFORE , SOME
                OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU . IN
                NO EVENT SHALL YINN ‘AGGREGATE LIABILITY TO YOU EXCEED THE
                AMOUNTS PAID BY YOU TO YINN PURSUANT TO THIS AGREEMENT .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.headingTen ? termPartTwoTranslation?.headingTen : 
                "10. Amendment of the Terms"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartTwoTranslation?.paraTen ? termPartTwoTranslation?.paraTen : 
                `We reserve the right to amend these Terms from time to time in
                our sole discretion . If you have registered as a member , we
                shall notify you of any material changes to these Terms ( and
                the effective date of such changes ) by sending an email to the
                address you have provided to YINN for your account . For all
                other users , we will post the revised terms on the Site . If
                you continue to use the service after effective date of the
                revised Terms , you will be deemed to have accepted those
                changes . If you do not agree to the revised Terms , your sole
                remedy shall be to discontinue the service .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.headingEleven ? termPartTwoTranslation?.headingEleven : "11. General"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartTwoTranslation?.paraEleven ? termPartTwoTranslation?.paraEleven : 
                `These Terms constitute the entire agreement between YINN and you
                with respect to your use of the service . YINN ‘s failure to
                enforce any right or provision in these Terms shall not
                constitute a waiver of such right or provision . If a court
                should find that one or more provisions contained in these Terms
                is invalid , you agree that the reminder of the Terms shall be
                enforceable . YINN shall have the right to assign its rights and
                or delegate its obligations under these Terms , in whole or in
                part , to any person or business entity . You may not assign
                your rights or delegate your obligations under these Terms
                without the prior written consent of YINN . These Terms shall be
                governed by and construed in accordance with the laws of the
                province of Quebec a . Any disputes arising under or in
                connection with these Terms shall be subject to the exclusive
                jurisdiction of province of Quebec ( Canada ) .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.headingTwelve ? termPartTwoTranslation?.headingTwelve : "12. Comments"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartTwoTranslation?.paraTwelve ? termPartTwoTranslation?.paraTwelve :
                `If you have any comments or questions about the service please
                contact us by email at support@yinn.ca`}
              </div>

              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.sectionTwo ? termPartTwoTranslation?.sectionTwo :
                `Section II : Additional Terms`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartTwoTranslation?.headindThirteen ? termPartTwoTranslation?.headindThirteen : "13. Registration"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartTwoTranslation?.paraThirteen ? termPartTwoTranslation?.paraThirteen : 
                `To register as a member of the Service or purchase products ,
                you must be 13 years or lawfully permitted to enter into and
                form contracts under applicable law . In no event may minors
                submit content to the Service .You agree that the information
                that you provide to us upon registration, at the time of
                purchase , and at all other times will be true , accurate ,
                current and complete . You also agree that you will ensure that
                this information is kept accurate and up to date at all times .
                This is especially important with respect to your email address
                , since that is the primary way in which we will communicate
                with you about your account and your orders .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartThreeTranslation?.headingFourteen ? termPartThreeTranslation?.headingFourteen :  "14. Password"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartThreeTranslation?.paraFourteen ? termPartThreeTranslation?.paraFourteen : 
                `When you register as a member you will be asked to provide a
                password . You are responsible for safeguarding the password and
                you agree not to disclose your password to any third party. You
                agree that you shall be solely responsible for any activities or
                actions under your password , whether or not you have authorized
                such activities or actions . You shall immediately notify YINN
                of any unauthorized use of your password.`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartThreeTranslation?.headingFifteen ? termPartThreeTranslation?.headingFifteen : "15. Submitting Content"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartThreeTranslation?.paraFifteen ? termPartThreeTranslation?.paraFifteen :
                `Chat Rooms – As a registered member and premium member of the
                Service , you will be granted the privilege of participating in
                chat rooms on the YINN website as part of the Service , which
                means you may post your Content in the form of text for display
                in these areas of the YINN website , subject to your compliance
                with the Terms . Artist Materials- As a registered member of the
                Service , you will also be granted the privilege of
                participating in chat rooms on YINN website as part of the
                Service , which means you may post your content in the form of
                text for display in these areas of the YINN website , subject to
                your compliance with the Terms .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartThreeTranslation?.headingSixteen ? termPartThreeTranslation?.headingSixteen : "16. Copyright in Your Content"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartThreeTranslation?.paraSixteen ? termPartThreeTranslation?.paraSixteen : 
                `YINN does not claim ownership rights in your content. For the
                sole purpose of enabling us to make your content available
                through the service , you grant to YINN a non-exclusive ,
                royalty free license to reproduce , distribute , re-format ,
                store , prepare derivative works based on , and publicly display
                and perform your content. Please note that when you upload
                content , third parties will be able to copy , distribute and
                display your content using readily available tools on their
                computers for this purpose although other than by linking to
                your content on YINN any use by a third party of your content
                could violate paragraph 4 of these Terms and conditions unless
                the third party receives permission from you by license`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartThreeTranslation?.headingSeventeen ? termPartThreeTranslation?.headingSeventeen :  "17. Monitoring Content"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartThreeTranslation?.paraSeventeen ? termPartThreeTranslation?.paraSeventeen : 
                `YINN has no ability to control the content you may upload , post
                or otherwise transmit using the Service and does not have any
                obligation to monitor such Content for any purpose . You
                acknowledge that you are solely responsible for all Content and
                material you upload , post or otherwise transmit using the
                Service .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartThreeTranslation?.headingEighteen ? termPartThreeTranslation?.headingEighteen :  "18. Storage policy"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartThreeTranslation?.paraEighteen ? termPartThreeTranslation?.paraEighteen : 
                `At this time , YINN provides free online storage of your content
                to registered members and Premium members of the Site . However
                , you acknowledge and agree that YINN may , at its option,
                establish limits concerning your use of the Service , including
                without limitation the maximum number of days that your content
                will be retained by the Service , the maximum size of any
                content files that may be stored on the service, the maximum
                disk space that will be allotted to you for the storage of
                Content on YINN ‘s servers . Furthermore , you acknowledge that
                YINN reserves the right to terminate or suspend accounts that
                are inactive , in YINN ‘s sole discretion , for an extended
                period of time (thus deleting or suspending access to your
                content ) . without limiting the generality of Section 9, YINN
                shall have no responsibility or liability for the deletion or
                failure to store any Content maintained on the service and you
                are solely responsible for creating back-ups of your content .
                You further acknowledge that YINN reserves the right to modify
                its storage policies from time to time , with or without notice
                to you .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartFourTranslation?.headingNinteen ? termPartFourTranslation?.headingNinteen : "19. Conduct"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartFourTranslation?.paraNinteen ? termPartFourTranslation?.paraNinteen :
                `You agree to be subject to and to conduct yourself in accordance
                with the YINN policy . You are responsible for all your content
                you upload , download , and otherwise copy, distribute and
                display using the Service . You must have the legal right to
                copy , distribute and display all parts of any content that you
                upload , download and otherwise copy , distribute and display . 
                Content provided to you by others , or made available through
                websites , magazines , books and other source , are protected by
                copyright and should not be uploaded , downloaded , or otherwise
                copied , distributed or displayed without the content of the
                copyright owner or as otherwise permitted by law . You agree to
                use the service :`}
                <ol>
                  <li>
                  {termPartFourTranslation?.oneOfNinteen ? termPartFourTranslation?.oneOfNinteen :
                    `To upload , post , or otherwise transmit any material that
                    is obscene , offensive , blasphemous , pornographic,
                    unlawful, threatening , menacing , abusive , harmful, an
                    invasion of privacy or publicity rights, defamatory ,
                    libellous , vulgar , illegal .`}
                  </li>
                  <li>
                    {termPartFourTranslation?.twoOfNinteen ? termPartFourTranslation?.twoOfNinteen :
                    `To upload , post , or otherwise transmit any material that
                    infringes any copyright , trade mark , patent or other
                    intellectual property right or any moral right or artist
                    right of any third party including, but not limited to YINN
                    or to facilitate the unlawful distribution of copyrighted
                    Content or illegal content .`}
                  </li>
                  <li>
                    {termPartFourTranslation?.threeOfNinteen ? termPartFourTranslation?.threeOfNinteen :
                    `To harm minors in any way, including, but not limited to,
                    uploading , posting , or otherwise transmitting content that
                    violates child pornography laws, child sexual exploitation
                    laws or laws prohibiting the depiction of minors engaged in
                    sexual conduct , or submitting any personally identifiable
                    information about any child under the age of 13`}
                  </li>
                  <li>
                    {termPartFourTranslation?.fourOfNinteen ? termPartFourTranslation?.fourOfNinteen :
                    `To forge headers or otherwise manipulate identifiers in
                    order to disguise the origin of any content transmitted
                    through the Service ;`}
                  </li>
                  <li>
                    {termPartFourTranslation?.fiveOfNinteen ? termPartFourTranslation?.fiveOfNinteen :
                    `To upload , post , or otherwise transmit any material which
                    is likely to cause harm to YINN or anyone else ‘s computer
                    systems , including but not limited to that which contains
                    any virus , code , worm , data or other files or programs
                    designed to damage or allow unauthorized access to the
                    service which may cause any defect , error , malfunction or
                    corruption to the Service .`}
                  </li>
                  <li>
                    {termPartFourTranslation?.sixOfNinteen ? termPartFourTranslation?.sixOfNinteen :
                    `For any commercial purpose , except as expressly permitted
                    under these Terms ;`}
                  </li>
                  <li>
                    {termPartFourTranslation?.sevenOfNinteen ? termPartFourTranslation?.sevenOfNinteen :
                    `To sell access to the service on any other website or to use
                    the service on another website for the primary purpose of
                    gaining advertising or subscription revenue other than a
                    personal blog or social network where the primary purpose is
                    to display content from YINN by hyperlink and not to compete
                    with YINN.`}
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartFourTranslation?.headingNinteenA ? termPartFourTranslation?.headingNinteenA :
                "19A- Commercial Activities"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartFourTranslation?.paraNinteenA ? termPartFourTranslation?.paraNinteenA :
                `Commercial activities mean the offering , solicitation or sale
                of goods or services by anyone other than YINN. Commercial
                activities with respect to the indie arts , physical and digital
                goods are permitted for registered members acting as
                individuals, for small corporations or partnerships engaged
                primarily in art-related activities in which one or more of the
                principals is a registered member or for those seeking to retain
                the services or works of a registered member . Commercial
                activities in the form of paid advertising on the Service are
                subject to the terms and conditions  relating to the purchase of
                such advertising . No other commercial activities are permitted
                on or through the service without YINN ‘s written approval . Any
                interactions with members of the Service with respect to
                commercial activities including payment for and delivery goods
                and or services and any terms related to the commercial
                activities including conditions, warranties or representations
                and so forth are solely between you and the other member.
                Paragraph 9 above, of these Terms of services specifically
                applies with respect of commercial activities .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                {termPartFourTranslation?.headingNinteenB ? termPartFourTranslation?.headingNinteenB :
                "19B- Groups"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
                {termPartFourTranslation?.paraNinteenB ? termPartFourTranslation?.paraNinteenB :
                `As a registered member of the Service , you will also be able to
                participate as an administrator or member of a ‘’Group’’ which
                is a set of user pages and applications formed for the purpose
                of collecting content , discussions and organizing members of
                the site with common interests .`}
                <ol>
                  <li>
                  {termPartFourTranslation?.oneOfNinteenB ? termPartFourTranslation?.oneOfNinteenB :
                    `You agree to participate in a Group on the basis of its own
                    rules consistent with these Terms of Service , the conduct
                    set out in paragraph 19 , above and such other rules created
                    by us for Groups from time to time including with respect to
                    the use of Group for commercial activities .`}
                  </li>
                  <li>
                  {termPartFourTranslation?.twoOfNinteenB ? termPartFourTranslation?.twoOfNinteenB :
                    `As an administrator or participant in a Group you acquire no
                    ownership rights over the Group, the Group applications
                    provided by us or over the right to conduct the activities
                    of the Group.`}
                  </li>
                  <li>
                    {termPartFourTranslation?.threeOfNinteenB ? termPartFourTranslation?.threeOfNinteenB :
                    `Any content or Artists materials submitted to a Group remain
                    , as between the Group and the user submitting such content
                    , the property of the person who submitted the content .`}
                  </li>
                  <li>
                    {termPartFourTranslation?.fourOfNinteenB ? termPartFourTranslation?.fourOfNinteenB :
                    `Groups are managed by registered members of the Service and
                    not by us . We are not responsible for the conduct of Group
                    participants or administrators and will not interfere with
                    the management or society of any specific Group or the rules
                    it establishes for itself as long as they are consistent
                    with these Terms of Service and our policies .`}
                  </li>
                  <li>
                    {termPartFourTranslation?.fiveOfNinteenB ? termPartFourTranslation?.fiveOfNinteenB :
                    `Groups may not be used to collect personal data about
                    participants in Groups without the participant’s express
                    permission .`}
                  </li>
                  <li>
                    {termPartFourTranslation?.sixOfNinteenB ? termPartFourTranslation?.sixOfNinteenB :
                    `The Group application permits us to take appropriate action
                    should intervention become necessary as a result of a
                    violation of the Terms of Service or any other of our
                    policies . We can remove a Group and the Group’s privileges
                    at any time at our discretion`}
                  </li>
                  <li>
                    {termPartFourTranslation?.sevenOfNinteenB ? termPartFourTranslation?.sevenOfNinteenB :
                    `The use in a Group name of trademarks or distinctive trade
                    names of properties , goods or services is subject to
                    objections from the owner of the marks and names . YINN will
                    respond to such objections by requiring an appropriate
                    change in the name of the Group . The use of trademarks and
                    trade names in Group names otherwise must be descriptive of
                    Group activity or purpose . To avoid confusion and animosity
                    , Groups wishing to name themselves after trademarked
                    properties must include a further description in its name of
                    the type of Group it expects to be .`}
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
               {termPartFiveTranslation?.headingTwenty ? termPartFiveTranslation?.headingTwenty : 
               "20. Suspension and Termination of Access and Membership"} 
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartFiveTranslation?.paraTewnty ? termPartFiveTranslation?.paraTewnty : 
                `You agree that YINN may at any time and without notice , suspend
                terminate any part of the service , or refuse to fulfill any
                order or any part of any order or terminate your membership or
                delete any content stored on YINN site,in YINN ‘s sole
                discretion , if you fail to comply with the Terms or applicable
                law .`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                {termPartFiveTranslation?.headingTwentyOne ? termPartFiveTranslation?.headingTwentyOne : "21. Buy on YINN site shop"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
                {termPartFiveTranslation?.paraTwentyOne ? termPartFiveTranslation?.paraTwentyOne : 
                "As a registered member you can buy on YINN site :"}
                <ol>
                  <li>
                  {termPartFiveTranslation?.oneOfTwentyOne ? termPartFiveTranslation?.oneOfTwentyOne : 
                    "A buyer pays for the product + calculated shipping costs."}
                  </li>
                  <li>
                  {termPartFiveTranslation?.twoOfTwentyOne ? termPartFiveTranslation?.twoOfTwentyOne : 
                    "Shipping costs vary based upon the information provided by sellers."}
                  </li>
                  <li>
                    {termPartFiveTranslation?.threeOfTwentyOne ? termPartFiveTranslation?.threeOfTwentyOne : 
                    `Shipping rates may be defined, or may be the standard rate
                    multiplied by the shipping rate for the destination.`}
                  </li>
                  <li>
                    {termPartFiveTranslation?.fourOfTwentyOne ? termPartFiveTranslation?.fourOfTwentyOne : 
                    `Shipping to PO boxes, freight forwarding companies, or
                    package lockers is prohibited.`}
                  </li>
                  <li>
                    {termPartFiveTranslation?.fiveOfTwentyOne ? termPartFiveTranslation?.fiveOfTwentyOne : 
                    `YINN does not store your credit card data; we use Braintree
                    & Paypal to process credit cards and never see sensitive
                    info such as credit card number, etc.`}
                  </li>
                  <li>
                    {termPartFiveTranslation?.sixOfTwentyOne ? termPartFiveTranslation?.sixOfTwentyOne : 
                    `YINN works with Paypal and Braintree to make sure our
                    payment process is secure.`}
                  </li>
                  <li>
                    {termPartFiveTranslation?.sevenOfTwentyOne ? termPartFiveTranslation?.sevenOfTwentyOne : 
                    `Simple returns: If you aren't happy with your order within
                    the first 30 days of your purchase, we will issue you a
                    refund after returning the goods to the seller , further
                    protecting you down the road.`}
                  </li>
                  <li>
                    {termPartFiveTranslation?.eightOfTwentyOne ? termPartFiveTranslation?.eightOfTwentyOne : 
                   ` We will never reveal an individual buyers' information, but
                    if a company places a corporate order we might use the logo
                    for promotional purposes.`}
                  </li>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartFiveTranslation?.headingTwentyTwo ? termPartFiveTranslation?.headingTwentyTwo : "22. Sell on YINN site"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartFiveTranslation?.paraTwentyTwo ? termPartFiveTranslation?.paraTwentyTwo :
               `As a registered member you are grant the right to sell on YINN
                site :`}
                <ol>
                  <li>
                  {termPartFiveTranslation?.oneOfTwentyTwo ? termPartFiveTranslation?.oneOfTwentyTwo :
                    `Products must have a photo of the physical product and a
                    thorough, accurate description. Images that contain any text
                    added after the fact (such as email addresses or website
                    URLs) are not allowed and will be removed.`}
                  </li>
                  <li>{termPartFiveTranslation?.twoOfTwentyTwo ? termPartFiveTranslation?.twoOfTwentyTwo :
                   `There is no fee for listing products.`}</li>
                  <li>
                  {termPartFiveTranslation?.threeOfTwentyTwo ? termPartFiveTranslation?.threeOfTwentyTwo :
                    `For each sale, YiINN’s fee will be deducted from the
                    disbursement , please see our fees in your dashboard . The
                    payment processing fee will also be deducted (this fee
                    varies depending on if the buyer pays via credit card
                    ,PayPal or Yncoin (our virtual money)).`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.fourOfTwentyTwo ? termPartFiveTranslation?.fourOfTwentyTwo :
                   ` New sellers will have their disbursements available within
                    30 days after their first order ships. Afterwards,
                    disbursements are available, via our payout system, after
                    orders have been shipped. If you don't pay out manually, you
                    will be automatically paid for all unpaid, shipped orders,
                    30 days after the earliest unpaid order was shipped. YINN
                    reserves the right to disburse funds on your behalf at any
                    time.`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.fiveOfTwentyTwo ? termPartFiveTranslation?.fiveOfTwentyTwo :
                    `Disbursements are only made to verified Paypal accounts . We
                    cannot disburse by any other means. Sellers should ensure
                    that their Paypal account is verified before the first
                    disbursement is due to avoid delays.`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.sixOfTwentyTwo ? termPartFiveTranslation?.sixOfTwentyTwo :
                    `If a product is for sale on YINN, promotion or links to
                    another marketplace or retail channel are prohibited for
                    products. It is ok to promote retired products that can now
                    be purchased elsewhere.`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.sevenOfTwentyTwo ? termPartFiveTranslation?.sevenOfTwentyTwo :
                    `We reserve the right to reject product submissions deemed
                    inappropriate for this marketplace. YINN is a marketplace
                    for DIY enthusiasts and products sold should reflect the
                    community's interest.`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.eightOfTwentyTwo ? termPartFiveTranslation?.eightOfTwentyTwo :
                    `Messages between sellers, buyers, and/or YINN should ideally
                    be answered within 48 hours. Special cases such as a store
                    owner setting their store to “vacation” mode or other
                    instances will be handled on a case-by-case basis. If a
                    buyer opens a support ticket to YINN with questions
                    regarding stock, product description, technical support, or
                    shipping, YINN will copy the seller on the email in an
                    attempt to keep open communication as well as acting as a
                    liaison between sellers and buyers.`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.nineOfTwentyTwo ? termPartFiveTranslation?.nineOfTwentyTwo :
                    `Items marked as in-stock are to be shipped within 2 weeks
                    (14 days) and packed to guard against damages during
                    transit. Double-check the mailing address, ensure the
                    correct item is being shipped, and that a packing slip has
                    been included. If sellers are unable to ship within 14 days,
                    a message needs to be sent to both the buyer and YINN
                    communicating this.`}
                  </li>
                  <li>{termPartFiveTranslation?.tenOfTwentyTwo ? termPartFiveTranslation?.tenOfTwentyTwo :
                  `A refund may be issued if:`}</li>
                  <ul>
                    <li>
                    {termPartFiveTranslation?.oneOfTen ? termPartFiveTranslation?.oneOfTen :
                      `A product has not shipped within the allotted timeframe,
                      without any notifications from the seller.`}
                    </li>
                    <li>
                    {termPartFiveTranslation?.twoOfTen ? termPartFiveTranslation?.twoOfTen :
                      `If a product has not been received within a reasonable
                      amount of time (taking into account average transit times
                      from origin to destination, time of year, processing by
                      customs, etc.) and attempts from YINN and/or the buyer to
                      receive updates have gone unanswered.`}
                    </li>
                    <li>
                    {termPartFiveTranslation?.threeOfTen ? termPartFiveTranslation?.threeOfTen :
                      `Item received is vastly different from the product
                      description`}
                    </li>
                    <li>
                    {termPartFiveTranslation?.fourOfTen ? termPartFiveTranslation?.fourOfTen :
                      `Messages to the seller from YINN or the buyer go
                      completely unanswered.`}
                    </li>
                    <li>
                    {termPartFiveTranslation?.fiveOfTen ? termPartFiveTranslation?.fiveOfTen :
                      `Failure to meet these practices will result in discussions
                      between YINN and the seller to rectify the situation. If
                      three cases of any of these situations have occurred,
                      actions do  not improve, or communication ceases, we
                      reserve the right to take actions including, but not
                      limited to:`}
                    </li>
                    <li>
                    {termPartFiveTranslation?.sixOfTen ? termPartFiveTranslation?.sixOfTen :
                      `Setting store stock to zero to protect other buyers while
                      we work with the store owner to improve standards.`}
                    </li>
                    <li>{termPartFiveTranslation?.sevenOfTen ? termPartFiveTranslation?.sevenOfTen : "Issuing refunds to buyers."}</li>
                    <li>{termPartFiveTranslation?.eightOfTen ? termPartFiveTranslation?.eightOfTen : "Closure of the shop" }</li>
                  </ul>
                </ol>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartFiveTranslation?.headingTwentyThree ? termPartFiveTranslation?.headingTwentyThree : 
                `23. Yncoin ( our virtual money )`}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartFiveTranslation?.paraTwnetyThree ? termPartFiveTranslation?.paraTwnetyThree : 
                `Is YINN virtual money allowing users to reward fellow YINN
                member or use it to buy physical and digital goods on YINN
                network . You can :`}
                <ul>
                  <li>
                  {termPartFiveTranslation?.oneOfTwentyThree ? termPartFiveTranslation?.oneOfTwentyThree :
                    `Share Yncoin with Friends : not only Yncoin is the perfect
                    way to show your friend you care , but using it couldn’t be
                    easier . You can transfer Yncoin (virtual money ) to someone
                    for an inspiration idea , as loan , trade Yncoin for a
                    commission , wish them a happy birthday .`}
                  </li>
                  <li>
                    {termPartFiveTranslation?.twoOfTwentyThree ? termPartFiveTranslation?.twoOfTwentyThree :
                    `Get stuff with Yncoin: stock up on Yncoin and redeem them
                    for anything in YINN marketplace , from Premium Membership ,
                    to physical and digital resources , to commissions .`}
                  </li>
                  <li>
                    {termPartFiveTranslation?.threeOfTwentyThree ? termPartFiveTranslation?.threeOfTwentyThree :
                    `Quick and Easy : It is quick and easy to buy Yncoin , You
                    get Yncoin for every U.S dollar or CAD dollar and start
                    sharing with friends or buy stuffs on YINN .`}
                  </li>
                </ul>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartFiveTranslation?.twoheadingTwentyThree ? termPartFiveTranslation?.twoheadingTwentyThree :
                `Choosing not to share your personal information`}
              </div>
              <div className="text-muted font-weight-bold font-xs">
              {termPartFiveTranslation?.twoParaTwentyThree ? termPartFiveTranslation?.twoParaTwentyThree :
                `Where we are required by law to collect your personal
                information, or where we need your personal information in order
                to provide our services to you, if you do not provide this
                information when requested (or you later ask to delete it), we
                may not be able to provide you with our services. We will tell
                you what information you must provide to receive the services by
                designating it as required in or on the Sites or through other
                appropriate means.`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartFiveTranslation?.threeHeadingTwentyThree ? termPartFiveTranslation?.threeHeadingTwentyThree :"Here are Yncoin Rules :"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ul>
                  <li>
                  {termPartFiveTranslation?.oneHeadingTwentyThree ? termPartFiveTranslation?.oneHeadingTwentyThree :
                    `Yncoin can only be used on YINN , and you can only get or
                    give Yncoin by being a member of YINN .`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.twoHeadingTwentyThree ? termPartFiveTranslation?.twoHeadingTwentyThree :
                    `Any transfer of Yncoin is biding , and cannot be reversed .`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.threeHeadingTwentyThree ? termPartFiveTranslation?.threeHeadingTwentyThree :
                    `You are able to convert Yncoin back into Fiat by paying
                    appropriate fee to buy it out .`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.fourHeadingTwentyThree ? termPartFiveTranslation?.fourHeadingTwentyThree :
                    `You can only give away 4000 Yncoin in a single transfer to
                    another YINN member or Group , and you can only give 200000
                    Yncoin to one person in a year`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.fiveHeadingTwentyThree ? termPartFiveTranslation?.fiveHeadingTwentyThree :
                    `A complete transaction record to keep track of your balance
                    is stored for you in Yncoin secure wallet .`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.sixHeadingTwentyThree ? termPartFiveTranslation?.sixHeadingTwentyThree :
                    `YINN will always keep your personal Yncoin information
                    private .`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.sevenHeadingTwentyThree ? termPartFiveTranslation?.sevenHeadingTwentyThree :
                    `We may cancel , suspend , revoke , or otherwise limit your
                    access to your Yncoin account if we suspect fraudulent ,
                    abusive and unlawful activity with regard to your Yncoin .`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.eightHeadingTwentyThree ? termPartFiveTranslation?.eightHeadingTwentyThree :
                    `You are only responsible for the Yncoin transferred to any
                    YINN member or Group`}
                  </li>
                  <li>
                  {termPartFiveTranslation?.nineHeadingTwentyThree ? termPartFiveTranslation?.nineHeadingTwentyThree :
                    `You can use YINN method of payment available to buy Yncoin
                    In case you have question please contact support@yinn.ca .`}
                  </li>
                </ul>
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartFiveTranslation?.headingTwentyFour ? termPartFiveTranslation?.headingTwentyFour : "24. YINN Loan ."}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
              {termPartFiveTranslation?.secondHeadingTwentyFour ? termPartFiveTranslation?.secondHeadingTwentyFour :
               " It's a loan, not a donation"}
              </div>
              <div className="text-muted font-weight-bold font-xs">
                {termPartFiveTranslation?.secondparaTwentyFour ? termPartFiveTranslation?.secondparaTwentyFour :
                `We believe lending alongside thousands of others and creators is
                one of the most powerful and sustainable ways to create economic
                and social good. Lending on YINN creates a partnership of mutual
                dignity and makes it easy to touch more creators lives with the
                same Yncoin backed by a dollar. Fund a loan, get repaid, fund
                another.`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                {termPartFiveTranslation?.thirdHeadingTwentyFour ? termPartFiveTranslation?.thirdHeadingTwentyFour :
                `You choose where to make an impact`}
              </div>
              <div className="text-muted font-weight-bold font-xs">
                {termPartFiveTranslation?.thirdparaTwentyFour ? termPartFiveTranslation?.thirdparaTwentyFour :
                `Whether you lend to any seller or creator in our community, YINN
                creates the opportunity to play a special part in someone else's
                story. At YINN, loans aren’t just about money—they’re a way to
                create connection and relationships.`}
              </div>
              <div className="h5 mb-0 mt-h" style={{ paddingTop: "6px" }}>
                {termPartFiveTranslation?.fourthHeadingTwentyFour ? termPartFiveTranslation?.fourthHeadingTwentyFour :
                `Loan lending came with conditions :`}
              </div>
              <div className="text-muted font-weight-bold font-xs">
                <ol>
                  <li>
                  {termPartFiveTranslation?.oneOfTwentyFour ? termPartFiveTranslation?.oneOfTwentyFour :
                    `The loan is base on seller sale flow in YINN marketplace
                    more seller sale , more he /she is able to get loan .`}
                  </li>
                  <li>
                    {termPartFiveTranslation?.twoOfTwentyFour ? termPartFiveTranslation?.twoOfTwentyFour :
                    "Being Solo , partner and Business verified paid member ."}
                  </li>
                  <li>
                    {termPartFiveTranslation?.threeOfTwentyFour ? termPartFiveTranslation?.threeOfTwentyFour :
                    `Being a seller on YINN for at least 3 months .`}</li>
                  <li>{termPartFiveTranslation?.fourOfTwentyFour ? termPartFiveTranslation?.fourOfTwentyFour :
                   " Making at least $USD 300.00 a month ."}</li>
                </ol>
                {termPartFiveTranslation?.secondLastLine ? termPartFiveTranslation?.secondLastLine :
                "In case you have question please contact support@yinn.ca ."}
              </div>

              <b>{termPartFiveTranslation?.secondLastLine ? termPartFiveTranslation?.secondLastLine :
                "This Term of use is effective on March , 20, 2022 ."}</b>
            </CardBody>
          </Card>
        </div>
      </div>
      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

export default TermsAndConditions;
