export const currencyAndFlags = [
  { AUD: "au" }, // Australia
  { BGN: "bg" }, // Bulgaria
  { BRL: "br" }, // Brazil
  { CAD: "ca" }, // Canada
  { CHF: "ch" }, // Switzerland
  { CNY: "cn" }, // China
  { CZK: "cz" }, // Czech Republic
  { DKK: "dk" }, // Denmark
  { EUR: "eu" }, // European Union
  { GBP: "gb" }, // United Kingdom
  { HKD: "hk" }, // Hong Kong
  { HRK: "hr" }, // Croatia
  { HUF: "hu" }, // Hungary
  { IDR: "id" }, // Indonesia
  { ILS: "il" }, // Israel
  { INR: "in" }, // India
  { ISK: "is" }, // Iceland
  { JPY: "jp" }, // Japan
  { KRW: "kr" }, // South Korea
  { MXN: "mx" }, // Mexico
  { MYR: "my" }, // Malaysia
  { NOK: "no" }, // Norway
  { NZD: "nz" }, // New Zealand
  { PHP: "ph" }, // Philippines
  { PLN: "pl" }, // Poland
  { RON: "ro" }, // Romania
  { RUB: "ru" }, // Russia
  { SEK: "se" }, // Sweden
  { SGD: "sg" }, // Singapore
  { THB: "th" }, // Thailand
  { TRY: "tr" }, // Turkey
  { USD: "us" }, // United States
  { ZAR: "za" }, // South Africa
];
