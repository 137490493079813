import React, { useEffect, useState } from "react";
import "./Addresses.css";
import "./Orders.css";
import { EditUserInfo } from "../../redux/reducers/auth";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


const EditBillingAddress = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];
  const dashboardTranslation = translationState?.[0]?.translations?.dashboard?.[userLanguage];
  const shippingCartTranslation = translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const digitalProductTranslation = translationState?.[0]?.translations?.digitalProduct?.[userLanguage];

  
  const [pageContent, setPageContent] = useState({
    firstname: "",
    lastname: "",
    companyname: "",
    country: "",
    streetname: "",
    zipcode: "",
    cityname: "",
    statename: "",
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };

  // useEffect(() => {
  //   setPageContent(user.billingAddress);
  // }, [user]);

  const HandleShippingAddress = () => {
    const {
      firstname,
      lastname,
      companyname,
      country,
      streetname,
      zipcode,
      cityname,
      statename,
    } = pageContent;
    if (
      !firstname ||
      !lastname ||
      !country ||
      !streetname ||
      !zipcode ||
      !cityname
    ) {
      toast.error(`${toastTranslation?.requried ? toastTranslation?.requried : "Kindly fill the required fields"}`);
      return;
    } else {
      const data = {
        billingAddress: pageContent,
      };

      dispatch(EditUserInfo(data)).then(() =>
        history.push("/customer-account/addresses")
      );
    }
  };

  return (
    <>
      <ToastContainer autoClose={10000} />
      <div className="main-row">
        <div className="row edit-form">
          <div className="col-md-12 ">
            <h3 className="my-3">{dashboardTranslation?.billing ? dashboardTranslation?.billing : "Billing Address"}</h3>
            <div className="row">
              <div className="col-6 mb-2">
                <b>
                  <label>{shippingCartTranslation?.firsrtName ? shippingCartTranslation?.firsrtName : "First Name *"}</label>
                </b>
                <input
                  type="text"
                  name="firstname"
                  placeholder={shippingCartTranslation?.firsrtName ? shippingCartTranslation?.firsrtName : "First Name"}
                  className="form-control"
                  value={pageContent?.firstname}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-6 mb-2">
                <b>
                  <label>{shippingCartTranslation?.lastName ? shippingCartTranslation?.lastName : "Last name *"}</label>
                </b>
                <input
                  type="text"
                  name="lastname"
                  placeholder={shippingCartTranslation?.lastName ? shippingCartTranslation?.lastName : "Last name"}
                  className="form-control"
                  value={pageContent?.lastname}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-2">
                <b>
                  <label>{dashboardTranslation?.company ? 
                  dashboardTranslation?.company + "(" + dashboardTranslation?.optional +")"
                  : "Company name (optional)"}</label>
                </b>
                <input
                  type="text"
                  name="companyname"
                  placeholder={dashboardTranslation?.company ? dashboardTranslation?.company : "Company name"}
                  className="form-control"
                  value={pageContent?.companyname}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <b>
                  <p>{shippingCartTranslation?.country ? shippingCartTranslation?.country : "Country / Region *"}</p>
                </b>
                <input
                  type="text"
                  name="country"
                  placeholder={shippingCartTranslation?.country ? shippingCartTranslation?.country : "Country / Region"}
                  className="form-control"
                  value={pageContent?.country}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-2">
                <b>
                  <label>{shippingCartTranslation?.street ? shippingCartTranslation?.street : "Street address *"}</label>
                </b>
                <input
                  type="text"
                  name="streetname"
                  placeholder={shippingCartTranslation?.street ? shippingCartTranslation?.street : "Street address"}
                  className="form-control"
                  value={pageContent?.streetname}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-2">
                <b>
                  <label>{shippingCartTranslation?.zipCode ? shippingCartTranslation?.zipCode : "Postcode / ZIP * "}</label>
                </b>
                <input
                  name="zipcode"
                  placeholder={shippingCartTranslation?.zipCode ? shippingCartTranslation?.zipCode : "Postcode / ZIP"}
                  className="form-control"
                  value={pageContent?.zipcode}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-2">
                <b>
                  <label>{shippingCartTranslation?.city ? shippingCartTranslation?.city : "Town / City *"}</label>
                </b>
                <input
                  type="text"
                  name="cityname"
                  placeholder={shippingCartTranslation?.city ? shippingCartTranslation?.city : "Town / City"}
                  className="form-control"
                  value={pageContent?.cityname}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-2">
                <b>
                  <label>{shippingCartTranslation?.state ? shippingCartTranslation?.state+ "(" + dashboardTranslation?.optional +")"
                  : "State (optional)"}</label>
                </b>
                <input
                  type="text"
                  name="statename"
                  placeholder={shippingCartTranslation?.state ? shippingCartTranslation?.state : "State"}
                  className="form-control"
                  value={pageContent?.statename}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                className=" mx-2 button-color"
                onClick={HandleShippingAddress}
              >
                {digitalProductTranslation?.saveButton ? digitalProductTranslation?.saveButton : "Save address"}
              </button>
              <button
                type="button"
                className="button-color"
                onClick={() => history.push("/customer-account/addresses")}
              >
                {digitalProductTranslation?.cancel ? digitalProductTranslation?.cancel : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBillingAddress;
