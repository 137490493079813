import React, { Component } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Table,
} from "reactstrap";
import axios from "axios";
import classnames from "classnames";
import Country from "../../../assets/country/countries";
import States from "../../../assets/country/states";
import Cities from "../../../assets/country/cities";
import Env from "../../../environment/environment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var user = {};
var address = [];
var availabelAddress = [];
const Customer = () => {
  let state = {
    activeTab: "1",
    element: [],
    addrerr: true,
    states: 0,
    count: 0,
    addbtnstate: false,
    nameValid: false,
    name: "",
    formvalid: false,
    formValidAddress: false,
    formErrors: {},

    first_nameValid: false,
    last_nameValid: false,
    contact_numberValid: false,
    gender_valid: false,
    emailValid: false,
    passwordValid: false,
    confirm_passwordValid: false,

    address_typeValid: false,
    addressLine1Valid: false,
    countryValid: false,
    stateValid: false,
    cityValid: false,
    phoneValid: false,
    zipValid: false,
  };
  // constructor(props) {
  //     super(props);
  //     data = {};
  //     addrData = {}
  //     toggle = toggle;

  //     save = save;
  //   }

  const toggle = (tab) => {
    if (state.activeTab !== tab) {
      //setState({
      //     activeTab: tab,
      //   });
    }
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    //setState({ [name]: value },
    // () => {
    //     validateField(name, value);
    //         bindValue(name, value)
    // });
  };
  // const bindValue = (fieldname, value) => {
  //     data[fieldname] = value;
  // }

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = state.formErrors;
    let first_nameValid = state.first_nameValid;
    let last_nameValid = state.last_nameValid;
    let contact_numberValid = state.contact_numberValid;
    let emailValid = state.emailValid;
    let gender_valid = state.gender_valid;
    let passwordValid = state.passwordValid;
    let confirm_passwordValid = state.confirm_passwordValid;

    switch (fieldName) {
      case "first_name":
        if (value != "") {
          first_nameValid = true;
          user["firstname"] = value;
          fieldValidationErrors.first_name = "";
        } else {
          fieldValidationErrors.first_name = "Please enter firstname.";
          first_nameValid = false;
        }
        break;

      case "last_name":
        if (value != "") {
          last_nameValid = true;
          user["lastname"] = value;
          fieldValidationErrors.last_name = "";
        } else {
          fieldValidationErrors.last_name = "Please enter lastname.";
          last_nameValid = false;
        }
        break;

      case "gender":
        if (value != "" && value != "Gender") {
          gender_valid = true;
          user["gender"] = value;
          fieldValidationErrors.gender = "";
        } else {
          fieldValidationErrors.gender = "Please select gender.";
          gender_valid = false;
        }
        break;

      case "contact_number":
        if (value != "") {
          if (value.match(/^([0|\+[0-9]{1,5})?([1-9][0-9]{9})$/)) {
            contact_numberValid = true;
            user["phone"] = value;
            fieldValidationErrors.contact_number = "";
          } else {
            fieldValidationErrors.contact_number =
              "Please enter valid contact number .";
            contact_numberValid = false;
          }
          break;
        } else {
          fieldValidationErrors.contact_number = "Please enter contact number.";
          contact_numberValid = false;
        }
        break;

      case "email":
        if (value != "") {
          if (
            value.match(
              /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
            )
          ) {
            emailValid = true;
            user["email"] = value;
            fieldValidationErrors.email = "";
          } else {
            fieldValidationErrors.email = "Please enter valid email id .";
            emailValid = false;
          }
          break;
        } else {
          fieldValidationErrors.email = "Please enter email id";
          emailValid = false;
        }
        break;

      case "password":
        if (value != "") {
          passwordValid = true;
          user["password"] = value;
          fieldValidationErrors.password = "";
        } else {
          fieldValidationErrors.password = "Please enter password.";
          passwordValid = false;
        }

        break;

      case "confirm_password":
        if (value != "" && value == state.password) {
          confirm_passwordValid = true;
          //setState({confirm_password : value})
          fieldValidationErrors.confirm_password = "";
        } else {
          fieldValidationErrors.confirm_password =
            "Please enter confirm password.";
          confirm_passwordValid = false;
        }

        break;
    }
    //setState({
    //     formErrors: fieldValidationErrors,
    //     last_nameValid: last_nameValid,
    //     first_nameValid: first_nameValid,
    //     contact_numberValid : contact_numberValid,
    //     emailValid: emailValid,
    //     gender_valid: gender_valid,
    //     passwordValid: passwordValid,
    //     confirm_passwordValid: confirm_passwordValid,

    // }, validateForm);
  };

  const validateForm = () => {
    //setState({
    //     formValid: (state.first_nameValid && state.last_nameValid && state.contact_numberValid && state.emailValid && state.passwordValid && state.confirm_passwordValid && state.gender_valid )
    // }, console.log(state.formValid))
  };

  const handleUserAddressInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    //setState({ [name]: value },
    // () => {
    //     validateFieldAddress(name, value);
    //         bindValue(name, value)
    // });
  };
  const bindValue = (fieldname, value) => {
    // data[fieldname] = value;
  };

  const validateFieldAddress = (fieldName, value) => {
    let fieldValidationErrors = state.formErrors;
    let address_typeValid = state.address_typeValid;
    let addressLine1Valid = state.addressLine1Valid;
    let countryValid = state.countryValid;
    let stateValid = state.stateValid;
    let cityValid = state.cityValid;
    let phoneValid = state.phoneValid;
    let zipValid = state.zipValid;

    switch (fieldName) {
      case "address_type":
        if (value != "Address Type") {
          address_typeValid = true;
          user["address_type"] = value;
          fieldValidationErrors.address_type = "";
        } else {
          fieldValidationErrors.address_type = "Please select address type.";
          address_typeValid = false;
        }
        break;

      case "addressLine1":
        if (value != "") {
          addressLine1Valid = true;
          user["addressline_1"] = value;
          fieldValidationErrors.addressLine1 = "";
        } else {
          fieldValidationErrors.addressLine1 = "Please enter Address.";
          addressLine1Valid = false;
        }
        break;

      case "addressLine2":
        user["addressline_2"] = value;

        break;

      case "country":
        if (value != "Select Country") {
          countryValid = true;
          user["country"] = value;
          let selected_country = Country.find((i) => i.id === parseInt(value));
          console.log(selected_country);
          let states = States.filter(
            (i) => i.country_id === selected_country.id.toString()
          );
          //setState({selected_states : states})
          fieldValidationErrors.country = "";
        } else {
          fieldValidationErrors.country = "Please select country.";
          countryValid = false;
        }
        break;

      case "state":
        if (value != "Select State") {
          stateValid = true;
          user["state"] = value;
          let item1 = States.find((i) => i.name === value);
          console.log(item1.id);
          let cities = Cities.filter((i) => i.state_id === item1.id);
          //console.log(cities);
          //setState({selected_cities : cities})
          fieldValidationErrors.state = "";
        } else {
          fieldValidationErrors.state = "Please select state.";
          stateValid = false;
        }
        break;

      case "city":
        if (value != "Select City") {
          cityValid = true;
          user["city"] = value;
          fieldValidationErrors.city = "";
        } else {
          fieldValidationErrors.city = "Please select city.";
          cityValid = false;
        }
        break;

      case "phone":
        if (value != "") {
          if (value.match(/^([0|\+[0-9]{1,5})?([1-9][0-9]{9})$/)) {
            phoneValid = true;
            user["phone"] = value;
            fieldValidationErrors.phone = "";
          } else {
            fieldValidationErrors.phone = "Please enter valid contact number .";
            phoneValid = false;
          }
          break;
        } else {
          fieldValidationErrors.phone = "Please enter contact number.";
          phoneValid = false;
        }
        break;

      case "zip":
        if (value != "") {
          if (value.match(/^[a-zA-Z0-9]{6}$/)) {
            zipValid = true;
            user["zip"] = value;
            fieldValidationErrors.zip = "";
          } else {
            fieldValidationErrors.zip = "Please enter valid zip code .";
            zipValid = false;
          }
          break;
        } else {
          fieldValidationErrors.zip = "Please enter zip code.";
          zipValid = false;
        }
        break;
    }

    //setState({
    //     formErrors: fieldValidationErrors,
    //     address_typeValid: address_typeValid,
    //     addressLine1Valid: addressLine1Valid,
    //     countryValid: countryValid,
    //     stateValid: stateValid,
    //     cityValid: cityValid,
    //     phoneValid: phoneValid,
    //     zipValid: zipValid

    // }, validateAddressForm);
  };

  const validateAddressForm = () => {
    //setState({
    //     formValidAddress: (state.address_typeValid && state.addressLine1Valid && state.countryValid && state.stateValid && state.cityValid && state.phoneValid && state.zipValid  )
    // }, console.log(state.formValidAddress))
  };

  const submitAddr = () => {
    if (!availabelAddress.includes(state.count)) {
      // address.push(addrData)
      //setState({addbtnstate : !state.addbtnstate })
      availabelAddress.push(state.count);
    }
  };

  const save = () => {
    user["userType"] = "seller";
    user["is_staff"] = 1;
    axios
      .post(Env.url + "/user/signup", user, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          toast.success("Seller added Successfully.", {
            position: toast.POSITION.TOP_CENTER,
          });

          setTimeout(function () {
            window.location = "#/customer";
          }, 3000);
        }
      });
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col xs="12" md="12" className="mb-4">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: state.activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                General
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col xs="12" sm="4">
                  <InputGroup className="mb-3">
                    <InputGroupAddon>
                      {" "}
                      <InputGroupText>
                        <i className="icon-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="first_name"
                      onChange={(e) => handleUserInput(e)}
                      type="text"
                      maxlength="20"
                      placeholder="First name"
                      value={state.first_name}
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.first_name}</p>
                </Col>
                <Col xs="12" sm="4">
                  <InputGroup className="mb-3">
                    <InputGroupAddon>
                      <InputGroupText>
                        <i className="icon-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="last_name"
                      value={state.last_name}
                      onChange={(e) => handleUserInput(e)}
                      type="text"
                      maxlength="20"
                      placeholder="Last name"
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.last_name}</p>
                </Col>
                <Col xs="12" sm="4"></Col>
                <Col xs="12" sm="4">
                  <InputGroup className="mb-3">
                    <InputGroupAddon>
                      <InputGroupText>
                        <i className="icon-phone"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="contact_number"
                      value={state.contact_number}
                      onChange={(e) => handleUserInput(e)}
                      type="text"
                      maxlength="20"
                      placeholder="Contact Number"
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.contact_number}</p>
                </Col>
                <Col xs="12" sm="4">
                  <InputGroup className="mb-3">
                    <Input
                      onChange={(e) => handleUserInput(e)}
                      type="select"
                      name="gender"
                      id="exampleSelect"
                      value={state.gender}
                    >
                      <option>Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Others</option>
                    </Input>
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.gender}</p>
                </Col>
                <Col xs="12" sm="8">
                  <InputGroup className="mb-3">
                    <InputGroupAddon>
                      <InputGroupText>
                        <i className="icon-envelope-letter"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="email"
                      type="text"
                      value={state.email}
                      onChange={(e) => handleUserInput(e)}
                      placeholder="Email"
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.email}</p>
                </Col>
                <Col xs="12" sm="2"></Col>
                <Col xs="12" sm="4">
                  <InputGroup className="mb-3">
                    <InputGroupAddon>
                      {" "}
                      <InputGroupText>
                        <i className="icon-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="password"
                      value={state.password}
                      onChange={(e) => handleUserInput(e)}
                      type="password"
                      maxlength="20"
                      placeholder="Password"
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.password}</p>
                </Col>
                <Col xs="12" sm="4">
                  <InputGroup className="mb-3">
                    <InputGroupAddon>
                      {" "}
                      <InputGroupText>
                        <i className="icon-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="confirm_password"
                      value={state.confirm_password}
                      onChange={(e) => handleUserInput(e)}
                      type="password"
                      maxlength="20"
                      placeholder="Confirm Password"
                    />
                  </InputGroup>
                  <p className="onerror">
                    {" "}
                    {state.formErrors.confirm_password}
                  </p>
                </Col>
                <Col xs="12" sm="4"></Col>
                <Col xs="12" sm="2">
                  <Label htmlFor="appendedPrependedInput">
                    {" "}
                    Address line 1*
                  </Label>
                </Col>
                <Col xs="12" sm="6">
                  <InputGroup className="input-prepend">
                    <Input
                      onChange={(e) => handleUserAddressInput(e)}
                      type="text"
                      name="addressLine1"
                      value={state.addressLine1}
                      placeholder="Address 1"
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.addressLine1}</p>
                </Col>
                <Col xs="12" sm="4"></Col>

                <Col xs="12" sm="2">
                  <Label htmlFor="appendedPrependedInput">
                    {" "}
                    Address line 2*
                  </Label>
                </Col>
                <Col xs="12" sm="6">
                  <InputGroup className="input-prepend">
                    <Input
                      onChange={(e) => handleUserAddressInput(e)}
                      type="text"
                      name="addressLine2"
                      value={state.addressLine2}
                      placeholder="Address 2"
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.addressLine2}</p>
                </Col>

                <Col xs="12" sm="4"></Col>

                <Col xs="12" sm="2">
                  <Label htmlFor="appendedPrependedInput">
                    {" "}
                    Select Address Type
                  </Label>
                </Col>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Input
                      onChange={(e) => handleUserAddressInput(e)}
                      type="select"
                      name="address_type"
                      value={state.address_type}
                      id="exampleSelect"
                    >
                      <option>Address Type</option>
                      <option>Permanent</option>
                      <option>Current</option>
                    </Input>
                    <p className="onerror"> {state.formErrors.address_type}</p>
                  </FormGroup>
                </Col>
                <Col xs="12" sm="4"></Col>
                <Col xs="12" sm="2">
                  <Label htmlFor="appendedPrependedInput"> Country</Label>
                </Col>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Input
                      onChange={(e) => handleUserAddressInput(e)}
                      type="select"
                      name="country"
                      value={state.country}
                      id="exampleSelect"
                    >
                      <option>Select Country</option>
                      {Country
                        ? Country.map((ct, i) => (
                            <option value={ct.id}>{ct.name}</option>
                          ))
                        : "Country not found"}
                    </Input>
                  </FormGroup>
                  <p className="onerror"> {state.formErrors.country}</p>
                </Col>
                <Col xs="12" sm="4"></Col>
                <Col xs="12" sm="2">
                  <Label htmlFor="appendedPrependedInput"> State</Label>
                </Col>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Input
                      onChange={(e) => handleUserAddressInput(e)}
                      type="select"
                      name="state"
                      value={state.state}
                      id="exampleSelect"
                    >
                      <option>Select State</option>
                      {state.selected_states
                        ? state.selected_states.map((ct, i) => (
                            <option>{ct.name}</option>
                          ))
                        : "State not found"}
                    </Input>
                  </FormGroup>
                  <p className="onerror"> {state.formErrors.state}</p>
                </Col>
                <Col xs="12" sm="4"></Col>
                <Col xs="12" sm="2">
                  <Label htmlFor="appendedPrependedInput"> City</Label>
                </Col>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Input
                      onChange={(e) => handleUserAddressInput(e)}
                      type="select"
                      name="city"
                      value={state.city}
                      id="exampleSelect"
                    >
                      <option>Select City</option>
                      {state.selected_cities
                        ? state.selected_cities.map((ct, i) => (
                            <option>{ct.name}</option>
                          ))
                        : "State not found"}
                    </Input>
                  </FormGroup>
                  <p className="onerror"> {state.formErrors.city}</p>
                </Col>
                <Col xs="12" sm="4"></Col>
                <Col xs="12" sm="2">
                  <Label htmlFor="appendedPrependedInput"> Zip*</Label>
                </Col>
                <Col xs="12" sm="6">
                  <InputGroup className="input-prepend">
                    <Input
                      onChange={(e) => handleUserAddressInput(e)}
                      type="text"
                      maxLength="6"
                      name="zip"
                      value={state.zip}
                      placeholder="Zip code"
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.zip}</p>
                </Col>
                <Col xs="12" sm="4"></Col>
                <Col xs="12" sm="2">
                  <Label htmlFor="appendedPrependedInput"> Phone*</Label>
                </Col>
                <Col xs="12" sm="6">
                  <InputGroup className="input-prepend">
                    <Input
                      onChange={(e) => handleUserAddressInput(e)}
                      type="text"
                      name="phone"
                      value={state.phone}
                      placeholder="Phone"
                    />
                  </InputGroup>
                  <p className="onerror"> {state.formErrors.phone}</p>
                </Col>
              </Row>
              <br />

              <Row>
                <Col xs="12" sm="5"></Col>
                <Col col="12" sm="2">
                  <Button
                    block
                    color="warning"
                    size="sm"
                    onClick={() => save()}
                  >
                    <b>Save</b>
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default Customer;
