import React from 'react';

export const exchangeRates = {
    "CAD":1.3359101124,
    "HKD":7.7696179775,
    "ISK":127.8202247191,
    "PHP":50.5959550562,
    "DKK":6.7170337079,
    "HUF":301.0876404494,
    "CZK":22.7775280899,
    "GBP":0.7806741573,
    "RON":4.3211685393,
    "SEK":9.4880898876,
    "IDR":14115.002247191,
    "INR":73.2606741573,
    "BRL":4.507505618,
    "RUB":65.8139325843,
    "HRK":6.7279101124,
    "JPY":107.4876404494,
    "THB":31.3402247191,
    "CHF":0.9570337079,
    "EUR":0.8988764045,
    "MYR":4.1789662921,
    "BGN":1.7580224719,
    "TRY":6.0968988764,
    "CNY":6.9226067416,
    "NOK":9.263011236,
    "NZD":1.5880449438,
    "ZAR":15.2614831461,
    "USD":1.0,
    "MXN":19.2453932584,
    "SGD":1.3842696629,
    "AUD":1.5095730337,
    "ILS":3.4562696629,
    "KRW":1181.9865168539,
    "PLN":3.8622921348
};

export const currencies = [
    "CAD",
    "HKD",
    "ISK",
    "PHP",
    "DKK",
    "HUF",
    "CZK",
    "GBP",
    "RON",
    "SEK",
    "IDR",
    "INR",
    "BRL",
    "RUB",
    "HRK",
    "JPY",
    "THB",
    "CHF",
    "EUR",
    "MYR",
    "BGN",
    "TRY",
    "CNY",
    "NOK",
    "NZD",
    "ZAR",
    "USD",
    "MXN",
    "SGD",
    "AUD",
    "ILS",
    "KRW",
    "PLN"
].sort();
  
export const CurrencyContext = React.createContext({
    currency:'USD', // default value
    changeCurrency:()=>{console.error('change currency function not found')},
    exchangeRates:{},
    // convertCurrency:convertCurrency,
    // convertToDollars:convertToDollars
});

export const convertCurrency = (dollarValue, currency='USD')=>{
    if(dollarValue!==null){
        return dollarValue*exchangeRates[currency];
    }else{
        return null
    }
}

export const convertToDollars = (value, currency='USD')=>{
    if(value){
        return value/exchangeRates[currency];
    }else{
        return null
    }
}

export const convertPricingBreakdown = (pricingObj, currency='USD')=>{
    if(pricingObj){
        const { basePrice,
            shippingCharges,
            yinnCharges,
            paymentProviderCharges,
            } = pricingObj
        let total = 0
        total += (Math.round(convertCurrency(basePrice, currency) * 100) / 100)
        total += (Math.round(convertCurrency(shippingCharges, currency) * 100) / 100)
        total += (Math.round(convertCurrency(yinnCharges, currency) * 100) / 100)
        total += (Math.round(convertCurrency(paymentProviderCharges, currency) * 100) / 100)
        const newPricingObj = {...pricingObj}
        newPricingObj.total = total?.toFixed(2)
        return newPricingObj
    }else{
        return null
    }
}

