import React, { useState } from "react";
import { Card, CardContent, Button, Box, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { updateAdminPassword } from "../../redux/reducers/admin";
import { logout } from "../../redux/reducers/auth";
import { toast } from "react-toastify";
import "./updatedpassword.css";

const UpdateAdminPassword = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.admin);

  const [passDetails, setPassDetails] = useState({
    newPassword: "",
    currPassword: "",
    confirmPassword: "",
  });

  const handleUpdatePassword = async () => {
    if (passDetails.newPassword !== passDetails.confirmPassword) {
      return toast.error("Passwords didn't match, please confirm again");
    }

    await dispatch(
      updateAdminPassword({
        currPassword: passDetails.currPassword,
        newPassword: passDetails.newPassword,
      })
    ).then(() => {
      dispatch(logout());
    });
  };

  const onHandleChangePassword = (event) => {
    event.persist();
    setPassDetails((passDetails) => {
      return { ...passDetails, [event.target.name]: event.target.value.trim() };
    });
  };

  return (
    <div className="main-div">
      <div className="card-box">
        <h5 className="text-center ">Update Password</h5>
        <label for="prevpassword">Previous Password</label>
        <input
          type="password"
          name="currPassword"
          id="prevpassword"
          value={passDetails.currPassword}
          onChange={(e) => onHandleChangePassword(e)}
          className="input-box"
        />
        <label for="newpassword">New Password</label>
        <input
          type="password"
          name="newPassword"
          id="newpassword"
          onChange={(e) => onHandleChangePassword(e)}
          value={passDetails.newPassword}
          className="input-box"
        />
        <label for="confirmPassword">Confirm Password</label>
        <input
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          onChange={(e) => onHandleChangePassword(e)}
          value={passDetails.confirmPassword}
          className="input-box"
        />
        <button
          className="update-password-button"
          onClick={(e) => handleUpdatePassword()}
        >
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <i
                className="fas fa-spinner fa-pulse"
                style={{ color: "green" }}
              ></i>
            </div>
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default UpdateAdminPassword;
