import React, { useEffect, useState } from "react";
import "./TranslationField.css";
import { useDispatch, useSelector } from "react-redux";
import { TranslationContent } from "../../../redux/reducers/admin";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { getAllWebContent } from "../../../redux/reducers/user";
import { toast, ToastContainer } from "react-toastify";

const FooterField = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  const { allWebContent } = useSelector((state) => state?.user);

  const footerData = allWebContent?.[0]?.footer;

  //   let contant = allWebContent[0]?.footer?.contant;
  //   let contactUs = allWebContent[0]?.footer?.contactUs;
  //   let copyright = allWebContent[0]?.footer?.copyright;
  //   let shopAt = allWebContent[0]?.footer?.shopAt;
  const [diable, setDiable] = useState(true);

  const [formData, setFormData] = useState({
    page: "footer",
    isChange: false,
    contant: footerData?.contant || "",
    contactUs: footerData?.contactUs || "",
    copyright: footerData?.copyright || "",
    shopAt: footerData?.shopAt || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiable(false);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    setDiable(true);
  };

  useEffect(() => {
    setFormData({ ...formData, ...footerData, isChange: false });
  }, [footerData]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <center>
        <h1 className="mt-3">Footer Page</h1>
      </center>
      <form onSubmit={handleSubmit} className="mx-5">
        <div className="form-group">
          <label>Footer Content:</label>
          <input
            type="text"
            name="contact"
            value={formData.contant}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Contact Us:</label>
          <input
            type="text"
            name="contactUs"
            value={formData.contactUs}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Copyright:</label>
          <input
            type="text"
            name="copyright"
            value={formData.copyright}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Shop At:</label>
          <input
            type="text"
            name="shopAt"
            value={formData.shopAt}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default FooterField;
