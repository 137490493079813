import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  CardHeader,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Label,
  Table,
} from "reactstrap";
import {
  getSingleOrder,
  updateItemOrder,
} from "../../../redux/reducers/seller";
import "./vieworder.css";
import { ToastContainer, toast } from "react-toastify";
import SkyLight from "react-skylight";

const ViewOrder = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const otherOrderTranslation =
    translationState?.[0]?.translations?.otherOrder?.[userLanguage];
  const productDetailTranslation =
    translationState?.[0]?.translations?.productDetail?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  const urlArray = history.location.pathname.split("/");
  const orderId = urlArray[urlArray.length - 1];
  const trackinModal = useRef(null);
  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const { singleOrder, loading } = useSelector((state) => state.seller);
  const [selectedProductId, setSelectedProductId] = useState(-1);
  const [activeTab, setActiveTab] = useState("1");
  const [trackingDetails, setTrackingDetails] = useState({
    carrier: "",
    trackingId: "",
    shipmentDate: new Date(),
    deliveryDate: new Date(),
    trackingLink: "",
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTrackingDetails({
      ...trackingDetails,
      [name]: value,
    });
  };
  useEffect(() => {
    dispatch(getSingleOrder(orderId));
  }, []);
  const carriers = ["USPS", "UPS", "FedEx"];
  const editShipment = (product) => {
    setSelectedProductId(product._id);
    setTrackingDetails(
      product.trackingDetails || {
        carrier: "",
        trackingId: "",
        shipmentDate: new Date(),
        deliveryDate: new Date(),
        trackingLink: "",
      }
    );
    trackinModal.current.show();
  };
  const SaveDetails = () => {
    const { carrier, trackingId, shipmentDate, deliveryDate, trackingLink } =
      trackingDetails;
    if (
      carrier == "" ||
      trackingId == "" ||
      shipmentDate == "" ||
      deliveryDate == "" ||
      trackingLink == ""
    ) {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "All fields are required"
        }`
      );
    } else {
      const data = {
        OId: orderId,
        product: selectedProductId,
        type: "tracking",
        ...trackingDetails,
      };
      dispatch(updateItemOrder(data)).then(() => {
        trackinModal.current.hide();
        setTrackingDetails({
          carrier: "",
          trackingId: "",
          shipmentDate: moment(),
          deliveryDate: moment().add(5, "days"),
          trackingLink: "",
        });
        setSelectedProductId(-1);
        dispatch(getSingleOrder(orderId));
      });
    }
  };
  var myBigGreenDialog = {
    width: "40%",
    minHeight: "20%",
  };
  const updateStatus = (product, orderStatus) => {
    const data = {
      OId: orderId,
      product,
      status: orderStatus,
    };
    dispatch(updateItemOrder(data)).then(() => {
      toast.success(
        `${
          toastTranslation?.success
            ? toastTranslation?.success
            : "Order Status Updated Successfully"
        }`
      );
      dispatch(getSingleOrder(orderId));
    });
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={trackinModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>
                {otherOrderTranslation?.shipment
                  ? otherOrderTranslation?.shipment
                  : "Add Shipment Details"}
              </h4>
            </b>
          </p>
        }
      >
        <div>
          <FormGroup>
            <Label>
              {otherOrderTranslation?.selectCarrier
                ? otherOrderTranslation?.selectCarrier
                : "Select carrier"}
            </Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroup>
                  <Input
                    type="select"
                    name="carrier"
                    disabled={userType === "admin"}
                    onChange={(e) => handleInputChange(e)}
                    value={trackingDetails.carrier}
                  >
                    {carriers.map((carrier, i) => (
                      <option value={carrier}> {carrier} </option>
                    ))}
                  </Input>
                </InputGroup>
              </InputGroup>
            </div>
            <div className="controls">
              <Label>
                {otherOrderTranslation?.deliverDate
                  ? otherOrderTranslation?.deliverDate
                  : "Delivery Date"}
              </Label>
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  disabled={userType === "admin"}
                  placeholder={
                    otherOrderTranslation?.deliverDate
                      ? otherOrderTranslation?.deliverDate
                      : "Delivery Date"
                  }
                  name="deliveryDate"
                  defaultValue={trackingDetails.deliveryDate}
                  onChange={(e) => handleInputChange(e)}
                  type="date"
                />
              </InputGroup>
            </div>

            <Label>
              {otherOrderTranslation?.trakingId
                ? otherOrderTranslation?.trakingId
                : "Tracking id"}
            </Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  disabled={userType === "admin"}
                  placeholder={
                    otherOrderTranslation?.trakingId
                      ? otherOrderTranslation?.trakingId
                      : "Tracking id"
                  }
                  name="trackingId"
                  value={trackingDetails.trackingId}
                  onChange={(e) => handleInputChange(e)}
                  type="text"
                />
              </InputGroup>
            </div>

            <Label>
              {" "}
              {otherOrderTranslation?.carrierLink
                ? otherOrderTranslation?.carrierLink
                : "Carrier link"}
            </Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  disabled={userType === "admin"}
                  placeholder="e.g.https://www.fedex.com/apps/fedextrack/?action=track"
                  name="trackingLink"
                  value={trackingDetails.trackingLink}
                  onChange={(e) => handleInputChange(e)}
                  type="text"
                />
              </InputGroup>
            </div>
          </FormGroup>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            {userType === "admin" ? (
              ""
            ) : (
              <Button
                disabled={userType === "admin"}
                onClick={() => SaveDetails()}
                size="md"
                color="warning"
              >
                {digitalProductTranslation?.saveButton
                  ? digitalProductTranslation?.saveButton
                  : "Save"}
              </Button>
            )}

            <Col xs="12" sm="1"></Col>
            <Button
              size="md"
              onClick={() => trackinModal.current.hide()}
              color="danger"
            >
              {digitalProductTranslation?.cancel
                ? digitalProductTranslation?.cancel
                : "Cancel"}
            </Button>
          </Row>
        </div>
      </SkyLight>
      <Row>
        <Col xs="12" md="12" className="mb-4">
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{
                  backgroundColor: activeTab == "1" ? "#2f353a" : "#858585",
                }}
                onClick={() => {
                  setActiveTab("1");
                }}
                Label="*Order Summary"
              >
                {otherOrderTranslation?.summary
                  ? otherOrderTranslation?.summary
                  : "*Order Summary"}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col xs="12" sm="12" md="12">
                  <Card>
                    <CardHeader>
                      <Label htmlFor="appendedPrependedInput">
                        <b>
                          {otherOrderTranslation?.fullfill
                            ? otherOrderTranslation?.fullfill
                            : "Fullfillment Orders"}{" "}
                        </b>
                      </Label>
                    </CardHeader>
                    {loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "160px",
                          marginBottom: "160px",
                        }}
                      >
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", marginHorizontal: "auto" }}
                        ></i>
                      </div>
                    ) : (
                      <CardBody className="card-body">
                        <Row>
                          <Col sm="6">
                            <Label htmlFor="appendedPrependedInput">
                              <b>
                                {otherOrderTranslation?.address
                                  ? otherOrderTranslation?.address
                                  : "Address"}{" "}
                              </b>
                            </Label>
                            <div>
                              <div>
                                {singleOrder.shippingAddress != undefined && (
                                  <div>
                                    {singleOrder.shippingAddress.addressLineOne}
                                    ,&nbsp;
                                    {singleOrder.shippingAddress.addressLineTwo}
                                    ,&nbsp;{singleOrder.shippingAddress.city}
                                    ,&nbsp;{singleOrder.shippingAddress.state}
                                    ,&nbsp;{singleOrder.shippingAddress.country}
                                    ,&nbsp;{singleOrder.shippingAddress.zipCode}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>

                          <Col sm="6">
                            <Label htmlFor="appendedPrependedInput">
                              <b>
                                {otherOrderTranslation?.orderNO
                                  ? otherOrderTranslation?.orderNO
                                  : "Order Number"}{" "}
                              </b>
                            </Label>
                            <br />
                            {singleOrder.orderId}
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col sm="6">
                            <Label htmlFor="appendedPrependedInput">
                              <b>
                                {otherOrderTranslation?.type
                                  ? otherOrderTranslation?.type
                                  : "Payment type"}{" "}
                              </b>
                            </Label>
                            <div style={{ textTransform: "capitalize" }}>
                              {singleOrder.paymentMethod}
                            </div>
                          </Col>
                          <Col sm="6">
                            {singleOrder.paymentMethod === "paypal" && (
                              <div>
                                <Label htmlFor="appendedPrependedInput">
                                  <b> Transaction email</b>
                                </Label>
                                <br />
                                <div>{singleOrder.transaction?.email}</div>
                              </div>
                            )}
                            {singleOrder.paymentMethod == "card" && (
                              <div>
                                <Label htmlFor="appendedPrependedInput">
                                  <b> Transaction id</b>
                                </Label>
                                <br />
                                <div>
                                  {singleOrder.transaction?.transactionId}
                                </div>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                          <Col sm="6">
                            {otherOrderTranslation?.paraOne
                              ? otherOrderTranslation?.paraOne
                              : "Kindly add tracking id for all orders to get paid."}
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col sm="12">
                            <Table
                              hover
                              bordered
                              striped
                              responsive
                              size="sm"
                              border="1"
                            >
                              <thead align="center">
                                <tr className="header-color">
                                  <th>
                                    {productDetailTranslation?.productId
                                      ? productDetailTranslation?.productId
                                      : "Product ID"}
                                  </th>
                                  <th>
                                    {productTranslation?.name
                                      ? productTranslation?.name
                                      : "Name"}{" "}
                                  </th>
                                  <th>
                                    {productTranslation?.status
                                      ? productTranslation?.status
                                      : "Status"}{" "}
                                  </th>
                                  <th>
                                    {productTranslation?.status
                                      ? productTranslation?.status
                                      : "Quantity"}{" "}
                                  </th>
                                  <th>
                                    {walletTranslation?.amount
                                      ? walletTranslation?.amount
                                      : "Amount"}
                                  </th>
                                  <th>
                                    {walletTranslation?.method
                                      ? walletTranslation?.method
                                      : "Payment Method"}
                                  </th>
                                  <th>
                                    {productTranslation?.status
                                      ? productTranslation?.status
                                      : "Payment status"}
                                  </th>
                                  <th>
                                    {otherOrderTranslation?.shipment
                                      ? otherOrderTranslation?.shipment
                                      : "Shipment information"}{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {singleOrder?.products?.length > 0
                                  ? singleOrder?.products?.map(
                                      (product, index) => (
                                        <tr>
                                          <td align="center">
                                            {product?.product?._id}
                                          </td>
                                          <td> {product?.product?.name}</td>
                                          <td>
                                            <select
                                              type="select"
                                              className="order-status-input"
                                              name="country"
                                              value={product.orderStatus}
                                              onChange={(e) =>
                                                updateStatus(
                                                  product._id,
                                                  e.target.value
                                                )
                                              }
                                              disabled={userType === "admin"}
                                            >
                                              {[
                                                `${
                                                  otherOrderTranslation?.pending
                                                    ? otherOrderTranslation?.pending
                                                    : "Pending"
                                                }`,
                                                `${
                                                  otherOrderTranslation?.acknoeledge
                                                    ? otherOrderTranslation?.acknoeledge
                                                    : "Acknowledged"
                                                }`,
                                                `${
                                                  otherOrderTranslation?.deliver
                                                    ? otherOrderTranslation?.deliver
                                                    : "Delivered"
                                                }`,
                                                `${
                                                  otherOrderTranslation?.shipped
                                                    ? otherOrderTranslation?.shipped
                                                    : "Shipped"
                                                }`,
                                                `${
                                                  otherOrderTranslation?.cancelled
                                                    ? otherOrderTranslation?.cancelled
                                                    : "Cancelled"
                                                }`,
                                              ].map((item, index) => (
                                                <option key={item} value={item}>
                                                  {item}
                                                </option>
                                              ))}
                                            </select>
                                          </td>
                                          <td className="pl-2">
                                            {product.qty}
                                          </td>
                                          <td className="pl-2">
                                            {product.unitPrice * product.qty}
                                          </td>
                                          <td
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {singleOrder.paymentMethod}
                                          </td>
                                          <td>
                                            {singleOrder.isInvoicePaid
                                              ? "Paid"
                                              : "UnPaid"}
                                          </td>
                                          <td align="center">
                                            <Button
                                              type="submit"
                                              onClick={(e) =>
                                                editShipment(product)
                                              }
                                              color="warning"
                                              btn="sm"
                                            >
                                              <b>
                                                {userType === "admin"
                                                  ? "View shipment"
                                                  : `${
                                                      otherOrderTranslation?.update
                                                        ? otherOrderTranslation?.update
                                                        : "Update Shipment"
                                                    }`}
                                              </b>
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    )
                                  : ""}
                              </tbody>
                            </Table>
                          </Col>
                          <Col xs="12" sm="4">
                            <div className="controls">
                              <InputGroup className="input-prepend"></InputGroup>
                            </div>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="4"></Col>
                          <Col xs="12" sm="4">
                            <div className="controls">
                              <InputGroup className="input-prepend"></InputGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    )}
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default ViewOrder;
