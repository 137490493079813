import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import home_en from "./en/home.json";
import home_es from "./es/home.json";
import content_en from "./en/content.json";
import content_es from "./es/content.json"

const resources = {
  en: {
    translation: {
      home: home_en,
      content: content_en,
    },
  },
  es: {
    translation: {
      home: home_es,
      content: content_es
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
