import React, { Component } from "react";
import { Slide, ButtonBack, ButtonNext, Dot, Image, WithStore, ImageWithZoom } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./ProductDetailsCarousel.css";
import { Button, Col, Row } from "reactstrap";
import { CurrencyContext } from "../../../contexts/currency-context";
import ReactHtmlParser from "react-html-parser";
import RatingWidget from "../ProductDetail/RatingWidget";
import Slider from "react-slick";

let timeout = null;

const ProductDetailsCarousel = (props) => {
   let state = {
      activeSlide: 0,
   };

   const componentDidMount = () => {
      props.carouselStore.setStoreState({ currentSlide: 0 });
   }

   const onChangeSlide = (direction) => {
      if (direction === "foreward") {
         //setState((state) => ({
         //    activeSlide: state.activeSlide + 1,
         // }));
      } else {
         //setState((state) => ({
         //    activeSlide: state.activeSlide - 1,
         // }));
      }
   };

   const getStarRating = (item) => {
      let price = item ? item.sales_price : null;
      if (price) {
         if (price < 20) {
            return 1;
         } else if (price < 100) {
            return 2;
         } else if (price < 500) {
            return 3;
         } else if (price < 1500) {
            return 4;
         } else {
            return 5;
         }
      } else {
         return null;
      }
   }

   const onSuggestedPriceChange = (value, currency, convertToDollars) => {
      // Clear the timeout if it has already been set.
      // This will prevent the previous task from executing
      // if it has been less than <MILLISECONDS>
      clearTimeout(timeout);
      if (value === "") return;

      // Make a new timeout set to go off in 1000ms (1 second)
      timeout = setTimeout(() => {
         //setState({
         //    suggestedPrice: value,
         // });
         // handleSuggestPriceClick(value, currency, convertToDollars);
      }, 600);
   };
      const settings = {
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1,
         dots: true,
         initialSlide: 0,

         appendDots: (dots) => <ul>{dots}</ul>,
         customPaging: (i) => (
            <div className="ft-slick__dots--custom">
               <div className="loading"></div>
            </div>
         ),
      };

      const { sliderItems, currentSlide, handleCheckNow } = props;

      // nextButtonRef && currentSlide == -1 && props.carouselStore.setStoreState({ currentSlide: 0 });

      return (
         <Row className="justify-content-center position-relative carousal-slider">
            {/* <Col xs="12" className="banner-column" style={{height:140, marginLeft: -85}}> */}

            <Slider {...settings}>
               {sliderItems.map((item, index) => {
                  const isResidencySwap = item && item.category && item.category[0] && item.category[0].name === "Residency Swap";
                  return (
                     <CurrencyContext.Consumer>
                        {({ currency, convertCurrency, convertToDollars }) => (
                           // <Slide index={index}>

                           <div className="position-relative items-container">
                              <img className="product-img" src={item.images[0]} />

                              <div className="upper-carousel-inner">
                                 <div className="font-weight-bold name-text">
                                    {item.name}
                                 </div>
                                 <div className="button-text font-weight-light">{item.brand}</div>
                                 <div className="d-flex align-items-center  mt-2">
                                    <div className="attribute-text button-text mr-2">Product Price Rate : </div>
                                    <div>
                                       <RatingWidget product={item} rating={getStarRating(item)} />
                                    </div>
                                 </div>

                                 <div className="attribute-text value-text mt-2">
                                    {isResidencySwap
                                       ? `Suggest a daily rate(${currency}) : `
                                       : `Add value for products : `}
                                 </div>
                                 <div className="d-flex my-1">
                                    <div className="d-flex">
                                       <input
                                          
                                          className={
                                             state.hasPriceError || !state.hasMadeOffer
                                                ? "price-input"
                                                : "price-input-accepted"
                                          }
                                          type="number"
                                          min={0}
                                          placeholder={`Offer in ${currency}`}
                                          // value={state.suggestedPrice}
                                          onChange={(e) => {
                                             onSuggestedPriceChange(e.target.value, currency, convertToDollars);
                                          }}
                                       />
                                       <div
                                          className={                   
                                                "suggestion-accept ml-0 mr-3"
                                          }
                                       ></div>
                                    </div>
                                    <div className="d-flex">
                                       <button
                                          className="buy-now"
                                          disabled={!(state.hasPriceDisclosed && state.quantityFlag)}
                                          // onClick={(evt) => shipping(item, currency, convertToDollars)}
                                       >
                                          {isResidencySwap ? "Rent Now" : "Buy Now"}
                                       </button>
                                       <button
                                          className="add-to-cart"
                                          disabled={!state.quantityFlag || !state.hasPriceDisclosed}
                                          // onClick={(e) => addTOCart(currency, convertToDollars)}
                                       />
                                    </div>
                                 </div>

                                 <div className="value-text">This value valid for all the products in here</div>
                              </div>
                           </div>
                           //</Slide>
                        )}
                     </CurrencyContext.Consumer>
                  );
               })}
            </Slider>
            {/* <div className="d-flex mt-3 ml-5">
                     {sliderItems.map((item, index) => {
                        if (index === props.currentSlide) {
                           return (
                              <div
                                 style={{
                                    display: "flex",
                                    width: "100px",
                                    height: "19px",
                                    marginRight: "12.5px",
                                    alignItems: "center",
                                 }}
                              >
                                 <Dot
                                    slide={index}
                                    className={index === props.currentSlide ? "selected-dot" : "carousal-dot-custom"}
                                 />
                                 <div className="carousal-position-line"></div>
                              </div>
                           );
                        } else {
                           return (
                              <Dot
                                 slide={index}
                                 className={index === props.currentSlide ? "selected-dot" : "carousal-dot-custom"}
                                 style={{ marginRight: "37px" }}
                              />
                           );
                        }
                     })}
                  </div> */}

            {/* </Col> */}
         </Row>
      );
}

export default WithStore(ProductDetailsCarousel, (state) => ({
   // these are read only properties.  we use the "deepFreeze"
   // npm package to make these properties immutable. You don't have to use
   // all of these, just pick the ones you need.
   currentSlide: state.currentSlide,
}));
