import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from "reactstrap";
import "./shipping.css";
import Summary from "./Summary";
import ProductSummary from "./ProductSummary";
import { useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";

const Shipping = (props) => {
  const {
    shippingInfo,
    setShippingInfo,
    moveStep,
    pricingBreakdown,
    paymentData,
    dataLoading,
    yinnPercentage,
    totalPrice,
    cartData,
  } = props;

  const [states, setStates] = useState([{ name: shippingInfo.state }]);
  const [cities, setCities] = useState([{ name: shippingInfo.city }]);

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const cartProduct = cartData?.[0]?.product?.eventCategory;

  const handleShippingInfoInput = (e) => {
    let { name, value } = e.target;

    if (name === "country") {
      const country = Country.getAllCountries(value).filter(
        (country) => country.name === value
      );
      const filteredStates = State.getStatesOfCountry(country[0]?.isoCode);
      setStates(filteredStates);
      setCities([]);
    } else if (name === "state") {
      const stateObj = State.getAllStates().filter(
        (state) => state.name === value
      )[0];

      const filteredCities = City.getCitiesOfState(
        stateObj.countryCode,
        stateObj.isoCode
      );
      setCities(filteredCities);
    }

    const update = { ...shippingInfo };
    update[name] = value;
    setShippingInfo(update);
  };
  useEffect(() => {
    if (cartProduct === "event") {
      moveStep(2);
    }
  }, []);

  const moveForward = () => {
    const {
      firstname,
      lastname,
      addressLineOne,
      addressLineTwo,
      zipCode,
      country,
      state,
    } = shippingInfo;
    if (
      firstname &&
      lastname &&
      addressLineOne &&
      addressLineTwo &&
      zipCode &&
      country &&
      state
    ) {
      moveStep(2);
    } else {
      toast.error("Complete shipping information first", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Row>
      <ToastContainer autoClose={20000} />
      <Col xs="12" lg="7" className="shipping-form">
        <Row>
          <Col xs="0" lg="1" />
          <Col xs="12" lg="10">
            <div className="shipping-title">
              {shippingCartTranslation?.shippingInfo
                ? shippingCartTranslation?.shippingInfo
                : "Shipping Information"}
            </div>

            <div style={{ marginTop: 30 }} className="shipping-form-input-row">
              <div className="shipping-form-input-label">
                {shippingCartTranslation?.firsrtName
                  ? shippingCartTranslation?.firsrtName
                  : "First Name :"}
              </div>
              <input
                className="shipping-form-input-input"
                name="firstname"
                value={shippingInfo.firstname}
                onChange={handleShippingInfoInput}
              />
            </div>
            <div className="shipping-form-input-row">
              <div className="shipping-form-input-label">
                {shippingCartTranslation?.lastName
                  ? shippingCartTranslation?.lastName
                  : "Last Name :"}
              </div>
              <input
                className="shipping-form-input-input"
                name="lastname"
                value={shippingInfo.lastname}
                onChange={handleShippingInfoInput}
              />
            </div>

            <div className="billing-Address-title">
              {shippingCartTranslation?.shippingAddress
                ? shippingCartTranslation?.shippingAddress
                : "Shipping Address :"}
            </div>
            <div className="billing-separator"></div>

            <div className="shipping-form-input-row">
              <div className="shipping-form-input-label">
                {shippingCartTranslation?.houseNo
                  ? shippingCartTranslation?.houseNo
                  : "House/ Apartment Number :"}
              </div>
              <input
                className="shipping-form-input-input"
                name="addressLineOne"
                value={shippingInfo.addressLineOne}
                onChange={handleShippingInfoInput}
              />
            </div>
            <div className="shipping-form-input-row">
              <div className="shipping-form-input-label">
                {shippingCartTranslation?.street
                  ? shippingCartTranslation?.street
                  : "Street/ Lane :"}
              </div>
              <input
                className="shipping-form-input-input"
                name="addressLineTwo"
                value={shippingInfo.addressLineTwo}
                onChange={handleShippingInfoInput}
              />
            </div>
            <div className="shipping-form-input-row">
              <div className="shipping-form-input-label">
                {shippingCartTranslation?.zipCode
                  ? shippingCartTranslation?.zipCode
                  : "Zip Code :"}
              </div>
              <input
                className="shipping-form-input-input"
                name="zipCode"
                value={shippingInfo.zipCode}
                onChange={handleShippingInfoInput}
              />
            </div>
            <div className="shipping-form-input-row">
              <div className="shipping-form-input-label">
                {shippingCartTranslation?.country
                  ? shippingCartTranslation?.country
                  : "Country :"}
              </div>

              <select
                type="select"
                className="shipping-form-input-input"
                name="country"
                value={shippingInfo.country}
                onChange={handleShippingInfoInput}
              >
                <option value="">
                  {shippingCartTranslation?.country
                    ? shippingCartTranslation?.country
                    : "Select Country"}
                </option>
                {Country.getAllCountries().map((country, index) => (
                  <option
                    style={{ maxWidth: 265 }}
                    key={country.name}
                    value={country.name}
                  >
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="shipping-form-input-row">
              <div className="shipping-form-input-label">
                {shippingCartTranslation?.state
                  ? shippingCartTranslation?.state
                  : "State/ Province :"}
              </div>
              <select
                type="select"
                className="shipping-form-input-input"
                name="state"
                value={shippingInfo.state}
                onChange={handleShippingInfoInput}
              >
                <option value="">
                  {shippingCartTranslation?.state
                    ? shippingCartTranslation?.state
                    : "Select State"}
                </option>
                {states.map((state, index) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="shipping-form-input-row">
              <div className="shipping-form-input-label">
                {shippingCartTranslation?.city
                  ? shippingCartTranslation?.city
                  : "City :"}
              </div>
              <select
                type="select"
                className="shipping-form-input-input"
                name="city"
                value={shippingInfo.city}
                onChange={handleShippingInfoInput}
              >
                <option value="">
                  {shippingCartTranslation?.city
                    ? shippingCartTranslation?.city
                    : "Select City"}
                </option>
                {cities.map((city, index) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="checkout-next-button-container">
              <button
                onClick={moveForward}
                className="checkout-next-button"
                disabled={dataLoading}
              >
                {shippingCartTranslation?.nextButton
                  ? shippingCartTranslation?.nextButton
                  : "Next"}
              </button>
            </div>
          </Col>
          <Col xs="0" lg="1" />
        </Row>
      </Col>
      <Col xs="12" lg="5">
        <Row style={{ marginBottom: 30 }}>
          <Col xs="0" lg="1" />
          <Col xs="12" lg="11">
            <Summary
              yinnPercentage={yinnPercentage}
              totalPrice={totalPrice}
              pricingBreakdown={pricingBreakdown}
              paymentData={paymentData}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="0" lg="1" />
          <Col xs="12" lg="11">
            <ProductSummary {...props} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Shipping;
