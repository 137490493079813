import React, { useEffect } from "react";
import "./Services.css";
import { useHistory } from "react-router-dom";

import market from "../../../assets/img/home/market-place.png";
import finance from "../../../assets/img/home/financial.png";
import stream from "../../../assets/img/home/streaming.png";
import recidency from "../../../assets/img/home/recidency.png";

import rightArrow from "../../../assets/img/arrow-right.svg";
import leftArrow from "../../../assets/img/002-left-arrow.svg";
import downArrow from "../../../assets/img/003-download-arrow.svg";
import upArrow from "../../../assets/img/004-up-arrow.svg";
import { useDispatch , useSelector } from "react-redux";

const Services = () => {
  const dispatch = useDispatch();

  const {translationState , allWebContent} = useSelector((state) => state.user)
  const {userLanguage} = useSelector((state) => state.auth);
  const homeFeature = allWebContent?.[0]?.homeFeature;
  const homeFeatureTranslation = translationState?.[0]?.translations?.homeFeature?.[userLanguage];

  const history = useHistory();
  return (
    <section className="services">
      <div className="row service-section-container">
        <div className="col-md-6 col-xl-5 offset-xl-1 service-section-text mb-4 mb-md-0">
          <div className="service-section-inner">
            <div className="header-font text-center text-md-left mb-3 mb-md-4">
            {homeFeatureTranslation?.featureHeadingOne ? homeFeatureTranslation?.featureHeadingOne : homeFeature?.featureHeadingOne}
              {/* Curated Marketplace */}
            </div>
            <div className="p-large text-center text-md-left">
              <span className="font-weight-bold">YINN{" "}</span> 
              {homeFeatureTranslation?.featureParaOne ? homeFeatureTranslation?.featureParaOne : homeFeature?.featureParaOne}
              {/* helps talented
              creators and artists sell digital and physical products online
              using the innovative PWIW pricing model. From handmade scented
              candles to stylish phone cases, shopping online has never been
              easier. */}
            </div>
          </div>
        </div>
        <div className="col-md-6 pr-md-0">
          <img
            src={market}
            className=" img-fluid d-block mx-auto ml-md-auto mr-md-0"
          />
          <button
            className="btn black-bg-btn flow-btn-right"
            onClick={() => history.push("/search")}
          >
            <span className="mr-4 button-text">
            {homeFeatureTranslation?.linkOne ? homeFeatureTranslation?.linkOne : homeFeature?.linkOne}
              {/* Find What You Want */}
              </span>{" "}
            <img src={rightArrow} alt="" />
          </button>
        </div>
      </div>

      <div className="row service-section-container">
        <div className="col-md-6 col-xl-5 service-section-text mb-4 mb-md-0 order-md-2">
          <div className="service-section-inner">
            <div className="header-font text-center text-md-left mb-3 mb-md-4">
            {homeFeatureTranslation?.featureHeadingTwo ? homeFeatureTranslation?.featureHeadingTwo : homeFeature?.featureHeadingTwo}
              {/* Content Streaming */}
            </div>
            <div className="p-large text-center text-md-left">
            {homeFeatureTranslation?.featureParaTwo ? homeFeatureTranslation?.featureParaTwo : homeFeature?.featureParaTwo}
              {/* Looking for an equitable way to buy, sell, and consume streaming
              media online? You’ve come to the right place.{" "}
              <span className="font-weight-bold">YINN</span> has recently
              brought transparent and trustworthy PWIW pricing to the online
              streaming of music, movies, and games, as well as digital
              advertising. */}
            </div>
          </div>
        </div>
        <div className="col-md-6 pl-md-0">
          <img
            src={stream}
            className=" img-fluid d-block mx-auto mr-md-auto ml-md-0 order-md-1"
          />
          <button
            onClick={() => history.push("/search?type=digital")}
            className="btn black-bg-btn flow-btn-left"
          >
            {" "}
            <span className="mr-4 button-text">
            {homeFeatureTranslation?.linkTwo ? homeFeatureTranslation?.linkTwo : homeFeature?.linkTwo}
              {/* Learn More */}
              </span>{" "}
            <img src={leftArrow} alt="" />
          </button>
        </div>
      </div>

      <div className="row service-section-container">
        <div className="col-md-6 col-xl-5 offset-xl-1 service-section-text mb-4 mb-md-0">
          <div className="service-section-inner">
            <div className="header-font text-center text-md-left mb-3 mb-md-4">
            {homeFeatureTranslation?.featureHeadingThree ? homeFeatureTranslation?.featureHeadingThree : homeFeature?.featureHeadingThree}
              {/* Financial Services */}
            </div>
            <div className="p-large text-center text-md-left">
            {homeFeatureTranslation?.featureParaThree ? homeFeatureTranslation?.featureParaThree : homeFeature?.featureParaThree}
              {/* A rise in online transactions has led to a rise in alternative
              financial services and payment options across the globe. With the{" "}
              <span className="font-weight-bold">YINN coin</span>,{" "}
              <span className="font-weight-bold">YINN</span> provides a stable
              virtual currency backed by the USD.{" "}
              <span className="font-weight-bold">YINN</span> members can also
              take advantage of a unique, in-house crowdfunding platform. */}
            </div>
          </div>
        </div>
        <div className="col-md-6 pr-md-0 position-relative">
          <img
            src={finance}
            className=" img-fluid d-block mx-auto ml-md-auto mr-md-0"
          />
          <button
            onClick={() => history.push("/auth/login")}
            className="btn black-bg-btn flow-btn-right"
          >
            <span className="mr-4 button-text">
            {homeFeatureTranslation?.linkThree ? homeFeatureTranslation?.linkThree : homeFeature?.linkThree}
              {/* Sign Up Free Today */}
              </span>{" "}
            <img src={upArrow} alt="" />
          </button>
        </div>
      </div>

      <div className="row service-section-container">
        <div className="col-md-6 col-xl-5 service-section-text mb-4 mb-md-0 order-md-2">
          <div className="service-section-inner">
            <div className="header-font text-center text-md-left mb-3 mb-md-4">
            {homeFeatureTranslation?.featureHeadingFour ? homeFeatureTranslation?.featureHeadingFour : homeFeature?.featureHeadingFour}
              {/* Residency Swap */}
            </div>
            <div className="p-large text-center text-md-left">
              <span className="font-weight-bold">YINN’</span>s 
              {homeFeatureTranslation?.featureParaFour ? homeFeatureTranslation?.featureParaFour : homeFeature?.featureParaFour}
              {/* Creation
              Residency Swap helps artists and creators arrange trusted,
              transparent housing swaps that make traveling the world more
              accessible and affordable. Find inspiration in a new city or
              country today! */}
            </div>
          </div>
        </div>
        <div className="col-md-6 pl-md-0">
          <img
            src={recidency}
            className=" img-fluid d-block mx-auto mr-md-auto ml-md-0 order-md-1"
          />
          <button
            onClick={() => history.push("/search?type=residency-swap")}
            className="btn black-bg-btn flow-btn-left"
          >
            <span className="mr-4 button-text">
            {homeFeatureTranslation?.linkFour ? homeFeatureTranslation?.linkFour : homeFeature?.linkFour}
              {/* View Locations */}
              </span>{" "}
            <img src={downArrow} alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;
