import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SkyLight from "react-skylight";
import {
  addFilter,
  deleteProduct,
  getProducts,
} from "../../redux/reducers/seller";
import {
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

const Coin = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const deleteProductModal = useRef(null);
  const { products } = useSelector((state) => state.seller);
  const [selectedId, setSelectedId] = useState(0);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: "YINN-COIN",
      })
    );
    dispatch(getProducts());
  }, []);
  const deleteModal = (id) => {
    setSelectedId(id);
    deleteProductModal.current.show();
  };
  const deleteItem = () => {
    dispatch(deleteProduct(selectedId));
    deleteProductModal.current.hide();
    dispatch(getProducts());
  };
  const editCoin = (id) => {
    history.push(`/admin/coin/edit/${id}`);
  };
  var myBigGreenDialog = {
    width: "40%",
    minHeight: "20%",
  };
  return (
    <div className="animated fadeIn">
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={deleteProductModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>Are you sure you want to delete this Product ?</h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            Yes
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            onClick={() => deleteProductModal.current.hide()}
            color="danger"
          >
            No
          </Button>
        </Row>
      </SkyLight>
      <Card>
        <CardHeader>
          <Row>
            <Col xs="8" sm="8" md="10">
              <i className="fa fa-align-justify"></i>{" "}
              <strong>YINN Coins</strong>
            </Col>
            <Col xs="4" sm="4" md="2">
              <Button
                onClick={() => history.push("/admin/coin/add")}
                size="md"
                color="warning"
              >
                <b> Add Coins </b>
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Table hover bordered striped responsive size="sm">
            <thead align="center">
              <tr className="header-color" align="center">
                <th> Number of Coins </th>
                <th> Price </th>
                <th> Maintanance Charge </th>
                <th> Total Price </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {products.length > 0 ? (
                products.map((coin, index) => (
                  <tr>
                    <td>
                      YINN Coin<i className="fas fa-coins"></i>
                      {coin.coinQuantity}
                    </td>
                    <td align="center">{coin.salesPrice}</td>
                    <td align="center">{coin.maintenanceCharge}</td>
                    <td align="center">
                      {parseFloat(coin.maintenanceCharge) +
                        parseFloat(coin.salesPrice)}
                    </td>
                    <td align="center">
                      <i
                        className="fa fa-edit fa-lg mt-4"
                        onClick={(e) => editCoin(coin._id)}
                      ></i>
                      &nbsp;
                      <i
                        className="fa fa-trash fa-lg mt-4"
                        onClick={(e) => deleteModal(coin._id)}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <p style={{ color: "red" }}>Records Not Found</p>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default Coin;
