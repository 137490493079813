import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveDispute } from "../../../redux/reducers/auth";
import {
  Button,
  Label,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  FormGroup,
} from "reactstrap";
import "./dispute.css";
import { ToastContainer, toast } from "react-toastify";
import { AppHeader } from "@coreui/react";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";

const Dispute = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  const {
    user: { userType },
    token,
  } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!token) {
      history.push("/auth/login");
    }
  }, []);
  const [pageContent, setPageContent] = useState({
    customerName: "",
    orderId: "",
    productId: "",
    detailMessage: "",
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const save = () => {
    const { orderId, productId } = pageContent;
    if (productId == "" && orderId == "") {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Product Id OR Order Id is required"
        }`
      );
      return;
    }
    dispatch(saveDispute(pageContent)).then(() => {
      history.push(`/${userType}/profile`);
    });
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <div className="app" style={{ "background-color": "white" }}>
        <Row>
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
        </Row>
        <div className="col-12 col-lg-12 header-content">
          <div className="card">
            <div className="card-block clearfix">
              <i className="fa fa-flag bg-warning p-1 font-4xl mr-1 float-left"></i>
              <div className="h2 text-warning mb-0 mt-h">Report a Dispute</div>
            </div>
          </div>
        </div>
        <div className="main-content">
          <FormGroup>
            <Label>Customer Name......</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="text"
                  name="customerName"
                  value={pageContent.customerName}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Order Id</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="text"
                  name="orderId"
                  value={pageContent.orderId}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Product Id</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="text"
                  name="productId"
                  value={pageContent.productId}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Detail Message</Label>
            <div className="controls">
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  size="16"
                  type="textarea"
                  name="detailMessage"
                  value={pageContent.detailMessage}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>

          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button type="submit" size="md" color="warning" onClick={save}>
              Save
            </Button>
            <Col xs="12" sm="1"></Col>
            <Button
              type="submit"
              size="md"
              onClick={() => history.goBack()}
              color="danger"
            >
              Cancel
            </Button>
          </Row>
        </div>
        <div>
          <DefaultFooter />
        </div>
      </div>
    </div>
  );
};

export default Dispute;
