import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getsingleDispute } from "../../../redux/reducers/seller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row, Button, Card, CardBody, CardHeader } from "reactstrap";
import "./ViewDispute.css";
const ViewDispute = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlArray = history.location.pathname.split("/");
  const disputerId = urlArray[urlArray.length - 1];
  const { singleDispute, loading } = useSelector((state) => state.seller);
  useEffect(() => {
    dispatch(getsingleDispute(disputerId));
  }, []);

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />

      <Row>
        <Col xs="12" lg="12">
          <Card className="mt-5 mx-2">
            <CardHeader>
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="d-flex">
                  <b>View Dispute</b>
                </div>
                <div className="d-flex">
                  <Button
                    onClick={() => history.push("/admin/disputemanagement")}
                    size="sm"
                    color="warning"
                  >
                    {" "}
                    <b>View All</b>{" "}
                  </Button>
                </div>
              </Row>
            </CardHeader>
            {loading ? (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i
                  className="fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green" }}
                ></i>
              </div>
            ) : singleDispute && !loading ? (
              <CardBody>
                <p style={{ fontWeight: "bold", display: "inline-block" }}>
                  Customer Name:{" "}
                </p>{" "}
                {singleDispute.customerName}
                <br />
                <p style={{ fontWeight: "bold", display: "inline-block" }}>
                  Message from Customer:{" "}
                </p>{" "}
                {singleDispute.detailMessage}
                <br />
                <p style={{ fontWeight: "bold", display: "inline-block" }}>
                  Status:{" "}
                </p>{" "}
                {singleDispute.status}
                <br />
                <p style={{ fontWeight: "bold", display: "inline-block" }}>
                  Status By Seller:{" "}
                </p>{" "}
                {singleDispute.sellerStatus}
                <br />
                <p style={{ fontWeight: "bold", display: "inline-block" }}>
                  Created At:{" "}
                </p>{" "}
                {new Date(singleDispute.createdAt).toLocaleString()}
                <br />
                <p style={{ fontWeight: "bold", display: "inline-block" }}>
                  Updated At:{" "}
                </p>{" "}
                {new Date(singleDispute.updatedAt).toLocaleString()}
              </CardBody>
            ) : (
              <td colSpan={6} className="py-5 text-center">
                <p style={{ color: "red" }}>Records Not Found</p>
              </td>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewDispute;
