import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleAnnouncement,
  getAnnouncements,
  readAnnouncements,
  replyAnnouncement,
} from "../../../redux/reducers/admin";
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-picky/dist/picky.css"; // Include CSS

const ViewAnnouncement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlArray = history.location.pathname.split("/");
  const announcementId = urlArray[urlArray.length - 1];
  const [message, setMessage] = useState("");
  const divRef = useRef(null);
  const [customLoader, setCustomLoader] = useState(true);
  const { singleAnnouncement } = useSelector((state) => state.admin);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSingleAnnouncement(announcementId));
  }, [announcementId]);
  useEffect(() => {
    if (user.userType !== "admin" && singleAnnouncement?._id) {
      dispatch(readAnnouncements([announcementId]));
      dispatch(getAnnouncements());
    }
  }, [singleAnnouncement, announcementId]);

  useEffect(() => {
    setCustomLoader(false);
  }, []);

  const send = () => {
    const data = {
      message,
      _id: announcementId,
    };
    dispatch(replyAnnouncement(data)).then(() => {
      setMessage("");
      dispatch(getSingleAnnouncement(announcementId));
    });
  };
  useEffect(() => {
    divRef.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  });
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Row>
        <Col sm="2" md="2"></Col>
        <Col xs="12" sm="8" md="8">
          {customLoader ? (
            <div style={{ marginTop: "240px", textAlign: "center" }}>
              <i
                className="mx-auto fas fa-spinner fa-pulse fa-2x"
                style={{ color: "green" }}
              ></i>
            </div>
          ) : (
            <>
              <Card>
                <CardHeader>
                  <b>View Announcement</b>
                </CardHeader>
                <CardBody className="card-body">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs="4" sm="4" md="4">
                          <Label>
                            <b>Announcement Id</b>
                          </Label>
                        </Col>

                        <Col xs="8" sm="8" md="8">
                          <Label>{singleAnnouncement._id}</Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="4" sm="4" md="4">
                          <Label>
                            <b>Description</b>
                          </Label>
                        </Col>

                        <Col xs="8" sm="8" md="8">
                          <Label>{singleAnnouncement.message}</Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="4" sm="4" md="4">
                          <Label>
                            <b>To</b>
                          </Label>
                        </Col>
                        <Col xs="8" sm="8" md="8">
                          <Row>
                            <Label>{singleAnnouncement.to?.email},</Label>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="4" sm="4" md="4">
                          <Label>
                            <b>Date & Time</b>
                          </Label>
                        </Col>

                        <Col xs="8" sm="8" md="8">
                          <Label>
                            {moment(singleAnnouncement.createdAt).format(
                              "MMM Do YYYY, h:mm:ss a"
                            )}
                          </Label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
              <div className="messages-wrapper">
                <Row
                  className="announcementMessage"
                  style={{ float: user.userType == "admin" ? "right" : "left" }}
                >
                  {singleAnnouncement.message}
                </Row>
                <br />
                {singleAnnouncement.thread?.map((item) => (
                  <>
                    <Row
                      className="announcementMessage"
                      style={{
                        float: item.from == user._id ? "right" : "left",
                      }}
                    >
                      {item.message}
                    </Row>
                    <br />
                  </>
                ))}
                <div ref={divRef} />
              </div>
              <Row className="send-field">
                <InputGroup className="input-prepend">
                  <Input
                    placeholder="Type..."
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    size="16"
                    type="text"
                  />
                  <InputGroupAddon addonType="append">
                    <Button onClick={() => send()} color="warning">
                      Send
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Row>
            </>
          )}
        </Col>
        <Col sm="2" md="2"></Col>
      </Row>
    </div>
  );
};

export default ViewAnnouncement;
