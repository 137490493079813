import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTranslation, getWallet } from "../../redux/reducers/user";
import { getPayouts, addFilter } from "../../redux/reducers/seller";
import {
  Table,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import logo from "../../assets/img/brand/logo.png";
import cookie from "react-cookies";
import axios from "axios";
import Env from "../../environment/environment";
import { ToastContainer, toast } from "react-toastify";
import "./wallet.css";

const Wallet = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { wallet ,translationState  } = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);

  const walletTranslation = translationState?.[0]?.translations?.wallet?.[userLanguage];
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];
  const digitalProductTranslation = translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const { allPayouts, filters, loading } = useSelector((state) => state.seller);
  useEffect(() => {
    dispatch(
      addFilter({
        field: "status",
        value: "Pending",
      })
    );
    dispatch(getWallet());
    dispatch(getPayouts());
  }, []);
  let state = {
    wallet: {},
    balance: 0,
    quantity: 0,
    ads_balance: 0,
    withdrawalAmount: 0,
    pendingPayments: [],
    sellerData: null,
  };
  // constructor(props) {
  //   super(props);

  //   getWalletBalance = getWalletBalance;
  //   getWalletBalance();
  // }

  const componentDidMount = () => {
    getPendingPayments();
    getSellerData();
  };

  const getWalletBalance = () => {
    axios
      .get(Env.url + "/wallet/get/" + cookie.load("_id"), {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          //setState({ wallet: response.data[0] });
          var balance = state.wallet.balance.toFixed(2);
          //setState({ balance: balance });
          var quantity = state.wallet.quantity;
          //setState({ quantity: quantity });
          var ads_balance = state.wallet.ads_balance.toFixed(2);
          //setState({ ads_balance: ads_balance });
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 ||
            err.response.status == 400 ||
            err.response.status == 500 ||
            err.response.status == 404
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const getSellerData = () => {
    axios
      .get(Env.url + "/seller/sellerdetails/get/" + cookie.load("_id"), {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        //setState({ sellerData: response.data.data });
      })
      .catch((err) => {
        window.location = "#/login";
      });
  };

  const transferConfirm = (e) => {
    var wallet = state.wallet;
    if (wallet === undefined) {
      toast.error(`${toastTranslation?.lowBlance ? toastTranslation?.lowBlance : "You don't have sufficient balance."}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (wallet.earnings > 0) {
      //setState({
      //   transferConfirm: !state.transferConfirm,
      // });
    } else {
      toast.error(`${toastTranslation?.lowBlance ? toastTranslation?.lowBlance : "You don't have sufficient balance."}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const hideTransferModal = () => {
    //setState({
    //   transferConfirm: false,
    // });
  };

  const sendPaymentRequest = () => {
    hideTransferModal();
    var data = {
      sellerId: cookie.load("_id"),
      amount: state.withdrawalAmount,
    };

    axios
      .post(Env.url + "/seller/payouts/makepayment/", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        getWalletBalance();
        toast.success(response.data.message);
      })
      .catch((err) => {
        const errorMessage = err.response.data.message || "Error occured";
        toast.error(errorMessage);
      });
  };

  const getPendingPayments = () => {
    axios
      .get(Env.url + "/payments/pendingpayments/" + cookie.load("_id"), {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        console.log(response.data);
        //setState({
        //   pendingPayments: response.data.pendingPayments
        // })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const beutifyValue = (value) => {
    return value ? Math.round(parseFloat(value) * 100) / 100 : 0;
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      {loading ? (
        <div style={{ margin: "240px", textAlign: "center" }}>
          <i
            className="mx-auto fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : (
        <>
          <Row style={{ marginTop: 30 }}>
            <Col xs="2" sm="2" md="2"></Col>
            <Col xs="12" sm="7" md="7">
              <Row>
                <img
                  src={logo}
                  width="100"
                  height="50"
                  style={{ marginTop: "-13px" }}
                  // onClick={(e) => redirectToHome(e)}
                />
                <i class="fas fa-2x fa-wallet" style={{ color: "#ffc907" }}></i>
                &nbsp;&nbsp;<h3> | {walletTranslation?.hOne ? walletTranslation?.hOne : "The trusted & convenient way to pay"}</h3>
              </Row>
              <div class="container">
                <div class="card">
                  <div class="title">
                    <h1>{walletTranslation?.lineONe ? walletTranslation?.lineONe : "YINN Wallet Balance"}</h1>
                  </div>
                  <a>
                    <div class="item item-selected">
                      <div class="title">
                        <h3> $ {beutifyValue(wallet.earnings)} </h3>
                        {walletTranslation?.with ? walletTranslation?.with : "with"}
                        <h5> {wallet.coinBalance} Yn Coins </h5>
                        {walletTranslation?.and ? walletTranslation?.and : "And"}
                        <h5>
                          {" "}
                          ${beutifyValue(wallet.adSpend)} {walletTranslation?.advertisement ? walletTranslation?.advertisement : "Advertisement Payments"}
                        </h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
            <Col sm="3" md="3" lg="3"></Col>
          </Row>
          <Row>
            <Col xs="2" sm="2" md="2" lg="2"></Col>
            <Col xs="12" sm="10" md="10" lg="10">
              <Row>
                <Col sm="6" md="6" lg="6">
                {walletTranslation?.lineTwo ? walletTranslation?.lineTwo : "Transfer money into bank or paypal?"}
                  {state.sellerData && state.sellerData.tranferMethod ? (
                    <a onClick={transferConfirm} style={{ cursor: "pointer" }}>
                      click here
                    </a>
                  ) : (
                    `${walletTranslation?.lineTwo ? " " : "Setup your transaction method in Home Tab"}`
                  )}
                </Col>
                <Col sm="3" md="3" lg="3">
                  {userType !== "admin" && (
                    <div className="flexNullCenter">
                      <i
                        class="far fa-2x fa-plus-square"
                        style={{ color: "#00aced", cursor: "pointer" }}
                        onClick={() => history.push("/yinn-coin")}
                      >
                        &nbsp;
                      </i>
                      <strong> {walletTranslation?.addCoins ? walletTranslation?.addCoins : "Add Coins"}</strong>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={"mt-3"}>
            <Col xs="2" sm="2" md="2"></Col>
            <Col xs="12" sm="7" md="7">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="8" sm="8" md="8">
                      <i className="fa fa-align-justify"></i>{" "}
                      <strong>{walletTranslation?.escrow ? walletTranslation?.escrow : "Payments in escrow"}</strong>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table hover bordered striped responsive size="sm">
                    <thead align="center">
                      <tr className="header-color" align="center">
                        <th>{walletTranslation?.id ? walletTranslation?.id : "Payment Id"} </th>
                        <th>{walletTranslation?.method ? walletTranslation?.method : "Paymnt method"} </th>
                        <th>{walletTranslation?.amount ? walletTranslation?.amount : "amount"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPayouts.length > 0 ? (
                        allPayouts.map((payment, index) => {
                          return (
                            <tr>
                              <td align="center">{payment._id}</td>
                              <td align="center">Local</td>
                              <td align="center">{payment.amount}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <td colSpan={3} style={{ textAlign: "center" }}>
                          <p style={{ color: "red", marginTop: "10px" }}>
                          {walletTranslation?.notFound ? walletTranslation?.notFound : "Records Not Found"}
                          </p>
                        </td>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col xs="2" sm="2" md="2"></Col>
          </Row>
          <Modal
            isOpen={state.transferConfirm}
            // toggle={deleteEbook}
            className={"modal-success " + props.className}
          >
            <ModalHeader>Confirm money withrawal</ModalHeader>
            <ModalBody>
              Enter the withdrawal amont:
              <input
                type="number"
                value={state.withdrawalAmount}
                onChange={(e) => {
                  //setState({ withdrawalAmount: e.target.value })
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={sendPaymentRequest}>
                Yes
              </Button>{" "}
              <Button color="secondary" onClick={(e) => hideTransferModal()}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Wallet;
