import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilter,
  getCategories,
  getProductSubCategories,
  createProductCategory,
  createProductSubCategory,
  createProductSubSubCategory,
  deleteProductCategory,
  deleteProductSubCategory,
  updateProductCategory,
  updateProductSubCategory,
} from "../../../redux/reducers/admin";
import {
  Row,
  Col,
  Button,
  Label,
  FormGroup,
  InputGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import "./category.css";
import SkyLight from "react-skylight";
import { ToastContainer, toast } from "react-toastify";

var myBigGreenDialog = {
  height: "460px",
};

const myDeleteDialog = {
  minHeight: "130px",
};

const AddCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlArray = history.location.pathname.split("/");
  const categoryType = urlArray[urlArray.length - 2];
  const modal = useRef(null);
  const subcategoriesmodal = useRef(null);
  const deleteCategoryModal = useRef(null);
  const [pageContent, setPageContent] = useState({});
  const [selectedId, setSelectedId] = useState(0);
  const [modalheading, setModalHeading] = useState("");
  const [modalType, setModalType] = useState("");
  const { loading } = useSelector((state) => state.admin);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const { productCategories, productSubCategories, err } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: categoryType,
      })
    );
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: "undefined",
      })
    );
    dispatch(getCategories());
  }, [categoryType]);

  let state = {
    category: [],
    element: [],
    prev_id: "",
    show_cat_modal: false,
    show_delete_modal: true,
    itemId: [],
  };
  const showSubcategories = (item) => {
    dispatch(getProductSubCategories(item._id));
    subcategoriesmodal.current.show();
  };

  const showCategoryModal = () => {
    setPageContent({
      name: "",
      description: "",
    });
    setModalType("new-category");
    setModalHeading("Create New Category");
    modal.current.show();
  };
  const createCategory = () => {
    if (modalType === "new-sub-category") {
      saveSubCategory();
    } else if (modalType === "edit-category") {
      editCategory();
    } else if (modalType === "edit-sub-category") {
      editSubCategory();
    } else if (modalType === "new-category") {
      saveCategory();
    } else if (modalType === "new-sub-sub-category") {
      saveSubSubCategory();
    }
  };

  const saveCategory = () => {
    const { name, description } = pageContent;
    if (!name) {
      toast.error("Kindly fill required fields");
      return;
    }
    const data = {
      name: name.trim(),
      description,
      type: categoryType,
    };
    dispatch(createProductCategory(data));
    dispatch(getCategories());
    setPageContent({
      name: "",
      description: "",
    });
    modal.current.hide();
  };

  const editCategoryModal = (data) => {
    setPageContent({
      id: data._id,
      name: data.name,
      description: data.description,
    });
    setModalType("edit-category");
    setModalHeading("Edit Category");
    modal.current.show();
  };

  const editCategory = () => {
    const { name, description, id } = pageContent;
    if (!name) {
      toast.error("Kindly fill required fields");
      return;
    }
    const data = {
      id,
      name,
      description,
      type: categoryType,
    };
    dispatch(updateProductCategory(data));
    dispatch(getCategories());
    setPageContent({
      name: "",
      description: "",
    });
    modal.current.hide();
  };

  const showSubCategoryModal = (item, type) => {
    subcategoriesmodal.current.hide();
    setPageContent({
      category_id: item._id,
      name: "",
      description: "",
    });
    setModalType("new-sub-category");
    setModalHeading("Create New SubCategroy");
    modal.current.show();
  };
  const showSubSubCategoryModal = (item, type) => {
    subcategoriesmodal.current.hide();
    setPageContent({
      subCategoryId: item._id,
      name: "",
      description: "",
    });
    setModalType("new-sub-sub-category");
    setModalHeading("Create New SubCategroy");
    modal.current.show();
  };

  const saveSubCategory = () => {
    const { name, description, category_id } = pageContent;
    if (!name) {
      toast.error("Kindly fill required fields");
      return;
    }
    const data = {
      name: name.trim(),
      description,
      categoryId: category_id,
    };
    dispatch(createProductSubCategory(data));
    dispatch(getCategories());
    setPageContent({
      name: "",
      description: "",
      category_id: null,
    });
    modal.current.hide();
  };
  const saveSubSubCategory = () => {
    const { name, description, subCategoryId } = pageContent;
    if (!name) {
      toast.error("Kindly fill required fields");
      return;
    }
    const data = {
      name: name.trim(),
      description,
      subCategoryId,
    };
    dispatch(createProductSubSubCategory(data));
    dispatch(getCategories());
    setPageContent({
      name: "",
      description: "",
      category_id: null,
      subCategoryId: null,
    });
    modal.current.hide();
  };

  const editSubCategory = () => {
    const { subCategoryId, name, description } = pageContent;
    if (!name) {
      toast.error("Kindly fill required fields");
      return;
    }
    setPageContent({
      subCategoryId: null,
      name: "",
      description: "",
    });
    dispatch(updateProductSubCategory({ subCategoryId, name, description }));
    dispatch(getCategories());
    subcategoriesmodal.current.hide();
    modal.current.hide();
  };

  const editSubCategoryModal = (item) => {
    const { _id, name, description } = item;
    if (!name) {
      toast.error("Kindly fill required fields");
      return;
    }
    subcategoriesmodal.current.hide();
    setPageContent({
      subCategoryId: _id,
      name,
      description,
    });
    setModalType("edit-sub-category");
    setModalHeading("Edit SubCategroy");
    modal.current.show();
  };

  const deleteModal = (id) => {
    setSelectedId(id);
    deleteCategoryModal.current.show();
  };
  const deleteItem = () => {
    dispatch(deleteProductCategory(selectedId)).then(() => {
      deleteCategoryModal.current.hide();
      dispatch(getCategories());
    });
  };
  const deleteSubCategoryItem = (id) => {
    dispatch(deleteProductSubCategory(id)).then(() => {
      subcategoriesmodal.current.hide();
      dispatch(getCategories());
    });
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myDeleteDialog}
        hideOnOverlayClicked
        ref={deleteCategoryModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4 style={{ fontSize: "20px", fontWeight: 600 }}>
                Are you sure you want to delete this Category ?
              </h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            Yes
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            onClick={() => deleteCategoryModal.current.hide()}
            color="danger"
          >
            No
          </Button>
        </Row>
      </SkyLight>
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={modal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h3>{modalheading}</h3>
            </b>
          </p>
        }
      >
        <div hidden={state.show_cat_modal}>
          <FormGroup>
            <Label htmlFor="appendedInput">{state.name_title}</Label>
            <div className="controls">
              <Label>
                <b>
                  Name <span className="required">*</span>{" "}
                </b>
              </Label>
              <InputGroup>
                <Input
                  type="text"
                  name="name"
                  maxlength="35"
                  value={pageContent.name}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </FormGroup>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              onClick={createCategory}
              size="md"
              color="primary"
            >
              {" "}
              Save{" "}
            </Button>
            <Col xs="12" sm="1"></Col>
            <Button
              type="submit"
              size="md"
              onClick={() => modal.current.hide()}
              color="danger"
            >
              {" "}
              Cancel{" "}
            </Button>
          </Row>
        </div>

        <Row
          hidden={state.show_delete_modal}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            {" "}
            Yes{" "}
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            // onClick={() => //simpleDialog.hide()}
            color="danger"
          >
            {" "}
            No{" "}
          </Button>
        </Row>
      </SkyLight>
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={subcategoriesmodal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h3>Sub Categories</h3>
            </b>
          </p>
        }
      >
        <Card style={{ height: "370px" }}>
          <CardHeader>
            <b>Sub Category</b>
          </CardHeader>
          {loading ? (
            <div style={{ textAlign: "center", margin: "130px 0" }}>
              <i
                className="fas fa-spinner fa-pulse fa-2x"
                style={{ color: "green" }}
              ></i>
            </div>
          ) : (
            <CardBody style={{ overflowY: "scroll" }}>
              {productSubCategories.length > 0 ? (
                productSubCategories.map((item, index) => (
                  <div>
                    <Row>
                      <Col xs="12" sm="8" className="category-pointer">
                        <Label
                          className="category-pointer"
                          htmlFor="appendedPrependedInput"
                        >
                          {" "}
                          {item.name}
                        </Label>
                      </Col>
                      <Col className="category-icons" xs="12" sm="4">
                        <i
                          className="fa fa-plus"
                          onClick={(e) =>
                            showSubSubCategoryModal(item, "category")
                          }
                        ></i>
                        <i
                          className="fa fa-edit"
                          onClick={(e) => editSubCategoryModal(item)}
                        ></i>
                        <i
                          className="fa fa-trash"
                          onClick={(e) => deleteSubCategoryItem(item._id)}
                        ></i>
                      </Col>
                    </Row>
                    <hr />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "50px",
                  }}
                >
                  <p>Sub Categories not Available</p>
                </div>
              )}
            </CardBody>
          )}
        </Card>
      </SkyLight>
      <Row>
        <Col xs="12" sm="10"></Col>
        <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
          <Button
            block
            color="warning"
            size="sm"
            onClick={(e) => showCategoryModal()}
            style={{ width: "auto" }}
          >
            <b>Add Category</b>
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <b>Category</b>
            </CardHeader>
            {loading ? (
              <div style={{ textAlign: "center", margin: "130px 0" }}>
                <i
                  className="fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green" }}
                ></i>
              </div>
            ) : (
              <CardBody className="card-body">
                <FormGroup>
                  {productCategories.length > 0
                    ? productCategories.map((item, index) => (
                        <div>
                          <Row>
                            <div className="col-8 category-pointer">
                              <Col xs="12" sm="8" className="category-pointer">
                                <Label
                                  className="category-pointer"
                                  htmlFor="appendedPrependedInput"
                                >
                                  {" "}
                                  {item.name}
                                </Label>
                              </Col>
                            </div>

                            <Col className="category-icons" xs="12" sm="4">
                              <i
                                className="fa fa-eye"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => showSubcategories(item)}
                              ></i>
                              <i
                                className="fa fa-plus"
                                onClick={(e) =>
                                  showSubCategoryModal(item, "category")
                                }
                              ></i>
                              <i
                                className="fa fa-edit"
                                onClick={(e) => editCategoryModal(item)}
                              ></i>
                              <i
                                className="fa fa-trash"
                                onClick={(e) => deleteModal(item._id)}
                              ></i>
                            </Col>
                          </Row>

                          <hr />
                        </div>
                      ))
                    : "No Category Found "}
                </FormGroup>
              </CardBody>
            )}
          </Card>
        </Col>
        {state.element.length > 0
          ? state.element.map((item, index) => (
              <Col xs="12" sm="3">
                {item}
              </Col>
            ))
          : " "}
      </Row>
    </div>
  );
};

export default AddCategory;
