import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  Input,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "./updatepages.css";
import { Editor } from "react-draft-wysiwyg";
import {
  singlePage,
  CMSCategories,
  deleteCMSCategory,
  createCMSCategory,
  updateCMSPage,
} from "../../../redux/reducers/admin";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  getDefaultKeyBinding,
} from "draft-js";
import { AppSwitch } from "@coreui/react";

const UpdatePages = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pageId = new URLSearchParams(history.location.search).get("pageId");
  const { cmsSinglePage, loading } = useSelector((state) => state.admin);
  const { cmsCategories } = useSelector((state) => state.admin);
  const [pageDescription, setPageDescription] = useState({
    editorState: EditorState.createEmpty(),
  });
  const [type, setType] = useState("add");
  const [pageContent, setPageContent] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [categoryModalShow, setCategoryModalShow] = useState(false);
  useEffect(() => {
    dispatch(CMSCategories());
    dispatch(singlePage(pageId));
  }, []);
  useEffect(() => {
    if (cmsSinglePage) {
      const categoryCategory = cmsCategories.find(
        (item) => item._id === cmsSinglePage.cmsCategoryId
      );
      setPageContent({
        ...cmsSinglePage,
        categoryName: categoryCategory?.categoryName,
      });
      setPageDescription({
        editorState: EditorState.createWithContent(
          convertFromRaw({
            entityMap: {},
            blocks: cmsSinglePage?.content?.blocks,
          })
        ),
      });
    }
  }, [cmsSinglePage]);
  const handleCategoryInput = (e) => {
    setCategoryName(e.target.value);
  };
  const openCategoryModal = (e, type) => {
    setCategoryModalShow(true);
    setType(type);
  };
  const hideModal = () => {
    setCategoryModalShow(false);
  };
  const changeProductDescription = (editorState) => {
    setPageDescription({ editorState });
  };
  const saveCategory = () => {
    if (type === "add") {
      if (categoryName == "") {
        toast.error("Please fill all mandatory fields");
        return;
      }
      dispatch(createCMSCategory({ categoryName: categoryName })).then(() =>
        dispatch(CMSCategories())
      );
      setCategoryModalShow(false);
    } else {
      const { categoryName } = pageContent;
      const cmsCategory = cmsCategories.find(
        (item) => item.categoryName === categoryName
      );
      if (!categoryName) {
        toast.error("Please fill all mandatory fields");
        return;
      }
      dispatch(deleteCMSCategory(cmsCategory._id)).then(() => {
        dispatch(CMSCategories());
      });
      setCategoryModalShow(false);
    }
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const updatePage = () => {
    const { title, publish, categoryName } = pageContent;
    const categoryCategory = cmsCategories.find(
      (item) => item.categoryName === categoryName
    );
    const content = convertToRaw(
      pageDescription.editorState.getCurrentContent()
    );
    if (!title || !categoryName || !content || !categoryCategory) {
      toast.error("Please fill all mandatory fields");
      return;
    }
    const data = {
      pageId,
      title,
      publish,
      content,
      cmsCategoryId: categoryCategory._id,
      categoryName,
    };
    dispatch(updateCMSPage(data));
    history.push("/admin/content-management");
  };

  const cancel = () => {
    history.push("/admin/content-management");
  };

  const myKeyBindingFn = (evt) => {
    if (evt.keyCode === 13) {
      return "";
    }
    return getDefaultKeyBinding(evt);
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <Modal
        isOpen={categoryModalShow}
        toggle={saveCategory}
        className={"modal-warning " + props.className}
      >
        {type === "add" ? (
          <ModalHeader>Add New CMS Category</ModalHeader>
        ) : (
          <ModalHeader>Delete Any CMS Category</ModalHeader>
        )}
        <ModalBody>
          <Form action="" method="post" className="form-horizontal">
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="hf-title">
                  Category <span className="required">*</span>
                </Label>
              </Col>
              {type === "add" ? (
                <Col xs="12" md="7">
                  <Input
                    type="text"
                    name="categoryName"
                    onChange={handleCategoryInput}
                    value={categoryName}
                    placeholder="Enter Category..."
                    autoComplete="title"
                    required
                  />
                </Col>
              ) : (
                <Col xs="15" md="7">
                  <Input
                    type="select"
                    name="categoryName"
                    onChange={handleInputChange}
                    value={pageContent.categoryName}
                  >
                    <option>Select Category</option>
                    {cmsCategories
                      ? cmsCategories.map((ct, i) => (
                          <option>{ct.categoryName}</option>
                        ))
                      : "Category not found"}
                  </Input>
                </Col>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={(e) => saveCategory()}>
            {type === "add" ? "Save" : "Delete"}
          </Button>{" "}
          <Button color="danger" onClick={(e) => hideModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col sm="1" md="1"></Col>
        <Col xs="12" sm="10" md="10" className="mt-5">
          <Card>
            <div className="form-horizontal">
              <CardHeader>
                <b>Manage Home Page & Footer Contents</b>
              </CardHeader>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <i
                    className="fas fa-spinner fa-pulse fa-2x"
                    style={{ color: "green", margin: "200px" }}
                  ></i>
                </div>
              ) : (
                <CardBody className="card-body">
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="title">
                        Title <span className="required">*</span>{" "}
                      </Label>
                    </Col>
                    <Col xs="12" md="7">
                      <Input
                        type="text"
                        name="title"
                        placeholder="Enter Title..."
                        autoComplete="title"
                        onChange={handleInputChange}
                        value={pageContent.title}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="select">
                        Category <span className="required">*</span>{" "}
                      </Label>
                    </Col>
                    <Col xs="12" md="7">
                      <Input
                        type="select"
                        name="categoryName"
                        value={pageContent.categoryName}
                        onChange={handleInputChange}
                      >
                        <option>Select Category</option>
                        {cmsCategories
                          ? cmsCategories.map((ct, i) => (
                              <option>{ct.categoryName}</option>
                            ))
                          : "Category not found"}
                      </Input>
                    </Col>
                    <Col md="2">
                      <Row>
                        <i
                          className="fa fa-plus"
                          onClick={(e) => openCategoryModal(e, "add")}
                        >
                          &nbsp;<b>Add New</b>
                        </i>
                      </Row>
                      <Row>
                        <i
                          className="fa fa-minus "
                          onClick={(e) => openCategoryModal(e, "delete")}
                        >
                          &nbsp;<b>Remove</b>
                        </i>
                      </Row>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="Publish">Publish</Label>
                    </Col>
                    <Col md="5">
                      <AppSwitch
                        className={"mx-1"}
                        variant={"pill"}
                        color={"primary"}
                        onChange={handleInputChange}
                        name="publish"
                        checked={pageContent.publish}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col xs="12" md="7">
                      <div className="controls">
                        <Editor
                          toolbarClassName="toolbar-class"
                          placeholder="Product Description"
                          wrapperClassName="demo-wrapper"
                          editorClassName="editer-content"
                          editorState={pageDescription.editorState}
                          onEditorStateChange={changeProductDescription}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </CardBody>
              )}

              <CardFooter>
                <Row>
                  <Col sm="4" md="4">
                    <Button
                      type="submit"
                      size="sm"
                      color="primary"
                      onClick={() => updatePage()}
                      block
                    >
                      <i className="fa fa-dot-circle-o"></i> Submit
                    </Button>
                  </Col>
                  <Col sm="4" md="4">
                    <Button
                      type="reset"
                      size="sm"
                      color="danger"
                      onClick={(e) => cancel()}
                      block
                    >
                      <i className="fa fa-ban"></i> Reset
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </div>
          </Card>
        </Col>
        <Col sm="1" md="1"></Col>
      </Row>
    </div>
  );
};

export default UpdatePages;
