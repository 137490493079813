import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ResetPassword } from "../../../redux/reducers/auth";
import yinnLogo from "../../../assets/img/brand/logo.png";
import {
  Button,
  Form,
  Card,
  CardBody,
  Label,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

var divStyle = {
  color: "red",
  marginTop: "-3%",
};

const Reset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authToken = new URLSearchParams(history.location.search).get("token");

  const [resetObject, setResetObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const clearErrors = () => {
    setPasswordError(false);
    setPasswordMatchError(false);
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setResetObject({
      ...resetObject,
      [name]: value,
    });
  };
  const setPassword = () => {
    clearErrors();
    if (resetObject.password === "") {
      setPasswordError(true);
    } else if (resetObject.password !== resetObject.confirmPassword) {
      setPasswordMatchError(true);
    } else {
      const data = {
        password: resetObject.password,
        token: authToken,
      };
      dispatch(ResetPassword(data)).then((response) => {
        if (response.payload.success) {
          history.push("/auth/login");
        }
      });
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="5">
            <CardGroup>
              <Card className="p-3">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={yinnLogo} width="100" height="50" />
                </div>
                <Label
                  style={{ display: "flex", justifyContent: "center" }}
                  check
                  htmlFor="inline-checkbox1"
                >
                  <b>Set Your New Password</b>
                </Label>
                <CardBody>
                  <Form
                    action=""
                    // onSubmit={ e => signIn(e)}
                    encType="multipart/form-data"
                    className="form-horizontal"
                  >
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        name="password"
                        value={resetObject.password}
                        onChange={handleInputChange}
                        placeholder="Password"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <div style={divStyle} hidden={!passwordError}>
                        Enter valid Password.
                      </div>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        name="confirmPassword"
                        value={resetObject.confirmPassword}
                        onChange={handleInputChange}
                        placeholder="Confirm Password"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <div style={divStyle} hidden={!passwordMatchError}>
                        Password donot match
                      </div>
                    </InputGroup>
                    <br />
                    <InputGroup
                      className="mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-6%",
                      }}
                    >
                      <Button
                        color="warning"
                        className="btn btn-md"
                        onClick={setPassword}
                        block
                      >
                        <b>Set Password</b>
                      </Button>
                    </InputGroup>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reset;
