import { configureStore } from "@reduxjs/toolkit";
import createFilter from 'redux-persist-transform-filter';
import logger from "redux-logger";
import { persistReducer } from "redux-persist";
import { combineReducers } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import auth from "../reducers/auth";
import thunk from 'redux-thunk';
import admin from '../reducers/admin';
import seller from '../reducers/seller';
import user from '../reducers/user';

// const transform = createFilter('reducerName', ['attributeName', 'attributeName ]);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "currency"],
  // transforms: [transform],
};
const reducers = combineReducers({
  auth: auth,
  admin: admin,
  seller: seller,
  user: user
});
const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk/* , logger */],
});
