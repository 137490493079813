import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  createProfilePayout,
  createPayoutMethod,
  updatePayoutMethod,
} from "../../redux/reducers/user";
import { getProfileData } from "../../redux/reducers/auth";
import {
  bluesnapSupportedData,
  bluesnapSupportedCountries,
  bluesnapSupportedCurrencies,
} from "../../assets/bluesnap/bluesnapSupport";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { AppSwitch } from "@coreui/react";
import { updateUser } from "../../redux/reducers/admin";

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    user: {
      firstname,
      lastname,
      email,
      dateOfBirth,
      address,
      city,
      country,
      state,
      postalCode,
      phone,
      currency,
      countryCode,
      isPayoutSetup,
      vendorId,
      vendorInfoId,
      id,
      localBankTransferSwitch,
    },
    loading,
  } = useSelector((state) => state.auth);
  const { err, translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const sellerHomeTranslation =
    translationState?.[0]?.translations?.sellerHome?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  const [
    bluesnapSupportedVendorCountries,
    setBluesnapSupportedVendorCountries,
  ] = useState(bluesnapSupportedCountries);
  const [
    bluesnapSupportedVendorCurrencies,
    setBluesnapSupportedVendorCurrencies,
  ] = useState(bluesnapSupportedCurrencies);
  const [profileData, setProfileData] = useState({
    firstname,
    lastname,
    email,
    dateOfBirth: dateOfBirth
      ? moment(dateOfBirth).format("YYYY-MM-DD")
      : moment().subtract(18, "years").format("YYYY-MM-DD"),
    address,
    city,
    country,
    state,
    postalCode,
    phone,
    currency,
    countryCode,
    isPayoutSetup,
    vendorId,
    vendorInfoId,
  });
  const [showPayout, setShowPayout] = useState(false);

  const [payoutData, setPayoutData] = useState({
    payoutType: "WIRE",
    baseCurrency: currency,
    nameOnAccount: "",
    bankAccountType: "",
    bankAccountClass: "",
    bankName: "",
    swiftBic: "",
    country: countryCode,
    state,
    address,
    zip: postalCode,
    city,
    iban: vendorInfoId?.payoutInfo?.iban || "",
  });

  useEffect(() => {
    dispatch(getProfileData()).then((res) => {
      const { vendorInfoId, isPayoutSetup } = res.payload.data;
      setPayoutData({
        ...payoutData,
        nameOnAccount: vendorInfoId?.payoutInfo?.nameOnAccount, //Name on bank account for vendor.
        bankAccountType: vendorInfoId?.payoutInfo?.bankAccountType, //CHECKING SAVINGS
        bankAccountClass: vendorInfoId?.payoutInfo?.bankAccountClass, // PERSONAL CORPORATE INTERNATIONAL
        bankName: vendorInfoId?.payoutInfo?.bankName,
        swiftBic: vendorInfoId?.payoutInfo?.swiftBic, // swiftBic
        iban: vendorInfoId?.payoutInfo?.iban, //iban
      });
      setShowPayout(isPayoutSetup);
    });
  }, []);

  const editProfileData = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "country") {
      const countryObj = bluesnapSupportedVendorCountries.find(
        (obj) => obj.Country === value
      );
      setProfileData({
        ...profileData,
        country: countryObj?.Country,
        countryCode: countryObj?.Code,
      });
    } else {
      setProfileData({
        ...profileData,
        [name]: value,
      });
    }
  };
  const editPayoutData = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPayoutData({
      ...payoutData,
      [name]: value,
    });
  };
  const submitPayoutDetails = async () => {
    const {
      firstname,
      lastname,
      email,
      dateOfBirth,
      address,
      city,
      country,
      state,
      postalCode,
      phone,
      currency,
      countryCode,
    } = profileData;
    if (
      !firstname ||
      !lastname ||
      !email ||
      !dateOfBirth ||
      !address ||
      !city ||
      !country ||
      !state ||
      !postalCode ||
      !currency ||
      !phone ||
      !countryCode
    ) {
      toast.error(
        `${
          toastTranslation?.success
            ? toastTranslation?.success
            : "Kindly fill the required fields"
        }`
      );
      return;
    }
    dispatch(createProfilePayout(profileData)).then((response) => {
      if (!response.error) {
        dispatch(getProfileData());
        setPayoutData({
          ...payoutData,
          baseCurrency: currency,
          country: countryCode,
          state,
          address,
          zip: postalCode,
          city,
          iban: vendorInfoId?.payoutInfo?.iban,
        });
        setShowPayout(true);
      }
    });
  };

  const submitPayout = async () => {
    const {
      nameOnAccount,
      bankAccountType,
      bankAccountClass,
      bankName,
      swiftBic,
      iban,
    } = payoutData;
    if (
      !nameOnAccount ||
      !bankAccountType ||
      !bankAccountClass ||
      !bankName ||
      !swiftBic ||
      !iban
    ) {
      toast.error(
        `${
          toastTranslation?.success
            ? toastTranslation?.success
            : "Kindly fill the required fields"
        }`
      );
      return;
    }
    const finalDetails = {
      email,
      state,
      address,
      city,
      phone,
      firstName: firstname,
      lastName: lastname,
      country: countryCode,
      zip: postalCode,
      defaultPayoutCurrency: currency,
      vendorPrincipal: {
        email,
        city,
        address,
        firstName: firstname,
        lastName: lastname,
        country: countryCode, //country code
        zip: postalCode,
        dob: moment(dateOfBirth).format("DD-MM-YYYY"), // 28-09-9999
        personalIdentificationNumber: "1234",
      },
      vendorAgreement: {
        commissionPercent: 0,
        accountStatus: "ACTIVE",
        recurringCommission: "N",
      },
      payoutInfo: [{ ...payoutData }],
    };

    if (!vendorId) {
      dispatch(createPayoutMethod(finalDetails)).then(() => {
        dispatch(getProfileData());
      });
    } else {
      dispatch(updatePayoutMethod(finalDetails)).then(() => {
        dispatch(getProfileData());
      });
    }
  };

  return (
    <div
      style={{
        paddingLeft: "5%",
        paddingRight: "5%",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <ToastContainer autoClose={20000} />
      <Card>
        <CardHeader>
          {sellerHomeTranslation?.firstLine
            ? sellerHomeTranslation?.firstLine
            : "Configure Payout Method to Recieve Money"}
        </CardHeader>
        {loading ? (
          <div style={{ margin: "240px", textAlign: "center" }}>
            <i
              className="mx-auto fas fa-spinner fa-pulse fa-2x"
              style={{ color: "green" }}
            ></i>
          </div>
        ) : (
          <CardBody style={{ paddingLeft: 20, paddingTop: 40 }}>
            {err && sellerHomeTranslation?.validationLineOne ? (
              <>
                <li className="text-danger pl-3 mb-2">
                  {" "}
                  {sellerHomeTranslation?.validationLineOne}{" "}
                </li>
                <li className="text-danger pl-3 mb-2">
                  {" "}
                  {sellerHomeTranslation?.validationLineTwo}{" "}
                </li>
                <li className="text-danger pl-3 mb-2">
                  {sellerHomeTranslation?.validationLineThree}
                </li>
              </>
            ) : (
              typeof err !== "string" &&
              err.length &&
              err.map((e) => (
                <li className="text-danger pl-3 mb-2">{e.description}</li>
              ))
            )}
            <Row>
              <Col className="col-sm-6 col-12">
                <h4
                  style={{
                    marginBottom: 35,
                    marginTop: 20,
                    fontWeight: "bold",
                  }}
                >
                  {sellerHomeTranslation?.headingOne
                    ? sellerHomeTranslation?.headingOne
                    : "Payout account holder details"}
                </h4>
                <Form>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="firstname">
                      {shippingCartTranslation?.firsrtName
                        ? shippingCartTranslation?.firsrtName
                        : "First Name "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      onChange={editProfileData}
                      value={profileData.firstname}
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="type in your first name here"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="lastname">
                      {shippingCartTranslation?.lastName
                        ? shippingCartTranslation?.lastName
                        : "Last Name "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData.lastname}
                      onChange={editProfileData}
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="type in your first last here"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="email">
                      {sellerHomeTranslation?.email
                        ? sellerHomeTranslation?.email
                        : "Email "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      disabled
                      value={profileData.email}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="your email address"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="dateOfBirth">
                      {sellerHomeTranslation?.dob
                        ? sellerHomeTranslation?.dob
                        : "Date of Birth "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData?.dateOfBirth}
                      onChange={editProfileData}
                      type="date"
                      name="dateOfBirth"
                      id="dateOfBirth"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="country">
                      {shippingCartTranslation?.country
                        ? shippingCartTranslation?.country
                        : "Country "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData.country}
                      onChange={editProfileData}
                      type="select"
                      name="country"
                      id="country"
                    >
                      <option value="">
                        {shippingCartTranslation?.country
                          ? shippingCartTranslation?.country
                          : "select Country"}
                      </option>
                      {bluesnapSupportedVendorCountries?.map((obj, index) => (
                        <option key={index} value={obj?.Country}>
                          {obj.Country}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="currency">
                      {sellerHomeTranslation?.currency
                        ? sellerHomeTranslation?.currency
                        : "Currency "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData?.currency}
                      onChange={editProfileData}
                      type="select"
                      name="currency"
                      id="currency"
                    >
                      <option value="">
                        {sellerHomeTranslation?.currency
                          ? sellerHomeTranslation?.currency
                          : "select currency "}
                      </option>
                      {bluesnapSupportedVendorCurrencies?.map((obj, index) => (
                        <option key={index} value={obj?.currency}>
                          {obj.currency}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="state">
                      {shippingCartTranslation?.state
                        ? shippingCartTranslation?.state
                        : "State/Province Code"}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData.state}
                      onChange={editProfileData}
                      type="text"
                      name="state"
                      id="state"
                      placeholder="e.g, CA"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="city">
                      {shippingCartTranslation?.city
                        ? shippingCartTranslation?.city
                        : "City"}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData.city}
                      onChange={editProfileData}
                      type="text"
                      name="city"
                      id="city"
                      placeholder="e.g, Los Angeles"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="address">
                      {sellerHomeTranslation?.address
                        ? sellerHomeTranslation?.address + "1"
                        : "Address Line 1 "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData.address}
                      onChange={editProfileData}
                      type="text"
                      name="address"
                      id="address"
                      placeholder="House no, Street Name"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="postalCode">
                      {sellerHomeTranslation?.postal
                        ? sellerHomeTranslation?.postal
                        : "Postal Code "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData.postalCode}
                      onChange={editProfileData}
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      placeholder="Postal Code"
                    />
                  </FormGroup>
                  <FormGroup style={{ maxWidth: 450 }}>
                    <Label for="phone">
                      {sellerHomeTranslation?.phone
                        ? sellerHomeTranslation?.phone
                        : "Phone No. "}
                      <span className="required">*</span>
                    </Label>
                    <Input
                      value={profileData.phone}
                      onChange={editProfileData}
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Phone No"
                    />
                  </FormGroup>
                  <Button
                    size="lg"
                    color="warning"
                    disabled={loading}
                    hidden={vendorId}
                    onClick={submitPayoutDetails}
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    {sellerHomeTranslation?.profile
                      ? sellerHomeTranslation?.profile
                      : " Update Profile"}
                  </Button>
                </Form>
              </Col>
              {showPayout && !loading ? (
                <Col className="col-sm-6 col-12">
                  <Form style={{ marginTop: 80 }}>
                    <FormGroup style={{ maxWidth: 450 }}>
                      <Label for="state">
                        {sellerHomeTranslation?.accountType
                          ? sellerHomeTranslation?.accountType
                          : "Account Type "}
                        <span className="required">*</span>
                      </Label>
                      <Input
                        value={"Local Bank Transfer"}
                        type="text"
                        name="type"
                        id="type"
                        placeholder="Account Type"
                        disabled
                      />
                    </FormGroup>
                    <FormGroup style={{ maxWidth: 450 }}>
                      <Label for="nameOnAccount">
                        {sellerHomeTranslation?.nameOfBankAcc
                          ? sellerHomeTranslation?.nameOfBankAcc
                          : "Name on Bank Account"}
                        <span className="required">*</span>
                      </Label>
                      <Input
                        value={payoutData?.nameOnAccount}
                        onChange={editPayoutData}
                        type="text"
                        name="nameOnAccount"
                        id="nameOnAccount"
                        placeholder="Name on account"
                      />
                    </FormGroup>
                    <FormGroup style={{ maxWidth: 450 }}>
                      <Label for="bankName">
                        {sellerHomeTranslation?.nameOfBank
                          ? sellerHomeTranslation?.nameOfBank
                          : "Bank Name "}
                        <span className="required">*</span>
                      </Label>
                      <Input
                        value={payoutData?.bankName}
                        onChange={editPayoutData}
                        type="text"
                        name="bankName"
                        id="bankName"
                        placeholder="Bank name"
                      />
                    </FormGroup>
                    <FormGroup style={{ maxWidth: 450 }}>
                      <Label for="swiftBic">
                        {sellerHomeTranslation?.swiftBio
                          ? sellerHomeTranslation?.swiftBio
                          : "Swift Bic"}
                        <span className="required">*</span>
                      </Label>
                      <Input
                        value={payoutData?.swiftBic}
                        type="text"
                        onChange={editPayoutData}
                        name="swiftBic"
                        id="swiftBic"
                        placeholder="swift Bic"
                      />
                    </FormGroup>
                    <FormGroup style={{ maxWidth: 450 }}>
                      <Label for="iban">
                        {sellerHomeTranslation?.Iban
                          ? sellerHomeTranslation?.Iban
                          : "Iban"}
                        <span className="required">*</span>
                      </Label>
                      <Input
                        value={payoutData?.iban}
                        onChange={editPayoutData}
                        type="text"
                        name="iban"
                        id="iban"
                        placeholder="Iban"
                      />
                    </FormGroup>
                    <FormGroup style={{ maxWidth: 450 }}>
                      <Label for="bankAccountType">
                        {sellerHomeTranslation?.bankAccType
                          ? sellerHomeTranslation?.bankAccType
                          : "Bank Account Type"}
                        <span className="required">*</span>
                      </Label>
                      <Input
                        value={payoutData?.bankAccountType}
                        onChange={editPayoutData}
                        type="select"
                        name="bankAccountType"
                        id="bankAccountType"
                      >
                        <option value="">select type</option>
                        <option value={"CHECKING"}>CHECKING</option>
                        <option value={"SAVINGS"}>SAVINGS</option>
                      </Input>
                    </FormGroup>
                    <FormGroup style={{ maxWidth: 450 }}>
                      <Label for="bankAccountClass">
                        {sellerHomeTranslation?.bankAccClass
                          ? sellerHomeTranslation?.bankAccClass
                          : "Bank Account Class"}
                        <span className="required">*</span>
                      </Label>
                      <Input
                        value={payoutData?.bankAccountClass}
                        onChange={editPayoutData}
                        type="select"
                        name="bankAccountClass"
                        id="bankAccountClass"
                      >
                        <option value="">select type</option>
                        <option value={"PERSONAL"}>PERSONAL</option>
                        <option value={"CORPORATE"}>CORPORATE</option>
                        <option value={"INTERNATIONAL"}>INTERNATIONAL</option>
                      </Input>
                    </FormGroup>
                    <Button
                      size="lg"
                      color="warning"
                      disabled={loading}
                      onClick={submitPayout}
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      {sellerHomeTranslation?.payoutButton
                        ? sellerHomeTranslation?.payoutButton
                        : "Update Payout"}
                    </Button>
                  </Form>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default Profile;
