import React from "react";
import "./summary.css";
import { useSelector } from "react-redux";

const ProductSummary = (props) => {
  const { cartData } = props;
  const { userCurrency , translationState} = useSelector((state) => state.user);
  const {userLanguage} = useSelector((state) => state.auth);
  const { usdRate } = useSelector((state) => state.auth);
  // const ChatGPTtranslation = cartData.map((item)=> {
  //   const data = item.product;
  //   const dd = data.translatedArray.find((item)=> item.language == userLanguage)
  // })
  const myCartTranslation = translationState?.[0]?.translations?.myCart?.[userLanguage];
 
  return (
    <>
      {cartData.map((cartEntry, index) => (
        <div className="product-summary-entry-container" key={index}>
          <img
            src={cartEntry.product.images[0]}
            className="product-summary-entry-image"
          />
          <div>
            <div className="product-summary-entry-title">
              
            {cartEntry.product.translatedArray.find((item)=> item.language == userLanguage)?.name ?
            cartEntry.product.translatedArray.find((item)=> item.language == userLanguage)?.name
            :
            cartEntry.product.name
            } 
            </div>
            <div className="product-summary-entry-quantity">
            {myCartTranslation?.quantity ? myCartTranslation?.quantity : "Quantity:"}
            {" "} {cartEntry.qty}
            </div>
            <div className="product-summary-entry-title">
            {myCartTranslation?.price ? myCartTranslation?.price : "Price:"}
              {" "}
              {(cartEntry.product?.unitPrice * cartEntry.qty * usdRate).toFixed(
                2
              )}{" "}
              {userCurrency}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProductSummary;
