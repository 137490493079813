export const nextPagesItems = (CurrentPage, perPage, TotalCount) => {
  // Per page * Current page - Total Products Count
  return TotalCount - perPage * CurrentPage;
};

export const sleep = (s) => {
  return new Promise((resolve) => setTimeout(resolve, s * 1000));
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const timeoutPromise = (ms) =>
  new Promise((res, rej) => {
    setTimeout(() => rej(new Error("Promise timeout")), ms);
  });
