import React, { useEffect } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

const AccountConfirmedNotice = () => {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <p className="text-muted float-left">Kindly check your email to confirm your account.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export default AccountConfirmedNotice;
